import React, { useState, useRef } from 'react';
import './CardList.css'; // Styles CSS pour la liste de cartes
import data from './json/data.json'; // Importez vos données depuis le fichier JSON
import { PlayCircleOutline } from '@mui/icons-material';

const CardList = ({ audioUrl, customClass }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const scrollableDivRef = useRef(null);

  const toggleAudio = () => {
    console.log("titi");
  };

  const scrollToMiddle = (card) => {
    const scrollableDiv = scrollableDivRef.current;
    const scrollLeft = card.offsetLeft - (scrollableDiv.offsetWidth - card.offsetWidth) +100;

    scrollableDiv.scrollTo({
      left: scrollLeft,
      behavior: 'smooth',
    });
  };

  return (
    <div className="card-list" ref={scrollableDivRef}>
      {data.map((sourate) => (
        <div
          key={sourate.id}
          className={`card ${customClass}`} // Utilisez la classe personnalisée ici
          onClick={(event) => {
            toggleAudio();
            scrollToMiddle(event.currentTarget);
          }}
        >
          <div className="card-content">
            <h6>
              {sourate.transliteration} <br /> {sourate.id}
            </h6>
            {/* Ajoutez d'autres informations de la sourate ici */}
          </div>
          <PlayCircleOutline
            onClick={(e) => {
              e.stopPropagation(); // Empêcher le déclenchement de l'écouteur de clic du parent
              toggleAudio();
              scrollToMiddle(e.currentTarget.parentElement);
            }}
            style={{ fontSize: 36, color: '#506757', cursor: 'pointer' }}
          />
        </div>
      ))}
    </div>
  );
};

export default CardList;
