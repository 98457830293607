import React, { useState } from 'react';
import data from './json/data.json';
import './QuranVerses.css';
import ListItemIcon from '@mui/material/ListItemIcon';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';

const QuranVerses = ({ onCopyArabicText }) => {
  const [selectedVerseId, setSelectedVerseId] = useState(1);

  const allVerseIds = data[1].verses.map((verse) => verse.id);

  const handleChange = (e) => {
    setSelectedVerseId(parseInt(e.target.value));
  };

  const handleCopyArabicText = (arabicText) => {
    navigator.clipboard.writeText(arabicText).then(() => {
      // Le texte a été copié avec succès, appelez la fonction parent pour afficher le texte copié et effacez le presse-papiers.
      onCopyArabicText(arabicText);
  
      // Effacez le presse-papiers pour éviter la duplication
      navigator.clipboard.writeText('');
    });
  };

  const filteredVerses = data.map((sourate) => {
    const verse = sourate.verses.find((verse) => verse.id === selectedVerseId);
    if (verse) {
      return (
        <div key={sourate.id} className='cardNum'>
          <h4>
            
            {`Sourate ${sourate.id}: `}{sourate.name} - {sourate.transliteration}
          </h4>
          <p><ListItemIcon>
              <FormatListNumberedRtlIcon
                className="icon-color"
                onClick={() => handleCopyArabicText(verse.text)}
              />
            </ListItemIcon>{`${verse.text} `}</p>
          <p>{`${verse.translation} `}</p>
        </div>
      );
    }
    return null;
  });

  return (
    <div className='divNum'>
      <h1>Versets du Coran</h1>
      <label>
        Sélectionnez le numéro de verset :
        <select value={selectedVerseId} onChange={handleChange}>
          {allVerseIds.map((verseId) => (
            <option key={verseId} value={verseId}>
              {verseId}
            </option>
          ))}
        </select>
      </label>
      <div className='numCardDiv'>
        {filteredVerses}
      </div>
    </div>
  );
};

export default QuranVerses;
