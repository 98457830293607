// src/Home.js
import React, { useState, useEffect } from 'react';
import QuranChart from './QuranChart'; // Assurez-vous que le chemin est correct

import data from './json/data.json'; // Assurez-vous d'importer correctement le JSON
import RadarChart from './RadarChart';
import './Chart.css';



const Chart  = () => {
  const [selectedSurah, setSelectedSurah] = useState(data[0]);
  const [sortedData, setSortedData] = useState(data); // Remplacez 'data' par vos données réelles

  const handleSortByClick = (sortBy) => {
    // Vous pouvez mettre en place la logique de tri ici
    
    // et mettez à jour 'sortedData' avec les données triées

    // Exemple de logique de tri
    if (sortBy === 'id') {
      sortedData.sort((a, b) => a.id - b.id);
    } else if (sortBy === 'appearance') {
      sortedData.sort((a, b) => a.order_of_revelation - b.order_of_revelation);
    } else if (sortBy === 'verses') {
      sortedData.sort((a, b) => a.total_verses - b.total_verses);
    } else if (sortBy === 'words') {
      sortedData.sort((a, b) => a.total_words - b.total_words);
    } else if (sortBy === 'letters') {
      sortedData.sort((a, b) => a.total_letters - b.total_letters);
    }

    // Mettez à jour 'sortedData' avec les données triées
    setSortedData([...sortedData]);
  };


  const handleSurahClick = (event) => {
    console.log('Sourate sélectionnée:', event);
    setSelectedSurah(event);
  };

 
  


  return <div className='mainChart'>
                <div className='charts'>
                <div className='chart-container chartContainer1'>
                <QuranChart data={sortedData} onSurahClick={handleSurahClick} onSortByClick={handleSortByClick}/>
                </div>
                <div className='chart-container chartContainer2' >
                  {selectedSurah && (
                    <RadarChart surahData={selectedSurah} data={data}/>
                  )}
                </div>
               
              </div>
                <div className='flexU'>
            
                </div>
                
      </div>;
  
};

export default Chart ;
