import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const WorldMap = () => {
  const svgRef = useRef(null);

  useEffect(() => {
    const width = 800;
    const height = 400;

    const svg = d3.select(svgRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const projection = d3.geoMercator()
      .scale(100)
      .translate([width / 2, height / 2]);

    const path = d3.geoPath().projection(projection);

    // Charger les données géographiques du monde (par exemple, GeoJSON)
    d3.json('/json/geo.json').then((worldData) => {
      // Dessiner les pays
      svg.selectAll("path")
        .data(worldData.features)
        .enter()
        .append("path")
        .attr("d", path)
        .attr("fill", "lightblue") // Couleur de remplissage des pays
        .attr("stroke", "white"); // Couleur de la bordure des pays

      // Dessiner des points
      const pointsData = [
        { coordinates: [39.82727449643028, 21.420314928165908] },
        { coordinates: [39.608815314086144, 24.468774457123374] },
        { coordinates: [37.96250279675013, 26.55708632284201] },
        { coordinates: [35.38326800752188, 30.33965932914812] },
        { coordinates: [44.24711283818982, 17.530197597579743] },
        { coordinates: [45.29507945611326, 15.482853383699123] },
      ];

      svg.selectAll("circle")
        .data(pointsData)
        .enter()
        .append("circle")
        .attr("cx", (d) => projection(d.coordinates)[0])
        .attr("cy", (d) => projection(d.coordinates)[1])
        .attr("r", 4) // Rayon du cercle
        .attr("fill", "red"); // Couleur du cercle

      // Dessiner des lignes
      const linesData = [
        { coordinates: [[35.36314265947965, 30.363845832194173], [45.23388817568622, 15.481520674117064]] },
        { coordinates: [[8.64081288562943, 24.949996566505448], [51.34899424310959, 33.9728490876241]] },
      ];

      const lineGenerator = d3.line()
        .x((d) => projection(d)[0])
        .y((d) => projection(d)[1]);

      svg.selectAll("path.line")
        .data(linesData)
        .enter()
        .append("path")
        .attr("d", (d) => lineGenerator(d.coordinates))
        .attr("fill", "none")
        .attr("stroke", "blue")
        .attr("stroke-width", 2);
    });
  }, []);

  return (
    <div>
      <svg ref={svgRef}></svg>
    </div>
  );
};

export default WorldMap;
