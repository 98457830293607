import React from 'react';
import ButtonList from './ButtonList';
import jsonData from './json/data.json'; // Assurez-vous d'importer correctement le JSON
import './Coran.css';

const Coran = () => {
  return (
    <div className="coranMain">

      

      <ButtonList data={jsonData} />
    </div>
  );
};




export default Coran;