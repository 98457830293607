// Exemple de composant de page simple (par exemple, Home.js)
import React from 'react';

const History = () => {
  return (
    <div>
      <h1>Page d'Histoire</h1>
      <p>Bienvenue sur la page d'histoire de l'application.</p>
    </div>
  );
};

export default History;
