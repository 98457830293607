// App.js
import React, { useState, useEffect } from 'react';
import Sourates from './Sourates.js';
import jsonData from './json/data.json'; // Assurez-vous d'importer correctement le JSON
import "./Home.css";


import jsonVersets from './json/versets.json';
import Services from './Services.js';
import CardList from './CardList.js';
import SearchButton from './SearchButton.js';


// Importez d'autres composants

const Home = () => {

  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedButton, setSelectedButton] = useState(1);


  useEffect(() => {
    // Réinitialiser la recherche lorsque la page est chargée
    setSearchTerm('');
    setSearchResults([]);
    setSelectedButton(selectedButton);
  }, [selectedButton]);


  const handleButtonClick = async (buttonId) => {
    setSelectedButton(buttonId);
    //const buttonElement = document.getElementById(`button-${buttonId}`);
    
    // Autres actions à effectuer après avoir mis à jour l'état
  };

  const handleSearch = (term) => {
    setSearchTerm(term);

    if (term.length === 0) {
      setSearchResults([]);
      return;
    }


    const results = jsonVersets.filter(
      item =>
        item['verse.translation']
          .toLowerCase()
          .includes(term.toLowerCase())
    );

    setSearchResults(results);
  };
  const fonction = () => {
    console.log(searchTerm);
  };
  


  return (
    <div className='mainHomeF'>
      <div className='mainHome'>
        <CardList />
        <div className='flexS'>
              <Sourates 
                handleButtonClick={handleButtonClick}
                setSelectedButton={setSelectedButton}
                data={jsonData} 
                selectedButton={selectedButton} 
              />
              <Services 
                  selectedButton={selectedButton} 
                  handleButtonClick={handleButtonClick}
                  data={jsonData} />
              
              

              
          </div>
        </div>
        <div className='mainSearch MuiGrid-grid-xs-4'>
            <SearchButton />
        </div>
    </div>
  );
};

export default Home;

