export const versesData = [
    {
      "key": 1,
      "id": "1-1",
      "translation": "Au nom d'Allah, le Tout Miséricordieux, le Très Miséricordieux"
    },
    {
      "key": 2,
      "id": "1-2",
      "translation": "Louange à Allah, Seigneur de l'univers"
    },
    {
      "key": 3,
      "id": "1-3",
      "translation": "Le Tout Miséricordieux, le Très Miséricordieux"
    },
    {
      "key": 4,
      "id": "1-4",
      "translation": "Maître du Jour de la rétribution"
    },
    {
      "key": 5,
      "id": "1-5",
      "translation": "C'est Toi [Seul] que nous adorons, et c'est Toi [Seul] dont nous implorons secours"
    },
    {
      "key": 6,
      "id": "1-6",
      "translation": "Guide-nous dans le droit chemin"
    },
    {
      "key": 7,
      "id": "1-7",
      "translation": "le chemin de ceux que Tu as comblés de faveurs, non pas de ceux qui ont encouru Ta colère, ni des égarés"
    },
    {
      "key": 8,
      "id": "2-1",
      "translation": "Alif, Lâm, Mim"
    },
    {
      "key": 9,
      "id": "2-2",
      "translation": "C'est le Livre au sujet duquel il n'y a aucun doute, c'est un guide pour les pieux"
    },
    {
      "key": 10,
      "id": "2-3",
      "translation": "qui croient à l'invisible et accomplissent la Salât et dépensent (dans l'obéissance à Allah), de ce que Nous leur avons attribué"
    },
    {
      "key": 11,
      "id": "2-4",
      "translation": "Ceux qui croient à ce qui t'a été descendu (révélé) et à ce qui a été descendu avant toi et qui croient fermement à la vie future"
    },
    {
      "key": 12,
      "id": "2-5",
      "translation": "Ceux-là sont sur le bon chemin de leur Seigneur, et ce sont eux qui réussissent (dans cette vie et dans la vie future)"
    },
    {
      "key": 13,
      "id": "2-6",
      "translation": "[Mais] certes les infidèles que tu les avertisses ou que tu ne les avertisses pas, ils ne croient pas"
    },
    {
      "key": 14,
      "id": "2-7",
      "translation": "Allah a scellé leurs cœurs et leurs oreilles; et un voile épais leur couvre la vue; et pour eux il y aura un grand châtiment"
    },
    {
      "key": 15,
      "id": "2-8",
      "translation": "Parmi les gens, il y a ceux qui disent: «Nous croyons en Allah et au Jour dernier!» tandis qu'en fait, ils n'y croient pas"
    },
    {
      "key": 16,
      "id": "2-9",
      "translation": "Ils cherchent à tromper Allah et les croyants; mais ils ne trompent qu'eux-mêmes, et ils ne s'en rendent pas compte"
    },
    {
      "key": 17,
      "id": "2-10",
      "translation": "Il y a dans leurs cœurs une maladie (de doute et d'hypocrisie), et Allah laisse croître leur maladie. Ils auront un châtiment douloureux, pour avoir menti"
    },
    {
      "key": 18,
      "id": "2-11",
      "translation": "Et quand on leur dit: «Ne semez pas la corruption sur la terre», ils disent: «Au contraire nous ne sommes que des réformateurs!»"
    },
    {
      "key": 19,
      "id": "2-12",
      "translation": "Certes, ce sont eux les véritables corrupteurs, mais ils ne s'en rendent pas compte"
    },
    {
      "key": 20,
      "id": "2-13",
      "translation": "Et quand on leur dit: «Croyez comme les gens ont cru», ils disent: «Croirons-nous comme ont cru les faibles d'esprit?» Certes, ce sont eux les véritables faibles d'esprit, mais ils ne le savent pas"
    },
    {
      "key": 21,
      "id": "2-14",
      "translation": "Quand ils rencontrent ceux qui ont cru, ils disent: «Nous croyons» mais quand ils se trouvent seuls avec leurs diables, ils disent: «Nous sommes avec vous; en effet nous ne faisions que nous moquer (d'eux)»"
    },
    {
      "key": 22,
      "id": "2-15",
      "translation": "C'est Allah qui Se moque d'eux et les endurcira dans leur révolte et prolongera sans fin leur égarement"
    },
    {
      "key": 23,
      "id": "2-16",
      "translation": "Ce sont eux qui ont troqué le droit chemin contre l'égarement. Eh bien, leur négoce n'a point profité. Et ils ne sont pas sur la bonne voie"
    },
    {
      "key": 24,
      "id": "2-17",
      "translation": "Ils ressemblent à quelqu'un qui a allumé un feu; puis quand le feu a illuminé tout à l'entour, Allah a fait disparaître leur lumière et les a abandonnés dans les ténèbres où ils ne voient plus rien"
    },
    {
      "key": 25,
      "id": "2-18",
      "translation": "Sourds, muets, aveugles, ils ne peuvent donc pas revenir (de leur égarement)"
    },
    {
      "key": 26,
      "id": "2-19",
      "translation": "(On peut encore les comparer à ces gens qui,) au moment où les nuées éclatent en pluies, chargées de ténèbres, de tonnerre et éclairs, se mettent les doigts dans les oreilles, terrorisés par le fracas de la foudre et craignant la mort; et Allah encercle de tous côtés les infidèles"
    },
    {
      "key": 27,
      "id": "2-20",
      "translation": "L'éclair presque leur emporte la vue: chaque fois qu'il leur donne de la lumière, ils avancent; mais dès qu'il fait obscur, ils s'arrêtent. Si Allah le voulait Il leur enlèverait certes l'ouïe et la vue, car Allah a pouvoir sur toute chose"
    },
    {
      "key": 28,
      "id": "2-21",
      "translation": "O hommes! Adorez votre Seigneur, qui vous a créés vous et ceux qui vous ont précédés. Ainsi atteindriez-vous à la piété"
    },
    {
      "key": 29,
      "id": "2-22",
      "translation": "C'est Lui qui vous a fait la terre pour lit, et le ciel pour toit; qui précipite la pluie du ciel et par elle fait surgir toutes sortes de fruits pour vous nourrir, ne Lui cherchez donc pas des égaux, alors que vous savez (tout cela)"
    },
    {
      "key": 30,
      "id": "2-23",
      "translation": "Si vous avez un doute sur ce que Nous avons révélé à Notre Serviteur, tâchez donc de produire une sourate semblable et appelez vos témoins, (les idoles) que vous adorez en dehors d'Allah, si vous êtes véridiques"
    },
    {
      "key": 31,
      "id": "2-24",
      "translation": "Si vous n'y parvenez pas et, à coup sûr, vous n'y parviendrez jamais, parez-vous donc contre le feu qu'alimenteront les hommes et les pierres, lequel est réservé aux infidèles"
    },
    {
      "key": 32,
      "id": "2-25",
      "translation": "Annonce à ceux qui croient et pratiquent de bonnes œuvres qu'ils auront pour demeures des jardins sous lesquels coulent les ruisseaux; chaque fois qu'ils seront gratifiés d'un fruit des jardins ils diront: «C'est bien là ce qui nous avait été servi auparavant». Or c'est quelque chose de semblable (seulement dans la forme); ils auront là des épouses pures, et là ils demeureront éternellement"
    },
    {
      "key": 33,
      "id": "2-26",
      "translation": "Certes, Allah ne se gêne point de citer en exemple n'importe quoi: un moustique ou quoi que ce soit au-dessus; quant aux croyants, ils savent bien qu'il s'agit de la vérité venant de la part de leur Seigneur; quant aux infidèles, ils se demandent «Qu'a voulu dire Allah par un tel exemple?» Par cela, nombreux sont ceux qu'Il égare et nombreux sont ceux qu'Il guide; mais Il n'égare par cela que les pervers"
    },
    {
      "key": 34,
      "id": "2-27",
      "translation": "qui rompent le pacte qu'ils avaient fermement conclu avec Allah, coupent ce qu'Allah a ordonné d'unir, et sèment la corruption sur la terre. Ceux-là sont les vrais perdants"
    },
    {
      "key": 35,
      "id": "2-28",
      "translation": "Comment pouvez-vous renier Allah alors qu'Il vous a donné la vie, quand vous en étiez privés? Puis Il vous fera mourir; puis Il vous fera revivre et enfin c'est à Lui que vous retournerez"
    },
    {
      "key": 36,
      "id": "2-29",
      "translation": "C'est Lui qui a créé pour vous tout ce qui est sur la terre, puis Il a orienté Sa volonté vers le ciel et en fit sept cieux. Et Il est Omniscient"
    },
    {
      "key": 37,
      "id": "2-30",
      "translation": "Lorsque Ton Seigneur confia aux Anges: «Je vais établir sur la terre un vicaire «Khalifa». Ils dirent: «Vas-Tu y désigner un qui y mettra le désordre et répandra le sang, quand nous sommes là à Te sanctifier et à Te glorifier?» - Il dit: «En vérité, Je sais ce que vous ne savez pas!»"
    },
    {
      "key": 38,
      "id": "2-31",
      "translation": "Et Il apprit à Adam tous les noms (de toutes choses), puis Il les présenta aux Anges et dit: «Informez-Moi des noms de ceux-là, si vous êtes véridiques!» (dans votre prétention que vous êtes plus méritants qu'Adam)"
    },
    {
      "key": 39,
      "id": "2-32",
      "translation": "Ils dirent: «Gloire à Toi! Nous n'avons de savoir que ce que Tu nous a appris. Certes c'est Toi l'Omniscient, le Sage»"
    },
    {
      "key": 40,
      "id": "2-33",
      "translation": "Il dit: «O Adam, informe-les de ces noms ;» Puis quand celui-ci les eut informés de ces noms, Allah dit: «Ne vous ai-Je pas dit que Je connais les mystères des cieux et de la terre, et que Je sais ce que vous divulguez et ce que vous cachez?»"
    },
    {
      "key": 41,
      "id": "2-34",
      "translation": "Et lorsque Nous demandâmes aux Anges de se prosterner devant Adam, ils se prosternèrent à l'exception d'Iblis qui refusa, s'enfla d'orgueil et fut parmi les infidèles"
    },
    {
      "key": 42,
      "id": "2-35",
      "translation": "Et Nous dîmes: «O Adam, habite le Paradis toi et ton épouse, et nourrissez-vous-en de partout à votre guise; mais n'approchez pas de l'arbre que voici: sinon vous seriez du nombre des injustes»"
    },
    {
      "key": 43,
      "id": "2-36",
      "translation": "Peu de temps après, Satan les fit glisser de là et les fit sortir du lieu où ils étaient. Et Nous dîmes: «Descendez (du Paradis); ennemis les uns des autres. Et pour vous il y aura une demeure sur la terre, et un usufruit pour un temps"
    },
    {
      "key": 44,
      "id": "2-37",
      "translation": "Puis Adam reçut de son Seigneur des paroles, et Allah agréa son repentir car c'est Lui certes, le Repentant, le Miséricordieux"
    },
    {
      "key": 45,
      "id": "2-38",
      "translation": "Nous dîmes: «Descendez d'ici, vous tous! Toutes les fois que Je vous enverrai un guide, ceux qui [le] suivront n'auront rien à craindre et ne seront point affligés»"
    },
    {
      "key": 46,
      "id": "2-39",
      "translation": "Et ceux qui ne croient pas (à nos messagers) et traitent de mensonge Nos révélations, ceux-là sont les gens du Feu où ils demeureront éternellement"
    },
    {
      "key": 47,
      "id": "2-40",
      "translation": "O enfants d'Israël, rappelez-vous Mon bienfait dont Je vous ai comblés. Si vous tenez vos engagements vis-à-vis de Moi, Je tiendrai les miens. Et c'est Moi que vous devez redouter"
    },
    {
      "key": 48,
      "id": "2-41",
      "translation": "Et croyez à ce que J'ai fait descendre, en confirmation de ce qui était déjà avec vous; et ne soyez pas les premiers à le rejeter. Et n'échangez pas Mes révélations contre un vil prix. Et c'est Moi que vous devez craindre"
    },
    {
      "key": 49,
      "id": "2-42",
      "translation": "Et ne mêlez pas le faux à la vérité. Ne cachez pas sciemment la vérité"
    },
    {
      "key": 50,
      "id": "2-43",
      "translation": "Et accomplissez la Salât, et acquittez la Zakât, et inclinez-vous avec ceux qui s'inclinent"
    },
    {
      "key": 51,
      "id": "2-44",
      "translation": "Commanderez-vous aux gens de faire le bien, et vous oubliez vous-mêmes de le faire, alors que vous récitez le Livre? Etes-vous donc dépourvus de raison"
    },
    {
      "key": 52,
      "id": "2-45",
      "translation": "Et cherchez secours dans l'endurance et la salât: certes, la Salât est une lourde obligation, sauf pour les humbles"
    },
    {
      "key": 53,
      "id": "2-46",
      "translation": "qui ont la certitude de rencontrer leur Seigneur (après leur résurrection) et retourner à Lui seul"
    },
    {
      "key": 54,
      "id": "2-47",
      "translation": "O Enfants d'Israël, rappelez-vous Mon bienfait dont Je vous ai comblés, (Rappelez-vous) que Je vous ai préférés à tous les peuples (de l'époque)"
    },
    {
      "key": 55,
      "id": "2-48",
      "translation": "Et redoutez le jour où nulle âme ne suffira en quoi que ce soit à une autre; où l'on n'acceptera d'elle aucune intercession; et où on ne recevra d'elle aucune compensation. Et ils ne seront point secourus"
    },
    {
      "key": 56,
      "id": "2-49",
      "translation": "Et [rappelez-vous] lorsque Nous vous avons délivrés des gens de Pharaon; qui vous infligeaient le pire châtiment: en égorgeant vos fils et épargnant vos femmes. C'était là une grande épreuve de la part de votre Seigneur"
    },
    {
      "key": 57,
      "id": "2-50",
      "translation": "Et [rappelez-vous] lorsque Nous avons fendu la mer pour vous donner passage!... Nous vous avons donc délivrés, et noyé les gens de Pharaon, tandis que vous regardiez"
    },
    {
      "key": 58,
      "id": "2-51",
      "translation": "Et [rappelez-vous] lorsque Nous donnâmes rendez-vous à Moïse pendant quarante nuits!... Puis en son absence vous avez pris le Veau pour idole alors que vous étiez injustes (à l'égard de vous-mêmes en adorant autre qu'Allah)"
    },
    {
      "key": 59,
      "id": "2-52",
      "translation": "Mais en dépit de cela Nous vous pardonnâmes, afin que vous reconnaissiez (Nos bienfaits à votre égard)"
    },
    {
      "key": 60,
      "id": "2-53",
      "translation": "Et [rappelez-vous] lorsque Nous avons donné à Moïse le Livre et le Discernement afin que vous soyez guidés"
    },
    {
      "key": 61,
      "id": "2-54",
      "translation": "Et [rappelez-vous] lorsque Moïse dit à son peuple: «O mon peuple, certes vous vous êtes fait du tort à vous-mêmes en prenant le Veau pour idole. Revenez donc à votre Créateur; puis, tuez donc les coupables vous-mêmes: ce serait mieux pour vous, auprès de votre Créateur!»... C'est ainsi qu'Il agréa votre repentir; car c'est Lui, certes, le Repentant et le Miséricordieux"
    },
    {
      "key": 62,
      "id": "2-55",
      "translation": "Et [rappelez-vous] lorsque vous dites: «O Moïse, nous ne te croirons qu'après avoir vu Allah clairement»!... Alors la foudre vous saisit tandis que vous regardiez"
    },
    {
      "key": 63,
      "id": "2-56",
      "translation": "Puis Nous vous ressuscitâmes après votre mort afin que vous soyez reconnaissants"
    },
    {
      "key": 64,
      "id": "2-57",
      "translation": "Et Nous vous couvrîmes de l'ombre d'un nuage; et fîmes descendre sur vous la manne et les cailles: - «Mangez des délices que Nous vous avons attribués!» - Ce n'est pas à Nous qu'ils firent du tort, mais ils se firent tort à eux-mêmes"
    },
    {
      "key": 65,
      "id": "2-58",
      "translation": "Et [rappelez-vous] lorsque Nous dîmes: «Entrez dans cette ville, et mangez-y à l'envie où il vous plaira; mais entrez par la porte en vous prosternant et demandez la «rémission» (de vos péchés); Nous vous pardonnerons vos fautes si vous faites cela et donnerons davantage de récompense pour les bienfaisants"
    },
    {
      "key": 66,
      "id": "2-59",
      "translation": "Mais, à ces paroles, les pervers en substituèrent d'autres, et pour les punir de leur fourberie Nous leur envoyâmes du ciel un châtiment avilissant"
    },
    {
      "key": 67,
      "id": "2-60",
      "translation": "Et [rappelez-vous] quand Moïse demanda de l'eau pour désaltérer son peuple, c'est alors que Nous dîmes: «Frappe le rocher avec ton bâton». Et tout d'un coup, douze sources en jaillirent, et certes, chaque tribu sut où s'abreuver! - «Mangez et buvez de ce qu'Allah vous accorde; et ne semez pas de troubles sur la terre comme des fauteurs de désordre»"
    },
    {
      "key": 68,
      "id": "2-61",
      "translation": "Et [rappelez-vous] quand vous dîtes: «O Moïse, nous ne pouvons plus tolérer une seule nourriture. Prie donc ton Seigneur pour qu'Il nous fasse sortir de la terre ce qu'elle fait pousser, de ses légumes, ses concombres, son ail (ou blé), ses lentilles et ses oignons!» - Il vous répondit: «Voulez-vous échanger le meilleur pour le moins bon? Descendez donc à n'importe quelle ville; vous y trouverez certainement ce que vous demandez!». L'avilissement et la misère s'abattirent sur eux; ils encoururent la colère d'Allah. Cela est parce qu'ils reniaient les révélations d'Allah, et qu'ils tuaient sans droit les prophètes. Cela parce qu'ils désobéissaient et transgressaient"
    },
    {
      "key": 69,
      "id": "2-62",
      "translation": "Certes, ceux qui ont cru, ceux qui se sont judaïsés, les Nazaréens, et les sabéens, quiconque d'entre eux a cru en Allah au Jour dernier et accompli de bonnes œuvres, sera récompensé par son Seigneur; il n'éprouvera aucune crainte et il ne sera jamais affligé"
    },
    {
      "key": 70,
      "id": "2-63",
      "translation": "(Et rappelez-vous) quand Nous avons contracté un engagement avec vous et brandi sur vous le Mont -: «Tenez ferme ce que Nous vous avons donné et souvenez-vous de ce qui s'y trouve afin que vous soyez pieux!»"
    },
    {
      "key": 71,
      "id": "2-64",
      "translation": "Puis vous vous en détournâtes après vos engagements, n'eût été donc la grâce d'Allah et Sa miséricorde, vous seriez certes parmi les perdants"
    },
    {
      "key": 72,
      "id": "2-65",
      "translation": "Vous avez certainement connu ceux des vôtres qui transgressèrent le Sabbat. Et bien Nous leur dîmes: «Soyez des singes abjects!»"
    },
    {
      "key": 73,
      "id": "2-66",
      "translation": "Nous fîmes donc de cela un exemple pour les villes qui l'entouraient alors et une exhortation pour les pieux"
    },
    {
      "key": 74,
      "id": "2-67",
      "translation": "(Et rappelez-vous) lorsque Moïse dit à son peuple: «Certes Allah vous ordonne d'immoler une vache». Ils dirent: «Nous prends-tu en moquerie?» «Qu'Allah me garde d'être du nombre des ignorants» dit-il"
    },
    {
      "key": 75,
      "id": "2-68",
      "translation": "Ils dirent: «Demande pour nous à ton Seigneur qu'Il nous précise ce qu'elle doit être». - Il dit: «Certes Allah dit que c'est bien une vache, ni vieille ni vierge, d'un âge moyen, entre les deux. Faites donc ce qu'on vous commande»"
    },
    {
      "key": 76,
      "id": "2-69",
      "translation": "Ils dirent: «Demande donc pour nous à ton Seigneur qu'Il nous précise sa couleur». - Il dit: «Allah dit que c'est une vache jaune, de couleur vive et plaisante à voir»"
    },
    {
      "key": 77,
      "id": "2-70",
      "translation": "Ils dirent: «Demande pour nous à ton Seigneur qu'Il nous précise ce qu'elle est car pour nous, les vaches se confondent. Mais, nous y serions certainement bien guidés, si Allah le veut»"
    },
    {
      "key": 78,
      "id": "2-71",
      "translation": "Il dit: «Allah dit que c'est bien une vache qui n'a pas été asservie à labourer la terre ni à arroser le champ, indemne d'infirmité et dont la couleur est unie». - Ils dirent: «Te voilà enfin, tu nous as apporté la vérité!» Ils l'immolèrent alors mais il s'en fallut qu'ils ne l'eussent pas fait"
    },
    {
      "key": 79,
      "id": "2-72",
      "translation": "Et quand vous aviez tué un homme et que chacun de vous cherchait à se disculper!... Mais Allah démasque ce que vous dissimuliez"
    },
    {
      "key": 80,
      "id": "2-73",
      "translation": "Nous dîmes donc: «Frappez le tué avec une partie de la vache». - Ainsi Allah ressuscite les morts et vous montre les signes (de Sa puissance) afin que vous raisonniez"
    },
    {
      "key": 81,
      "id": "2-74",
      "translation": "Puis, et en dépit de tout cela, vos cœurs se sont endurcis; ils sont devenus comme des pierres ou même plus durs encore; car il y a des pierres d'où jaillissent les ruisseaux, d'autres se fendent pour qu'en surgisse l'eau, d'autres s'affaissent par crainte d'Allah. Et Allah n'est certainement jamais inattentif à ce que vous faites"
    },
    {
      "key": 82,
      "id": "2-75",
      "translation": "Eh bien, espérez-vous [Musulmans] que des pareils gens (les Juifs) vous partageront la foi? alors qu'un groupe d'entre eux; après avoir entendu et compris la parole d'Allah, la falsifièrent sciemment"
    },
    {
      "key": 83,
      "id": "2-76",
      "translation": "Et quand ils rencontrent des croyants, ils disent: «Nous croyons» et, une fois seuls entre eux, ils disent: «Allez-vous confier aux musulmans ce qu'Allah vous a révélé pour leur fournir, ainsi, un argument contre vous devant votre Seigneur! Etes-vous donc dépourvus de raison?»"
    },
    {
      "key": 84,
      "id": "2-77",
      "translation": "Ne savent-ils pas qu'en vérité Allah sait ce qu'ils cachent et ce qu'ils divulguent"
    },
    {
      "key": 85,
      "id": "2-78",
      "translation": "Et il y a parmi eux des illettrés qui ne savent rien du Livre hormis des prétentions et ils ne font que des conjectures"
    },
    {
      "key": 86,
      "id": "2-79",
      "translation": "Malheur, donc, à ceux qui de leurs propres mains composent un livre puis le présentent comme venant d'Allah pour en tirer un vil profit! - Malheur à eux, donc, à cause de ce que leurs mains ont écrit, et malheur à eux à cause de ce qu'ils en profitent"
    },
    {
      "key": 87,
      "id": "2-80",
      "translation": "Et ils ont dit: «Le Feu ne nous touchera que pour quelques jours comptés!». Dis: «Auriez-vous pris un engagement avec Allah - car Allah ne manque jamais à Son engagement; - non, mais vous dites sur Allah ce que vous ne savez pas»"
    },
    {
      "key": 88,
      "id": "2-81",
      "translation": "Bien au contraire! Ceux qui font le mal et qui se font cerner par leurs péchés, ceux-là sont les gens du Feu où ils demeureront éternellement"
    },
    {
      "key": 89,
      "id": "2-82",
      "translation": "Et ceux qui croient et pratiquent les bonnes œuvres, ceux-là sont les gens du Paradis où ils demeureront éternellement"
    },
    {
      "key": 90,
      "id": "2-83",
      "translation": "Et [rappelle-toi], lorsque Nous avons pris l'engagement des enfants d'Israël de n'adorer qu'Allah, de faire le bien envers les pères, les mères, les proches parents, les orphelins et les nécessiteux, d'avoir de bonnes paroles avec les gens; d'accomplir régulièrement la Salât et d'acquitter la Zakât! - Mais à l'exception d'un petit nombre de vous, vous manquiez à vos engagements en vous détournant de Nos commandements"
    },
    {
      "key": 91,
      "id": "2-84",
      "translation": "Et rappelez-vous, lorsque Nous obtînmes de vous l'engagement de ne pas vous  le sang, [par le meurtre] de ne pas vous expulser les uns les autres de vos maisons. Puis vous y avez souscrit avec votre propre témoignage"
    },
    {
      "key": 92,
      "id": "2-85",
      "translation": "Quoique ainsi engagés, voilà que vous vous entretuez, que vous expulsez de leurs maisons une partie d'entre vous contre qui vous prêtez main forte par péché et agression. Mais quelle contradiction! Si vos coreligionnaires vous viennent captifs vous les rançonnez alors qu'il vous était interdit de les expulser (de chez eux). Croyez-vous donc en une partie du Livre et rejetez-vous le reste? Ceux d'entre vous qui agissent de la sorte ne méritent que l'ignominie dans cette vie, et au Jour de la Résurrection ils seront refoulés au plus dur châtiment, et Allah n'est pas inattentif à ce que vous faites"
    },
    {
      "key": 93,
      "id": "2-86",
      "translation": "Voilà ceux qui échangent la vie présente contre la vie future. Eh bien, leur châtiment ne sera pas diminué. Et ils ne seront point secourus"
    },
    {
      "key": 94,
      "id": "2-87",
      "translation": "Certes, Nous avons donné le Livre à Moïse; Nous avons envoyé après lui des prophètes successifs. Et Nous avons donné des preuves à Jésus fils de Marie, et Nous l'avons renforcé du Saint-Esprit. Est-ce qu'à chaque fois, qu'un Messager vous apportait des vérités contraires à vos souhaits vous vous enfliez d'orgueil? Vous traitiez les uns d'imposteurs et vous tuiez les autres"
    },
    {
      "key": 95,
      "id": "2-88",
      "translation": "Et ils dirent: «Nos cœurs sont enveloppés et impénétrables» - Non mais Allah les a maudits à cause de leur infidélité, leur foi est donc médiocre"
    },
    {
      "key": 96,
      "id": "2-89",
      "translation": "Et quand leur vint d'Allah un Livre confirmant celui qu'ils avaient déjà, - alors qu'auparavant ils cherchaient la suprématie sur les mécréants, - quand donc leur vint cela même qu'ils reconnaissaient, ils refusèrent d'y croire. Que la malédiction d'Allah soit sur les mécréants"
    },
    {
      "key": 97,
      "id": "2-90",
      "translation": "Comme est vil ce contre quoi ils ont troqué leurs âmes! Ils ne croient pas en ce qu'Allah a fait descendre, révoltés à l'idée qu'Allah, de par Sa grâce, fasse descendre la révélation sur ceux de Ses serviteurs qu'Il veut. Ils ont donc acquis colère sur colère, car un châtiment avilissant attend les infidèles"
    },
    {
      "key": 98,
      "id": "2-91",
      "translation": "Et quand on leur dit: «Croyez à ce qu'Allah a fait descendre», ils disent: «Nous croyons à ce qu'on a fait descendre à nous». Et ils rejettent le reste, alors qu'il est la vérité confirmant ce qu'il y avait déjà avec eux. - Dis: «Pourquoi donc avez-vous tué auparavant les prophètes d'Allah, si vous étiez croyants?»"
    },
    {
      "key": 99,
      "id": "2-92",
      "translation": "Et en effet Moïse vous est venu avec les preuves. Malgré cela, une fois absent, vous avez pris le Veau pour idole, alors que vous étiez injustes"
    },
    {
      "key": 100,
      "id": "2-93",
      "translation": "Et rappelez-vous, lorsque Nous avons pris l'engagement de vous, et brandi sur vous AT-Tûr (le Mont Sinaï) en vous disant: «Tenez ferme à ce que Nous vous avons donné, et écoutez!». Ils dirent: «Nous avons écouté et désobéi». Dans leur impiété, leurs cœurs étaient passionnément épris du Veau (objet de leur culte). Dis-[leur]: «Quelles mauvaises prescriptions ordonnées par votre foi, si vous êtes croyants»"
    },
    {
      "key": 101,
      "id": "2-94",
      "translation": "Dis: «Si l'Ultime demeure auprès d'Allah est pour vous seuls, à l'exclusion des autres gens, souhaitez donc la mort [immédiate] si vous êtes véridiques!»"
    },
    {
      "key": 102,
      "id": "2-95",
      "translation": "Or, ils ne la souhaiteront jamais, sachant tout le mal qu'ils ont perpétré de leurs mains. Et Allah connait bien les injustes"
    },
    {
      "key": 103,
      "id": "2-96",
      "translation": "Et certes tu les trouveras les plus attachés à la vie [d'ici-bas], pire en cela que les Associateurs. Tel d'entre eux aimerait vivre mille ans. Mais une pareille longévité ne le sauvera pas du châtiment! Et Allah voit bien leurs actions"
    },
    {
      "key": 104,
      "id": "2-97",
      "translation": "Dis: «Quiconque est ennemi de Gabriel doit connaître que c'est lui qui, avec la permission d'Allah, a fait descendre sur ton cœur cette révélation qui déclare véridiques les messages antérieurs et qui sert aux croyants de guide et d'heureuse annonce»"
    },
    {
      "key": 105,
      "id": "2-98",
      "translation": "[Dis:] «Quiconque est ennemi d'Allah, de Ses anges, de Ses messagers, de Gabriel et de Michaël... [Allah sera son ennemi] car Allah est l'ennemi des infidèles»"
    },
    {
      "key": 106,
      "id": "2-99",
      "translation": "Et très certainement Nous avons fait descendre vers toi des signes évidents. Et seuls les pervers n'y croient pas"
    },
    {
      "key": 107,
      "id": "2-100",
      "translation": "Faudrait-il chaque fois qu'ils concluent un pacte, qu'une partie d'entre eux le dénonce? C'est que plutôt la plupart d'entre eux ne sont pas croyants"
    },
    {
      "key": 108,
      "id": "2-101",
      "translation": "Et quand leur vint d'Allah un messager confirmant ce qu'il y avait déjà avec eux, certains à qui le Livre avait été donné, jetèrent derrière leur dos le Livre d'Allah comme s'ils ne savaient pas"
    },
    {
      "key": 109,
      "id": "2-102",
      "translation": "Et ils suivirent ce que les diables racontent contre le règne de Solayman. Alors que Solayman n'a jamais été mécréant mais bien les diables: ils enseignent aux gens la magie ainsi que ce qui est descendu aux deux anges Hârout et Mârout, à Babylone; mais ceux-ci n'enseignaient rien à personne, qu'ils n'aient dit d'abord: «Nous ne sommes rien qu'une tentation: ne sois pas mécréant» ils apprennent auprès d'eux ce qui sème la désunion entre l'homme et son épouse. Or ils ne sont capables de nuire à personne qu'avec la permission d'Allah. Et les gens apprennent ce qui leur nuit et ne leur est pas profitable. Et ils savent, très certainement, que celui qui acquiert [ce pouvoir] n'aura aucune part dans l'au-delà. Certes, quelle détestable marchandise pour laquelle ils ont vendu leurs âmes! Si seulement ils savaient"
    },
    {
      "key": 110,
      "id": "2-103",
      "translation": "Et s'ils croyaient et vivaient en piété, une récompense de la part d'Allah serait certes meilleure. Si seulement ils savaient"
    },
    {
      "key": 111,
      "id": "2-104",
      "translation": "O vous qui croyez! Ne dites pas: «Râ'inâ» (favorise-nous) mais dites: «Onzurnâ» (regarde-nous); et écoutez! Un châtiment douloureux sera pour les infidèles"
    },
    {
      "key": 112,
      "id": "2-105",
      "translation": "Ni les mécréants parmi les gens du Livre, ni les Associateurs n'aiment qu'on fasse descendre sur vous un bienfait de la part de votre Seigneur, alors qu'Allah réserve à qui Il veut sa Miséricorde. Et c'est Allah le Détenteur de l'abondante grâce"
    },
    {
      "key": 113,
      "id": "2-106",
      "translation": "Si Nous abrogeons un  quelconque ou que Nous le fassions oublier, Nous en apportons un meilleur, ou un semblable. Ne sais-tu pas qu'Allah est Omnipotent"
    },
    {
      "key": 114,
      "id": "2-107",
      "translation": "Ne sais-tu pas qu'à Allah, appartient le royaume des cieux et de la terre, et qu'en dehors d'Allah vous n'avez ni protecteur ni secoureur"
    },
    {
      "key": 115,
      "id": "2-108",
      "translation": "Voudriez-vous interroger votre Messager comme auparavant on interrogea Moïse? Quiconque substitue la mécréance à la foi s'égare certes du droit chemin"
    },
    {
      "key": 116,
      "id": "2-109",
      "translation": "Nombre de gens du Livre aimeraient par jalousie de leur part, pouvoir vous rendre mécréants après que vous ayez cru. Et après que la vérité s'est manifestée à eux! Pardonnez et oubliez jusqu'à ce qu'Allah fasse venir Son commandement. Allah est très certainement Omnipotent"
    },
    {
      "key": 117,
      "id": "2-110",
      "translation": "Et accomplissez la Salât et acquittez la Zakât. Et tout ce que vous avancez de bien pour vous-mêmes, vous le retrouverez auprès d'Allah, car Allah voit parfaitement ce que vous faites"
    },
    {
      "key": 118,
      "id": "2-111",
      "translation": "Et ils ont dit: «Nul n'entrera au Paradis que Juifs ou Chrétiens». Voilà leurs chimères. - Dis: «Donnez votre preuve, si vous êtes véridiques»"
    },
    {
      "key": 119,
      "id": "2-112",
      "translation": "Non, mais quiconque soumet à Allah son être tout en faisant le bien, aura sa rétribution auprès de son Seigneur. Pour eux, nulle crainte, et ils ne seront point attristés"
    },
    {
      "key": 120,
      "id": "2-113",
      "translation": "Et les Juifs disent: «Les Chrétiens ne tiennent sur rien» et les Chrétiens disent: «Les Juifs ne tiennent sur rien», alors qu'ils lisent le Livre! De même ceux qui ne savent rien tiennent un langage semblable au leur. Eh bien, Allah jugera sur ce quoi ils s'opposent, au Jour de la Résurrection"
    },
    {
      "key": 121,
      "id": "2-114",
      "translation": "Qui est plus injuste que celui qui empêche que dans les mosquées d'Allah, on mentionne Son Nom, et qui s'efforce à les détruire? De tels gens ne devraient y entrer qu'apeurés. Pour eux, ignominie ici-bas; et dans l'au-delà un énorme châtiment"
    },
    {
      "key": 122,
      "id": "2-115",
      "translation": "A Allah seul appartiennent l'Est et l'Ouest. Où que vous vous tourniez, la Face (direction) d'Allah est donc là, car Allah a la grâce immense; Il est Omniscient"
    },
    {
      "key": 123,
      "id": "2-116",
      "translation": "Et ils ont dit: «Allah s'est donné un fils»! Gloire à Lui! Non! mais c'est à Lui qu'appartient ce qui est dans les cieux et la terre et c'est à Lui que tous obéissent"
    },
    {
      "key": 124,
      "id": "2-117",
      "translation": "Il est le Créateur des cieux et de la terre à partir du néant. Lorsqu'Il décide une chose, Il dit seulement: «Sois», et elle est aussitôt"
    },
    {
      "key": 125,
      "id": "2-118",
      "translation": "Et ceux qui ne savent pas ont dit: «Pourquoi Allah ne nous parle-t-Il pas [directement], ou pourquoi un signe ne nous vient-il pas?» De même, ceux d'avant eux disaient une parole semblable. Leurs cœurs se ressemblent. Nous avons clairement exposé les signes pour des gens qui ont la foi ferme"
    },
    {
      "key": 126,
      "id": "2-119",
      "translation": "Certes, Nous t'avons envoyé avec la vérité, en annonciateur et avertisseur; et on ne te demande pas compte des gens de l'Enfer"
    },
    {
      "key": 127,
      "id": "2-120",
      "translation": "Ni les Juifs, ni les Chrétiens ne seront jamais satisfaits de toi, jusqu'à ce que tu suives leur religion. - Dis: «Certes, c'est la direction d'Allah qui est la vraie direction». Mais si tu suis leurs passions après ce que tu as reçu de science, tu n'auras contre Allah ni protecteur ni secoureur"
    },
    {
      "key": 128,
      "id": "2-121",
      "translation": "Ceux à qui Nous avons donné le Livre, qui le récitent comme il se doit, ceux-là y croient. Et ceux qui n'y croient pas sont les perdants"
    },
    {
      "key": 129,
      "id": "2-122",
      "translation": "O Enfants d'Israël, rappelez-vous Mon bienfait dont Je vous ai comblés et que Je vous ai favorisés par-dessus le reste du monde (de leur époque)"
    },
    {
      "key": 130,
      "id": "2-123",
      "translation": "Et redoutez le jour où nulle âme ne bénéficiera à une autre, où l'on n'acceptera d'elle aucune compensation, et où aucune intercession ne lui sera utile. Et ils ne seront point secourus"
    },
    {
      "key": 131,
      "id": "2-124",
      "translation": "[Et rappelle-toi] quand ton Seigneur eut éprouvé Abraham par certains commandements, et qu'il les eut accomplis, le Seigneur lui dit: «Je vais faire de toi un exemple à suivre pour les gens». - «Et parmi ma descendance?» demanda-t-il. - «Mon engagement, dit Allah, ne s'applique pas aux injustes»"
    },
    {
      "key": 132,
      "id": "2-125",
      "translation": "[Et rappelle-toi], quand nous fîmes de la Maison un lieu de visite et un asile pour les gens - Adoptez donc pour lieu de prière, ce lieu où Abraham se tint debout - Et Nous confiâmes à Abraham et à Ismaël ceci: «Purifiez Ma Maison pour ceux qui tournent autour, y font retraite pieuse, s'y inclinent et s'y prosternent"
    },
    {
      "key": 133,
      "id": "2-126",
      "translation": "Et quand Abraham supplia: «O mon Seigneur, fais de cette cité un lieu de sécurité, et fais attribution des fruits à ceux qui parmi ses habitants auront cru en Allah et au Jour dernier», le Seigneur dit: «Et quiconque n'y aura pas cru, alors Je lui concèderai une courte jouissance [ici-bas], puis Je le contraindrai au châtiment du Feu [dans l'au-delà]. Et quelle mauvaise destination!»"
    },
    {
      "key": 134,
      "id": "2-127",
      "translation": "Et quand Abraham et Ismaël élevaient les assises de la Maison: «O notre Seigneur, accepte ceci de notre part! Car c'est Toi l'Audient, l'Omniscient"
    },
    {
      "key": 135,
      "id": "2-128",
      "translation": "Notre Seigneur! Fais de nous Tes Soumis, et de notre descendance une communauté soumise à Toi. Et montre nous nos rites et accepte de nous le repentir. Car c'est Toi certes l'Accueillant au repentir, le Miséricordieux"
    },
    {
      "key": 136,
      "id": "2-129",
      "translation": "Notre Seigneur! Envoie l'un des leurs comme messager parmi eux, pour leur réciter Tes versets, leur enseigner le Livre et la Sagesse, et les purifier. Car c'est Toi certes le Puissant, le Sage"
    },
    {
      "key": 137,
      "id": "2-130",
      "translation": "Qui donc aura en aversion la religion d'Abraham, sinon celui qui sème son âme dans la sottise? Car très certainement Nous l'avons choisi en ce monde; et, dans l'au-delà, il est certes du nombre des gens de bien"
    },
    {
      "key": 138,
      "id": "2-131",
      "translation": "Quand son Seigneur lui avait dit: «Soumets-toi», il dit: «Je me soumets au Seigneur de l'Univers»"
    },
    {
      "key": 139,
      "id": "2-132",
      "translation": "Et c'est ce qu'Abraham recommanda à ses fils, de même que Jacob: «O mes fils, certes Allah vous a choisi la religion: ne mourrez point, donc, autrement qu'en Soumis!» (à Allah)"
    },
    {
      "key": 140,
      "id": "2-133",
      "translation": "Etiez-vous témoins quand la mort se présenta à Jacob et qu'il dit à ses fils: «Qu'adorerez-vous après moi?» - Ils répondirent: «Nous adorerons ta divinité et la divinité de tes pères, Abraham, Ismaël et Isaac, Divinité Unique et à laquelle nous sommes Soumis»"
    },
    {
      "key": 141,
      "id": "2-134",
      "translation": "Voilà une génération bel et bien révolue. A elle ce qu'elle a acquis, et à vous ce que vous avez acquis. On ne vous demandera pas compte de ce qu'ils faisaient"
    },
    {
      "key": 142,
      "id": "2-135",
      "translation": "Ils ont dit : «Soyez Juifs ou Chrétiens, vous serez donc sur la bonne voie». - Dis: «Non, mais nous suivons la religion d'Abraham le modèle même de la droiture et qui ne fut point parmi les Associateurs»"
    },
    {
      "key": 143,
      "id": "2-136",
      "translation": "Dites: «Nous croyons en Allah et en ce qu'on nous a révélé, et en ce qu'on a fait descendre vers Abraham et Ismaël et Isaac et Jacob et les Tribus, et en ce qui a été donné à Moïse et à Jésus, et en ce qui a été donné aux prophètes, venant de leur Seigneur: nous ne faisons aucune distinction entre eux. Et à Lui nous sommes Soumis»"
    },
    {
      "key": 144,
      "id": "2-137",
      "translation": "Alors, s'ils croient à cela même à quoi vous croyez, ils seront certainement sur la bonne voie. Et s'ils s'en détournent, ils seront certes dans le schisme! Alors Allah te suffira contre eux. Il est l'Audient, l'Omniscient"
    },
    {
      "key": 145,
      "id": "2-138",
      "translation": "«Nous suivons la religion d'Allah! Et qui est meilleur qu'Allah en Sa religion? C'est Lui que nous adorons»"
    },
    {
      "key": 146,
      "id": "2-139",
      "translation": "Dis: «Discutez vous avec nous au sujet d'Allah, alors qu'Il est notre Seigneur et le vôtre? A nous nos actions et à vous les vôtres! C'est à Lui que nous sommes dévoués"
    },
    {
      "key": 147,
      "id": "2-140",
      "translation": "Ou dites-vous qu'Abraham, Ismaël, Isaac et Jacob et les tribus étaient Juifs ou Chrétiens?» - Dis: «Est-ce vous les plus savants ou Allah?» - Qui est plus injuste que celui qui cache un témoignage qu'il détient d'Allah? Et Allah n'est pas inattentif à ce que vous faites"
    },
    {
      "key": 148,
      "id": "2-141",
      "translation": "Voilà une génération bel et bien révolue. A elle ce qu'elle a acquis, et à vous ce que vous avez acquis. Et on ne vous demandera pas compte de ce qu'ils faisaient"
    },
    {
      "key": 149,
      "id": "2-142",
      "translation": "Les faibles d'esprit parmi les gens vont dire: «Qui les a détournés de la direction (Qibla) vers laquelle ils s'orientaient auparavant?» - Dis: «C'est à Allah qu'appartiennent le Levant et le Couchant. Il guide qui Il veut vers un droit chemin»"
    },
    {
      "key": 150,
      "id": "2-143",
      "translation": "Et aussi Nous avons fait de vous une communauté de justes pour que vous soyez témoins aux gens, comme le Messager sera témoin à vous. Et Nous n'avions établi la direction (Qibla) vers laquelle tu te tournais que pour savoir qui suit le Messager et qui s'en retourne sur ses talons. C'était un changement difficile, mais pas pour ceux qu'Allah guide. Et ce n'est pas Allah qui vous fera perdre [la récompense de] votre foi, car Allah, certes est Compatissant et Miséricordieux pour les hommes"
    },
    {
      "key": 151,
      "id": "2-144",
      "translation": "Certes nous te voyons tourner le visage en tous sens dans le ciel. Nous te faisons donc orienter vers une direction qui te plaît. Tourne donc ton visage vers la Mosquée sacrée. Où que vous soyez, tournez-y vos visages. Certes, ceux à qui le Livre a été donné savent bien que c'est la vérité venue de leur Seigneur. Et Allah n'est pas inattentif à ce qu'ils font"
    },
    {
      "key": 152,
      "id": "2-145",
      "translation": "Certes si tu apportais toutes les preuves à ceux à qui le Livre a été donné, ils ne suivraient pas ta direction (Qibla)! Et tu ne suivras pas la leur; et entre eux, les uns ne suivent pas la direction des autres. Et si tu suivais leurs passions après ce que tu as reçu de science, tu serais, certes, du nombre des injustes"
    },
    {
      "key": 153,
      "id": "2-146",
      "translation": "Ceux à qui Nous avons donné le Livre, le reconnaissent comme ils reconnaissent leurs enfants. Or une partie d'entre eux cache la vérité, alors qu'ils la savent"
    },
    {
      "key": 154,
      "id": "2-147",
      "translation": "La vérité vient de ton Seigneur. Ne sois donc pas de ceux qui doutent"
    },
    {
      "key": 155,
      "id": "2-148",
      "translation": "A chacun une orientation vers laquelle il se tourne. Rivalisez donc dans les bonnes œuvres. Où que vous soyez, Allah vous ramènera tous vers Lui, car Allah est, certes Omnipotent"
    },
    {
      "key": 156,
      "id": "2-149",
      "translation": "Et d'où que tu sortes, tourne ton visage vers la Mosquée sacrée. Oui voilà bien la vérité venant de ton Seigneur. Et Allah n'est pas inattentif à ce que vous faites"
    },
    {
      "key": 157,
      "id": "2-150",
      "translation": "Et d'où que tu sortes, tourne ton visage vers la Mosquée sacrée. Et où que vous soyez, tournez-y vos visages, afin que les gens n'aient pas d'argument contre vous, sauf ceux d'entre eux qui sont de vrais injustes. Ne les craignez donc pas; mais craignez-Moi pour que Je parachève Mon bienfait à votre égard, et que vous soyez bien guidés"
    },
    {
      "key": 158,
      "id": "2-151",
      "translation": "Ainsi, Nous avons envoyé parmi vous un messager de chez vous qui vous récite Nos versets, vous purifie, vous enseigne le Livre et la Sagesse et vous enseigne ce que vous ne saviez pas"
    },
    {
      "key": 159,
      "id": "2-152",
      "translation": "Souvenez-vous de Moi donc. Je vous récompenserai. Remerciez-Moi et ne soyez pas ingrats envers Moi"
    },
    {
      "key": 160,
      "id": "2-153",
      "translation": "O les croyants! Cherchez secours dans l'endurance et la Salât. Car Allah est avec ceux qui sont endurants"
    },
    {
      "key": 161,
      "id": "2-154",
      "translation": "Et ne dites pas de ceux qui sont tués dans le sentier d'Allah qu'ils sont morts. Au contraire ils sont vivants, mais vous en êtes inconscients"
    },
    {
      "key": 162,
      "id": "2-155",
      "translation": "Très certainement, Nous vous éprouverons par un peu de peur, de faim et de diminution de biens, de personnes et de fruits. Et fais la bonne annonce aux endurants"
    },
    {
      "key": 163,
      "id": "2-156",
      "translation": "qui disent, quand un malheur les atteint: «Certes nous sommes à Allah, et c'est à Lui que nous retournerons"
    },
    {
      "key": 164,
      "id": "2-157",
      "translation": "Ceux-là reçoivent des bénédictions de leur Seigneur, ainsi que la miséricorde; et ceux-là sont les biens guidés"
    },
    {
      "key": 165,
      "id": "2-158",
      "translation": "AS-Safâ et Al-Marwah sont vraiment parmi les lieux sacrés d'Allah. Donc, quiconque fait pèlerinage à la Maison ou fait l'Umra ne commet pas de péché en faisant le va-et-vient entre ces deux monts. Et quiconque fait de son propre gré une bonne œuvre, alors Allah est Reconnaissant, Omniscient"
    },
    {
      "key": 166,
      "id": "2-159",
      "translation": "Certes ceux qui cachent ce que Nous avons fait descendre en fait de preuves et de guide après l'exposé que Nous en avons fait aux gens, dans le Livre, voilà ceux qu'Allah maudit et que les maudisseurs maudissent"
    },
    {
      "key": 167,
      "id": "2-160",
      "translation": "sauf ceux qui se sont repentis, corrigés et déclarés: d'eux Je reçois le repentir. Car c'est Moi, l'Accueillant au repentir, le Miséricordieux"
    },
    {
      "key": 168,
      "id": "2-161",
      "translation": "Ceux qui ne croient pas et meurent mécréants, recevront la malédiction d'Allah, des Anges et de tous les hommes"
    },
    {
      "key": 169,
      "id": "2-162",
      "translation": "Ils y demeureront éternellement; le châtiment ne leur sera pas allégé, et on ne leur accordera pas de répit"
    },
    {
      "key": 170,
      "id": "2-163",
      "translation": "Et votre Divinité est une divinité unique. Pas de divinité à part Lui, le Tout Miséricordieux, le Très Miséricordieux"
    },
    {
      "key": 171,
      "id": "2-164",
      "translation": "Certes dans la création des cieux et de la terre, dans l'alternance de la nuit et du jour, dans le navire qui vogue en mer chargé de choses profitables aux gens, dans l'eau qu'Allah fait descendre du ciel, par laquelle Il rend la vie à la terre une fois morte et y répand des bêtes de toute espèce dans la variation des vents, et dans les nuages soumis entre le ciel et la terre, en tout cela il y a des signes, pour un peuple qui raisonne"
    },
    {
      "key": 172,
      "id": "2-165",
      "translation": "Parmi les hommes, il en est qui prennent, en dehors d'Allah, des égaux à Lui, en les aimant comme on aime Allah. Or les croyants sont les plus ardents en l'amour d'Allah. Quand les injustes verront le châtiment, ils sauront que la force tout entière est à Allah et qu'Allah est dur en châtiment"
    },
    {
      "key": 173,
      "id": "2-166",
      "translation": "Quand les meneurs désavoueront les suiveurs à la vue du châtiment, les liens entre eux seront bien brisés"
    },
    {
      "key": 174,
      "id": "2-167",
      "translation": "Et les suiveurs diront: «Ah! Si un retour nous était possible! Alors nous les désavouerions comme ils nous ont désavoués!» - Ainsi Allah leur montra leurs actions; source de remords pour eux; mais ils ne pourront pas sortir du Feu"
    },
    {
      "key": 175,
      "id": "2-168",
      "translation": "O gens! De ce qui existe sur la terre; mangez le licite pur; ne suivez point les pas du Diable car il est vraiment pour vous, un ennemi déclaré"
    },
    {
      "key": 176,
      "id": "2-169",
      "translation": "Il ne vous commande que le mal et la turpitude et de dire contre Allah ce que vous ne savez pas"
    },
    {
      "key": 177,
      "id": "2-170",
      "translation": "Et quand on leur dit: «Suivez ce qu'Allah a fait descendre», ils disent: «Non, mais nous suivrons les coutumes de nos ancêtres.» - Quoi! et si leurs ancêtres n'avaient rien raisonné et s'ils n'avaient pas été dans la bonne direction"
    },
    {
      "key": 178,
      "id": "2-171",
      "translation": "Les mécréants ressemblent à [du bétail] auquel on crie et qui entend seulement appel et voix confus. Sourds, muets, aveugles, ils ne raisonnent point"
    },
    {
      "key": 179,
      "id": "2-172",
      "translation": "O les croyants! Mangez des (nourritures) licites que Nous vous avons attribuées. Et remerciez Allah, si c'est Lui que vous adorez"
    },
    {
      "key": 180,
      "id": "2-173",
      "translation": "Certes, Il vous interdit la chair d'une bête morte, le sang, la viande de porc et ce sur quoi on a invoqué un autre qu'Allah. Il n'y a pas de péché sur celui qui est contraint sans toutefois abuser ni transgresser, car Allah est Pardonneur et Miséricordieux"
    },
    {
      "key": 181,
      "id": "2-174",
      "translation": "Ceux qui cachent ce qu'Allah a fait descendre du Livre et le vendent à vil prix, ceux-là ne s'emplissent le ventre que de Feu. Allah ne leur adressera pas la parole, au Jour de la Résurrection, et ne les purifiera pas. Et il y aura pour eux un douloureux châtiment"
    },
    {
      "key": 182,
      "id": "2-175",
      "translation": "Ceux-là ont échangé la bonne direction contre l'égarement et le pardon contre le châtiment. Qu'est-ce qui leur fera supporter le Feu"
    },
    {
      "key": 183,
      "id": "2-176",
      "translation": "C'est ainsi, car c'est avec la vérité qu'Allah a fait descendre le Livre; et ceux qui s'opposent au sujet du Livre sont dans une profonde divergence"
    },
    {
      "key": 184,
      "id": "2-177",
      "translation": "La bonté pieuse ne consiste pas à tourner vos visages vers le Levant ou le Couchant. Mais la bonté pieuse est de croire en Allah, au Jour dernier, aux Anges, au Livre et aux prophètes, de donner de son bien, quelqu'amour qu'on en ait, aux proches, aux orphelins, aux nécessiteux, aux voyageurs indigents et à ceux qui demandent l'aide et pour délier les jougs, d'accomplir la Salât et d'acquitter la Zakât. Et ceux qui remplissent leurs engagements lorsqu'ils se sont engagés, ceux qui sont endurants dans la misère, la maladie et quand les combats font rage, les voilà les véridiques et les voilà les vrais pieux"
    },
    {
      "key": 185,
      "id": "2-178",
      "translation": "O les croyants! On vous a prescrit le talion au sujet des tués: homme libre pour homme libre, esclave pour esclave, femme pour femme. Mais celui à qui son frère aura pardonné en quelque façon doit faire face à une requête convenable et doit payer des dommages de bonne grâce. Ceci est un allègement de la part de votre Seigneur, et une miséricorde. Donc, quiconque après cela transgresse, aura un châtiment douloureux"
    },
    {
      "key": 186,
      "id": "2-179",
      "translation": "C'est dans le talion que vous aurez la préservation de la vie, ô vous doués d'intelligence, ainsi atteindrez-vous la piété"
    },
    {
      "key": 187,
      "id": "2-180",
      "translation": "On vous a prescrit, quand la mort est proche de l'un de vous et s'il laisse des biens, de faire un testament en règle en faveur de ses père et mère et de ses plus proches. C'est un devoir pour les pieux"
    },
    {
      "key": 188,
      "id": "2-181",
      "translation": "Quiconque l'altère après l'avoir entendu, le péché ne reposera que sur ceux qui l'ont altéré; certes, Allah est Audient et Omniscient"
    },
    {
      "key": 189,
      "id": "2-182",
      "translation": "Mais quiconque craint d'un testateur quelque partialité (volontaire ou involontaire), et les réconcilie, alors, pas de péché sur lui car Allah est certes Pardonneur et Miséricordieux"
    },
    {
      "key": 190,
      "id": "2-183",
      "translation": "O les croyants! On vous a prescrit aS-Siyâm comme on l'a prescrit à ceux d'avant vous, ainsi atteindrez-vous la piété"
    },
    {
      "key": 191,
      "id": "2-184",
      "translation": "pendant un nombre déterminé de jours. Quiconque d'entre vous est malade ou en voyage, devra jeûner un nombre égal d'autres jours. Mais pour ceux qui ne pourraient le supporter qu'(avec grande difficulté), il y a une compensation: nourrir un pauvre. Et si quelqu'un fait plus de son propre gré, c'est pour lui; mais il est mieux pour vous de jeûner; si vous saviez"
    },
    {
      "key": 192,
      "id": "2-185",
      "translation": "(Ces jours sont) le mois de Ramadân au cours duquel le Coran a été descendu comme guide pour les gens, et preuves claires de la bonne direction et du discernement. Donc, quiconque d'entre vous est présent en ce mois, qu'il jeûne! Et quiconque est malade ou en voyage, alors qu'il jeûne un nombre égal d'autres jours. - Allah veut pour vous la facilité, Il ne veut pas la difficulté pour vous, afin que vous en complétiez le nombre et que vous proclamiez la grandeur d'Allah pour vous avoir guidés, et afin que vous soyez reconnaissants"
    },
    {
      "key": 193,
      "id": "2-186",
      "translation": "Et quand Mes serviteurs t'interrogent sur Moi.. alors Je suis tout proche: Je réponds à l'appel de celui qui Me prie quand il Me prie. Qu'ils répondent à Mon appel, et qu'ils croient en Moi, afin qu'ils soient bien guidés"
    },
    {
      "key": 194,
      "id": "2-187",
      "translation": "On vous a permis, la nuit d'as-Siyâm, d'avoir des rapports avec vos femmes; elles sont un vêtement pour vous et vous êtes un vêtement pour elles. Allah sait que vous aviez clandestinement des rapports avec vos femmes. Il vous a pardonné et vous a graciés. Cohabitez donc avec elles, maintenant, et cherchez ce qu'Allah a prescrit en votre faveur; mangez et buvez jusqu'à ce que se distingue, pour vous, le fil blanc de l'aube du fil noir de la nuit. Puis accomplissez le jeûne jusqu'à la nuit. Mais ne cohabitez pas avec elles pendant que vous êtes en retraite rituelle dans les mosquées. Voilà les lois d'Allah: ne vous en approchez donc pas (pour les transgresser). C'est ainsi qu'Allah expose aux hommes Ses enseignements, afin qu'ils deviennent pieux"
    },
    {
      "key": 195,
      "id": "2-188",
      "translation": "Et ne dévorez pas mutuellement et illicitement vos biens; et ne vous en servez pas pour corrompre des juges pour vous permettre de dévorer une partie des biens des gens, injustement et sciemment"
    },
    {
      "key": 196,
      "id": "2-189",
      "translation": "Ils t'interrogent sur les nouvelles lunes - Dis: «Elles servent aux gens pour compter le temps, et aussi pour le hajj [pèlerinage]. Et ce n'est pas un acte de bienfaisance que de rentrer chez vous par l'arrière des maisons. Mais la bonté pieuse consiste à craindre Allah. Entrez donc dans les maisons par leurs portes. Et craignez Allah, afin que vous réussissiez"
    },
    {
      "key": 197,
      "id": "2-190",
      "translation": "Combattez dans le sentier d'Allah ceux qui vous combattent, et ne transgressez pas. Certes, Allah n'aime pas les transgresseurs"
    },
    {
      "key": 198,
      "id": "2-191",
      "translation": "Et tuez-les, où que vous les rencontriez; et chassez-les d'où ils vous ont chassés: l'association est plus grave que le meurtre. Mais ne les combattez pas près de la Mosquée sacrée avant qu'ils ne vous y aient combattus. S'ils vous y combattent, tuez-les donc. Telle est la rétribution des mécréants"
    },
    {
      "key": 199,
      "id": "2-192",
      "translation": "S'ils cessent, Allah est, certes, Pardonneur et Miséricordieux"
    },
    {
      "key": 200,
      "id": "2-193",
      "translation": "Et combattez-les jusqu'à ce qu'il n'y ait plus d'association et que la religion soit entièrement à Allah seul. S'ils cessent, donc plus d'hostilités, sauf contre les injustes"
    },
    {
      "key": 201,
      "id": "2-194",
      "translation": "Le Mois sacré pour le mois sacré! - Le talion s'applique à toutes choses sacrées -. Donc, quiconque transgresse contre vous, transgressez contre lui, à transgression égale. Et craignez Allah. Et sachez qu'Allah est avec les pieux"
    },
    {
      "key": 202,
      "id": "2-195",
      "translation": "Et dépensez dans le sentier d'Allah. Et ne vous jetez pas par vos propres mains dans la destruction. Et faites le bien. Car Allah aime les bienfaisants"
    },
    {
      "key": 203,
      "id": "2-196",
      "translation": "Et accomplissez pour Allah le pèlerinage et l'Umra. Si vous en êtes empêchés, alors faites un sacrifice qui vous soit facile. Et ne rasez pas vos têtes avant que l'offrande [l'animal à sacrifier] n'ait atteint son lieu d'immolation. Si l'un d'entre vous est malade ou souffre d'une affection de la tête (et doit se raser), qu'il se rachète alors par un Siyâm ou par une aumône ou par un sacrifice. Quand vous retrouverez ensuite la paix, quiconque a joui d'une vie normale après avoir fait l'Umra en attendant le pèlerinage, doit faire un sacrifice qui lui soit facile. S'il n'a pas les moyens qu'il jeûne trois jours pendant le pèlerinage et sept jours une fois rentré chez lui, soit en tout dix jours. Cela est prescrit pour celui dont la famille n'habite pas auprès de la Mosquée sacrée. Et craignez Allah. Et sachez qu'Allah est dur en punition"
    },
    {
      "key": 204,
      "id": "2-197",
      "translation": "Le pèlerinage a lieu dans des mois connus. Si l'on se décide de l'accomplir, alors point de rapport sexuel, point de perversité, point de dispute pendant le pèlerinage. Et le bien que vous faites, Allah le sait. Et prenez vos provisions; mais vraiment la meilleure provision est la piété. Et redoutez-Moi, ô doués d'intelligence"
    },
    {
      "key": 205,
      "id": "2-198",
      "translation": "Ce n'est pas un péché que d'aller en quête de quelque grâce de votre Seigneur. Puis, quand vous déferlez depuis 'Arafât, invoquez Allah, à Al-Mach'ar-al-haram (Al-Muzdalifa). Et invoquez-Le comme Il vous a montré la bonne voie, quoiqu'auparavant vous étiez du nombre des égarés"
    },
    {
      "key": 206,
      "id": "2-199",
      "translation": "Ensuite déferlez par où les gens déferlèrent, et demandez pardon à Allah. Car Allah est Pardonneur et Miséricordieux"
    },
    {
      "key": 207,
      "id": "2-200",
      "translation": "Et quand vous aurez achevé vos rites, alors invoquez Allah comme vous invoquez vos pères, et plus ardemment encore. Mais il est des gens qui disent seulement: «Seigneur! Accorde nous [le bien] ici-bas!» - Pour ceux-là, nulle part dans l'au-delà"
    },
    {
      "key": 208,
      "id": "2-201",
      "translation": "Et il est des gens qui disent: «Seigneur! Accorde nous belle part ici-bas, et belle part aussi dans l'au-delà; et protège-nous du châtiment du Feu!»"
    },
    {
      "key": 209,
      "id": "2-202",
      "translation": "Ceux-là auront une part de ce qu'ils auront acquis. Et Allah est prompt à faire rendre compte"
    },
    {
      "key": 210,
      "id": "2-203",
      "translation": "Et invoquez Allah pendant un nombre de jours déterminés. Ensuite, il n'y a pas de péché, pour qui se comporte en piété, à partir au bout de deux jours, à s'attarder non plus. Et craignez Allah. Et sachez que c'est vers Lui que vous serez rassemblés"
    },
    {
      "key": 211,
      "id": "2-204",
      "translation": "Il y a parmi les gens celui dont la parole sur la vie présente te plaît, et qui prend Allah à témoin de ce qu'il a dans le cœur, tandis que c'est le plus acharné disputeur"
    },
    {
      "key": 212,
      "id": "2-205",
      "translation": "Dès qu'il tourne le dos, il parcourt la terre pour y semer le désordre et saccager culture et bétail. Et Allah n'aime pas le désordre"
    },
    {
      "key": 213,
      "id": "2-206",
      "translation": "Et quand on lui dit: «Redoute Allah», l'orgueil criminel s'empare de lui. L'Enfer lui suffira, et quel mauvais lit, certes"
    },
    {
      "key": 214,
      "id": "2-207",
      "translation": "Et il y a parmi les gens celui qui se sacrifie pour la recherche de l'agrément d'Allah. Et Allah est Compatissant envers Ses serviteurs"
    },
    {
      "key": 215,
      "id": "2-208",
      "translation": "O les croyants! Entrez en plein dans l'Islam, et ne suivez point les pas du diable, car il est certes pour vous un ennemi déclaré"
    },
    {
      "key": 216,
      "id": "2-209",
      "translation": "Puis, si vous bronchez, après que les preuves vous soient venues, sachez alors qu'Allah est Puissant et Sage"
    },
    {
      "key": 217,
      "id": "2-210",
      "translation": "Qu'attendent-ils sinon qu'Allah leur vienne à l'ombre des nuées de même que les Anges et que leur sort soit réglé? Et c'est à Allah que toute chose est ramenée"
    },
    {
      "key": 218,
      "id": "2-211",
      "translation": "Demande aux enfants d'Israël combien de miracles évidents Nous leur avons apportés! Or, quiconque altère le bienfait d'Allah après qu'il lui soit parvenu... alors, Allah vraiment est dur en punition"
    },
    {
      "key": 219,
      "id": "2-212",
      "translation": "On a enjolivé la vie présente à ceux qui ne croient pas, et ils se moquent de ceux qui croient. Mais les pieux seront au-dessus d'eux, au Jour de la Résurrection. Et Allah accorde Ses bienfaits à qui Il veut, sans compter"
    },
    {
      "key": 220,
      "id": "2-213",
      "translation": "Les gens formaient (à l'origine) une seule communauté (croyante). Puis, (après leurs divergences,) Allah envoya des prophètes comme annonciateurs et avertisseurs; et Il fit descendre avec eux le Livre contenant la vérité, pour régler parmi les gens leurs divergences. Mais, ce sont ceux-là mêmes à qui il avait été apporté, qui se mirent à en disputer, après que les preuves leur furent venues, par esprit de rivalité! Puis Allah, de par Sa Grâce, guida ceux qui crurent vers cette Vérité sur laquelle les autres disputaient. Et Allah guide qui Il veut vers le chemin droit"
    },
    {
      "key": 221,
      "id": "2-214",
      "translation": "Pensez-vous entrer au Paradis alors que vous n'avez pas encore subi des épreuves semblables à celles que subirent ceux qui vécurent avant vous? Misère et maladie les avaient touchés; et ils furent secoués jusqu'à ce que le Messager, et avec lui, ceux qui avaient cru, se fussent écriés: «Quand viendra le secours d'Allah?» - Quoi! le secours d'Allah est sûrement proche"
    },
    {
      "key": 222,
      "id": "2-215",
      "translation": "Ils t'interrogent: «Qu'est-ce qu'on doit dépenser?» Dis: «Ce que vous dépensez de bien devrait être pour les père et mère, les proches, les orphelins, les pauvres et les voyageurs indigents. Et tout ce que vous faites de bien, vraiment Allah le sait»"
    },
    {
      "key": 223,
      "id": "2-216",
      "translation": "Le combat vous a été prescrit alors qu'il vous est désagréable. Or, il se peut que vous ayez de l'aversion pour une chose alors qu'elle vous est un bien. Et il se peut que vous aimiez une chose alors qu'elle vous est mauvaise. C'est Allah qui sait, alors que vous ne savez pas"
    },
    {
      "key": 224,
      "id": "2-217",
      "translation": "Ils t'interrogent sur le fait de faire la guerre pendant les mois sacrés. - Dis: «Y combattre est un péché grave, mais plus grave encore auprès d'Allah est de faire obstacle au sentier d'Allah, d'être impie envers Celui-ci et la Mosquée sacrée, et d'expulser de là ses habitants. L'association est plus grave que le meurtre.» Or, ils ne cesseront de vous combattre jusqu'à, s'ils peuvent, vous détourner de votre religion. Et ceux qui parmi vous abjureront leur religion et mourront infidèles, vaines seront pour eux leurs actions dans la vie immédiate et la vie future. Voilà les gens du Feu: ils y demeureront éternellement"
    },
    {
      "key": 225,
      "id": "2-218",
      "translation": "Certes, ceux qui ont cru, émigré et lutté dans le sentier d'Allah, ceux-là espèrent la miséricorde d'Allah. Et Allah est Pardonneur et Miséricordieux"
    },
    {
      "key": 226,
      "id": "2-219",
      "translation": "Ils t'interrogent sur le vin et les jeux de hasard. Dis: «Dans les deux il y a un grand péché et quelques avantages pour les gens; mais dans les deux, le péché est plus grand que l'utilité». Et ils t'interrogent: «Que doit-on dépenser (en charité)?» Dis: «L'excédent de vos biens.» Ainsi, Allah vous explique Ses versets afin que vous méditiez"
    },
    {
      "key": 227,
      "id": "2-220",
      "translation": "sur ce monde et sur l'au-delà! Et ils t'interrogent au sujet des orphelins. Dis: «Leur faire du bien est la meilleure action. Si vous vous mêlez à eux, ce sont alors vos frères [en religion]. Allah distingue celui qui sème le désordre de celui qui fait le bien. Et si Allah avait voulu, Il vous aurait accablés. Certes Allah est Puissant et Sage"
    },
    {
      "key": 228,
      "id": "2-221",
      "translation": "Et n'épousez pas les femmes associatrices tant qu'elles n'auront pas la foi, et certes, une esclave croyante vaut mieux qu'une associatrice même si elle vous enchante. Et ne donnez pas d'épouses aux associateurs tant qu'ils n'auront pas la foi, et certes, un esclave croyant vaut mieux qu'un associateur même s'il vous enchante. Car ceux-là [les associateurs] invitent au Feu; tandis qu'Allah invite, de par Sa Grâce, au Paradis et au pardon Et Il expose aux gens Ses enseignements afin qu'ils se souviennent"
    },
    {
      "key": 229,
      "id": "2-222",
      "translation": "Et ils t'interrogent sur la menstruation des femmes. - Dis: «C'est un mal. Eloignez-vous donc des femmes pendant les menstrues, et ne les approchez que quand elles sont pures. Quand elles se sont purifiées, alors cohabitez avec elles suivant les prescriptions d'Allah car Allah aime ceux qui se repentent, et Il aime ceux qui se purifient»"
    },
    {
      "key": 230,
      "id": "2-223",
      "translation": "Vos épouses sont pour vous un champ de labour; allez à votre champ comme [et quand] vous le voulez et œuvrez pour vous-mêmes à l'avance. Craignez Allah et sachez que vous Le rencontrerez. Et fais gracieuse annonce aux croyants"
    },
    {
      "key": 231,
      "id": "2-224",
      "translation": "Et n'usez pas du nom d'Allah, dans vos serments, pour vous dispenser de faire le bien, d'être pieux et de réconcilier les gens. Et Allah est Audient et Omniscient"
    },
    {
      "key": 232,
      "id": "2-225",
      "translation": "Ce n'est pas pour les expressions gratuites dans vos serments qu'Allah vous saisit: Il vous saisit pour ce que vos cœurs ont acquis. Et Allah est Pardonneur et Patient"
    },
    {
      "key": 233,
      "id": "2-226",
      "translation": "Pour ceux qui font le serment de se priver de leurs femmes, il y a un délai d'attente de quatre mois. Et s'ils reviennent (de leur serment) celui-ci sera annulé, car Allah est certes Pardonneur et Miséricordieux"
    },
    {
      "key": 234,
      "id": "2-227",
      "translation": "Mais s'ils se décident au divorce, (celui-ci devient exécutoire) car Allah est certes Audient et Omniscient"
    },
    {
      "key": 235,
      "id": "2-228",
      "translation": "Et les femmes divorcées doivent observer un délai d'attente de trois menstrues; et il ne leur est pas permis de taire ce qu'Allah a créé dans leurs ventres, si elles croient en Allah et au Jour dernier. Et leurs époux seront plus en droit de les reprendre pendant cette période, s'ils veulent la réconciliation. Quant à elles, elles ont des droits équivalents à leurs obligations, conformément à la bienséance. Mais les hommes ont cependant une prédominance sur elles. Et Allah est Puissant et Sage"
    },
    {
      "key": 236,
      "id": "2-229",
      "translation": "Le divorce est permis pour seulement deux fois. Alors, c'est soit la reprise conformément à la bienséance, ou la libération avec gentillesse. Et il ne vous est pas permis de reprendre quoi que ce soit de ce que vous leur aviez donné, - à moins que tous deux ne craignent de ne point pouvoir se conformer aux ordres imposés par Allah. Si donc vous craignez que tous deux ne puissent se conformer aux ordres d'Allah, alors ils ne commettent aucun péché si la femme se rachète avec quelque bien. Voilà les ordres d'Allah. Ne les transgressez donc pas. Et ceux qui transgressent les ordres d'Allah ceux-là sont les injustes"
    },
    {
      "key": 237,
      "id": "2-230",
      "translation": "S'il divorce avec elle (la troisième fois) alors elle ne lui sera plus licite tant qu'elle n'aura pas épousé un autre. Et si ce (dernier) la répudie alors les deux ne commettent aucun péché en reprenant la vie commune, pourvu qu'ils pensent pouvoir tous deux se conformer aux ordres d'Allah. Voilà les ordres d'Allah, qu'Il expose aux gens qui comprennent"
    },
    {
      "key": 238,
      "id": "2-231",
      "translation": "Et quand vous divorcez d'avec vos épouses, et que leur délai expire, alors, reprenez-les conformément à la bienséance ou libérez-les conformément à la bienséance. Mais ne les retenez pas pour leur faire du tort: vous transgresseriez alors et quiconque agit ainsi se fait du tort à lui-même. Ne prenez pas en moquerie les versets d'Allah. Et rappelez-vous le bienfait d'Allah envers vous, ainsi que le Livre et la Sagesse qu'Il vous a fait descendre; par lesquels Il vous exhorte. Et craignez Allah, et sachez qu'Allah est Omniscient"
    },
    {
      "key": 239,
      "id": "2-232",
      "translation": "Et quand vous divorcez d'avec vos épouses, et que leur délai expire, alors ne les empêchez pas de renouer avec leurs époux, s'ils s'agréent l'un l'autre, et conformément à la bienséance. Voilà à quoi est exhorté celui d'entre vous qui croit en Allah et au Jour dernier. Ceci est plus décent et plus pur pour vous. Et Allah sait, alors que vous ne savez pas"
    },
    {
      "key": 240,
      "id": "2-233",
      "translation": "Et les mères, qui veulent donner un allaitement complet, allaiteront leurs bébés deux ans complets. Au père de l'enfant de les nourrir et vêtir de manière convenable. Nul ne doit supporter plus que ses moyens. La mère n'a pas à subir de dommage à cause de son enfant, ni le père, à cause de son enfant. Même obligation pour l'héritier. Et si, après s'être consultés, tous deux tombent d'accord pour décider le sevrage, nul grief à leur faire. Et si vous voulez mettre vos enfants en nourrice, nul grief à vous faire non plus, à condition que vous acquittiez la rétribution convenue, conformément à l'usage. Et craignez Allah, et sachez qu'Allah observe ce que vous faites"
    },
    {
      "key": 241,
      "id": "2-234",
      "translation": "Ceux des vôtres que la mort frappe et qui laissent des épouses: celles-ci doivent observer une période d'attente de quatre mois et dix jours. Passé ce délai, on ne vous reprochera pas la façon dont elles disposeront d'elles-mêmes d'une manière convenable. Allah est Parfaitement Connaisseur de ce que vous faites"
    },
    {
      "key": 242,
      "id": "2-235",
      "translation": "Et on ne vous reprochera pas de faire, aux femmes, allusion à une proposition de mariage, ou d'en garder secrète l'intention. Allah sait que vous allez songer à ces femmes. Mais ne leur promettez rien secrètement sauf à leur dire des paroles convenables. Et ne vous décidez au contrat de mariage qu'à l'expiration du délai prescrit. Et sachez qu'Allah sait ce qu'il y a dans vos âmes. Prenez donc garde à Lui, et sachez aussi qu'Allah est Pardonneur et Plein de mansuétude"
    },
    {
      "key": 243,
      "id": "2-236",
      "translation": "Vous ne faites point de péché en divorçant d'avec des épouses que vous n'avez pas touchées, et à qui vous n'avez pas fixé leur mahr. Donnez-leur toutefois - l'homme aisé selon sa capacité, l'indigent selon sa capacité - quelque bien convenable dont elles puissent jouir. C'est un devoir pour les bienfaisants"
    },
    {
      "key": 244,
      "id": "2-237",
      "translation": "Et si vous divorcez d'avec elles sans les avoir touchées, mais après fixation de leur mahr -leur alors la moitié de ce que vous avez fixé, à moins qu'elles ne s'en désistent, ou que ne se désiste celui entre les mains de qui est la conclusion du mariage. Le désistement est plus proche de la piété. Et n'oubliez pas votre faveur mutuelle. Car Allah voit parfaitement ce que vous faites"
    },
    {
      "key": 245,
      "id": "2-238",
      "translation": "Soyez assidus aux Salâts et surtout la Salât médiane; et tenez-vous debout devant Allah, avec humilité"
    },
    {
      "key": 246,
      "id": "2-239",
      "translation": "Mais si vous craignez (un grand danger), alors priez en marchant ou sur vos montures. Puis quand vous êtes en sécurité, invoquez Allah comme Il vous a enseigné ce que vous ne saviez pas"
    },
    {
      "key": 247,
      "id": "2-240",
      "translation": "Ceux d'entre vous que la mort frappe et qui laissent des épouses, doivent laisser un testament en faveur de leurs épouses pourvoyant à un an d'entretien sans les expulser de chez elles. Si ce sont elles qui partent, alors on ne vous reprochera pas ce qu'elles font de convenable pour elles-mêmes. Allah est Puissant et Sage"
    },
    {
      "key": 248,
      "id": "2-241",
      "translation": "Les divorcées ont droit à la jouissance d'une allocation convenable, [constituant] un devoir pour les pieux"
    },
    {
      "key": 249,
      "id": "2-242",
      "translation": "C'est ainsi qu'Allah vous explique Ses versets, afin que vous raisonniez"
    },
    {
      "key": 250,
      "id": "2-243",
      "translation": "N'as-tu pas vu ceux qui sortirent de leurs demeures, - il y en avait des milliers -, par crainte de la mort? Puis Allah leur dit: «Mourez». Après quoi Il les rendit à la vie. Certes, Allah est Détenteur de la Faveur, envers les gens; mais la plupart des gens ne sont pas reconnaissants"
    },
    {
      "key": 251,
      "id": "2-244",
      "translation": "Et combattez dans le sentier d'Allah. Et sachez qu'Allah est Audient et Omniscient"
    },
    {
      "key": 252,
      "id": "2-245",
      "translation": "Quiconque prête à Allah de bonne grâce, Il le lui rendra multiplié plusieurs fois. Allah restreint ou étend (Ses faveurs.) Et c'est à Lui que vous retournerez"
    },
    {
      "key": 253,
      "id": "2-246",
      "translation": "N'as-tu pas su l'histoire des notables, parmi les enfants d'Israël, lorsqu'après Moïse ils dirent à un prophète à eux: «Désigne-nous un roi, pour que nous combattions dans le sentier d'Allah». Il dit: «Et si vous ne combattez pas, quand le combat vous sera prescrit?» Ils dirent: «Et qu'aurions-nous à ne pas combattre dans le sentier d'Allah, alors qu'on nous a expulsés de nos maisons et qu'on a capturé nos enfants?» Et quand le combat leur fut prescrit, ils tournèrent le dos, sauf un petit nombre d'entre eux. Et Allah connaît bien les injustes"
    },
    {
      "key": 254,
      "id": "2-247",
      "translation": "Et leur prophète leur dit: «Voici qu'Allah vous a envoyé Tâlût pour roi.» Ils dirent: «Comment règnerait-il sur nous? Nous avons plus de droit que lui à la royauté. On ne lui a même pas prodigué beaucoup de richesses!» Il dit: «Allah, vraiment l'a élu sur vous, et a accru sa part quant au savoir et à la condition physique.» - Et Allah alloue Son pouvoir à qui Il veut. Allah a la grâce immense et Il est Omniscient"
    },
    {
      "key": 255,
      "id": "2-248",
      "translation": "Et leur prophète leur dit: «Le signe de son investiture sera que le Coffre va vous revenir; objet de quiétude inspiré par votre Seigneur, et contenant les reliques de ce que laissèrent la famille de Moïse et la famille d'Aaron. Les Anges le porteront. Voilà bien là un signe pour vous, si vous êtes croyants!»"
    },
    {
      "key": 256,
      "id": "2-249",
      "translation": "Puis, au moment de partir avec les troupes, Tâlût dit: «Voici: Allah va vous éprouver par une rivière: quiconque y boira ne sera plus des miens; et quiconque n'y goûtera pas sera des miens; - passe pour celui qui y puisera un coup dans le creux de sa main.» Ils en burent, sauf un petit nombre d'entre eux. Puis, lorsqu'ils l'eurent traversée, lui et ceux des croyants qui l'accompagnaient, ils dirent: «Nous voilà sans force aujourd'hui contre Goliath et ses troupes!» Ceux qui étaient convaincus qu'ils auront à rencontrer Allah dirent: «Combien de fois une troupe peu nombreuse a, par la grâce d'Allah, vaincu une troupe très nombreuse! Et Allah est avec les endurants»"
    },
    {
      "key": 257,
      "id": "2-250",
      "translation": "Et quand ils affrontèrent Goliath et ses troupes, ils dirent: «Seigneur! Déverse sur nous l'endurance, affermis nos pas et donne-nous la victoire sur ce peuple infidèle»"
    },
    {
      "key": 258,
      "id": "2-251",
      "translation": "Ils les mirent en déroute, par la grâce d'Allah. Et David tua Goliath; et Allah lui donna la royauté et la sagesse, et lui enseigna ce qu'Il voulut. Et si Allah ne neutralisait pas une partie des hommes par une autre, la terre serait certainement corrompue. Mais Allah est Détenteur de la Faveur pour les mondes"
    },
    {
      "key": 259,
      "id": "2-252",
      "translation": "Voilà les versets d'Allah, que Nous te  récitons avec la vérité. Et tu es, certes parmi les Envoyés"
    },
    {
      "key": 260,
      "id": "2-253",
      "translation": "Parmi ces messagers, Nous avons favorisé certains par rapport à d'autres. Il en est à qui Allah a parlé; et Il en a élevé d'autres en grade. A Jésus fils de Marie Nous avons apporté les preuves, et l'avons fortifié par le Saint-Esprit. Et si Allah avait voulu, les gens qui vinrent après eux ne se seraient pas entretués, après que les preuves leur furent venues; mais ils se sont opposés: les uns restèrent croyants, les autres furent infidèles. Si Allah avait voulu, ils ne se seraient pas entretués; mais Allah fait ce qu'Il veut"
    },
    {
      "key": 261,
      "id": "2-254",
      "translation": "O les croyants! Dépensez de ce que Nous vous avons attribué, avant que vienne le jour où il n'y aura ni rançon ni amitié ni intercession. Et ce sont les mécréants qui sont les injustes"
    },
    {
      "key": 262,
      "id": "2-255",
      "translation": "Allah! Point de divinité à part Lui, le Vivant, Celui qui subsiste par lui-même «Al-Qayyûm». Ni somnolence ni sommeil ne Le saisissent. A Lui appartient tout ce qui est dans les cieux et sur la terre. Qui peut intercéder auprès de Lui sans Sa permission? Il connaît leur passé et leur futur. Et, de Sa science, ils n'embrassent que ce qu'Il veut. Son Trône «Kursiy», déborde les cieux et la terre, dont la garde ne Lui coûte aucune peine. Et Il est le Très Haut, le Très Grand"
    },
    {
      "key": 263,
      "id": "2-256",
      "translation": "Nulle contrainte en religion! Car le bon chemin s'est distingué de l'égarement. Donc, quiconque mécroit au Rebelle tandis qu'il croit en Allah saisit l'anse la plus solide, qui ne peut se briser. Et Allah est Audient et Omniscient"
    },
    {
      "key": 264,
      "id": "2-257",
      "translation": "Allah est le défenseur de ceux qui ont la foi: Il les fait sortir des ténèbres à la lumière. Quant à ceux qui ne croient pas, ils ont pour défenseurs les Tâghût, qui les font sortir de la lumière aux ténèbres. Voilà les gens du Feu, où ils demeurent éternellement"
    },
    {
      "key": 265,
      "id": "2-258",
      "translation": "N'as-tu pas su (l'histoire de) celui qui, parce qu'Allah l'avait fait roi, argumenta contre Abraham au sujet de son Seigneur? Abraham ayant dit: «J'ai pour Seigneur Celui qui donne la vie et la mort», «Moi aussi, dit l'autre, je donne la vie et la mort.» Alors dit Abraham: «Puisqu'Allah fait venir le soleil du Levant, fais-le donc venir du Couchant.» Le mécréant resta alors confondu. Allah ne guide pas les gens injustes"
    },
    {
      "key": 266,
      "id": "2-259",
      "translation": "Ou comme celui qui passait par un village désert et dévasté: «Comment Allah va-t-Il redonner la vie à celui-ci après sa mort?» dit-il. Allah donc le fit mourir et le garda ainsi pendant cent ans. Puis Il le ressuscita en disant: «Combien de temps as-tu demeuré ainsi?» «Je suis resté un jour, dit l'autre, ou une partie d'une journée.» «Non! dit Allah, tu es resté cent ans. Regarde donc ta nourriture et ta boisson: rien ne s'est gâté; mais regarde ton âne... Et pour faire de toi un signe pour les gens, et regarde ces ossements, comment Nous les assemblons et les revêtons de chair». Et devant l'évidence, il dit: «Je sais qu'Allah est Omnipotent»"
    },
    {
      "key": 267,
      "id": "2-260",
      "translation": "Et quand Abraham dit: «Seigneur! Montre-moi comment Tu ressuscites les morts», Allah dit: «Ne crois-tu pas encore?» «Si! dit Abraham; mais que mon cœur soit rassuré». «Prends donc, dit Allah, quatre oiseaux, apprivoise-les (et coupe-les) puis, sur des monts séparés, mets-en un fragment ensuite appelle-les: ils viendront à toi en toute hâte. Et sache qu'Allah est Puissant et sage.»"
    },
    {
      "key": 268,
      "id": "2-261",
      "translation": "Ceux qui dépensent leurs biens dans le sentier d'Allah ressemblent à un grain d'où naissent sept épis, à cent grains l'épi. Car Allah multiplie la récompense à qui Il veut et la grâce d'Allah est immense, et Il est Omniscient"
    },
    {
      "key": 269,
      "id": "2-262",
      "translation": "Ceux qui dépensent leurs biens dans le sentier d'Allah sans faire suivre leurs largesses ni d'un rappel ni d'un tort, auront leur récompense auprès de leur Seigneur. Nulle crainte pour eux, et ils ne seront point affligés"
    },
    {
      "key": 270,
      "id": "2-263",
      "translation": "Une parole agréable et un pardon valent mieux qu'une aumône suivie d'un tort. Allah n'a besoin de rien, et Il est Indulgent"
    },
    {
      "key": 271,
      "id": "2-264",
      "translation": "O les croyants! N'annulez pas vos aumônes par un rappel ou un tort, comme celui qui dépense son bien par ostentation devant les gens sans croire en Allah et au Jour dernier. Il ressemble à un rocher recouvert de terre: qu'une averse l'atteigne, elle le laisse dénué. De pareils hommes ne tirent aucun profit de leurs actes. Et Allah ne guide pas les gens mécréants"
    },
    {
      "key": 272,
      "id": "2-265",
      "translation": "Et ceux qui dépensent leurs biens cherchant l'agrément d'Allah, et bien rassurés (de Sa récompense), ils ressemblent à un jardin sur une colline. Qu'une averse l'atteigne, il double ses fruits; à défaut d'une averse qui l'atteint, c'est la rosée. Et Allah voit parfaitement ce que vous faites"
    },
    {
      "key": 273,
      "id": "2-266",
      "translation": "L'un de vous aimerait-il avoir un jardin de dattiers et de vignes sous lequel coulent les ruisseaux, et qui lui donne toutes espèces de fruits, que la vieillesse le rattrape, tandis que ses enfants sont encore petits, et qu'un tourbillon contenant du feu s'abatte sur son jardin et le brûle? Ainsi Allah vous explique les signes afin que vous méditiez"
    },
    {
      "key": 274,
      "id": "2-267",
      "translation": "O les croyants! Dépensez des meilleures choses que vous avez gagnées et des récoltes que Nous avons fait sortir de la terre pour vous. Et ne vous tournez pas vers ce qui est vil pour en faire dépense. Ne donnez pas ce que vous-mêmes n'accepteriez qu'en fermant les yeux! Et sachez qu'Allah n'a besoin de rien et qu'Il est digne de louange"
    },
    {
      "key": 275,
      "id": "2-268",
      "translation": "Le Diable vous fait craindre l'indigence et vous commande des actions honteuses; tandis qu'Allah vous promet pardon et faveur venant de Lui. La grâce d'Allah est immense et Il est Omniscient"
    },
    {
      "key": 276,
      "id": "2-269",
      "translation": "Il donne la sagesse à qui Il veut. Et celui à qui la sagesse est donnée, vraiment, c'est un bien immense qui lui est donné. Mais les doués d'intelligence seulement s'en souviennent"
    },
    {
      "key": 277,
      "id": "2-270",
      "translation": "Quelles que soient les dépenses que vœus avez faites, ou le vou que vous avez voué, Allah le sait. Et pour les injustes, pas de secoureurs"
    },
    {
      "key": 278,
      "id": "2-271",
      "translation": "Si vous donnez ouvertement vos aumônes, c'est bien; c'est mieux encore, pour vous, si vous êtes discrets avec elles et vous les donniez aux indigents. Allah effacera une partie de vos méfaits. Allah est Parfaitement Connaisseur de ce que vous faites"
    },
    {
      "key": 279,
      "id": "2-272",
      "translation": "Ce n'est pas à toi de les guider (vers la bonne voie), mais c'est Allah qui guide qui Il veut. Et tout ce que vous dépensez de vos biens sera à votre avantage et vous ne dépensez que pour la recherche de la Face «Wajh» d'Allah. Et tout ce que vous dépensez de vos biens dans les bonnes œuvres vous sera récompensé pleinement. Et vous ne serez pas lésés"
    },
    {
      "key": 280,
      "id": "2-273",
      "translation": "Aux nécessiteux qui se sont confinés dans le sentier d'Allah, ne pouvant pas parcourir le monde, et que l'ignorant croit riches parce qu'ils ont honte de mendier - tu les reconnaîtras à leur aspect - Ils n'importunent personne en mendiant. Et tout ce que vous dépensez de vos biens, Allah le sait parfaitement"
    },
    {
      "key": 281,
      "id": "2-274",
      "translation": "Ceux qui, de nuit et de jour, en secret et ouvertement, dépensent leurs biens (dans les bonnes œuvres), ont leur salaire auprès de leur Seigneur. Ils n'ont rien à craindre et ils ne seront point affligés"
    },
    {
      "key": 282,
      "id": "2-275",
      "translation": "Ceux qui mangent [pratiquent] de l'intérêt usuraire ne se tiennent (au jour du Jugement dernier) que comme se tient celui que le toucher de Satan a bouleversé. Cela, parce qu'ils disent: «Le commerce est tout à fait comme l'intérêt». Alors qu'Allah a rendu licite le commerce, et illicite l'intérêt. Celui, donc, qui cesse dès que lui est venue une exhortation de son Seigneur, peut conserver ce qu'il a acquis auparavant; et son affaire dépend d'Allah. Mais quiconque récidive... alors les voilà, les gens du Feu! Ils y demeureront éternellement"
    },
    {
      "key": 283,
      "id": "2-276",
      "translation": "Allah anéantit l'intérêt usuraire et fait fructifier les aumônes. Et Allah n'aime pas le mécréant pécheur"
    },
    {
      "key": 284,
      "id": "2-277",
      "translation": "Ceux qui ont la foi, ont fait de bonnes œuvres, accompli la Salât et acquitté la Zakât auront certes leur récompense auprès de leur Seigneur. Pas de crainte pour eux, et ils ne seront point affligés"
    },
    {
      "key": 285,
      "id": "2-278",
      "translation": "O les croyants! Craignez Allah; et renoncez au reliquat de l'intérêt usuraire, si vous êtes croyants"
    },
    {
      "key": 286,
      "id": "2-279",
      "translation": "Et si vous ne le faites pas, alors recevez l'annonce d'une guerre de la part d'Allah et de Son messager. Et si vous vous repentez, vous aurez vos capitaux. Vous ne léserez personne, et vous ne serez point lésés"
    },
    {
      "key": 287,
      "id": "2-280",
      "translation": "A celui qui est dans la gêne, accordez un sursis jusqu'à ce qu'il soit dans l'aisance. Mais il est mieux pour vous de faire remise de la dette par charité! Si vous saviez"
    },
    {
      "key": 288,
      "id": "2-281",
      "translation": "Et craignez le jour où vous serez ramenés vers Allah. Alors chaque âme sera pleinement rétribuée de ce qu'elle aura acquis. Et ils ne seront point lésés"
    },
    {
      "key": 289,
      "id": "2-282",
      "translation": "O les croyants! Quand vous contractez une dette à échéance déterminée, mettez-la en écrit; et qu'un scribe l'écrive, entre vous, en toute justice; un scribe n'a pas à refuser d'écrire selon ce qu'Allah lui a enseigné; qu'il écrive donc, et que dicte le débiteur: qu'il craigne Allah son Seigneur, et se garde d'en rien diminuer. Si le débiteur est gaspilleur ou faible, ou incapable de dicter lui-même, que son représentant dicte alors en toute justice. Faites-en témoigner par deux témoins d'entre vos hommes; et à défaut de deux hommes, un homme et deux femmes d'entre ceux que vous agréez comme témoins, en sorte que si l'une d'elles s'égare, l'autre puisse lui rappeler. Et que les témoins ne refusent pas quand ils sont appelés. Ne vous lassez pas d'écrire la dette, ainsi que son terme, qu'elle soit petite ou grande: c'est plus équitable auprès d'Allah, et plus droit pour le témoignage, et plus susceptible d'écarter les doutes. Mais s'il s'agit d'une marchandise présente que vous négociez entre vous: dans ce cas, il n'y a pas de péché à ne pas l'écrire. Mais prenez des témoins lorsque vous faites une transaction entre vous; et qu'on ne fasse aucun tort à aucun scribe ni à aucun témoin. Si vous le faisiez, cela serait une perversité en vous. Et craignez Allah. Alors Allah vous enseigne et Allah est Omniscient"
    },
    {
      "key": 290,
      "id": "2-283",
      "translation": "Mais si vous êtes en voyage et ne trouvez pas de scribe, un gage reçu suffit. S'il y a entre vous une confiance réciproque, que celui à qui on a confié quelque chose la restitue; et qu'il craigne Allah son Seigneur. Et ne cachez pas le témoignage: quiconque le cache a, certes, un cœur pécheur. Allah, de ce que vous faites, est Omniscient"
    },
    {
      "key": 291,
      "id": "2-284",
      "translation": "C'est à Allah qu'appartient tout ce qui est dans les cieux et sur la terre. Que vous manifestiez ce qui est en vous ou que vous le cachiez, Allah vous en demandera compte. Puis Il pardonnera à qui Il veut, et châtiera qui Il veut. Et Allah est Omnipotent"
    },
    {
      "key": 292,
      "id": "2-285",
      "translation": "Le Messager a cru en ce qu'on a fait descendre vers lui venant de son Seigneur, et aussi les croyants: tous ont cru en Allah, en Ses anges, à Ses livres et en Ses messagers; (en disant): «Nous ne faisons aucune distinction entre Ses messagers». Et ils ont dit: «Nous avons entendu et obéi. Seigneur, nous implorons Ton pardon. C'est à Toi que sera le retour»"
    },
    {
      "key": 293,
      "id": "2-286",
      "translation": "Allah n'impose à aucune âme une charge supérieure à sa capacité. Elle sera récompensée du bien qu'elle aura fait, punie du mal qu'elle aura fait. Seigneur, ne nous châtie pas s'il nous arrive d'oublier ou de commettre une erreur. Seigneur! Ne nous charge pas d'un fardeau lourd comme Tu as chargé ceux qui vécurent avant nous. Seigneur! Ne nous impose pas ce que nous ne pouvons supporter, efface nos fautes, pardonne-nous et fais nous miséricorde. Tu es Notre Maître, accorde-nous donc la victoire sur les peuples infidèles"
    },
    {
      "key": 294,
      "id": "3-1",
      "translation": "Alif, Lâm, Mîm"
    },
    {
      "key": 295,
      "id": "3-2",
      "translation": "Allah! Pas de divinité à part Lui, le Vivant, Celui qui subsiste par Lui-même «Al-Qayyum»"
    },
    {
      "key": 296,
      "id": "3-3",
      "translation": "Il a fait descendre sur toi le Livre avec la vérité, confirmant les Livres descendus avant lui. Et Il fit descendre la Thora et l'Evangile"
    },
    {
      "key": 297,
      "id": "3-4",
      "translation": "auparavant, en tant que guide pour les gens. Et Il a fait descendre le Discernement. Ceux qui ne croient pas aux Révélations d'Allah auront, certes, un dur châtiment! Et, Allah est Puissant, Détenteur du pouvoir de punir"
    },
    {
      "key": 298,
      "id": "3-5",
      "translation": "Rien, vraiment, ne se cache d'Allah de ce qui existe sur la terre ou dans le ciel"
    },
    {
      "key": 299,
      "id": "3-6",
      "translation": "C'est Lui qui vous donne forme dans les matrices comme Il veut. Point de divinité à part Lui, le Puissant, le Sage"
    },
    {
      "key": 300,
      "id": "3-7",
      "translation": "C'est Lui qui a fait descendre sur toi le Livre: il s'y trouve des versets sans équivoque, qui sont la base du Livre, et d'autres versets qui peuvent prêter à d'interprétations diverses. Les gens, donc, qui ont au cœur une inclination vers l'égarement, mettent l' accent sur les versets à équivoque cherchant la dissension en essayant de leur trouver une interprétation, alors que nul n'en connaît l'interprétation, à part Allah. Mais ceux qui sont bien enracinés dans la science disent: «Nous y croyons: tout est de la part de notre Seigneur!» Mais, seuls les doués d'intelligence s'en rappellent"
    },
    {
      "key": 301,
      "id": "3-8",
      "translation": "«Seigneur! Ne laisse pas dévier nos cœurs après que Tu nous aies guidés; et accorde-nous Ta miséricorde. C'est Toi, certes, le Grand Donateur"
    },
    {
      "key": 302,
      "id": "3-9",
      "translation": "Seigneur! C'est Toi qui rassembleras les gens, un jour - en quoi il n'y a point de doute - Allah, vraiment, ne manque jamais à Sa promesse.»"
    },
    {
      "key": 303,
      "id": "3-10",
      "translation": "Ceux qui ne croient pas, ni leurs biens ni leurs enfants ne les mettront aucunement à l' abri de la punition d'Allah. Ils seront du combustible pour le Feu"
    },
    {
      "key": 304,
      "id": "3-11",
      "translation": "comme les gens de Pharaon et ceux qui vécurent avant eux. Ils avaient traité de mensonges Nos preuves. Allah les saisit donc, pour leurs péchés. Et Allah est dur en punition"
    },
    {
      "key": 305,
      "id": "3-12",
      "translation": "Dis à ceux qui ne croient pas: «Vous serez vaincus bientôt; et vous serez rassemblés vers l'Enfer. Et quel mauvais endroit pour se reposer!»"
    },
    {
      "key": 306,
      "id": "3-13",
      "translation": "Il y eut déjà pour vous un signe dans ces deux troupes qui s'affrontèrent: l'une combattait dans le sentier d'Allah; et l'autre, était mécréante. Ces derniers voyaient (les croyants) de leurs propres yeux, deux fois plus nombreux qu'eux-mêmes. Or Allah secourt qui Il veut de Son aide. Voilà bien là un exemple pour les doués de clairvoyance"
    },
    {
      "key": 307,
      "id": "3-14",
      "translation": "On a enjolivé aux gens l'amour des choses qu'ils désirent: femmes, enfants, trésors thésaurisés d'or et d'argent, chevaux marqués, bétail et champs; tout cela est l'objet de jouissance pour la vie présente, alors que c'est près d'Allah qu'il y a bon retour"
    },
    {
      "key": 308,
      "id": "3-15",
      "translation": "Dis: «Puis-je vous apprendre quelque chose de meilleur que tout cela? Pour les pieux, il y a, auprès de leur Seigneur, des jardins sous lesquels coulent les ruisseaux, pour y demeurer éternellement, et aussi, des épouses purifiées, et l'agrément d'Allah.» Et Allah est Clairvoyant sur [Ses] serviteurs"
    },
    {
      "key": 309,
      "id": "3-16",
      "translation": "qui disent: «O notre Seigneur, nous avons la foi; pardonne-nous donc nos péchés, et protège-nous du châtiment du Feu»"
    },
    {
      "key": 310,
      "id": "3-17",
      "translation": "ce sont les endurants, les véridiques, les obéissants, ceux qui dépensent [dans le sentier d'Allah] et ceux qui implorent pardon juste avant l'aube"
    },
    {
      "key": 311,
      "id": "3-18",
      "translation": "Allah atteste, et aussi les Anges et les doués de science, qu'il n'y a point de divinité à part Lui, le Mainteneur de la justice. Point de divinité à part Lui, le Puissant, le Sage"
    },
    {
      "key": 312,
      "id": "3-19",
      "translation": "Certes, la religion acceptée d'Allah, c'est l'Islam. Ceux auxquels le Livre a été apporté ne se sont disputés, par agressivité entre eux, qu'après avoir reçu la science. Et quiconque ne croit pas aux signes d'Allah... alors Allah est prompt à demander compte"
    },
    {
      "key": 313,
      "id": "3-20",
      "translation": "S'ils te contredisent, dis leur: «Je me suis entièrement soumis à Allah, moi et ceux qui m'ont suivi». Et dis à ceux à qui le Livre a été donné, ainsi qu'aux illettrés: «Avez-vous embrassé l'Islam?» S'ils embrassent l'Islam, ils seront bien guidés. Mais; s'ils tournent le dos... Ton devoir n'est que la transmission (du message). Allah, sur [Ses] serviteurs est Clairvoyant"
    },
    {
      "key": 314,
      "id": "3-21",
      "translation": "Ceux qui ne croient pas aux signes d'Allah, tuent sans droit les prophètes et tuent les gens qui commandent la justice, annonce-leur un châtiment douloureux"
    },
    {
      "key": 315,
      "id": "3-22",
      "translation": "Ce sont eux dont les œuvres sont devenues vaines, ici-bas comme dans l'au-delà. Et pour eux, pas de secoureurs"
    },
    {
      "key": 316,
      "id": "3-23",
      "translation": "N'as-tu pas vu comment agissent ceux qui ont reçu une part du Livre, et qui sont maintenant invités au Livre d'Allah pour trancher leurs différends; comment un groupe des leurs tourne le dos et s'esquive"
    },
    {
      "key": 317,
      "id": "3-24",
      "translation": "C'est parce qu'ils disent: «Le Feu ne nous touchera que pour un nombre de jours déterminés.» Et leurs mensonges les trompent en religion"
    },
    {
      "key": 318,
      "id": "3-25",
      "translation": "Eh bien comment seront-ils, quand Nous les aurons rassemblés, en un jour sur quoi il n'y a point de doute, et que chaque âme sera pleinement rétribuée selon ce qu'elle aura acquis? Et ils ne seront point lésés"
    },
    {
      "key": 319,
      "id": "3-26",
      "translation": "Dis: «O Allah, Maître de l'autorité absolue. Tu donnes l'autorité à qui Tu veux, et Tu arraches l'autorité à qui Tu veux; et Tu donnes la puissance à qui Tu veux, et Tu humilies qui Tu veux. Le bien est en Ta main et Tu es Omnipotent"
    },
    {
      "key": 320,
      "id": "3-27",
      "translation": "Tu fais pénétrer la nuit dans le jour, et Tu fais pénétrer le jour dans la nuit, et Tu fais sortir le vivant du mort, et Tu fais sortir le mort du vivant. Et Tu accordes attribution à qui Tu veux, sans compter»"
    },
    {
      "key": 321,
      "id": "3-28",
      "translation": "Que les croyants ne prennent pas, pour alliés, des infidèles, au lieu de croyants. Quiconque le fait contredit la religion d'Allah, à moins que vous ne cherchiez à vous protéger d'eux. Allah vous met en garde à l'égard de Lui-même. Et c'est à Allah le retour"
    },
    {
      "key": 322,
      "id": "3-29",
      "translation": "Dis: «Que vous cachiez ce qui est dans vos poitrines ou bien vous le divulguiez, Allah le sait. Il connaît tout ce qui est dans les cieux et sur la terre. Et Allah est Omnipotent"
    },
    {
      "key": 323,
      "id": "3-30",
      "translation": "Le jour où chaque âme se trouvera confrontée avec ce qu'elle aura fait de bien et ce qu'elle aura fait de mal; elle souhaitera qu'il y ait entre elle et ce mal une longue distance! Allah vous met en garde à l'égard de Lui-même. Allah est Compatissant envers [Ses] serviteurs"
    },
    {
      "key": 324,
      "id": "3-31",
      "translation": "Dis: «Si vous aimez vraiment Allah, suivez-moi, Allah vous aimera alors et vous pardonnera vos péchés. Allah est Pardonneur et Miséricordieux"
    },
    {
      "key": 325,
      "id": "3-32",
      "translation": "Dis: «Obéissez à Allah et au Messager. Et si vous tournez le dos... alors Allah n'aime pas les infidèles"
    },
    {
      "key": 326,
      "id": "3-33",
      "translation": "Certes, Allah a élu Adam, Noé, la famille d'Abraham et la famille d''Imran au-dessus de tout le monde"
    },
    {
      "key": 327,
      "id": "3-34",
      "translation": "En tant que descendants les uns des autres, et Allah est Audient et Omniscient"
    },
    {
      "key": 328,
      "id": "3-35",
      "translation": "(Rappelle-toi) quand la femme de 'Imran dit: «Seigneur, je T'ai voué en toute exclusivité ce qui est dans mon ventre. Accepte-le donc, de moi. C'est Toi certes l' Audient et l'Omniscient"
    },
    {
      "key": 329,
      "id": "3-36",
      "translation": "Puis, lorsqu'elle en eut accouché, elle dit: «Seigneur, voilà que j'ai accouché d'une fille» or Allah savait mieux ce dont elle avait accouché! Le garçon n'est pas comme la fille. «Je l'ai nommée Marie, et je la place, ainsi que sa descendance, sous Ta protection contre le Diable, le banni»"
    },
    {
      "key": 330,
      "id": "3-37",
      "translation": "Son Seigneur l'agréa alors du bon agrément, la fit croître en belle croissance. Et Il en confia la garde à Zacharie. Chaque fois que celui-ci entrait auprès d'elle dans le Sanctuaire, il trouvait près d'elle de la nourriture. Il dit: «O Marie, d'où te vient cette nourriture?» - Elle dit: «Cela me vient d'Allah». Il donne certes la nourriture à qui Il veut sans compter"
    },
    {
      "key": 331,
      "id": "3-38",
      "translation": "Alors, Zacharie pria son Seigneur, et dit: «O mon Seigneur, donne-moi, venant de Toi, une excellente descendance. Car Tu es Celui qui entend bien la prière»"
    },
    {
      "key": 332,
      "id": "3-39",
      "translation": "Alors, les Anges l'appelèrent pendant que, debout, il priait dans le Sanctuaire: «Voilà qu'Allah t'annonce la naissance de Yahya, confirmateur d'une parole d'Allah. Il sera un chef, un chaste, un prophète et du nombre des gens de bien»"
    },
    {
      "key": 333,
      "id": "3-40",
      "translation": "Il dit: «O mon Seigneur, comment aurais-je un garçon maintenant que la vieillesse m'a atteint et que ma femme est stérile?» Allah dit: «Comme cela!», Allah fait ce qu'Il veut"
    },
    {
      "key": 334,
      "id": "3-41",
      "translation": "«Seigneur, dit Zacharie, donne-moi un signe.» - «Ton signe, dit Allah, c'est que pendant trois jours tu ne pourras parler aux gens que par geste. Invoque beaucoup Ton Seigneur; et, glorifie-Le, en fin et en début de journée.»"
    },
    {
      "key": 335,
      "id": "3-42",
      "translation": "(Rappelle-toi) quand les Anges dirent: «O Marie, certes Allah t'a élue et purifiée; et Il t'a élue au-dessus des femmes des mondes"
    },
    {
      "key": 336,
      "id": "3-43",
      "translation": "«O Marie, obéis à Ton Seigneur, prosterne-toi, et incline-toi avec ceux qui s'inclinent»"
    },
    {
      "key": 337,
      "id": "3-44",
      "translation": "Ce sont là des nouvelles de l'Inconnaissable que Nous te révélons. Car tu n'étais pas là lorsqu'ils jetaient leurs calames pour décider qui se chargerait de Marie! Tu n'étais pas là non plus lorsqu'ils se disputaient"
    },
    {
      "key": 338,
      "id": "3-45",
      "translation": "(Rappelle-toi) quand les Anges dirent: «O Marie, voilà qu'Allah t'annonce une parole de Sa part: son nom sera «Al-Masîh», «'Issâ», fils de Marie, illustre ici-bas comme dans l'au-delà, et l'un des rapprochés d'Allah»"
    },
    {
      "key": 339,
      "id": "3-46",
      "translation": "Il parlera aux gens, dans le berceau et en son âge mûr et il sera du nombre des gens de bien»"
    },
    {
      "key": 340,
      "id": "3-47",
      "translation": "Elle dit: «Seigneur! Comment aurais-je un enfant, alors qu'aucun homme ne m'a touchée?» - «C'est ainsi!» dit-Il. Allah crée ce qu'Il veut. Quand Il décide d'une chose, Il lui dit seulement: «Sois» et elle est aussitôt"
    },
    {
      "key": 341,
      "id": "3-48",
      "translation": "Et (Allah) lui enseignera l'écriture, la sagesse, la Thora et l'Evangile"
    },
    {
      "key": 342,
      "id": "3-49",
      "translation": "et Il sera le messager aux enfants d'Israël, [et leur dira]: «En vérité, je viens à vous avec un signe de la part de votre Seigneur. Pour vous, je forme de la glaise comme la figure d'un oiseau, puis je souffle dedans: et, par la permission d'Allah, cela devient un oiseau. Et je guéris l'aveugle-né et le lépreux, et je ressuscite les morts, par la permission d'Allah. Et je vous apprends ce que vous mangez et ce que vous amassez dans vos maisons. Voilà bien là un signe, pour vous, si vous êtes croyants"
    },
    {
      "key": 343,
      "id": "3-50",
      "translation": "Et je confirme ce qu'il y a dans la Thora révélée avant moi, et je vous rends licite une partie de ce qui vous était interdit. Et j'ai certes apporté un signe de votre Seigneur. Craignez Allah donc, et obéissez-moi"
    },
    {
      "key": 344,
      "id": "3-51",
      "translation": "Allah est mon Seigneur et votre Seigneur. Adorez-Le donc: voilà le chemin droit.»"
    },
    {
      "key": 345,
      "id": "3-52",
      "translation": "Puis, quand Jésus ressentit de l'incrédulité, de leur part, il dit: «Qui sont mes alliés dans la voie d'Allah?» Les apôtres dirent: «Nous sommes les alliés d'Allah. Nous croyons en Allah. Et sois témoin que nous lui sommes soumis"
    },
    {
      "key": 346,
      "id": "3-53",
      "translation": "Seigneur! Nous avons cru à ce que Tu as fait descendre et suivi le messager. Inscris-nous donc parmi ceux qui témoignent»"
    },
    {
      "key": 347,
      "id": "3-54",
      "translation": "Et ils [les autres] se mirent à comploter. Allah a fait échouer leur complot. Et c'est Allah qui sait le mieux leur machination"
    },
    {
      "key": 348,
      "id": "3-55",
      "translation": "(Rappelle-toi) quand Allah dit: «O Jésus, certes, Je vais mettre fin à ta vie terrestre t'élever vers Moi, te débarrasser de ceux qui n'ont pas cru et mettre jusqu'au Jour de la Résurrection, ceux qui te suivent au-dessus de ceux qui ne croient pas. Puis, c'est vers Moi que sera votre retour, et Je jugerai, entre vous, ce sur quoi vous vous opposiez"
    },
    {
      "key": 349,
      "id": "3-56",
      "translation": "Quant à ceux qui n'ont pas cru, Je les châtierai d'un dur châtiment, ici-bas tout comme dans l'au-delà; et pour eux, pas de secoureurs"
    },
    {
      "key": 350,
      "id": "3-57",
      "translation": "Et quant à ceux qui ont la foi et font de bonnes œuvres, Il leur donnera leurs récompenses. Et Allah n'aime pas les injustes"
    },
    {
      "key": 351,
      "id": "3-58",
      "translation": "Voilà ce que Nous te récitons des versets et de la révélation précise"
    },
    {
      "key": 352,
      "id": "3-59",
      "translation": "Pour Allah, Jésus est comme Adam qu'Il créa de poussière, puis Il lui dit: «Sois»: et il fut"
    },
    {
      "key": 353,
      "id": "3-60",
      "translation": "La vérité vient de ton Seigneur. Ne sois donc pas du nombre des sceptiques"
    },
    {
      "key": 354,
      "id": "3-61",
      "translation": "A ceux qui te contredisent à son propos, maintenant que tu en es bien informé, tu n'as qu'à dire: «Venez, appelons nos fils et les vôtres, nos femmes et les vôtres, nos propres personnes et les vôtres, puis proférons exécration réciproque en appelant la malédiction d'Allah sur les menteurs"
    },
    {
      "key": 355,
      "id": "3-62",
      "translation": "Voilà, certes, le récit véridique. Et il n'y a pas de divinité à part Allah. En vérité, c'est Allah qui est le Puissant, le Sage"
    },
    {
      "key": 356,
      "id": "3-63",
      "translation": "Si donc ils tournent le dos... alors Allah connaît bien les semeurs de corruption"
    },
    {
      "key": 357,
      "id": "3-64",
      "translation": "Dis: «O gens du Livre, venez à une parole commune entre nous et vous: que nous n'adorions qu'Allah, sans rien Lui associer, et que nous ne prenions point les uns les autres pour seigneurs en dehors d'Allah». Puis, s'ils tournent le dos, dites: «Soyez témoins que nous, nous sommes soumis»"
    },
    {
      "key": 358,
      "id": "3-65",
      "translation": "O gens du Livre, pourquoi disputez-vous au sujet d'Abraham, alors que la Thora et l'Evangile ne sont descendus qu'après lui? Ne raisonnez-vous donc pas"
    },
    {
      "key": 359,
      "id": "3-66",
      "translation": "Vous avez bel et bien disputé à propos d'une chose dont vous avez connaissance. Mais pourquoi disputez-vous des choses dont vous n'avez pas connaissance? Or Allah sait, tandis que vous ne savez pas"
    },
    {
      "key": 360,
      "id": "3-67",
      "translation": "Abraham n'était ni Juif ni Chrétien. Il était entièrement soumis à Allah (Musulman). Et il n'était point du nombre des Associateurs"
    },
    {
      "key": 361,
      "id": "3-68",
      "translation": "Certes les hommes les plus dignes de se réclamer d'Abraham, sont ceux qui l'ont suivi, ainsi que ce Prophète-ci, et ceux qui ont la foi. Et Allah est l'allié des croyants"
    },
    {
      "key": 362,
      "id": "3-69",
      "translation": "Une partie des gens du Livre aurait bien voulu vous égarer. Or ils n'égarent qu'eux-mêmes; et ils n'en sont pas conscients"
    },
    {
      "key": 363,
      "id": "3-70",
      "translation": "O gens du Livre, pourquoi ne croyez vous pas aux versets d'Allah (le Coran), cependant que vous en êtes témoins"
    },
    {
      "key": 364,
      "id": "3-71",
      "translation": "O gens du Livre, pourquoi mêlez-vous le faux au vrai et cachez-vous sciemment la vérité"
    },
    {
      "key": 365,
      "id": "3-72",
      "translation": "Ainsi dit une partie des gens du Livre: «Au début du jour, croyez à ce qui a été révélé aux Musulmans, mais, à la fin du jour, rejetez-le, afin qu'ils retournent (à leur ancienne religion)"
    },
    {
      "key": 366,
      "id": "3-73",
      "translation": "[Et les gens du Livre disent à leurs coreligionnaires]: «Ne croyez que ceux qui suivent votre religion...» Dis: «La vraie direction est la direction d'Allah» - [et ils disent encore: Vous ne devez ni approuver ni reconnaître] que quelqu'un d'autre que vous puisse recevoir comme ce que vous avez reçu de sorte qu'ils (les musulmans) ne puissent argumenter contre vous auprès de votre Seigneur. Dis-[leur]: En vérité, la grâce est en la main d'Allah. Il la donne à qui Il veut. La grâce d'Allah est immense et Il est Omniscient"
    },
    {
      "key": 367,
      "id": "3-74",
      "translation": "Il réserve à qui Il veut sa miséricorde. Et Allah est Détenteur d'une grâce immense"
    },
    {
      "key": 368,
      "id": "3-75",
      "translation": "Et parmi les gens du Livre, il y en a qui, si tu lui confies un qintâr, te le rend. Mais il y en a aussi qui, si tu lui confies un dinâr, ne te le rendra que si tu l'y contrains sans relâche. Tout cela parce qu'ils disent: «Ces (arabes) qui n'ont pas de livre n'ont aucun chemin pour nous contraindre.» Ils profèrent des mensonges contre Allah alors qu'ils savent"
    },
    {
      "key": 369,
      "id": "3-76",
      "translation": "Au contraire, quiconque remplit sa promesse et craint Allah... Allah aime les pieux"
    },
    {
      "key": 370,
      "id": "3-77",
      "translation": "Ceux qui vendent à vil prix leur engagement avec Allah ainsi que leurs serments n'auront aucune part dans l'au-delà, et Allah ne leur parlera pas, ni les regardera, au Jour de la Résurrection, ni ne les purifiera; et ils auront un châtiment douloureux"
    },
    {
      "key": 371,
      "id": "3-78",
      "translation": "Et il y a parmi eux certains qui roulent leurs langues en lisant le Livre pour vous faire croire que cela provient du Livre, alors qu'il n'est point du Livre; et ils disent: «Ceci vient d'Allah, alors qu'il ne vient point d'Allah. Ils disent sciemment des mensonges contre Allah"
    },
    {
      "key": 372,
      "id": "3-79",
      "translation": "Il ne conviendrait pas à un être humain à qui Allah a donné le Livre, la Compréhension et la Prophétie, de dire ensuite aux gens: «Soyez mes adorateurs, à l'exclusion d'Allah; mais au contraire, [il devra dire]: «Devenez des savants, obéissant au Seigneur, puisque vous enseignez le Livre et vous l'étudiez»"
    },
    {
      "key": 373,
      "id": "3-80",
      "translation": "Et il ne va pas vous commander de prendre pour seigneurs anges et prophètes. Vous commanderait-il de rejeter la foi, vous qui êtes Musulmans"
    },
    {
      "key": 374,
      "id": "3-81",
      "translation": "Et lorsqu'Allah prit cet engagement des prophètes: «Chaque fois que Je vous accorderai un Livre et de la Sagesse, et qu'ensuite un messager vous viendra confirmer ce qui est avec vous, vous devez croire en lui, et vous devrez lui porter secours.» Il leur dit: «Consentez-vous et acceptez-vous Mon pacte à cette condition?» - «Nous consentons», dirent-ils. «Soyez-en donc témoins, dit Allah. Et Me voici, avec vous, parmi les témoins"
    },
    {
      "key": 375,
      "id": "3-82",
      "translation": "Quiconque ensuite tournera le dos... alors ce sont eux qui seront les pervers»"
    },
    {
      "key": 376,
      "id": "3-83",
      "translation": "Désirent-ils une autre religion que celle d'Allah, alors que se soumet à Lui, bon gré, mal gré, tout ce qui existe dans les cieux et sur la terre, et que c'est vers Lui qu'ils seront ramenés"
    },
    {
      "key": 377,
      "id": "3-84",
      "translation": "Dis: «Nous croyons en Allah, à ce qu'on a fait descendre sur nous, à ce qu'on a fait descendre sur Abraham, Ismaël, Isaac, Jacob et les Tribus, et à ce qui a été apporté à Moïse, à Jésus et aux prophètes, de la part de leur Seigneur: nous ne faisons aucune différence entre eux; et c'est à Lui que nous sommes Soumis»"
    },
    {
      "key": 378,
      "id": "3-85",
      "translation": "Et quiconque désire une religion autre que l'Islam, ne sera point agréé, et il sera, dans l'au-delà, parmi les perdants"
    },
    {
      "key": 379,
      "id": "3-86",
      "translation": "Comment Allah guiderait-Il des gens qui n'ont plus la foi après avoir cru et témoigné que le Messager est véridique, et après que les preuves leur sont venues? Allah ne guide pas les gens injustes"
    },
    {
      "key": 380,
      "id": "3-87",
      "translation": "Ceux là, leur rétribution sera qu'ils auront sur eux la malédiction d'Allah, des Anges et de tous les êtres humains"
    },
    {
      "key": 381,
      "id": "3-88",
      "translation": "Ils y demeureront éternellement. Le châtiment ne leur sera pas allégé, et ils n'auront aucun répit"
    },
    {
      "key": 382,
      "id": "3-89",
      "translation": "excepté ceux qui par la suite se repentiront et se réformeront: car Allah est certes Pardonneur et Miséricordieux"
    },
    {
      "key": 383,
      "id": "3-90",
      "translation": "En vérité, ceux qui ne croient plus après avoir eu la foi, et laissent augmenter encore leur mécréance, leur repentir ne sera jamais accepté. Ceux-là sont vraiment les égarés"
    },
    {
      "key": 384,
      "id": "3-91",
      "translation": "Ceux qui ne croient pas et qui meurent mécréants, il ne sera jamais accepté, d'aucun d'eux de se racheter même si pour cela il (donnait) le contenu, en or, de la terre. Ils auront un châtiment douloureux, et ils n'auront point de secoureurs"
    },
    {
      "key": 385,
      "id": "3-92",
      "translation": "Vous n'atteindrez la (vraie) piété, que si vous faites largesses de ce que vous chérissez. Tout ce dont vous faites largesses, Allah le sait certainement bien"
    },
    {
      "key": 386,
      "id": "3-93",
      "translation": "Toute nourriture était licite aux enfants d'Israël, sauf celle qu'Israël lui-même s'interdit avant que ne descendît la Thora. Dis-[leur]: «Apportez la Thora et lisez-la, si ce que vous dites est vrai!»"
    },
    {
      "key": 387,
      "id": "3-94",
      "translation": "Donc, quiconque, après cela, invente des mensonges contre Allah... ceux-là sont, donc, les vrais injustes"
    },
    {
      "key": 388,
      "id": "3-95",
      "translation": "Dis: «C'est Allah qui dit la vérité. Suivez donc la religion d'Abraham, Musulman droit. Et il n'était point des associateurs»"
    },
    {
      "key": 389,
      "id": "3-96",
      "translation": "La première Maison qui ait été édifiée pour les gens, c'est bien celle de Bakka (la Mecque) bénie et une bonne direction pour l'univers"
    },
    {
      "key": 390,
      "id": "3-97",
      "translation": "Là sont des signes évidents, parmi lesquels l'endroit où Abraham s'est tenu debout; et quiconque y entre est en sécurité. Et c'est un devoir envers Allah pour les gens qui ont les moyens, d'aller faire le pèlerinage de la Maison. Et quiconque ne croit pas... Allah Se passe largement des mondes"
    },
    {
      "key": 391,
      "id": "3-98",
      "translation": "Dis: «O gens du Livre, pourquoi ne croyez-vous pas aux versets d'Allah (al-Qur'ân), alors qu'Allah est témoin de ce que vous faites?»"
    },
    {
      "key": 392,
      "id": "3-99",
      "translation": "Dis: «O gens du Livre, pourquoi obstruez-vous la voie d'Allah à celui qui a la foi, et pourquoi voulez-vous rendre cette voie tortueuse, alors que vous êtes témoins de la vérité!» Et Allah n'est pas inattentif à ce que vous faites"
    },
    {
      "key": 393,
      "id": "3-100",
      "translation": "O les croyants! Si vous obéissez à un groupe de ceux auxquels on a donné le Livre, il vous rendra mécréants après que vous ayez eu la foi"
    },
    {
      "key": 394,
      "id": "3-101",
      "translation": "Et comment pouvez-vous ne pas croire, alors que les versets d'Allah vous sont récités, et qu'au milieu de vous se tient Son messager? Quiconque s'attache fortement à Allah, il est certes guidé vers un droit chemin"
    },
    {
      "key": 395,
      "id": "3-102",
      "translation": "O les croyants! Craignez Allah comme Il doit être craint. Et ne mourez qu'en pleine soumission"
    },
    {
      "key": 396,
      "id": "3-103",
      "translation": "Et cramponnez-vous tous ensemble au «Habl» (câble) d'Allah et ne soyez pas divisés; et rappelez-vous le bienfait d'Allah sur vous: lorsque vous étiez ennemis, c'est Lui qui réconcilia vos cœurs. Puis, par Son bienfait, vous êtes devenus frères. Et alors que vous étiez au bord d'un abîme de Feu, c'est Lui qui vous en a sauvés. Ainsi Allah vous montre Ses signes afin que vous soyez bien guidés"
    },
    {
      "key": 397,
      "id": "3-104",
      "translation": "Que soit issue de vous une communauté qui appelle au bien, ordonne le convenable, et interdit le blâmable. Car ce seront eux qui réussiront"
    },
    {
      "key": 398,
      "id": "3-105",
      "translation": "Et ne soyez pas comme ceux qui se sont divisés et se sont mis à disputer, après que les preuves leur furent venues, et ceux-là auront un énorme châtiment"
    },
    {
      "key": 399,
      "id": "3-106",
      "translation": "Au jour où certains visages s'éclaireront, et que d'autres s'assombriront. A ceux dont les visages seront assombris (il sera dit): «avez-vous mécru après avoir eu la foi?» Eh bien, goûtez au châtiment, pour avoir renié la foi"
    },
    {
      "key": 400,
      "id": "3-107",
      "translation": "Et quant à ceux dont les visages s'éclaireront, ils seront dans la miséricorde d'Allah, où ils demeureront éternellement"
    },
    {
      "key": 401,
      "id": "3-108",
      "translation": "Tels sont les versets d'Allah; Nous te  les récitons avec vérité. Et Allah ne veut point léser les mondes"
    },
    {
      "key": 402,
      "id": "3-109",
      "translation": "A Allah appartient tout ce qui est dans les cieux et sur la terre. Et c'est vers Allah que toute chose sera ramenée"
    },
    {
      "key": 403,
      "id": "3-110",
      "translation": "Vous êtes la meilleure communauté, qu'on ait fait surgir pour les hommes. Vous ordonnez le convenable, interdisez le blâmable et croyez à Allah. Si les gens du Livre croyaient, ce serait meilleur pour eux, il y en a qui ont la foi, mais la plupart d'entre eux sont des pervers"
    },
    {
      "key": 404,
      "id": "3-111",
      "translation": "Ils ne sauront jamais vous causer de grand mal, seulement une nuisance (par la langue); et s'ils vous combattent, ils vous tourneront le dos, et ils n'auront alors point de secours"
    },
    {
      "key": 405,
      "id": "3-112",
      "translation": "Où qu'ils se trouvent, ils sont frappés d'avilissement, à moins d'un secours providentiel d'Allah ou d'un pacte conclu avec les hommes. Ils ont encouru la colère d'Allah, et les voilà frappés de malheur, pour n'avoir pas cru aux signes d'Allah, et assassiné injustement les prophètes, et aussi pour avoir désobéi et transgressé"
    },
    {
      "key": 406,
      "id": "3-113",
      "translation": "Mais ils ne sont pas tous pareils. Il est, parmi les gens du Livre, une communauté droite qui, aux heures de la nuit, récite les versets d'Allah en se prosternant"
    },
    {
      "key": 407,
      "id": "3-114",
      "translation": "Ils croient en Allah et au Jour dernier, ordonnent le convenable, interdisent le blâmable et concourent aux bonnes œuvres. Ceux-là sont parmi les gens de bien"
    },
    {
      "key": 408,
      "id": "3-115",
      "translation": "Et quelque bien qu'ils fassent, il ne leur sera pas dénié. Car Allah connaît bien les pieux"
    },
    {
      "key": 409,
      "id": "3-116",
      "translation": "Quant à ceux qui ne croient pas, ni leurs biens, ni leurs enfants ne pourront jamais leur servir contre la punition d'Allah. Et ce sont les gens du Feu: ils y demeureront éternellement"
    },
    {
      "key": 410,
      "id": "3-117",
      "translation": "Ce qu'ils dépensent dans la vie présente ressemble à un vent glacial qui s'abat sur un champ appartenant à des gens qui se sont lésés eux-mêmes, et le détruit. Car ce n'est pas Allah qui leur cause du mal, mais ils se font du mal à eux-mêmes"
    },
    {
      "key": 411,
      "id": "3-118",
      "translation": "O les croyants, ne prenez pas de confidents en dehors de vous-mêmes: ils ne failliront pas à vous bouleverser. Ils souhaiteraient que vous soyez en difficulté. La haine certes s'est manifestée dans leurs bouches, mais ce que leurs poitrines cachent est encore plus énorme. Voilà que Nous vous exposons les signes. Si vous pouviez raisonner"
    },
    {
      "key": 412,
      "id": "3-119",
      "translation": "Vous, (Musulmans) vous les aimez, alors qu'ils ne vous aiment pas; et vous avez foi dans le Livre tout entier. Et lorsqu'ils vous rencontrent, ils disent: «Nous croyons» et une fois seuls, de rage contre vous, ils se mordent les bouts des doigts. Dis: «mourez de votre rage». En vérité, Allah connaît fort bien le contenu des cœurs"
    },
    {
      "key": 413,
      "id": "3-120",
      "translation": "Qu'un bien vous touche, ils s'en affligent. Qu'un mal vous atteigne, ils s'en réjouissent. Mais si vous êtes endurants et pieux, leur manigance ne vous causera aucun mal. Allah connaît parfaitement tout ce qu'ils font"
    },
    {
      "key": 414,
      "id": "3-121",
      "translation": "Lorsqu'un matin, tu  quittas ta famille, pour assigner aux croyants les postes de combat et Allah est Audient et Omniscient"
    },
    {
      "key": 415,
      "id": "3-122",
      "translation": "Quand deux de vos groupes songèrent à fléchir! Alors qu'Allah est leur allié à tous deux! Car, c'est en Allah que les croyants doivent placer leur confiance"
    },
    {
      "key": 416,
      "id": "3-123",
      "translation": "Allah vous a donné la victoire, à Badr, alors que vous étiez humiliés. Craignez Allah donc. Afin que vous soyez reconnaissants"
    },
    {
      "key": 417,
      "id": "3-124",
      "translation": "(Allah vous a bien donné la victoire) lorsque tu disais aux croyants; «Ne vous suffit-il pas que votre Seigneur vous fasse descendre en aide trois milliers d'Anges?»"
    },
    {
      "key": 418,
      "id": "3-125",
      "translation": "Mais oui! Si vous êtes endurants et pieux, et qu'ils [les ennemis] vous assaillent immédiatement, votre Seigneur vous enverra en renfort cinq mille Anges marqués distinctement"
    },
    {
      "key": 419,
      "id": "3-126",
      "translation": "Et Allah ne le fit que (pour vous annoncer) une bonne nouvelle, et pour que vos cœurs s'en rassurent. La victoire ne peut venir que d'Allah, le Puissant, le Sage"
    },
    {
      "key": 420,
      "id": "3-127",
      "translation": "pour anéantir une partie des mécréants ou pour les humilier (par la défaite) et qu'ils en retournent donc déçus"
    },
    {
      "key": 421,
      "id": "3-128",
      "translation": "Tu n'as  aucune part dans l'ordre (divin) - qu'Il (Allah) accepte leur repentir (en embrassant l'Islam) ou qu'Il les châtie, car ils sont bien des injustes"
    },
    {
      "key": 422,
      "id": "3-129",
      "translation": "A Allah appartient tout ce qui est dans les cieux et sur la terre. Il pardonne à qui Il veut, et Il châtie qui Il veut... Et Allah est Pardonneur et Miséricordieux"
    },
    {
      "key": 423,
      "id": "3-130",
      "translation": "O les croyants! Ne pratiquez pas l'usure en multipliant démesurément votre capital. Et craignez Allah afin que vous réussissiez"
    },
    {
      "key": 424,
      "id": "3-131",
      "translation": "Et craignez le Feu préparé pour les mécréants"
    },
    {
      "key": 425,
      "id": "3-132",
      "translation": "Et obéissez à Allah et au Messager afin qu'il vous soit fait miséricorde"
    },
    {
      "key": 426,
      "id": "3-133",
      "translation": "Et concourez au pardon de votre Seigneur, et à un Jardin (paradis) large comme les cieux et la terre, préparé pour les pieux"
    },
    {
      "key": 427,
      "id": "3-134",
      "translation": "qui dépensent dans l'aisance et dans l'adversité, qui dominent leur rage et pardonnent à autrui - car Allah aime les bienfaisants"
    },
    {
      "key": 428,
      "id": "3-135",
      "translation": "et pour ceux qui, s'ils ont commis quelque turpitude ou causé quelque préjudice à leurs propres âmes (en désobéissant à Allah), se souviennent d'Allah et demandent pardon pour leurs péchés - et qui est-ce qui pardonne les péchés sinon Allah? - et qui ne persistent pas sciemment dans le mal qu'ils ont fait"
    },
    {
      "key": 429,
      "id": "3-136",
      "translation": "Ceux-là ont pour récompense le pardon de leur Seigneur, ainsi que les Jardins sous lesquels coulent les ruisseaux, pour y demeurer éternellement. Comme est beau le salaire de ceux qui font le bien"
    },
    {
      "key": 430,
      "id": "3-137",
      "translation": "Avant vous, certes, beaucoup d'événements se sont passés. Or, parcourez la terre, et voyez ce qu'il est advenu de ceux qui traitaient (les prophètes) de menteurs"
    },
    {
      "key": 431,
      "id": "3-138",
      "translation": "Voilà un exposé pour les gens, un guide, et une exhortation pour les pieux"
    },
    {
      "key": 432,
      "id": "3-139",
      "translation": "Ne vous laissez pas battre, ne vous affligez pas alors que vous êtes les supérieurs, si vous êtes de vrais croyants"
    },
    {
      "key": 433,
      "id": "3-140",
      "translation": "Si une blessure vous atteint, pareille blessure atteint aussi l'ennemi. Ainsi faisons-Nous alterner les jours (bons et mauvais) parmi les gens, afin qu'Allah reconnaisse ceux qui ont cru, et qu'Il choisisse parmi vous des martyrs - et Allah n'aime pas les injustes"
    },
    {
      "key": 434,
      "id": "3-141",
      "translation": "et afin qu'Allah purifie ceux qui ont cru, et anéantisse les mécréants"
    },
    {
      "key": 435,
      "id": "3-142",
      "translation": "Comptez-vous entrer au Paradis sans qu'Allah ne distingue parmi vous ceux qui luttent et qui sont endurants"
    },
    {
      "key": 436,
      "id": "3-143",
      "translation": "Bien sûr, vous souhaitiez la mort avant de la rencontrer. Or vous l'avez vue, certes, tandis que vous regardiez"
    },
    {
      "key": 437,
      "id": "3-144",
      "translation": "Muhammad n'est qu'un messager - des messagers avant lui sont passés -. S'il mourait, donc, ou s'il était tué, retourneriez-vous sur vos talons? Quiconque retourne sur ses talons ne nuira en rien à Allah; et Allah récompensera bientôt les reconnaissants"
    },
    {
      "key": 438,
      "id": "3-145",
      "translation": "Personne ne peut mourir que par la permission d'Allah, et au moment prédéterminé. Quiconque veut la récompense d'ici-bas, Nous lui en donnons. Quiconque veut la récompense de l'au-delà, Nous lui en donnons, et Nous récompenserons bientôt les reconnaissants"
    },
    {
      "key": 439,
      "id": "3-146",
      "translation": "Combien de prophètes ont combattu, en compagnie de beaucoup de disciples, ceux-ci ne fléchirent pas à cause de ce qui les atteignit dans le sentier d'Allah. Ils ne faiblirent pas et ils ne cédèrent point. Et Allah aime les endurants"
    },
    {
      "key": 440,
      "id": "3-147",
      "translation": "Et ils n'eurent que cette parole: «Seigneur, pardonne-nous nos péchés ainsi que nos excès dans nos comportements, affermis nos pas et donne-nous la victoire sur les gens mécréants»"
    },
    {
      "key": 441,
      "id": "3-148",
      "translation": "Allah, donc, leur donna la récompense d'ici-bas, ainsi que la belle récompense de l'au-delà. Et Allah aime les gens bienfaisants"
    },
    {
      "key": 442,
      "id": "3-149",
      "translation": "O les croyants! Si vous obéissez à ceux qui ne croient pas, ils vous feront retourner en arrière. Et vous reviendrez perdants"
    },
    {
      "key": 443,
      "id": "3-150",
      "translation": "Mais c'est Allah votre Maître. Il est le meilleur des secoureurs"
    },
    {
      "key": 444,
      "id": "3-151",
      "translation": "Nous allons jeter l'effroi dans les cœurs des mécréants. Car ils ont associé à Allah (des idoles) sans aucune preuve descendue de Sa part. Le Feu sera leur refuge. Quel mauvais séjour, que celui des injustes"
    },
    {
      "key": 445,
      "id": "3-152",
      "translation": "Et certes, Allah a tenu Sa promesse envers vous, quand par Sa permission vous les tuiez sans relâche, jusqu'au moment où vous avez fléchi, où vous vous êtes disputés à propos de l'ordre donné, et vous avez désobéi après qu'Il vous eut montré (la victoire) que vous aimez! Il en était parmi vous qui désiraient la vie d'ici-bas et il en était parmi vous qui désiraient l'au-delà. Puis Il vous a fait reculer devant eux, afin de vous éprouver. Et certes Il vous a pardonné. Et Allah est Détenteur de la grâce envers les croyants"
    },
    {
      "key": 446,
      "id": "3-153",
      "translation": "(Rappelez-vous) quand vous fuyiez sans vous retourner vers personne, cependant que, derrière vous, le Messager vous appelait. Alors Il vous infligea angoisse sur angoisse, afin que vous n'ayez pas de chagrin pour ce qui vous a échappé ni pour les revers que vous avez subis. Et Allah est Parfaitement Connaisseur de ce que vous faites"
    },
    {
      "key": 447,
      "id": "3-154",
      "translation": "Puis Il fit descendre sur vous, après l'angoisse, la tranquillité, un sommeil qui enveloppa une partie d'entre vous, tandis qu'une autre partie était soucieuse pour elle-même et avait des pensées sur Allah non conformes à la vérité, des pensées dignes de l'époque de l'Ignorance. - Ils disaient: «Est-ce que nous avons une part dans cette affaire?» Dis: «L'affaire toute entière est à Allah.» Ce qu'ils ne te révèlent pas, ils le cachent en eux-mêmes: «Si nous avions eu un choix quelconque dans cette affaire, disent-ils, Nous n'aurions pas été tués ici.» Dis: «Eussiez-vous été dans vos maisons, ceux pour qui la mort était décrétée seraient sortis pour l'endroit où la mort les attendait. Ceci afin qu'Allah éprouve ce que vous avez dans vos poitrines, et qu'Il purifie ce que vous avez dans vos cœurs. Et Allah connaît ce qu'il y a dans les cœurs"
    },
    {
      "key": 448,
      "id": "3-155",
      "translation": "Ceux d'entre vous qui ont tourné le dos, le jour où les deux armées se rencontrèrent, c'est seulement le Diable qui les a fait broncher, à cause d'une partie de leurs (mauvaises) actions. Mais, certes, Allah leur a pardonné. Car vraiment Allah est Pardonneur et Indulgent"
    },
    {
      "key": 449,
      "id": "3-156",
      "translation": "O les croyants! Ne soyez pas comme ces mécréants qui dirent à propos de leurs frères partis en voyage ou pour combattre: «S'ils étaient chez nous, ils ne seraient pas morts, et ils n'auraient pas été tués.» Allah en fit un sujet de regret dans leurs cœurs. C'est Allah qui donne la vie et la mort. Et Allah observe bien ce que vous faites"
    },
    {
      "key": 450,
      "id": "3-157",
      "translation": "Et si vous êtes tués dans le sentier d'Allah ou si vous mourez, un pardon de la part d'Allah et une miséricorde valent mieux que ce qu'ils amassent"
    },
    {
      "key": 451,
      "id": "3-158",
      "translation": "Que vous mouriez ou que vous soyez tués, c'est vers Allah que vous serez rassemblés"
    },
    {
      "key": 452,
      "id": "3-159",
      "translation": "C'est par quelque miséricorde de la part d'Allah que tu  as été si doux envers eux! Mais si tu étais rude, au cœur dur, ils se seraient enfuis de ton entourage. Pardonne-leur donc, et implore pour eux le pardon (d'Allah). Et consulte-les à propos des affaires; puis une fois que tu t'es décidé, confie-toi donc à Allah, Allah aime, en vérité, ceux qui Lui font confiance"
    },
    {
      "key": 453,
      "id": "3-160",
      "translation": "Si Allah vous donne Son secours, nul ne peut vous vaincre. S'Il vous abandonne, qui donc après Lui vous donnera secours? C'est à Allah que les croyants doivent faire confiance"
    },
    {
      "key": 454,
      "id": "3-161",
      "translation": "Un prophète n'est pas quelqu'un à s'approprier du butin. Quiconque s'en approprie, viendra avec ce qu'il se sera approprié le Jour de la Résurrection. Alors, à chaque individu on rétribuera pleinement ce qu'il aura acquis. Et ils ne seront point lésés"
    },
    {
      "key": 455,
      "id": "3-162",
      "translation": "Est-ce que celui qui se conforme à l'agrément d'Allah ressemble à celui qui encourt le courroux d'Allah? Son refuge sera l'Enfer; et quelle mauvaise destination"
    },
    {
      "key": 456,
      "id": "3-163",
      "translation": "Ils ont des grades (différents) auprès d'Allah et Allah observe bien ce qu'ils font"
    },
    {
      "key": 457,
      "id": "3-164",
      "translation": "Allah a très certainement fait une faveur aux croyants lorsqu'Il a envoyé chez eux un messager de parmi eux-mêmes, qui leur récite Ses versets, les purifie et leur enseigne le Livre et la Sagesse, bien qu'ils fussent auparavant dans un égarement évident"
    },
    {
      "key": 458,
      "id": "3-165",
      "translation": "Quoi! Quand un malheur vous atteint - mais vous en avez jadis infligé le double - vous dites: «D'où vient cela?» Réponds-leur: «Il vient de vous-mêmes». Certes Allah est Omnipotent"
    },
    {
      "key": 459,
      "id": "3-166",
      "translation": "Et tout ce que vous avez subi, le jour où les deux troupes se rencontrèrent, c'est par permission d'Allah, et afin qu'Il distingue les croyants"
    },
    {
      "key": 460,
      "id": "3-167",
      "translation": "et qu'Il distingue les hypocrites. On avait dit à ceux-ci: «Venez combattre dans le sentier d'Allah, ou repoussez [l'ennemi»], ils dirent: «Bien sûr que nous vous suivrions si nous étions sûrs qu'il y aurait une guerre.» Ils étaient, ce jour-là, plus près de la mécréance que de la foi. Ils disaient de leurs bouches ce qui n'était pas dans leurs cœurs. Et Allah sait fort bien ce qu'ils cachaient"
    },
    {
      "key": 461,
      "id": "3-168",
      "translation": "Ceux qui sont restés dans leurs foyers dirent à leurs frères: «S'ils nous avaient obéi, ils n'auraient pas été tués.» Dis: «Ecartez donc de vous la mort, si vous êtes véridiques»"
    },
    {
      "key": 462,
      "id": "3-169",
      "translation": "Ne pense pas que ceux qui ont été tués dans le sentier d'Allah, soient morts. Au contraire, ils sont vivants, auprès de leur Seigneur, bien pourvus"
    },
    {
      "key": 463,
      "id": "3-170",
      "translation": "et joyeux de la faveur qu'Allah leur a accordée, et ravis que ceux qui sont restés derrière eux et ne les ont pas encore rejoints, ne connaîtront aucune crainte et ne seront point affligés"
    },
    {
      "key": 464,
      "id": "3-171",
      "translation": "Ils sont ravis d'un bienfait d'Allah et d'une faveur, et du fait qu'Allah ne laisse pas perdre la récompense des croyants"
    },
    {
      "key": 465,
      "id": "3-172",
      "translation": "Ceux qui, quoiqu'atteints de blessure, répondirent à l'appel d'Allah et du Messager, il y aura une énorme récompense pour ceux d'entre eux qui ont agi en bien et pratiqué la piété"
    },
    {
      "key": 466,
      "id": "3-173",
      "translation": "Certes ceux auxquels l'on disait: «Les gens se sont rassemblés contre vous; craignez-les» - cela accrut leur foi - et ils dirent: «Allah nous suffit; Il est notre meilleur garant»"
    },
    {
      "key": 467,
      "id": "3-174",
      "translation": "Ils revinrent donc avec un bienfait de la part d'Allah et une grâce. Nul mal ne les toucha et ils suivirent ce qui satisfait Allah. Et Allah est Détenteur d'une grâce immense"
    },
    {
      "key": 468,
      "id": "3-175",
      "translation": "C'est le Diable qui vous fait peur de ses adhérents. N'ayez donc pas peur d'eux. Mais ayez peur de Moi, si vous êtes croyants"
    },
    {
      "key": 469,
      "id": "3-176",
      "translation": "N'aie aucun chagrin pour ceux qui se jettent rapidement dans la mécréance. En vérité, ils ne nuiront en rien à Allah. Allah tient à ne leur assigner aucune part de biens dans l'au-delà. Et pour eux il y aura un énorme châtiment"
    },
    {
      "key": 470,
      "id": "3-177",
      "translation": "Ceux qui auront troqué la croyance contre la mécréance ne nuiront en rien à Allah. Et pour eux un châtiment douloureux"
    },
    {
      "key": 471,
      "id": "3-178",
      "translation": "Que ceux qui n'ont pas cru ne comptent pas que ce délai que Nous leur accordons soit à leur avantage. Si Nous leur accordons un délai, c'est seulement pour qu'ils augmentent leurs péchés. Et pour eux un châtiment avilissant"
    },
    {
      "key": 472,
      "id": "3-179",
      "translation": "Allah n'est point tel qu'Il laisse les croyants dans l'état où vous êtes jusqu'à ce qu'Il distingue le mauvais du bon. Et Allah n'est point tel qu'Il vous dévoile l'Inconnaissable. Mais Allah choisit parmi Ses messagers qui Il veut. Croyez donc en Allah et en Ses messagers. Et si vous avez la foi et la piété, vous aurez alors une récompense énorme"
    },
    {
      "key": 473,
      "id": "3-180",
      "translation": "Que ceux qui gardent avec avarice ce qu'Allah leur donne par Sa grâce, ne comptent point cela comme bon pour eux. Au contraire, c'est mauvais pour eux: au Jour de la Résurrection, on leur attachera autour du cou ce qu'ils ont gardé avec avarice. C'est Allah qui a l'héritage des cieux et de la terre. Et Allah est Parfaitement Connaisseur de ce que vous faites"
    },
    {
      "key": 474,
      "id": "3-181",
      "translation": "Allah a certainement entendu la parole de ceux qui ont dit: «Allah est pauvre et nous sommes riches.» Nous enregistrons leur parole, ainsi que leur meurtre, sans droit, des prophètes. Et Nous leur dirons: «Goûtez au châtiment de la fournaise"
    },
    {
      "key": 475,
      "id": "3-182",
      "translation": "Cela, à cause de ce que vos mains ont accompli (antérieurement)!» Car Allah ne fait point de tort aux serviteurs"
    },
    {
      "key": 476,
      "id": "3-183",
      "translation": "Ceux-là mêmes qui ont dit: «Vraiment Allah nous a enjoint de ne pas croire en un messager tant qu'Il ne nous a pas apporté une offrande que le feu consume». - Dis: «Des messagers avant moi vous sont, certes, venus avec des preuves, et avec ce que vous avez dit [demandé]. Pourquoi donc les avez-vous tués, si vous êtes véridiques?»"
    },
    {
      "key": 477,
      "id": "3-184",
      "translation": "S'ils te  traitent de menteur, des prophètes avant toi, ont certes été traités de menteurs. Ils étaient venus avec les preuves claires, les Psaumes et le Livre lumineux"
    },
    {
      "key": 478,
      "id": "3-185",
      "translation": "Toute âme goûtera la mort. Mais c'est seulement au Jour de la Résurrection que vous recevrez votre entière rétribution. Quiconque donc est écarté du Feu et introduit au Paradis, a certes réussi. Et la vie présente n'est qu'un objet de jouissance trompeuse"
    },
    {
      "key": 479,
      "id": "3-186",
      "translation": "Certes vous serez éprouvés dans vos biens et vos personnes; et certes vous entendrez de la part de ceux à qui le Livre a été donné avant vous, et de la part des Associateurs, beaucoup de propos désagréables. Mais si vous êtes endurants et pieux... voilà bien la meilleure résolution à prendre"
    },
    {
      "key": 480,
      "id": "3-187",
      "translation": "Allah prit, de ceux auxquels le Livre était donné, cet engagement: «Exposez-le, certes, aux gens et ne le cachez pas». Mais ils l'ont jeté derrière leur dos et l'ont vendu à vil prix. Quel mauvais commerce ils ont fait"
    },
    {
      "key": 481,
      "id": "3-188",
      "translation": "Ne pense point que ceux-là qui exultent de ce qu'ils ont fait, et qui aiment qu'on les loue pour ce qu'ils n'ont pas fait, ne pense point donc, qu'ils trouvent une échappatoire au châtiment. Pour eux, il y aura un châtiment douloureux"
    },
    {
      "key": 482,
      "id": "3-189",
      "translation": "A Allah appartient le royaume des cieux et de la terre. Et Allah est Omnipotent"
    },
    {
      "key": 483,
      "id": "3-190",
      "translation": "En vérité, dans la création des cieux et de la terre, et dans l'alternance de la nuit et du jour, il y a certes des signes pour les doués d'intelligence"
    },
    {
      "key": 484,
      "id": "3-191",
      "translation": "qui, debout, assis, couchés sur leurs côtés, invoquent Allah et méditent sur la création des cieux et de la terre (disant): «Notre Seigneur! Tu n'as pas créé cela en vain. Gloire à Toi! Garde-nous du châtiment du Feu"
    },
    {
      "key": 485,
      "id": "3-192",
      "translation": "Seigneur! Quiconque Tu fais entrer dans le Feu, Tu le couvres vraiment d'ignominie. Et pour les injustes, il n'y a pas de secoureurs"
    },
    {
      "key": 486,
      "id": "3-193",
      "translation": "Seigneur! Nous avons entendu l'appel de celui qui a appelé ainsi à la foi: «Croyez en votre Seigneur» et dès lors nous avons cru. Seigneur, pardonne-nous nos péchés, efface de nous nos méfaits, et place nous, à notre mort, avec les gens de bien"
    },
    {
      "key": 487,
      "id": "3-194",
      "translation": "Seigneur! Donne-nous ce que Tu nous as promis par Tes messagers. Et ne nous couvre pas d'ignominie au Jour de la Résurrection. Car Toi, Tu ne manques pas à Ta promesse»"
    },
    {
      "key": 488,
      "id": "3-195",
      "translation": "Leur Seigneur les a alors exaucés (disant): «En vérité, Je ne laisse pas perdre le bien que quiconque parmi vous a fait, homme ou femme, car vous êtes les uns des autres. Ceux donc qui ont émigré, qui ont été expulsés de leurs demeures, qui ont été persécutés dans Mon chemin, qui ont combattu, qui ont été tués, Je tiendrai certes pour expiées leurs mauvaises actions, et les ferai entrer dans les Jardins sous lesquels coulent les ruisseaux, comme récompense de la part d'Allah.» Quant à Allah, c'est auprès de Lui qu'est la plus belle récompense"
    },
    {
      "key": 489,
      "id": "3-196",
      "translation": "Que ne t'abuse point la versatilité [pour la prospérité] dans le pays, de ceux qui sont infidèles"
    },
    {
      "key": 490,
      "id": "3-197",
      "translation": "Piètre jouissance! Puis leur refuge sera l'Enfer. Et quelle détestable couche"
    },
    {
      "key": 491,
      "id": "3-198",
      "translation": "Mais quant à ceux qui craignent leur Seigneur, ils auront des Jardins sous lesquels coulent les ruisseaux, pour y demeurer éternellement, un lieu d'accueil de la part d'Allah. Et ce qu'il y a auprès d'Allah est meilleur, pour les pieux"
    },
    {
      "key": 492,
      "id": "3-199",
      "translation": "Il y a certes, parmi les gens du Livre ceux qui croient en Allah et en ce qu'on a fait descendre vers vous et en ce qu'on a fait descendre vers eux. Ils sont humbles envers Allah, et ne vendent point les versets d'Allah à vil prix. Voilà ceux dont la récompense est auprès de leur Seigneur. En vérité, Allah est prompt à faire les comptes"
    },
    {
      "key": 493,
      "id": "3-200",
      "translation": "O les croyants! Soyez endurants. Incitez-vous à l'endurance. Luttez constamment (contre l'ennemi) et craignez Allah, afin que vous réussissiez"
    },
    {
      "key": 494,
      "id": "4-1",
      "translation": "O hommes! Craignez votre Seigneur qui vous a créés d'un seul être, et a créé de celui-ci son épouse, et qui de ces deux là a fait répandre (sur la terre) beaucoup d'hommes et de femmes. Craignez Allah au nom duquel vous vous implorez les uns les autres, et craignez de rompre les liens du sang. Certes Allah vous observe parfaitement"
    },
    {
      "key": 495,
      "id": "4-2",
      "translation": "Et donnez aux orphelins leurs biens; n'y substituez pas le mauvais au bon. Ne mangez pas leurs biens avec les vôtres: c'est vraiment un grand péché"
    },
    {
      "key": 496,
      "id": "4-3",
      "translation": "Et si vous craignez de n'être pas justes envers les orphelins,... Il est permis d'épouser deux, trois ou quatre, parmi les femmes qui vous plaisent, mais, si vous craignez de n'être pas justes avec celles-ci, alors une seule, ou des esclaves que vous possédez. Cela, afin de ne pas faire d'injustice (ou afin de ne pas aggraver votre charge de famille)"
    },
    {
      "key": 497,
      "id": "4-4",
      "translation": "Et donnez aux épouses leur mahr, de bonne grâce. Si de bon gré, elles vous en abandonnent quelque chose, disposez-en alors à votre aise et de bon cœur"
    },
    {
      "key": 498,
      "id": "4-5",
      "translation": "Et ne confiez pas aux incapables vos biens dont Allah a fait votre subsistance. Mais prélevez-en, pour eux, nourriture et vêtement; et parlez-leur convenablement"
    },
    {
      "key": 499,
      "id": "4-6",
      "translation": "Et éprouvez (la capacité) des orphelins jusqu'à ce qu'ils atteignent (l'aptitude) au mariage; et si vous ressentez en eux une bonne conduite, remettez-leur leurs biens. Ne les utilisez pas (dans votre intérêt) avec gaspillage et dissipation, avant qu'ils ne grandissent. Quiconque est aisé, qu'il s'abstienne d'en prendre lui-même. S'il est pauvre, alors qu'il en utilise raisonnablement: et lorsque vous leur remettez leurs biens, prenez des témoins à leur encontre. Mais Allah suffit pour observer et compter"
    },
    {
      "key": 500,
      "id": "4-7",
      "translation": "Aux hommes revient une part de ce qu'ont laissé les père et mère ainsi que les proches; et aux femmes une part de ce qu'ont laissé les père et mère ainsi que les proches, que ce soit peu ou beaucoup: une part fixée"
    },
    {
      "key": 501,
      "id": "4-8",
      "translation": "Et lorsque les proches parents, les orphelins, les nécessiteux assistent au partage, offrez-leur quelque chose de l'héritage, et parlez-leur convenablement"
    },
    {
      "key": 502,
      "id": "4-9",
      "translation": "Que la crainte saisisse ceux qui laisseraient après eux une descendance faible, et qui seraient inquiets à leur sujet; qu'ils redoutent donc Allah et qu'ils prononcent des paroles justes"
    },
    {
      "key": 503,
      "id": "4-10",
      "translation": "Ceux qui mangent [disposent] injustement des biens des orphelins ne font que manger du feu dans leurs ventres. Ils brûleront bientôt dans les flammes de l'Enfer"
    },
    {
      "key": 504,
      "id": "4-11",
      "translation": "Voici ce qu'Allah vous enjoint au sujet de vos enfants: au fils, une part équivalente à celle de deux filles. S'il n'y a que des filles, même plus de deux, à elles alors deux tiers de ce que le défunt laisse. Et s'il n'y en a qu'une, à elle alors la moitié. Quant aux père et mère du défunt, à chacun d'eux le sixième de ce qu'il laisse, s'il a un enfant. S'il n'a pas d'enfant et que ses père et mère héritent de lui, à sa mère alors le tiers. Mais s'il a des frères, à la mère alors le sixième, après exécution du testament qu'il aurait fait ou paiement d'une dette. De vos ascendants ou descendants, vous ne savez pas qui est plus près de vous en utilité. Ceci est un ordre obligatoire de la part d'Allah, car Allah est, certes, Omniscient et Sage"
    },
    {
      "key": 505,
      "id": "4-12",
      "translation": "Et à vous la moitié de ce que laissent vos épouses, si elles n'ont pas d'enfants. Si elles ont un enfant, alors à vous le quart de ce qu'elles laissent, après exécution du testament qu'elles auraient fait ou paiement d'une dette. Et à elles un quart de ce que vous laissez, si vous n'avez pas d'enfant. Mais si vous avez un enfant, à elles alors le huitième de ce que vous laissez après exécution du testament que vous auriez fait ou paiement d'une dette. Et si un homme, ou une femme meurt sans héritier direct, cependant qu'il laisse un frère ou une sœur, à chacun de ceux-ci alors, un sixième. S'ils sont plus de deux, tous alors participeront au tiers, après exécution du testament ou paiement d'une dette, sans préjudice à quiconque. (Telle est l') Injonction d'Allah! Et Allah est Omniscient et Indulgent"
    },
    {
      "key": 506,
      "id": "4-13",
      "translation": "Tels sont les ordres d'Allah. Et quiconque obéit à Allah et à Son messager, Il le fera entrer dans les Jardins sous lesquels coulent les ruisseaux, pour y demeurer éternellement. Et voilà la grande réussite"
    },
    {
      "key": 507,
      "id": "4-14",
      "translation": "Et quiconque désobéit à Allah et à Son messager, et transgresse Ses ordres, Il le fera entrer au Feu pour y demeurer éternellement. Et celui-là aura un châtiment avilissant"
    },
    {
      "key": 508,
      "id": "4-15",
      "translation": "Celles de vos femmes qui forniquent, faites témoigner à leur encontre quatre d'entre vous. S'ils témoignent, alors confinez ces femmes dans vos maisons jusqu'à ce que la mort les rappelle ou qu'Allah décrète un autre ordre à leur égard"
    },
    {
      "key": 509,
      "id": "4-16",
      "translation": "Les deux d'entre vous qui l'ont commise [la fornication], sévissez contre eux. S'ils se repentent ensuite et se réforment, alors laissez-les en paix. Allah demeure Accueillant au repentir et Miséricordieux"
    },
    {
      "key": 510,
      "id": "4-17",
      "translation": "Allah accueille seulement le repentir de ceux qui font le mal par ignorance et qui aussitôt se repentent. Voilà ceux de qui Allah accueille le repentir. Et Allah est Omniscient et Sage"
    },
    {
      "key": 511,
      "id": "4-18",
      "translation": "Mais l'absolution n'est point destinée à ceux qui font de mauvaises actions jusqu'au moment où la mort se présente à l'un d'eux, et qui s'écrie: «Certes, je me repens maintenant» - non plus pour ceux qui meurent mécréants. Et c'est pour eux que Nous avons préparé un châtiment douloureux"
    },
    {
      "key": 512,
      "id": "4-19",
      "translation": "O les croyants! Il ne vous est pas licite d'hériter des femmes contre leur gré. Ne les empêchez pas de se remarier dans le but de leur ravir une partie de ce que vous aviez donné, à moins qu'elles ne viennent à commettre un péché prouvé. Et comportez-vous convenablement envers elles. Si vous avez de l'aversion envers elles durant la vie commune, il se peut que vous ayez de l'aversion pour une chose où Allah a déposé un grand bien"
    },
    {
      "key": 513,
      "id": "4-20",
      "translation": "Si vous voulez substituer une épouse à une autre, et que vous ayez donné à l'une un qintâr, n'en reprenez rien. Quoi! Le reprendriez-vous par injustice et péché manifeste"
    },
    {
      "key": 514,
      "id": "4-21",
      "translation": "Comment oseriez-vous le reprendre, après que l'union la plus intime vous ait associés l'un à l'autre et qu'elles aient obtenu de vous un engagement solennel"
    },
    {
      "key": 515,
      "id": "4-22",
      "translation": "Et n'épousez pas les femmes que vos pères ont épousées, exception faite pour le passé. C'est une turpitude, une abomination, et quelle mauvaise conduite"
    },
    {
      "key": 516,
      "id": "4-23",
      "translation": "Vous sont interdites vos mères, filles, sœurs, tantes paternelles et tantes maternelles, filles d'un frère et filles d'une sœur, mères qui vous ont allaités, sœurs de lait, mères de vos femmes, belles-filles sous votre tutelle et issues des femmes avec qui vous avez consommé le mariage; si le mariage n'a pas été consommé, ceci n'est pas un péché de votre part; les femmes de vos fils nés de vos reins; de même que deux sœurs réunies - exception faite pour le passé. Car vraiment Allah est Pardonneur et Miséricordieux"
    },
    {
      "key": 517,
      "id": "4-24",
      "translation": "et, parmi les femmes, les dames (qui ont un mari), sauf si elles sont vos esclaves en toute propriété. Prescription d'Allah sur vous! A part cela, il vous est permis de les rechercher, en vous servant de vos biens et en concluant mariage, non en débauchés. Puis, de même que vous jouissez d'elles, donnez-leur leur mahr comme une chose due. Il n'y a aucun péché contre vous à ce que vous concluez un accord quelconque entre vous après la fixation du mahr Car Allah est, certes, Omniscient et Sage"
    },
    {
      "key": 518,
      "id": "4-25",
      "translation": "Et quiconque parmi vous n'a pas les moyens pour épouser des femmes libres (non esclaves) croyantes, eh bien (il peut épouser) une femme parmi celles de vos esclaves croyantes. Allah connaît mieux votre foi, car vous êtes les uns des autres (de la même religion). Et épousez-les avec l'autorisation de leurs maîtres (Waliy) et donnez-leur un mahr convenable; (épousez-les) étant vertueuses et non pas livrées à la débauche ni ayant des amants clandestins. Si, une fois engagées dans le mariage, elles commettent l'adultère, elles reçoivent la moitié du châtiment qui revient aux femmes libres (non esclaves) mariées. Ceci est autorisé à celui d'entre vous qui craint la débauche; mais ce serait mieux pour vous d'être endurant. Et Allah est Pardonneur et Miséricordieux"
    },
    {
      "key": 519,
      "id": "4-26",
      "translation": "Allah veut vous éclairer, vous montrer les voies des hommes d'avant vous, et aussi accueillir votre repentir. Et Allah est Omniscient et Sage"
    },
    {
      "key": 520,
      "id": "4-27",
      "translation": "Et Allah veut accueillir votre repentir. Mais ceux qui suivent les passions veulent que vous vous incliniez grandement (vers l'erreur comme ils le font)"
    },
    {
      "key": 521,
      "id": "4-28",
      "translation": "Allah veut vous alléger (les obligations,) car l'homme a été créé faible"
    },
    {
      "key": 522,
      "id": "4-29",
      "translation": "O les croyants! Que les uns d'entre vous ne mangent pas les biens des autres illégalement. Mais qu'il y ait du négoce (légal), entre vous, par consentement mutuel. Et ne vous tuez pas vous-mêmes. Allah, en vérité, est Miséricordieux envers vous"
    },
    {
      "key": 523,
      "id": "4-30",
      "translation": "Et quiconque commet cela, par excès et par iniquité, Nous le jetterons au Feu, voilà qui est facile pour Allah"
    },
    {
      "key": 524,
      "id": "4-31",
      "translation": "Si vous évitez les grands péchés qui vous sont interdits, Nous effacerons vos méfaits de votre compte, et Nous vous ferons entrer dans un endroit honorable (le Paradis)"
    },
    {
      "key": 525,
      "id": "4-32",
      "translation": "Ne convoitez pas ce qu'Allah a attribué aux uns d'entre vous plus qu'aux autres; aux hommes la part qu'ils ont acquise, et aux femmes la part qu'elles ont acquise. Demandez à Allah de Sa grâce. Car Allah, certes, est Omniscient"
    },
    {
      "key": 526,
      "id": "4-33",
      "translation": "A tous Nous avons désigné des héritiers pour ce que leur laissent leurs père et mère, leurs proches parents, et ceux envers qui, de vos propres mains, vous vous êtes engagés, donnez leur donc leur part, car Allah, en vérité, est témoin de tout"
    },
    {
      "key": 527,
      "id": "4-34",
      "translation": "Les hommes ont autorité sur les femmes, en raison des faveurs qu'Allah accorde à ceux-là sur celles-ci, et aussi à cause des dépenses qu'ils font de leurs biens. Les femmes vertueuses sont obéissantes (à leurs maris), et protègent ce qui doit être protégé, pendant l'absence de leurs époux, avec la protection d'Allah. Et quant à celles dont vous craignez la désobéissance, exhortez-les, éloignez-vous d'elles dans leurs lits et frappez-les. Si elles arrivent à vous obéir, alors ne cherchez plus de voie contre elles, car Allah est certes, Haut et Grand"
    },
    {
      "key": 528,
      "id": "4-35",
      "translation": "Si vous craignez le désaccord entre les deux [époux], envoyez alors un arbitre de sa famille à lui, et un arbitre de sa famille à elle. Si les deux veulent la réconciliation, Allah rétablira l'entente entre eux. Allah est certes, Omniscient et Parfaitement Connaisseur"
    },
    {
      "key": 529,
      "id": "4-36",
      "translation": "Adorez Allah et ne Lui donnez aucun associé. Agissez avec bonté envers (vos) père et mère, les proches, les orphelins, les pauvres, le proche voisin, le voisin lointain, le collègue et le voyageur, et les esclaves en votre possession, car Allah n'aime pas, en vérité, le présomptueux, l'arrogant"
    },
    {
      "key": 530,
      "id": "4-37",
      "translation": "Ceux qui sont avares et ordonnent l'avarice aux autres, et cachent ce qu'Allah leur a donné de par Sa grâce. Nous avons préparé un châtiment avilissant pour les mécréants"
    },
    {
      "key": 531,
      "id": "4-38",
      "translation": "Et ceux qui dépensent leurs biens avec ostentation devant les gens, et ne croient ni en Allah ni au Jour dernier. Quiconque a le Diable pour camarade inséparable, quel mauvais camarade"
    },
    {
      "key": 532,
      "id": "4-39",
      "translation": "Qu'auraient-ils à se reprocher s'ils avaient cru en Allah et au Jour dernier et dépensé (dans l'obéissance) de ce qu'Allah leur a attribué? Allah, d'eux, est Omniscient"
    },
    {
      "key": 533,
      "id": "4-40",
      "translation": "Certes, Allah ne lèse (personne), fût-ce du poids d'un atome. S'il est une bonne action, Il la double, et accorde une grosse récompense de Sa part"
    },
    {
      "key": 534,
      "id": "4-41",
      "translation": "Comment seront-ils quand Nous ferons venir de chaque communauté un témoin, et que Nous te  ferons venir comme témoin contre ces gens-ci"
    },
    {
      "key": 535,
      "id": "4-42",
      "translation": "Ce jour-là, ceux qui n'ont pas cru et ont désobéi au Messager, préfèreraient que la terre fût nivelée sur eux et ils ne sauront cacher à Allah aucune parole"
    },
    {
      "key": 536,
      "id": "4-43",
      "translation": "O les croyants! N'approchez pas de la Salât alors que vous êtes ivres jusqu'à ce que vous compreniez ce que vous dites, et aussi quand vous êtes en état d'impureté [pollués] - à moins que vous ne soyez en voyage - jusqu'à ce que vous ayez pris un bain rituel. Si vous êtes malades ou en voyage, ou si l'un de vous revient du lieu où il a fait ses besoins, ou si vous avez touché à des femmes et que vous ne trouviez pas d'eau, alors recourez à une terre pure, et passez-vous-en sur vos visages et sur vos mains. Allah, en vérité, est Indulgent et Pardonneur"
    },
    {
      "key": 537,
      "id": "4-44",
      "translation": "N'as-tu  pas vu ceux qui ont reçu une partie du Livre acheter l'égarement et chercher à ce que vous vous égariez du [droit] chemin"
    },
    {
      "key": 538,
      "id": "4-45",
      "translation": "Allah connaît mieux vos ennemis. Et Allah suffit comme protecteur. Et Allah suffit comme secoureur"
    },
    {
      "key": 539,
      "id": "4-46",
      "translation": "Il en est parmi les Juifs qui détournent les mots de leur sens, et disent: «Nous avions entendu, mais nous avons désobéi», «Ecoute sans qu'il te soit donné d'entendre», et favorise nous «Ra'inâ», tordant la langue et attaquant la religion. Si au contraire ils disaient: «Nous avons entendu et nous avons obéi», «Ecoute», et «Regarde-nous», ce serait meilleur pour eux, et plus droit. Mais Allah les a maudits à cause de leur mécréance; leur foi est donc bien médiocre"
    },
    {
      "key": 540,
      "id": "4-47",
      "translation": "O vous à qui on a donné le Livre, croyez à ce que Nous avons fait descendre, en confirmation de ce que vous aviez déjà, avant que Nous effacions des visages et les retournions sens devant derrière, ou que Nous les maudissions comme Nous avons maudit les gens du Sabbat. Car le commandement d'Allah est toujours exécuté"
    },
    {
      "key": 541,
      "id": "4-48",
      "translation": "Certes Allah ne pardonne pas qu'on Lui donne quelqu'associé. A part cela, Il pardonne à qui Il veut. Mais quiconque donne à Allah quelqu'associé commet un énorme péché"
    },
    {
      "key": 542,
      "id": "4-49",
      "translation": "N'as-tu pas vu ceux-là qui se déclarent purs? Mais c'est Allah qui purifie qui Il veut; et ils ne seront point lésés, fût-ce d'un brin de noyau de datte"
    },
    {
      "key": 543,
      "id": "4-50",
      "translation": "Regarde comme ils inventent le mensonge à l'encontre d'Allah. Et çà, c'est assez comme péché manifeste"
    },
    {
      "key": 544,
      "id": "4-51",
      "translation": "N'as-tu pas vu ceux-là, à qui une partie du Livre a été donnée, avoir foi à la magie (gibt) et au Tâghhoût, et dire en faveur de ceux qui ne croient pas: «Ceux-là sont mieux guidés (sur le chemin) que ceux qui ont cru»"
    },
    {
      "key": 545,
      "id": "4-52",
      "translation": "Voilà ceux qu'Allah a maudits; et quiconque Allah maudit, jamais tu ne trouveras pour lui de secoureur"
    },
    {
      "key": 546,
      "id": "4-53",
      "translation": "Possèdent-ils une partie du pouvoir? Ils ne donneraient donc rien aux gens, fût-ce le creux d'un noyau de datte"
    },
    {
      "key": 547,
      "id": "4-54",
      "translation": "Envient-ils aux gens ce qu'Allah leur a donné de par Sa grâce? Or, Nous avons donné à la famille d'Abraham le Livre et la Sagesse; et Nous leur avons donné un immense royaume"
    },
    {
      "key": 548,
      "id": "4-55",
      "translation": "Certains d'entre eux ont cru en lui, d'autres d'entre eux s'en sont écartés. L'Enfer leur suffira comme flamme (pour y brûler)"
    },
    {
      "key": 549,
      "id": "4-56",
      "translation": "Certes, ceux qui ne croient pas à Nos Versets, (le Coran) Nous les brûlerons bientôt dans le Feu. Chaque fois que leurs peaux auront été consumées, Nous leur donnerons d'autres peaux en échange afin qu'ils goûtent au châtiment. Allah est certes, Puissant et Sage"
    },
    {
      "key": 550,
      "id": "4-57",
      "translation": "Et quant à ceux qui ont cru et fait de bonnes œuvres, bientôt Nous les ferons entrer aux Jardins sous lesquels coulent des ruisseaux. Ils y demeureront éternellement. Il y aura là pour eux des épouses purifiées. Et Nous les ferons entrer sous un ombrage épais"
    },
    {
      "key": 551,
      "id": "4-58",
      "translation": "Certes, Allah vous commande de rendre les dépôts à leurs ayants-droit, et quand vous jugez entre des gens, de juger avec équité. Quelle bonne exhortation qu'Allah vous fait! Allah est, en vérité, Celui qui entend et qui voit tout"
    },
    {
      "key": 552,
      "id": "4-59",
      "translation": "O les croyants! Obéissez à Allah, et obéissez au Messager et à ceux d'entre vous qui détiennent le commandement. Puis, si vous vous disputez en quoi que ce soit, renvoyez-le à Allah et au Messager, si vous croyez en Allah et au Jour dernier. Ce sera bien mieux et de meilleure interprétation (et aboutissement)"
    },
    {
      "key": 553,
      "id": "4-60",
      "translation": "N'as-tu pas vu ceux qui prétendent croire à ce qu'on a fait descendre vers toi [prophète] et à ce qu'on a fait descendre avant toi? Ils veulent prendre pour juge le Tâghût, alors que c'est en lui qu'on leur a commandé de ne pas croire. Mais le Diable veut les égarer très loin, dans l'égarement"
    },
    {
      "key": 554,
      "id": "4-61",
      "translation": "Et lorsqu'on leur dit: «Venez vers ce qu'Allah a fait descendre et vers le Messager», tu vois les hypocrites s'écarter loin de toi"
    },
    {
      "key": 555,
      "id": "4-62",
      "translation": "Comment (agiront-ils) quand un malheur les atteindra, à cause de ce qu'ils ont préparé de leurs propres mains? Puis ils viendront alors près de toi, jurant par Allah: «Nous n'avons voulu que le bien et la réconciliation»"
    },
    {
      "key": 556,
      "id": "4-63",
      "translation": "Voilà ceux dont Allah sait ce qu'ils ont dans leurs cœurs. Ne leur tiens donc pas rigueur, exhorte-les, et dis-leur sur eux-mêmes des paroles convaincantes"
    },
    {
      "key": 557,
      "id": "4-64",
      "translation": "Nous n'avons envoyé de Messager que pour qu'il soit obéi par la permission d'Allah. Si, lorsqu'ils ont fait du tort à leurs propres personnes ils venaient à toi en implorant le pardon d'Allah et si le Messager demandait le pardon pour eux, ils trouveraient, certes, Allah, Très Accueillant au repentir, Miséricordieux"
    },
    {
      "key": 558,
      "id": "4-65",
      "translation": "Non!... Par ton Seigneur! Ils ne seront pas croyants aussi longtemps qu'ils ne t'auront demandé de juger de leurs disputes et qu'ils n'auront éprouvé nulle angoisse pour ce que tu auras décidé, et qu'ils se soumettent complètement [à ta sentence]"
    },
    {
      "key": 559,
      "id": "4-66",
      "translation": "Si Nous leur avions prescrit ceci: «Tuez-vous vous-mêmes», ou «Sortez de vos demeures», ils ne l'auraient pas fait, sauf un petit nombre d'entre eux. S'ils avaient fait ce à quoi on les exhortait, cela aurait été certainement meilleur pour eux, et (leur foi) aurait été plus affermie"
    },
    {
      "key": 560,
      "id": "4-67",
      "translation": "Alors Nous leur aurions donné certainement, de Notre part, une grande récompense"
    },
    {
      "key": 561,
      "id": "4-68",
      "translation": "et Nous les aurions guidé certes, vers un droit chemin"
    },
    {
      "key": 562,
      "id": "4-69",
      "translation": "Quiconque obéit à Allah et au Messager... ceux-là seront avec ceux qu'Allah a comblés de Ses bienfaits: les prophètes, les véridiques, les martyrs, et les vertueux. Et quels bons compagnons que ceux-là"
    },
    {
      "key": 563,
      "id": "4-70",
      "translation": "Cette grâce vient d'Allah. Et Allah suffit comme Parfait Connaisseur"
    },
    {
      "key": 564,
      "id": "4-71",
      "translation": "O les croyants! Prenez vos précautions et partez en expédition par détachements ou en masse"
    },
    {
      "key": 565,
      "id": "4-72",
      "translation": "Parmi vous, il y aura certes, quelqu'un qui tardera [à aller au combat] et qui, si un malheur vous atteint, dira: «Certes, Allah m'a fait une faveur en ce que je ne me suis pas trouvé en leur compagnie»"
    },
    {
      "key": 566,
      "id": "4-73",
      "translation": "et si c'est une grâce qui vous atteint de la part d'Allah, il se mettra, certes, à dire, comme s'il n'y avait aucune affection entre vous et lui: «Quel dommage! Si j'avais été avec eux, j'aurais alors acquis un gain énorme»"
    },
    {
      "key": 567,
      "id": "4-74",
      "translation": "Qu'ils combattent donc dans le sentier d'Allah, ceux qui troquent la vie présente contre la vie future. Et quiconque combat dans le sentier d'Allah, tué ou vainqueur, Nous lui donnerons bientôt une énorme récompense"
    },
    {
      "key": 568,
      "id": "4-75",
      "translation": "Et qu'avez-vous à ne pas combattre dans le sentier d'Allah, et pour la cause des faibles: hommes, femmes et enfants qui disent: «Seigneur! Fais-nous sortir de cette cité dont les gens sont injustes, et assigne-nous de Ta part un allié, et assigne-nous de Ta part un secoureur»"
    },
    {
      "key": 569,
      "id": "4-76",
      "translation": "Les croyants combattent dans le sentier d'Allah, et ceux qui ne croient pas combattent dans le sentier du Tâghût. Eh bien, combattez les alliés du Diable, car la ruse du Diable est, certes, faible"
    },
    {
      "key": 570,
      "id": "4-77",
      "translation": "N'as-tu pas vu ceux auxquels on avait dit: «Abstenez-vous de combattre, accomplissez la Salât et acquittez la Zakât!» Puis lorsque le combat leur fut prescrit, voilà qu'une partie d'entre eux se mit à craindre les gens comme on craint Allah, ou même d'une crainte plus forte encore, et à dire: «O notre Seigneur! Pourquoi nous as-Tu prescrit le combat? Pourquoi n'as-Tu pas reporté cela à un peu plus tard?» Dis: «La jouissance d'ici-bas est éphémère, mais la vie future est meilleure pour quiconque est pieux. Et on ne vous lésera pas, fût-ce d'un brin de noyau de datte"
    },
    {
      "key": 571,
      "id": "4-78",
      "translation": "Où que vous soyez, la mort vous atteindra, fussiez-vous dans des tours imprenables. Qu'un bien les atteigne, ils disent: «C'est de la part d'Allah.» Qu'un mal les atteigne, ils disent: «C'est dû à toi .» Dis: «Tout est d'Allah.» Mais qu'ont-ils ces gens, à ne comprendre presque aucune parole"
    },
    {
      "key": 572,
      "id": "4-79",
      "translation": "Tout bien qui t'atteint vient d'Allah, et tout mal qui t'atteint vient de toi-même. Et nous t'avons envoyé aux gens comme Messager. Et Allah suffit comme témoin"
    },
    {
      "key": 573,
      "id": "4-80",
      "translation": "Quiconque obéit au Messager obéit certainement à Allah. Et quiconque tourne le dos... Nous ne t'avons pas envoyé à eux comme gardien"
    },
    {
      "key": 574,
      "id": "4-81",
      "translation": "Ils disent: «Obéissance!» Puis, sitôt sortis de chez toi, une partie d'entre eux délibère au cours de la nuit de tout autre chose que ce qu'elle t'a dit. [Cependant] Allah enregistre ce qu'ils font la nuit. Pardonne-leur donc et place ta confiance en Allah. Et Allah suffit comme Protecteur"
    },
    {
      "key": 575,
      "id": "4-82",
      "translation": "Ne méditent-ils donc pas sur le Coran? S'il provenait d'un autre qu'Allah, ils y trouveraient certes maintes contradictions"
    },
    {
      "key": 576,
      "id": "4-83",
      "translation": "Quand leur parvient une nouvelle rassurante ou alarmante, ils la diffusent. S'ils la rapportaient au Messager et aux détenteurs du commandement parmi eux, ceux d'entre eux qui cherchent à être éclairés, auraient appris (la vérité de la bouche du Prophète et des détenteurs du commandement). Et n'eussent été la grâce d'Allah sur vous et Sa miséricorde, vous auriez suivi le Diable, à part quelques-uns"
    },
    {
      "key": 577,
      "id": "4-84",
      "translation": "Combats donc dans le sentier d'Allah, tu n'es responsable que de toi même, et incite les croyants (au combat) Allah arrêtera certes la violence des mécréants. Allah est plus redoutable en force et plus sévère en punition"
    },
    {
      "key": 578,
      "id": "4-85",
      "translation": "Quiconque intercède d'une bonne intercession, en aura une part; et quiconque intercède d'une mauvaise intercession en portera une part de responsabilité. Et Allah est Puissant sur toute chose"
    },
    {
      "key": 579,
      "id": "4-86",
      "translation": "Si on vous fait une salutation, saluez d'une façon meilleure; ou bien rendez-la (simplement). Certes, Allah tient compte de tout"
    },
    {
      "key": 580,
      "id": "4-87",
      "translation": "Allah! Pas de divinité à part Lui! Très certainement Il vous rassemblera au Jour de la Résurrection, point de doute là-dessus. Et qui est plus véridique qu'Allah en parole"
    },
    {
      "key": 581,
      "id": "4-88",
      "translation": "Qu'avez-vous à vous diviser en deux factions au sujet des hypocrites? Alors qu'Allah les a refoulés (dans leur infidélité) pour ce qu'ils ont acquis. Voulez-vous guider ceux qu'Allah égare? Et quiconque Allah égare, tu ne lui trouveras pas de chemin (pour le ramener)"
    },
    {
      "key": 582,
      "id": "4-89",
      "translation": "Ils aimeraient vous voir mécréants comme ils ont mécru: alors vous seriez tous égaux! Ne prenez donc pas d'alliés parmi eux, jusqu'à ce qu'ils émigrent dans le sentier d'Allah. Mais s'ils tournent le dos, saisissez-les alors, et tuez-les où que vous les trouviez; et ne prenez parmi eux ni allié ni secoureur"
    },
    {
      "key": 583,
      "id": "4-90",
      "translation": "excepté ceux qui se joignent à un groupe avec lequel vous avez conclu une alliance, ou ceux qui viennent chez vous, le cœur serré d'avoir à vous combattre ou à combattre leur propre tribu. Si Allah avait voulu, Il leur aurait donné l'audace (et la force) contre vous, et ils vous auraient certainement combattu. (Par conséquent,) s'ils restent neutres à votre égard et ne vous combattent point, et qu'ils vous offrent la paix, alors, Allah ne vous donne pas de chemin contre eux"
    },
    {
      "key": 584,
      "id": "4-91",
      "translation": "Vous en trouverez d'autres qui cherchent à avoir votre confiance, et en même temps la confiance de leur propre tribu. Toutes les fois qu'on les pousse vers l'Association, (l'idolâtrie) ils y retombent en masse. (Par conséquent,) s'ils ne restent pas neutres à votre égard, ne vous offrent pas la paix et ne retiennent pas leurs mains (de vous combattre), alors, saisissez-les et tuez les où que vous les trouviez. Contre ceux-ci, Nous vous avons donné une autorité manifeste"
    },
    {
      "key": 585,
      "id": "4-92",
      "translation": "Il n'appartient pas à un croyant de tuer un autre croyant, si ce n'est par erreur. Quiconque tue par erreur un croyant, qu'il affranchisse alors un esclave croyant et remette à sa famille le prix du sang, à moins que celle-ci n'y renonce par charité. Mais si [le tué] appartenait à un peuple ennemi à vous et qu'il soit croyant, qu'on affranchisse alors un esclave croyant. S'il appartenait à un peuple auquel vous êtes liés par un pacte, qu'on alors à sa famille le prix du sang et qu'on affranchisse un esclave croyant. Celui qui n'en trouve pas les moyens, qu'il jeûne deux mois d'affilée pour être pardonné par Allah. Allah est Omniscient et Sage"
    },
    {
      "key": 586,
      "id": "4-93",
      "translation": "Quiconque tue intentionnellement un croyant, Sa rétribution alors sera l'Enfer, pour y demeurer éternellement. Allah l'a frappé de Sa colère, l'a maudit et lui a préparé un énorme châtiment"
    },
    {
      "key": 587,
      "id": "4-94",
      "translation": "O les croyants! Lorsque vous sortez pour lutter dans le sentier d'Allah, voyez bien clair (ne vous hâtez pas) et ne dites pas à quiconque vous adresse le salut (de l'Islam): «Tu n'es pas croyant», convoitant les biens de la vie d'ici-bas. Or c'est auprès d'Allah qu'il y a beaucoup de butin. C'est ainsi que vous étiez auparavant; puis Allah vous a accordé Sa grâce. Voyez donc bien clair. Allah est, certes, Parfaitement Connaisseur de ce que vous faites"
    },
    {
      "key": 588,
      "id": "4-95",
      "translation": "Ne sont pas égaux ceux des croyants qui restent chez eux - sauf ceux qui ont quelque infirmité - et ceux qui luttent corps et biens dans le sentier d'Allah. Allah donne à ceux qui luttent corps et biens un grade d'excellence sur ceux qui restent chez eux. Et à chacun Allah a promis la meilleure récompense; et Allah a mis les combattants au-dessus des non combattants en leur accordant une rétribution immense"
    },
    {
      "key": 589,
      "id": "4-96",
      "translation": "des grades de supériorité de Sa part ainsi qu'un pardon et une miséricorde. Allah est Pardonneur et Miséricordieux"
    },
    {
      "key": 590,
      "id": "4-97",
      "translation": "Ceux qui ont fait du tort à eux-mêmes, les Anges enlèveront leurs âmes en disant: «Où en étiez-vous?» (à propos de votre religion) - «Nous étions impuissants sur terre», dirent-ils. Alors les Anges diront: «La terre d'Allah n'était-elle pas assez vaste pour vous permettre d'émigrer?» Voilà bien ceux dont le refuge est l'Enfer. Et quelle mauvaise destination"
    },
    {
      "key": 591,
      "id": "4-98",
      "translation": "A l'exception des impuissants: hommes, femmes et enfants, incapables de se débrouiller, et qui ne trouvent aucune voie"
    },
    {
      "key": 592,
      "id": "4-99",
      "translation": "A ceux-là, il se peut qu'Allah donne le pardon. Allah est Clément et Pardonneur"
    },
    {
      "key": 593,
      "id": "4-100",
      "translation": "Et quiconque émigre dans le sentier d'Allah trouvera sur terre maints refuges et abondance. Et quiconque sort de sa maison, émigrant vers Allah et Son messager, et que la mort atteint, sa récompense incombe à Allah. Et Allah est Pardonneur et Miséricordieux"
    },
    {
      "key": 594,
      "id": "4-101",
      "translation": "Et quand vous parcourez la terre, ce n'est pas un péché pour vous de raccourcir la Salât, si vous craignez que les mécréants ne vous mettent à l'épreuve, car les mécréants demeurent pour vous un ennemi déclaré"
    },
    {
      "key": 595,
      "id": "4-102",
      "translation": "Et lorsque tu  te trouves parmi eux, et que tu les diriges dans la Salât, qu'un groupe d'entre eux se mette debout en ta compagnie, en gardant leurs armes. Puis lorsqu'ils ont terminé la prosternation, qu'ils passent derrière vous et que vienne l'autre groupe, ceux qui n'ont pas encore célébré la Salât. A ceux-ci alors d'accomplir la Salât avec toi, prenant leurs précautions et leurs armes. Les mécréants aimeraient vous voir négliger vos armes et vos bagages, afin de tomber sur vous en une seule masse. Vous ne commettez aucun péché si, incommodés par la pluie ou malades, vous déposez vos armes; cependant prenez garde. Certes, Allah a préparé pour les mécréants un châtiment avilissant"
    },
    {
      "key": 596,
      "id": "4-103",
      "translation": "Quand vous avez accompli la Salât, invoquez le nom d'Allah, debout, assis ou couchés sur vos côtés. Puis lorsque vous êtes en sécurité, accomplissez la Salât (normalement), car la Salât demeure, pour les croyants, une prescription, à des temps déterminés"
    },
    {
      "key": 597,
      "id": "4-104",
      "translation": "Ne faiblissez pas dans la poursuite du peuple [ennemi]. Si vous souffrez, lui aussi souffre comme vous souffrez, tandis que vous espérez d'Allah ce qu'il n'espère pas. Allah est Omniscient et Sage"
    },
    {
      "key": 598,
      "id": "4-105",
      "translation": "Nous avons fait descendre vers toi le Livre avec la vérité, pour que tu juges entre les gens, selon ce qu'Allah t'a appris. Et ne te fais pas l'avocat des traîtres"
    },
    {
      "key": 599,
      "id": "4-106",
      "translation": "Et implore d'Allah le pardon car Allah est certes Pardonneur et Miséricordieux"
    },
    {
      "key": 600,
      "id": "4-107",
      "translation": "Et ne dispute pas en faveur de ceux qui se trahissent eux-mêmes. Allah, vraiment, n'aime pas le traître et le pécheur"
    },
    {
      "key": 601,
      "id": "4-108",
      "translation": "Ils cherchent à se cacher des gens, mais ils ne cherchent pas à se cacher d'Allah. Or, Il est avec eux quand ils tiennent la nuit des paroles qu'Il (Allah) n'agrée pas. Et Allah ne cesse de cerner (par Sa science) ce qu'ils font"
    },
    {
      "key": 602,
      "id": "4-109",
      "translation": "Voilà les gens en faveur desquels vous disputez dans la vie présente. Mais qui va disputer pour eux devant Allah au Jour de la Résurrection? Ou bien qui sera leur protecteur"
    },
    {
      "key": 603,
      "id": "4-110",
      "translation": "Quiconque agit mal ou fait du tort à lui-même, puis aussitôt implore d'Allah le pardon, trouvera Allah Pardonneur et Miséricordieux"
    },
    {
      "key": 604,
      "id": "4-111",
      "translation": "Quiconque acquiert un péché, ne l'acquiert que contre lui-même. Et Allah est Omniscient et Sage"
    },
    {
      "key": 605,
      "id": "4-112",
      "translation": "Et quiconque acquiert une faute ou un péché puis en accuse un innocent, se rend coupable alors d'une injustice et d'un péché manifeste"
    },
    {
      "key": 606,
      "id": "4-113",
      "translation": "Et n'eût été la grâce d'Allah sur toi  et Sa miséricorde, une partie d'entre eux t'aurait bien volontiers égaré. Mais ils n'égarent qu'eux-mêmes, et ne peuvent en rien te nuire. Allah a fait descendre sur toi le Livre et la Sagesse, et t'a enseigné ce que tu ne savais pas. Et la grâce d'Allah sur toi est immense"
    },
    {
      "key": 607,
      "id": "4-114",
      "translation": "Il n'y a rien de bon dans la plus grande partie de leurs conversations secrètes, sauf si l'un d'eux ordonne une charité, une bonne action, ou une conciliation entre les gens. Et quiconque le fait, cherchant l'agrément d'Allah, à celui-là Nous donnerons bientôt une récompense énorme"
    },
    {
      "key": 608,
      "id": "4-115",
      "translation": "Et quiconque fait scission d'avec le Messager, après que le droit chemin lui est apparu et suit un sentier autre que celui des croyants, alors Nous le laisserons comme il s'est détourné, et le brûlerons dans l'Enfer. Et quelle mauvaise destination"
    },
    {
      "key": 609,
      "id": "4-116",
      "translation": "Certes, Allah ne pardonne pas qu'on Lui donne des associés. A part cela, Il pardonne à qui Il veut. Quiconque donne des associés à Allah s'égare, très loin dans l'égarement"
    },
    {
      "key": 610,
      "id": "4-117",
      "translation": "Ce ne sont que des femelles qu'ils invoquent, en dehors de Lui. Et ce n'est qu'un diable rebelle qu'ils invoquent"
    },
    {
      "key": 611,
      "id": "4-118",
      "translation": "Allah l'a (le Diable) maudit et celui-ci a dit: «Certainement, je saisirai parmi Tes serviteurs, une partie déterminée"
    },
    {
      "key": 612,
      "id": "4-119",
      "translation": "Certes, je ne manquerai pas de les égarer, je leur donnerai de faux espoirs, je leur commanderai, et ils fendront les oreilles aux bestiaux; je leur commanderai, et ils altèreront la création d'Allah. Et quiconque prend le Diable pour allié au lieu d'Allah, sera, certes, voué à une perte évidente"
    },
    {
      "key": 613,
      "id": "4-120",
      "translation": "Il leur fait des promesses et leur donne de faux espoirs. Et le Diable ne leur fait que des promesses trompeuses"
    },
    {
      "key": 614,
      "id": "4-121",
      "translation": "Voilà ceux dont le refuge est l'Enfer. Et ils ne trouveront aucun moyen d'y échapper"
    },
    {
      "key": 615,
      "id": "4-122",
      "translation": "Et quant à ceux qui ont cru et fait de bonnes œuvres, Nous les ferons entrer bientôt aux Jardins sous lesquels coulent les ruisseaux, pour y demeurer éternellement. Promesse d'Allah en vérité. Et qui est plus véridique qu'Allah en parole"
    },
    {
      "key": 616,
      "id": "4-123",
      "translation": "Ceci ne dépend ni de vos désirs ni des désirs des gens du Livre. Quiconque fait un mal sera rétribué pour cela, et ne trouvera en sa faveur, hors d'Allah, ni allié ni secoureur"
    },
    {
      "key": 617,
      "id": "4-124",
      "translation": "Et quiconque, homme ou femme, fait de bonnes œuvres, tout en étant croyant... les voilà ceux qui entreront au Paradis; et on ne leur fera aucune injustice, fût-ce d'un creux de noyau de datte"
    },
    {
      "key": 618,
      "id": "4-125",
      "translation": "Qui est meilleur en religion que celui qui soumet à Allah son être, tout en se conformant à la Loi révélée et suivant la religion d'Abraham, homme de droiture? Et Allah avait pris Abraham pour ami privilégié"
    },
    {
      "key": 619,
      "id": "4-126",
      "translation": "C'est à Allah qu'appartient tout ce qui est dans les cieux et sur la terre. Et Allah embrasse toute chose (de Sa science et de Sa puissance)"
    },
    {
      "key": 620,
      "id": "4-127",
      "translation": "Et ils te consultent à propos de ce qui a été décrété au sujet des femmes. Dis: «Allah vous donne Son décret là-dessus, en plus de ce qui vous est récité dans le Livre, au sujet des orphelines auxquelles vous ne donnez pas ce qui leur a été prescrit, et que vous désirez épouser, et au sujet des mineurs encore d'âge faible». Vous devez agir avec équité envers les orphelins. Et de tout ce que vous faites de bien, Allah en est, certes, Omniscient"
    },
    {
      "key": 621,
      "id": "4-128",
      "translation": "Et si une femme craint de son mari abandon ou indifférence, alors ce n'est pas un péché pour les deux s'ils se réconcilient par un compromis quelconque, et la réconciliation est meilleure, puisque les âmes sont portées à la ladrerie. Mais si vous agissez en bien et vous êtes pieux... Allah est, certes, Parfaitement Connaisseur de ce que vous faites"
    },
    {
      "key": 622,
      "id": "4-129",
      "translation": "Vous ne pourrez jamais être équitables entre vos femmes, même si vous en êtes soucieux. Ne vous penchez pas tout à fait vers l'une d'elles, au point de laisser l'autre comme en suspens. Mais si vous vous réconciliez et vous êtes pieux... donc Allah est, certes, Pardonneur et Miséricordieux"
    },
    {
      "key": 623,
      "id": "4-130",
      "translation": "Si les deux se séparent, Allah de par Sa largesse, accordera à chacun d'eux un autre destin. Et Allah est plein de largesses et parfaitement Sage"
    },
    {
      "key": 624,
      "id": "4-131",
      "translation": "A Allah seul appartient tout ce qui est dans les cieux et sur la terre. «Craignez Allah!» Voilà ce que Nous avons enjoint à ceux auxquels avant vous le Livre fut donné, tout comme à vous-mêmes. Et si vous ne croyez pas (cela ne nuit pas à Allah, car) très certainement à Allah seul appartient tout ce qui est dans les cieux et sur la terre. Et Allah se suffit à Lui-même et Il est digne de louange"
    },
    {
      "key": 625,
      "id": "4-132",
      "translation": "A Allah seul appartient tout ce qui est dans les cieux et sur la terre. Et Allah suffit pour s'occuper de tout"
    },
    {
      "key": 626,
      "id": "4-133",
      "translation": "S'Il voulait, il vous ferait disparaître, ô gens, et en ferait venir d'autres. Car Allah en est très capable"
    },
    {
      "key": 627,
      "id": "4-134",
      "translation": "Quiconque désire la récompense d'ici-bas, c'est auprès d'Allah qu'est la récompense d'ici-bas tout comme celle de l'au-delà. Et Allah entend et observe tout"
    },
    {
      "key": 628,
      "id": "4-135",
      "translation": "O les croyants! Observez strictement la justice et soyez des témoins (véridiques) comme Allah l'ordonne, fût-ce contre vous-mêmes, contre vos père et mère ou proches parents. Qu'il s'agisse d'un riche ou d'un besogneux, Allah a priorité sur eux deux (et Il est plus connaisseur de leur intérêt que vous). Ne suivez donc pas les passions, afin de ne pas dévier de la justice. Si vous portez un faux témoignage ou si vous le refusez, [sachez qu'] Allah est Parfaitement Connaisseur de ce que vous faites"
    },
    {
      "key": 629,
      "id": "4-136",
      "translation": "O les croyants! Soyez fermes en votre foi en Allah, en Son messager, au Livre qu'Il a fait descendre sur Son messager, et au Livre qu'Il a fait descendre avant. Quiconque ne croit pas en Allah, en Ses anges, en Ses Livres, en Ses messagers et au Jour dernier, s'égare, loin dans l'égarement"
    },
    {
      "key": 630,
      "id": "4-137",
      "translation": "Ceux qui ont cru, puis sont devenus mécréants, puis ont cru de nouveau, ensuite sont redevenus mécréants, et n'ont fait que croître en mécréance, Allah ne leur pardonnera pas, ni les guidera vers un chemin (droit)"
    },
    {
      "key": 631,
      "id": "4-138",
      "translation": "Annonce aux hypocrites qu'il y a pour eux un châtiment douloureux"
    },
    {
      "key": 632,
      "id": "4-139",
      "translation": "ceux qui prennent pour alliés des mécréants au lieu des croyants, est-ce la puissance qu'ils recherchent auprès d'eux? (En vérité) la puissance appartient entièrement à Allah"
    },
    {
      "key": 633,
      "id": "4-140",
      "translation": "Dans le Livre, Il vous a déjà révélé ceci: lorsque vous entendez qu'on renie les versets (le Coran) d'Allah et qu'on s'en raille, ne vous asseyez point avec ceux-là jusqu'à ce qu'ils entreprennent une autre conversation. Sinon, vous serez comme eux. Allah rassemblera, certes, les hypocrites et les mécréants, tous, dans l'Enfer"
    },
    {
      "key": 634,
      "id": "4-141",
      "translation": "Ils restent dans l'expectative à votre égard; si une victoire vous vient de la part d'Allah, ils disent: «N'étions-nous pas avec vous?» et s'il en revient un avantage aux mécréants, ils leur disent: «Est-ce que nous n'avons pas mis la main sur vous pour vous soustraire aux croyants?» Eh bien, Allah jugera entre vous au Jour de la Résurrection. Et jamais Allah ne donnera une voie aux mécréants contre les croyants"
    },
    {
      "key": 635,
      "id": "4-142",
      "translation": "Les hypocrites cherchent à tromper Allah, mais Allah retourne leur tromperie (contre eux-mêmes). Et lorsqu'ils se lèvent pour la Salât, ils se lèvent avec paresse et par ostentation envers les gens. A peine invoquent-ils Allah"
    },
    {
      "key": 636,
      "id": "4-143",
      "translation": "Ils sont indécis (entre les croyants et les mécréants,) n'appartenant ni aux uns ni aux autres. Or, quiconque Allah égare, jamais tu ne trouveras de chemin pour lui"
    },
    {
      "key": 637,
      "id": "4-144",
      "translation": "O les croyants! Ne prenez pas pour alliés les mécréants au lieu des croyants. Voudriez-vous donner à Allah une preuve évidente contre vous"
    },
    {
      "key": 638,
      "id": "4-145",
      "translation": "Les hypocrites seront, certes, au plus bas fond du Feu, et tu ne leur trouveras jamais de secoureur"
    },
    {
      "key": 639,
      "id": "4-146",
      "translation": "sauf ceux qui se repentent, s'amendent, s'attachent fermement à Allah, et Lui vouent une foi exclusive. Ceux-là seront avec les croyants. Et Allah donnera aux croyants une énorme récompense"
    },
    {
      "key": 640,
      "id": "4-147",
      "translation": "Pourquoi Allah vous infligerait-Il un châtiment si vous êtes reconnaissants et croyants? Allah est Reconnaissant et Omniscient"
    },
    {
      "key": 641,
      "id": "4-148",
      "translation": "Allah n'aime pas qu'on profère de mauvaises paroles sauf quand on a été injustement provoqué. Et Allah est Audient et Omniscient"
    },
    {
      "key": 642,
      "id": "4-149",
      "translation": "Que vous fassiez du bien, ouvertement ou en cachette, ou bien que vous pardonniez un mal... Alors Allah est Pardonneur et Omnipotent"
    },
    {
      "key": 643,
      "id": "4-150",
      "translation": "Ceux qui ne croient pas en Allah et en Ses messagers, et qui veulent faire distinction entre Allah et Ses messagers et qui disent: «Nous croyons en certains d'entre eux mais ne croyons pas en d'autres», et qui veulent prendre un chemin intermédiaire (entre la foi et la mécréance)"
    },
    {
      "key": 644,
      "id": "4-151",
      "translation": "les voilà les vrais mécréants! Et Nous avons préparé pour les mécréants un châtiment avilissant"
    },
    {
      "key": 645,
      "id": "4-152",
      "translation": "Et ceux qui croient en Allah et en Ses messagers et qui ne font point de différence entre ces derniers, voilà ceux à qui Il donnera leurs récompenses. Et Allah est Pardonneur et Miséricordieux"
    },
    {
      "key": 646,
      "id": "4-153",
      "translation": "Les gens du Livre te demandent de leur faire descendre du ciel un Livre. Ils ont déjà demandé à Moïse quelque chose de bien plus grave quand ils dirent: «Fais-nous voir Allah à découvert!» Alors la foudre les frappa pour leur tort. Puis ils adoptèrent le Veau (comme idole) même après que les preuves leur furent venues. Nous leur pardonnâmes cela et donnâmes à Moïse une autorité déclarée"
    },
    {
      "key": 647,
      "id": "4-154",
      "translation": "Et pour (obtenir) leur engagement, Nous avons brandi au-dessus d'eux le Mont Tor, Nous leur avons dit: «Entrez par la porte en vous prosternant» Nous leur avons dit: «Ne transgressez pas le Sabbat» et Nous avons pris d'eux un engagement ferme"
    },
    {
      "key": 648,
      "id": "4-155",
      "translation": "(Nous les avons maudits) à cause de leur rupture de l'engagement, leur mécréance aux révélations d'Allah, leur meurtre injustifié des prophètes, et leur parole: «Nos cœurs sont (enveloppés) et imperméables». En réalité, c'est Allah qui a scellé leurs cœurs à cause de leur mécréance, car ils ne croyaient que très peu"
    },
    {
      "key": 649,
      "id": "4-156",
      "translation": "Et à cause de leur mécréance et de l'énorme calomnie qu'ils prononcent contre Marie"
    },
    {
      "key": 650,
      "id": "4-157",
      "translation": "et à cause de leur parole: «Nous avons vraiment tué le Christ, Jésus, fils de Marie, le Messager d'Allah»... Or, ils ne l'ont ni tué ni crucifié; mais ce n'était qu'un faux semblant! Et ceux qui ont discuté sur son sujet sont vraiment dans l'incertitude: ils n'en ont aucune connaissance certaine, ils ne font que suivre des conjectures et ils ne l'ont certainement pas tué"
    },
    {
      "key": 651,
      "id": "4-158",
      "translation": "mais Allah l'a élevé vers Lui. Et Allah est Puissant et Sage"
    },
    {
      "key": 652,
      "id": "4-159",
      "translation": "Il n'y aura personne, parmi les gens du Livre, qui n'aura pas foi en lui avant sa mort. Et au Jour de la Résurrection, il sera témoin contre eux"
    },
    {
      "key": 653,
      "id": "4-160",
      "translation": "C'est à cause des iniquités des Juifs que Nous leur avons rendu illicites les bonnes nourritures qui leur étaient licites, et aussi à cause de ce qu'ils obstruent le sentier d'Allah, (à eux-mêmes et) à beaucoup de monde"
    },
    {
      "key": 654,
      "id": "4-161",
      "translation": "et à cause de ce qu'ils prennent des intérêts usuraires- qui leur étaient pourtant interdits - et parce qu'ils mangent illégalement les biens des gens. A ceux d'entre eux qui sont mécréants Nous avons préparé un châtiment douloureux"
    },
    {
      "key": 655,
      "id": "4-162",
      "translation": "Mais ceux d'entre eux qui sont enracinés dans la connaissance, ainsi que les croyants, (tous) ont foi à ce qu'on a fait descendre sur toi et à ce qu'on a fait descendre avant toi. Et quant à ceux qui accomplissent la Salât, paient la Zakât et croient en Allah et au Jour dernier, ceux-là Nous leur donnerons une énorme récompense"
    },
    {
      "key": 656,
      "id": "4-163",
      "translation": "Nous t'avons fait une révélation comme Nous fîmes à Noé et aux prophètes après lui. Et Nous avons fait révélation à Abraham, à Ismaël, à Isaac, à Jacob aux Tribus, à Jésus, à Job, à Jonas, à Aaron et à Salomon, et Nous avons donné le Zabour à David"
    },
    {
      "key": 657,
      "id": "4-164",
      "translation": "Et il y a des messagers dont Nous t'avons raconté l'histoire précédemment, et des messagers dont Nous ne t'avons point raconté l'histoire - et Allah a parlé à Moïse de vive voix"
    },
    {
      "key": 658,
      "id": "4-165",
      "translation": "en tant que messagers, annonciateurs et avertisseurs, afin qu'après la venue des messagers il n'y eût pour les gens point d'argument devant Allah. Allah est Puissant et Sage"
    },
    {
      "key": 659,
      "id": "4-166",
      "translation": "Mais Allah témoigne de ce qu'Il a fait descendre vers toi, Il l'a fait descendre en toute connaissance. Et les Anges en témoignent. Et Allah suffit comme témoin"
    },
    {
      "key": 660,
      "id": "4-167",
      "translation": "Ceux qui ne croient pas et qui obstruent le sentier d'Allah, s'égarent certes loin dans l'égarement"
    },
    {
      "key": 661,
      "id": "4-168",
      "translation": "Ceux qui ne croient pas et qui pratiquent l'injustice, Allah n'est nullement disposé à leur pardonner, ni à les guider dans un chemin"
    },
    {
      "key": 662,
      "id": "4-169",
      "translation": "(autre) que le chemin de l'Enfer où ils demeureront éternellement. Et cela est facile à Allah"
    },
    {
      "key": 663,
      "id": "4-170",
      "translation": "O gens! Le Messager vous a apporté la vérité de la part de votre Seigneur. Ayez la foi, donc, cela vous sera meilleur. Et si vous ne croyez pas (qu'importe!), c'est à Allah qu'appartient tout ce qui est dans les cieux et sur la terre. Et Allah est Omniscient et Sage"
    },
    {
      "key": 664,
      "id": "4-171",
      "translation": "O gens du Livre (Chrétiens), n'exagérez pas dans votre religion, et ne dites d'Allah que la vérité. Le Messie Jésus, fils de Marie, n'est qu'un Messager d'Allah, Sa parole qu'Il envoya à Marie, et un souffle (de vie) venant de Lui. Croyez donc en Allah et en Ses messagers. Et ne dites pas «Trois». Cessez! Ce sera meilleur pour vous. Allah n'est qu'un Dieu unique. Il est trop glorieux pour avoir un enfant. C'est à Lui qu'appartient tout ce qui est dans les cieux et sur la terre et Allah suffit comme protecteur"
    },
    {
      "key": 665,
      "id": "4-172",
      "translation": "Jamais le Messie ne trouve indigne d'être un serviteur d'Allah, ni les Anges rapprochés [de Lui]. Et ceux qui trouvent indigne de L'adorer et s'enflent d'orgueil... Il les rassemblera tous vers Lui"
    },
    {
      "key": 666,
      "id": "4-173",
      "translation": "Quant à ceux qui ont cru et fait de bonnes œuvres, Il leur accordera leurs pleines récompenses et y ajoutera le surcroît de Sa grâce. Et quant à ceux qui ont eu la morgue et se sont enflés d'orgueil, Il les châtiera d'un châtiment douloureux. Et ils ne trouveront, pour eux, en dehors d'Allah, ni allié ni secoureur"
    },
    {
      "key": 667,
      "id": "4-174",
      "translation": "O gens! Certes, une preuve évidente vous est venue de la part de votre Seigneur. Et Nous avons fait descendre vers vous une lumière éclatante"
    },
    {
      "key": 668,
      "id": "4-175",
      "translation": "Alors ceux qui croient en Allah et qui s'attachent à Lui, Il les fera entrer dans une miséricorde venue de Lui, et dans une grâce aussi. Et Il les guidera vers Lui dans un chemin droit"
    },
    {
      "key": 669,
      "id": "4-176",
      "translation": "Ils te demandent ce qui a été décrété. Dis: «Au sujet du défunt qui n'a pas de père ni de mère ni d'enfant, Allah vous donne Son décret: si quelqu'un meurt sans enfant, mais a une sœur, à celle-ci revient la moitié de ce qu'il laisse. Et lui, il héritera d'elle en totalité si elle n'a pas d'enfant. Mais s'il a deux sœurs (ou plus), à elles alors les deux tiers de ce qu'il laisse; et s'il a des frères et des sœurs, à un frère alors revient une portion égale à celle de deux sœurs. Allah vous donne des explications pour que vous ne vous égariez pas. Et Allah est Omniscient"
    },
    {
      "key": 670,
      "id": "5-1",
      "translation": "O les croyants! Remplissez fidèlement vos engagements. Vous est permise la bête du cheptel, sauf ce qui sera énoncé [comme étant interdit]. Ne vous permettez point la chasse alors que vous êtes en état d'ihrâm. Allah en vérité, décide ce qu'Il veut"
    },
    {
      "key": 671,
      "id": "5-2",
      "translation": "O les croyants! Ne profanez ni les rites du pèlerinage (dans les endroits sacrés) d'Allah, ni le mois sacré, ni les animaux de sacrifice, ni les guirlandes, ni ceux qui se dirigent vers la Maison sacrée cherchant de leur Seigneur grâce et agrément. Une fois désacralisés, vous êtes libres de chasser. Et ne laissez pas la haine pour un peuple qui vous a obstrué la route vers la Mosquée sacrée vous inciter à transgresser. Entraidez-vous dans l'accomplissement des bonnes œuvres et de la piété et ne vous entraidez pas dans le péché et la transgression. Et craignez Allah, car Allah est, certes, dur en punition"
    },
    {
      "key": 672,
      "id": "5-3",
      "translation": "Vous sont interdits la bête trouvée morte, le sang, la chair de porc, ce sur quoi on a invoqué un autre nom que celui d'Allah, la bête étouffée, la bête assommée ou morte d'une chute ou morte d'un coup de corne, et celle qu'une bête féroce a dévorée - sauf celle que vous égorgez avant qu'elle ne soit morte -. (Vous sont interdits aussi la bête) qu'on a immolée sur les pierres dressées, ainsi que de procéder au partage par tirage au sort au moyen de flèches. Car cela est perversité. Aujourd'hui, les mécréants désespèrent (de vous détourner) de votre religion: ne les craignez donc pas et craignez-Moi. Aujourd'hui, J'ai parachevé pour vous votre religion, et accompli sur vous Mon bienfait. Et J'agrée l'Islam comme religion pour vous. Si quelqu'un est contraint par la faim, sans inclination vers le péché... alors, Allah est Pardonneur et Miséricordieux"
    },
    {
      "key": 673,
      "id": "5-4",
      "translation": "Ils t'interrogent sur ce qui leur est permis. Dis: «Vous sont permises les bonnes nourritures, ainsi que ce que capturent les carnassiers que vous avez dressés, en leur apprenant ce qu'Allah vous a appris. Mangez donc de ce qu'elles capturent pour vous et prononcez dessus le nom d'Allah. Et craignez Allah. Car Allah est, certes, prompt dans les comptes"
    },
    {
      "key": 674,
      "id": "5-5",
      "translation": "«Vous sont permises, aujourd'hui, les bonnes nourritures. Vous est permise la nourriture des gens du Livre, et votre propre nourriture leur est permise. (Vous sont permises) les femmes vertueuses d'entre les croyantes, et les femmes vertueuses d'entre les gens qui ont reçu le Livre avant vous, si vous leur donnez leur mahr avec contrat de mariage, non en débauchés ni en preneurs d'amantes. Et quiconque abjure la foi, alors vaine devient son action, et il sera dans l'au-delà, du nombre des perdants"
    },
    {
      "key": 675,
      "id": "5-6",
      "translation": "O les croyants! Lorsque vous vous levez pour la Salât, lavez vos visages et vos mains jusqu'aux coudes; passez les mains mouillées sur vos têtes; et lavez-vous les pieds jusqu'aux chevilles. Et si vous êtes pollués «junub», alors purifiez-vous (par un bain); mais si vous êtes malades, ou en voyage, ou si l'un de vous revient du lieu où il a fait ses besoins ou si vous avez touché aux femmes et que vous ne trouviez pas d'eau, alors recourez à la terre pure, passez-en sur vos visages et vos mains. Allah ne veut pas vous imposer quelque gêne, mais Il veut vous purifier et parfaire sur vous Son bienfait. Peut-être serez-vous reconnaissants"
    },
    {
      "key": 676,
      "id": "5-7",
      "translation": "Et rappelez-vous le bienfait d'Allah sur vous, ainsi que l'alliance qu'Il a conclue avec vous, quand vous avez dit: «Nous avons entendu et nous avons obéi». Et craignez Allah. Car Allah connaît parfaitement le contenu des cœurs"
    },
    {
      "key": 677,
      "id": "5-8",
      "translation": "O les croyants! Soyez stricts (dans vos devoirs) envers Allah et (soyez) des témoins équitables. Et que la haine pour un peuple ne vous incite pas à être injustes. Pratiquez l'équité: cela est plus proche de la piété. Et craignez Allah. Car Allah est certes Parfaitement Connaisseur de ce que vous faites"
    },
    {
      "key": 678,
      "id": "5-9",
      "translation": "Allah a promis à ceux qui croient et font de bonnes œuvres qu'il y aura pour eux un pardon et une énorme récompense"
    },
    {
      "key": 679,
      "id": "5-10",
      "translation": "Quant à ceux qui ne croient pas et traitent de mensonge Nos preuves, ceux-là sont des gens de l'Enfer"
    },
    {
      "key": 680,
      "id": "5-11",
      "translation": "O les croyants! Rappelez-vous le bienfait d'Allah à votre égard, le jour où un groupe d'ennemis s'apprêtait à porter la main sur vous (en vue de vous attaquer) et qu'Il repoussa leur tentative. Et craignez Allah. C'est en Allah que les croyants doivent mettre leur confiance"
    },
    {
      "key": 681,
      "id": "5-12",
      "translation": "Et Allah certes prit l'engagement des enfants d'Israël. Nous nommâmes douze chefs d'entre eux. Et Allah dit: «Je suis avec vous, pourvu que vous accomplissiez la Salât, acquittiez la Zakât, croyiez en Mes messagers, les aidiez et fassiez à Allah un bon prêt. Alors, certes, j'effacerai vos méfaits, et vous ferai entrer aux Jardins sous lesquels coulent les ruisseaux. Et quiconque parmi vous, après cela, mécroit, s'égare certes du droit chemin»"
    },
    {
      "key": 682,
      "id": "5-13",
      "translation": "Et puis, à cause de leur violation de l'engagement, Nous les avons maudits et endurci leurs cœurs: ils détournent les paroles de leur sens et oublient une partie de ce qui leur a été rappelé. Tu ne cesseras de découvrir leur trahison, sauf d'un petit nombre d'entre eux. Pardonne-leur donc et oublie [leurs fautes]. Car Allah aime, certes, les bienfaisants"
    },
    {
      "key": 683,
      "id": "5-14",
      "translation": "Et de ceux qui disent: «Nous sommes chrétiens», Nous avons pris leur engagement. Mais ils ont oublié une partie de ce qui leur a été rappelé. Nous avons donc suscité entre eux l'inimitié et la haine jusqu'au Jour de la Résurrection. Et Allah les informera de ce qu'ils faisaient"
    },
    {
      "key": 684,
      "id": "5-15",
      "translation": "O gens du Livre! Notre Messager  vous est certes venu, vous exposant beaucoup de ce que vous cachiez du Livre, et passant sur bien d'autres choses! Une lumière et un Livre explicite vous sont certes venus d'Allah"
    },
    {
      "key": 685,
      "id": "5-16",
      "translation": "Par ceci (le Coran), Allah guide aux chemins du salut ceux qui cherchent Son agrément. Et Il les fait sortir des ténèbres à la lumière par Sa grâce. Et Il les guide vers un chemin droit"
    },
    {
      "key": 686,
      "id": "5-17",
      "translation": "Certes sont mécréants ceux qui disent: «Allah, c'est le Messie, fils de Marie!» - Dis: «Qui donc détient quelque chose d'Allah (pour L'empêcher), s'Il voulait faire périr le Messie, fils de Marie, ainsi que sa mère et tous ceux qui sont sur la terre?... A Allah seul appartient la royauté des cieux et de la terre et de ce qui se trouve entre les deux». Il crée ce qu'Il veut. Et Allah est Omnipotent"
    },
    {
      "key": 687,
      "id": "5-18",
      "translation": "Les Juifs et les Chrétiens ont dit: «Nous sommes les fils d'Allah et Ses préférés.» Dis: «Pourquoi donc vous châtie-t-Il pour vos péchés?» En fait, vous êtes des êtres humains d'entre ceux qu'Il a créés. Il pardonne à qui Il veut et Il châtie qui Il veut. Et à Allah seul appartient la royauté des cieux et de la terre et de ce qui se trouve entre les deux. Et c'est vers Lui que sera la destination finale"
    },
    {
      "key": 688,
      "id": "5-19",
      "translation": "O gens du Livre! Notre Messager  est venu pour vous éclairer après une interruption des messagers afin que vous ne disiez pas: «Il ne nous est venu ni annonciateur ni avertisseur». Voilà, certes, que vous est venu un annonciateur et un avertisseur. Et Allah est Omnipotent"
    },
    {
      "key": 689,
      "id": "5-20",
      "translation": "(Souvenez-vous) Lorsque Moïse dit à son peuple: «O, mon peuple! Rappelez-vous le bienfait d'Allah sur vous, lorsqu'Il a désigné parmi vous des prophètes. Et Il a fait de vous des rois. Et Il vous a donné ce qu'Il n'avait donné à nul autre aux mondes"
    },
    {
      "key": 690,
      "id": "5-21",
      "translation": "O mon peuple! Entrez dans la terre sainte qu'Allah vous a prescrite. Et ne revenez point sur vos pas [en refusant de combattre] car vous retourneriez perdants"
    },
    {
      "key": 691,
      "id": "5-22",
      "translation": "Ils dirent: «O Moïse, il y a là un peuple de géants. Jamais nous n'y entrerons jusqu'à ce qu'ils en sortent. S'ils en sortent, alors nous y entrerons»"
    },
    {
      "key": 692,
      "id": "5-23",
      "translation": "Deux hommes d'entre ceux qui craignaient Allah et qui étaient comblés par Lui de bienfaits dirent: «Entrez chez eux par la porte; puis quand vous y serez entrés, vous serez sans doute les dominants. Et c'est en Allah qu'il faut avoir confiance, si vous êtes croyants»"
    },
    {
      "key": 693,
      "id": "5-24",
      "translation": "Ils dirent: «Moïse! Nous n'y entrerons jamais, aussi longtemps qu'ils y seront. Va donc, toi et ton Seigneur, et combattez tous deux. Nous restons là où nous sommes»"
    },
    {
      "key": 694,
      "id": "5-25",
      "translation": "Il dit: «Seigneur! Je n'ai de pouvoir, vraiment, que sur moi-même et sur mon frère: sépare-nous donc de ce peuple pervers»"
    },
    {
      "key": 695,
      "id": "5-26",
      "translation": "Il (Allah) dit: «Eh bien, ce pays leur sera interdit pendant quarante ans, durant lesquels ils erreront sur la terre. Ne te tourmente donc pas pour ce peuple pervers»"
    },
    {
      "key": 696,
      "id": "5-27",
      "translation": "Et raconte-leur en toute vérité l'histoire des deux fils d'Adam. Les deux offrirent des sacrifices; celui de l'un fut accepté et celui de l'autre ne le fut pas. Celui-ci dit: «Je te tuerai sûrement». «Allah n'accepte, dit l'autre, que de la part des pieux»"
    },
    {
      "key": 697,
      "id": "5-28",
      "translation": "Si tu étends vers moi ta main pour me tuer, moi, je n'étendrai pas vers toi ma main pour te tuer: car je crains Allah, le Seigneur de l'Univers"
    },
    {
      "key": 698,
      "id": "5-29",
      "translation": "Je veux que tu partes avec le péché de m'avoir tué et avec ton propre péché: alors tu seras du nombre des gens du Feu. Telle est la récompense des injustes"
    },
    {
      "key": 699,
      "id": "5-30",
      "translation": "Son âme l'incita à tuer son frère. Il le tua donc et devint ainsi du nombre des perdants"
    },
    {
      "key": 700,
      "id": "5-31",
      "translation": "Puis Allah envoya un corbeau qui se mit à gratter la terre pour lui montrer comment ensevelir le cadavre de son frère. Il dit: «Malheur à moi! Suis-je incapable d'être, comme ce corbeau, à même d'ensevelir le cadavre de mon frère?» Il devint alors du nombre de ceux que ronge le remords"
    },
    {
      "key": 701,
      "id": "5-32",
      "translation": "C'est pourquoi Nous avons prescrit pour les Enfants d'Israël que quiconque tuerait une personne non coupable d'un meurtre ou d'une corruption sur la terre, c'est comme s'il avait tué tous les hommes. Et quiconque lui fait don de la vie, c'est comme s'il faisait don de la vie à tous les hommes. En effet Nos messagers sont venus à eux avec les preuves. Et puis voilà, qu'en dépit de cela, beaucoup d'entre eux se mettent à commettre des excès sur la terre"
    },
    {
      "key": 702,
      "id": "5-33",
      "translation": "La récompense de ceux qui font la guerre contre Allah et Son messager, et qui s'efforcent de semer la corruption sur la terre, c'est qu'ils soient tués, ou crucifiés, ou que soient coupées leur main et leur jambe opposées, ou qu'ils soient expulsés du pays. Ce sera pour eux l'ignominie ici-bas; et dans l'au-delà, il y aura pour eux un énorme châtiment"
    },
    {
      "key": 703,
      "id": "5-34",
      "translation": "excepté ceux qui se sont repentis avant de tomber en votre pouvoir: sachez qu'alors, Allah est Pardonneur et Miséricordieux"
    },
    {
      "key": 704,
      "id": "5-35",
      "translation": "O les croyants! Craignez Allah, cherchez le moyen de vous rapprocher de Lui et luttez pour Sa cause. Peut-être serez-vous de ceux qui réussissent"
    },
    {
      "key": 705,
      "id": "5-36",
      "translation": "Si les mécréants possédaient tout ce qui est sur la terre et autant encore, pour se racheter du châtiment du Jour de la Résurrection, on ne l'accepterait pas d'eux. Et pour eux il y aura un châtiment douloureux"
    },
    {
      "key": 706,
      "id": "5-37",
      "translation": "Ils voudront sortir du Feu, mais ils n'en sortiront point. Et ils auront un châtiment permanent"
    },
    {
      "key": 707,
      "id": "5-38",
      "translation": "Le voleur et la voleuse, à tous deux coupez la main, en punition de ce qu'ils se sont acquis, et comme châtiment de la part d'Allah. Allah est Puissant et Sage"
    },
    {
      "key": 708,
      "id": "5-39",
      "translation": "Mais quiconque se repent après son tort et se réforme, Allah accepte son repentir. Car, Allah est, certes, Pardonneur et Miséricordieux"
    },
    {
      "key": 709,
      "id": "5-40",
      "translation": "Ne sais-tu pas qu'à Allah appartient la royauté des cieux et de la terre? Il châtie qui Il veut et pardonne à qui Il veut. Et Allah est Omnipotent"
    },
    {
      "key": 710,
      "id": "5-41",
      "translation": "O Messager! Que ne t'affligent point ceux qui concourent en mécréance; parmi ceux qui ont dit: «Nous avons cru» avec leurs bouches sans que leurs cœurs aient jamais cru et parmi les Juifs qui aiment bien écouter le mensonge et écouter d'autres gens qui ne sont jamais venus à toi et qui déforment le sens des mots une fois bien établi. Ils disent: «Si vous avez reçu ceci, acceptez-le et si vous ne l'avez pas reçu, soyez méfiants». Celui qu'Allah veut éprouver, tu n'as pour lui aucune protection contre Allah. Voilà ceux dont Allah n'a point voulu purifier les cœurs. A eux, seront réservés, une ignominie ici-bas et un énorme châtiment dans l'au-delà"
    },
    {
      "key": 711,
      "id": "5-42",
      "translation": "Ils sont attentifs au mensonge et voraces de gains illicites. S'ils viennent à toi, sois juge entre eux ou détourne toi d'eux. Et si tu te détournes d'eux, jamais ils ne pourront te faire aucun mal. Et si tu juges, alors juge entre eux en équité. Car Allah aime ceux qui jugent équitablement"
    },
    {
      "key": 712,
      "id": "5-43",
      "translation": "Mais comment te demanderaient-ils d'être leur juge quand ils ont avec eux la Thora dans laquelle se trouve le jugement d'Allah? Et puis, après cela, ils rejettent ton jugement. Ces gens-là ne sont nullement les croyants"
    },
    {
      "key": 713,
      "id": "5-44",
      "translation": "Nous avons fait descendre la Thora dans laquelle il y a guide et lumière. C'est sur sa base que les prophètes qui se sont soumis à Allah, ainsi que les rabbins et les docteurs jugent les affaires des Juifs. Car on leur a confié la garde du Livre d'Allah, et ils en sont les témoins. Ne craignez donc pas les gens, mais craignez Moi. Et ne vendez pas Mes enseignements à vil prix. Et ceux qui ne jugent pas d'après ce qu'Allah a fait descendre, les voilà les mécréants"
    },
    {
      "key": 714,
      "id": "5-45",
      "translation": "Et Nous y avons prescrit pour eux vie pour vie, œil pour œil, nez pour nez, oreille pour oreille, dent pour dent. Les blessures tombent sous la loi du talion. Après, quiconque y renonce par charité, cela lui vaudra une expiation. Et ceux qui ne jugent pas d'après ce qu'Allah a fait descendre, ceux-là sont des injustes"
    },
    {
      "key": 715,
      "id": "5-46",
      "translation": "Et Nous avons envoyé après eux Jésus, fils de Marie, pour confirmer ce qu'il y avait dans la Thora avant lui. Et Nous lui avons donné l'Evangile, où il y a guide et lumière, pour confirmer ce qu'il y avait dans la Thora avant lui, et un guide et une exhortation pour les pieux"
    },
    {
      "key": 716,
      "id": "5-47",
      "translation": "Que les gens de l'Evangile jugent d'après ce qu'Allah y a fait descendre. Ceux qui ne jugent pas d'après ce qu'Allah a fait descendre, ceux-là sont les pervers"
    },
    {
      "key": 717,
      "id": "5-48",
      "translation": "Et sur toi  Nous avons fait descendre le Livre avec la vérité, pour confirmer le Livre qui était là avant lui et pour prévaloir sur lui. Juge donc parmi eux d'après ce qu'Allah a fait descendre. Ne suis pas leurs passions, loin de la vérité qui t'est venue. A chacun de vous Nous avons assigné une législation et un plan à suivre. Si Allah avait voulu, certes Il aurait fait de vous tous une seule communauté. Mais Il veut vous éprouver en ce qu'Il vous donne. Concurrencez donc dans les bonnes cœvres. C'est vers Allah qu'est votre retour à tous; alors Il vous informera de ce en quoi vous divergiez"
    },
    {
      "key": 718,
      "id": "5-49",
      "translation": "Juge alors parmi eux d'après ce qu'Allah a fait descendre. Ne suis pas leurs passions, et prends garde qu'ils ne tentent de t'éloigner d'une partie de ce qu'Allah t'a révélé. Et puis, s'ils refusent (le jugement révélé) sache qu'Allah veut les affliger [ici-bas] pour une partie de leurs péchés. Beaucoup de gens, certes, sont des pervers"
    },
    {
      "key": 719,
      "id": "5-50",
      "translation": "Est-ce donc le jugement du temps de l'Ignorance qu'ils cherchent? Qu'y a-t-il de meilleur qu'Allah, en matière de jugement pour des gens qui ont une foi ferme"
    },
    {
      "key": 720,
      "id": "5-51",
      "translation": "O les croyants! Ne prenez pas pour alliés les Juifs et les Chrétiens; ils sont alliés les uns des autres. Et celui d'entre vous qui les prend pour alliés, devient un des leurs. Allah ne guide certes pas les gens injustes"
    },
    {
      "key": 721,
      "id": "5-52",
      "translation": "Tu verras, d'ailleurs, que ceux qui ont la maladie au cœur se précipitent vers eux et disent: «Nous craignons qu'un revers de fortune ne nous frappe.» Mais peut-être qu'Allah fera venir la victoire ou un ordre émanant de Lui. Alors ceux-là regretteront leurs pensées secrètes"
    },
    {
      "key": 722,
      "id": "5-53",
      "translation": "Et les croyants diront: «Est-ce là ceux qui juraient par Allah de toute leur force qu'ils étaient avec vous?» Mais leurs actions sont devenues vaines et ils sont devenus perdants"
    },
    {
      "key": 723,
      "id": "5-54",
      "translation": "O les croyants! Quiconque parmi vous apostasie de sa religion... Allah va faire venir un peuple qu'Il aime et qui L'aime, modeste envers les croyants et fier et puissant envers les mécréants, qui lutte dans le sentier d'Allah, ne craignant le blâme d'aucun blâmeur. Telle est la grâce d'Allah. Il la donne à qui Il veut. Allah est Immense et Omniscient"
    },
    {
      "key": 724,
      "id": "5-55",
      "translation": "Vous n'avez d'autres alliés qu'Allah, Son messager, et les croyants qui accomplissent la Salât, s'acquittent de la Zakât, et s'inclinent (devant Allah)"
    },
    {
      "key": 725,
      "id": "5-56",
      "translation": "Et quiconque prend pour alliés Allah, Son messager et les croyants, [réussira] car c'est le parti d'Allah qui sera victorieux"
    },
    {
      "key": 726,
      "id": "5-57",
      "translation": "O les croyants! N'adoptez pas pour alliés ceux qui prennent en raillerie et jeu votre religion, parmi ceux à qui le Livre fut donné avant vous et parmi les mécréants. Et craignez Allah si vous êtes croyants"
    },
    {
      "key": 727,
      "id": "5-58",
      "translation": "Et lorsque vous faites l'appel à la Salât, ils la prennent en raillerie et jeu. C'est qu'ils sont des gens qui ne raisonnent point"
    },
    {
      "key": 728,
      "id": "5-59",
      "translation": "Dis: «O gens du Livre! Est-ce que vous nous reprochez autre chose que de croire en Allah, à ce qu'on a fait descendre vers nous et à ce qu'on a fait descendre auparavant? Mais la plupart d'entre vous sont des pervers"
    },
    {
      "key": 729,
      "id": "5-60",
      "translation": "Dis: «Puis-je vous informer de ce qu'il y a de pire, en fait de rétribution auprès d'Allah? Celui qu'Allah a maudit, celui qui a encouru Sa colère, et ceux dont Il a fait des singes, des porcs, et de même, celui qui a adoré le Tâghût, ceux-là ont la pire des places et sont les plus égarés du chemin droit»"
    },
    {
      "key": 730,
      "id": "5-61",
      "translation": "Lorsqu'ils viennent chez vous, ils disent: «Nous croyons.» Alors qu'ils sont entrés avec la mécréance et qu'ils sont sortis avec. Et Allah sait parfaitement ce qu'ils cachent"
    },
    {
      "key": 731,
      "id": "5-62",
      "translation": "Et tu verras beaucoup d'entre eux se précipiter vers le péché et l'iniquité, et manger des gains illicites. Comme est donc mauvais ce qu'ils œuvrent"
    },
    {
      "key": 732,
      "id": "5-63",
      "translation": "Pourquoi les rabbins et les docteurs (de la Loi religieuse) ne les empêchent-ils pas de tenir des propos mensongers et de manger des gains illicites? Que leurs actions sont donc mauvaises"
    },
    {
      "key": 733,
      "id": "5-64",
      "translation": "Et les Juifs disent: «La main d'Allah est fermée!» Que leurs propres mains soient fermées, et maudits soient-ils pour l'avoir dit. Au contraire, Ses deux mains sont largement ouvertes: Il distribue Ses dons comme Il veut. Et certes, ce qui a été descendu vers toi de la part de ton Seigneur va faire beaucoup croître parmi eux la rébellion et la mécréance. Nous avons jeté parmi eux l'inimitié et la haine jusqu'au Jour de la Résurrection. Toutes les fois qu'ils allument un feu pour la guerre, Allah l'éteint. Et ils s'efforcent de semer le désordre sur la terre, alors qu'Allah n'aime pas les semeurs de désordre"
    },
    {
      "key": 734,
      "id": "5-65",
      "translation": "Si les gens du Livre avaient la foi et la piété, Nous leur aurions certainement effacé leurs méfaits et les aurions certainement introduits dans les Jardins du délice"
    },
    {
      "key": 735,
      "id": "5-66",
      "translation": "S'ils avaient appliqué la Thora et l'Evangile et ce qui est descendu sur eux de la part de leur Seigneur, ils auraient certainement joui de ce qui est au-dessus d'eux et de ce qui est sous leurs pieds. Il y a parmi eux un groupe qui agit avec droiture; mais pour beaucoup d'entre eux, comme est mauvais ce qu'ils font"
    },
    {
      "key": 736,
      "id": "5-67",
      "translation": "O Messager, transmets ce qui t'a été descendu de la part de ton Seigneur. Si tu ne le faisais pas, alors tu n'aurais pas communiqué Son message. Et Allah te protègera des gens. Certes, Allah ne guide pas les gens mécréants"
    },
    {
      "key": 737,
      "id": "5-68",
      "translation": "Dis: «O gens du Livre, vous ne tenez sur rien, tant que vous ne vous conformez pas à la Thora et à l'Evangile et à ce qui vous a été descendu de la part de votre Seigneur.» Et certes, ce qui t'a été descendu de la part de ton Seigneur va accroître beaucoup d'entre eux en rébellion et en mécréance. Ne te tourmente donc pas pour les gens mécréants"
    },
    {
      "key": 738,
      "id": "5-69",
      "translation": "Ceux qui ont cru, ceux qui se sont judaïsés, les Sabéens, et les Chrétiens, ceux parmi eux qui croient en Allah, au Jour dernier et qui accomplissent les bonnes œuvres, pas de crainte sur eux, et ils ne seront point affligés"
    },
    {
      "key": 739,
      "id": "5-70",
      "translation": "Certes, Nous avions déjà pris l'engagement des Enfants d'Israël, et Nous leur avions envoyé des messagers. Mais chaque fois qu'un Messager leur vient avec ce qu'ils ne désirent pas, ils en traitent certains de menteurs et ils en tuent d'autres"
    },
    {
      "key": 740,
      "id": "5-71",
      "translation": "Comptant qu'il n'y aurait pas de tentation contre eux, ils étaient devenus aveugles et sourds. Puis Allah accueillit leur repentir. Ensuite, beaucoup d'entre eux redevinrent aveugles et sourds. Et Allah voit parfaitement ce qu'ils font"
    },
    {
      "key": 741,
      "id": "5-72",
      "translation": "Ce sont, certes, des mécréants ceux qui disent: «En vérité, Allah c'est le Messie, fils de Marie.» Alors que le Messie a dit: «O enfants d'Israël, adorez Allah, mon Seigneur et votre Seigneur». Quiconque associe à Allah (d'autres divinités,) Allah lui interdit le Paradis; et son refuge sera le Feu. Et pour les injustes, pas de secoureurs"
    },
    {
      "key": 742,
      "id": "5-73",
      "translation": "Ce sont certes des mécréants, ceux qui disent: «En vérité, Allah est le troisième de trois.» Alors qu'il n'y a de divinité qu'Une Divinité Unique! Et s'ils ne cessent de le dire, certes, un châtiment douloureux touchera les mécréants d'entre eux"
    },
    {
      "key": 743,
      "id": "5-74",
      "translation": "Ne vont-ils donc pas se repentir à Allah et implorer Son pardon? Car Allah est Pardonneur et Miséricordieux"
    },
    {
      "key": 744,
      "id": "5-75",
      "translation": "Le Messie, fils de Marie, n'était qu'un Messager. Des messagers sont passés avant lui. Et sa mère était une véridique. Et tous deux consommaient de la nourriture. Vois comme Nous leur expliquons les preuves et puis vois comme ils se détournent"
    },
    {
      "key": 745,
      "id": "5-76",
      "translation": "Dis: «Adorez-vous, au lieu d'Allah, ce qui n'a le pouvoir de vous faire ni le mal ni le bien?» Or c'est Allah qui est l'Audient et l'Omniscient"
    },
    {
      "key": 746,
      "id": "5-77",
      "translation": "Dis: «O gens du Livre, n'exagérez pas en votre religion, s'opposant à la vérité. Ne suivez pas les passions des gens qui se sont égarés avant cela, qui ont égaré beaucoup de monde et qui se sont égarés du chemin droit"
    },
    {
      "key": 747,
      "id": "5-78",
      "translation": "Ceux des Enfants d'Israël qui n'avaient pas cru ont été maudits par la bouche de David et de Jésus fils de Marie, parce qu'ils désobéissaient et transgressaient"
    },
    {
      "key": 748,
      "id": "5-79",
      "translation": "Ils ne s'interdisaient pas les uns aux autres ce qu'ils faisaient de blâmable. Comme est mauvais, certes, ce qu'ils faisaient"
    },
    {
      "key": 749,
      "id": "5-80",
      "translation": "Tu vois beaucoup d'entre eux s'allier aux mécréants. Comme est mauvais, certes, ce que leurs âmes ont préparé, pour eux-mêmes, de sorte qu'ils ont encouru le courroux d'Allah, et c'est dans le supplice qu'ils éterniseront"
    },
    {
      "key": 750,
      "id": "5-81",
      "translation": "S'ils croyaient en Allah, au Prophète et à ce qui lui a été descendu, ils ne prendraient pas ces mécréants pour alliés. Mais beaucoup d'entre eux sont pervers"
    },
    {
      "key": 751,
      "id": "5-82",
      "translation": "Tu trouveras certainement que les Juifs et les associateurs sont les ennemis les plus acharnés des croyants. Et tu trouveras certes que les plus disposés à aimer les croyants sont ceux qui disent: «Nous sommes chrétiens». C'est qu'il y a parmi eux des prêtres et des moines, et qu'ils ne s'enflent pas d'orgueil"
    },
    {
      "key": 752,
      "id": "5-83",
      "translation": "Et quand ils entendent ce qui a été descendu sur le Messager, tu vois leurs yeux déborder de larmes, parce qu'ils ont reconnu la vérité. Ils disent: «O notre Seigneur! Nous croyons: inscris-nous donc parmi ceux qui témoignent (de la véracité du Coran)"
    },
    {
      "key": 753,
      "id": "5-84",
      "translation": "Pourquoi ne croirions-nous pas en Allah et à ce qui nous est parvenu de la vérité. Pourquoi ne convoitions-nous pas que notre Seigneur nous fasse entrer en la compagnie des gens vertueux?»"
    },
    {
      "key": 754,
      "id": "5-85",
      "translation": "Allah donc les récompense pour ce qu'ils disent par des Jardins sous lesquels coulent les ruisseaux, où ils demeureront éternellement. Telle est la récompense des bienfaisants"
    },
    {
      "key": 755,
      "id": "5-86",
      "translation": "Et quant à ceux qui ne croient pas et qui traitent de mensonges Nos versets, ce sont les gens de la Fournaise"
    },
    {
      "key": 756,
      "id": "5-87",
      "translation": "O les croyants: ne déclarez pas illicites les bonnes choses qu'Allah vous a rendues licites. Et ne transgressez pas. Allah, (en vérité,) n'aime pas les transgresseurs"
    },
    {
      "key": 757,
      "id": "5-88",
      "translation": "Et mangez de ce qu'Allah vous a attribué de licite et de bon. Craignez Allah, en qui vous avez foi"
    },
    {
      "key": 758,
      "id": "5-89",
      "translation": "Allah ne vous sanctionne pas pour la frivolité dans vos serments, mais Il vous sanctionne pour les serments que vous avez l'intention d'exécuter. L'expiation en sera de nourrir dix pauvres, de ce dont vous nourrissez normalement vos familles, ou de les habiller, ou de libérer un esclave. Quiconque n'en trouve pas les moyens devra jeûner trois jours. Voilà l'expiation pour vos serments, lorsque vous avez juré. Et tenez à vos serments. Ainsi Allah vous explique Ses versets, afin que vous soyez reconnaissants"
    },
    {
      "key": 759,
      "id": "5-90",
      "translation": "O les croyants! Le vin, le jeu de hasard, les pierres dressées, les flèches de divination ne sont qu'une abomination, œuvre du Diable. Ecartez-vous en, afin que vous réussissiez"
    },
    {
      "key": 760,
      "id": "5-91",
      "translation": "Le Diable ne veut que jeter parmi vous, à travers le vin et le jeu de hasard, l'inimitié et la haine, et vous détourner d'invoquer Allah et de la Salât. Allez-vous donc y mettre fin"
    },
    {
      "key": 761,
      "id": "5-92",
      "translation": "Obéissez à Allah, obéissez au Messager, et prenez garde! Si ensuite vous vous détournez... alors sachez qu'il n'incombe à Notre messager que de transmettre le message clairement"
    },
    {
      "key": 762,
      "id": "5-93",
      "translation": "Ce n'est pas un péché pour ceux qui ont la foi et font de bonnes œuvres en ce qu'ils ont consommé (du vin et des gains des jeux de hasard avant leur prohibition) pourvu qu'ils soient pieux (en évitant les choses interdites après en avoir eu connaissance) et qu'ils croient (en acceptant leur prohibition) et qu'ils fassent de bonnes œuvres; puis qui (continuent) d'être pieux et de croire et qui (demeurent) pieux et bienfaisants. Car Allah aime les bienfaisants"
    },
    {
      "key": 763,
      "id": "5-94",
      "translation": "O les croyants! Allah va certainement vous éprouver par quelque gibier à la portée de vos mains et de vos lances. C'est pour qu'Allah sache celui qui Le craint en secret. Quiconque après cela transgresse aura un châtiment douloureux"
    },
    {
      "key": 764,
      "id": "5-95",
      "translation": "O les croyants! Ne tuez pas de gibier pendant que vous êtes en état d'Ihram. Quiconque parmi vous en tue délibérément, qu'il compense alors, soit par quelque bête de troupeau, semblable à ce qu'il a tué, d'après le jugement de deux personnes intègres parmi vous, et cela en offrande qu'il fera parvenir à (destination des pauvres de) la Ka'aba, ou bien par une expiation, en nourrissant des pauvres, ou par l'équivalent en jeûne. Cela afin qu'il goûte à la mauvaise conséquence de son acte. Allah a pardonné ce qui est passé; mais quiconque récidive, Allah le punira. Allah est Puissant et Détenteur du pouvoir de punir"
    },
    {
      "key": 765,
      "id": "5-96",
      "translation": "La chasse en mer vous est permise, et aussi d'en manger, pour votre jouissance et celle des voyageurs. Et vous est illicite la chasse à terre tant que vous êtes en état d'Ihram. Et craignez Allah vers qui vous serez rassemblés"
    },
    {
      "key": 766,
      "id": "5-97",
      "translation": "Allah a institué la Ka'aba, la Maison sacrée, comme un lieu de rassemblement pour les gens. (Il a institué) le mois sacré, l'offrande (d'animaux,) et les guirlandes, afin que vous sachiez que vraiment Allah sait tout ce qui est dans les cieux et sur la terre; et que vraiment Allah est Omniscient"
    },
    {
      "key": 767,
      "id": "5-98",
      "translation": "Sachez qu'Allah est sévère en punition, mais aussi qu'Allah est Pardonneur et Miséricordieux"
    },
    {
      "key": 768,
      "id": "5-99",
      "translation": "Il n'incombe au Messager que de transmettre (le message). Et Allah sait ce que vous divulguez tout comme ce que vous cachez"
    },
    {
      "key": 769,
      "id": "5-100",
      "translation": "Dis: «Le mauvais et le bon ne sont pas semblables, même si l'abondance du mal te séduit. Craignez Allah, donc, ô gens intelligents, afin que vous réussissiez"
    },
    {
      "key": 770,
      "id": "5-101",
      "translation": "O les croyants! Ne posez pas de questions sur des choses qui, si elles vous étaient divulguées, vous mécontenteraient. Et si vous posez des questions à leur sujet, pendant que le Coran est révélé, elles vous seront divulguées. Allah vous a pardonné cela. Et Allah est Pardonneur et Indulgent"
    },
    {
      "key": 771,
      "id": "5-102",
      "translation": "Un peuple avant vous avait posé des questions (pareilles) puis, devinrent de leur fait mécréants"
    },
    {
      "key": 772,
      "id": "5-103",
      "translation": "Allah n'a pas institué la Bahira, la Sâïba, la Wasîlani le Hâm. Mais ceux qui ont mécru ont inventé ce mensonge contre Allah, et la plupart d'entre eux ne raisonnent pas"
    },
    {
      "key": 773,
      "id": "5-104",
      "translation": "Et quand on leur dit: «Venez vers ce qu'Allah a fait descendre (la Révélation), et vers le Messager», ils disent: «Il nous suffit de ce sur quoi nous avons trouvé nos ancêtres.» Quoi! Même si leurs ancêtres ne savaient rien et n'étaient pas sur le bon chemin"
    },
    {
      "key": 774,
      "id": "5-105",
      "translation": "O les croyants! Vous êtes responsables de vous-mêmes! Celui qui s'égare ne vous nuira point si vous vous avez pris la bonne voie. C'est Vers Allah que vous retournerez tous; alors Il vous informera de ce que vous faisiez"
    },
    {
      "key": 775,
      "id": "5-106",
      "translation": "O les croyants! Quand la mort se présente à l'un de vous, le testament sera attesté par deux hommes intègres d'entre vous, ou deux autres, non des vôtres, si vous êtes en voyage dans le monde et que la mort vous frappe. Vous les retiendrez (les deux témoins), après la Sal?t, puis, si vous avez des doutes, vous les ferez jurer par Allah: «Nous ne faisons aucun commerce ou profit avec cela, même s'il s'agit d'un proche, et nous ne cacherons point le témoignage d'Allah. Sinon, nous serions du nombre des pécheurs»"
    },
    {
      "key": 776,
      "id": "5-107",
      "translation": "Si l'on découvre que ces deux témoins sont coupables de péché, deux autres plus intègres, parmi ceux auxquels le tort a été fait, prendront leur place et tous deux jureront par Allah: «En vérité, notre témoignage est plus juste que le témoignage de ces deux-là; et nous ne transgressons point. Sinon, nous serions certainement du nombre des injustes»"
    },
    {
      "key": 777,
      "id": "5-108",
      "translation": "C'est le moyen le plus sûr pour les inciter à fournir le témoignage dans sa forme réelle; ou leur faire craindre de voir d'autres serments se substituer aux leurs. Et craignez Allah et écoutez. Allah ne guide pas les gens pervers"
    },
    {
      "key": 778,
      "id": "5-109",
      "translation": "(Rappelle-toi) le jour où Allah rassemblera (tous) les messagers, et qu'Il dira: «Que vous a-t-on donné comme réponse?» Ils diront: «Nous n'avons aucun savoir: c'est Toi, vraiment, le grand connaisseur de tout ce qui est inconnu»"
    },
    {
      "key": 779,
      "id": "5-110",
      "translation": "Et quand Allah dira: «O Jésus, fils de Marie, rappelle-toi Mon bienfait sur toi et sur ta mère quand Je te fortifiais du Saint-Esprit. Au berceau tu parlais aux gens, tout comme en ton âge mûr. Je t'enseignais le Livre, la Sagesse, la Thora et l'Evangile! Tu fabriquais de l'argile comme une forme d'oiseau par Ma permission; puis tu soufflais dedans. Alors par Ma permission, elle devenait oiseau. Et tu guérissais par Ma permission, l'aveugle-né et le lépreux. Et par Ma permission, tu faisais revivre les morts. Je te protégeais contre les Enfants d'Israël pendant que tu leur apportais les preuves. Mais ceux d'entre eux qui ne croyaient pas dirent: «Ceci n'est que de la magie évidente»"
    },
    {
      "key": 780,
      "id": "5-111",
      "translation": "Et quand J'ai révélé aux Apôtres ceci: «Croyez en Moi et en Mon messager (Jésus)». Ils dirent: «Nous croyons; et atteste que nous sommes entièrement soumis»"
    },
    {
      "key": 781,
      "id": "5-112",
      "translation": "(Rappelle-toi le moment) où les Apôtres dirent: «O Jésus, fils de Marie, se peut-il que ton Seigneur fasse descendre sur nous du ciel une table servie?» Il leur dit: «Craignez plutôt Allah, si vous êtes croyants»"
    },
    {
      "key": 782,
      "id": "5-113",
      "translation": "Ils dirent: «Nous voulons en manger, rassurer ainsi nos cœurs, savoir que tu nous as réellement dit la vérité et en être parmi les témoins»"
    },
    {
      "key": 783,
      "id": "5-114",
      "translation": "«O Allah, notre Seigneur, dit Jésus, fils de Marie, fais descendre du ciel sur nous une table servie qui soit une fête pour nous, pour le premier d'entre nous, comme pour le dernier, ainsi qu'un signe de Ta part. Nourris-nous: Tu es le meilleur des nourrisseurs.»"
    },
    {
      "key": 784,
      "id": "5-115",
      "translation": "«Oui, dit Allah, Je la ferai descendre sur vous. Mais ensuite, quiconque d'entre vous refuse de croire, Je le châtierai d'un châtiment dont Je ne châtierai personne d'autre dans l'univers.»"
    },
    {
      "key": 785,
      "id": "5-116",
      "translation": "(Rappelle-leur) le moment où Allah dira: «O Jésus, fils de Marie, est-ce toi qui as dit aux gens: «Prenez-moi, ainsi que ma mère, pour deux divinités en dehors d'Allah?» Il dira: «Gloire et pureté à Toi! Il ne m'appartient pas de déclarer ce que je n'ai pas le droit de dire! Si je l'avais dit, Tu l'aurais su, certes. Tu sais ce qu'il y a en moi, et je ne sais pas ce qu'il y a en Toi. Tu es, en vérité, le grand connaisseur de tout ce qui est inconnu"
    },
    {
      "key": 786,
      "id": "5-117",
      "translation": "Je ne leur ai dit que ce que Tu m'avais commandé, (à savoir): «Adorez Allah, mon Seigneur et votre Seigneur». Et je fus témoin contre eux aussi longtemps que je fus parmi eux. Puis quand Tu m'as rappelé, c'est Toi qui fus leur observateur attentif. Et Tu es témoin de toute chose"
    },
    {
      "key": 787,
      "id": "5-118",
      "translation": "Si Tu les châties, ils sont Tes serviteurs. Et si Tu leur pardonnes, c'est Toi le Puissant, le Sage»"
    },
    {
      "key": 788,
      "id": "5-119",
      "translation": "Allah dira: «Voilà le jour où leur véracité va profiter aux véridiques: ils auront des Jardins sous lesquels coulent les ruisseaux pour y demeurer éternellement.» Allah les a agréés et eux L'ont agréé. Voilà l'énorme succès"
    },
    {
      "key": 789,
      "id": "5-120",
      "translation": "A Allah seul appartient le royaume des cieux, de la terre et de ce qu'ils renferment. Et Il est Omnipotent"
    },
    {
      "key": 790,
      "id": "6-1",
      "translation": "Louange à Allah qui a créé les cieux et la terre, et établi les ténèbres et la lumière. Pourtant, les mécréants donnent des égaux à leur Seigneur"
    },
    {
      "key": 791,
      "id": "6-2",
      "translation": "C'est Lui qui vous a créés d'argile; puis il vous a décrété un terme, et il y a un terme fixé auprès de Lui. Pourtant, vous doutez encore"
    },
    {
      "key": 792,
      "id": "6-3",
      "translation": "Et Lui, Il est Allah dans les cieux et sur la terre. Il connaît ce que vous cachez en vous et ce que vous divulguez et Il sait ce que vous acquérez"
    },
    {
      "key": 793,
      "id": "6-4",
      "translation": "Et il ne leur vient aucun des signes d'entre les signes de leur Seigneur, sans qu'ils ne s'en détournent"
    },
    {
      "key": 794,
      "id": "6-5",
      "translation": "Ils traitent de mensonge la vérité quand celle-ci leur vient. Mais ils vont avoir des nouvelles de ce dont ils se moquent"
    },
    {
      "key": 795,
      "id": "6-6",
      "translation": "N'ont-ils pas vu combien de générations, avant eux, Nous avons détruites, auxquelles Nous avions donné pouvoir sur terre, bien plus que ce que Nous vous avons donnés? Nous avions envoyé, sur eux, du ciel, la pluie en abondance, et Nous avions fait couler des rivières à leurs pieds. Puis Nous les avons détruites, pour leurs péchés; et Nous avons créé après eux, une nouvelle génération"
    },
    {
      "key": 796,
      "id": "6-7",
      "translation": "Même si Nous avions fait descendre sur toi  un Livre en papier qu'ils pouvaient toucher de leurs mains, ceux qui ne croient pas auraient certainement dit: «Ce n'est que de la magie évidente!»"
    },
    {
      "key": 797,
      "id": "6-8",
      "translation": "Et ils disent: «Pourquoi n'a-t-on pas fait descendre sur lui  un Ange?» Si Nous avions fait descendre un Ange, c'eût été, sûrement, affaire faite; puis on ne leur eût point donné de délai"
    },
    {
      "key": 798,
      "id": "6-9",
      "translation": "Si Nous avions désigné un Ange [comme prophète], Nous aurions fait de lui un homme et Nous leur aurions causé la même confusion que celle dans laquelle ils sont"
    },
    {
      "key": 799,
      "id": "6-10",
      "translation": "Certes, on s'est moqué de messagers avant toi, mais ceux qui se sont raillés d'eux, leur propre raillerie les enveloppa"
    },
    {
      "key": 800,
      "id": "6-11",
      "translation": "Dis: «Parcourez la terre et regardez ce qu'il est advenu de ceux qui traitaient la vérité de mensonge»"
    },
    {
      "key": 801,
      "id": "6-12",
      "translation": "Dis: «A qui appartient ce qui est dans les cieux et la terre?» Dis: «A Allah!» Il S'est à Lui-même prescrit la miséricorde. Il vous rassemblera, certainement, au Jour de la Résurrection: il n'y a pas de doute là-dessus. Ceux qui font leur propre perte sont ceux qui ne croient pas"
    },
    {
      "key": 802,
      "id": "6-13",
      "translation": "Et à Lui tout ce qui réside dans la nuit et le jour. C'est Lui qui est l'Audient, l'Omniscient"
    },
    {
      "key": 803,
      "id": "6-14",
      "translation": "Dis: «Devais-je prendre pour allié autre qu'Allah, le Créateur des cieux et de la terre? C'est Lui qui nourrit et personne ne Le nourrit. Dis: «On m'a commandé d'être le premier à me soumettre». Et ne sois jamais du nombre des associateurs"
    },
    {
      "key": 804,
      "id": "6-15",
      "translation": "Dis: «Je crains, si je désobéis à mon Seigneur, le châtiment d'un jour redoutable»"
    },
    {
      "key": 805,
      "id": "6-16",
      "translation": "En ce jour, quiconque est épargné, c'est qu'[Allah] lui a fait miséricorde. Et voilà le succès éclatant"
    },
    {
      "key": 806,
      "id": "6-17",
      "translation": "Et si Allah fait qu'un malheur te touche, nul autre que Lui ne peut l'enlever. Et s'Il fait qu'un bonheur te touche... c'est qu'Il est Omnipotent"
    },
    {
      "key": 807,
      "id": "6-18",
      "translation": "C'est Lui le Dominateur Suprême sur Ses serviteurs; c'est Lui le Sage, le Parfaitement Connaisseur"
    },
    {
      "key": 808,
      "id": "6-19",
      "translation": "Dis: «Qu'y a-t-il de plus grand en fait de témoignage?» Dis: «Allah est témoin entre moi et vous; et ce Coran m'a été révélé pour que je vous avertisse, par sa voie, vous et tous ceux qu'il atteindra. «Est-ce vous vraiment qui attestez qu'il y ait avec Allah d'autres divinités? Dis: «Je n'atteste pas». Dis [aussi]: «Il n'y a qu'une Divinité Unique. Et moi, je désavoue ce que vous (Lui) associez»"
    },
    {
      "key": 809,
      "id": "6-20",
      "translation": "Ceux à qui Nous avons donné le Livre reconnaissent comme ils reconnaissent leurs propres enfants. Ceux qui font leur propre perte sont ceux qui ne croient pas"
    },
    {
      "key": 810,
      "id": "6-21",
      "translation": "Qui donc est plus injuste que celui qui invente un mensonge contre Allah, ou qui traite de mensonge Ses versets? Les injustes ne réussiront pas"
    },
    {
      "key": 811,
      "id": "6-22",
      "translation": "Et le Jour où Nous les rassemblerons tous puis dirons à ceux qui auront donné des associés: «Où sont donc vos associés que vous prétendiez?»"
    },
    {
      "key": 812,
      "id": "6-23",
      "translation": "Alors il ne leur restera comme excuse que de dire: «Par Allah notre Seigneur! Nous n'étions jamais des associateurs»"
    },
    {
      "key": 813,
      "id": "6-24",
      "translation": "Vois comment ils mentent à eux-mêmes! Et comment les abandonnent (les associés) qu'ils inventaient"
    },
    {
      "key": 814,
      "id": "6-25",
      "translation": "Il en est parmi eux qui viennent t'écouter, cependant que Nous avons entouré de voiles leurs cœurs, qui les empêchent de comprendre (le Coran), et dans leurs oreilles est une lourdeur. Quand même ils verraient toutes sortes de preuves, ils n'y croiraient pas. Et quand ils viennent disputer avec toi, ceux qui ne croient pas disent alors: «Ce ne sont que des légendes des anciens»"
    },
    {
      "key": 815,
      "id": "6-26",
      "translation": "Ils empêchent [les gens] de s'approcher de lui et s'en écartent eux-mêmes. Ils ne feront périr qu'eux-mêmes sans s'en rendre compte"
    },
    {
      "key": 816,
      "id": "6-27",
      "translation": "Si tu les voyais, quand ils seront placés devant le Feu. Ils diront alors: «Hélas! Si nous pouvions être renvoyés (sur la terre), nous ne traiterions plus de mensonges les versets de notre Seigneur et nous serions du nombre des croyants»"
    },
    {
      "key": 817,
      "id": "6-28",
      "translation": "Mais non! Voilà que leur apparaîtra ce qu'auparavant ils cachaient. Or, s'ils étaient rendus [à la vie terrestre], ils reviendraient sûrement à ce qui leur était interdit. Ce sont vraiment des menteurs"
    },
    {
      "key": 818,
      "id": "6-29",
      "translation": "Et ils disent: «Il n'y a pour nous [d'autre vie] que celle d'ici-bas; et nous ne serons pas ressuscités»"
    },
    {
      "key": 819,
      "id": "6-30",
      "translation": "Si tu les voyais, quand ils comparaîtront devant leur Seigneur. Il leur dira: «Cela n'est-il pas la vérité?» Ils diront: «Mais si! Par notre Seigneur!» Et, il dira: «Goûtez alors au châtiment pour n'avoir pas cru»"
    },
    {
      "key": 820,
      "id": "6-31",
      "translation": "Sont perdants certes ceux qui traitent de mensonge la rencontre d'Allah. Et quand soudain l'Heure leur viendra, ils diront: «Malheur à nous pour notre négligence à son égard, Et ils porteront leurs fardeaux sur leurs dos, et quels mauvais fardeaux"
    },
    {
      "key": 821,
      "id": "6-32",
      "translation": "La présente vie n'est que jeu et amusement. La demeure dans l'au-delà sera meilleure pour ceux qui sont pieux. Eh bien, ne comprenez-vous pas"
    },
    {
      "key": 822,
      "id": "6-33",
      "translation": "Nous savons qu'en vérité ce qu'ils disent te chagrine. Or, vraiment ils ne croient pas que tu es menteur, mais ce sont les versets (le Coran) d'Allah, que les injustes renient"
    },
    {
      "key": 823,
      "id": "6-34",
      "translation": "Certes, des messagers avant toi  ont été traités de menteurs. Ils endurèrent alors avec constance d'être traités de menteurs et d'être persécutés, jusqu'à ce que Notre secours leur vînt. Et nul ne peut changer les paroles d'Allah, et il t'est déjà parvenu une partie de l'histoire des Envoyés"
    },
    {
      "key": 824,
      "id": "6-35",
      "translation": "Et si leur indifférence t'afflige énormément, et qu'il est dans ton pouvoir de chercher un tunnel à travers la terre, ou une échelle pour aller au ciel pour leur apporter un miracle, [fais-le donc]. Et si Allah voulait, Il pourrait les mettre tous sur le chemin droit. Ne sois pas du nombre des ignorants"
    },
    {
      "key": 825,
      "id": "6-36",
      "translation": "Seuls ceux qui entendent répondent à l'appel [de la foi]. Et quant aux morts, Allah les ressuscitera; puis ils Lui seront ramenés"
    },
    {
      "key": 826,
      "id": "6-37",
      "translation": "Et ils disent: «Pourquoi n'a-t-on pas fait descendre sur lui  un miracle de la part de son Seigneur?» Dis: «Certes Allah est capable de faire descendre un miracle. Mais la plupart d'entre eux ne savent pas»"
    },
    {
      "key": 827,
      "id": "6-38",
      "translation": "Nulle bête marchant sur terre, nul oiseau volant de ses ailes, qui ne soit comme vous en communauté. Nous n'avons rien omis d'écrire dans le Livre. Puis, c'est vers leur Seigneur qu'ils seront ramenés"
    },
    {
      "key": 828,
      "id": "6-39",
      "translation": "Et ceux qui traitent de mensonges Nos versets sont sourds et muets, dans les ténèbres. Allah égare qui Il veut; et Il place qui Il veut sur un chemin droit"
    },
    {
      "key": 829,
      "id": "6-40",
      "translation": "Dis: «Informez-moi: si le châtiment d'Allah vous vient, ou que vous vient l'Heure, ferez-vous appel à autre qu'Allah, si vous êtes véridiques?»"
    },
    {
      "key": 830,
      "id": "6-41",
      "translation": "C'est plutôt à Lui que vous ferez appel. Puis, Il dissipera, s'Il veut, l'objet de votre appel et vous oublierez ce que vous [Lui] associez"
    },
    {
      "key": 831,
      "id": "6-42",
      "translation": "Nous avons, certes, envoyé (des messagers) aux communautés avant toi. Ensuite Nous les avons saisies par l'adversité et la détresse - peut-être imploreront-ils (la miséricorde)"
    },
    {
      "key": 832,
      "id": "6-43",
      "translation": "Pourquoi donc, lorsque Notre rigueur leur vînt, n'ont-ils pas imploré (la miséricorde)? Mais leurs cœurs s'étaient endurcis et le Diable enjolivait à leurs yeux ce qu'ils faisaient"
    },
    {
      "key": 833,
      "id": "6-44",
      "translation": "Puis, lorsqu'ils eurent oublié ce qu'on leur avait rappelé, Nous leur ouvrîmes les portes donnant sur toute chose (l'abondance); et lorsqu'ils eurent exulté de joie en raison de ce qui leur avait été donné, Nous les saisîmes soudain, et les voilà désespérés"
    },
    {
      "key": 834,
      "id": "6-45",
      "translation": "Ainsi fut exterminé le dernier reste de ces injustes. Et louange à Allah, Seigneur de l'Univers"
    },
    {
      "key": 835,
      "id": "6-46",
      "translation": "Dis: «Voyez-vous? Si Allah prenait votre ouïe et votre vue, et scellait vos cœurs, quelle divinité autre qu'Allah vous les rendrait? Regarde comment, à leur intention, Nous clarifions les preuves! Pourtant ils s'en détournent"
    },
    {
      "key": 836,
      "id": "6-47",
      "translation": "Dis: «Que vous en semble? Si le châtiment d'Allah vous venait à l'improviste ou au grand jour, qui seront détruits sinon les gens injustes?»"
    },
    {
      "key": 837,
      "id": "6-48",
      "translation": "Nous n'envoyons des messagers qu'en annonciateurs et avertisseurs: ceux qui croient donc et se réforment, nulle crainte sur eux et ils ne seront point affligés"
    },
    {
      "key": 838,
      "id": "6-49",
      "translation": "Et ceux qui traitent de mensonges Nos preuves, le châtiment les touchera, à cause de leur perversité"
    },
    {
      "key": 839,
      "id": "6-50",
      "translation": "Dis-[leur]: «Je ne vous dis pas que je détiens les trésors d'Allah, ni que je connais l'Inconnaissable, et je ne vous dis pas que je suis un ange. Je ne fais que suivre ce qui m'est révélé.» Dis: «Est-ce que sont égaux l'aveugle et celui qui voit? Ne réfléchissez-vous donc pas?»"
    },
    {
      "key": 840,
      "id": "6-51",
      "translation": "Et avertis par ceci (le Coran), ceux qui craignent d'être rassemblés devant leur Seigneur, qu'ils n'auront hors d'Allah ni allié ni intercesseur. Peut-être deviendraient-ils pieux"
    },
    {
      "key": 841,
      "id": "6-52",
      "translation": "Et ne repousse pas ceux qui, matin et soir, implorent leur Seigneur, cherchant sa Face «Wajh». Leur demander compte ne t'incombe en rien, et te demander compte ne leur incombe en rien. En les repoussant donc, tu serais du nombre des injustes"
    },
    {
      "key": 842,
      "id": "6-53",
      "translation": "Ainsi, éprouvons-Nous (les gens) les uns par les autres, pour qu'ils disent: «Est-ce là ceux qu'Allah a favorisés parmi nous?» N'est-ce pas Allah qui sait le mieux lesquels sont reconnaissants"
    },
    {
      "key": 843,
      "id": "6-54",
      "translation": "Et lorsque viennent vers toi ceux qui croient à nos versets (le Coran), dis: «Que la paix soit sur vous! Votre Seigneur S'est prescrit à Lui-même la miséricorde. Et quiconque d'entre vous a fait un mal par ignorance, et ensuite s'est repenti et s'est réformé... Il est, alors, Pardonneur et Miséricordieux»"
    },
    {
      "key": 844,
      "id": "6-55",
      "translation": "C'est ainsi que Nous détaillons les versets, afin qu'apparaisse clairement le chemin des criminels"
    },
    {
      "key": 845,
      "id": "6-56",
      "translation": "Dis: «il m'a été interdit d'adorer ceux que vous priez en dehors d'Allah». Dis: «Je ne suivrai pas vos passions: car ce serait m'égarer, et je ne serais plus parmi les bien-guidés»"
    },
    {
      "key": 846,
      "id": "6-57",
      "translation": "Dis: «Je m'appuie sur une preuve évidente de la part de mon Seigneur, et vous avez traité cela de mensonge. Ce (le châtiment) que vous voulez hâter ne dépend pas de moi. Le jugement n'appartient qu'à Allah: Il tranche en toute vérité et Il est le meilleur des juges"
    },
    {
      "key": 847,
      "id": "6-58",
      "translation": "Dis: «Si ce que vous voulez hâter dépendait de moi, ce serait affaire faite entre vous et moi.» C'est Allah qui connaît le mieux les injustes"
    },
    {
      "key": 848,
      "id": "6-59",
      "translation": "C'est Lui qui détient les clefs de l'Inconnaissable. Nul autre que Lui ne les connaît. Et Il connaît ce qui est dans la terre ferme, comme dans la mer. Et pas une feuille ne tombe qu'Il ne le sache. Et pas une graine dans les ténèbres de la terre, rien de frais ou de sec, qui ne soit consigné dans un livre explicite"
    },
    {
      "key": 849,
      "id": "6-60",
      "translation": "Et, la nuit, c'est Lui qui prend vos âmes, et Il sait ce que vous avez acquis pendant le jour. Puis Il vous ressuscite le jour afin que s'accomplisse le terme fixé. Ensuite, c'est vers Lui que sera votre retour, et Il vous informera de ce que vous faisiez"
    },
    {
      "key": 850,
      "id": "6-61",
      "translation": "Et Il est le Dominateur Suprême sur Ses serviteurs. Et Il envoie sur vous des gardiens. Et lorsque la mort atteint l'un de vous, Nos messagers (les Anges) enlèvent son âme sans aucune négligence"
    },
    {
      "key": 851,
      "id": "6-62",
      "translation": "Ils sont ensuite ramenés vers Allah, leur vrai Maître. C'est à Lui qu'appartient le jugement et Il est le plus prompt des juges"
    },
    {
      "key": 852,
      "id": "6-63",
      "translation": "Dis: «Qui vous délivre des ténèbres de la terre et de la mer?» Vous l'invoquez humblement et en secret: «S'Il nous délivre de ceci, nous serons du nombre des reconnaissants"
    },
    {
      "key": 853,
      "id": "6-64",
      "translation": "Dis: «C'est Allah qui vous en délivre ainsi que de toute angoisse. Pourtant, vous Lui donnez des associés»"
    },
    {
      "key": 854,
      "id": "6-65",
      "translation": "Dis: «Il est capable, Lui, de susciter contre vous, d'en haut, ou de dessous vos pieds, un châtiment, ou de vous confondre dans le sectarisme. Et Il vous fait goûter l'ardeur [au combat] les uns aux autres.» Regarde comment Nous exposons Nos versets. Peut-être comprendront-ils"
    },
    {
      "key": 855,
      "id": "6-66",
      "translation": "Et ton peuple traite cela (le Coran) de mensonge, alors que c'est la vérité. Dis: «Je ne suis pas votre garant"
    },
    {
      "key": 856,
      "id": "6-67",
      "translation": "Chaque annonce arrive en son temps et en son lieu. Et bientôt vous le saurez.»"
    },
    {
      "key": 857,
      "id": "6-68",
      "translation": "Quand tu vois ceux qui pataugent dans des discussions à propos de Nos versets, éloigne-toi d'eux jusqu'à ce qu'ils entament une autre discussion. Et si le Diable te fait oublier, alors, dès que tu te rappelles, ne reste pas avec les injustes"
    },
    {
      "key": 858,
      "id": "6-69",
      "translation": "Il n'incombe nullement à ceux qui sont pieux de rendre compte pour ces gens là. Mais c'est à titre de rappel. Peut-être craindront-ils [Allah]"
    },
    {
      "key": 859,
      "id": "6-70",
      "translation": "Laisse ceux qui prennent leur religion pour jeu et amusement, et qui sont séduits par la vie sur terre. Et rappelle par ceci (le Coran) pour qu'une âme ne s'expose pas à sa perte selon ce qu'elle aura acquis, elle n'aura en dehors d'Allah, ni allié ni intercesseur. Et quelle que soit la compensation qu'elle offrirait, elle ne sera pas acceptée d'elle. Ceux-là se sont abandonnés à leur perdition à cause de ce qu'ils ont acquis. Leur breuvage sera l'eau bouillante et ils auront un châtiment douloureux, pour avoir mécru"
    },
    {
      "key": 860,
      "id": "6-71",
      "translation": "Dis: «Invoquerons-nous, au lieu d'Allah, ce qui ne peut nous profiter ni nous nuire? Et reviendrons-nous sur nos talons après qu'Allah nous a guidés, comme quelqu'un que les diables ont séduit et qui erre perplexe sur la terre, bien que des amis l'appellent vers le droit chemin (lui disant): - «Viens à nous». Dis: «Le vrai chemin, c'est le chemin d'Allah. Et il nous a été commandé de nous soumettre au Seigneur de l'Univers"
    },
    {
      "key": 861,
      "id": "6-72",
      "translation": "Et d'accomplir la Salât et de Le craindre. C'est vers Lui que vous serez rassemblés»"
    },
    {
      "key": 862,
      "id": "6-73",
      "translation": "Et c'est Lui qui a créé les cieux et la terre, en toute vérité. Et le jour où Il dit: «Sois!» Cela est, Sa parole est la vérité. A Lui, [seul,] la royauté, le jour où l'on soufflera dans la Trompe. C'est Lui le Connaisseur de ce qui est voilé et de ce qui est manifeste. Et c'est Lui le Sage et le Parfaitement Connaisseur"
    },
    {
      "key": 863,
      "id": "6-74",
      "translation": "(Rappelle le moment) où Abraham dit à `Azar, son père: «Prends-tu des idoles comme divinités? Je te vois, toi et ton peuple, dans un égarement évident!»"
    },
    {
      "key": 864,
      "id": "6-75",
      "translation": "Ainsi avons-Nous montré à Abraham le royaume des cieux et de la terre, afin qu'il fût de ceux qui croient avec conviction"
    },
    {
      "key": 865,
      "id": "6-76",
      "translation": "Quand la nuit l'enveloppa, il observa une étoile, et dit: «Voilà mon Seigneur!» Puis, lorsqu'elle disparut, il dit: «Je n'aime pas les choses qui disparaissent»"
    },
    {
      "key": 866,
      "id": "6-77",
      "translation": "Lorsqu'ensuite il observa la lune se levant, il dit: «Voilà mon Seigneur!» Puis, lorsqu'elle disparut, il dit: «Si mon Seigneur ne me guide pas, je serai certes du nombre des gens égarés»"
    },
    {
      "key": 867,
      "id": "6-78",
      "translation": "Lorsqu'ensuite il observa le soleil levant, il dit: «Voilà mon Seigneur! Celui-ci est plus grand» Puis lorsque le soleil disparut, il dit: «O mon peuple, je désavoue tout ce que vous associez à Allah"
    },
    {
      "key": 868,
      "id": "6-79",
      "translation": "Je tourne mon visage exclusivement vers Celui qui a créé (à partir du néant) les cieux et la terre; et je ne suis point de ceux qui Lui donnent des associés.»"
    },
    {
      "key": 869,
      "id": "6-80",
      "translation": "Son peuple disputa avec lui; mais il dit: «Allez-vous disputer avec moi au sujet d'Allah, alors qu'Il m'a guidé? Je n'ai pas peur des associés que vous Lui donnez. Je ne crains que ce que veut mon Seigneur. Mon Seigneur embrasse tout dans Sa science. Ne vous rappelez-vous donc pas"
    },
    {
      "key": 870,
      "id": "6-81",
      "translation": "Et comment aurais-je peur des associés que vous Lui donnez, alors que vous n'avez pas eu peur d'associer à Allah des choses pour lesquelles Il ne vous a fait descendre aucune preuve? Lequel donc des deux partis a le plus droit à la sécurité? (Dites-le) si vous savez"
    },
    {
      "key": 871,
      "id": "6-82",
      "translation": "Ceux qui ont cru et n'ont point troublé la pureté de leur foi par quelqu'inéquité (association), ceux-là ont la sécurité; et ce sont eux les bien-guidés»"
    },
    {
      "key": 872,
      "id": "6-83",
      "translation": "Tel est l'argument que Nous inspirâmes à Abraham contre son peuple. Nous élevons en haut rang qui Nous voulons. Ton Seigneur est Sage et Omniscient"
    },
    {
      "key": 873,
      "id": "6-84",
      "translation": "Et Nous lui avons donné Isaac et Jacob et Nous les avons guidés tous les deux. Et Noé, Nous l'avons guidé auparavant, et parmi la descendance (d'Abraham) (ou de Noé), David, Salomon, Job, Joseph, Moïse et Aaron. Et c'est ainsi que Nous récompensons les bienfaisants"
    },
    {
      "key": 874,
      "id": "6-85",
      "translation": "De même, Zacharie, Jean-Baptiste, Jésus et Elie, tous étant du nombre des gens de bien"
    },
    {
      "key": 875,
      "id": "6-86",
      "translation": "De même, Ismaël, Elisée, Jonas et Lot. Chacun d'eux Nous l'avons favorisé par dessus le reste du monde"
    },
    {
      "key": 876,
      "id": "6-87",
      "translation": "De même une partie de leurs ancêtres, de leurs descendants et de leurs frères et Nous les avons choisis et guidés vers un chemin droit"
    },
    {
      "key": 877,
      "id": "6-88",
      "translation": "Telle est la direction par laquelle Allah guide qui Il veut parmi Ses serviteurs. Mais s'ils avaient donné à Allah des associés, alors, tout ce qu'ils auraient fait eût certainement été vain"
    },
    {
      "key": 878,
      "id": "6-89",
      "translation": "C'est à eux que Nous avons apporté le Livre, la sagesse et la prophétie. Si ces autres-là n'y croient pas, du moins Nous avons confié ces choses à des gens qui ne les nient pas"
    },
    {
      "key": 879,
      "id": "6-90",
      "translation": "Voilà ceux qu'Allah a guidés: suis donc leur direction. Dis: «Je ne vous demande pas pour cela de salaire». Ce n'est qu'un rappel à l'intention de tout l'univers"
    },
    {
      "key": 880,
      "id": "6-91",
      "translation": "Ils n'apprécient pas Allah comme Il le mérite quand ils disent: «Allah n'a rien fait descendre sur un humain.» Dis: «Qui a fait descendre le Livre que Moïse a apporté comme lumière et guide, pour les gens? Vous le mettez en feuillets, pour en montrer une partie, tout en cachant beaucoup. Vous avez été instruits de ce que vous ne saviez pas, ni vous ni vos ancêtres. Dis: «C'est Allah». Et puis, laisse-les s'amuser dans leur égarement"
    },
    {
      "key": 881,
      "id": "6-92",
      "translation": "Voici un Livre (le Coran) béni que Nous avons fait descendre, confirmant ce qui existait déjà avant lui, afin que tu avertisses la Mère des Cités (la Mecque) et les gens tout autour. Ceux qui croient au Jour dernier, y croient et demeurent assidus dans leur Salât"
    },
    {
      "key": 882,
      "id": "6-93",
      "translation": "Et quel pire injuste que celui qui fabrique un mensonge contre Allah ou qui dit: «Révélation m'a été faite», quand rien ne lui a été révélé. De même celui qui dit: «Je vais faire descendre quelque chose de semblable à ce qu'Allah a fait descendre.» Si tu voyais les injustes lorsqu'ils seront dans les affres de la mort, et que les Anges leur tendront les mains (disant): «Laissez sortir vos âmes. Aujourd'hui vous allez être récompensés par le châtiment de l'humiliation pour ce que vous disiez sur Allah d'autre que la vérité et parce que vous vous détourniez orgueilleusement de Ses enseignements»"
    },
    {
      "key": 883,
      "id": "6-94",
      "translation": "Et vous voici venus à Nous, seuls, tout comme Nous vous avions créés la première fois, abandonnant derrière vos dos tout ce que Nous vous avions accordé. Nous ne vous voyons point accompagnés des intercesseurs que vous prétendiez être des associés. Il y a certainement eu rupture entre vous: ils vous ont abandonnés, ceux que vous prétendiez (être vos intercesseurs)"
    },
    {
      "key": 884,
      "id": "6-95",
      "translation": "C'est Allah qui fait fendre la graine et le noyau: du mort il fait sortir le vivant, et du vivant, il fait sortir le mort. Tel est Allah. Comment donc vous laissez-vous détourner"
    },
    {
      "key": 885,
      "id": "6-96",
      "translation": "Fendeur de l'aube, Il a fait de la nuit une phase de repos; le soleil et la lune pour mesurer le temps. Voilà l'ordre conçu par le Puissant, l'Omniscient"
    },
    {
      "key": 886,
      "id": "6-97",
      "translation": "Et c'est Lui qui vous a assigné les étoiles, pour que, par elles, vous vous guidiez dans les ténèbres de la terre et de la mer. Certes, Nous exposons les preuves pour ceux qui savent"
    },
    {
      "key": 887,
      "id": "6-98",
      "translation": "Et c'est Lui qui vous a créés à partir d'une personne unique (Adam). Et il y a une demeure et un lieu de dépôt (pour vous.) Nous avons exposé les preuves pour ceux qui comprennent"
    },
    {
      "key": 888,
      "id": "6-99",
      "translation": "Et c'est Lui qui, du ciel, a fait descendre l'eau. Puis par elle, Nous fîmes germer toute plante, de quoi Nous fîmes sortir une verdure, d'où Nous produisîmes des grains, superposés les uns sur les autres; et du palmier, de sa spathe, des régimes de dattes qui se tendent. Et aussi les jardins de raisins, l'olive et la grenade, semblables ou différents les uns des autres. Regardez leurs fruits au moment de leur production et de leur mûrissement. Voilà bien là des signes pour ceux qui ont la foi"
    },
    {
      "key": 889,
      "id": "6-100",
      "translation": "Et ils ont désigné des associés à Allah: les djinns, alors que c'est Lui qui les a créés. Et ils Lui ont inventé, dans leur ignorance, des fils et des filles, Gloire à Lui! Il transcende tout ce qu'ils lui attribuent"
    },
    {
      "key": 890,
      "id": "6-101",
      "translation": "Créateur des cieux et de la terre. Comment aurait-Il un enfant, quand Il n'a pas de compagne? C'est Lui qui a tout créé, et Il est Omniscient"
    },
    {
      "key": 891,
      "id": "6-102",
      "translation": "Voilà Allah, votre Seigneur! Il n'y a de divinité que Lui, Créateur de tout. Adorez-Le donc. C'est Lui qui a charge de tout"
    },
    {
      "key": 892,
      "id": "6-103",
      "translation": "Les regards ne peuvent l'atteindre, cependant qu'Il saisit tous les regards. Et Il est le Doux, le Parfaitement Connaisseur"
    },
    {
      "key": 893,
      "id": "6-104",
      "translation": "Certes, il vous est parvenu des preuves évidentes, de la part de votre Seigneur. Donc, quiconque voit clair, c'est en sa faveur; et quiconque reste aveugle, c'est à son détriment, car je ne suis nullement chargé de votre sauvegarde"
    },
    {
      "key": 894,
      "id": "6-105",
      "translation": "C'est ainsi que Nous expliquons les versets. Et afin qu'ils disent: «Tu as étudié». Et afin de l'exposer clairement à des gens qui savent"
    },
    {
      "key": 895,
      "id": "6-106",
      "translation": "Suis ce qui t'est révélé de la part de ton Seigneur. Point de divinité autre que Lui. Et écarte-toi des associateurs"
    },
    {
      "key": 896,
      "id": "6-107",
      "translation": "Si Allah voulait, ils ne seraient point associateurs! Mais Nous ne t'avons pas désigné comme gardien sur eux; et tu n'es pas leur garant"
    },
    {
      "key": 897,
      "id": "6-108",
      "translation": "N'injuriez pas ceux qu'ils invoquent, en dehors d'Allah, car par agressivité, ils injurieraient Allah, dans leur ignorance. De même, Nous avons enjolivé (aux yeux) de chaque communauté sa propre action. Ensuite, c'est vers leur Seigneur que sera leur retour; et Il les informera de ce qu'ils œuvraient"
    },
    {
      "key": 898,
      "id": "6-109",
      "translation": "Et ils jurent par Allah de toute la force de leurs serments, que s'il leur venait un miracle, ils y croiraient (sans hésiter,) Dis: «En vérité, les miracles ne dépendent que d'Allah.» Mais qu'est ce qui vous fait penser que quand cela (le signe) arrivera, ils n'y croiront pas"
    },
    {
      "key": 899,
      "id": "6-110",
      "translation": "Parce qu'ils n'ont pas cru la première fois, nous détournerons leurs cœurs et leurs yeux; nous les laisserons marcher aveuglement dans leur rébellion"
    },
    {
      "key": 900,
      "id": "6-111",
      "translation": "Et si Nous faisions descendre les Anges vers eux, [comme ils l'avaient proposé] si les morts leur parlaient, et si Nous rassemblions toute chose devant eux, ils ne croiraient que si Allah veut. Mais la plupart d'entre eux ignorent"
    },
    {
      "key": 901,
      "id": "6-112",
      "translation": "Ainsi, à chaque prophète avons-Nous assigné un ennemi: des diables d'entre les hommes et les djinns, qui s'inspirent trompeusement les uns aux autres des paroles enjolivées. Si ton Seigneur avait voulu, ils ne l'auraient pas fait; laisse-les donc avec ce qu'ils inventent"
    },
    {
      "key": 902,
      "id": "6-113",
      "translation": "Et pour que les cœurs de ceux qui ne croient pas à l'au-delà se penchent vers elles, qu'ils les agréent, et qu'ils perpètrent ce qu'ils perpètrent"
    },
    {
      "key": 903,
      "id": "6-114",
      "translation": "Chercherai-je un autre juge qu'Allah, alors que c'est Lui qui a fait descendre vers vous ce Livre bien exposé? Ceux auxquels Nous avons donné le Livre savent qu'il est descendu avec la vérité venant de ton Seigneur. Ne sois donc point du nombre de ceux qui doutent"
    },
    {
      "key": 904,
      "id": "6-115",
      "translation": "Et la parole de ton Seigneur s'est accomplie en toute vérité et équité. Nul ne peut modifier Ses paroles. Il est l'Audient, l'Omniscient"
    },
    {
      "key": 905,
      "id": "6-116",
      "translation": "Et si tu obéis à la majorité de ceux qui sont sur la terre, ils t'égareront du sentier d'Allah: ils ne suivent que la conjecture et ne font que fabriquer des mensonges"
    },
    {
      "key": 906,
      "id": "6-117",
      "translation": "Certes ton Seigneur connaît le mieux ceux qui s'égarent de Son sentier, et c'est Lui qui connaît le mieux les bien-guidés"
    },
    {
      "key": 907,
      "id": "6-118",
      "translation": "Mangez donc de ce sur quoi on a prononcé le nom d'Allah si vous êtes croyants en Ses versets (le Coran)"
    },
    {
      "key": 908,
      "id": "6-119",
      "translation": "Qu'avez-vous à ne pas manger de ce sur quoi le nom d'Allah a été prononcé? Alors qu'Il vous a détaillé ce qu'Il vous a interdit, à moins que vous ne soyez contraints d'y recourir. Beaucoup de gens égarent, sans savoir, par leurs passions. C'est ton Seigneur qui connaît le mieux les transgresseurs"
    },
    {
      "key": 909,
      "id": "6-120",
      "translation": "Evitez le péché apparent ou caché, (car) ceux qui acquièrent le péché seront rétribués selon ce qu'ils auront commis"
    },
    {
      "key": 910,
      "id": "6-121",
      "translation": "Et ne mangez pas de ce sur quoi le nom d'Allah n'a pas été prononcé, car ce serait (assurément) une perversité. Les diables inspirent à leurs alliés de disputer avec vous. Si vous leur obéissez, vous deviendrez certes des associateurs"
    },
    {
      "key": 911,
      "id": "6-122",
      "translation": "Est-ce que celui qui était mort et que Nous avons ramené à la vie et à qui Nous avons assigné une lumière grâce à laquelle il marche parmi les gens, est pareil à celui qui est dans les ténèbres sans pouvoir en sortir? Ainsi on a enjolivé aux mécréants ce qu'ils œuvrent"
    },
    {
      "key": 912,
      "id": "6-123",
      "translation": "Ainsi, Nous avons placé dans chaque cité de grands criminels qui y ourdissent des complots. Mais ils ne complotent que contre eux-mêmes et ils n'en sont pas conscients"
    },
    {
      "key": 913,
      "id": "6-124",
      "translation": "Et lorsqu'une preuve leur vient, ils disent: «Jamais nous ne croirons tant que nous n'aurons pas reçu un don semblable à celui qui a été donné aux messagers d'Allah». Allah sait mieux où placer Son message. Ceux qui ont commis le crime seront atteints d'un rapetissement auprès d'Allah ainsi que d'un supplice sévère pour les ruses qu'ils tramaient"
    },
    {
      "key": 914,
      "id": "6-125",
      "translation": "Et puis, quiconque Allah veut guider, Il lui ouvre la poitrine à l'Islam. Et quiconque Il veut égarer, Il rend sa poitrine étroite et gênée, comme s'il s'efforçait de monter au ciel. Ainsi Allah inflige Sa punition à ceux qui ne croient pas"
    },
    {
      "key": 915,
      "id": "6-126",
      "translation": "Telle est la voie de ton Seigneur dans toute sa rectitude. Nous avons [effectivement] bien détaillé les signes (ou versets) à des gens qui se rappellent"
    },
    {
      "key": 916,
      "id": "6-127",
      "translation": "Pour eux la maison du Salut auprès de leur Seigneur. Et c'est Lui qui est leur protecteur, pour ce qu'ils faisaient (sur terre)"
    },
    {
      "key": 917,
      "id": "6-128",
      "translation": "Et le jour où Il les rassemblera tous: «O communauté des djinns, vous avez trop abusé des humains». Et leurs alliés parmi les humains diront: «O notre Seigneur, nous avons profité les uns des autres, et nous avons atteint le terme que Tu avais fixé pour nous.» Il leur dira: «l'Enfer est votre demeure, pour y rester éternellement, sauf si Allah en décide autrement.» Vraiment ton Seigneur est Sage et Omniscient"
    },
    {
      "key": 918,
      "id": "6-129",
      "translation": "Et ainsi accordons-Nous, à certains injustes l'autorité sur d'autres, (injustes) à cause de ce qu'ils ont acquis"
    },
    {
      "key": 919,
      "id": "6-130",
      "translation": "O communauté des djinns et des humains, ne vous est-il pas venu des messagers, choisis parmi vous, qui vous ont raconté Mes signes et averti de la rencontre de ce jour? Ils diront: «Nous témoignons contre nous-mêmes.» La vie présente les a trompés; et ils ont témoigné contre eux-mêmes qu'en (vérité) ils étaient mécréants"
    },
    {
      "key": 920,
      "id": "6-131",
      "translation": "C'est que ton Seigneur n'anéantit point injustement des cités dont les gens ne sont pas encore avertis"
    },
    {
      "key": 921,
      "id": "6-132",
      "translation": "A chacun des rangs (des récompenses) selon ses œuvres. Or ton Seigneur n'est pas inattentif à ce qu'ils font"
    },
    {
      "key": 922,
      "id": "6-133",
      "translation": "Ton Seigneur est le Suffisant à Soi-même, le Détenteur de la miséricorde. S'Il voulait, Il vous ferait périr et mettrait à votre place qui Il veut, de même qu'Il vous a créés d'une descendance d'un autre peuple"
    },
    {
      "key": 923,
      "id": "6-134",
      "translation": "Ce qui vous a été promis arrivera (certainement.) Et vous n'êtes pas à même de [Nous] réduire à l'impuissance"
    },
    {
      "key": 924,
      "id": "6-135",
      "translation": "Dis: «O mon peuple! Continuez à agir selon votre méthode; moi aussi j'agirai selon la mienne. Ensuite, vous saurez qui aura un meilleur (sort) dans l'au-delà.» Certes, les injustes ne réussiront jamais"
    },
    {
      "key": 925,
      "id": "6-136",
      "translation": "Et ils assignent à Allah une part de ce qu'Il a Lui-même créé, en fait de récoltes et de bestiaux, et ils disent: «Ceci est à Allah - selon leur prétention! - et ceci à nos divinités.» Mais ce qui est pour leurs divinités ne parvient pas à Allah, tandis que ce qui est pour Allah parvient à leurs divinités. Comme leur jugement est mauvais"
    },
    {
      "key": 926,
      "id": "6-137",
      "translation": "Et c'est ainsi que leurs divinités ont enjolivé à beaucoup d'associateurs le meurtre de leurs enfants, afin de les ruiner et de travestir à leurs yeux leur religion. Or si Allah voulait, ils ne le feraient pas. Laisse-les donc, ainsi que ce qu'ils inventent"
    },
    {
      "key": 927,
      "id": "6-138",
      "translation": "Et ils dirent: «Voilà des bestiaux et des champs frappés d'interdiction: n'en mangeront que ceux que nous voudrons.» - selon leur prétention! - Et voilà des bêtes dont le dos est tabou, et des bêtes sur lesquelles ils ne mentionnent pas le nom d'Allah. Des inventions contre Lui! Il les rétribuera pour ce qu'ils inventaient comme mensonges"
    },
    {
      "key": 928,
      "id": "6-139",
      "translation": "Et ils dirent: «Ce qui est dans le ventre de ces bêtes est réservé aux mâles d'entre nous, et interdit à nos femmes.» Et si c'est un mort-né, ils y participent tous. Bientôt Il les rétribuera pour leur prescription, car Il est Sage et Omniscient"
    },
    {
      "key": 929,
      "id": "6-140",
      "translation": "Ils sont certes perdants, ceux qui ont, par sottise et ignorance tué leurs enfants, et ceux qui ont interdit ce qu'Allah leur a attribué de nourriture, inventant des mensonges contre Allah. Ils se sont égarés et ne sont point guidés"
    },
    {
      "key": 930,
      "id": "6-141",
      "translation": "C'est Lui qui a créé les jardins, treillagés et non treillagés; ainsi que les palmiers et la culture aux récoltes diverses; [de même que] l'olive et la grenade, d'espèces semblables et différentes. Mangez de leurs fruits, quand ils en produisent; et acquittez-en les droits le jour de la récolte. Et ne gaspillez point car Il n'aime pas les gaspilleurs"
    },
    {
      "key": 931,
      "id": "6-142",
      "translation": "Et (Il a créé) parmi les bestiaux, certains pour le transport, et d'autres pour diverses utilités; mangez de ce qu'Allah vous a attribué, et ne suivez pas les pas du Diable, car il est pour vous un ennemi déclaré"
    },
    {
      "key": 932,
      "id": "6-143",
      "translation": "(Il en a créé) huit, en couples: deux pour les ovins, deux pour les caprins... dis: «Est-ce les deux mâles qu'Il a interdits ou les deux femelles, ou ce qui est dans les matrices des deux femelles? Informez-moi de toute connaissance, si vous êtes véridiques»"
    },
    {
      "key": 933,
      "id": "6-144",
      "translation": "deux pour les camélidés, deux pour les bovins... Dis: «Est-ce les deux mâles qu'Il a interdits ou les deux femelles, ou ce qui est dans les matrices des deux femelles? Ou bien étiez-vous témoins quand Allah vous l'enjoignit?» Qui est donc plus injuste que celui qui invente un mensonge contre Allah pour égarer les gens sans se baser sur aucun savoir? Allah ne guide pas les gens injustes"
    },
    {
      "key": 934,
      "id": "6-145",
      "translation": "Dis: «Dans ce qui m'a été révélé, je ne trouve d'interdit, à aucun mangeur d'en manger, que la bête (trouvée) morte, ou le sang qu'on a fait couler, ou la chair de porc - car c'est une souillure - ou ce qui, par perversité, a été sacrifié à autre qu'Allah». Quiconque est contraint, sans toutefois abuser ou transgresser, ton Seigneur est certes Pardonneur et Miséricordieux"
    },
    {
      "key": 935,
      "id": "6-146",
      "translation": "Aux Juifs, Nous avons interdit toute bête à ongle unique. Des bovins et des ovins, Nous leurs avons interdit les graisses, sauf ce que portent leur dos, leurs entrailles, ou ce qui est mêlé à l'os. Ainsi les avons-Nous punis pour leur rébellion. Et Nous sommes bien véridiques"
    },
    {
      "key": 936,
      "id": "6-147",
      "translation": "Puis, s'ils te traitent de menteur, alors dis: «Votre Seigneur est Détenteur d'une immense miséricorde cependant que Sa rigueur ne saura être détournée des gens criminels»"
    },
    {
      "key": 937,
      "id": "6-148",
      "translation": "Ceux qui ont associé diront: «Si Allah avait voulu, nous ne Lui aurions pas donné des associés, nos ancêtres non plus et nous n'aurions rien déclaré interdit.» Ainsi leurs prédécesseurs traitaient de menteurs (les messagers) jusqu'à ce qu'ils eurent goûté Notre rigueur. Dis: «Avez-vous quelque science à nous produire? Vous ne suivez que la conjecture et ne faites que mentir»"
    },
    {
      "key": 938,
      "id": "6-149",
      "translation": "Dis: «L'argument décisif appartient à Allah. S'Il avait voulu certainement Il vous aurait tous guidés. (sur le droit chemin)"
    },
    {
      "key": 939,
      "id": "6-150",
      "translation": "Dis: «Amenez vos témoins qui attesteraient qu'Allah a interdit cela.» Si ensuite ils témoignent, alors toi, ne témoigne pas avec eux et ne suis pas les passions de ceux qui traitent de mensonges Nos signes et qui ne croient pas à l'au-delà, tandis qu'ils donnent des égaux à leur Seigneur"
    },
    {
      "key": 940,
      "id": "6-151",
      "translation": "Dis: «Venez, je vais réciter ce que votre Seigneur vous a interdit: ne Lui associez rien; et soyez bienfaisants envers vos père et mère. Ne tuez pas vos enfants pour cause de pauvreté. Nous vous nourrissons tout comme eux. N'approchez pas des turpitudes ouvertement, ou en cachette. Ne tuez qu'en toute justice la vie qu'Allah a fait sacrée. Voilà ce qu'[Allah] vous a recommandé de faire; peut-être comprendrez-vous"
    },
    {
      "key": 941,
      "id": "6-152",
      "translation": "Et ne vous approchez des biens de l'orphelin que de la plus belle manière, jusqu'à ce qu'il ait atteint sa majorité. Et donnez la juste mesure et le bon poids, en toute justice. Nous n'imposons à une âme que selon sa capacité. Et quand vous parlez, soyez équitables même s'il s'agit d'un proche parent. Et remplissez votre engagement envers Allah. Voilà ce qu'Il vous enjoint. Peut-être vous rappellerez-vous"
    },
    {
      "key": 942,
      "id": "6-153",
      "translation": "«Et voilà Mon chemin dans toute sa rectitude, suivez-le donc; et ne suivez pas les sentiers qui vous écartent de Sa voie.» Voilà ce qu'Il vous enjoint. Ainsi atteindrez-vous la piété"
    },
    {
      "key": 943,
      "id": "6-154",
      "translation": "Puis Nous avons donné à Moïse le Livre complet en récompense pour le bien qu'il avait fait, et comme un exposé détaillé de toute chose, un guide et une miséricorde. Peut-être croiraient-ils en leur rencontre avec leur Seigneur (au jour du Jugement dernier)"
    },
    {
      "key": 944,
      "id": "6-155",
      "translation": "Et voici un Livre (le Coran) béni que Nous avons fait descendre - suivez-le donc et soyez pieux, afin de recevoir la miséricorde"
    },
    {
      "key": 945,
      "id": "6-156",
      "translation": "afin que vous ne disiez point: «On n'a fait descendre le Livre que sur deux peuples avant nous, et nous avons été inattentifs à les étudier"
    },
    {
      "key": 946,
      "id": "6-157",
      "translation": "Ou que vous disiez: «Si c'était à nous qu'on avait fait descendre le Livre, nous aurions certainement été mieux guidés qu'eux.» Voilà certes que vous sont venus, de votre Seigneur, preuve, guidée et miséricorde. Qui est plus injuste que celui qui traite de mensonges les versets d'Allah et qui s'en détourne? Nous punirons ceux qui se détournent de Nos versets, par un mauvais châtiment, pour s'en être détournés"
    },
    {
      "key": 947,
      "id": "6-158",
      "translation": "Qu'attendent-ils? Que les Anges leur viennent? Que vienne ton Seigneur? Ou que viennent certains signes de ton Seigneur? Le jour où certains signes de ton Seigneur viendront, la foi en Lui ne profitera à aucune âme qui n'avait pas cru auparavant ou qui n'avait acquis aucun mérite de sa croyance. Dis: «Attendez!» Nous attendons, Nous aussi"
    },
    {
      "key": 948,
      "id": "6-159",
      "translation": "Ceux qui émiettent leur religion et se divisent en sectes, de ceux-là tu n'es responsable en rien: leur sort ne dépend que d'Allah. Puis Il les informera de ce qu'ils faisaient"
    },
    {
      "key": 949,
      "id": "6-160",
      "translation": "Quiconque viendra avec le bien aura dix fois autant; et quiconque viendra avec le mal ne sera rétribué que par son équivalent. Et on ne leur fera aucune injustice"
    },
    {
      "key": 950,
      "id": "6-161",
      "translation": "Dis: «Moi, mon Seigneur m'a guidé vers un chemin droit, une religion droite, la religion d'Abraham, le soumis exclusivement à Allah et qui n'était point parmi les associateurs"
    },
    {
      "key": 951,
      "id": "6-162",
      "translation": "Dis: «En vérité, ma Salât, mes actes de dévotion, ma vie et ma mort appartiennent à Allah, Seigneur de l'Univers"
    },
    {
      "key": 952,
      "id": "6-163",
      "translation": "A Lui nul associé! Et voilà ce qu'il m'a été ordonné, et je suis le premier à me soumettre.»"
    },
    {
      "key": 953,
      "id": "6-164",
      "translation": "Dis: «Chercherais-je un autre Seigneur qu'Allah, alors qu'Il est le Seigneur de toute chose? Chacun n'acquiert [le mal] qu'à son détriment: personne ne portera le fardeau (responsabilité) d'autrui. Puis vers votre Seigneur sera votre retour et Il vous informera de ce en quoi vous divergez"
    },
    {
      "key": 954,
      "id": "6-165",
      "translation": "C'est Lui qui a fait de vous les successeurs sur terre et qui vous a élevés, en rangs, les uns au-dessus des autres, afin de vous éprouver en ce qu'Il vous a donné. (Vraiment) ton Seigneur est prompt en punition, Il est aussi Pardonneur et Miséricordieux"
    },
    {
      "key": 955,
      "id": "7-1",
      "translation": "Alif, Lâm, Mîm, Sâd"
    },
    {
      "key": 956,
      "id": "7-2",
      "translation": "C'est un Livre qui t'a été descendu; qu'il n'y ait, à son sujet, nulle gêne dans ton cœur; afin que par cela tu avertisses, et (qu'il soit) un Rappel aux croyants"
    },
    {
      "key": 957,
      "id": "7-3",
      "translation": "Suivez ce qui vous a été descendu venant de votre Seigneur et ne suivez pas d'autres alliés que Lui. Mais vous vous souvenez peu"
    },
    {
      "key": 958,
      "id": "7-4",
      "translation": "Que de cités Nous avons détruites! Or, Notre rigueur les atteignit au cours du repos nocturne ou durant leur sieste"
    },
    {
      "key": 959,
      "id": "7-5",
      "translation": "Leur invocation, lorsque leur survint notre rigueur, se limita à ces paroles: «Certes nous étions injustes»"
    },
    {
      "key": 960,
      "id": "7-6",
      "translation": "Nous interrogerons ceux vers qui furent envoyés des messagers et Nous interrogerons aussi les envoyés"
    },
    {
      "key": 961,
      "id": "7-7",
      "translation": "Nous leur raconterons en toute connaissance (ce qu'ils faisaient) car Nous n'étions pas absent"
    },
    {
      "key": 962,
      "id": "7-8",
      "translation": "Et la pesée, ce jour-là, sera équitable. Donc, celui dont les bonnes actions pèseront lourd... voilà ceux qui réussiront"
    },
    {
      "key": 963,
      "id": "7-9",
      "translation": "Et quant à celui dont les bonnes actions pèseront léger... voilà ceux qui auront causé la perte de leurs âmes parce qu'ils étaient injustes envers Nos enseignements"
    },
    {
      "key": 964,
      "id": "7-10",
      "translation": "Certes, Nous vous avons donné du pouvoir sur terre et Nous vous y avons assigné subsistance. (Mais) vous êtes très peu reconnaissants"
    },
    {
      "key": 965,
      "id": "7-11",
      "translation": "Nous vous avons créés, puis Nous vous avons donné une forme, ensuite Nous avons dit aux Anges: «Prosternez-vous devant Adam.» Ils se prosternèrent, à l'exception de Iblis qui ne fut point de ceux qui se prosternèrent"
    },
    {
      "key": 966,
      "id": "7-12",
      "translation": "[Allah] dit: «Qu'est-ce qui t'empêche de te prosterner quand Je te l'ai commandé?» Il répondit: «Je suis meilleur que lui: Tu m'as créé de feu, alors que Tu l'as créé d'argile»"
    },
    {
      "key": 967,
      "id": "7-13",
      "translation": "[Allah] dit: «Descends d'ici, Tu n'as pas à t'enfler d'orgueil ici. Sors, te voilà parmi les méprisés.»"
    },
    {
      "key": 968,
      "id": "7-14",
      "translation": "«Accorde-moi un délai, dit (Satan,) jusqu'au jour où ils seront ressuscités.»"
    },
    {
      "key": 969,
      "id": "7-15",
      "translation": "[Allah] dit: «Tu es de ceux à qui délai est accordé.»"
    },
    {
      "key": 970,
      "id": "7-16",
      "translation": "«Puisque Tu m'as mis en erreur, dit [Satan], je m'assoirai pour eux sur Ton droit chemin"
    },
    {
      "key": 971,
      "id": "7-17",
      "translation": "puis je les assaillerai de devant, de derrière, de leur droite et de leur gauche. Et, pour la plupart, Tu ne les trouveras pas reconnaissants.»"
    },
    {
      "key": 972,
      "id": "7-18",
      "translation": "«Sors de là», dit (Allah,) banni et rejeté. Quiconque te suit parmi eux... de vous tous, J'emplirai l'Enfer»"
    },
    {
      "key": 973,
      "id": "7-19",
      "translation": "«O Adam, habite le Paradis, toi et ton épouse; et mangez en vous deux, à votre guise; et n'approchez pas l'arbre que voici; sinon, vous seriez du nombre des injustes.»"
    },
    {
      "key": 974,
      "id": "7-20",
      "translation": "Puis le Diable, afin de leur rendre visible ce qui leur était caché - leurs nudités - leur chuchota, disant: «Votre Seigneur ne vous a interdit cet arbre que pour vous empêcher de devenir des Anges ou d'être immortels»"
    },
    {
      "key": 975,
      "id": "7-21",
      "translation": "Et il leur jura: «Vraiment, je suis pour vous deux un bon conseiller»"
    },
    {
      "key": 976,
      "id": "7-22",
      "translation": "Alors il les fit tomber par tromperie. Puis, lorsqu'ils eurent goûté de l'arbre, leurs nudités leur devinrent visibles; et ils commencèrent tous deux à y attacher des feuilles du Paradis. Et leur Seigneur les appela: «Ne vous avais-Je pas interdit cet arbre? Et ne vous avais-Je pas dit que le Diable était pour vous un ennemi déclaré?»"
    },
    {
      "key": 977,
      "id": "7-23",
      "translation": "Tous deux dirent: «O notre Seigneur, nous avons fait du tort à nous-mêmes. Et si Tu ne nous pardonnes pas et ne nous fais pas miséricorde, nous serons très certainement du nombre des perdants»"
    },
    {
      "key": 978,
      "id": "7-24",
      "translation": "«Descendez, dit [Allah], vous serez ennemis les uns des autres. Et il y aura pour vous sur terre séjour et jouissance, pour un temps.»"
    },
    {
      "key": 979,
      "id": "7-25",
      "translation": "«Là, dit (Allah), vous vivrez, là vous mourrez, et de là on vous fera sortir.»"
    },
    {
      "key": 980,
      "id": "7-26",
      "translation": "O enfants d'Adam! Nous avons fait descendre sur vous un vêtement pour cacher vos nudités, ainsi que des parures. - Mais le vêtement de la piété voilà qui est meilleur - C'est un des signes (de la puissance) d'Allah. Afin qu'ils se rappellent"
    },
    {
      "key": 981,
      "id": "7-27",
      "translation": "O enfants d'Adam! Que le Diable ne vous tente point, comme il a fait sortir du Paradis vos père et mère, leur arrachant leur vêtement pour leur rendre visibles leurs nudités. Il vous voit, lui et ses suppôts, d'où vous ne les voyez pas. Nous avons désigné les diables pour alliés à ceux qui ne croient point"
    },
    {
      "key": 982,
      "id": "7-28",
      "translation": "et quand ceux-ci commettent une turpitude, ils disent: «C'est une coutume léguée par nos ancêtres et prescrite par Allah.» Dis: «[Non,] Allah ne commande point la turpitude. Direz-vous contre Allah ce que vous ne savez pas?»"
    },
    {
      "key": 983,
      "id": "7-29",
      "translation": "Dis: «Mon Seigneur a commandé l'équité. Que votre prosternation soit exclusivement pour Lui. Et invoquez-Le, sincères dans votre culte. De même qu'Il vous a créés, vous retournerez à Lui»"
    },
    {
      "key": 984,
      "id": "7-30",
      "translation": "Il guide une partie, tandis qu'une autre partie a mérité l'égarement parce qu'ils ont pris, au lieu d'Allah, les diables pour alliés, et ils pensent qu'ils sont bien-guidés"
    },
    {
      "key": 985,
      "id": "7-31",
      "translation": "O enfants d'Adam, dans chaque lieu de Salât portez votre parure (vos habits). Et mangez et buvez; et ne commettez pas d'excès, car Il [Allah] n'aime pas ceux qui commettent des excès"
    },
    {
      "key": 986,
      "id": "7-32",
      "translation": "Dis: «Qui a interdit la parure d'Allah, qu'Il a produite pour Ses serviteurs, ainsi que les bonnes nourritures?» Dis: «Elles sont destinées à ceux qui ont la foi, dans cette vie, et exclusivement à eux au Jour de la Résurrection.» Ainsi exposons-Nous clairement les versets pour les gens qui savent"
    },
    {
      "key": 987,
      "id": "7-33",
      "translation": "Dis: «Mon Seigneur n'a interdit que les turpitudes (les grands péchés), tant apparentes que secrètes, de même que le péché, l'agression sans droit et d'associer à Allah ce dont Il n'a fait descendre aucune preuve, et de dire sur Allah ce que vous ne savez pas»"
    },
    {
      "key": 988,
      "id": "7-34",
      "translation": "Pour chaque communauté il y a un terme. Quand leur terme vient, ils ne peuvent le retarder d'une heure et ils ne peuvent le hâter non plus"
    },
    {
      "key": 989,
      "id": "7-35",
      "translation": "O enfants d'Adam! Si des messagers [choisis] parmi vous viennent pour vous exposer Mes signes, alors ceux qui acquièrent la piété et se réforment, n'auront aucune crainte et ne seront point affligés"
    },
    {
      "key": 990,
      "id": "7-36",
      "translation": "Et ceux qui traitent de mensonges Nos signes et s'en écartent avec orgueil, sont les gens du Feu et ils y demeureront éternellement"
    },
    {
      "key": 991,
      "id": "7-37",
      "translation": "Quel pire injuste, que celui qui invente un mensonge contre Allah, ou qui traite de mensonges Ses signes? Ceux là auront la part qui leur a été prescrite; jusqu'au moment où Nos Envoyés [Nos Anges] viennent à eux pour leur enlever l'âme, en leur disant: «Où sont ceux que vous invoquiez en dehors d'Allah?» - Ils répondront: «Nous ne les trouvons plus». Et ils témoigneront contre eux-mêmes qu'ils étaient mécréants"
    },
    {
      "key": 992,
      "id": "7-38",
      "translation": "«Entrez dans le Feu», dira [Allah,] «parmi les djinns et les hommes des communautés qui vous ont précédés.» Chaque fois qu'une communauté entrera, elle maudira celle qui l'aura précédée. Puis, lorsque tous s'y retrouveront, la dernière fournée dira de la première: «O notre Seigneur! Voilà ceux qui nous ont égarés: donne-leur donc double châtiment du feu.» Il dira: «A chacun le double, mais vous ne savez pas»"
    },
    {
      "key": 993,
      "id": "7-39",
      "translation": "Et la première fournée dira à la dernière: «Mais vous n'avez sur nous aucun avantage. Goûtez donc au châtiment, pour ce que vous avez acquis»"
    },
    {
      "key": 994,
      "id": "7-40",
      "translation": "Pour ceux qui traitent de mensonges Nos enseignements et qui s'en écartent par orgueil, les portes du ciel ne leur seront pas ouvertes, et ils n'entreront au Paradis que quand le chameau pénètre dans le chas de l'aiguille. Ainsi rétribuons-Nous les criminels"
    },
    {
      "key": 995,
      "id": "7-41",
      "translation": "L'Enfer leur servira de lit et, comme couverture, ils auront des voiles de ténèbres. Ainsi rétribuons-Nous les injustes"
    },
    {
      "key": 996,
      "id": "7-42",
      "translation": "Et ceux qui croient et font de bonnes œuvres - Nous n'imposons aucune charge à personne que selon sa capacité - ceux-là seront les gens du Paradis: ils y demeureront éternellement"
    },
    {
      "key": 997,
      "id": "7-43",
      "translation": "Et Nous enlèverons toute la rancune de leurs poitrines, sous eux couleront les ruisseaux, et ils diront: «Louange à Allah qui nous a guidés à ceci. Nous n'aurions pas été guidés, si Allah ne nous avait pas guidés. Les messagers de notre Seigneur sont venus avec la vérité.» Et on leur proclamera: «Voilà le Paradis qui vous a été donné en héritage pour ce que vous faisiez»"
    },
    {
      "key": 998,
      "id": "7-44",
      "translation": "Les gens du Paradis crieront aux gens du Feu: «Certes, nous avons trouvé vrai ce que notre Seigneur nous avait promis. Avez-vous aussi trouvé vrai ce que votre Seigneur avait promis?» «Oui», diront-ils. Un héraut annoncera alors au milieu d'eux: Que la malédiction d'Allah soit sur les injustes"
    },
    {
      "key": 999,
      "id": "7-45",
      "translation": "qui obstruaient le sentier d'Allah, qui voulaient le rendre tortueux, et qui ne croyaient pas à l'au-delà.»"
    },
    {
      "key": 1000,
      "id": "7-46",
      "translation": "Et entre les deux, il y aura un mur, et, sur al-A'râf seront des gens qui reconnaîtront tout le monde par leurs traits caractéristiques. Et ils crieront aux gens du Paradis: «Paix sur vous!» Ils n'y sont pas entrés bien qu'ils le souhaitent"
    },
    {
      "key": 1001,
      "id": "7-47",
      "translation": "Et quand leurs regards seront tournés vers les gens du Feu, ils diront; «O notre Seigneur! Ne nous mets pas avec le peuple injuste»"
    },
    {
      "key": 1002,
      "id": "7-48",
      "translation": "Et les gens d'al-A'râf, appelant certains hommes qu'ils reconnaîtront par leurs traits caractéristiques, diront: «Vous n'avez tiré aucun profit de tout ce que vous aviez amassé et de l'orgueil dont vous étiez enflés"
    },
    {
      "key": 1003,
      "id": "7-49",
      "translation": "Est-ce donc ceux-là au sujet desquels vous juriez qu'ils n'obtiendront de la part d'Allah aucune miséricorde...? - Entrez au Paradis! Vous serez à l'abri de toute crainte et vous ne serez point affligés"
    },
    {
      "key": 1004,
      "id": "7-50",
      "translation": "Et les gens du Feu crieront aux gens du Paradis: «Déversez sur nous de l'eau, ou de ce qu'Allah vous a attribué.» «Ils répondront: Allah les a interdits aux mécréants»"
    },
    {
      "key": 1005,
      "id": "7-51",
      "translation": "Ceux-ci prenaient leur religion comme distraction et jeu, et la vie d'ici-bas les trompait. Aujourd'hui, Nous les oublierons comme ils ont oublié la rencontre de leur jour que voici, et parce qu'ils reniaient Nos enseignements"
    },
    {
      "key": 1006,
      "id": "7-52",
      "translation": "Nous leur avons, certes, apporté un Livre que Nous avons détaillé, en toute connaissance, à titre de guide et de miséricorde pour les gens qui croient"
    },
    {
      "key": 1007,
      "id": "7-53",
      "translation": "Attendent-ils uniquement la réalisation (de Sa menace et de Ses promesses?). Le jour où sa (véritable) réalisation viendra, ceux qui auparavant l'oubliaient diront: «Les messagers de notre Seigneur sont venus avec la vérité. Y a-t-il pour nous des intercesseurs qui puissent intercéder en notre faveur? Ou pourrons-nous être renvoyés [sur terre], afin que nous œuvrions autrement que ce que nous faisions auparavant?» Ils ont certes créé leur propre perte; et ce qu'ils inventaient les a délaissés"
    },
    {
      "key": 1008,
      "id": "7-54",
      "translation": "Votre Seigneur, c'est Allah, qui a créé les cieux et la terre en six jours, puis S'est établi «'istawâ» sur le Trône. Il couvre le jour de la nuit qui poursuit celui-ci sans arrêt. (Il a créé) le soleil, la lune et les étoiles, soumis à Son commandement. La création et le commandement n'appartiennent qu'à Lui. Toute gloire à Allah, Seigneur de l'Univers"
    },
    {
      "key": 1009,
      "id": "7-55",
      "translation": "Invoquez votre Seigneur en toute humilité et recueillement et avec discrétion. Certes, Il n'aime pas les transgresseurs"
    },
    {
      "key": 1010,
      "id": "7-56",
      "translation": "Et ne semez pas la corruption sur la terre après qu'elle ait été réformée. Et invoquez-Le avec crainte et espoir, car la miséricorde d'Allah est proche des bienfaisants"
    },
    {
      "key": 1011,
      "id": "7-57",
      "translation": "C'est Lui qui envoie les vents comme une annonce de Sa Miséricorde. Puis, lorsqu'ils transportent une nuée lourde, Nous la dirigeons vers un pays mort [de sécheresse], puis Nous en faisons descendre l'eau, ensuite Nous en faisons sortir toutes espèces de fruits. Ainsi ferons-Nous sortir les morts. Peut-être vous rappellerez-vous"
    },
    {
      "key": 1012,
      "id": "7-58",
      "translation": "Le bon pays, sa végétation pousse avec la grâce de son Seigneur; quant au mauvais pays, (sa végétation) ne sort qu'insuffisamment et difficilement. Ainsi déployons-Nous les enseignements pour des gens reconnaissants"
    },
    {
      "key": 1013,
      "id": "7-59",
      "translation": "Nous avons envoyé Noé vers son peuple. Il dit: «O mon peuple, adorez Allah. Pour vous, pas d'autre divinité que Lui. Je crains pour vous le châtiment d'un jour terrible»"
    },
    {
      "key": 1014,
      "id": "7-60",
      "translation": "Les notables de son peuple dirent: «Nous te voyons dans un égarement manifeste»"
    },
    {
      "key": 1015,
      "id": "7-61",
      "translation": "Il dit: «O mon peuple, il n'y a pas d'égarement en moi; mais je suis un Messager de la part du Seigneur de l'Univers"
    },
    {
      "key": 1016,
      "id": "7-62",
      "translation": "Je vous communique les messages de mon Seigneur, et je vous donne conseil sincère, et je sais d'Allah ce que vous ne savez pas"
    },
    {
      "key": 1017,
      "id": "7-63",
      "translation": "Est-ce que vous vous étonnez qu'un rappel vous vienne de votre Seigneur à travers un homme issu de vous, pour qu'il vous avertisse et que vous deveniez pieux et que la miséricorde vous soit accordée?»"
    },
    {
      "key": 1018,
      "id": "7-64",
      "translation": "Et ils le traitèrent de menteur. Or, Nous le sauvâmes, lui et ceux qui étaient avec lui dans l'arche, et noyâmes ceux qui traitaient de mensonges Nos miracles. C'étaient des gens aveugles, vraiment"
    },
    {
      "key": 1019,
      "id": "7-65",
      "translation": "Et aux 'Aad, leur frère Hûd: «O mon peuple, dit-il, adorez Allah. Pour vous, pas d'autre divinité que Lui. Ne [Le] craignez-vous donc pas?»"
    },
    {
      "key": 1020,
      "id": "7-66",
      "translation": "Les notables de son peuple qui ne croyaient pas dirent: «Certes, nous te voyons en pleine sottise, et nous pensons que tu es du nombre des menteurs»"
    },
    {
      "key": 1021,
      "id": "7-67",
      "translation": "Il dit: «O mon peuple, il n'y a point de sottise en moi; mais je suis un Messager de la part du Seigneur de l'Univers"
    },
    {
      "key": 1022,
      "id": "7-68",
      "translation": "Je vous communique les messages de mon Seigneur, et je suis pour vous un conseiller digne de confiance"
    },
    {
      "key": 1023,
      "id": "7-69",
      "translation": "Quoi! Vous vous étonnez qu'un rappel vous vienne de votre Seigneur à travers un homme issu de vous, pour qu'il vous avertisse? Et rappelez-vous quand Il vous a fait succéder au peuple de Noé, et qu'Il accrut votre corps en hauteur (et puissance). Eh bien, rappelez-vous les bienfaits d'Allah afin que vous réussissiez"
    },
    {
      "key": 1024,
      "id": "7-70",
      "translation": "Ils dirent: «Es-tu venu à nous pour que nous adorions Allah seul, et que nous délaissions ce que nos ancêtres adoraient? Fais donc venir ce dont tu nous menaces, si tu es du nombre des véridiques»"
    },
    {
      "key": 1025,
      "id": "7-71",
      "translation": "Il dit: «Vous voilà, frappés de la part de votre Seigneur d'un supplice et d'une colère. Allez vous vous disputer avec moi au sujet de noms que vous et vos ancêtres avez donnés, sans qu'Allah n'y fasse descendre la moindre preuve? Attendez donc! Moi aussi j'attends avec vous"
    },
    {
      "key": 1026,
      "id": "7-72",
      "translation": "Or, Nous l'avons sauvé, (lui) et ceux qui étaient avec lui par miséricorde, de Notre part, et Nous avons exterminé ceux qui traitaient de mensonges Nos enseignements et qui n'étaient pas croyants"
    },
    {
      "key": 1027,
      "id": "7-73",
      "translation": "Et aux Thamûd, leur frère Sâlih: «O mon peuple, dit-il, adorez Allah. Pour vous, pas d'autre divinité que Lui. Certes, une preuve vous est venue de votre Seigneur: voici la chamelle d'Allah, un signe pour vous. Laissez-la donc manger sur la terre d'Allah et ne lui faites aucun mal; sinon un châtiment douloureux vous saisira"
    },
    {
      "key": 1028,
      "id": "7-74",
      "translation": "Et rappelez-vous quand Il vous fit succéder aux Aad et vous installa sur la terre. Vous avez édifié des palais dans ses plaines, et taillé en maisons les montagnes. Rappelez-vous donc les bienfaits d'Allah et ne répandez pas la corruption sur la terre «comme des fauteurs de trouble»"
    },
    {
      "key": 1029,
      "id": "7-75",
      "translation": "Les notables de son peuple qui s'enflaient d'orgueil dirent aux opprimés, à ceux d'entre eux qui avaient la foi: «Savez-vous si Sâlih est envoyé de la part de son Seigneur?» Ils dirent: «Oui, nous sommes croyants à son message»"
    },
    {
      "key": 1030,
      "id": "7-76",
      "translation": "Ceux qui s'enflaient d'orgueil dirent: «Nous, nous ne croyons certainement pas en ce que vous avez cru»"
    },
    {
      "key": 1031,
      "id": "7-77",
      "translation": "Ils tuèrent la chamelle, désobéirent au commandement de leur Seigneur et dirent: «O Sâlih, fais nous venir ce dont tu nous menaces, si tu es du nombre des Envoyés»"
    },
    {
      "key": 1032,
      "id": "7-78",
      "translation": "Le cataclysme les saisit; et les voilà étendus gisant dans leurs demeures"
    },
    {
      "key": 1033,
      "id": "7-79",
      "translation": "Alors il se détourna d'eux et dit: «O mon peuple, je vous avais communiqué le message de mon Seigneur et vous avais conseillé sincèrement. Mais vous n'aimez pas les conseillers sincères!»"
    },
    {
      "key": 1034,
      "id": "7-80",
      "translation": "Et Lot, quand il dit à son peuple: «Vous livrez vous à cette turpitude que nul, parmi les mondes, n'a commise avant vous"
    },
    {
      "key": 1035,
      "id": "7-81",
      "translation": "Certes, vous assouvissez vos désirs charnels avec les hommes au lieu des femmes! Vous êtes bien un peuple outrancier.»"
    },
    {
      "key": 1036,
      "id": "7-82",
      "translation": "Et pour toute réponse, son peuple ne fit que dire: «Expulsez-les de votre cité. Ce sont des gens qui veulent se garder purs!»"
    },
    {
      "key": 1037,
      "id": "7-83",
      "translation": "Or, Nous l'avons sauvé, lui et sa famille, sauf sa femme qui fut parmi les exterminés"
    },
    {
      "key": 1038,
      "id": "7-84",
      "translation": "Et Nous avons fait pleuvoir sur eux une pluie. Regarde donc ce que fut la fin des criminels"
    },
    {
      "key": 1039,
      "id": "7-85",
      "translation": "Et aux Madyan, leur frère Chu'aïb: «O mon peuple, dit-il, adorez Allah. Pour vous, pas d'autre divinité que Lui. Une preuve vous est venue de votre Seigneur. Donnez donc la pleine mesure et le poids et ne donnez pas aux gens moins que ce qui leur est dû. Et ne commettez pas de la corruption sur la terre après sa réforme. Ce sera mieux pour vous si vous êtes croyants"
    },
    {
      "key": 1040,
      "id": "7-86",
      "translation": "Et ne vous placez pas sur tout chemin, menaçant, empêchant du sentier d'Allah celui qui croit en Lui et cherchant à rendre ce sentier tortueux. Rappelez-vous quand vous étiez peu nombreux et qu'Il vous a multipliés en grand nombre. Et regardez ce qui est advenu aux fauteurs de désordre"
    },
    {
      "key": 1041,
      "id": "7-87",
      "translation": "Si une partie d'entre vous a cru au message avec lequel j'ai été envoyé, et qu'une partie n'a pas cru, patientez donc jusqu'à ce qu'Allah juge parmi nous car Il est le Meilleur des juges.»"
    },
    {
      "key": 1042,
      "id": "7-88",
      "translation": "Les notables de son peuple qui s'enflaient d'orgueil, dirent: «Nous t'expulserons certes de notre cité, ô Chu'aïb, toi et ceux qui ont cru avec toi. Ou que vous reveniez à notre religion.» - Il dit: «Est-ce même quand cela nous répugne?»"
    },
    {
      "key": 1043,
      "id": "7-89",
      "translation": "Certes, nous aurions forgé un mensonge contre Allah si nous revenions à votre religion après qu'Allah nous en a sauvés. Il ne nous appartient pas d'y retourner à moins qu'Allah notre Seigneur ne le veuille. Notre Seigneur embrasse toute chose de Sa science. C'est en Allah que nous plaçons notre confiance. O notre Seigneur, tranche par la vérité, entre nous et notre peuple car Tu es le meilleur des juges.»"
    },
    {
      "key": 1044,
      "id": "7-90",
      "translation": "Et les notables de son peuple qui ne croyaient pas, dirent: «Si vous suivez Chu'aïb, vous serez assurément perdants»"
    },
    {
      "key": 1045,
      "id": "7-91",
      "translation": "Alors le tremblement (de terre) les saisit; et les voilà étendus, gisant dans leurs demeures"
    },
    {
      "key": 1046,
      "id": "7-92",
      "translation": "Ceux qui traitaient Chu'aïb de menteur (disparurent) comme s'ils n'y avaient jamais vécu. Ceux qui traitaient Chu'aïb de menteur furent eux les perdants"
    },
    {
      "key": 1047,
      "id": "7-93",
      "translation": "Il se détourna d'eux et dit: «O mon peuple, je vous ai bien communiqué les messages de mon Seigneur et donné des conseils. Comment donc m'attristerais-je sur des gens mécréants?»"
    },
    {
      "key": 1048,
      "id": "7-94",
      "translation": "Nous n'avons envoyé aucun prophète dans une cité, sans que Nous n'ayons pris ses habitants ensuite par l'adversité et la détresse afin qu'ils implorent (le pardon)"
    },
    {
      "key": 1049,
      "id": "7-95",
      "translation": "Puis Nous avons changé leur mauvaise condition en y substituant le bien, au point qu'ayant grandi en nombre et en richesse, ils dirent: «La détresse et l'aisance ont touché nos ancêtres aussi.» Eh bien, Nous les avons saisis soudain, sans qu'ils s'en rendent compte"
    },
    {
      "key": 1050,
      "id": "7-96",
      "translation": "Si les habitants des cités avaient cru et avaient été pieux, Nous leur aurions certainement accordé des bénédictions du ciel et de la terre. Mais ils ont démenti et Nous les avons donc saisis, pour ce qu'ils avaient acquis"
    },
    {
      "key": 1051,
      "id": "7-97",
      "translation": "Les gens des cités sont-ils sûrs que Notre châtiment rigoureux ne les atteindra pas la nuit, pendant qu'ils sont endormis"
    },
    {
      "key": 1052,
      "id": "7-98",
      "translation": "Les gens des cités sont-ils sûrs que Notre châtiment rigoureux ne les atteindra pas le jour, pendant qu'ils s'amusent"
    },
    {
      "key": 1053,
      "id": "7-99",
      "translation": "Sont-ils à l'abri du stratagème d'Allah? Seuls les gens perdus se sentent à l'abri du stratagème d'Allah"
    },
    {
      "key": 1054,
      "id": "7-100",
      "translation": "N'est-il pas prouvé à ceux qui reçoivent la terre en héritage des peuples précédents que, si Nous voulions, Nous les frapperions pour leurs péchés et scellerions leurs cœurs, et ils n'entendraient plus rien"
    },
    {
      "key": 1055,
      "id": "7-101",
      "translation": "Voilà les cités dont Nous te racontons certaines de leurs nouvelles. [A ceux-là,] en vérité, leurs messagers leur avaient apporté les preuves, mais ils n'étaient pas prêts à accepter ce qu'auparavant ils avaient traité de mensonge. C'est ainsi qu'Allah scelle les cœurs des mécréants"
    },
    {
      "key": 1056,
      "id": "7-102",
      "translation": "Et Nous n'avons trouvé chez la plupart d'entre eux aucun respect de l'engagement; mais Nous avons trouvé la plupart d'entre eux pervers"
    },
    {
      "key": 1057,
      "id": "7-103",
      "translation": "Puis, après [ces messagers,] Nous avons envoyé Moïse avec Nos miracles vers Pharaon et ses notables. Mais ils se montrèrent injustes envers Nos signes. Considère donc quelle fut la fin des corrupteurs"
    },
    {
      "key": 1058,
      "id": "7-104",
      "translation": "Et Moïse dit: «O Pharaon, je suis un Messager de la part du Seigneur de l'Univers"
    },
    {
      "key": 1059,
      "id": "7-105",
      "translation": "je ne dois dire sur Allah que la vérité. Je suis venu à vous avec une preuve de la part de votre Seigneur. Laisse donc partir avec moi les Enfants d'Israël.»"
    },
    {
      "key": 1060,
      "id": "7-106",
      "translation": "«Si tu es venu avec un miracle, dit (Pharaon,) apporte-le donc, si tu es du nombre des véridiques.»"
    },
    {
      "key": 1061,
      "id": "7-107",
      "translation": "Il jeta son bâton et voilà que c'était un serpent évident"
    },
    {
      "key": 1062,
      "id": "7-108",
      "translation": "Et il sortit sa main et voilà qu'elle était blanche (éclatante), pour ceux qui regardaient"
    },
    {
      "key": 1063,
      "id": "7-109",
      "translation": "Les notables du peuple de Pharaon dirent: «Voilà, certes, un magicien chevronné"
    },
    {
      "key": 1064,
      "id": "7-110",
      "translation": "Il veut vous expulser de votre pays.» - «Alors, que commandez-vous?»"
    },
    {
      "key": 1065,
      "id": "7-111",
      "translation": "Ils dirent: «Fais-le attendre, lui et son frère, et envoie des rassembleurs dans les villes"
    },
    {
      "key": 1066,
      "id": "7-112",
      "translation": "qui t'amèneront tout magicien averti"
    },
    {
      "key": 1067,
      "id": "7-113",
      "translation": "Et les magiciens vinrent à Pharaon en disant: «Y aura-t-il vraiment une récompense pour nous, si nous sommes les vainqueurs?»"
    },
    {
      "key": 1068,
      "id": "7-114",
      "translation": "Il dit: «Oui, et vous serez certainement du nombre de mes rapprochés»"
    },
    {
      "key": 1069,
      "id": "7-115",
      "translation": "Ils dirent: «O Moïse, ou bien tu jetteras (le premier), ou bien nous serons les premiers à jeter»"
    },
    {
      "key": 1070,
      "id": "7-116",
      "translation": "«Jetez» dit-il. Puis lorsqu'ils eurent jeté, ils ensorcelèrent les yeux des gens et les épouvantèrent, et vinrent avec une puissante magie"
    },
    {
      "key": 1071,
      "id": "7-117",
      "translation": "Et Nous révélâmes à Moïse: «Jette ton bâton». Et voilà que celui-ci se mit à engloutir ce qu'ils avaient fabriqué"
    },
    {
      "key": 1072,
      "id": "7-118",
      "translation": "Ainsi la vérité se manifesta et ce qu'ils firent fût vain"
    },
    {
      "key": 1073,
      "id": "7-119",
      "translation": "Ainsi ils furent battus et se trouvèrent humiliés"
    },
    {
      "key": 1074,
      "id": "7-120",
      "translation": "Et les magiciens se jetèrent prosternés"
    },
    {
      "key": 1075,
      "id": "7-121",
      "translation": "Ils dirent: «Nous croyons au Seigneur de l'Univers"
    },
    {
      "key": 1076,
      "id": "7-122",
      "translation": "au Seigneur de Moïse et d'Aaron.»"
    },
    {
      "key": 1077,
      "id": "7-123",
      "translation": "«Y avez-vous cru avant que je ne vous (le) permette? dit Pharaon. C'est bien un stratagème que vous avez manigancé dans la ville, afin d'en faire partir ses habitants. Vous saurez bientôt"
    },
    {
      "key": 1078,
      "id": "7-124",
      "translation": "Je vais vous couper la main et la jambe opposées, et puis, je vous crucifierai tous.»"
    },
    {
      "key": 1079,
      "id": "7-125",
      "translation": "Ils dirent: «En vérité, c'est vers notre Seigneur que nous retournerons"
    },
    {
      "key": 1080,
      "id": "7-126",
      "translation": "Tu ne te venges de nous que parce que nous avons cru aux preuves de notre Seigneur, lorsqu'elles nous sont venues. O notre Seigneur! Déverse sur nous l'endurance et fais nous mourir entièrement soumis.»"
    },
    {
      "key": 1081,
      "id": "7-127",
      "translation": "Et les notables du peuple de Pharaon dirent: «Laisseras-tu Moïse et son peuple commettre du désordre sur la terre, et lui-même te délaisser, toi et tes divinités?» Il dit: «Nous allons massacrer leurs fils et laisser vivre leurs femmes. Nous aurons le dessus sur eux et les dominerons.»"
    },
    {
      "key": 1082,
      "id": "7-128",
      "translation": "Moïse dit à son peuple: «Demandez aide auprès d'Allah et soyez patients, car la terre appartient à Allah. Il en fait héritier qui Il veut parmi Ses serviteurs. Et la fin (heureuse) sera aux pieux»"
    },
    {
      "key": 1083,
      "id": "7-129",
      "translation": "Ils dirent: «Nous avons été persécutés avant que tu ne viennes à nous, et après ton arrivée.» Il dit: «Il se peut que votre Seigneur détruise votre ennemi et vous donne la lieutenance sur terre, et Il verra ensuite comment vous agirez»"
    },
    {
      "key": 1084,
      "id": "7-130",
      "translation": "Nous avons éprouvé les gens de Pharaon par des années de disette et par une diminution des fruits afin qu'ils se rappellent"
    },
    {
      "key": 1085,
      "id": "7-131",
      "translation": "Et quand le bien-être leur vint, ils dirent: «Cela nous est dû» et si un mal les atteignait, ils voyaient en Moïse et ceux qui étaient avec lui un mauvais augure. En vérité leur sort dépend uniquement d'Allah? Mais la plupart d'entre eux ne savent pas"
    },
    {
      "key": 1086,
      "id": "7-132",
      "translation": "Et ils dirent: «Quel que soit le miracle que tu nous apportes pour nous fasciner, nous ne croirons pas en toi»"
    },
    {
      "key": 1087,
      "id": "7-133",
      "translation": "Et Nous avons alors envoyé sur eux l'inondation, les sauterelles, les poux (ou la calandre), les grenouilles et le sang, comme signes explicites. Mais ils s'enflèrent d'orgueil et demeurèrent un peuple criminel"
    },
    {
      "key": 1088,
      "id": "7-134",
      "translation": "Et quand le châtiment les frappa, ils dirent: «O Moïse, invoque pour nous ton Seigneur en vertu de l'engagement qu'Il t'a donné. Si tu éloignes de nous le châtiment, nous croirons certes en toi et laisserons partir avec toi les enfants d'Israël»"
    },
    {
      "key": 1089,
      "id": "7-135",
      "translation": "Et quand Nous eûmes éloigné d'eux le châtiment jusqu'au terme fixé qu'ils devaient atteindre, voilà qu'ils violèrent l'engagement"
    },
    {
      "key": 1090,
      "id": "7-136",
      "translation": "Alors Nous Nous sommes vengés d'eux; Nous les avons noyés dans les flots, parce qu'ils traitaient de mensonges Nos signes et n'y prêtaient aucune attention"
    },
    {
      "key": 1091,
      "id": "7-137",
      "translation": "Et les gens qui étaient opprimés, Nous les avons fait hériter les contrées orientales et occidentales de la terre que Nous avons bénies. Et la très belle promesse de ton Seigneur sur les enfants d'Israël s'accomplit pour prix de leur endurance. Et Nous avons détruit ce que faisaient Pharaon et son peuple, ainsi que ce qu'ils construisaient"
    },
    {
      "key": 1092,
      "id": "7-138",
      "translation": "Et Nous avons fait traverser la Mer aux Enfants d'Israël. Ils passèrent auprès d'un peuple attaché à ses idoles et dirent: «O Moïse, désigne-nous une divinité semblable à leurs dieux.» Il dit: «Vous êtes certes des gens ignorants"
    },
    {
      "key": 1093,
      "id": "7-139",
      "translation": "Le culte, auquel ceux-là s'adonnent, est caduc; et tout ce qu'ils font est nul et sans valeur.»"
    },
    {
      "key": 1094,
      "id": "7-140",
      "translation": "Il dit: «Chercherai-je pour vous une autre divinité qu'Allah, alors que c'est Lui qui vous a préférés à toutes les créatures [de leur époque]?»"
    },
    {
      "key": 1095,
      "id": "7-141",
      "translation": "(Rappelez-vous) le moment où Nous vous sauvâmes des gens de Pharaon qui vous infligeaient le pire châtiment. Ils massacraient vos fils et laissaient vivre vos femmes. C'était là une terrible épreuve de la part de votre Seigneur"
    },
    {
      "key": 1096,
      "id": "7-142",
      "translation": "Et Nous donnâmes à Moïse rendez-vous pendant trente nuits, et Nous les complétâmes par dix, de sorte que le temps fixé par son Seigneur se termina au bout de quarante nuits. Et Moïse dit à Aaron son frère: «Remplace-moi auprès de mon peuple, et agis en bien, et ne suis pas le sentier des corrupteurs»"
    },
    {
      "key": 1097,
      "id": "7-143",
      "translation": "Et lorsque Moïse vint à Notre rendez-vous et que son Seigneur lui eut parlé, il dit: «O mon Seigneur, montre Toi à moi pour que je Te voie!» Il dit: «Tu ne Me verras pas; mais regarde le Mont: s'il tient en sa place, alors tu Me verras.» Mais lorsque son Seigneur Se manifesta au Mont, Il le pulvérisa, et Moïse s'effondra foudroyé. Lorsqu'il se fut remis, il dit: «Gloire à Toi! A Toi je me repens; et je suis le premier des croyants»"
    },
    {
      "key": 1098,
      "id": "7-144",
      "translation": "Et (Allah) dit: «O Moïse, Je t'ai préféré à tous les hommes, par Mes messages et par Ma parole. Prends donc ce que Je te donne, et sois du nombre des reconnaissants»"
    },
    {
      "key": 1099,
      "id": "7-145",
      "translation": "Et Nous écrivîmes pour lui, sur les tablettes, une exhortation concernant toute chose, et un exposé détaillé de toute chose. «Prends-les donc fermement et commande à ton peuple d'en adopter le meilleur. Bientôt Je vous ferai voir la demeure des pervers"
    },
    {
      "key": 1100,
      "id": "7-146",
      "translation": "J'écarterai de Mes signes ceux qui, sans raison, s'enflent d'orgueil sur terre. Même s'ils voyaient tous les miracles, ils n'y croiraient pas. Et s'ils voient le bon sentier, ils ne le prennent pas comme sentier. Mais s'ils voient le sentier de l'erreur, ils le prennent comme sentier. C'est qu'en vérité ils traitent de mensonges Nos preuves et ils ne leur accordaient aucune attention"
    },
    {
      "key": 1101,
      "id": "7-147",
      "translation": "Et ceux qui traitent de mensonges Nos preuves ainsi que la rencontre de l'au-delà, leurs œuvres sont vaines. Seraient-ils rétribués autrement que selon leurs œuvres?»"
    },
    {
      "key": 1102,
      "id": "7-148",
      "translation": "Et le peuple de Moïse adopta après lui un veau, fait de leurs parures: un corps qui semblait mugir. N'ont-ils pas vu qu'il ne leur parlait point et qu'il ne les guidait sur aucun chemin? Ils l'adoptèrent [comme divinité], et ils étaient des injustes"
    },
    {
      "key": 1103,
      "id": "7-149",
      "translation": "Et quand ils éprouvèrent des regrets, et qu'ils virent qu'ils étaient bel et bien égarés, ils dirent: «Si notre Seigneur ne nous fait pas miséricorde et ne nous pardonne pas, nous serons très certainement du nombre des perdants»"
    },
    {
      "key": 1104,
      "id": "7-150",
      "translation": "Et lorsque Moïse retourna à son peuple, fâché, attristé, il dit: «Vous avez très mal agi pendant mon absence! Avez-vous voulu hâter le commandement de votre Seigneur?» Il jeta les tablettes et prit la tête de son frère, en la tirant à lui: «O fils de ma mère, dit (Aaron), le peuple m'a traité en faible, et peu s'en est fallu qu'ils ne me tuent. Ne fais donc pas que les ennemis se réjouissent à mes dépens, et ne m'assigne pas la compagnie des gens injustes»"
    },
    {
      "key": 1105,
      "id": "7-151",
      "translation": "Et (Moïse) dit: «O mon Seigneur, pardonne à moi et à mon frère et fais-nous entrer en Ta miséricorde, car Tu es Le plus Miséricordieux des miséricordieux»"
    },
    {
      "key": 1106,
      "id": "7-152",
      "translation": "Ceux qui prenaient le veau (comme divinité), bientôt tombera sur eux de la part de leur Seigneur, une colère, et un avilissement dans la vie présente. Ainsi, Nous rétribuons les inventeurs (d'idoles)"
    },
    {
      "key": 1107,
      "id": "7-153",
      "translation": "Ceux qui ont fait de mauvaises actions et qui ensuite se sont repentis et ont cru... ton Seigneur, après cela est sûrement Pardonneur et Miséricordieux"
    },
    {
      "key": 1108,
      "id": "7-154",
      "translation": "Et quand la colère de Moïse se fut calmée, il prit les tablettes. Il y avait dans leur texte guide et miséricorde à l'intention de ceux qui craignent leur Seigneur"
    },
    {
      "key": 1109,
      "id": "7-155",
      "translation": "Et Moïse choisit de son peuple soixante-dix hommes pour un rendez-vous avec Nous. Puis lorsqu'ils furent saisis par le tremblement (de terre), il dit: «Mon Seigneur, si Tu avais voulu, Tu les aurais détruits avant, et moi avec. Vas-Tu nous détruire pour ce que des sots d'entre nous ont fait? Ce n'est là qu'une épreuve de Toi, par laquelle Tu égares qui Tu veux, et guides qui Tu veux. Tu es notre Maître. Pardonne-nous et fais-nous miséricorde, car Tu es le Meilleur des pardonneurs"
    },
    {
      "key": 1110,
      "id": "7-156",
      "translation": "Et prescris pour nous le bien ici-bas ainsi que dans l'au-delà. Nous voilà revenus vers Toi, repentis.» Et (Allah) dit: «Je ferai que Mon châtiment atteigne qui Je veux. Et Ma miséricorde embrasse toute chose. Je la prescrirai à ceux qui (Me) craignent, acquittent la Zakât, et ont foi en Nos signes"
    },
    {
      "key": 1111,
      "id": "7-157",
      "translation": "Ceux qui suivent le Messager, le Prophète illettré qu'ils trouvent écrit (mentionné) chez eux dans la Thora et l'Evangile. Il leur ordonne le convenable, leur défend le blâmable, leur rend licites les bonnes choses, leur interdit les mauvaises, et leur ôte le fardeau et les jougs qui étaient sur eux. Ceux qui croiront en lui, le soutiendront, lui porteront secours et suivront la lumière descendue avec lui; ceux-là seront les gagnants"
    },
    {
      "key": 1112,
      "id": "7-158",
      "translation": "Dis: «O hommes! Je suis pour vous tous le Messager d'Allah, à Qui appartient la royauté des cieux et de la terre. Pas de divinité à part Lui. Il donne la vie et Il donne la mort. Croyez donc en Allah, en Son messager, le Prophète illettré qui croit en Allah et en Ses paroles. Et suivez-le afin que vous soyez bien guidés»"
    },
    {
      "key": 1113,
      "id": "7-159",
      "translation": "Parmi le peuple de Moïse, il est une communauté qui guide (les autres) avec la vérité, et qui, par là, exerce la justice"
    },
    {
      "key": 1114,
      "id": "7-160",
      "translation": "Nous les répartîmes en douze tribus, (en douze) communautés. Et Nous révélâmes à Moïse, lorsque son peuple lui demanda de l'eau: «Frappe le rocher avec ton bâton.» Et voilà qu'en jaillirent douze sources. Chaque tribu sut son abreuvoir. Nous les couvrîmes de l'ombre du nuage, et fîmes descendre sur eux la manne et les cailles: «Mangez des bonnes choses que Nous vous avons attribuées.» Et ce n'est pas à Nous qu'ils ont fait du tort, mais c'est à eux-mêmes qu'ils en faisaient"
    },
    {
      "key": 1115,
      "id": "7-161",
      "translation": "Et lorsqu'il leur fut dit: «Habitez cette cité et mangez [de ses produits] à votre guise, mais dites: rémission [à nos péchés] et entrez par la porte en vous prosternant. Nous vous pardonnerons vos fautes; et aux bienfaisants (d'entre vous,) Nous accorderons davantage»"
    },
    {
      "key": 1116,
      "id": "7-162",
      "translation": "Puis, les injustes parmi eux changèrent en une autre, la parole qui leur était dite. Alors, Nous envoyâmes du ciel un châtiment sur eux, pour le méfait qu'ils avaient commis"
    },
    {
      "key": 1117,
      "id": "7-163",
      "translation": "Et interroge-les au sujet de la cité qui donnait sur la mer, lorsqu'on y transgressait le Sabbat! Que leurs poissons venaient à eux faisant surface, au jour de leur Sabbat, et ne venaient pas à eux le jour où ce n'était pas Sabbat! Ainsi les éprouvions-Nous pour la perversité qu'ils commettaient"
    },
    {
      "key": 1118,
      "id": "7-164",
      "translation": "Et quand parmi eux une communauté dit: «Pourquoi exhortez-vous un peuple qu'Allah va anéantir ou châtier d'un châtiment sévère?» Ils répondirent: «Pour dégager notre responsabilité vis-à-vis de votre Seigneur; et que peut-être ils deviendront pieux!»"
    },
    {
      "key": 1119,
      "id": "7-165",
      "translation": "Puis, lorsqu'ils oublièrent ce qu'on leur avait rappelé, Nous sauvâmes ceux qui (leur) avaient interdit le mal et saisîmes par un châtiment rigoureux les injustes pour leurs actes pervers"
    },
    {
      "key": 1120,
      "id": "7-166",
      "translation": "Puis, lorsqu'ils refusèrent (par orgueil) d'abandonner ce qui leur avait été interdit, Nous leur dîmes: «Soyez des singes abjects»"
    },
    {
      "key": 1121,
      "id": "7-167",
      "translation": "Et lorsque ton Seigneur annonça qu'Il enverra certes contre eux quelqu'un qui leur imposera le pire châtiment jusqu'au Jour de la Résurrection. En vérité ton Seigneur est prompt à punir mais Il est aussi Pardonneur et Miséricordieux"
    },
    {
      "key": 1122,
      "id": "7-168",
      "translation": "Et Nous les avons répartis en communautés sur la terre. Il y a parmi eux des gens de bien, mais il y en a qui le sont moins. Nous les avons éprouvés par des biens et par des maux, peut-être reviendraient-ils (au droit chemin)"
    },
    {
      "key": 1123,
      "id": "7-169",
      "translation": "Puis les suivirent des successeurs qui héritèrent le Livre, mais qui préférèrent ce qu'offre la vie d'ici-bas en disant: «Nous aurons le pardon.» Et si des choses semblables s'offrent à eux, ils les acceptent. N'avait-on pas pris d'eux l'engagement du Livre, qu'ils ne diraient sur Allah que la vérité? Ils avaient pourtant étudié ce qui s'y trouve. Et l'ultime demeure est meilleure pour ceux qui pratiquent la piété, - Ne comprendrez-vous donc pas"
    },
    {
      "key": 1124,
      "id": "7-170",
      "translation": "Et ceux qui se conforment au Livre et accomplissent laSalât, [en vérité], Nous ne laissons pas perdre la récompense de ceux qui s'amendent"
    },
    {
      "key": 1125,
      "id": "7-171",
      "translation": "Et lorsque Nous avons brandi au-dessus d'eux le Mont, comme si c'eût été une ombrelle. Ils pensaient qu'il allait tomber sur eux. «Tenez fermement à ce que Nous vous donnons et rappelez-vous son contenu. Peut-être craindrez vous Allah»"
    },
    {
      "key": 1126,
      "id": "7-172",
      "translation": "Et quand ton Seigneur tira une descendance des reins des fils d'Adam et les fit témoigner sur eux-mêmes: «Ne suis-Je pas votre Seigneur?» Ils répondirent: «Mais si, nous en témoignons...» - afin que vous ne disiez point, au Jour de la Résurrection: «Vraiment, nous n'y avons pas fait attention»"
    },
    {
      "key": 1127,
      "id": "7-173",
      "translation": "ou que vous auriez dit (tout simplement): «Nos ancêtres autrefois donnaient des associés à Allah, et nous sommes leurs descendants, après eux. Vas-Tu nous détruire pour ce qu'ont fait les imposteurs?»"
    },
    {
      "key": 1128,
      "id": "7-174",
      "translation": "Et c'est ainsi que Nous expliquons intelligemment les signes. Peut-être reviendront-ils"
    },
    {
      "key": 1129,
      "id": "7-175",
      "translation": "Et raconte-leur l'histoire de celui à qui Nous avions donné Nos signes et qui s'en écarta. Le Diable, donc, l'entraîna dans sa suite et il devint ainsi du nombre des égarés"
    },
    {
      "key": 1130,
      "id": "7-176",
      "translation": "Et si Nous avions voulu, Nous l'aurions élevé par ces mêmes enseignements, mais il s'inclina vers la terre et suivit sa propre passion. Il est semblable à un chien qui halète si tu l'attaques, et qui halète aussi si tu le laisses. Tel est l'exemple des gens qui traitent de mensonges Nos signes. Eh bien, raconte le récit. Peut-être réfléchiront-ils"
    },
    {
      "key": 1131,
      "id": "7-177",
      "translation": "Quel mauvais exemple que ces gens qui traitent de mensonges Nos signes, cependant que c'est à eux-mêmes qu'ils font du tort"
    },
    {
      "key": 1132,
      "id": "7-178",
      "translation": "Quiconque Allah guide, voilà le bien guidé. Et quiconque Il égare, voilà les perdants"
    },
    {
      "key": 1133,
      "id": "7-179",
      "translation": "Nous avons destiné beaucoup de djinns et d'hommes pour l'Enfer. Ils ont des cœurs, mais ne comprennent pas. Ils ont des yeux, mais ne voient pas. Ils ont des oreilles, mais n'entendent pas. Ceux-là sont comme les bestiaux, même plus égarés encore. Tels sont les insouciants"
    },
    {
      "key": 1134,
      "id": "7-180",
      "translation": "C'est à Allah qu'appartiennent les noms les plus beaux. Invoquez-Le par ces noms et laissez ceux qui profanent Ses noms: ils seront rétribués pour ce qu'ils ont fait"
    },
    {
      "key": 1135,
      "id": "7-181",
      "translation": "Parmi ceux que Nous avons créés, il y a une communauté qui guide (les autres) selon la vérité et par celle-ci exerce la justice"
    },
    {
      "key": 1136,
      "id": "7-182",
      "translation": "Ceux qui traitent de mensonges Nos enseignements, Nous allons les conduire graduellement vers leur perte par des voies qu'ils ignorent"
    },
    {
      "key": 1137,
      "id": "7-183",
      "translation": "Et Je leur accorderai un délai, car Mon stratagème est solide"
    },
    {
      "key": 1138,
      "id": "7-184",
      "translation": "Est-ce qu'ils n'ont pas réfléchi? Il n'y a point de folie en leur compagnon : il n'est qu'un avertisseur explicite"
    },
    {
      "key": 1139,
      "id": "7-185",
      "translation": "N'ont-ils pas médité sur le royaume des cieux et de la terre, et toute chose qu'Allah a créée, et que leur terme est peut-être déjà proche? En quelle parole croiront-ils après cela"
    },
    {
      "key": 1140,
      "id": "7-186",
      "translation": "Quiconque Allah égare, pas de guide pour lui. Et Il les laisse dans leur transgression confus et hésitants"
    },
    {
      "key": 1141,
      "id": "7-187",
      "translation": "Ils t'interrogent sur l'Heure: «Quand arrivera-t-elle?» Dis: «Seul mon Seigneur en a connaissance. Lui seul la manifestera en son temps. Lourde elle sera dans les cieux et (sur) la terre et elle ne viendra à vous que soudainement.» Ils t'interrogent comme si tu en étais averti. Dis: «Seul Allah en a connaissance.» Mais beaucoup de gens ne savent pas"
    },
    {
      "key": 1142,
      "id": "7-188",
      "translation": "Dis: «Je ne détiens pour moi-même ni profit ni dommage, sauf ce qu'Allah veut. Et si je connaissais l'Inconnaissable, j'aurais eu des biens en abondance et aucun mal ne m'aurait touché. Je ne suis, pour les gens qui croient, qu'un avertisseur et un annonciateur»"
    },
    {
      "key": 1143,
      "id": "7-189",
      "translation": "C'est Lui qui vous a créés d'un seul être dont il a tiré son épouse, pour qu'il trouve de la tranquillité auprès d'elle; et lorsque celui-ci eut cohabité avec elle, elle conçut une légère grossesse, avec quoi elle se déplaçait (facilement). Puis lorsqu'elle se trouva alourdie, tous deux invoquèrent leur Seigneur: «Si Tu nous donnes un (enfant) sain, nous serons certainement du nombre des reconnaissants»"
    },
    {
      "key": 1144,
      "id": "7-190",
      "translation": "Puis, lorsqu'Il leur eût donné un (enfant) sain, tous deux assignèrent à Allah des associés en ce qu'Il leur avait donné. Mais Allah est bien au-dessus des associés qu'on Lui assigne"
    },
    {
      "key": 1145,
      "id": "7-191",
      "translation": "Est-ce qu'ils assignent comme associés ce qui ne crée rien et qui eux-mêmes sont créés"
    },
    {
      "key": 1146,
      "id": "7-192",
      "translation": "et qui ne peuvent ni les secourir ni se secourir eux-mêmes"
    },
    {
      "key": 1147,
      "id": "7-193",
      "translation": "Si vous les appelez vers le chemin droit, ils ne vous suivront pas. Le résultat pour vous est le même, que vous les appeliez ou que vous gardiez le silence"
    },
    {
      "key": 1148,
      "id": "7-194",
      "translation": "Ceux que vous invoquez en dehors d'Allah sont des serviteurs comme vous. Invoquez-les donc et qu'ils vous répondent, si vous êtes véridiques"
    },
    {
      "key": 1149,
      "id": "7-195",
      "translation": "Ont-ils des jambes pour marcher? Ont-ils de mains pour frapper? Ont-ils des yeux pour observer? Ont-ils des oreilles pour entendre? Dis: «Invoquez vos associés, et puis, rusez contre moi; et ne me donnez pas de répit"
    },
    {
      "key": 1150,
      "id": "7-196",
      "translation": "Certes mon Maître, c'est Allah qui a fait descendre le Livre (le Coran). C'est Lui qui se charge (de la protection) des vertueux"
    },
    {
      "key": 1151,
      "id": "7-197",
      "translation": "Et ceux que vous invoquez en dehors de Lui ne sont capables ni de vous secourir, ni de se secourir eux-mêmes.»"
    },
    {
      "key": 1152,
      "id": "7-198",
      "translation": "Et si tu les appelles vers le chemin droit, ils n'entendent pas. Tu les vois qui te regardent, (mais) ils ne voient pas"
    },
    {
      "key": 1153,
      "id": "7-199",
      "translation": "Accepte ce qu'on t'offre de raisonnable, commande ce qui est convenable et éloigne-toi des ignorants"
    },
    {
      "key": 1154,
      "id": "7-200",
      "translation": "Et si jamais le Diable t'incite à faire le mal, cherche refuge auprès d'Allah. Car Il entend, et sait tout"
    },
    {
      "key": 1155,
      "id": "7-201",
      "translation": "Ceux qui pratiquent la piété, lorsqu'une suggestion du Diable les touche se rappellent [du châtiment d'Allah]: et les voilà devenus clairvoyants"
    },
    {
      "key": 1156,
      "id": "7-202",
      "translation": "(Quant aux méchants), leurs partenaires diaboliques les enfoncent dans l'aberration, puis ils ne cessent (de s'enfoncer)"
    },
    {
      "key": 1157,
      "id": "7-203",
      "translation": "Quand tu ne leur apportes pas de miracle, ils disent: «Pourquoi ne l'inventes-tu pas?» Dis: «Je ne fais que suivre ce qui m'est révélé de mon Seigneur. Ces [versets coraniques] sont des preuves illuminantes venant de votre Seigneur, un guide et une grâce pour des gens qui croient"
    },
    {
      "key": 1158,
      "id": "7-204",
      "translation": "Et quand on récite le Coran, prêtez-lui l'oreille attentivement et observez le silence, afin que vous obteniez la miséricorde (d'Allah)"
    },
    {
      "key": 1159,
      "id": "7-205",
      "translation": "Et invoque ton Seigneur en toi-même, en humilité et crainte, à mi-voix, le matin et le soir, et ne sois pas du nombre des insouciants"
    },
    {
      "key": 1160,
      "id": "7-206",
      "translation": "Ceux qui sont auprès de ton Seigneur [les anges] ne dédaignent pas de L'adorer. Ils Le glorifient et se prosternent devant Lui"
    },
    {
      "key": 1161,
      "id": "8-1",
      "translation": "Ils t'interrogent au sujet du butin. Dis: «Le butin est à Allah et à Son messager.» Craignez Allah, maintenez la concorde entre vous et obéissez à Allah et à Son messager, si vous êtes croyants"
    },
    {
      "key": 1162,
      "id": "8-2",
      "translation": "Les vrais croyants sont ceux dont les cœurs frémissent quand on mentionne Allah. Et quand Ses versets leur sont récités, cela fait augmenter leur foi. Et ils placent leur confiance en leur Seigneur"
    },
    {
      "key": 1163,
      "id": "8-3",
      "translation": "Ceux qui accomplissent la Salât et qui dépensent [dans le sentier d'Allah] de ce que Nous leur avons attribué"
    },
    {
      "key": 1164,
      "id": "8-4",
      "translation": "Ceux-là sont, en toute vérité les croyants: à eux des degrés (élevés) auprès de leur Seigneur, ainsi qu'un pardon et une dotation généreuse"
    },
    {
      "key": 1165,
      "id": "8-5",
      "translation": "De même, c'est au nom de la vérité que ton Seigneur t'a fait sortir de ta demeure, malgré la répulsion d'une partie des croyants"
    },
    {
      "key": 1166,
      "id": "8-6",
      "translation": "Ils discutent avec toi au sujet de la vérité après qu'elle fut clairement apparue; comme si on les poussait vers la mort et qu'ils (la) voyaient"
    },
    {
      "key": 1167,
      "id": "8-7",
      "translation": "(Rappelez-vous), quand Allah vous promettait qu'une des deux bandes sera à vous. Vous désiriez vous emparer de celle qui était sans armes, alors qu'Allah voulait par Ses paroles faire triompher la vérité et anéantir les mécréants jusqu'au dernier"
    },
    {
      "key": 1168,
      "id": "8-8",
      "translation": "afin qu'Il fasse triompher la vérité et anéantir le faux, en dépit de la répulsion qu'en avaient les criminels"
    },
    {
      "key": 1169,
      "id": "8-9",
      "translation": "(Et rappelez-vous) le moment où vous imploriez le secours de votre Seigneur et qu'Il vous exauça aussitôt: «Je vais vous aider d'un millier d'Anges déferlant les uns à la suite des autres.»"
    },
    {
      "key": 1170,
      "id": "8-10",
      "translation": "Allah ne fit cela que pour (vous) apporter une bonne nouvelle et pour qu'avec cela vos cœurs se tranquillisent. Il n'y a de victoire que de la part d'Allah. Allah est Puissant et Sage"
    },
    {
      "key": 1171,
      "id": "8-11",
      "translation": "Et quand Il vous enveloppa de sommeil comme d'une sécurité de Sa part, et du ciel Il fit descendre de l'eau sur vous afin de vous en purifier, d'écarter de vous la souillure du Diable, de renforcer les cœurs et d'en raffermir les pas! [vos pas]"
    },
    {
      "key": 1172,
      "id": "8-12",
      "translation": "Et ton Seigneur révéla aux Anges: «Je suis avec vous: affermissez donc les croyants. Je vais jeter l'effroi dans les cœurs des mécréants. Frappez donc au-dessus des cous et frappez-les sur tous les bouts des doigts"
    },
    {
      "key": 1173,
      "id": "8-13",
      "translation": "Ce, parce qu'ils ont désobéi à Allah et à Son messager.» Et quiconque désobéit à Allah et à Son messager... Allah est certainement dur en punition"
    },
    {
      "key": 1174,
      "id": "8-14",
      "translation": "Voilà (votre sort); goûtez-le donc! Et aux mécréants le châtiment du Feu (sera réservé)"
    },
    {
      "key": 1175,
      "id": "8-15",
      "translation": "O vous qui croyez quand vous rencontrez (l'armée) des mécréants en marche, ne leur tournez point le dos"
    },
    {
      "key": 1176,
      "id": "8-16",
      "translation": "Quiconque, ce jour-là, leur tourne le dos, - à moins que ce soit par tactique de combat, ou pour rallier un autre groupe, - celui-là encourt la colère d'Allah et son refuge sera l'Enfer. Et quelle mauvaise destination"
    },
    {
      "key": 1177,
      "id": "8-17",
      "translation": "Ce n'est pas vous qui les avez tués: mais c'est Allah qui les a tués. Et lorsque tu lançais (une poignée de terre), ce n'est pas toi qui lançais: mais c'est Allah qui lançait, et ce pour éprouver les croyants d'une belle épreuve de Sa part! Allah est Audient et Omniscient"
    },
    {
      "key": 1178,
      "id": "8-18",
      "translation": "Voilà! Allah réduit à rien la ruse des mécréants"
    },
    {
      "key": 1179,
      "id": "8-19",
      "translation": "Si vous avez imploré l'arbitrage d'Allah vous connaissez maintenant la sentence [d'Allah] Et si vous cessez [la mécréance et l'hostilité contre le Prophète..], c'est mieux pour vous. Mais si vous revenez, Nous reviendrons, et votre masse, même nombreuse, ne vous sera d'aucune utilité. Car Allah est vraiment avec les croyants"
    },
    {
      "key": 1180,
      "id": "8-20",
      "translation": "O vous qui croyez! Obéissez à Allah et à Son messager et ne vous détournez pas de lui quand vous l'entendez (parler)"
    },
    {
      "key": 1181,
      "id": "8-21",
      "translation": "Et ne soyez pas comme ceux qui disent: «Nous avons entendu», alors qu'ils n'entendent pas"
    },
    {
      "key": 1182,
      "id": "8-22",
      "translation": "Les pires des bêtes auprès d'Allah, sont, [en vérité], les sourds-muets qui ne raisonnent pas"
    },
    {
      "key": 1183,
      "id": "8-23",
      "translation": "Et si Allah avait reconnu en eux quelque bien, Il aurait fait qu'ils entendent. Mais, même s'Il les faisait entendre, ils tourneraient [sûrement] le dos en s'éloignant"
    },
    {
      "key": 1184,
      "id": "8-24",
      "translation": "O vous qui croyez! Répondez à Allah et au Messager lorsqu'il vous appelle à ce qui vous donne la (vraie) vie, et sachez qu'Allah s'interpose entre l'homme et son cœur, et que c'est vers Lui que vous serez rassemblés"
    },
    {
      "key": 1185,
      "id": "8-25",
      "translation": "Et craignez une calamité qui n'affligera pas exclusivement les injustes d'entre vous. Et sachez qu'Allah est dur en punition"
    },
    {
      "key": 1186,
      "id": "8-26",
      "translation": "Et rappelez-vous quand vous étiez peu nombreux, opprimés sur terre, craignant de vous faire enlever par des gens. Il vous donna asile, vous renforça de Son secours et vous attribua de bonnes choses afin que vous soyez reconnaissants"
    },
    {
      "key": 1187,
      "id": "8-27",
      "translation": "O vous qui croyez! Ne trahissez pas Allah et le Messager. Ne trahissez pas sciemment la confiance qu'on a placée en vous"
    },
    {
      "key": 1188,
      "id": "8-28",
      "translation": "Et sachez que vos biens et vos enfants ne sont qu'une épreuve et qu'auprès d'Allah il y a une énorme récompense"
    },
    {
      "key": 1189,
      "id": "8-29",
      "translation": "O vous qui croyez! Si vous craignez Allah, Il vous accordera la faculté de discerner (entre le bien et le mal), vous effacera vos méfaits et vous pardonnera. Et Allah est le Détenteur de l'énorme grâce"
    },
    {
      "key": 1190,
      "id": "8-30",
      "translation": "(Et rappelle-toi) le moment où les mécréants complotaient contre toi pour t'emprisonner ou t'assassiner ou te bannir. Ils complotèrent. mais Allah a fait échouer leur complot, et Allah est le meilleur en stratagèmes"
    },
    {
      "key": 1191,
      "id": "8-31",
      "translation": "Et lorsque Nos versets leur sont récités, ils disent: «Nous avons écouté, certes! Si nous voulions, nous dirions pareil à cela, ce ne sont que des légendes d'anciens.»"
    },
    {
      "key": 1192,
      "id": "8-32",
      "translation": "Et quand ils dirent: «O Allah, si cela est la vérité de Ta part, alors, fais pleuvoir du ciel des pierres sur nous, ou fais venir sur nous un châtiment douloureux»"
    },
    {
      "key": 1193,
      "id": "8-33",
      "translation": "Allah n'est point tel qu'Il les châtie, alors que tu es au milieu d'eux. Et Allah n'est point tel qu'il les châtie alors qu'Ils demandent pardon"
    },
    {
      "key": 1194,
      "id": "8-34",
      "translation": "Qu'ont-ils donc pour qu'Allah ne les châtie pas, alors qu'ils repoussent (les croyants) de la Mosquée sacrée, quoiqu'ils n'en soient pas les gardiens, car ses gardiens ne sont que les pieux. Mais la plupart d'entre eux ne le savent pas"
    },
    {
      "key": 1195,
      "id": "8-35",
      "translation": "Et leur prière, auprès de la Maison, n'est que sifflement et battements de mains: «Goûtez donc au châtiment, à cause de votre mécréance!»"
    },
    {
      "key": 1196,
      "id": "8-36",
      "translation": "Ceux qui ne croient pas dépensent leurs biens pour éloigner (les gens) du sentier d'Allah. Or, après les avoir dépensés, ils seront pour eux un sujet de regret. Puis ils seront vaincus, et tous ceux qui ne croient pas seront rassemblés vers l'Enfer"
    },
    {
      "key": 1197,
      "id": "8-37",
      "translation": "afin qu'Allah distingue le mauvais du bon, et qu'Il place les mauvais les uns sur les autres, pour en faire un amoncellement qu'Il jettera dans l'Enfer. Ceux-là sont les perdants"
    },
    {
      "key": 1198,
      "id": "8-38",
      "translation": "Dis à ceux qui ne croient pas que, s'ils cessent, on leur pardonnera ce qui s'est passé. Et s'ils récidivent, (ils seront châtiés); à l'exemple de (leurs) devanciers"
    },
    {
      "key": 1199,
      "id": "8-39",
      "translation": "Et combattez-les jusqu'à ce qu'il ne subsiste plus d'association, et que la religion soit entièrement à Allah. Puis, s'ils cessent (ils seront pardonnés car) Allah observe bien ce qu'ils œuvrent"
    },
    {
      "key": 1200,
      "id": "8-40",
      "translation": "Et s'ils tournent le dos, sachez alors qu'Allah est votre Maître. Quel excellent Maître et quel excellent Protecteur"
    },
    {
      "key": 1201,
      "id": "8-41",
      "translation": "Et sachez que, de tout butin que vous avez ramassé, le cinquième appartient à Allah, au messager, à ses proches parents, aux orphelins, aux pauvres, et aux voyageurs (en détresse), si vous croyez en Allah et en ce que Nous avons fait descendre sur Notre serviteur, le jour du Discernement: le jour où les deux groupes s'étaient rencontrés, et Allah est Omnipotent"
    },
    {
      "key": 1202,
      "id": "8-42",
      "translation": "Vous étiez sur le versant le plus proche, et eux (les ennemis) sur le versant le plus éloigné, tandis que la caravane était plus bas que vous. Si vous vous étiez donné rendez-vous, vous l'auriez manqué (effrayés par le nombre de l'ennemi). Mais il fallait qu'Allah accomplît un ordre qui devait être exécuté, pour que, sur preuve, pérît celui qui (devait) périr, et vécût, sur preuve, celui qui (devait) vivre. Et certes Allah est Audient et Omniscient"
    },
    {
      "key": 1203,
      "id": "8-43",
      "translation": "En songe, Allah te les avait montrés peu nombreux! Car s'Il te les avait montrés nombreux, vous auriez certainement fléchi, et vous vous seriez certainement disputés à propos de l'affaire. Mais Allah vous en a préservés. Il connait le contenu des cœurs"
    },
    {
      "key": 1204,
      "id": "8-44",
      "translation": "Et aussi, au moment de la rencontre, Il vous les montrait peu nombreux à Vos yeux, de même qu'Il vous faisant paraître à leurs yeux peu nombreux afin qu'Allah parachève un ordre qui devait être exécuté. C'est à Allah que sont ramenées les choses"
    },
    {
      "key": 1205,
      "id": "8-45",
      "translation": "O vous qui croyez! Lorsque vous rencontrez une troupe (ennemie), soyez fermes, et invoquez beaucoup Allah afin de réussir"
    },
    {
      "key": 1206,
      "id": "8-46",
      "translation": "Et obéissez à Allah et à Son messager; et ne vous disputez pas, sinon vous fléchirez et perdrez votre force. Et soyez endurants, car Allah est avec les endurants"
    },
    {
      "key": 1207,
      "id": "8-47",
      "translation": "Et ne soyez pas comme ceux qui sortirent de leurs demeures pour repousser la vérité et avec ostentation publique, obstruant le chemin d'Allah. Et Allah cerne ce qu'ils font"
    },
    {
      "key": 1208,
      "id": "8-48",
      "translation": "Et quand le Diable leur eut embelli leurs actions et dit: «Nul parmi les humains ne peut vous dominer aujourd'hui, et je suis votre soutien.» Mais, lorsque les deux groupes furent en vue l'un de l'autre, il tourna les deux talons et dit: «Je vous désavoue. Je vois ce que vous ne voyez pas; je crains Allah, et Allah est dur en punition»"
    },
    {
      "key": 1209,
      "id": "8-49",
      "translation": "(Et rappelez-vous), quand les hypocrites et ceux qui ont une maladie au cœur [dont la foi est douteuse] disaient: «Ces gens-là, leur religion les trompe.» Mais quiconque place sa confiance en Allah (sera victorieux)... car Allah est Puissant et Sage"
    },
    {
      "key": 1210,
      "id": "8-50",
      "translation": "Si tu voyais, lorsque les Anges arrachaient les âmes aux mécréants! Ils les frappaient sur leurs visages et leurs derrières, (en disant): «Goûtez au châtiment du Feu"
    },
    {
      "key": 1211,
      "id": "8-51",
      "translation": "Cela (le châtiment), pour ce que vos mains ont accompli.» Et Allah n'est point injuste envers les esclaves"
    },
    {
      "key": 1212,
      "id": "8-52",
      "translation": "Il en fut de même des gens de Pharaon et ceux qui avant eux n'avaient pas cru aux signes (enseignements) d'Allah. Allah les saisit pour leurs péchés. Allah est certes Fort et sévère en punition"
    },
    {
      "key": 1213,
      "id": "8-53",
      "translation": "C'est qu'en effet Allah ne modifie pas un bienfait dont Il a gratifié un peuple avant que celui-ci change ce qui est en lui-même. Et Allah est, Audient et Omniscient"
    },
    {
      "key": 1214,
      "id": "8-54",
      "translation": "Il en fut de même des gens de Pharaon et ceux qui avant eux avaient traité de mensonges les signes (enseignements) de leur Seigneur. Nous les avons fait périr pour leurs péchés. Et Nous avons noyé les gens de Pharaon. Car ils étaient tous des injustes"
    },
    {
      "key": 1215,
      "id": "8-55",
      "translation": "Les pires bêtes, auprès d'Allah, sont ceux qui ont été infidèles (dans le passé) et qui ne croient donc point (actuellement)"
    },
    {
      "key": 1216,
      "id": "8-56",
      "translation": "ceux-là mêmes avec lesquels tu as fait un pacte et qui chaque fois le rompent, sans aucune crainte [d'Allah]"
    },
    {
      "key": 1217,
      "id": "8-57",
      "translation": "Donc, si tu les maîtrises à la guerre, inflige-leur un châtiment exemplaire de telle sorte que ceux qui sont derrière eux soient effarouchés. Afin qu'ils se souviennent"
    },
    {
      "key": 1218,
      "id": "8-58",
      "translation": "Et si jamais tu crains vraiment une trahison de la part d'un peuple, dénonce alors le pacte (que tu as conclu avec), d'une façon franche et loyale car Allah n'aime pas les traîtres"
    },
    {
      "key": 1219,
      "id": "8-59",
      "translation": "Que les mécréants ne pensent pas qu'ils Nous ont échappé. Non, ils ne pourront jamais Nous empêcher (de les rattraper à n'importe quel moment)"
    },
    {
      "key": 1220,
      "id": "8-60",
      "translation": "Et préparez [pour lutter] contre eux tout ce que vous pouvez comme force et comme cavalerie équipée, afin d'effrayer l'ennemi d'Allah et le vôtre, et d'autres encore que vous ne connaissez pas en dehors de ceux-ci mais qu'Allah connaît. Et tout ce que vous dépensez dans le sentier d'Allah vous sera remboursé pleinement et vous ne serez point lésés"
    },
    {
      "key": 1221,
      "id": "8-61",
      "translation": "Et s'ils inclinent à la paix, incline vers celle-ci (toi aussi) et place ta confiance en Allah, car c'est Lui l'Audient, l'Omniscient"
    },
    {
      "key": 1222,
      "id": "8-62",
      "translation": "Et s'ils veulent te tromper, alors Allah te suffira. C'est Lui qui t'a soutenu par Son secours, ainsi que par (l'assistance) des croyants"
    },
    {
      "key": 1223,
      "id": "8-63",
      "translation": "Il a uni leurs cœurs (par la foi). Aurais-tu dépensé tout ce qui est sur terre, tu n'aurais pu unir leurs cœurs; mais c'est Allah qui les a unis, car Il est Puissant et Sage"
    },
    {
      "key": 1224,
      "id": "8-64",
      "translation": "O Prophète, Allah et ceux des croyants qui te suivent te suffisent"
    },
    {
      "key": 1225,
      "id": "8-65",
      "translation": "O Prophète, incite les croyants au combat. S'il se trouve parmi vous vingt endurants, ils vaincront deux cents; et s'il s'en trouve cent, ils vaincront mille mécréants, car ce sont vraiment des gens qui ne comprennent pas"
    },
    {
      "key": 1226,
      "id": "8-66",
      "translation": "Maintenant, Allah a allégé votre tâche, sachant qu'il y a de la faiblesse en vous. S'il y a cent endurants parmi vous, ils vaincront deux cents; et s'il y en a mille, ils vaincront deux mille, par la grâce d'Allah. Et Allah est avec les endurants"
    },
    {
      "key": 1227,
      "id": "8-67",
      "translation": "Un prophète ne devrait pas faire de prisonniers avant d'avoir prévalu [mis les mécréants hors de combat] sur la terre. Vous voulez les biens d'ici-bas, tandis qu'Allah veut l'au-delà. Allah est Puissant et Sage"
    },
    {
      "key": 1228,
      "id": "8-68",
      "translation": "N'eût-été une prescription préalable d'Allah, un énorme châtiment vous aurait touché pour ce que vous avez pris. [de la rançon]"
    },
    {
      "key": 1229,
      "id": "8-69",
      "translation": "Mangez donc de ce qui vous est échu en butin, tant qu'il est licite et pur. Et craignez Allah, car Allah est Pardonneur et Miséricordieux"
    },
    {
      "key": 1230,
      "id": "8-70",
      "translation": "O Prophète, dis aux captifs qui sont entre vos mains: «Si Allah sait qu'il y a quelque bien dans vos cœurs, Il vous donnera mieux que ce qui vous a été pris et vous pardonnera. Allah est Pardonneur et Miséricordieux"
    },
    {
      "key": 1231,
      "id": "8-71",
      "translation": "Et s'ils veulent te trahir..., c'est qu'ils ont déjà trahi Allah [par la mécréance]; mais Il a donné prise sur eux [le jour de Badr]. Et Allah est Omniscient et Sage"
    },
    {
      "key": 1232,
      "id": "8-72",
      "translation": "Ceux qui ont cru, émigré et lutté de leurs biens et de leurs personnes dans le sentier d'Allah, ainsi que ceux qui leur ont donné refuge et secours, ceux-là sont alliés les uns des autres. Quant à ceux qui ont cru et n'ont pas émigré, vous ne serez pas liés à eux, jusqu'à ce qu'ils émigrent. Et s'ils vous demandent secours au nom de la religion, à vous alors de leur porter secours, mais pas contre un peuple auquel vous êtes liés par un pacte. Et Allah observe bien ce que vous œuvrez"
    },
    {
      "key": 1233,
      "id": "8-73",
      "translation": "Et ceux qui n'ont pas cru sont alliés les uns des autres. Si vous n'agissez pas ainsi [en rompant les liens avec les infidèles], il y aura discorde sur terre et grand désordre"
    },
    {
      "key": 1234,
      "id": "8-74",
      "translation": "Et ceux qui ont cru, émigré et lutté dans le sentier d'Allah, ainsi que ceux qui leur ont donné refuge et porté secours, ceux-là sont les vrais croyants: à eux, le pardon et une récompense généreuse"
    },
    {
      "key": 1235,
      "id": "8-75",
      "translation": "Et ceux qui après cela ont cru et émigré et lutté en votre compagnie, ceux-là sont des vôtres. Cependant ceux qui sont liés par la parenté ont priorité les uns envers les autres, d'après le Livre d'Allah. Certes, Allah est Omniscient"
    },
    {
      "key": 1236,
      "id": "9-1",
      "translation": "Désaveu de la part d'Allah et de Son messager à l'égard des associateurs avec qui vous avez conclu un pacte"
    },
    {
      "key": 1237,
      "id": "9-2",
      "translation": "Parcourez la terre durant quatre mois; et sachez que vous ne réduirez pas Allah à l'impuissance et qu'Allah couvre d'ignominie les mécréants.»"
    },
    {
      "key": 1238,
      "id": "9-3",
      "translation": "Et proclamation aux gens, de la part d'Allah et de Son messager, au jour du Grand Pèlerinage, qu'Allah et Son messager, désavouent les associateurs. Si vous vous repentez, ce sera mieux pour vous. Mais si vous vous détournez, sachez que vous ne réduirez pas Allah à l'impuissance. Et annonce un châtiment douloureux à ceux qui ne croient pas"
    },
    {
      "key": 1239,
      "id": "9-4",
      "translation": "A l'exception des associateurs avec lesquels vous avez conclu un pacte, puis ils ne vous ont manqué en rien, et n'ont soutenu personne [à lutter] contre vous: respectez pleinement le pacte conclu avec eux jusqu'au terme convenu. Allah aime les pieux"
    },
    {
      "key": 1240,
      "id": "9-5",
      "translation": "Après que les mois sacrés expirent, tuez les associateurs où que vous les trouviez. Capturez-les, assiégez-les et guettez-les dans toute embuscade. Si ensuite ils se repentent, accomplissent la Salât et acquittent la Zakât, alors laissez-leur la voie libre, car Allah est Pardonneur et Miséricordieux"
    },
    {
      "key": 1241,
      "id": "9-6",
      "translation": "Et si l'un des associateurs te demande asile, accorde-le lui, afin qu'il entende la parole d'Allah, puis fais-le parvenir à son lieu de sécurité. Car ce sont des gens qui ne savent pas"
    },
    {
      "key": 1242,
      "id": "9-7",
      "translation": "Comment y aurait-il pour les associateurs un pacte admis par Allah et par Son messager? A l'exception de ceux avec lesquels vous avez conclu un pacte près de la Mosquée sacrée. Tant qu'ils sont droits envers vous, soyez droits envers eux. Car Allah aime les pieux"
    },
    {
      "key": 1243,
      "id": "9-8",
      "translation": "Comment donc! Quand ils triomphent de vous, ils ne respectent à votre égard, ni parenté ni pacte conclu. Ils vous satisfont de leurs bouches, tandis que leurs cœurs se refusent; et la plupart d'entre eux sont des pervers"
    },
    {
      "key": 1244,
      "id": "9-9",
      "translation": "Ils troquent à vil prix les versets d'Allah (le Coran) et obstruent Son chemin. Ce qu'ils font est très mauvais"
    },
    {
      "key": 1245,
      "id": "9-10",
      "translation": "Ils ne respectent, à l'égard d'un croyant, ni parenté ni pacte conclu. Et ceux-là sont les transgresseurs"
    },
    {
      "key": 1246,
      "id": "9-11",
      "translation": "Mais s'ils se repentent, accomplissent la Salât et acquittent la Zakat, ils deviendront vos frères en religion. Nous exposons intelligiblement les versets pour des gens qui savent"
    },
    {
      "key": 1247,
      "id": "9-12",
      "translation": "Et si, après le pacte, ils violent leurs serments et attaquent votre religion, combattez alors les chefs de la mécréance - car, ils ne tiennent aucun serment - peut-être cesseront-ils"
    },
    {
      "key": 1248,
      "id": "9-13",
      "translation": "Ne combattrez-vous pas des gens qui ont violé leurs serments, qui ont voulu bannir le Messager et alors que ce sont eux qui vous ont attaqués les premiers? Les redoutiez-vous? C'est Allah qui est plus digne de votre crainte si vous êtes croyants"
    },
    {
      "key": 1249,
      "id": "9-14",
      "translation": "Combattez-les. Allah, par vos mains, les châtiera, les couvrira d'ignominie, vous donnera la victoire sur eux et guérira les poitrines d'un peuple croyant"
    },
    {
      "key": 1250,
      "id": "9-15",
      "translation": "Et il fera partir la colère de leurs cœurs. Allah accueille le repentir de qui Il veut. Allah est Omniscient et Sage"
    },
    {
      "key": 1251,
      "id": "9-16",
      "translation": "Pensez-vous que vous serez délaissés, cependant qu'Allah n'a pas encore distingué ceux d'entre vous qui ont lutté et qui n'ont pas cherché des alliés en dehors d'Allah, de Son messager et des croyants? Et Allah est Parfaitement Connaisseur de ce que vous faites"
    },
    {
      "key": 1252,
      "id": "9-17",
      "translation": "Il n'appartient pas aux associateurs de peupler les mosquées d'Allah, vu qu'ils témoignent contre eux-mêmes de leur mécréance. Voilà ceux dont les œuvres sont vaines; et dans le Feu ils demeureront éternellement"
    },
    {
      "key": 1253,
      "id": "9-18",
      "translation": "Ne peupleront les mosquées d'Allah que ceux qui croient en Allah et au Jour dernier, accomplissent la Salât, acquittent la Zakât et ne craignent qu'Allah. Il se peut que ceux-là soient du nombre des bien-guidés"
    },
    {
      "key": 1254,
      "id": "9-19",
      "translation": "Ferez-vous de la charge de donner à boire aux pèlerins et d'entretenir la Mosquée sacrée (des devoirs) comparables [au mérite] de celui qui croit en Allah et au Jour dernier et lutte dans le sentier d'Allah? Ils ne sont pas égaux auprès d'Allah et Allah ne guide pas les gens injustes"
    },
    {
      "key": 1255,
      "id": "9-20",
      "translation": "Ceux qui ont cru, qui ont émigré et qui ont lutté par leurs biens et leurs personnes dans le sentier d'Allah, ont les plus hauts rangs auprès d'Allah... et ce sont eux les victorieux"
    },
    {
      "key": 1256,
      "id": "9-21",
      "translation": "Leur Seigneur leur annonce de Sa part, miséricorde et agrément, et des Jardins où il y aura pour eux un délice permanent"
    },
    {
      "key": 1257,
      "id": "9-22",
      "translation": "où ils demeureront éternellement. Certes il y a auprès d'Allah une énorme récompense"
    },
    {
      "key": 1258,
      "id": "9-23",
      "translation": "O vous qui croyez! Ne prenez pas pour alliés, vos pères et vos frères s'ils préfèrent la mécréance à la foi. Et quiconque parmi vous les prend pour alliés... ceux-là sont les injustes"
    },
    {
      "key": 1259,
      "id": "9-24",
      "translation": "Dis: «Si vos pères, vos enfants, vos frères, vos épouses, vos clans, les biens que vous gagnez, le négoce dont vous craignez le déclin et les demeures qui vous sont agréables, vous sont plus chers qu'Allah, Son messager et la lutte dans le sentier d'Allah, alors attendez qu'Allah fasse venir Son ordre. Et Allah ne guide pas les gens pervers»"
    },
    {
      "key": 1260,
      "id": "9-25",
      "translation": "Allah vous a déjà secourus en maints endroits. Et [rappelez-vous] le jour de Hunayn, quand vous étiez fiers de votre grand nombre et que cela ne vous a servi à rien. La terre, malgré son étendue vous devint bien étroite; puis vous avez tourné le dos en fuyards"
    },
    {
      "key": 1261,
      "id": "9-26",
      "translation": "Puis, Allah fit descendre Sa quiétude [Sa «sakîna»] sur Son messager et sur les croyants. Il fit descendre des troupes (Anges) que vous ne voyiez pas, et châtia ceux qui ont mécru. Telle est la rétribution des mécréants"
    },
    {
      "key": 1262,
      "id": "9-27",
      "translation": "Après cela Allah, accueillera le repentir de qui Il veut, car Allah est Pardonneur et Miséricordieux"
    },
    {
      "key": 1263,
      "id": "9-28",
      "translation": "O vous qui croyez! Les associateurs ne sont qu'impureté: qu'ils ne s'approchent plus de la Mosquée sacrée, après cette année-ci. Et si vous redoutez une pénurie, Allah vous enrichira, s'Il veut, de par Sa grâce. Car Allah est Omniscient et Sage"
    },
    {
      "key": 1264,
      "id": "9-29",
      "translation": "Combattez ceux qui ne croient ni en Allah ni au Jour dernier, qui n'interdisent pas ce qu'Allah et Son messager ont interdit et qui ne professent pas la religion de la vérité, parmi ceux qui ont reçu le Livre, jusqu'à ce qu'ils versent la capitation par leurs propres mains, après s'être humiliés"
    },
    {
      "key": 1265,
      "id": "9-30",
      "translation": "Les Juifs disent: «'Uzayr est fils d'Allah» et les Chrétiens disent: «Le Christ est fils d'Allah». Telle est leur parole provenant de leurs bouches. Ils imitent le dire des mécréants avant eux. Qu'Allah les anéantisse! Comment s'écartent-ils (de la vérité)"
    },
    {
      "key": 1266,
      "id": "9-31",
      "translation": "Ils ont pris leurs rabbins et leurs moines, ainsi que le Christ fils de Marie, comme Seigneurs en dehors d'Allah, alors qu'on ne leur a commandé que d'adorer un Dieu unique. Pas de divinité à part Lui! Gloire à Lui! Il est au-dessus de ce qu'ils [Lui] associent"
    },
    {
      "key": 1267,
      "id": "9-32",
      "translation": "Ils veulent éteindre avec leurs bouches la lumière d'Allah, alors qu'Allah ne veut que parachever Sa lumière, quelque répulsion qu'en aient les mécréants"
    },
    {
      "key": 1268,
      "id": "9-33",
      "translation": "C'est Lui qui a envoyé Son messager avec la bonne direction et la religion de la vérité, afin qu'elle triomphe sur toute autre religion, quelque répulsion qu'en aient les associateurs"
    },
    {
      "key": 1269,
      "id": "9-34",
      "translation": "O vous qui croyez! Beaucoup de rabbins et de moines dévorent, les biens des gens illégalement et [leur] obstruent le sentier d'Allah. A ceux qui thésaurisent l'or et l'argent et ne les dépensent pas dans le sentier d'Allah, annonce un châtiment douloureux"
    },
    {
      "key": 1270,
      "id": "9-35",
      "translation": "le jour où (ces trésors) seront portés à l'incandescence dans le feu de l'Enfer et qu'ils en seront cautérisés, front, flancs et dos: voici ce que vous avez thésaurisé pour vous-mêmes. Goûtez de ce que vous thésaurisiez.»"
    },
    {
      "key": 1271,
      "id": "9-36",
      "translation": "Le nombre de mois, auprès d'Allah, est de douze [mois], dans la prescription d'Allah, le jour où Il créa les cieux et la terre. Quatre d'entre eux sont sacrés: telle est la religion droite. [Durant ces mois], ne faites pas de tort à vous-mêmes. Combattez les associateurs sans exception, comme ils vous combattent sans exception. Et sachez qu'Allah est avec les pieux"
    },
    {
      "key": 1272,
      "id": "9-37",
      "translation": "Le report d'un mois sacré à un autre est un surcroît de mécréance. Par là, les mécréants sont égarés: une année, ils le font profane, et une année, ils le font sacré, afin d'ajuster le nombre de mois qu'Allah a fait sacrés. Ainsi rendent-ils profane ce qu'Allah a fait sacré. Leurs méfaits leurs sont enjolivés. Et Allah ne guide pas les gens mécréants"
    },
    {
      "key": 1273,
      "id": "9-38",
      "translation": "O vous qui croyez! Qu'avez-vous? Lorsque l'on vous a dit: «Elancez-vous dans le sentier d'Allah» vous vous êtes appesantis sur la terre. La vie présente vous agrée-t-elle plus que l'au-delà? - Or, la jouissance de la vie présente ne sera que peu de chose, comparée à l'au-delà"
    },
    {
      "key": 1274,
      "id": "9-39",
      "translation": "Si vous ne vous lancez pas au combat, Il vous châtiera d'un châtiment douloureux et vous remplacera par un autre peuple. Vous ne Lui nuirez en rien. Et Allah est Omnipotent"
    },
    {
      "key": 1275,
      "id": "9-40",
      "translation": "Si vous ne lui portez pas secours... Allah l'a déjà secouru, lorsque ceux qui avaient mécru l'avaient banni, deuxième de deux. Quand ils étaient dans la grotte et qu'il disait à son compagnon: «Ne t'afflige pas, car Allah est avec nous.» Allah fit alors descendre sur Lui Sa sérénité «Sa sakîna» et le soutint de soldats (Anges) que vous ne voyiez pas, et Il abaissa ainsi la parole des mécréants, tandis que la parole d'Allah eut le dessus. Et Allah est Puissant et Sage"
    },
    {
      "key": 1276,
      "id": "9-41",
      "translation": "Légers ou lourds, lancez-vous au combat, et luttez avec vos biens et vos personnes dans le sentier d'Allah. Cela est meilleur pour vous, si vous saviez"
    },
    {
      "key": 1277,
      "id": "9-42",
      "translation": "S'il s'était agi d'un profit facile ou d'un court voyage, ils t'auraient suivi; mais la distance leur parut longue. Et ils jureront par Allah: «Si nous avions pu, nous serions sortis en votre compagnie.» Ils se perdent eux-mêmes. Et Allah sait bien qu'ils mentent"
    },
    {
      "key": 1278,
      "id": "9-43",
      "translation": "Qu'Allah te pardonne! Pourquoi leur as-tu donné permission avant que tu ne puisses distinguer ceux qui disaient vrai et reconnaître les menteurs"
    },
    {
      "key": 1279,
      "id": "9-44",
      "translation": "Ceux qui croient en Allah et au Jour dernier ne te demandent pas permission quand il s'agit de mener combat avec leurs biens et leurs personnes. Et Allah connaît bien les pieux"
    },
    {
      "key": 1280,
      "id": "9-45",
      "translation": "Ne te demandent permission que ceux qui ne croient pas en Allah et au Jour dernier, et dont les cœurs sont emplis de doute. Ils ne font qu'hésiter dans leur incertitude"
    },
    {
      "key": 1281,
      "id": "9-46",
      "translation": "Et s'ils avaient voulu partir (au combat), ils lui auraient fait des préparatifs. Mais leur départ répugna à Allah; Il les a rendus paresseux. Et il leur fut dit: «Restez avec ceux qui restent»"
    },
    {
      "key": 1282,
      "id": "9-47",
      "translation": "S'ils étaient sortis avec vous, ils n'auraient fait qu'accroître votre trouble et jeter la dissension dans vos rangs, cherchant à créer la discorde entre vous. Et il y en a parmi vous qui les écoutent. Et Allah connaît bien les injustes"
    },
    {
      "key": 1283,
      "id": "9-48",
      "translation": "Ils ont, auparavant, cherché à semer la discorde (dans vos rangs) et à embrouiller tes affaires jusqu'à ce que vînt la vérité et triomphât le commandement d'Allah, en dépit de leur hostilité"
    },
    {
      "key": 1284,
      "id": "9-49",
      "translation": "Parmi eux il en est qui dit: «Donne-moi la permission (de rester) et ne me mets pas en tentation.» Or, c'est bien dans la tentation qu'ils sont tombés; l'Enfer est tout autour des mécréants"
    },
    {
      "key": 1285,
      "id": "9-50",
      "translation": "Qu'un bonheur t'atteigne, ça les afflige. Et que t'atteigne un malheur, ils disent: «Heureusement que nous avions pris d'avance nos précautions.» Et ils se détournent tout en exultant"
    },
    {
      "key": 1286,
      "id": "9-51",
      "translation": "Dis: «Rien ne nous atteindra, en dehors de ce qu'Allah a prescrit pour nous. Il est notre Protecteur. C'est en Allah que les croyants doivent mettre leur confiance»"
    },
    {
      "key": 1287,
      "id": "9-52",
      "translation": "Dis: «Qu'attendez-vous pour nous, sinon l'une des deux meilleures choses? Tandis que ce que nous attendons pour vous, c'est qu'Allah vous inflige un châtiment de Sa part ou par nos mains. Attendez donc! Nous attendons aussi, avec vous»"
    },
    {
      "key": 1288,
      "id": "9-53",
      "translation": "Dis: «Dépensez bon gré, mal gré: jamais cela ne sera accepté de vous, car vous êtes des gens pervers»"
    },
    {
      "key": 1289,
      "id": "9-54",
      "translation": "Ce qui empêche leurs dons d'être agréés, c'est le fait qu'ils n'ont pas cru en Allah et Son messager, qu'ils ne se rendent à la Salât que paresseusement, et qu'ils ne dépensent (dans les bonnes œuvres) qu'à contrecœur"
    },
    {
      "key": 1290,
      "id": "9-55",
      "translation": "Que leurs biens et leurs enfants ne t'émerveillent point! Allah ne veut par là que les châtier dans la vie présente, et que (les voir) rendre péniblement l'âme en état de mécréance"
    },
    {
      "key": 1291,
      "id": "9-56",
      "translation": "Et ils (les hypocrites) jurent par Allah qu'ils sont vraiment des vôtres; alors qu'ils ne le sont pas. Mais ce sont des gens peureux"
    },
    {
      "key": 1292,
      "id": "9-57",
      "translation": "S'ils trouvaient un refuge, des cavernes ou un souterrain, ils s'y tourneraient donc et s'y précipiteraient à bride abattue"
    },
    {
      "key": 1293,
      "id": "9-58",
      "translation": "Il en est parmi eux qui te critiquent au sujet des Sadaqats: s'il leur en est donné, les voilà contents; mais s'il ne leur en est pas donné, les voilà pleins de rancœur"
    },
    {
      "key": 1294,
      "id": "9-59",
      "translation": "S'ils s'étaient contentés de ce qu'Allah leur avait donné ainsi que Son messager et avaient dit: «Allah nous suffit. Bientôt Allah nous accordera Sa faveur de même que Son messager!... C'est vers Allah que va tout notre désir»"
    },
    {
      "key": 1295,
      "id": "9-60",
      "translation": "Les Sadaqâts ne sont destinés que pour les pauvres, les indigents, ceux qui y travaillent, ceux dont les cœurs sont à gagner (à l'Islam), l'affranchissement des jougs, ceux qui sont lourdement endettés, dans le sentier d'Allah, et pour le voyageur (en détresse). C'est un décret d'Allah! Et Allah est Omniscient et Sage"
    },
    {
      "key": 1296,
      "id": "9-61",
      "translation": "Et il en est parmi eux ceux qui font du tort au Prophète et disent: «Il est tout oreille». - Dis: «Une oreille pour votre bien. Il croit en Allah et fait confiance aux croyants, et il est une miséricorde pour ceux d'entre vous qui croient. Et ceux qui font du tort au Messager d'Allah auront un châtiment douloureux»"
    },
    {
      "key": 1297,
      "id": "9-62",
      "translation": "Ils vous jurent par Allah pour vous satisfaire. Alors qu'Allah - ainsi que Son messager - est plus en droit qu'ils Le satisfassent, s'ils sont vraiment croyants"
    },
    {
      "key": 1298,
      "id": "9-63",
      "translation": "Ne savent-ils pas qu'en vérité quiconque s'oppose à Allah et à Son messager, aura le feu de l'Enfer pour y demeurer éternellement? Et voilà l'immense opprobre"
    },
    {
      "key": 1299,
      "id": "9-64",
      "translation": "Les hypocrites craignent que l'on fasse descendre sur eux une Sourate leur dévoilant ce qu'il y a dans leurs cœurs. Dis: «Moquez-vous! Allah fera surgir ce que vous prenez la précaution (de cacher)»"
    },
    {
      "key": 1300,
      "id": "9-65",
      "translation": "Et si tu les interrogeais, ils diraient très certainement: «Vraiment, nous ne faisions que bavarder et jouer.» Dis: «Est-ce d'Allah, de Ses versets (le Coran) et de Son messager que vous vous moquiez?»"
    },
    {
      "key": 1301,
      "id": "9-66",
      "translation": "Ne vous excusez pas: vous avez bel et bien rejeté la foi après avoir cru. Si Nous pardonnons à une partie des vôtres, Nous en châtierons une autre pour avoir été des criminels"
    },
    {
      "key": 1302,
      "id": "9-67",
      "translation": "Les hypocrites, hommes et femmes, appartiennent les uns aux autres. Ils commandent le blâmable, interdisent le convenable, et replient leurs mains (d'avarice). Ils ont oublié Allah et Il les a alors oubliés. En vérité, les hypocrites sont les pervers"
    },
    {
      "key": 1303,
      "id": "9-68",
      "translation": "Aux hypocrites, hommes et femmes, et aux mécréants, Allah a promis le feu de l'Enfer pour qu'ils y demeurent éternellement. C'est suffisant pour eux. Allah les a maudits. Et pour eux, il y aura un châtiment permanent"
    },
    {
      "key": 1304,
      "id": "9-69",
      "translation": "[Il en fut] de même de ceux qui vous ont précédés: ils étaient plus forts que vous, plus riches et avaient plus d'enfants. Ils jouirent de leur lot [en ce monde] et vous avez joui de votre lot comme ont joui vos prédécesseurs de leur lot. Et vous avez discuté à tort et à travers comme ce qu'ils avaient discuté. Ceux-là verront leurs œuvres anéanties dans ce monde et dans l'autre et ceux-là sont les perdants"
    },
    {
      "key": 1305,
      "id": "9-70",
      "translation": "Est-ce que ne leur est pas parvenue l'histoire de ceux qui les ont précédés: le peuple de Noé, des 'Aad, des Tamûd, d'Abraham, des gens de Madyan, et des Villes renversées? Leurs messagers leur avaient apporté des preuves évidentes. Ce ne fut pas Allah qui leur fit du tort, mais ils se firent du tort à eux-mêmes"
    },
    {
      "key": 1306,
      "id": "9-71",
      "translation": "Les croyants et les croyantes sont alliés les uns des autres. Ils commandent le convenable, interdisent le blâmable accomplissent la ?alât, acquittent la Zakât et obéissent à Allah et à Son messager. Voilà ceux auxquels Allah fera miséricorde, car Allah est Puissant et Sage"
    },
    {
      "key": 1307,
      "id": "9-72",
      "translation": "Aux croyants et aux croyantes, Allah a promis des Jardins sous lesquels coulent les ruisseaux, pour qu'ils y demeurent éternellement, et des demeures excellentes, aux jardins d'Eden [du séjour permanent]. Et la satisfaction d'Allah est plus grande encore, et c'est là l'énorme succès"
    },
    {
      "key": 1308,
      "id": "9-73",
      "translation": "O Prophète, lutte contre les mécréants et les hypocrites, et sois rude avec eux; l'Enfer sera leur refuge, et quelle mauvaise destination"
    },
    {
      "key": 1309,
      "id": "9-74",
      "translation": "Ils jurent par Allah qu'ils n'ont pas dit (ce qu'ils ont proféré), alors qu'en vérité ils ont dit la parole de la mécréance et ils ont rejeté la foi après avoir été musulmans. Ils ont projeté ce qu'ils n'ont pu accomplir. Mais ils n'ont pas de reproche à faire si ce n'est qu'Allah - ainsi que Son messager - les a enrichis par Sa grâce. S'ils se repentaient, ce serait mieux pour eux. Et s'ils tournent le dos, Allah les châtiera d'un douloureux châtiment, ici-bas et dans l'au-delà; et ils n'auront sur terre ni allié ni secoureur"
    },
    {
      "key": 1310,
      "id": "9-75",
      "translation": "Et parmi eux il en est qui avaient pris l'engagement envers Allah: «S'Il nous donne de Sa grâce, nous payerons, certes, la Zakât, et serons du nombre des gens de bien»"
    },
    {
      "key": 1311,
      "id": "9-76",
      "translation": "Mais, lorsqu'Il leur donna de Sa grâce, ils s'en montrèrent avares et tournèrent le dos en faisant volte-face"
    },
    {
      "key": 1312,
      "id": "9-77",
      "translation": "Il a donc suscité l'hypocrisie dans leurs cœurs, et cela jusqu'au jour où ils Le rencontreront, pour avoir violé ce qu'ils avaient promis à Allah et pour avoir menti"
    },
    {
      "key": 1313,
      "id": "9-78",
      "translation": "Ne savent-ils pas qu'Allah connaît leur secret et leurs conversations confidentielles et qu'Allah connaît parfaitement les (choses) inconnaissables"
    },
    {
      "key": 1314,
      "id": "9-79",
      "translation": "Ceux-là qui dirigent leurs calomnies contre les croyants qui font des aumônes volontaires et contre ceux qui ne trouvent que leurs faibles moyens (à offrir), et ils se moquent alors d'eux. Qu'Allah les raille. Et ils auront un châtiment douloureux"
    },
    {
      "key": 1315,
      "id": "9-80",
      "translation": "Que tu demandes pardon pour eux, ou que tu ne le demandes pas - et si tu demandes pardon pour eux soixante dix fois - Allah ne leur pardonnera point. Et ce parce qu'ils n'ont pas cru en Allah et en Son messager et Allah ne guide pas les gens pervers"
    },
    {
      "key": 1316,
      "id": "9-81",
      "translation": "Ceux qui ont été laissés à l'arrière se sont réjouis de pouvoir rester chez eux à l'arrière du Messager d'Allah, ils ont répugné à lutter par leurs biens et leurs personnes dans le sentier d'Allah, et ont dit: «Ne partez pas au combat pendant cette chaleur!» Dis: «Le feu de l'Enfer est plus intense en chaleur.» - S'ils comprenaient"
    },
    {
      "key": 1317,
      "id": "9-82",
      "translation": "Qu'ils rient un peu et qu'ils pleurent beaucoup en récompense de ce qu'ils se sont acquis"
    },
    {
      "key": 1318,
      "id": "9-83",
      "translation": "Si Allah te ramène vers un groupe de ces (gens-là), et qu'ils te demandent permission de partir au combat, alors dis: «Vous ne sortirez plus jamais en ma compagnie, et vous ne combattrez plus jamais d'ennemis avec moi. Vous avez été plus contents de rester chez vous la première fois; demeurez donc chez vous en compagnie de ceux qui se tiennent à l'arrière»"
    },
    {
      "key": 1319,
      "id": "9-84",
      "translation": "Et ne fais jamais la Salât sur l'un d'entre eux qui meurt, et ne te tiens pas debout auprès de sa tombe, parce qu'ils n'ont pas cru en Allah et en Son messager, et ils sont morts tout en étant pervers"
    },
    {
      "key": 1320,
      "id": "9-85",
      "translation": "Et que ni leurs biens ni leurs enfants ne t'émerveillent! Allah ne veut par là, que les châtier ici-bas, et qu'ils rendent péniblement l'âme en mécréants"
    },
    {
      "key": 1321,
      "id": "9-86",
      "translation": "Et lorsqu'une Sourate est révélée: «Croyez en Allah et luttez en compagnie de Son messager», les gens qui ont tous les moyens (de combattre) parmi eux te demandent de les dispenser (du combat), et disent: «Laisse-nous avec ceux qui restent»"
    },
    {
      "key": 1322,
      "id": "9-87",
      "translation": "Il leur plaît, (après le départ des combattants) de demeurer avec celles qui sont restées à l'arrière. Leurs cœurs ont été scellés et ils ne comprennent rien"
    },
    {
      "key": 1323,
      "id": "9-88",
      "translation": "Mais le Messager et ceux qui ont cru avec lui ont lutté avec leurs biens et leurs personnes. Ceux-là auront les bonnes choses et ce sont eux qui réussiront"
    },
    {
      "key": 1324,
      "id": "9-89",
      "translation": "Allah a préparé pour eux des Jardins sous lesquels coulent les ruisseaux, pour qu'ils y demeurent éternellement. Voilà l'énorme succès"
    },
    {
      "key": 1325,
      "id": "9-90",
      "translation": "Et parmi les Bédouins, certains sont venus demander d'être dispensés (du combat). Et ceux qui ont menti à Allah et à Son messager sont restés chez eux. Un châtiment douloureux affligera les mécréants d'entre eux"
    },
    {
      "key": 1326,
      "id": "9-91",
      "translation": "Nul grief sur les faibles, ni sur les malades, ni sur ceux qui ne trouvent pas de quoi dépenser (pour la cause d'Allah), s'ils sont sincères envers Allah et Son messager. Pas de reproche contre les bienfaiteurs. Allah est Pardonneur et Miséricordieux"
    },
    {
      "key": 1327,
      "id": "9-92",
      "translation": "(Pas de reproche) non plus à ceux qui vinrent te trouver pour que tu leur fournisses une monture et à qui tu dis: «Je ne trouve pas de monture pour vous.» Ils retournèrent les yeux débordant de larmes, tristes de ne pas trouver de quoi dépenser"
    },
    {
      "key": 1328,
      "id": "9-93",
      "translation": "Il n'y a de voie (de reproche à), vraiment, que contre ceux qui demandent d'être dispensés, alors qu'ils sont riches. Il leur plaît de demeurer avec celles qui sont restées à l'arrière. Et Allah a scellé leurs cœurs et ils ne savent pas"
    },
    {
      "key": 1329,
      "id": "9-94",
      "translation": "Ils vous présentent des excuses quand vous revenez à eux. Dis: «Ne présentez pas d'excuses: nous ne vous croyons pas. Allah nous a déjà informés de vos nouvelles. Et Allah verra votre œuvre, ainsi que Son messager. Puis vous serez ramenés vers Celui qui connaît bien l'invisible et le visible, et alors, Il vous informera de ce que vous faisiez"
    },
    {
      "key": 1330,
      "id": "9-95",
      "translation": "Ils vous feront des serments par Allah, quand vous êtes de retour vers eux, afin que vous passiez (sur leur tort). Détournez-vous d'eux. Ils sont une souillure et leur refuge est l'Enfer, en rétribution de ce qu'ils acquéraient"
    },
    {
      "key": 1331,
      "id": "9-96",
      "translation": "Ils vous font des serments pour se faire agréer de vous, même si vous les agréez, Allah n'agrée pas les gens pervers"
    },
    {
      "key": 1332,
      "id": "9-97",
      "translation": "Les Bédouins sont plus endurcis dans leur impiété et dans leur hypocrisie, et les plus enclins à méconnaître les préceptes qu'Allah a révélés à Son messager. Et Allah est Omniscient et Sage"
    },
    {
      "key": 1333,
      "id": "9-98",
      "translation": "Parmi les Bédouins, certains prennent leur dépense (en aumône ou à la guerre) comme une charge onéreuse, et attendent pour vous un revers de fortune. Que le malheur retombe sur eux! Allah est Audient et Omniscient"
    },
    {
      "key": 1334,
      "id": "9-99",
      "translation": "(Tel autre,) parmi les Bédouins, croit en Allah et au Jour dernier et prend ce qu'il dépense comme moyen de se rapprocher d'Allah et afin de bénéficier des invocations du Messager. C'est vraiment pour eux (un moyen) de se rapprocher (d'Allah) et Allah les admettra en Sa miséricorde. Car Allah est Pardonneur et Miséricordieux"
    },
    {
      "key": 1335,
      "id": "9-100",
      "translation": "Les tout premiers [croyants] parmi les Emigrés et les Auxiliaires et ceux qui les ont suivis dans un beau comportement, Allah les agrée, et ils L'agréent. Il a préparé pour eux des Jardins sous lesquels coulent les ruisseaux, et ils y demeureront éternellement. Voilà l'énorme succès"
    },
    {
      "key": 1336,
      "id": "9-101",
      "translation": "Et parmi les Bédouins qui vous entourent, il y a des hypocrites, tout comme une partie des habitants de Médine. Ils s'obstinent dans l'hypocrisie. Tu ne les connais pas mais Nous les connaissons. Nous les châtierons deux fois puis ils seront ramenés vers un énorme châtiment"
    },
    {
      "key": 1337,
      "id": "9-102",
      "translation": "D'autres ont reconnu leurs péchés, ils ont mêlé de bonnes actions à d'autres mauvaises. Il se peut qu'Allah accueille leur repentir. Car Allah est Pardonneur et Miséricordieux"
    },
    {
      "key": 1338,
      "id": "9-103",
      "translation": "Prélève de leurs biens une Sadaqa par laquelle tu les purifies et les bénis, et prie pour eux. Ta prière est une quiétude pour eux. Et Allah est Audient et Omniscient"
    },
    {
      "key": 1339,
      "id": "9-104",
      "translation": "Ne savent-ils pas que c'est Allah qui accueille le repentir de Ses serviteurs, et qui reçoit les Sadaqât, et qu'Allah est L'Accueillant au repentir et le Miséricordieux"
    },
    {
      "key": 1340,
      "id": "9-105",
      "translation": "Et dis: «Oeuvrez, car Allah va voir votre œuvre, de même que Son messager et les croyants, et vous serez ramenés vers Celui qui connaît bien l'invisible et le visible. Alors Il vous informera de ce que vous faisiez»"
    },
    {
      "key": 1341,
      "id": "9-106",
      "translation": "Et d'autres sont laissés dans l'attente de la décision d'Allah, soit qu'Il les punisse, soit qu'Il leur pardonne. Et Allah est Omniscient et Sage"
    },
    {
      "key": 1342,
      "id": "9-107",
      "translation": "Ceux qui ont édifié une mosquée pour en faire [un mobile] de rivalité, d'impiété et de division entre les croyants, qui la préparent pour celui qui auparavant avait combattu Allah et son Envoyé et jurent en disant: «Nous ne voulions que le bien!» [Ceux-là], Allah atteste qu'ils mentent"
    },
    {
      "key": 1343,
      "id": "9-108",
      "translation": "Ne te tiens jamais dans (cette mosquée). Car une Mosquée fondée dès le premier jour, sur la piété, est plus digne que tu t'y tiennes debout [pour y prier]. On y trouve des gens qui aiment bien se purifier, et Allah aime ceux qui se purifient"
    },
    {
      "key": 1344,
      "id": "9-109",
      "translation": "Lequel est plus méritant? Est-ce celui qui a fondé son édifice sur la piété et l'agrément d'Allah, ou bien celui qui a placé les assises de sa construction sur le bord d'une falaise croulante et qui croula avec lui dans le feu de l'Enfer? Et Allah ne guide pas les gens injustes"
    },
    {
      "key": 1345,
      "id": "9-110",
      "translation": "La construction qu'ils ont édifiée sera toujours une source de doute dans leurs cœurs, jusqu'à ce que leurs cœurs se déchirent. Et Allah est Omniscient et Sage"
    },
    {
      "key": 1346,
      "id": "9-111",
      "translation": "Certes, Allah a acheté des croyants, leurs personnes et leurs biens en échange du Paradis. Ils combattent dans le sentier d'Allah: ils tuent, et ils se font tuer. C'est une promesse authentique qu'Il a prise sur Lui-même dans la Thora, l'Evangile et le Coran. Et qui est plus fidèle qu'Allah à son engagement? Réjouissez-vous donc de l'échange que vous avez fait: Et c'est là le très grand succès"
    },
    {
      "key": 1347,
      "id": "9-112",
      "translation": "Ils sont ceux qui se repentent, qui adorent, qui louent, qui parcourent la terre (ou qui jeûnent), qui s'inclinent, qui se prosternent, qui commandent le convenable et interdisent le blâmable et qui observent les lois d'Allah... et fais bonne annonce aux croyants"
    },
    {
      "key": 1348,
      "id": "9-113",
      "translation": "Il n'appartient pas au Prophète et aux croyants d'implorer le pardon en faveur des associateurs, fussent-ils des parents alors qu'il leur est apparu clairement que ce sont les gens de l'Enfer"
    },
    {
      "key": 1349,
      "id": "9-114",
      "translation": "Abraham ne demanda pardon en faveur de son père qu'à cause d'une promesse qu'il lui avait faite. Mais, dès qu'il lui apparut clairement qu'il était un ennemi d'Allah, il le désavoua. Abraham était certes plein de sollicitude et indulgent"
    },
    {
      "key": 1350,
      "id": "9-115",
      "translation": "Allah n'est point tel à égarer un peuple après qu'Il les a guidés, jusqu'à ce qu'Il leur ait montré clairement ce qu'ils doivent éviter. Certes, Allah est Omniscient"
    },
    {
      "key": 1351,
      "id": "9-116",
      "translation": "A Allah appartient la royauté des cieux et de la terre. Il donne la vie et Il donne la mort. Et il n'y a pour vous, en dehors d'Allah, ni allié ni protecteur"
    },
    {
      "key": 1352,
      "id": "9-117",
      "translation": "Allah a accueilli le repentir du Prophète, celui des Emigrés et des Auxiliaires qui l'ont suivi à un moment difficile, après que les cœurs d'un groupe d'entre eux étaient sur le point de dévier. Puis Il accueillit leur repentir car Il est Compatissant et Miséricordieux à leur égard"
    },
    {
      "key": 1353,
      "id": "9-118",
      "translation": "Et [Il accueillit le repentir] des trois qui étaient restés à l'arrière si bien que, toute vaste qu'elle fût, la terre leur paraissait exiguë; ils se sentaient à l'étroit, dans leur propre personne et ils pensaient qu'il n'y avait d'autre refuge d'Allah qu'auprès de Lui. Puis Il agréa leur repentir pour qu'ils reviennent [à Lui], car Allah est l'accueillant au repentir, le Miséricordieux"
    },
    {
      "key": 1354,
      "id": "9-119",
      "translation": "O vous qui croyez! Craignez Allah et soyez avec les véridiques"
    },
    {
      "key": 1355,
      "id": "9-120",
      "translation": "Il n'appartient pas aux habitants de Médine, ni aux Bédouins qui sont autour d'eux, de traîner loin derrière le Messager d'Allah, ni de préférer leur propre vie à la sienne. Car ils n'éprouveront ni soif, ni fatigue, ni faim dans le sentier d'Allah, ils ne fouleront aucune terre en provoquant la colère des infidèles, et n'obtiendront aucun avantage sur un ennemi, sans qu'il ne leur soit écrit pour cela une bonne action. En vérité Allah ne laisse pas perdre la récompense des bienfaiteurs"
    },
    {
      "key": 1356,
      "id": "9-121",
      "translation": "Ils ne supporteront aucune dépense, minime ou importante, ne traverseront aucune vallée, sans que (cela) ne soit inscrit à leur actif, en sorte qu'Allah les récompense pour le meilleur de ce qu'ils faisaient"
    },
    {
      "key": 1357,
      "id": "9-122",
      "translation": "Les croyants n'ont pas à quitter tous leurs foyers. Pourquoi de chaque clan quelques hommes ne viendraient-ils pas s'instruire dans la religion, pour pouvoir à leur retour, avertir leur peuple afin qu'ils soient sur leur garde"
    },
    {
      "key": 1358,
      "id": "9-123",
      "translation": "O vous qui croyez! Combattez ceux des mécréants qui sont près de vous; et qu'ils trouvent de la dureté en vous. Et sachez qu'Allah est avec les pieux"
    },
    {
      "key": 1359,
      "id": "9-124",
      "translation": "Et quand une Sourate est révélée, il en est parmi eux qui dit: «Quel est celui d'entre vous dont elle fait croître la foi?» Quant aux croyants, elle fait certes croître leur foi, et ils s'en réjouissent"
    },
    {
      "key": 1360,
      "id": "9-125",
      "translation": "Mais quant à ceux dont les cœurs sont malades, elle ajoute une souillure à leur souillure, et ils meurent dans la mécréance"
    },
    {
      "key": 1361,
      "id": "9-126",
      "translation": "Ne voient-ils pas que chaque année on les éprouve une ou deux fois? Malgré cela, ils ne se repentent, ni ne se souviennent"
    },
    {
      "key": 1362,
      "id": "9-127",
      "translation": "Et quand une Sourate est révélée, ils se regardent les uns les autres [et se disent]: «Quelqu'un vous voit-il?» Puis ils se détournent. Qu'Allah détourne leurs cœurs, puisque ce sont des gens qui ne comprennent rien"
    },
    {
      "key": 1363,
      "id": "9-128",
      "translation": "Certes, un Messager pris parmi vous, est venu à vous, auquel pèsent lourd les difficultés que vous subissez, qui est plein de sollicitude pour vous, qui est compatissant et miséricordieux envers les croyants"
    },
    {
      "key": 1364,
      "id": "9-129",
      "translation": "Alors, s'ils se détournent dis: «Allah me suffit. Il n'y a de divinité que Lui. En Lui je place ma confiance; et Il est le Seigneur du Trône immense»"
    },
    {
      "key": 1365,
      "id": "10-1",
      "translation": "Alif, Lâm, Râ. Voici les versets du Livre plein de sagesse"
    },
    {
      "key": 1366,
      "id": "10-2",
      "translation": "Est-il étonnant pour les gens, que Nous ayons révélé à un homme d'entre eux: «Avertis les gens, et annonce la bonne nouvelle aux croyants qu'ils ont auprès de leur Seigneur une présence méritée [pour leur loyauté antérieure]? Les mécréants dirent alors: «Celui-ci est certainement un magicien évident»"
    },
    {
      "key": 1367,
      "id": "10-3",
      "translation": "Votre Seigneur est, Allah qui créa les cieux et la terre en six jours, puis S'est établi «Istawâ» sur le Trône, administrant toute chose. Il n'y a d'intercesseur qu'avec Sa permission. Tel est Allah votre Seigneur. Adorez-Le donc. Ne réfléchissez-vous pas"
    },
    {
      "key": 1368,
      "id": "10-4",
      "translation": "C'est vers Lui que vous retournerez tous, c'est là, la promesse d'Allah en toute vérité! C'est Lui qui fait la création une première fois puis la refait (en la ressuscitant) afin de rétribuer en toute équité ceux qui ont cru et fait de bonnes œuvres. Quant à ceux qui n'ont pas cru, ils auront un breuvage d'eau bouillante et un châtiment douloureux à cause de leur mécréance"
    },
    {
      "key": 1369,
      "id": "10-5",
      "translation": "C'est Lui qui a fait du soleil une clarté et de la lune une lumière, et Il en a déterminé les phases afin que vous sachiez le nombre des années et le calcul (du temps). Allah n'a créé cela qu'en toute vérité. Il expose les signes pour les gens doués de savoir"
    },
    {
      "key": 1370,
      "id": "10-6",
      "translation": "Dans l'alternance de la nuit et du jour, et aussi dans tout ce qu'Allah a créé dans les cieux et la terre, il y a des signes, certes, pour des gens qui craignent (Allah)"
    },
    {
      "key": 1371,
      "id": "10-7",
      "translation": "Ceux qui n'espèrent pas Notre rencontre, qui sont satisfaits de la vie présente et s'y sentent en sécurité, et ceux qui sont inattentifs à Nos signes [ou versets]"
    },
    {
      "key": 1372,
      "id": "10-8",
      "translation": "leur refuge sera le Feu, pour ce qu'ils acquéraient"
    },
    {
      "key": 1373,
      "id": "10-9",
      "translation": "Ceux qui croient et font de bonnes œuvres, leur Seigneur les guidera à cause de leur foi. A leurs pieds les ruisseaux couleront dans les Jardins des délices"
    },
    {
      "key": 1374,
      "id": "10-10",
      "translation": "Là, leur invocation sera «Gloire à Toi, O Allah», et leur salutation: «Salâm», [Paix!] et la fin de leur invocation: «Louange à Allah, Seigneur de l'Univers»"
    },
    {
      "key": 1375,
      "id": "10-11",
      "translation": "Et si Allah hâtait le malheur des gens avec autant de hâte qu'ils cherchent le bonheur, le terme de leur vie aurait été décrété. Mais Nous laissons ceux qui n'espèrent pas Notre rencontre confus et hésitants dans leur transgression"
    },
    {
      "key": 1376,
      "id": "10-12",
      "translation": "Et quand le malheur touche l'homme, il fait appel à Nous, couché sur le côté, assis, ou debout. Puis quand Nous le délivrons de son malheur, il s'en va comme s'il ne Nous avait point imploré pour un mal qui l'a touché. C'est ainsi que furent embellies aux outranciers leurs actions"
    },
    {
      "key": 1377,
      "id": "10-13",
      "translation": "Nous avons fait périr les générations d'avant vous lorsqu'elles eurent été injustes alors que leurs messagers leur avaient apporté des preuves. Cependant, elles n'étaient pas disposées à croire. C'est ainsi que Nous rétribuons les gens criminels"
    },
    {
      "key": 1378,
      "id": "10-14",
      "translation": "Puis nous fîmes de vous des successeurs sur terre après eux, pour voir comment vous agiriez"
    },
    {
      "key": 1379,
      "id": "10-15",
      "translation": "Et quand leur sont récités Nos versets en toute clarté, ceux qui n'espèrent pas notre rencontre disent: «Apporte un Coran autre que celui-ci» ou bien «Change-le». Dis: «Il ne m'appartient pas de le changer de mon propre chef. Je ne fais que suivre ce qui m'est révélé. Je crains, si je désobéis à mon Seigneur, le châtiment d'un jour terrible»"
    },
    {
      "key": 1380,
      "id": "10-16",
      "translation": "Dis: «Si Allah avait voulu, je ne vous l'aurais pas récité et Il ne vous l'aurait pas non plus fait connaître. Je suis bien resté, avant cela, tout un âge parmi vous. Ne raisonnez-vous donc pas?»"
    },
    {
      "key": 1381,
      "id": "10-17",
      "translation": "Qui est plus injuste que celui qui invente un mensonge contre Allah, ou qui traite de mensonges Ses versets (le Coran)? Vraiment, les criminels ne réussissent pas"
    },
    {
      "key": 1382,
      "id": "10-18",
      "translation": "Ils adorent au lieu d'Allah ce qui ne peut ni leur nuire ni leur profiter et disent: «Ceux-ci sont nos intercesseurs auprès d'Allah». Dis: «Informerez-vous Allah de ce qu'Il ne connaît pas dans les cieux et sur la terre?» Pureté à Lui, Il est Très élevé au-dessus de ce qu'Ils Lui associent"
    },
    {
      "key": 1383,
      "id": "10-19",
      "translation": "Les gens ne formaient (à l'origine) qu'une seule communauté. Puis ils divergèrent. Et si ce n'était une décision préalable de ton Seigneur, les litiges qui les opposaient auraient été tranchés"
    },
    {
      "key": 1384,
      "id": "10-20",
      "translation": "Et ils disent: «Que ne fait-on descendre sur lui  un miracle de son Seigneur?» Alors, dis: «L'inconnaissable relève seulement d'Allah. Attendez donc; je serai avec vous parmi ceux qui attendent"
    },
    {
      "key": 1385,
      "id": "10-21",
      "translation": "Et quand Nous faisons goûter aux gens une miséricorde après qu'un malheur les a touchés, voilà qu'ils dénigrent Nos versets. Dis: «Allah est plus prompt à réprimer (ceux qui dénigrent Ses versets)». Car Nos anges enregistrent vos dénigrements"
    },
    {
      "key": 1386,
      "id": "10-22",
      "translation": "C'est Lui qui vous fait aller sur terre et sur mer, quand vous êtes en bateau. [Ces bateaux] les emportèrent, grâce à un bon vent. Ils s'en réjouirent jusqu'au moment où, assaillis par un vent impétueux, assaillis de tous côtés par les vagues, se jugeant enveloppés [par la mort], ils prièrent Allah, Lui vouant le culte [et disant]: «Certes, si Tu nous sauves de ceci, nous serons parmi les reconnaissants!»"
    },
    {
      "key": 1387,
      "id": "10-23",
      "translation": "Lorsqu'Il les a sauvés, les voilà qui, sur terre, transgressent injustement. O gens! Votre transgression ne retombera que sur vous-mêmes. C'est une jouissance temporaire de la vie présente. Ensuite, c'est vers Nous que sera votre retour, et Nous vous rappellerons alors ce que vous faisiez"
    },
    {
      "key": 1388,
      "id": "10-24",
      "translation": "La vie présente est comparable à une eau que Nous faisons descendre du ciel et qui se mélange à la végétation de la terre dont se nourrissent les hommes et les bêtes. Puis, lorsque la terre prend sa parure et s'embellit, et que ses habitants pensent qu'elle est à leur entière disposition, Notre Ordre lui vient, de nuit ou de jour, c'est alors que Nous la rendrons toute moissonnée, comme si elle n'avait pas été florissante la veille. Ainsi exposons-Nous les preuves pour des gens qui réfléchissent"
    },
    {
      "key": 1389,
      "id": "10-25",
      "translation": "Allah appelle à la demeure de la paix et guide qui Il veut vers un droit chemin"
    },
    {
      "key": 1390,
      "id": "10-26",
      "translation": "A ceux qui agissent en bien est réservée la meilleure (récompense) et même davantage. Nulle fumée noircissante, nul avilissement ne couvriront leurs visages. Ceux-là sont les gens du Paradis, où ils demeureront éternellement"
    },
    {
      "key": 1391,
      "id": "10-27",
      "translation": "Et ceux qui ont commis de mauvaises actions, la rétribution d'une mauvaise action sera l'équivalent. Un avilissement les couvrira, - pas de protecteur pour eux contre Allah -, comme si leurs visages se couvraient de lambeaux de ténèbres nocturnes. Ceux-là sont là les gens du Feu où ils demeureront éternellement"
    },
    {
      "key": 1392,
      "id": "10-28",
      "translation": "(Et rappelle-toi) le jour où Nous les rassemblerons tous. Puis, Nous dirons à ceux qui ont donné [à Allah] des associés: «A votre place, vous et vos associés.» Nous les séparerons les uns des autres et leurs associés diront: «Ce n'est pas nous que vous adoriez»"
    },
    {
      "key": 1393,
      "id": "10-29",
      "translation": "Allah suffit comme témoin entre nous et vous. En vérité, nous étions indifférents à votre adoration»"
    },
    {
      "key": 1394,
      "id": "10-30",
      "translation": "Là, chaque âme éprouvera (les conséquences de) ce qu'elle a précédemment accompli. Et ils seront ramenés vers Allah leur vrai Maître; et leurs inventions (idoles) s'éloigneront d'eux"
    },
    {
      "key": 1395,
      "id": "10-31",
      "translation": "Dis: «Qui vous attribue de la nourriture du ciel et de la terre? Qui détient l'ouïe et la vue, et qui fait sortir le vivant du mort et fait sortir le mort du vivant, et qui administre tout?» Ils diront: «Allah». Dis alors: «Ne Le craignez-vous donc pas?»"
    },
    {
      "key": 1396,
      "id": "10-32",
      "translation": "Tel est Allah, votre vrai Seigneur. Au delà de la vérité qu'y a-t-il donc sinon l'égarement? Comment alors pouvez-vous, vous détourner?»"
    },
    {
      "key": 1397,
      "id": "10-33",
      "translation": "C'est ainsi que s'est réalisée la parole de ton Seigneur contre ceux qui sont pervers: «Ils ne croiront pas»"
    },
    {
      "key": 1398,
      "id": "10-34",
      "translation": "Dis: «Parmi vos associés, qui donne la vie par une première création et la redonne [après la mort]?» Dis: «Allah [seul] donne la vie par une première création et la redonne. Comment pouvez-vous vous écarter [de l'adoration d'Allah]"
    },
    {
      "key": 1399,
      "id": "10-35",
      "translation": "Dis: «Est-ce qu'il y a parmi vos associés un qui guide vers la vérité?» Dis: «C'est Allah qui guide vers la vérité. Celui qui guide vers la vérité est-il plus digne d'être suivi, ou bien celui qui ne se dirige qu'autant qu'il est lui-même dirigé? Qu'avez-vous donc? Comment jugez-vous ainsi?»"
    },
    {
      "key": 1400,
      "id": "10-36",
      "translation": "Et la plupart d'entre eux ne suivent que conjecture. Mais, la conjecture ne sert à rien contre la vérité! Allah sait parfaitement ce qu'ils font"
    },
    {
      "key": 1401,
      "id": "10-37",
      "translation": "Ce Coran n'est nullement à être forgé en dehors d'Allah mais c'est la confirmation de ce qui existait déjà avant lui, et l'exposé détaillé du Livre en quoi il n'y a pas de doute, venu du Seigneur de l'Univers"
    },
    {
      "key": 1402,
      "id": "10-38",
      "translation": "Ou bien ils disent: «Il  l'a inventé?» Dis: «Composez donc une sourate semblable à ceci, et appelez à votre aide n'importe qui vous pourrez, en dehors d'Allah, si vous êtes véridiques»"
    },
    {
      "key": 1403,
      "id": "10-39",
      "translation": "Bien au contraire: ils ont traité de mensonge ce qu'ils ne peuvent embrasser de leur savoir, et dont l'interprétation ne leur est pas encore parvenue. Ainsi ceux qui vivaient avant eux traitaient d'imposteurs (leurs messagers). Regarde comment a été la fin des injustes"
    },
    {
      "key": 1404,
      "id": "10-40",
      "translation": "Certains d'entre eux y croient, et d'autres n'y croient pas. Et ton Seigneur connaît le mieux les fauteurs de désordre"
    },
    {
      "key": 1405,
      "id": "10-41",
      "translation": "Et s'ils te traitent de menteur, dis alors: «A moi mon œuvre, et à vous la vôtre. Vous êtes irresponsables de ce que je fais et je suis irresponsable de ce que vous faites»"
    },
    {
      "key": 1406,
      "id": "10-42",
      "translation": "Et il en est parmi eux qui te prêtent l'oreille. Est-ce toi qui fait entendre les sourds, même s'ils sont incapables de comprendre"
    },
    {
      "key": 1407,
      "id": "10-43",
      "translation": "Et il en est parmi eux qui te regardent. Est-ce toi qui peux guider les aveugles, même s'ils ne voient pas"
    },
    {
      "key": 1408,
      "id": "10-44",
      "translation": "En vérité, Allah n'est point injuste à l'égard des gens, mais ce sont les gens qui font du tort à eux-mêmes"
    },
    {
      "key": 1409,
      "id": "10-45",
      "translation": "Et le jour où Il les rassemblera, ce sera comme s'ils n'étaient restés [dans leur tombeau] qu'une heure du jour et ils se reconnaîtront mutuellement. Perdants seront alors ceux qui auront traité de mensonge la rencontre d'Allah, et ils n'auront pas été bien guidés"
    },
    {
      "key": 1410,
      "id": "10-46",
      "translation": "Que Nous te fassions voir une partie de ce dont Nous les menaçons, ou que Nous te fassions mourir, (en tout cas), c'est vers Nous que sera leur retour. Allah est en outre, témoin de ce qu'ils font"
    },
    {
      "key": 1411,
      "id": "10-47",
      "translation": "A chaque communauté un Messager. Et lorsque leur messager vint, tout se décida en équité entre eux et ils ne furent point lésés"
    },
    {
      "key": 1412,
      "id": "10-48",
      "translation": "Et ils disent «A quand cette promesse, si vous êtes véridiques»"
    },
    {
      "key": 1413,
      "id": "10-49",
      "translation": "Dis: «Je ne détiens pour moi rien qui peut me nuire ou me profiter, excepté ce qu'Allah veut. A chaque communauté un terme. Quand leur terme arrive, ils ne peuvent ni le retarder d'une heure ni l'avancer»"
    },
    {
      "key": 1414,
      "id": "10-50",
      "translation": "Dis: «Voyez-vous! Si Son châtiment vous arrivait de nuit ou de jour, les criminels pourraient-ils en hâter quelque chose"
    },
    {
      "key": 1415,
      "id": "10-51",
      "translation": "«Est-ce au moment où le châtiment se produira que vous croirez? [Il vous sera dit: «Inutile».] Maintenant! Autrefois, vous en réclamiez [ironiquement] la prompte arrivée!»"
    },
    {
      "key": 1416,
      "id": "10-52",
      "translation": "Puis il sera dit aux injustes: «Goûtez au châtiment éternel! Etes-vous rétribués autrement qu'en rapport de ce que vous acquériez?»"
    },
    {
      "key": 1417,
      "id": "10-53",
      "translation": "Et ils s'informent auprès de toi: «Est-ce vrai?» - Dis: «Oui! Par mon Seigneur! C'est bien vrai. Et vous ne pouvez vous soustraire à la puissance d'Allah"
    },
    {
      "key": 1418,
      "id": "10-54",
      "translation": "Si chaque âme injuste possédait tout ce qu'il y a sur terre, elle le donnerait pour sa rançon. Ils dissimuleront leur regret quand ils verront le châtiment. Et il sera décidé entre eux en toute équité, et ils ne seront point lésés"
    },
    {
      "key": 1419,
      "id": "10-55",
      "translation": "C'est à Allah qu'appartient, certes, tout ce qui est dans les cieux et sur la terre. Certes, la promesse d'Allah est vérité. Mais la plupart d'entre eux ne (le) savent pas"
    },
    {
      "key": 1420,
      "id": "10-56",
      "translation": "C'est Lui qui donne la vie et qui donne la mort; et c'est vers Lui que vous serez ramenés"
    },
    {
      "key": 1421,
      "id": "10-57",
      "translation": "O gens! Une exhortation vous est venue, de votre Seigneur, une guérison de ce qui est dans les poitrines, un guide et une miséricorde pour les croyants"
    },
    {
      "key": 1422,
      "id": "10-58",
      "translation": "Dis: «[Ceci provient] de la grâce d'Allah et de Sa miséricorde; Voilà de quoi ils devraient se réjouir. C'est bien mieux que tout ce qu'ils amassent»"
    },
    {
      "key": 1423,
      "id": "10-59",
      "translation": "Que dites-vous de ce qu'Allah a fait descendre pour vous comme subsistance et dont vous avez alors fait des choses licites et des choses interdites? - Dis: «Est-ce Allah qui vous l'a permis? Ou bien forgez vous (des mensonges) contre Allah?»"
    },
    {
      "key": 1424,
      "id": "10-60",
      "translation": "Et que penseront, au Jour de la Résurrection, ceux qui forgent le mensonge contre Allah? - Certes, Allah est Détenteur de grâce pour les gens, mais la plupart d'entre eux ne sont pas reconnaissants"
    },
    {
      "key": 1425,
      "id": "10-61",
      "translation": "Tu ne te trouveras dans aucune situation, tu ne réciteras aucun passage du Coran, vous n'accomplirez aucun acte sans que Nous soyons témoin au moment où vous l'entreprendrez. Il n'échappe à ton Seigneur ni le poids d'un atome sur terre ou dans le ciel, ni un poids plus petit ou plus grand qui ne soit déjà inscrit dans un livre évident"
    },
    {
      "key": 1426,
      "id": "10-62",
      "translation": "En vérité, les bien-aimés d'Allah seront à l'abri de toute crainte, et ils ne seront point affligés"
    },
    {
      "key": 1427,
      "id": "10-63",
      "translation": "ceux qui croient et qui craignent [Allah]"
    },
    {
      "key": 1428,
      "id": "10-64",
      "translation": "Il y a pour eux une bonne annonce dans la vie d'ici-bas tout comme dans la vie ultime. - Il n'y aura pas de changement aux paroles d'Allah -. Voilà l'énorme succès"
    },
    {
      "key": 1429,
      "id": "10-65",
      "translation": "Que ce qu'ils disent ne t'afflige pas. La puissance toute entière appartient à Allah. C'est Lui qui est l'Audient, l'Omniscient"
    },
    {
      "key": 1430,
      "id": "10-66",
      "translation": "C'est à Allah qu'appartient, ce qui est dans les cieux et ce qui est sur la terre. Que suivent donc ceux qui invoquent, en dehors d'Allah, [des divinités] qu'ils Lui associent? Ils ne suivent que la conjecture et ne font que mentir"
    },
    {
      "key": 1431,
      "id": "10-67",
      "translation": "C'est Lui qui vous a désigné la nuit pour que vous vous y reposiez, et le jour pour vous permettre de voir. Ce sont en vérité des signes pour les gens qui entendent"
    },
    {
      "key": 1432,
      "id": "10-68",
      "translation": "Ils disent: «Allah S'est donné un enfant» Gloire et Pureté à Lui! Il est Le Riche par excellence. A Lui appartient tout ce qui est aux cieux et sur la terre; - vous n'avez pour cela aucune preuve. Allez-vous dire contre Allah ce que vous ne savez pas"
    },
    {
      "key": 1433,
      "id": "10-69",
      "translation": "Dis: «En vérité, ceux qui forgent le mensonge contre Allah ne réussiront pas»"
    },
    {
      "key": 1434,
      "id": "10-70",
      "translation": "C'est une jouissance (temporaire) dans la vie d'ici-bas; puis ils retourneront vers Nous et Nous leur ferons goûter au dur châtiment, à titre de sanction pour leur mécréance"
    },
    {
      "key": 1435,
      "id": "10-71",
      "translation": "Raconte-leur l'histoire de Noé, quand il dit à son peuple: «O mon peuple, si mon séjour (parmi vous), et mon rappel des signes d'Allah vous pèsent trop, alors c'est en Allah que je place (entièrement) ma confiance. Concertez-vous avec vos associés, et ne cachez pas vos desseins. Puis, décidez de moi et ne me donnez pas de répit"
    },
    {
      "key": 1436,
      "id": "10-72",
      "translation": "Si vous vous détournez, alors je ne vous ai pas demandé de salaire... Mon salaire n'incombe qu'à Allah. Et il m'a été commandé d'être du nombre des soumis»"
    },
    {
      "key": 1437,
      "id": "10-73",
      "translation": "Ils le traitèrent de menteur. Nous le sauvâmes, lui et ceux qui étaient avec lui dans l'arche, desquels Nous fîmes les successeurs (sur la terre). Nous noyâmes ceux qui traitaient de mensonge Nos preuves. Regarde comment a été la fin de ceux qui avaient été avertis"
    },
    {
      "key": 1438,
      "id": "10-74",
      "translation": "Puis Nous envoyâmes après lui des messagers à leurs peuples. Ils leur vinrent avec les preuves. Mais (les gens) étaient tels qu'ils ne pouvaient croire à ce qu'auparavant ils avaient traité de mensonge. Ainsi scellons-Nous les cœurs des transgresseurs"
    },
    {
      "key": 1439,
      "id": "10-75",
      "translation": "Ensuite, Nous envoyâmes après eux Moïse et Aaron, munis de Nos preuves à Pharaon et ses notables. Mais (ces gens) s'enflèrent d'orgueil et ils étaient un peuple criminel"
    },
    {
      "key": 1440,
      "id": "10-76",
      "translation": "Et lorsque la vérité leur vint de Notre part, ils dirent: «Voilà certes, une magie manifeste!»"
    },
    {
      "key": 1441,
      "id": "10-77",
      "translation": "Moïse dit: «Dites-vous à la Vérité quand elle vous est venue: Est-ce que cela est de la magie? Alors que les magiciens ne réussissent pas...»"
    },
    {
      "key": 1442,
      "id": "10-78",
      "translation": "Ils dirent: «Est-ce pour nous écarter de ce sur quoi nous avons trouvé nos ancêtres que tu es venu à nous, et pour que la grandeur appartienne à vous deux sur la terre? Et nous ne croyons pas en vous!»"
    },
    {
      "key": 1443,
      "id": "10-79",
      "translation": "Et Pharaon dit: «Amenez-moi tout magicien savant!»"
    },
    {
      "key": 1444,
      "id": "10-80",
      "translation": "Puis, lorsque vinrent les magiciens, Moïse leur dit: «Jetez ce que vous avez à jeter»"
    },
    {
      "key": 1445,
      "id": "10-81",
      "translation": "Lorsqu'ils jetèrent, Moïse dit: «Ce que vous avez produit est magie! Allah l'annulera. Car Allah ne fait pas prospérer ce que font les fauteurs de désordre"
    },
    {
      "key": 1446,
      "id": "10-82",
      "translation": "Et par Ses paroles, Allah fera triompher la Vérité, quelque répulsion qu'en aient les criminels»"
    },
    {
      "key": 1447,
      "id": "10-83",
      "translation": "Personne ne crut (au message) de Moïse, sauf un groupe de jeunes gens de son peuple, par crainte de représailles de Pharaon et de leurs notables. En vérité, Pharaon fut certes superbe sur terre et il fut du nombre des extravagants"
    },
    {
      "key": 1448,
      "id": "10-84",
      "translation": "Et Moïse dit: «O mon peuple, si vous croyez en Allah, placez votre confiance en Lui si vous (Lui) êtes soumis»"
    },
    {
      "key": 1449,
      "id": "10-85",
      "translation": "Ils dirent: «En Allah nous plaçons notre confiance. O notre Seigneur, ne fais pas de nous une cible pour les persécutions des injustes"
    },
    {
      "key": 1450,
      "id": "10-86",
      "translation": "Et délivre-nous, par Ta miséricorde, des gens mécréants»"
    },
    {
      "key": 1451,
      "id": "10-87",
      "translation": "Et Nous révélâmes à Moïse et à son frère: «Prenez pour votre peuple des maisons en Egypte, faites de vos maisons un lieu de prière et soyez assidus dans la prière. Et fais la bonne annonce aux croyants»"
    },
    {
      "key": 1452,
      "id": "10-88",
      "translation": "Et Moïse dit: «O notre Seigneur, Tu as accordé à Pharaon et ses notables des parures et des biens dans la vie présente, et voilà, O notre Seigneur, qu'avec cela ils égarent (les gens loin) de Ton sentier. O notre Seigneur, anéantis leurs biens et endurcis leurs cœurs, afin qu'ils ne croient pas, jusqu'à ce qu'ils aient vu le châtiment douloureux»"
    },
    {
      "key": 1453,
      "id": "10-89",
      "translation": "Il dit: «Votre prière est exaucée. Restez tous deux sur le chemin droit, et ne suivez point le sentier de ceux qui ne savent pas»"
    },
    {
      "key": 1454,
      "id": "10-90",
      "translation": "Et Nous fîmes traverser la mer aux Enfants d'Israël. Pharaon et ses armées les poursuivirent avec acharnement et inimitié. Puis, quand la noyade l'eut atteint, il dit: «Je crois qu'il n'y a d'autre divinité que Celui en qui ont cru les enfants d'Israël. Et je suis du nombre des soumis»"
    },
    {
      "key": 1455,
      "id": "10-91",
      "translation": "[Allah dit]: Maintenant? Alors qu'auparavant tu as désobéi et que tu as été du nombre des corrupteurs"
    },
    {
      "key": 1456,
      "id": "10-92",
      "translation": "Nous allons aujourd'hui épargner ton corps, afin que tu deviennes un signe à tes successeurs. Cependant beaucoup de gens ne prêtent aucune attention à Nos signes (d'avertissement)"
    },
    {
      "key": 1457,
      "id": "10-93",
      "translation": "Certes, Nous avons établi les Enfants d'Israël dans un endroit honorable, et leur avons attribué comme nourriture de bons aliments. Par la suite, ils n'ont divergé qu'au moment où leur vint la science. Ton Seigneur décidera entre eux, au Jour de la Résurrection sur ce qui les divisait"
    },
    {
      "key": 1458,
      "id": "10-94",
      "translation": "Et si tu es en doute sur ce que Nous avons fait descendre vers toi, interroge alors ceux qui lisent le Livre révélé avant toi. La vérité certes, t'est venue de ton Seigneur: ne sois donc point de ceux qui doutent"
    },
    {
      "key": 1459,
      "id": "10-95",
      "translation": "Et ne sois point de ceux qui traitent de mensonge les versets d'Allah. Tu serais alors du nombre des perdants"
    },
    {
      "key": 1460,
      "id": "10-96",
      "translation": "Ceux contre qui la parole (la menace) de ton Seigneur se réalisera ne croiront pas"
    },
    {
      "key": 1461,
      "id": "10-97",
      "translation": "même si tous les signes leur parvenaient, jusqu'à ce qu'ils voient le châtiment douloureux"
    },
    {
      "key": 1462,
      "id": "10-98",
      "translation": "Si seulement il y avait, à part le peuple de Yûnus (Jonas), une cité qui ait cru et à qui sa croyance eut ensuite profité! Lorsqu'ils eurent cru, Nous leur enlevâmes le châtiment d'ignominie dans la vie présente et leur donnâmes jouissance pour un certain temps"
    },
    {
      "key": 1463,
      "id": "10-99",
      "translation": "Si ton Seigneur l'avait voulu, tous ceux qui sont sur la terre auraient cru. Est-ce à toi de contraindre les gens à devenir croyants"
    },
    {
      "key": 1464,
      "id": "10-100",
      "translation": "Il n'appartient nullement à une âme de croire si ce n'est avec la permission d'Allah. Et Il voue au châtiment ceux qui ne raisonnent pas"
    },
    {
      "key": 1465,
      "id": "10-101",
      "translation": "Dis: «Regardez ce qui est dans les cieux et sur la terre». Mais ni les preuves ni les avertisseurs (prophètes) ne suffisent à des gens qui ne croient pas"
    },
    {
      "key": 1466,
      "id": "10-102",
      "translation": "Est-ce qu'ils attendent autre chose que des châtiments semblables à ceux des peuples antérieurs? Dis: «Attendez! Moi aussi, j'attends avec vous»"
    },
    {
      "key": 1467,
      "id": "10-103",
      "translation": "Ensuite, Nous délivrerons Nos messagers et les croyants. C'est ainsi qu'il Nous incombe [en toute justice] de délivrer les croyants"
    },
    {
      "key": 1468,
      "id": "10-104",
      "translation": "Dis: «O gens! Si vous êtes en doute sur ma religion, moi, je n'adore point ceux que vous adorez en dehors d'Allah; mais j'adore Allah qui vous fera mourir. Et il m'a été commandé d'être du nombre des croyants»"
    },
    {
      "key": 1469,
      "id": "10-105",
      "translation": "Et (il m'a été dit): «Oriente-toi exclusivement sur la religion en pur monothéiste! Et ne sois pas du nombre des Associateurs"
    },
    {
      "key": 1470,
      "id": "10-106",
      "translation": "et n'invoque pas, en dehors d'Allah, ce qui ne peut te profiter ni te nuire. Et si tu le fais, tu seras alors du nombre des injustes»"
    },
    {
      "key": 1471,
      "id": "10-107",
      "translation": "Et si Allah fait qu'un mal te touche, nul ne peut l'écarter en dehors de Lui. Et s'Il te veut un bien, nul ne peut repousser Sa grâce. Il en gratifie qui Il veut parmi Ses serviteurs. Et c'est Lui le Pardonneur, le Miséricordieux"
    },
    {
      "key": 1472,
      "id": "10-108",
      "translation": "Dis: «O gens! Certes la vérité vous est venue de votre Seigneur. Donc, quiconque est dans le bon chemin ne l'est que pour lui-même; et quiconque s'égare, ne s'égare qu'à son propre détriment. Je ne suis nullement un protecteur pour vous"
    },
    {
      "key": 1473,
      "id": "10-109",
      "translation": "Et suis ce qui t'est révélé, et sois constant jusqu'à ce qu'Allah rende Son jugement car Il est le meilleur des juges"
    },
    {
      "key": 1474,
      "id": "11-1",
      "translation": "Alif, Lâm, Râ. C'est un Livre dont les versets sont parfaits en style et en sens, émanant d'un Sage, Parfaitement Connaisseur"
    },
    {
      "key": 1475,
      "id": "11-2",
      "translation": "N'adorez qu'Allah. Moi, je suis pour vous, de Sa part, un avertisseur et un annonciateur"
    },
    {
      "key": 1476,
      "id": "11-3",
      "translation": "Demandez pardon à votre Seigneur; ensuite, revenez à Lui. Il vous accordera une belle jouissance jusqu'à un terme fixé, et Il accordera à chaque méritant l'honneur qu'il mérite. Mais si vous tournez le dos, je crains alors pour vous le châtiment d'un grand jour"
    },
    {
      "key": 1477,
      "id": "11-4",
      "translation": "C'est à Allah que sera votre retour; et Il est Omnipotent"
    },
    {
      "key": 1478,
      "id": "11-5",
      "translation": "Eh quoi! Ils replient leurs poitrines afin de se cacher de Lui. Même lorsqu'ils se couvrent de leurs vêtements, Il sait ce qu'ils cachent et ce qu'ils divulguent car Il connaît certes le contenu des poitrines"
    },
    {
      "key": 1479,
      "id": "11-6",
      "translation": "Il n'y a point de bête sur terre dont la subsistance n'incombe à Allah qui connaît son gîte et son dépôt; tout est dans un Livre explicite"
    },
    {
      "key": 1480,
      "id": "11-7",
      "translation": "Et c'est Lui qui a créé les cieux et la terre en six jours, - alors que Son Trône était sur l'eau, - afin d'éprouver lequel de vous agirait le mieux. Et si tu dis: «Vous serez ressuscités après la mort», ceux qui ne croient pas diront: «Ce n'est là qu'une magie évidente»"
    },
    {
      "key": 1481,
      "id": "11-8",
      "translation": "Et si Nous retardons pour eux le châtiment jusqu'à une période fixée, ils diront: «Qu'est-ce qui le retient?» - Mais le jour où cela leur viendra, il ne sera pas détourné; d'eux et ce dont ils se moquaient les enveloppera"
    },
    {
      "key": 1482,
      "id": "11-9",
      "translation": "Et si Nous faisons goûter à l'homme une grâce de Notre part, et qu'ensuite Nous la lui arrachons, le voilà désespéré et ingrat"
    },
    {
      "key": 1483,
      "id": "11-10",
      "translation": "Et si Nous lui faisons goûter le bonheur, après qu'un malheur l'ait touché, il dira: «Les maux se sont éloignés de moi», et le voilà qui exulte, plein de gloriole"
    },
    {
      "key": 1484,
      "id": "11-11",
      "translation": "sauf ceux qui sont endurants et font de bonnes œuvres. Ceux-là obtiendront pardon et une grosse récompense"
    },
    {
      "key": 1485,
      "id": "11-12",
      "translation": "Il se peut que tu négliges une partie de ce qui t'est révélé, et que ta poitrine s'en sente compressée; parce qu'ils disent: «Que n'a-t-on fait descendre sur lui un trésor?» Ou bien: «Que n'est-il venu un Ange en sa compagnie?» - Tu n'es qu'un avertisseur. Et Allah est Le protecteur de toute chose"
    },
    {
      "key": 1486,
      "id": "11-13",
      "translation": "Ou bien ils disent: «Il l'a forgé [le Coran]» - Dis: «Apportez donc dix Sourates semblables à ceci, forgées (par vous). Et appelez qui vous pourrez (pour vous aider), hormis Allah, si vous êtes véridiques»"
    },
    {
      "key": 1487,
      "id": "11-14",
      "translation": "S'ils ne vous répondent pas, sachez alors que c'est par la science d'Allah qu'il est descendu, et qu'il n'y a de divinité que Lui. Etes-vous soumis (à Lui)"
    },
    {
      "key": 1488,
      "id": "11-15",
      "translation": "Ceux qui veulent la vie présente avec sa parure, Nous les rétribuerons exactement selon leurs actions sur terre, sans que rien leur en soit diminué"
    },
    {
      "key": 1489,
      "id": "11-16",
      "translation": "Ceux-là qui n'ont rien, dans l'au-delà, que le Feu. Ce qu'ils auront fait ici-bas sera un échec, et sera vain ce qu'ils auront œuvré"
    },
    {
      "key": 1490,
      "id": "11-17",
      "translation": "Est-ce que celui qui se fonde sur une preuve évidente (le Coran) venant de son Seigneur et récitée par un témoin [l'archange Gabriel] de Sa part, cependant qu'avant lui il y a le livre de Moïse tenant lieu de guide et de miséricorde... [est meilleur ou bien celui qui ne se fonde sur aucune preuve valable?]. Ceux-là y croient; mais quiconque d'entre les factions n'y croit pas, aura le Feu comme rendez-vous. Ne sois donc pas en doute au sujet de ceci (le Coran). Oui, c'est la vérité venant de ton Seigneur; mais la plupart des gens n'y croient pas"
    },
    {
      "key": 1491,
      "id": "11-18",
      "translation": "Et quel pire injuste que celui qui forge un mensonge contre Allah? Ceux-là seront présentés à leur Seigneur, et les témoins (les anges) diront: «Voilà ceux qui ont menti contre leur Seigneur». Que la malédiction d'Allah (frappe) les injustes"
    },
    {
      "key": 1492,
      "id": "11-19",
      "translation": "qui obstruent le sentier d'Allah (aux gens), cherchent à le rendre tortueux et ne croient pas en l'au-delà"
    },
    {
      "key": 1493,
      "id": "11-20",
      "translation": "Ceux-là ne peuvent réduire (Allah) à l'impuissance sur terre! Pas d'alliés pour eux en dehors d'Allah et leur châtiment sera doublé. Ils étaient incapables d'entendre; ils ne voyaient pas non plus"
    },
    {
      "key": 1494,
      "id": "11-21",
      "translation": "Ce sont ceux-là qui ont causé la perte de leurs propres âmes. Et leurs inventions (idoles) se sont éloignées d'eux"
    },
    {
      "key": 1495,
      "id": "11-22",
      "translation": "Ce sont eux, infailliblement, qui dans l'au-delà seront les plus grands perdants"
    },
    {
      "key": 1496,
      "id": "11-23",
      "translation": "Certes ceux qui croient, font de bonnes œuvres et s'humilient devant leur Seigneur, voilà les gens du Paradis où ils demeureront éternellement"
    },
    {
      "key": 1497,
      "id": "11-24",
      "translation": "Les deux groupes ressemblent, l'un à l'aveugle et au sourd, l'autre à celui qui voit et qui entend. Les deux sont-ils comparativement égaux? Ne vous souvenez-vous pas"
    },
    {
      "key": 1498,
      "id": "11-25",
      "translation": "Nous avons déjà envoyé Noé à son peuple: «Je suis pour vous un avertisseur explicite"
    },
    {
      "key": 1499,
      "id": "11-26",
      "translation": "afin que vous n'adoriez qu'Allah. Je crains pour vous le châtiment d'un jour douloureux»"
    },
    {
      "key": 1500,
      "id": "11-27",
      "translation": "Les notables de son peuple qui avaient mécru, dirent alors: «Nous ne voyons en toi qu'un homme comme nous; et nous voyons que ce sont seulement les vils parmi nous qui te suivent sans réfléchir; et nous ne voyons en vous aucune supériorité sur nous. Plutôt, nous pensons que vous êtes des menteurs»"
    },
    {
      "key": 1501,
      "id": "11-28",
      "translation": "Il dit: «O mon peuple! Que vous en semble? Si je me conforme à une preuve de mon Seigneur, si une Miséricorde, (prophétie) échappant à vos yeux, est venue à moi de Sa part, devrons-nous vous l'imposer alors que vous la répugnez"
    },
    {
      "key": 1502,
      "id": "11-29",
      "translation": "O mon peuple, je ne vous demande pas de richesse en retour. Mon salaire n'incombe qu'à Allah. Je ne repousserai point ceux qui ont cru, ils auront à rencontrer leur Seigneur. Mais je vous trouve des gens ignorants"
    },
    {
      "key": 1503,
      "id": "11-30",
      "translation": "O mon peuple, qui me secourra contre (la punition d') Allah si je les repousse? Ne vous souvenez-vous pas"
    },
    {
      "key": 1504,
      "id": "11-31",
      "translation": "Et je ne vous dis pas que je détiens les trésors d'Allah, je ne connais pas l'Inconnaissable, et je ne dis pas que je suis un Ange; et je ne dis pas non plus aux gens, que vos yeux méprisent, qu'Allah ne leur accordera aucune faveur; Allah connaît mieux ce qu'il y a dans leurs âmes. [Si je le leur disais], je serais du nombre des injustes"
    },
    {
      "key": 1505,
      "id": "11-32",
      "translation": "Ils dirent: «O Noé, tu as disputé avec nous et multiplié les discussions. Apporte-nous donc ce dont tu nous menaces, si tu es du nombre des véridiques»"
    },
    {
      "key": 1506,
      "id": "11-33",
      "translation": "Il dit: «C'est Allah seul qui vous l'apportera - s'Il veut - et vous ne saurez y échapper"
    },
    {
      "key": 1507,
      "id": "11-34",
      "translation": "Et mon conseil ne vous profiterait pas, au cas où je voulais vous conseiller, et qu'Allah veuille vous égarer. Il est votre Seigneur, et c'est vers Lui que vous serez ramenés»"
    },
    {
      "key": 1508,
      "id": "11-35",
      "translation": "Ou bien ils disent: il l'a inventé? Dis: «Si je l'ai inventé, que mon crime retombe sur moi! Et je suis innocent de vos criminelles accusations»"
    },
    {
      "key": 1509,
      "id": "11-36",
      "translation": "Et il fut révélé à Noé: «De ton peuple, il n'y aura plus de croyants que ceux qui ont déjà cru. Ne t'afflige pas de ce qu'ils faisaient"
    },
    {
      "key": 1510,
      "id": "11-37",
      "translation": "Et construis l'arche sous Nos yeux et d'après Notre révélation. Et ne M'interpelle plus au sujet des injustes, car ils vont être noyés»"
    },
    {
      "key": 1511,
      "id": "11-38",
      "translation": "Et il construisait l'arche. Et chaque fois que des notables de son peuple passaient près de lui, ils se moquaient de lui. Il dit: «Si vous vous moquez de nous, eh bien, nous nous moquerons de vous, comme vous vous moquez [de nous]"
    },
    {
      "key": 1512,
      "id": "11-39",
      "translation": "Et vous saurez bientôt à qui viendra un châtiment qui l'humiliera, et sur qui s'abattra un châtiment durable!»"
    },
    {
      "key": 1513,
      "id": "11-40",
      "translation": "Puis, lorsque Notre commandement vint et que le four se mit à bouillonner [d'eau], Nous dîmes: «Charge [dans l'arche] un couple de chaque espèce ainsi que ta famille - sauf ceux contre qui le décret est déjà prononcé - et ceux qui croient». Or, ceux qui avaient cru avec lui étaient peu nombreux"
    },
    {
      "key": 1514,
      "id": "11-41",
      "translation": "Et il dit: «Montez dedans. Que sa course et son mouillage soient au nom d'Allah. Certes mon Seigneur est Pardonneur et Miséricordieux»"
    },
    {
      "key": 1515,
      "id": "11-42",
      "translation": "Et elle vogua en les emportant au milieu des vagues comme des montagnes. Et Noé appela son fils, qui restait en un lieu écarté (non loin de l'arche): «O mon enfant, monte avec nous et ne reste pas avec les mécréants»"
    },
    {
      "key": 1516,
      "id": "11-43",
      "translation": "Il répondit: «Je vais me réfugier vers un mont qui me protègera de l'eau». Et Noé lui dit: «Il n'y a aujourd'hui aucun protecteur contre l'ordre d'Allah. (Tous périront) sauf celui à qui Il fait miséricorde». Et les vagues s'interposèrent entre les deux, et le fils fut alors du nombre des noyés"
    },
    {
      "key": 1517,
      "id": "11-44",
      "translation": "Et il fut dit: «O terre, absorbe ton eau! Et toi, ciel, cesse [de pleuvoir]!» L'eau baissa, l'ordre fut exécuté et l'arche s'installa sur le Jûdi, et il fut dit: «Que disparaissent les gens pervers»"
    },
    {
      "key": 1518,
      "id": "11-45",
      "translation": "Et Noé invoqua son Seigneur et dit: «O mon Seigneur, certes mon fils est de ma famille et Ta promesse est vérité. Tu es le plus juste des juges»"
    },
    {
      "key": 1519,
      "id": "11-46",
      "translation": "Il dit: «O Noé, il n'est pas de ta famille car il a commis un acte infâme. Ne me demande pas ce dont tu n'as aucune connaissance. Je t'exhorte afin que tu ne sois pas du nombre des ignorants»"
    },
    {
      "key": 1520,
      "id": "11-47",
      "translation": "Alors Noé dit: «Seigneur, je cherche Ta protection contre toute demande de ce dont je n'ai aucune connaissance. Et si Tu ne me pardonnes pas et ne me fais pas miséricorde, je serai au nombre des perdants»"
    },
    {
      "key": 1521,
      "id": "11-48",
      "translation": "Il fut dit: «O Noé, débarque avec Notre sécurité et Nos bénédictions sur toi et sur des communautés [issues] de ceux qui sont avec toi. Et il y (en) aura des communautés auxquelles Nous accorderons une jouissance temporaire; puis un châtiment douloureux venant de Nous les touchera»"
    },
    {
      "key": 1522,
      "id": "11-49",
      "translation": "Voilà quelques nouvelles de l'Inconnaissable que Nous te révélons. Tu ne les savais pas, ni toi ni ton peuple, avant cela. Sois patient. La fin heureuse sera aux pieux"
    },
    {
      "key": 1523,
      "id": "11-50",
      "translation": "Et (Nous avons envoyé) aux 'Aad, leur frère Hûd, qui leur dit: «O mon peuple, adorez Allah. Vous n'avez point de divinité à part Lui. Vous n'êtes que des forgeurs (de mensonges)"
    },
    {
      "key": 1524,
      "id": "11-51",
      "translation": "O mon peuple, je ne vous demande pas de salaire pour cela. Mon salaire n'incombe qu'à Celui qui m'a créé. Ne raisonnez-vous pas"
    },
    {
      "key": 1525,
      "id": "11-52",
      "translation": "O mon peuple, implorez le pardon de votre Seigneur et repentez-vous à Lui pour qu'Il envoie sur vous du ciel des pluies abondantes et qu'Il ajoute force à votre force. Et ne vous détournez pas [de Lui] en devenant coupables»"
    },
    {
      "key": 1526,
      "id": "11-53",
      "translation": "Ils dirent: «O Hûd, tu n'es pas venu à nous avec une preuve, et nous ne sommes pas disposés à abandonner nos divinités sur ta parole, et nous n'avons pas foi en toi"
    },
    {
      "key": 1527,
      "id": "11-54",
      "translation": "Nous dirons plutôt qu'une de nos divinités t'a affligé d'un mal». Il dit: «Je prends Allah à témoin - et vous aussi soyez témoins - qu'en vérité, je désavoue ce que vous associez"
    },
    {
      "key": 1528,
      "id": "11-55",
      "translation": "en dehors de Lui. Rusez donc tous contre moi et ne me donnez pas de répit"
    },
    {
      "key": 1529,
      "id": "11-56",
      "translation": "Je place ma confiance en Allah, mon Seigneur et le vôtre. Il n'y a pas d'être vivant qu'Il ne tienne par son toupet. Mon Seigneur, certes, est sur un droit chemin"
    },
    {
      "key": 1530,
      "id": "11-57",
      "translation": "Si vous vous détournez... voilà que je vous ai transmis [le message] que j'étais chargé de vous faire parvenir. Et mon Seigneur vous remplacera par un autre peuple, sans que vous ne Lui nuisiez en rien, car mon Seigneur, est gardien par excellence sur toute chose»"
    },
    {
      "key": 1531,
      "id": "11-58",
      "translation": "Et quand vint Notre Ordre, Nous sauvâmes par une miséricorde de Notre part, Hûd et ceux qui avec lui avaient cru. Et Nous les sauvâmes d'un terrible châtiment"
    },
    {
      "key": 1532,
      "id": "11-59",
      "translation": "Voilà les 'Aad. Ils avaient nié les signes (enseignements) de leur Seigneur, désobéi à Ses messagers et suivi le commandement de tout tyran entêté"
    },
    {
      "key": 1533,
      "id": "11-60",
      "translation": "Et ils furent poursuivis, ici-bas, d'une malédiction, ainsi qu'au Jour de la Résurrection. En vérité, les 'Aad n'ont pas cru en leur Seigneur. Que s'éloignent (périssent) les 'Aad, peuple de Hûd"
    },
    {
      "key": 1534,
      "id": "11-61",
      "translation": "Et (Nous avons envoyé) aux Thamûd, leur frère Sâlih qui dit: «O mon peuple, adorez Allah. Vous n'avez point de divinité en dehors de Lui. De la terre Il vous a créés, et Il vous l'a fait peupler (et exploiter). Implorez donc Son pardon, puis repentez-vous à Lui. Mon Seigneur est bien proche et Il répond toujours (aux appels)»"
    },
    {
      "key": 1535,
      "id": "11-62",
      "translation": "Ils dirent: «O Sâlih, tu étais auparavant un espoir pour nous. Nous interdirais-tu d'adorer ce qu'adoraient nos ancêtres? Cependant, nous voilà bien dans un doute troublant au sujet de ce vers quoi tu nous invites»"
    },
    {
      "key": 1536,
      "id": "11-63",
      "translation": "Il dit: «O mon peuple! Que vous en semble, si je m'appuie sur une preuve évidente émanant de mon Seigneur et s'Il m'a accordé, de Sa part, une miséricorde, qui donc me protègera contre Allah si je Lui désobéis? Vous ne ferez qu'accroître ma perte"
    },
    {
      "key": 1537,
      "id": "11-64",
      "translation": "O mon peuple, voici la chamelle d'Allah qu'Il vous a envoyée comme signe. Laissez-la donc paître sur la terre d'Allah, et ne lui faites aucun mal sinon, un châtiment proche vous saisira!»"
    },
    {
      "key": 1538,
      "id": "11-65",
      "translation": "Ils la tuèrent. Alors, il leur dit: «Jouissez (de vos biens) dans vos demeures pendant trois jours (encore)! Voilà une promesse qui ne sera pas démentie»"
    },
    {
      "key": 1539,
      "id": "11-66",
      "translation": "Puis, lorsque Notre ordre vint, Nous sauvâmes Sâlih et ceux qui avaient cru avec lui, - par une miséricorde venant de Nous - de l'ignominie de ce jour-là. En vérité, c'est ton Seigneur qui est le Fort, le Puissant"
    },
    {
      "key": 1540,
      "id": "11-67",
      "translation": "Et le Cri saisit les injustes. Et les voilà foudroyés dans leurs demeures"
    },
    {
      "key": 1541,
      "id": "11-68",
      "translation": "comme s'ils n'y avaient jamais prospéré. En vérité, les Thamûd n'ont pas cru en leur Seigneur. Que périssent les Thamûd"
    },
    {
      "key": 1542,
      "id": "11-69",
      "translation": "Et Nos émissaires sont, certes, venus à Abraham avec la bonne nouvelle, en disant: «Salâm!». Il dit: «Salâm!», et il ne tarda pas à apporter un veau rôti"
    },
    {
      "key": 1543,
      "id": "11-70",
      "translation": "Puis, lorsqu'il vit que leurs mains ne l'approchaient pas, il fut pris de suspicion à leur égard et ressentit de la peur vis-à-vis d'eux. Ils dirent: «N'aie pas peur, nous sommes envoyés au peuple de Lot»"
    },
    {
      "key": 1544,
      "id": "11-71",
      "translation": "Sa femme était debout, et elle rit alors; Nous lui annonçâmes donc (la naissance d') Isaac, et après Isaac, Jacob"
    },
    {
      "key": 1545,
      "id": "11-72",
      "translation": "Elle dit: «Malheur à moi! Vais-je enfanter alors que je suis vieille et que mon mari, que voici, est un vieillard? C'est là vraiment une chose étrange!»"
    },
    {
      "key": 1546,
      "id": "11-73",
      "translation": "Ils dirent: «T'étonnes-tu de l'ordre d'Allah? Que la miséricorde d'Allah et Ses bénédictions soient sur vous, gens de cette maison! Il est vraiment, digne de louange et de glorification!»"
    },
    {
      "key": 1547,
      "id": "11-74",
      "translation": "Lorsque l'effroi eut quitté Abraham et que la bonne nouvelle l'eut atteint, voilà qu'il discuta avec Nous (en faveur) du peuple de Lot"
    },
    {
      "key": 1548,
      "id": "11-75",
      "translation": "Abraham était, certes, longanime, très implorant et repentant"
    },
    {
      "key": 1549,
      "id": "11-76",
      "translation": "O Abraham, renonce à cela; car l'ordre de Ton Seigneur est déjà venu, et un châtiment irrévocable va leur arriver»"
    },
    {
      "key": 1550,
      "id": "11-77",
      "translation": "Et quand Nos émissaires (Anges) vinrent à Lot, il fut chagriné pour eux, et en éprouva une grande gêne. Et il dit: «Voici un jour terrible»"
    },
    {
      "key": 1551,
      "id": "11-78",
      "translation": "Quant à son peuple, ils vinrent à lui, accourant. Auparavant ils commettaient des mauvaises actions. Il dit: «O mon peuple, voici mes filles: elles sont plus pures pour vous. Craignez Allah donc, et ne me déshonorez pas dans mes hôtes. N'y a-t-il pas parmi vous un homme raisonnable?»"
    },
    {
      "key": 1552,
      "id": "11-79",
      "translation": "Ils dirent: Tu sais très bien que nous n'avons pas de droit sur tes filles. Et en vérité, tu sais bien ce que nous voulons»"
    },
    {
      "key": 1553,
      "id": "11-80",
      "translation": "Il dit: «[Ah!] si j'avais de la force pour vous résister! ou bien si je trouvais un appui solide!»"
    },
    {
      "key": 1554,
      "id": "11-81",
      "translation": "Alors [les hôtes] dirent: «O Lot, nous sommes vraiment les émissaires de ton Seigneur. Ils ne pourront jamais t'atteindre. Pars avec ta famille à un moment de la nuit. Et que nul d'entre vous ne se retourne en arrière. Exception faite de ta femme qui sera atteinte par ce qui frappera les autres. Ce qui les menace s'accomplira à l'aube. L'aube n'est-elle pas proche?»"
    },
    {
      "key": 1555,
      "id": "11-82",
      "translation": "Et, lorsque vint Notre ordre, Nous renversâmes [la cité] de fond en comble, et fîmes pleuvoir sur elle en masse, des pierres d'argile succédant les unes aux autres"
    },
    {
      "key": 1556,
      "id": "11-83",
      "translation": "portant une marque connue de ton Seigneur. Et elles (ces pierres) ne sont pas loin des injustes"
    },
    {
      "key": 1557,
      "id": "11-84",
      "translation": "Et (Nous avons envoyé) aux Madyan, leur frère Chuayb qui leur dit: «O mon peuple, adorez Allah; vous n'avez point de divinité en dehors Lui. Et ne diminuez pas les mesures et le poids. Je vous vois dans l'aisance, et je crains pour vous [si vous ne croyez pas] le châtiment d'un jour qui enveloppera tout"
    },
    {
      "key": 1558,
      "id": "11-85",
      "translation": "O mon peuple, faites équitablement pleine mesure et plein poids, ne dépréciez pas aux gens leurs valeurs et ne semez pas la corruption sur terre"
    },
    {
      "key": 1559,
      "id": "11-86",
      "translation": "Ce qui demeure auprès d'Allah est meilleur pour vous si vous êtes croyants! Et je ne suis pas un gardien pour vous»"
    },
    {
      "key": 1560,
      "id": "11-87",
      "translation": "Ils dirent: «O Chuayb! Est-ce que ta prière te demande de nous faire abandonner ce qu'adoraient nos ancêtres, ou de ne plus faire de nos biens ce que nous voulons? Est-ce toi l'indulgent, le droit?»"
    },
    {
      "key": 1561,
      "id": "11-88",
      "translation": "Il dit: «O mon peuple, voyez-vous si je me base sur une preuve évidente émanant de mon Seigneur, et s'Il m'attribue de Sa part une excellente donation?... Je ne veux nullement faire ce que je vous interdis. Je ne veux que la réforme, autant que je le puis. Et ma réussite ne dépend que d'Allah. En Lui je place ma confiance, et c'est vers Lui que je reviens repentant"
    },
    {
      "key": 1562,
      "id": "11-89",
      "translation": "O mon peuple, que votre répugnance et votre hostilité à mon égard ne vous entraînent pas à encourir les mêmes châtiments qui atteignirent le peuple de Noé, le peuple de Hûd, ou le peuple de Sâlih et (l'exemple du) peuple de Lot n'est pas éloigné de vous"
    },
    {
      "key": 1563,
      "id": "11-90",
      "translation": "Et implorez le pardon de votre Seigneur et repentez-vous à Lui. Mon Seigneur est vraiment Miséricordieux et plein d'amour»"
    },
    {
      "key": 1564,
      "id": "11-91",
      "translation": "Ils dirent: «O Chuayb, nous ne comprenons pas grand chose à ce que tu dis; et vraiment nous te considérons comme un faible parmi nous. Si ce n'est ton clan, nous t'aurions certainement lapidé. Et rien ne nous empêche de t'atteindre»"
    },
    {
      "key": 1565,
      "id": "11-92",
      "translation": "Il dit: «O mon peuple, mon clan est-il à vos yeux plus puissant qu'Allah à qui vous tournez ouvertement le dos? Mon Seigneur embrasse (en Sa science) tout ce que vous œuvrez"
    },
    {
      "key": 1566,
      "id": "11-93",
      "translation": "O mon peuple, agissez autant que vous voulez. Moi aussi j'agis. Bientôt, vous saurez sur qui tombera un châtiment qui le déshonorera, et qui de nous est l'imposteur. Et attendez (la conséquence de vos actes)! Moi aussi j'attends avec vous»"
    },
    {
      "key": 1567,
      "id": "11-94",
      "translation": "Lorsque vint Notre ordre, Nous sauvâmes, par une miséricorde de Notre part, Chuayb et ceux qui avaient cru avec lui. Et le Cri terrible saisit les injustes, et ils gisèrent dans leurs demeures"
    },
    {
      "key": 1568,
      "id": "11-95",
      "translation": "comme s'ils n'y avaient jamais prospéré. Que les Madyan s'éloignent comme les Thamûd se sont éloignés"
    },
    {
      "key": 1569,
      "id": "11-96",
      "translation": "Et Nous avions envoyé Moïse, avec Nos miracles et une autorité incontestable"
    },
    {
      "key": 1570,
      "id": "11-97",
      "translation": "à Pharaon et ses notables. Mais ils suivirent l'ordre de Pharaon, bien que l'ordre de Pharaon n'avait rien de sensé"
    },
    {
      "key": 1571,
      "id": "11-98",
      "translation": "Il précédera son peuple, au Jour de la Résurrection. Il les mènera à l'aiguade du Feu. Et quelle détestable aiguade"
    },
    {
      "key": 1572,
      "id": "11-99",
      "translation": "Et ils sont poursuivis par une malédiction ici-bas et au Jour de la Résurrection. Quel détestable don leur sera donné"
    },
    {
      "key": 1573,
      "id": "11-100",
      "translation": "Cela fait partie des récits que Nous te racontons concernant des cités: les unes sont encore debout, tandis que d'autres (sont complètement) rasées"
    },
    {
      "key": 1574,
      "id": "11-101",
      "translation": "Nous ne leur avons fait aucun tort. Mais ils se sont fait du tort à eux-mêmes. Leurs divinités, qu'ils invoquaient en dehors d'Allah, ne leur ont servi à rien, quand l'Ordre (le châtiment) de ton Seigneur fut venu; elles n'ont fait qu'accroître leur perte"
    },
    {
      "key": 1575,
      "id": "11-102",
      "translation": "Telle est la rigueur de la prise de ton Seigneur quand Il frappe les cités lorsqu'elles sont injustes. Son châtiment est bien douloureux et bien dur"
    },
    {
      "key": 1576,
      "id": "11-103",
      "translation": "Il y a bien là un signe pour celui qui craint le châtiment de l'au-delà. C'est un jour où les gens seront rassemblés; et c'est un jour solennel (attesté par tous)"
    },
    {
      "key": 1577,
      "id": "11-104",
      "translation": "Et Nous ne le retardons que pour un terme bien déterminé"
    },
    {
      "key": 1578,
      "id": "11-105",
      "translation": "Le jour où cela arrivera, nulle âme ne parlera qu'avec Sa permission (celle d'Allah). Il y aura des damnés et des heureux"
    },
    {
      "key": 1579,
      "id": "11-106",
      "translation": "Ceux qui sont damnés seront dans le Feu où ils ont des soupirs et des sanglots"
    },
    {
      "key": 1580,
      "id": "11-107",
      "translation": "Pour y demeurer éternellement tant que dureront les cieux et la terre - à moins que ton Seigneur en décide autrement - car ton Seigneur fait absolument tout ce qu'Il veut"
    },
    {
      "key": 1581,
      "id": "11-108",
      "translation": "Et quant aux bienheureux, ils seront au Paradis, pour y demeurer éternellement tant que dureront les cieux et la terre - à moins que ton Seigneur n'en décide autrement - c'est là un don qui n'est jamais interrompu"
    },
    {
      "key": 1582,
      "id": "11-109",
      "translation": "Ne sois donc pas en doute au sujet de ce que ceux-là adorent. Ils n'adorent que comme leurs ancêtres adoraient auparavant. Et Nous leur donnerons la totalité de leur part, sans en rien retrancher"
    },
    {
      "key": 1583,
      "id": "11-110",
      "translation": "Et Nous avons déjà donné à Moïse le Livre. Il y eut des divergences à son sujet. S'il n'y avait pas un décret préalable de la part de ton Seigneur, tout aurait été décidé entre eux. Et ils sont, à son sujet pleins d'un doute troublant"
    },
    {
      "key": 1584,
      "id": "11-111",
      "translation": "Très certainement, ton Seigneur fera leur pleine rétribution à tous pour leurs œuvres... Il est Parfaitement Connaisseur de ce qu'ils font"
    },
    {
      "key": 1585,
      "id": "11-112",
      "translation": "Demeure sur le droit chemin comme il t'est commandé, ainsi que ceux qui sont revenus [à Allah] avec toi. Et ne commettez pas d'excès. Car vraiment Il observe ce que vous faites"
    },
    {
      "key": 1586,
      "id": "11-113",
      "translation": "Et ne vous penchez pas vers les injustes: sinon le Feu vous atteindrait. Vous n'avez pas d'alliés en dehors d'Allah. Et vous ne serez pas secourus"
    },
    {
      "key": 1587,
      "id": "11-114",
      "translation": "Et accomplis la Salât aux deux extrémités du jour et à certaines heures de la nuit. Les bonnes œuvres dissipent les mauvaises. Cela est une exhortation pour ceux qui réfléchissent"
    },
    {
      "key": 1588,
      "id": "11-115",
      "translation": "Et sois patient. Car Allah ne laisse pas perdre la récompense des gens bienfaisants"
    },
    {
      "key": 1589,
      "id": "11-116",
      "translation": "Si seulement il existait, dans les générations d'avant vous, des gens vertueux qui interdisent la corruption sur terre! (Hélas) Il n'y en avait qu'un petit nombre que Nous sauvâmes, alors que les injustes persistaient dans le luxe (exagéré) dans lequel ils vivaient, et ils étaient des criminels"
    },
    {
      "key": 1590,
      "id": "11-117",
      "translation": "Et ton Seigneur n'est point tel à détruire injustement des cités dont les habitants sont des réformateurs"
    },
    {
      "key": 1591,
      "id": "11-118",
      "translation": "Et si ton Seigneur avait voulu, Il aurait fait des gens une seule communauté. Or, ils ne cessent d'être en désaccord (entre eux)"
    },
    {
      "key": 1592,
      "id": "11-119",
      "translation": "sauf ceux à qui ton Seigneur a accordé miséricorde. C'est pour cela qu'Il les a créés. Et la parole de ton Seigneur s'accomplit: «Très certainement, Je remplirai l'Enfer de djinns et d'hommes, tous ensemble»"
    },
    {
      "key": 1593,
      "id": "11-120",
      "translation": "Et tout ce que Nous te racontons des récits des messagers, c'est pour en raffermir ton cœur. Et de ceux-ci t'est venue la vérité ainsi qu'une exhortation et un rappel aux croyants"
    },
    {
      "key": 1594,
      "id": "11-121",
      "translation": "Et dis à ceux qui ne croient pas: «Oeuvrez autant que vous pouvez. Nous aussi, nous œuvrons"
    },
    {
      "key": 1595,
      "id": "11-122",
      "translation": "Et attendez. Nous aussi nous attendons!»"
    },
    {
      "key": 1596,
      "id": "11-123",
      "translation": "A Allah appartient l'Inconnaissable des cieux et de la terre, et c'est à Lui que revient l'ordre tout entier. Adore-Le donc et place ta confiance en Lui. Ton Seigneur n'est pas inattentif à ce que vous faites"
    },
    {
      "key": 1597,
      "id": "12-1",
      "translation": "Alif, Lâm, Râ. Tels sont les versets du Livre explicite"
    },
    {
      "key": 1598,
      "id": "12-2",
      "translation": "Nous l'avons fait descendre, un Coran en [langue] arabe, afin que vous raisonniez"
    },
    {
      "key": 1599,
      "id": "12-3",
      "translation": "Nous te racontons le meilleur récit, grâce à la révélation que Nous te faisons dans ce Coran même si tu étais auparavant du nombre des inattentifs (à ces récits)"
    },
    {
      "key": 1600,
      "id": "12-4",
      "translation": "Quand Joseph dit à son père: «O mon père, j'ai vu [en songe], onze étoiles, et aussi le soleil et la lune; je les ai vus prosternés devant moi»"
    },
    {
      "key": 1601,
      "id": "12-5",
      "translation": "«O mon fils, dit-il, ne raconte pas ta vision à tes frères car ils monteraient un complot contre toi; le Diable est certainement pour l'homme un ennemi déclaré"
    },
    {
      "key": 1602,
      "id": "12-6",
      "translation": "Ainsi ton Seigneur te choisira et t'enseignera l'interprétation des rêves, et Il parfera Son bienfait sur toi et sur la famille de Jacob, tout comme Il l'a parfait auparavant sur tes deux ancêtres, Abraham et Isaac, car ton Seigneur est Omniscient et Sage"
    },
    {
      "key": 1603,
      "id": "12-7",
      "translation": "Il y avait certainement, en Joseph et ses frères, des exhortations pour ceux qui interrogent"
    },
    {
      "key": 1604,
      "id": "12-8",
      "translation": "quand ceux-ci dirent: «Joseph et son frère sont plus aimés de notre père que nous, alors que nous sommes un groupe bien fort. Notre père est vraiment dans un tort évident"
    },
    {
      "key": 1605,
      "id": "12-9",
      "translation": "Tuez Joseph ou bien éloignez-le dans n'importe quel pays, afin que le visage de votre père se tourne exclusivement vers vous, et que vous soyez après cela des gens de bien»"
    },
    {
      "key": 1606,
      "id": "12-10",
      "translation": "L'un d'eux dit: «Ne tuez pas Joseph, mais jetez-le si vous êtes disposés à agir, au fond du puits afin que quelque caravane le recueille»"
    },
    {
      "key": 1607,
      "id": "12-11",
      "translation": "Ils dirent: «O notre père, qu'as-tu à ne pas te fier à nous au sujet de Joseph? Nous sommes cependant bien intentionnés à son égard"
    },
    {
      "key": 1608,
      "id": "12-12",
      "translation": "Envoie-le demain avec nous faire une promenade et jouer. Et nous veillerons sur lui»"
    },
    {
      "key": 1609,
      "id": "12-13",
      "translation": "Il dit: «Certes, je m'attristerai que vous l'emmeniez; et je crains que le loup ne le dévore dans un moment où vous ne ferez pas attention à lui»"
    },
    {
      "key": 1610,
      "id": "12-14",
      "translation": "Ils dirent: «Si le loup le dévore alors que nous sommes nombreux, nous serons vraiment les perdants»"
    },
    {
      "key": 1611,
      "id": "12-15",
      "translation": "Et lorsqu'ils l'eurent emmené, et se furent mis d'accord pour le jeter dans les profondeurs invisibles du puits, Nous lui révélâmes: «Tu les informeras sûrement de cette affaire sans qu'ils s'en rendent compte»"
    },
    {
      "key": 1612,
      "id": "12-16",
      "translation": "Et ils vinrent à leur père, le soir, en pleurant"
    },
    {
      "key": 1613,
      "id": "12-17",
      "translation": "Ils dirent: «O notre père, nous sommes allés faire une course, et nous avons laissé Joseph auprès de nos effets; et le loup l'a dévoré. Tu ne nous croiras pas, même si nous disons la vérité»"
    },
    {
      "key": 1614,
      "id": "12-18",
      "translation": "Ils apportèrent sa tunique tachée d'un faux sang. Il dit: «Vos âmes, plutôt, vous ont suggéré quelque chose... [Il ne me reste plus donc] qu'une belle patience! C'est Allah qu'il faut appeler au secours contre ce que vous racontez!»"
    },
    {
      "key": 1615,
      "id": "12-19",
      "translation": "Or, vint une caravane. Ils envoyèrent leur chercheur d'eau, qui fit descendre son seau. Il dit: «Bonne nouvelle! Voilà un garçon!» Et ils le dissimulèrent [pour le vendre] telle une marchandise. Allah cependant savait fort bien ce qu'ils faisaient"
    },
    {
      "key": 1616,
      "id": "12-20",
      "translation": "Et ils le vendirent à vil prix: pour quelques dirhams comptés. Ils le considéraient comme indésirable"
    },
    {
      "key": 1617,
      "id": "12-21",
      "translation": "Et celui qui l'acheta était de l'Egypte. Il dit à sa femme: «Accorde lui une généreuse hospitalité. Il se peut qu'il nous soit utile ou que nous l'adoptions comme notre enfant.» Ainsi avons-nous raffermi Joseph dans le pays et nous lui avons appris l'interprétation des rêves. Et Allah est souverain en Son Commandement: mais la plupart des gens ne savent pas"
    },
    {
      "key": 1618,
      "id": "12-22",
      "translation": "Et quand il eut atteint sa maturité Nous lui accordâmes sagesse et savoir. C'est ainsi que nous récompensons les bienfaisants"
    },
    {
      "key": 1619,
      "id": "12-23",
      "translation": "Or celle [Zulikha] qui l'avait reçu dans sa maison essaya de le séduire. Et elle ferma bien les portes et dit: «Viens, (je suis prête pour toi!)» - Il dit: «Qu'Allah me protège! C'est mon maître qui m'a accordé un bon asile. Vraiment les injustes ne réussissent pas»"
    },
    {
      "key": 1620,
      "id": "12-24",
      "translation": "Et, elle le désira. Et il l'aurait désirée n'eût été ce qu'il vit comme preuve évidente de son Seigneur. Ainsi [Nous avons agi] pour écarter de lui le mal et la turpitude. Il était certes un de Nos serviteurs élus"
    },
    {
      "key": 1621,
      "id": "12-25",
      "translation": "Et tous deux coururent vers la porte, et elle lui déchira sa tunique par derrière. Ils trouvèrent le mari [de cette femme] à la porte. Elle dit: «Quelle serait la punition de quiconque a voulu faire du mal à ta famille, sinon la prison, ou un châtiment douloureux?»"
    },
    {
      "key": 1622,
      "id": "12-26",
      "translation": "[Joseph] dit: «C'est elle qui a voulu me séduire». Et un témoin, de la famille de celle-ci témoigna: «Si sa tunique [à lui] est déchirée par devant, alors c'est elle qui dit la vérité, tandis qu'il est du nombre des menteurs"
    },
    {
      "key": 1623,
      "id": "12-27",
      "translation": "Mais si sa tunique est déchirée par derrière, alors c'est elle qui mentit, tandis qu'il est du nombre des véridiques»"
    },
    {
      "key": 1624,
      "id": "12-28",
      "translation": "Puis, quand il (le mari) vit la tunique déchirée par derrière, il dit: «C'est bien de votre ruse de femmes! Vos ruses sont vraiment énormes"
    },
    {
      "key": 1625,
      "id": "12-29",
      "translation": "Joseph, ne pense plus à cela! Et toi, (femme), implore le pardon pour ton péché car tu es fautive»"
    },
    {
      "key": 1626,
      "id": "12-30",
      "translation": "Et dans la ville, des femmes dirent: «la femme d'Al-'Azize essaye de séduire son valet! Il l'a vraiment rendue folle d'amour. Nous la trouvons certes dans un égarement évident"
    },
    {
      "key": 1627,
      "id": "12-31",
      "translation": "Lorsqu'elle eut entendu leur fourberie, elle leur envoya [des invitations,] et prépara pour elles une collation; et elle remit à chacune d'elles un couteau. Puis elle dit: «Sors devant elles, (Joseph!)» - Lorsqu'elles le virent, elles l'admirèrent, se coupèrent les mains et dirent: «A Allah ne plaise! Ce n'est pas un être humain, ce n'est qu'un ange noble!»"
    },
    {
      "key": 1628,
      "id": "12-32",
      "translation": "Elle dit: «Voilà donc celui à propos duquel vous me blâmiez. J'ai essayé de le séduire mais il s'en défendit fermement. Or, s'il ne fait pas ce que je lui commande, il sera très certainement emprisonné et sera certes parmi les humiliés»"
    },
    {
      "key": 1629,
      "id": "12-33",
      "translation": "Il dit: «O mon Seigneur, la prison m'est préférable à ce à quoi elles m'invitent. Et si Tu n'écartes pas de moi leur ruse, je pencherai vers elles et serai du nombre des ignorants» [des pécheurs]"
    },
    {
      "key": 1630,
      "id": "12-34",
      "translation": "Son Seigneur l'exauça donc, et éloigna de lui leur ruse. C'est Lui, vraiment, qui est l'Audient et l'Omniscient"
    },
    {
      "key": 1631,
      "id": "12-35",
      "translation": "Puis, après qu'ils eurent vu les preuves (de son innocence), il leur sembla qu'ils devaient l'emprisonner pour un temps"
    },
    {
      "key": 1632,
      "id": "12-36",
      "translation": "Deux valets entrèrent avec lui en prison. L'un d'eux dit: «Je me voyais [en rêve] pressant du raisin...» Et l'autre dit: «Et moi, je me voyais portant sur ma tête du pain dont les oiseaux mangeaient. Apprends-nous l'interprétation (de nos rêves), nous te voyons au nombre des bienfaisants»"
    },
    {
      "key": 1633,
      "id": "12-37",
      "translation": "«La nourriture qui vous est attribuée ne vous parviendra point, dit-il, que je ne vous aie avisés de son interprétation [de votre nourriture] avant qu'elle ne vous arrive. Cela fait partie de ce que mon Seigneur m'a enseigné. Certes, j'ai abandonné la religion d'un peuple qui ne croit pas en Allah et qui nie la vie future»"
    },
    {
      "key": 1634,
      "id": "12-38",
      "translation": "Et j'ai suivi la religion de mes ancêtres, Abraham, Isaac et Jacob. Il ne nous convient pas d'associer à Allah quoi que ce soit. Ceci est une grâce d'Allah sur nous et sur tout le monde; mais la plupart des gens ne sont pas reconnaissants"
    },
    {
      "key": 1635,
      "id": "12-39",
      "translation": "O mes deux compagnons de prison! Qui est le meilleur: des Seigneurs éparpillés ou Allah, l'Unique, le Dominateur suprême"
    },
    {
      "key": 1636,
      "id": "12-40",
      "translation": "Vous n'adorez, en dehors de Lui, que des noms que vous avez inventés, vous et vos ancêtres, et à l'appui desquels Allah n'a fait descendre aucune preuve. Le pouvoir n'appartient qu'à Allah. Il vous a commandé de n'adorer que Lui. Telle est la religion droite; mais la plupart des gens ne savent pas"
    },
    {
      "key": 1637,
      "id": "12-41",
      "translation": "O mes deux compagnons de prison! L'un de vous donnera du vin à boire à son maître; quant à l'autre, il sera crucifié, et les oiseaux mangeront de sa tête. L'affaire sur laquelle vous me consultez est déjà décidée.»"
    },
    {
      "key": 1638,
      "id": "12-42",
      "translation": "Et il dit à celui des deux dont il pensait qu'il serait délivré: «Parle de moi auprès de ton maître». Mais le Diable fit qu'il oublia de rappeler (le cas de Joseph) à son maître. Joseph resta donc en prison quelques années"
    },
    {
      "key": 1639,
      "id": "12-43",
      "translation": "Et le roi dit: «En vérité, je voyais (en rêve) sept vaches grasses mangées par sept maigres; et sept épis verts, et autant d'autres, secs. O conseil de notables, donnez-moi une explication de ma vision, si vous savez interpréter le rêve»"
    },
    {
      "key": 1640,
      "id": "12-44",
      "translation": "Ils dirent: «C'est un amas de rêves! Et nous ne savons pas interpréter les rêves!»"
    },
    {
      "key": 1641,
      "id": "12-45",
      "translation": "Or, celui des deux qui avait été délivré et qui, après quelque temps se rappela, dit: «Je vous en donnerai l'interprétation. Envoyez-moi donc»"
    },
    {
      "key": 1642,
      "id": "12-46",
      "translation": "«O toi, Joseph, le véridique! Eclaire-nous au sujet de sept vaches grasses que mangent sept très maigres, et sept épis verts et autant d'autres, secs, afin que je retourne aux gens et qu'ils sachent [l'interprétation exacte du rêve]»"
    },
    {
      "key": 1643,
      "id": "12-47",
      "translation": "Alors [Joseph dit]: «Vous sèmerez pendant sept années consécutives. Tout ce que vous aurez moissonné, laissez-le en épi, sauf le peu que vous consommerez"
    },
    {
      "key": 1644,
      "id": "12-48",
      "translation": "Viendront ensuite sept années de disette qui consommeront tout ce que vous aurez amassé pour elles sauf le peu que vous aurez réservé [comme semence]"
    },
    {
      "key": 1645,
      "id": "12-49",
      "translation": "Puis, viendra après cela une année où les gens seront secourus [par la pluie] et iront au pressoir.»"
    },
    {
      "key": 1646,
      "id": "12-50",
      "translation": "Et le roi dit: «Amenez-le moi». Puis, lorsque l'émissaire arriva auprès de lui, [Joseph] dit: «Retourne auprès de ton maître et demande-lui: «Quelle était la raison qui poussa les femmes à se couper les mains? Mon Seigneur connaît bien leur ruse»"
    },
    {
      "key": 1647,
      "id": "12-51",
      "translation": "Alors, [le roi leur] dit: «Qu'est-ce donc qui vous a poussées à essayer de séduire Joseph?» Elles dirent: «A Allah ne plaise! Nous ne connaissons rien de mauvais contre lui». Et la femme d'Al-'Azize dit: «Maintenant la vérité s'est manifestée. C'est moi qui ai voulu le séduire. Et c'est lui, vraiment, qui est du nombre des véridiques!»"
    },
    {
      "key": 1648,
      "id": "12-52",
      "translation": "«Cela afin qu'il sache que je ne l'ai pas trahi en son absence, et qu'en vérité Allah ne guide pas la ruse des traîtres"
    },
    {
      "key": 1649,
      "id": "12-53",
      "translation": "Je ne m'innocente cependant pas, car l'âme est très incitatrice au mal, à moins que mon Seigneur, par miséricorde, [ne la préserve du péché]. Mon Seigneur est certes Pardonneur et très Miséricordieux»"
    },
    {
      "key": 1650,
      "id": "12-54",
      "translation": "Et le roi dit: «Amenez-le moi: je me le réserve pour moi-même». Et lorsqu'il lui eut parlé, il dit: «Tu es dès aujourd'hui près de nous, en une position d'autorité et de confiance»"
    },
    {
      "key": 1651,
      "id": "12-55",
      "translation": "Et [Joseph] dit: «Assigne-moi les dépôts du territoire: je suis bon gardien et connaisseur»"
    },
    {
      "key": 1652,
      "id": "12-56",
      "translation": "Ainsi avons-nous affermi (l'autorité de) Joseph dans ce territoire et il s'y installait là où il le voulait. Nous touchons de Notre miséricorde qui Nous voulons et ne faisons pas perdre aux hommes de bien le mérite [de leurs œuvres]"
    },
    {
      "key": 1653,
      "id": "12-57",
      "translation": "Et la récompense de l'au-delà est meilleure pour ceux qui ont cru et ont pratiqué la piété"
    },
    {
      "key": 1654,
      "id": "12-58",
      "translation": "Et les frères de Joseph vinrent et entrèrent auprès de lui. Il les reconnut, mais eux ne le reconnurent pas"
    },
    {
      "key": 1655,
      "id": "12-59",
      "translation": "Et quand il leur eut fourni leur provision, il dit: «Amenez-moi un frère que vous avez de votre père. Ne voyez-vous pas que je donne la pleine mesure et que je suis le meilleur des hôtes"
    },
    {
      "key": 1656,
      "id": "12-60",
      "translation": "Et si vous ne me l'amenez pas, alors il n'y aura plus de provision pour vous, chez moi; et vous ne m'approcherez plus»"
    },
    {
      "key": 1657,
      "id": "12-61",
      "translation": "Ils dirent: «Nous essayerons de persuader son père. Certes, nous le ferons»"
    },
    {
      "key": 1658,
      "id": "12-62",
      "translation": "Et il dit à ses serviteurs: «Remettez leurs marchandises dans leurs sacs: peut-être les reconnaîtront-ils quand ils seront de retour vers leur famille et peut-être qu'ils reviendront»"
    },
    {
      "key": 1659,
      "id": "12-63",
      "translation": "Et lorsqu'ils revinrent à leur père, ils dirent: «O notre père, il nous sera refusé [à l'avenir] de nous ravitailler [en grain]. Envoie donc avec nous notre frère, afin que nous obtenions des provisions. Nous le surveillerons bien»"
    },
    {
      "key": 1660,
      "id": "12-64",
      "translation": "Il dit: «Vais-je vous le confier comme, auparavant, je vous ai confié son frère? Mais Allah est le meilleur gardien, et Il est Le plus Miséricordieux des miséricordieux!»"
    },
    {
      "key": 1661,
      "id": "12-65",
      "translation": "Et lorsqu'ils ouvrirent leurs bagages, ils trouvèrent qu'on leur avait rendu leurs marchandises. Ils dirent: «O notre père. Que désirons-nous [de plus]? Voici que nos marchandises nous ont été rendues. Et ainsi nous approvisionnerons notre famille, nous veillerons à la sécurité de notre frère et nous nous ajouterons la charge d'un chameau et c'est une charge facile»"
    },
    {
      "key": 1662,
      "id": "12-66",
      "translation": "Il dit: «Jamais je ne l'enverrai avec vous, jusqu'à ce que vous m'apportiez l'engagement formel au nom d'Allah que vous me le ramènerez à moins que vous ne soyez cernés». Lorsqu'ils lui eurent apporté l'engagement, il dit: «Allah est garant de ce que nous disons»"
    },
    {
      "key": 1663,
      "id": "12-67",
      "translation": "Et il dit: «O mes fils, n'entrez pas par une seule porte, mais entrez par portes séparées. Je ne peux cependant vous être d'aucune utilité contre les desseins d'Allah. La décision n'appartient qu'à Allah: en Lui je place ma confiance. Et que ceux qui placent leur confiance la placent en Lui»"
    },
    {
      "key": 1664,
      "id": "12-68",
      "translation": "Etant entrés comme leur père le leur avait commandé [cela] ne leur servit à rien contre (les décrets d') Allah. Ce n'était [au reste] qu'une précaution que Jacob avait jugé [de leur recommander]. Il avait pleine connaissance de ce que Nous lui avions enseigné. Mais la plupart des gens ne savent pas"
    },
    {
      "key": 1665,
      "id": "12-69",
      "translation": "Et quand ils furent entrés auprès de Joseph, [celui-ci] retint son frère auprès de lui en disant: «Je suis ton frère. Ne te chagrine donc pas pour ce qu'ils faisaient»"
    },
    {
      "key": 1666,
      "id": "12-70",
      "translation": "Puis, quand il leur eut fourni leurs provisions, il mit la coupe dans le sac de son frère. Ensuite un crieur annonça: «Caravaniers! vous êtes des voleurs»"
    },
    {
      "key": 1667,
      "id": "12-71",
      "translation": "Ils se retournèrent en disant: «Qu'avez-vous perdu?»"
    },
    {
      "key": 1668,
      "id": "12-72",
      "translation": "Ils répondirent: «Nous cherchons la grande coupe du roi. La charge d'un chameau à qui l'apportera et j'en suis garant»"
    },
    {
      "key": 1669,
      "id": "12-73",
      "translation": "«Par Allah, dirent-ils, vous savez certes que nous ne sommes pas venus pour semer la corruption sur le territoire et que nous ne sommes pas des voleurs»"
    },
    {
      "key": 1670,
      "id": "12-74",
      "translation": "Quelle sera donc la sanction si vous êtes des menteurs? (dirent-ils)"
    },
    {
      "key": 1671,
      "id": "12-75",
      "translation": "Ils dirent: «La sanction infligée à celui dont les bagages de qui la coupe sera retrouvée est: [qu'il soit livré] lui-même [à titre d'esclave à la victime du vol]. C'est ainsi que nous punissons les malfaiteurs»"
    },
    {
      "key": 1672,
      "id": "12-76",
      "translation": "[Joseph] commença par les sacs des autres avant celui de son frère; puis il la fit sortir du sac de son frère. Ainsi suggérâmes-Nous cet artifice à Joseph. Car il ne pouvait pas se saisir de son frère, selon la justice du roi, à moins qu'Allah ne l'eût voulu. Nous élevons en rang qui Nous voulons. Et au-dessus de tout homme détenant la science il y a un savant [plus docte que lui]"
    },
    {
      "key": 1673,
      "id": "12-77",
      "translation": "Ils dirent: «S'il a commis un vol, un frère à lui auparavant a volé aussi.» Mais Joseph tint sa pensée secrète, et ne la leur dévoila pas. Il dit [en lui même]: «Votre position est bien pire encore! Et Allah connaît mieux ce que vous décrivez.»"
    },
    {
      "key": 1674,
      "id": "12-78",
      "translation": "Ils dirent. «O Al-'Azize, il a un père très vieux; saisis-toi donc de l'un de nous, à sa place. Nous voyons que tu es vraiment du nombre des gens bienfaisants»"
    },
    {
      "key": 1675,
      "id": "12-79",
      "translation": "Il dit: «Qu'Allah nous garde de prendre un autre que celui chez qui nous avons trouvé notre bien! Nous serions alors vraiment injustes.»"
    },
    {
      "key": 1676,
      "id": "12-80",
      "translation": "Puis, lorsqu'ils eurent perdu tout espoir [de ramener Benyamin] ils se concertèrent en secret. Leur aîné dit: «Ne savez-vous pas que votre père a pris de vous un engagement formel au nom d'Allah, et que déjà vous y avez manqué autrefois à propos de Joseph? Je ne quitterai point le territoire, jusqu'à ce que mon père me le permette ou qu'Allah juge en ma faveur, et Il est le meilleur des juges"
    },
    {
      "key": 1677,
      "id": "12-81",
      "translation": "Retournez à votre père et dites: «O notre père, ton fils a volé. Et nous n'attestons que ce que nous savons. Et nous n'étions nullement au courant de l'inconnu"
    },
    {
      "key": 1678,
      "id": "12-82",
      "translation": "Et interroge la ville où nous étions, ainsi que la caravane dans laquelle nous sommes arrivés. Nous disons réellement la vérité.»"
    },
    {
      "key": 1679,
      "id": "12-83",
      "translation": "Alors [Jacob] dit: Vos âmes plutôt vous ont inspiré [d'entreprendre] quelque chose!... Oh! belle patience. Il se peut qu'Allah me les ramènera tous les deux. Car c'est Lui l'Omniscient, le Sage»"
    },
    {
      "key": 1680,
      "id": "12-84",
      "translation": "Et il se détourna d'eux et dit: «Que mon chagrin est grand pour Joseph!» Et ses yeux blanchirent d'affliction. Et il était accablé"
    },
    {
      "key": 1681,
      "id": "12-85",
      "translation": "Ils dirent: «Par Allah! Tu ne cesseras pas d'évoquer Joseph, jusqu'à ce que tu t'épuises ou que tu sois parmi les morts»"
    },
    {
      "key": 1682,
      "id": "12-86",
      "translation": "Il dit: «Je ne me plains qu'à Allah de mon déchirement et de mon chagrin. Et, je sais de la part d'Allah, ce que vous ne savez pas"
    },
    {
      "key": 1683,
      "id": "12-87",
      "translation": "O mes fils! Partez et enquérez-vous de Joseph et de son frère. Et ne désespérez pas de la miséricorde d'Allah. Ce sont seulement les gens mécréants qui désespèrent de la miséricorde d'Allah»"
    },
    {
      "key": 1684,
      "id": "12-88",
      "translation": "Et lorsqu'ils s'introduisirent auprès de [Joseph,] ils dirent: «O al-'Azize, la famine nous a touchés, nous et notre famille; et nous venons avec une marchandise sans grande valeur. Donne-nous une pleine mesure, et fais-nous la charité. Certes, Allah récompense les charitables!»"
    },
    {
      "key": 1685,
      "id": "12-89",
      "translation": "Il dit: «Savez-vous ce que vous avez fait de Joseph et de son frère alors que vous étiez ignorants? [injustes]»"
    },
    {
      "key": 1686,
      "id": "12-90",
      "translation": "Ils dirent: «Est-ce que tu es... Certes, tu es Joseph!» - Il dit: «Je suis Joseph, et voici mon frère. Certes, Allah nous a favorisés. Quiconque craint et patiente... Et très certainement, Allah ne fait pas perdre la récompense des bienfaisants»"
    },
    {
      "key": 1687,
      "id": "12-91",
      "translation": "Ils dirent: «Par Allah! Vraiment Allah t'a préféré à nous et nous avons été fautifs»"
    },
    {
      "key": 1688,
      "id": "12-92",
      "translation": "Il dit: «Pas de récrimination contre vous aujourd'hui! Qu'Allah vous pardonne. C'est Lui Le plus Miséricordieux des miséricordieux"
    },
    {
      "key": 1689,
      "id": "12-93",
      "translation": "Emportez ma tunique que voici, et appliquez-la sur le visage de mon père: il recouvrera [aussitôt] la vue. Et amenez-moi toute votre famille»"
    },
    {
      "key": 1690,
      "id": "12-94",
      "translation": "Et dès que la caravane franchit la frontière [de Canâan], leur père dit: «Je décèle, certes, l'odeur de Joseph, même si vous dites que je radote»"
    },
    {
      "key": 1691,
      "id": "12-95",
      "translation": "Ils Lui dirent: «Par Allah te voilà bien dans ton ancien égarement»"
    },
    {
      "key": 1692,
      "id": "12-96",
      "translation": "Puis quand arriva le porteur de bonne annonce, il l'appliqua [la tunique] sur le visage de Jacob. Celui-ci recouvra [aussitôt] la vue, et dit: «Ne vous ai-je pas dit que je sais, par Allah, ce que vous ne savez pas?»"
    },
    {
      "key": 1693,
      "id": "12-97",
      "translation": "Ils dirent: «O notre père, implore pour nous la rémission de nos péchés. Nous étions vraiment fautifs»"
    },
    {
      "key": 1694,
      "id": "12-98",
      "translation": "Il dit: «J'implorerai pour vous le pardon de mon Seigneur. Car c'est Lui le Pardonneur, le Très Miséricordieux»"
    },
    {
      "key": 1695,
      "id": "12-99",
      "translation": "Lorsqu'ils s'introduisirent auprès de Joseph, celui-ci accueillit ses père et mère, et leur dit: «Entrez en Egypte, en toute sécurité, si Allah le veut!»"
    },
    {
      "key": 1696,
      "id": "12-100",
      "translation": "Et il éleva ses parents sur le trône, et tous tombèrent devant lui, prosternés. Et il dit: «O mon père, voilà l'interprétation de mon rêve de jadis. Allah l'a bel et bien réalisé... Et Il m'a certainement fait du bien quand Il m'a fait sortir de prison et qu'Il vous a fait venir de la campagne, [du désert], après que le Diable ait suscité la discorde entre mes frères et moi. Mon Seigneur est plein de douceur pour ce qu'Il veut. Et c'est Lui l'Omniscient, le Sage"
    },
    {
      "key": 1697,
      "id": "12-101",
      "translation": "O mon Seigneur, Tu m'as donné du pouvoir et m'as enseigné l'interprétation des rêves. [C'est Toi Le] Créateur des cieux et de la terre, Tu es mon patron, ici-bas et dans l'au-delà. Fais-moi mourir en parfaite soumission et fais moi rejoindre les vertueux"
    },
    {
      "key": 1698,
      "id": "12-102",
      "translation": "Ce sont là des récits inconnus que Nous te révélons. Et tu n'étais pas auprès d'eux quand ils se mirent d'accord pour comploter"
    },
    {
      "key": 1699,
      "id": "12-103",
      "translation": "Et la plupart des gens ne sont pas croyants malgré ton désir ardent"
    },
    {
      "key": 1700,
      "id": "12-104",
      "translation": "Et tu ne leur demandes aucun salaire pour cela. Ce n'est là qu'un rappel adressé à l'univers"
    },
    {
      "key": 1701,
      "id": "12-105",
      "translation": "Et dans les cieux et sur la terre, que de signes auprès desquels les gens passent, en s'en détournant"
    },
    {
      "key": 1702,
      "id": "12-106",
      "translation": "Et la plupart d'entre eux ne croient en Allah, qu'en lui donnant des associés"
    },
    {
      "key": 1703,
      "id": "12-107",
      "translation": "Est-ce qu'ils sont sûrs que le châtiment d'Allah ne viendra pas les couvrir ou que l'Heure ne leur viendra pas soudainement, sans qu'ils s'en rendent compte"
    },
    {
      "key": 1704,
      "id": "12-108",
      "translation": "Dis: «Voici ma voie, j'appelle les gens à [la religion] d'Allah, moi et ceux qui me suivent, nous basant sur une preuve évidente. Gloire à Allah! Et je ne suis point du nombre des associateurs"
    },
    {
      "key": 1705,
      "id": "12-109",
      "translation": "Nous n'avons envoyé avant toi que des hommes originaires des cités, à qui Nous avons fait des révélations. [Ces gens là] n'ont-ils pas parcouru la terre et considéré quelle fut la fin de ceux qui ont vécu avant eux? La demeure de l'au-delà est assurément meilleure pour ceux qui craignent [Allah]. Ne raisonnerez-vous donc pas"
    },
    {
      "key": 1706,
      "id": "12-110",
      "translation": "Quand les messagers faillirent perdre espoir (et que leurs adeptes) eurent pensé qu'ils étaient dupés voilà que vint à eux Notre secours. Et furent sauvés ceux que Nous voulûmes. Mais Notre rigueur ne saurait être détournée des gens criminels"
    },
    {
      "key": 1707,
      "id": "12-111",
      "translation": "Dans leurs récits il y a certes une leçon pour les gens doués d'intelligence. Ce n'est point là un récit fabriqué. C'est au contraire la confirmation de ce qui existait déjà avant lui, un exposé détaillé de toute chose, un guide et une miséricorde pour des gens qui croient"
    },
    {
      "key": 1708,
      "id": "13-1",
      "translation": "Alif, Lâm, Mîm, Râ. Voici les versets du Livre; et ce qui t'a été révélé par ton Seigneur est la vérité; mais la plupart des gens ne croient pas"
    },
    {
      "key": 1709,
      "id": "13-2",
      "translation": "Allah est Celui qui a élevé [bien haut] les cieux sans piliers visibles. Il S'est établi [istawâ] sur le Trône et a soumis le soleil et la lune, chacun poursuivant sa course vers un terme fixé. Il règle l'Ordre [de tout] et expose en détail les signes afin que vous ayez la certitude de la rencontre de votre Seigneur"
    },
    {
      "key": 1710,
      "id": "13-3",
      "translation": "Et c'est Lui qui a étendu la terre et y a placé montagnes et fleuves. Et de chaque espèce de fruits Il y établit deux éléments de couple. Il fait que la nuit couvre le jour. Voilà bien là des preuves pour des gens qui réfléchissent"
    },
    {
      "key": 1711,
      "id": "13-4",
      "translation": "Et sur la terre il y a des parcelles voisines les unes des autres, des jardins [plantés] de vignes, et des céréales et des palmiers, en touffes ou espacés, arrosés de la même eau, cependant Nous rendons supérieurs les uns aux autres quant au goût. Voilà bien là des preuves pour des gens qui raisonnent"
    },
    {
      "key": 1712,
      "id": "13-5",
      "translation": "Et si tu dois t'étonner, rien de plus étonnant que leurs dires: «Quand nous seront poussière, reviendrons-nous vraiment à une nouvelle création?» Ceux-là sont ceux qui ne croient pas en leur Seigneur. Et ce sont eux qui auront des jougs à leur cou. Et ce sont eux les gens du Feu, où ils demeureront éternellement"
    },
    {
      "key": 1713,
      "id": "13-6",
      "translation": "Et ils te demandent de hâter [la venue] du malheur plutôt que celle du bonheur. Certes, il s'est produit avant eux des châtiments exemplaires. Ton Seigneur est Détenteur du pardon pour les gens, malgré leurs méfaits. Et ton Seigneur est assurément dur en punition"
    },
    {
      "key": 1714,
      "id": "13-7",
      "translation": "Et ceux qui ont mécru disent: «Pourquoi n'a-t-on pas fait descendre sur celui-ci  un miracle venant de son Seigneur?» Tu n'es qu'un avertisseur, et à chaque peuple un guide"
    },
    {
      "key": 1715,
      "id": "13-8",
      "translation": "Allah sait ce que porte chaque femelle, et de combien la période de gestation dans la matrice est écourtée ou prolongée. Et toute chose a auprès de Lui sa mesure"
    },
    {
      "key": 1716,
      "id": "13-9",
      "translation": "Le Connaisseur de ce qui est caché et de ce qui est apparent, Le Grand, Le Sublime"
    },
    {
      "key": 1717,
      "id": "13-10",
      "translation": "Sont égaux pour lui, celui parmi vous qui tient secrète sa parole, et celui qui la divulgue, celui qui se cache la nuit comme celui qui se montre au grand jour"
    },
    {
      "key": 1718,
      "id": "13-11",
      "translation": "Il [l'homme] a par devant lui et derrière lui des Anges qui se relaient et qui veillent sur lui par ordre d'Allah. En vérité, Allah ne modifie point l'état d'un peuple, tant que les [individus qui le composent] ne modifient pas ce qui est en eux-mêmes. Et lorsqu'Allah veut [infliger] un mal à un peuple, nul ne peut le repousser: ils n'ont en dehors de Lui aucun protecteur"
    },
    {
      "key": 1719,
      "id": "13-12",
      "translation": "C'est lui qui vous fait voir l'éclair [qui vous inspire] crainte et espoir; et Il crée les nuages lourds"
    },
    {
      "key": 1720,
      "id": "13-13",
      "translation": "Le tonnerre Le glorifie par Sa louange, et aussi les Anges, sous l'effet de Sa crainte. Et Il lance les foudres dont Il atteint qui Il veut. Or ils disputent au sujet d'Allah alors qu'Il est redoutable en Sa force"
    },
    {
      "key": 1721,
      "id": "13-14",
      "translation": "A Lui l'appel de la Vérité! Ceux qu'ils invoquent en dehors de Lui ne leur répondent d'aucune façon; semblables à celui qui étend ses deux mains vers l'eau pour la porter à sa bouche, mais qui ne parvient jamais à l'atteindre. L'invocation des mécréants n'est que vanité"
    },
    {
      "key": 1722,
      "id": "13-15",
      "translation": "Et c'est à Allah que se prosternent, bon gré mal gré, tous ceux qui sont dans les cieux et sur la terre, ainsi que leurs ombres, au début et à la fin de la journée"
    },
    {
      "key": 1723,
      "id": "13-16",
      "translation": "Dis: «Qui est le Seigneur des cieux et de la terre?» Dis: «Allah». Dis: «Et prendrez-vous en dehors de Lui, des maîtres qui ne détiennent pour eux-mêmes ni bien ni mal?» Dis: «Sont-ils égaux, l'aveugle et celui qui voit? Ou sont-elles égales, les ténèbres et la lumière? Ou donnent-ils à Allah des associés qui créent comme Sa création au point que les deux créations se soient confondues à eux? Dis: «Allah est le Créateur de toute chose, et c'est Lui l'Unique, le Dominateur suprême»"
    },
    {
      "key": 1724,
      "id": "13-17",
      "translation": "Il a fait descendre une eau du ciel à laquelle des vallées servent de lit, selon leur grandeur. Le flot débordé a charrié une écume flottante; et semblable à celle-ci est [l'] écume provenant de ce qu'on porte à fusion, dans le feu pour [fabriquer] des bijoux et des ustensiles. Ainsi Allah représente en parabole la Vérité et le Faux: l'écume [du torrent et du métal fondu] s'en va, au rebut, tandis que [l'eau et les objets] utiles aux Hommes demeurent sur la terre. Ainsi Allah propose des paraboles"
    },
    {
      "key": 1725,
      "id": "13-18",
      "translation": "La meilleure [fin] est pour ceux qui répondent à [l'appel] de leur Seigneur. Et quant à ceux qui ne Lui répondent pas, s'ils avaient tout ce qui est sur la terre, et autant encore, ils l'offriraient en rançon. Ceux-là auront le détestable rendement de compte et l'Enfer sera leur refuge. Quel détestable lit de repos"
    },
    {
      "key": 1726,
      "id": "13-19",
      "translation": "Celui qui sait que ce qui t'est révélé de la part de ton Seigneur est la vérité, est-il semblable à l'aveugle? Seuls les gens doués d'intelligence réfléchissent bien"
    },
    {
      "key": 1727,
      "id": "13-20",
      "translation": "ceux qui remplissent leur engagement envers Allah et ne violent pas le pacte"
    },
    {
      "key": 1728,
      "id": "13-21",
      "translation": "qui unissent ce qu'Allah a commandé d'unir, redoutent leur Seigneur et craignent une malheureuse reddition de compte"
    },
    {
      "key": 1729,
      "id": "13-22",
      "translation": "et qui endurent dans la recherche de l'agrément d'Allah, accomplissent la Salât et dépensent (dans le bien), en secret et en public, de ce que Nous leur avons attribué, et repoussent le mal par le bien. A ceux-là, la bonne demeure finale"
    },
    {
      "key": 1730,
      "id": "13-23",
      "translation": "les jardins d'Eden, où ils entreront, ainsi que tous ceux de leurs ascendants, conjoints et descendants, qui ont été de bons croyants. De chaque porte, les Anges entreront auprès d'eux"
    },
    {
      "key": 1731,
      "id": "13-24",
      "translation": "«Paix sur vous, pour ce que vous avez enduré!» - Comme est bonne votre demeure finale!»"
    },
    {
      "key": 1732,
      "id": "13-25",
      "translation": "[Mais] ceux qui violent leur pacte avec Allah après l'avoir engagé, et rompent ce qu'Allah a commandé d'unir et commettent le désordre sur terre, auront la malédiction et la mauvaise demeure"
    },
    {
      "key": 1733,
      "id": "13-26",
      "translation": "Allah étend largement Ses dons ou [les] restreint à qui Il veut. Ils se réjouissent de la vie sur terre, mais la vie d'ici-bas ne paraîtra que comme une jouissance éphémère en comparaison de l'au-delà"
    },
    {
      "key": 1734,
      "id": "13-27",
      "translation": "Ceux qui ont mécru disent: «Pourquoi n'a-t-on pas descendu sur lui  un miracle venant de son Seigneur?» Dis: «En vérité, Allah égare qui Il veut; et Il guide vers Lui celui qui se repent"
    },
    {
      "key": 1735,
      "id": "13-28",
      "translation": "ceux qui ont cru, et dont les cœurs se tranquillisent à l'évocation d'Allah». Certes, c'est par l'évocation d'Allah que les cœurs se tranquillisent"
    },
    {
      "key": 1736,
      "id": "13-29",
      "translation": "Ceux qui croient et font de bonnes œuvres, auront le plus grand bien et aussi le plus bon retour"
    },
    {
      "key": 1737,
      "id": "13-30",
      "translation": "Ainsi Nous t'envoyons dans une communauté - que d'autres communautés ont précédée - pour que tu leur récites ce que Nous te révélons [le Coran], cependant qu'ils ne croient pas au Tout Miséricordieux. Dis: «C'est Lui mon Seigneur. Pas d'autre divinité à part Lui. En Lui je place ma confiance. Et à Lui je me repens»"
    },
    {
      "key": 1738,
      "id": "13-31",
      "translation": "S'il y avait un Coran à mettre les montagnes en marche, à fendre la terre ou à faire parler les morts (ce serait celui-ci). C'est plutôt à Allah le commandement tout entier. Les croyants ne savent-ils pas que, si Allah voulait, Il aurait dirigé tous les hommes vers le droit chemin. Cependant, ceux qui ne croient pas ne manqueront pas, pour prix de ce qu'ils font, d'être frappés par un cataclysme, ou [qu'un cataclysme] s'abattra près de leurs demeures jusqu'à ce que vienne la promesse d'Allah. Car Allah, ne manque pas à Sa promesse"
    },
    {
      "key": 1739,
      "id": "13-32",
      "translation": "On s'est certes moqué des messagers avant toi. Alors, J'ai donné un répit aux mécréants. Ensuite, Je les ai saisis. Et quel fut Mon châtiment"
    },
    {
      "key": 1740,
      "id": "13-33",
      "translation": "Est-ce que Celui qui observe ce que chaque âme acquiert [est semblable aux associés?...] Et pourtant ils donnent des associés à Allah. Dis [leur:] «Nommez-les. Ou essayez-vous de Lui apprendre ce qu'Il ne connaît pas sur la terre? Ou avez-vous été simplement séduits par de faux noms?» En fait, on a embelli aux mécréants leur stratagème et on les a empêchés de prendre le droit chemin. Et quiconque Allah laisse égarer, n'a plus personne pour le guider"
    },
    {
      "key": 1741,
      "id": "13-34",
      "translation": "Un châtiment les atteindra dans la vie présente. Le châtiment de l'au-delà sera cependant plus écrasant et ils n'auront nul protecteur contre Allah"
    },
    {
      "key": 1742,
      "id": "13-35",
      "translation": "Tel est le paradis qui a été promis aux pieux: sous lequel coulent les ruisseaux; ses fruits sont perpétuels, ainsi que son ombrage. Voilà la fin de ceux qui pratiquent la piété, tandis que la fin des mécréants sera le Feu"
    },
    {
      "key": 1743,
      "id": "13-36",
      "translation": "Et ceux à qui Nous avons déjà donné le Livre se réjouissent de ce qu'on a fait descendre vers toi. Tandis que certaines factions en rejettent une partie. Dis: «Il m'a seulement été commandé d'adorer Allah et de ne rien Lui associer. C'est à Lui que j'appelle [les gens], Et c'est vers Lui que sera mon retour»"
    },
    {
      "key": 1744,
      "id": "13-37",
      "translation": "Ainsi l'avons-Nous fait descendre (le Coran) [sous forme] de loi en arabe. Et si tu suis leurs passions après ce que tu as reçu comme savoir, il n'y aura pour toi, contre Allah, ni allié ni protecteur"
    },
    {
      "key": 1745,
      "id": "13-38",
      "translation": "Et Nous avons certes envoyé avant toi des messagers, et leur avons donné des épouses et des descendants. Et il n'appartient pas à un Messager d'apporter un miracle, si ce n'est qu'avec la permission d'Allah. Chaque échéance a son terme prescrit"
    },
    {
      "key": 1746,
      "id": "13-39",
      "translation": "Allah efface ou confirme ce qu'Il veut et l'Ecriture primordiale est auprès de Lui"
    },
    {
      "key": 1747,
      "id": "13-40",
      "translation": "Que Nous te fassions voir une partie de ce dont Nous les menaçons, ou que Nous te fassions mourir (avant cela), ton devoir est seulement la communication du message, et le règlement de compte sera à Nous"
    },
    {
      "key": 1748,
      "id": "13-41",
      "translation": "Ne voient-ils pas que Nous frappons la terre et que Nous la réduisons de tous côtés? C'est Allah qui juge et personne ne peut s'opposer à Son jugement, et Il est prompt à régler les comptes"
    },
    {
      "key": 1749,
      "id": "13-42",
      "translation": "Certes ceux d'avant eux ont manigancé (contre leur Messager); le stratagème tout entier appartient à Allah. Il sait ce que chaque âme acquiert. Et les mécréants sauront bientôt à qui appartient la bonne demeure finale"
    },
    {
      "key": 1750,
      "id": "13-43",
      "translation": "Et ceux qui ne croient pas disent: «Tu n'es pas un Messager». Dis: «Allah suffit, comme témoin entre vous et moi, et ceux qui ont la connaissance du Livre (sont aussi témoins)»"
    },
    {
      "key": 1751,
      "id": "14-1",
      "translation": "Alif, Lâm, Râ. (Voici) un livre que nous avons fait descendre sur toi, afin que - par la permission de leur Seigneur - tu fasses sortir les gens des ténèbres vers la lumière, sur la voie du Tout Puissant, du Digne de louange"
    },
    {
      "key": 1752,
      "id": "14-2",
      "translation": "Allah, à qui appartient tout ce qui est dans les cieux et sur la terre. Et malheur aux mécréants, pour un dur châtiment [qu'ils subiront]"
    },
    {
      "key": 1753,
      "id": "14-3",
      "translation": "Ceux qui préfèrent la vie d'ici-bas à l'au-delà, obstruent [aux gens] le chemin d'Allah et cherchent à le rendre tortueux, ceux-là sont loin dans l'égarement"
    },
    {
      "key": 1754,
      "id": "14-4",
      "translation": "Et Nous n'avons envoyé de Messager qu'avec la langue de son peuple, afin de les éclairer. Allah égare qui Il veut et guide qui Il veut. Et, c'est Lui le Tout Puissant, le Sage"
    },
    {
      "key": 1755,
      "id": "14-5",
      "translation": "Nous avons certes, envoyé Moïse avec Nos miracles [en lui disant]: «Fais sortir ton peuple des ténèbres vers la lumière, et rappelle-leur les jours d'Allah». [Ses bienfaits]. Dans tout cela il y a des signes pour tout homme plein d'endurance et de reconnaissance"
    },
    {
      "key": 1756,
      "id": "14-6",
      "translation": "(Rappelle-toi) quand Moïse dit à son peuple: «Rappelez-vous le bienfait d'Allah sur vous quand Il vous sauva des gens de Pharaon qui vous infligeaient le pire châtiment. Ils massacraient vos fils et laissaient en vie vos filles. Il y avait là une dure épreuve de la part de votre Seigneur»"
    },
    {
      "key": 1757,
      "id": "14-7",
      "translation": "Et lorsque votre Seigneur proclama: «Si vous êtes reconnaissants, très certainement J'augmenterai [Mes bienfaits] pour vous. Mais si vous êtes ingrats, Mon châtiment sera terrible»"
    },
    {
      "key": 1758,
      "id": "14-8",
      "translation": "Et Moïse dit: «Si vous êtes ingrats, vous ainsi que tous ceux qui sont sur terre, [sachez] qu'Allah Se suffit à Lui-même et qu'Il est digne de louange"
    },
    {
      "key": 1759,
      "id": "14-9",
      "translation": "Ne vous est-il pas parvenu le récit de ceux d'avant vous, du peuple de Noé, des `Aad, des Thamud et de ceux qui vécurent après eux, et que seul Allah connaît? Leurs messagers vinrent à eux avec des preuves, mais ils dirent, ramenant leurs mains à leurs bouches: «Nous ne croyons pas [au message] avec lequel vous avez été envoyés et nous sommes, au sujet de ce à quoi vous nous appelez, dans un doute vraiment troublant»"
    },
    {
      "key": 1760,
      "id": "14-10",
      "translation": "Leurs messagers dirent: «Y a-t-il un doute au sujet d'Allah, Créateur des cieux et de la terre, qui vous appelle pour vous pardonner une partie de vos péchés et vous donner un délai jusqu'à un terme fixé?» [Les mécréants] répondirent: «Vous n'êtes que des hommes comme nous. Vous voulez nous empêcher de ce que nos ancêtres adoraient. Apportez-nous donc une preuve évidente»"
    },
    {
      "key": 1761,
      "id": "14-11",
      "translation": "Leurs messagers leur dirent: «Certes, nous ne sommes que des humains comme vous. Mais Allah favorise qui Il veut parmi Ses serviteurs. Il ne nous appartient de vous apporter quelque preuve, que par la permission d'Allah. Et c'est en Allah que les croyants doivent placer leur confiance"
    },
    {
      "key": 1762,
      "id": "14-12",
      "translation": "Et qu'aurions-nous à ne pas placer notre confiance en Allah, alors qu'Il nous a guidés sur les sentiers [que nous devions suivre]? Nous endurerons sûrement la persécution que vous nous infligez. Et ceux qui ont confiance en Allah s'en remettent entièrement à Lui.»"
    },
    {
      "key": 1763,
      "id": "14-13",
      "translation": "Et ceux qui ont mécru dirent à leurs messagers: «Nous vous expulserons certainement de notre territoire, à moins que vous ne réintégriez notre religion!» Alors, leur Seigneur leur révéla: «Assurément Nous anéantirons les injustes"
    },
    {
      "key": 1764,
      "id": "14-14",
      "translation": "et vous établirons dans le pays après eux. Cela est pour celui qui craint Ma présence et craint Ma menace»"
    },
    {
      "key": 1765,
      "id": "14-15",
      "translation": "Et ils demandèrent [à Allah] la victoire. Et tout tyran insolent fut déçu"
    },
    {
      "key": 1766,
      "id": "14-16",
      "translation": "L'Enfer est sa destination et il sera abreuvé d'une eau purulente"
    },
    {
      "key": 1767,
      "id": "14-17",
      "translation": "qu'il tentera d'avaler à petites gorgées. Mais c'est à peine s'il peut l'avaler. La mort lui viendra de toutes parts, mais il ne mourra pas; et il aura un châtiment terrible"
    },
    {
      "key": 1768,
      "id": "14-18",
      "translation": "Les œuvres de ceux qui ont mécru en leur Seigneur sont comparables à de la cendre violemment frappée par le vent, dans un jour de tempête. Ils ne tireront aucun profit de ce qu'ils ont acquis. C'est cela l'égarement profond"
    },
    {
      "key": 1769,
      "id": "14-19",
      "translation": "Ne vois-tu pas qu'Allah a créé les cieux et la terre pour une juste raison? S'Il voulait, Il vous ferait disparaître et ferait venir de nouvelles créatures"
    },
    {
      "key": 1770,
      "id": "14-20",
      "translation": "et cela n'est nullement difficile pour Allah"
    },
    {
      "key": 1771,
      "id": "14-21",
      "translation": "Et tous comparaîtront devant Allah. Puis, les faibles diront à ceux qui s'enflaient d'orgueil: «Nous étions bien vos suiveurs. Pouvez-vous nous être de quelque utilité contre le châtiment d'Allah?» - Alors, les autres diront: «Si Allah nous avait guidés nous vous aurions certainement guidés. Il est indifférent pour nous de nous plaindre ou d'endurer; nous n'avons pas d'échappatoire»"
    },
    {
      "key": 1772,
      "id": "14-22",
      "translation": "Et quand tout sera accompli, le Diable dira: «Certes, Allah vous avait fait une promesse de vérité; tandis que moi, je vous ai fait une promesse que je n'ai pas tenue. Je n'avais aucune autorité sur vous si ce n'est que je vous ai appelés, et que vous m'avez répondu. Ne me faites donc pas de reproches; mais faites-en à vous-mêmes. Je ne vous suis d'aucun secours et vous ne m'êtes d'aucun secours. Je vous renie de m'avoir jadis associé [à Allah]». Certes, un châtiment douloureux attend les injustes [les associateurs]"
    },
    {
      "key": 1773,
      "id": "14-23",
      "translation": "Et on fera entrer ceux qui croient et font de bonnes œuvres, dans les jardins sous lesquels coulent les ruisseaux, pour y demeurer éternellement, par permission de leur Seigneur. Et là, leur salutation sera: «Salâm» (Paix)"
    },
    {
      "key": 1774,
      "id": "14-24",
      "translation": "N'as-tu pas vu comment Allah propose en parabole une bonne parole pareille à un bel arbre dont la racine est ferme et la ramure s'élançant dans le ciel"
    },
    {
      "key": 1775,
      "id": "14-25",
      "translation": "Il donne à tout instant ses fruits, par la grâce de son Seigneur. Allah propose des paraboles à l'intention des gens afin qu'ils s'exhortent"
    },
    {
      "key": 1776,
      "id": "14-26",
      "translation": "Et une mauvaise parole est pareille à un mauvais arbre, déraciné de la surface de la terre et qui n'a point de stabilité"
    },
    {
      "key": 1777,
      "id": "14-27",
      "translation": "Allah affermit les croyants par une parole ferme, dans la vie présente et dans l'au-delà. Tandis qu'Il égare les injustes. Et Allah fait ce qu'Il veut"
    },
    {
      "key": 1778,
      "id": "14-28",
      "translation": "Ne vois-tu point ceux qui troquent les bienfaits d'Allah contre l'ingratitude et établissent leur peuple dans la demeure de la perdition"
    },
    {
      "key": 1779,
      "id": "14-29",
      "translation": "l'Enfer, où ils brûleront? Et quel mauvais gîte"
    },
    {
      "key": 1780,
      "id": "14-30",
      "translation": "Et ils ont donné à Allah des égaux afin d'égarer (les gens) de Son sentier. - Dis: «Jouissez [de cette vie] car votre destination sera le feu»"
    },
    {
      "key": 1781,
      "id": "14-31",
      "translation": "Dis à Mes serviteurs qui ont cru, qu'ils accomplissent la Salât et qu'ils dépensent [dans le bien] en secret et en public de ce que Nous leur avons attribué, avant que vienne le jour où il n'y a ni rachat ni amitié"
    },
    {
      "key": 1782,
      "id": "14-32",
      "translation": "Allah, c'est Lui qui a crée les cieux et la terre et qui, du ciel, a fait descendre l'eau; grâce à laquelle Il a produit des fruits pour vous nourrir. Il a soumis à votre service les vaisseaux qui, par Son ordre, voguent sur la mer. Et Il a soumis à votre service les rivières"
    },
    {
      "key": 1783,
      "id": "14-33",
      "translation": "Et pour vous, Il a assujetti le soleil et la lune à une perpétuelle révolution. Et Il vous a assujetti la nuit et le jour"
    },
    {
      "key": 1784,
      "id": "14-34",
      "translation": "Il vous a accordé de tout ce que vous Lui avez demandé. Et si vous comptiez les bienfaits d'Allah, vous ne sauriez les dénombrer. L'homme est vraiment très injuste, très ingrat"
    },
    {
      "key": 1785,
      "id": "14-35",
      "translation": "Et (rappelle-toi) quand Abraham dit: «O mon Seigneur, fais de cette cité un lieu sûr, et préserve-moi ainsi que mes enfants de l'adoration des idoles"
    },
    {
      "key": 1786,
      "id": "14-36",
      "translation": "O mon Seigneur, elles (les idoles) ont égaré beaucoup de gens. Quiconque me suit est des miens. Quant à celui qui me désobéit... c'est Toi, le Pardonneur, le Très Miséricordieux"
    },
    {
      "key": 1787,
      "id": "14-37",
      "translation": "O notre Seigneur, j'ai établi une partie de ma descendance dans une vallée sans agriculture, près de Ta Maison sacrée [la Ka'ba], - ô notre Seigneur - afin qu'ils accomplissent la Salât. Fais donc que se penchent vers eux les cœurs d'une partie des gens. Et nourris-les de fruits. Peut-être seront-ils reconnaissants"
    },
    {
      "key": 1788,
      "id": "14-38",
      "translation": "O notre Seigneur, Tu sais, vraiment, ce que nous cachons et ce que nous divulguons: - et rien n'échappe à Allah, ni sur terre, ni au ciel"
    },
    {
      "key": 1789,
      "id": "14-39",
      "translation": "Louange à Allah, qui en dépit de ma vieillesse, m'a donné Ismaël et Isaac. Certes, mon Seigneur entend bien les prières"
    },
    {
      "key": 1790,
      "id": "14-40",
      "translation": "O mon Seigneur! Fais que j'accomplisse assidûment la Salât ainsi qu'une partie de ma descendance; exauce ma prière, ô notre Seigneur"
    },
    {
      "key": 1791,
      "id": "14-41",
      "translation": "O notre Seigneur! pardonne-moi, ainsi qu'à mes père et mère et aux croyants, le jour de la reddition des comptes»"
    },
    {
      "key": 1792,
      "id": "14-42",
      "translation": "Et ne pense point qu'Allah soit inattentif à ce que font les injustes. Ils leur accordera un délai jusqu'au jour ou leurs regards se figeront"
    },
    {
      "key": 1793,
      "id": "14-43",
      "translation": "Ils courront [suppliant], levant la tête, les yeux hagards et les cœurs vides"
    },
    {
      "key": 1794,
      "id": "14-44",
      "translation": "Et avertis les gens du jour où le châtiment les atteindra et ceux qui auront été injustes diront: «O notre Seigneur accorde-nous un court délai, nous répondrons à Ton appel et suivrons les messagers». - N'avez-vous pas juré auparavant que vous ne deviez jamais disparaître"
    },
    {
      "key": 1795,
      "id": "14-45",
      "translation": "Et vous avez habité, les demeures de ceux qui s'étaient fait du tort à eux-mêmes. Il vous est apparu en toute évidence comment Nous les avions traité et Nous vous avons cité les exemples"
    },
    {
      "key": 1796,
      "id": "14-46",
      "translation": "Ils ont certes comploté. Or leur complot est (inscrit) auprès d'Allah même si leur complot était assez puissant pour faire disparaître les montagnes"
    },
    {
      "key": 1797,
      "id": "14-47",
      "translation": "Ne pense point qu'Allah manque à Sa promesse envers Ses messagers. Certes Allah est Tout Puissant et Détenteur du pouvoir de punir"
    },
    {
      "key": 1798,
      "id": "14-48",
      "translation": "au jour où la terre sera remplacée par une autre, de même que les cieux et où (les hommes) comparaîtront devant Allah, l'Unique, le Dominateur Suprême"
    },
    {
      "key": 1799,
      "id": "14-49",
      "translation": "Et ce jour-là, tu verras les coupables, enchaînés les uns aux autres"
    },
    {
      "key": 1800,
      "id": "14-50",
      "translation": "leurs tuniques seront de goudron et le feu couvrira leurs visages"
    },
    {
      "key": 1801,
      "id": "14-51",
      "translation": "(Tout cela) afin qu'Allah rétribue chaque âme de ce qu'elle aura acquis. Certes Allah est prompt dans Ses comptes"
    },
    {
      "key": 1802,
      "id": "14-52",
      "translation": "Ceci est un message (le Coran) pour les gens afin qu'ils soient avertis, qu'ils sachent qu'Il n'est qu'un Dieu unique, et pour que les doués d'intelligence s'exhortent"
    },
    {
      "key": 1803,
      "id": "15-1",
      "translation": "Alif, Lâm, Râ. Voici les versets du Livre et d'une Lecture explicite"
    },
    {
      "key": 1804,
      "id": "15-2",
      "translation": "[Le Jour du Jugement Dernier] les mécréants voudraient avoir été Musulmans [soumis]"
    },
    {
      "key": 1805,
      "id": "15-3",
      "translation": "Laisse-les manger, jouir (un temps), et être distraits par l'espoir; car bientôt ils sauront"
    },
    {
      "key": 1806,
      "id": "15-4",
      "translation": "Or Nous ne détruisons aucune cité sans qu'elle n'ait eu [un terme fixé en] une Ecriture connue"
    },
    {
      "key": 1807,
      "id": "15-5",
      "translation": "Nulle communauté ne devance son terme, ni ne le retarde"
    },
    {
      "key": 1808,
      "id": "15-6",
      "translation": "Et ils (les mecquois) disent: «O toi sur qui on a fait descendre le Coran, tu es certainement fou"
    },
    {
      "key": 1809,
      "id": "15-7",
      "translation": "Pourquoi ne nous es-tu pas venu avec les Anges, si tu es du nombre des véridiques?»"
    },
    {
      "key": 1810,
      "id": "15-8",
      "translation": "Nous ne faisons descendre les Anges qu'avec la vérité; et alors, il ne leur sera pas accordé de répit [à ces impies]"
    },
    {
      "key": 1811,
      "id": "15-9",
      "translation": "En vérité c'est Nous qui avons fait descendre le Coran, et c'est Nous qui en sommes gardien"
    },
    {
      "key": 1812,
      "id": "15-10",
      "translation": "Et nous avons certes envoyé, avant toi, [des Messagers] parmi les peuples des Anciens"
    },
    {
      "key": 1813,
      "id": "15-11",
      "translation": "Et pas un Messager ne leur est venu sans qu'ils s'en soient moqués"
    },
    {
      "key": 1814,
      "id": "15-12",
      "translation": "C'est ainsi que Nous faisons pénétrer (la mécréance) dans les cœurs des coupables"
    },
    {
      "key": 1815,
      "id": "15-13",
      "translation": "Ils ne croiront pas en lui [le Messager ou le Coran] bien que se soit accompli le sort traditionnel des anciens"
    },
    {
      "key": 1816,
      "id": "15-14",
      "translation": "Et même si Nous ouvrions pour eux une porte du ciel, et qu'ils pussent y monter"
    },
    {
      "key": 1817,
      "id": "15-15",
      "translation": "ils diraient: «Vraiment nos yeux sont voilés. Mais plutôt, nous sommes des gens ensorcelés»"
    },
    {
      "key": 1818,
      "id": "15-16",
      "translation": "Certes Nous avons placé dans le ciel des constellations et Nous l'avons embelli pour ceux qui regardent"
    },
    {
      "key": 1819,
      "id": "15-17",
      "translation": "Et Nous l'avons protégé contre tout diable banni"
    },
    {
      "key": 1820,
      "id": "15-18",
      "translation": "A moins que l'un d'eux parvienne subrepticement à écouter, une flamme brillante alors le poursuit"
    },
    {
      "key": 1821,
      "id": "15-19",
      "translation": "Et quant à la terre, Nous l'avons étalée et y avons placé des montagnes (immobiles) et y avons fait pousser toute chose harmonieusement proportionnée"
    },
    {
      "key": 1822,
      "id": "15-20",
      "translation": "Et Nous y avons placé des vivres pour vous, et (placé aussi pour vous) des êtres que vous ne nourrissez pas"
    },
    {
      "key": 1823,
      "id": "15-21",
      "translation": "Et il n'est rien dont Nous n'ayons les réserves et Nous ne le faisons descendre que dans une mesure déterminée"
    },
    {
      "key": 1824,
      "id": "15-22",
      "translation": "Et Nous envoyons les vents fécondants; et Nous faisons alors descendre du ciel une eau dont Nous vous abreuvons et que vous n'êtes pas en mesure de conserver"
    },
    {
      "key": 1825,
      "id": "15-23",
      "translation": "Et c'est bien Nous qui donnons la vie et donnons la mort, et c'est Nous qui sommes l'héritier [de tout]"
    },
    {
      "key": 1826,
      "id": "15-24",
      "translation": "Et Nous connaissons certes ceux qui parmi vous ont avancé et Nous connaissons ceux qui tardent encore"
    },
    {
      "key": 1827,
      "id": "15-25",
      "translation": "Certes, c'est ton Seigneur qui les rassemblera. Car c'est Lui le Sage, l'Omniscient"
    },
    {
      "key": 1828,
      "id": "15-26",
      "translation": "Nous créâmes l'homme d'une argile crissante, extraite d'une boue malléable"
    },
    {
      "key": 1829,
      "id": "15-27",
      "translation": "Et quant au djinn, Nous l'avions auparavant créé d'un feu d'une chaleur ardente"
    },
    {
      "key": 1830,
      "id": "15-28",
      "translation": "Et lorsque ton Seigneur dit aux Anges: «Je vais créer un homme d'argile crissante, extraite d'une boue malléable"
    },
    {
      "key": 1831,
      "id": "15-29",
      "translation": "et dès que Je l'aurai harmonieusement formé et lui aurai insufflé Mon souffle de vie, jetez-vous alors, prosternés devant lui»"
    },
    {
      "key": 1832,
      "id": "15-30",
      "translation": "Alors, les Anges se prosternèrent tous ensemble"
    },
    {
      "key": 1833,
      "id": "15-31",
      "translation": "excepté Iblis qui refusa d'être avec les prosternés"
    },
    {
      "key": 1834,
      "id": "15-32",
      "translation": "Alors [Allah] dit: «O Iblis, pourquoi n'es-tu pas au nombre des prosternés?»"
    },
    {
      "key": 1835,
      "id": "15-33",
      "translation": "Il dit: «Je ne puis me prosterner devant un homme que Tu as créé d'argile crissante, extraite d'une boue malléable»"
    },
    {
      "key": 1836,
      "id": "15-34",
      "translation": "Et [Allah] dit: «Sors de là [du Paradis], car te voilà banni"
    },
    {
      "key": 1837,
      "id": "15-35",
      "translation": "Et malédiction sur toi, jusqu'au Jour de la rétribution!»"
    },
    {
      "key": 1838,
      "id": "15-36",
      "translation": "Il dit: «O mon Seigneur, donne-moi donc un délai jusqu'au jour où ils (les gens) seront ressuscités»"
    },
    {
      "key": 1839,
      "id": "15-37",
      "translation": "[Allah] dit: «tu es de ceux à qui ce délai est accordé"
    },
    {
      "key": 1840,
      "id": "15-38",
      "translation": "jusqu'au jour de l'instant connu» [d'Allah]"
    },
    {
      "key": 1841,
      "id": "15-39",
      "translation": "Il dit: «O mon Seigneur, parce que Tu m'as induit en erreur, eh bien je leur enjoliverai la vie sur terre et les égarerai tous"
    },
    {
      "key": 1842,
      "id": "15-40",
      "translation": "à l'exception, parmi eux, de Tes serviteurs élus.»"
    },
    {
      "key": 1843,
      "id": "15-41",
      "translation": "«[Allah] dit: voici une voie droite [qui mène] vers Moi"
    },
    {
      "key": 1844,
      "id": "15-42",
      "translation": "Sur Mes serviteurs tu n'auras aucune autorité, excepté sur celui qui te suivra parmi les dévoyés"
    },
    {
      "key": 1845,
      "id": "15-43",
      "translation": "Et l'Enfer sera sûrement leur lieu de rendez-vous à tous"
    },
    {
      "key": 1846,
      "id": "15-44",
      "translation": "Il a sept portes; et chaque porte en a sa part déterminée»"
    },
    {
      "key": 1847,
      "id": "15-45",
      "translation": "Certes, les pieux seront dans des jardins avec des sources"
    },
    {
      "key": 1848,
      "id": "15-46",
      "translation": "«Entrez-y en paix et en sécurité»"
    },
    {
      "key": 1849,
      "id": "15-47",
      "translation": "Et Nous aurons arraché toute rancune de leurs poitrines: et ils se sentiront frères, faisant face les uns aux autres sur des lits"
    },
    {
      "key": 1850,
      "id": "15-48",
      "translation": "Nulle fatigue ne les y touchera. Et on ne les en fera pas sortir"
    },
    {
      "key": 1851,
      "id": "15-49",
      "translation": "Informe Mes serviteurs que c'est Moi le Pardonneur, le Très Miséricordieux"
    },
    {
      "key": 1852,
      "id": "15-50",
      "translation": "et que Mon châtiment est certes le châtiment douloureux"
    },
    {
      "key": 1853,
      "id": "15-51",
      "translation": "Et informe-les au sujet des hôtes d'Abraham"
    },
    {
      "key": 1854,
      "id": "15-52",
      "translation": "Quand ils entrèrent chez lui et dirent: «Salâm» - Il dit: «Nous avons peur de vous»"
    },
    {
      "key": 1855,
      "id": "15-53",
      "translation": "Ils dirent: «N'aie pas peur! Nous t'annonçons une bonne nouvelle, [la naissance] d'un garçon plein de savoir»"
    },
    {
      "key": 1856,
      "id": "15-54",
      "translation": "Il dit: «M'annoncez-vous [cette nouvelle] alors que la vieillesse m'a touché? Que m'annoncez-vous donc?»"
    },
    {
      "key": 1857,
      "id": "15-55",
      "translation": "Ils dirent: «Nous t'annonçons la vérité. Ne sois donc pas de ceux qui désespèrent»"
    },
    {
      "key": 1858,
      "id": "15-56",
      "translation": "Il dit: «Et qui désespère de la miséricorde de son Seigneur, sinon les égarés?»"
    },
    {
      "key": 1859,
      "id": "15-57",
      "translation": "Et il [leur] dit: «Que voulez-vous, ô envoyés d'Allah?»"
    },
    {
      "key": 1860,
      "id": "15-58",
      "translation": "Ils dirent: «En vérité, nous sommes envoyés à des gens criminels"
    },
    {
      "key": 1861,
      "id": "15-59",
      "translation": "à l'exception de la famille de Lot que nous sauverons tous"
    },
    {
      "key": 1862,
      "id": "15-60",
      "translation": "sauf sa femme.«Nous (Allah) avions déterminé qu'elle sera du nombre des exterminés"
    },
    {
      "key": 1863,
      "id": "15-61",
      "translation": "Puis lorsque les envoyés vinrent auprès de la famille de Lot"
    },
    {
      "key": 1864,
      "id": "15-62",
      "translation": "celui-ci dit: «Vous êtes [pour moi] des gens inconnus»"
    },
    {
      "key": 1865,
      "id": "15-63",
      "translation": "Ils dirent: «Nous sommes plutôt venus à toi en apportant (le châtiment) à propos duquel ils doutaient"
    },
    {
      "key": 1866,
      "id": "15-64",
      "translation": "Et nous venons à toi avec la vérité. Et nous sommes véridiques"
    },
    {
      "key": 1867,
      "id": "15-65",
      "translation": "Pars donc avec ta famille en parie de nuit et suis leurs arrières; et que nul d'entre vous ne se retourne. Et allez là où on vous le commande»"
    },
    {
      "key": 1868,
      "id": "15-66",
      "translation": "Et Nous lui annonçâmes cet ordre: que ces gens-là, au matin, seront anéantis jusqu'au dernier"
    },
    {
      "key": 1869,
      "id": "15-67",
      "translation": "Et les habitants de la ville (Sodome) vinrent [à lui] dans la joie"
    },
    {
      "key": 1870,
      "id": "15-68",
      "translation": "Il dit: «Ceux-ci sont mes hôtes, ne me déshonorez donc pas"
    },
    {
      "key": 1871,
      "id": "15-69",
      "translation": "Et craignez Allah. Et ne me couvrez pas d'ignominie.»"
    },
    {
      "key": 1872,
      "id": "15-70",
      "translation": "Ils dirent: «Ne t'avions-nous pas interdit de [recevoir] du monde?»"
    },
    {
      "key": 1873,
      "id": "15-71",
      "translation": "Il dit: «Voici mes filles, si vous voulez faire [quelque chose]!»"
    },
    {
      "key": 1874,
      "id": "15-72",
      "translation": "Par ta vie! ils se confondaient dans leur délire"
    },
    {
      "key": 1875,
      "id": "15-73",
      "translation": "Alors, au lever du soleil le Cri (la catastrophe) les saisit"
    },
    {
      "key": 1876,
      "id": "15-74",
      "translation": "Et Nous renversâmes [la ville] de fond en comble et fîmes pleuvoir sur eux des pierres d'argile dure"
    },
    {
      "key": 1877,
      "id": "15-75",
      "translation": "Voilà vraiment des preuves, pour ceux qui savent observer"
    },
    {
      "key": 1878,
      "id": "15-76",
      "translation": "Elle [cette ville] se trouvait sur un chemin connu de tous"
    },
    {
      "key": 1879,
      "id": "15-77",
      "translation": "Voilà vraiment une exhortation pour les croyants"
    },
    {
      "key": 1880,
      "id": "15-78",
      "translation": "Et les habitants d'al-Aïka étaient [aussi] des injustes"
    },
    {
      "key": 1881,
      "id": "15-79",
      "translation": "Nous Nous sommes donc vengés d'eux. Et ces deux [cités], vraiment, sont sur une route bien évidente [que vous connaissez]"
    },
    {
      "key": 1882,
      "id": "15-80",
      "translation": "Certes, les gens d'al-Hijr ont traité de menteurs les messagers"
    },
    {
      "key": 1883,
      "id": "15-81",
      "translation": "Nous leur avons montré Nos miracles, mais ils s'en étaient détournés"
    },
    {
      "key": 1884,
      "id": "15-82",
      "translation": "Et ils taillaient des maisons dans les montagnes, vivant en sécurité"
    },
    {
      "key": 1885,
      "id": "15-83",
      "translation": "Puis, au matin, le Cri les saisit"
    },
    {
      "key": 1886,
      "id": "15-84",
      "translation": "Ce qu'ils avaient acquis ne leur a donc point profité"
    },
    {
      "key": 1887,
      "id": "15-85",
      "translation": "Et Nous n'avons créé les cieux et la terre, et ce qui est entre eux, que pour une juste raison. Et l'Heure [sans aucun doute] arrivera! Pardonne-[leur] donc d'un beau pardon"
    },
    {
      "key": 1888,
      "id": "15-86",
      "translation": "Ton Seigneur, c'est Lui vraiment le grand Créateur, l'Omniscient"
    },
    {
      "key": 1889,
      "id": "15-87",
      "translation": "Nous t'avons certes donné «les sept versets que l'on répète», ainsi que le Coran sublime"
    },
    {
      "key": 1890,
      "id": "15-88",
      "translation": "Ne regarde surtout pas avec envie les choses dont Nous avons donné jouissance temporaire à certains couples d'entre eux, ne t'afflige pas à leur sujet et abaisse ton aile pour les croyants"
    },
    {
      "key": 1891,
      "id": "15-89",
      "translation": "Et dis: «Je suis l'avertisseur évident» (d'un châtiment)"
    },
    {
      "key": 1892,
      "id": "15-90",
      "translation": "De même que Nous avons fait descendre [le châtiment] sur ceux qui ont juré (entre eux)"
    },
    {
      "key": 1893,
      "id": "15-91",
      "translation": "ceux qui ont fait du Coran des fractions diverses, (pour créer des doutes)"
    },
    {
      "key": 1894,
      "id": "15-92",
      "translation": "Par ton Seigneur! Nous les interrogerons tous"
    },
    {
      "key": 1895,
      "id": "15-93",
      "translation": "sur ce qu'ils œuvraient"
    },
    {
      "key": 1896,
      "id": "15-94",
      "translation": "Expose donc clairement ce qu'on t'a commandé et détourne-toi des associateurs"
    },
    {
      "key": 1897,
      "id": "15-95",
      "translation": "Nous t'avons effectivement défendu vis-à-vis des railleurs"
    },
    {
      "key": 1898,
      "id": "15-96",
      "translation": "Ceux qui associent à Allah une autre divinité. Mais ils sauront bientôt"
    },
    {
      "key": 1899,
      "id": "15-97",
      "translation": "Et Nous savons certes que ta poitrine se serre, à cause de ce qu'ils disent"
    },
    {
      "key": 1900,
      "id": "15-98",
      "translation": "Glorifie donc Ton Seigneur par Sa louange et sois de ceux qui se prosternent"
    },
    {
      "key": 1901,
      "id": "15-99",
      "translation": "et adore ton Seigneur jusqu'à ce que te vienne la certitude (la mort)"
    },
    {
      "key": 1902,
      "id": "16-1",
      "translation": "L'ordre d'Allah arrive. Ne le hâtez donc pas. Gloire à Lui! Il est au-dessus de ce qu'on Lui associe"
    },
    {
      "key": 1903,
      "id": "16-2",
      "translation": "Il fait descendre, par Son ordre, les Anges, avec la révélation sur qui Il veut parmi Ses serviteurs: «Avertissez qu'il n'est d'autre divinité que Moi. Craignez-Moi donc»"
    },
    {
      "key": 1904,
      "id": "16-3",
      "translation": "Il a créé les cieux et la terre avec juste raison. Il transcende ce qu'on [Lui] associe"
    },
    {
      "key": 1905,
      "id": "16-4",
      "translation": "Il a créé l'homme d'une goutte de sperme; et voilà que l'homme devient un disputeur déclaré"
    },
    {
      "key": 1906,
      "id": "16-5",
      "translation": "Et les bestiaux, Il les a créés pour vous; vous en retirez des [vêtements] chauds ainsi que d'autres profits. Et vous en mangez aussi"
    },
    {
      "key": 1907,
      "id": "16-6",
      "translation": "Ils vous paraissent beaux quand vous les ramenez, le soir, et aussi le matin quand vous les lâchez pour le pâturage"
    },
    {
      "key": 1908,
      "id": "16-7",
      "translation": "Et ils portent vos fardeaux vers un pays que vous n'atteindriez qu'avec peine. Vraiment, votre Seigneur est Compatissant et Miséricordieux"
    },
    {
      "key": 1909,
      "id": "16-8",
      "translation": "Et les chevaux, les mulets et les ânes, pour que vous les montiez, et pour l'apparat. Et Il crée ce que vous ne savez pas"
    },
    {
      "key": 1910,
      "id": "16-9",
      "translation": "Il appartient à Allah [par Sa grâce, de montrer] le droit chemin car il en est qui s'en détachent. Or, s'Il voulait, Il vous guiderait tous"
    },
    {
      "key": 1911,
      "id": "16-10",
      "translation": "C'est Lui qui, du ciel, a fait descendre de l'eau qui vous sert de boisson et grâce à laquelle poussent des plantes dont vous nourrissez vos troupeaux"
    },
    {
      "key": 1912,
      "id": "16-11",
      "translation": "D'elle, Il fait pousser pour vous, les cultures, les oliviers, les palmiers, les vignes et aussi toutes sortes de fruits. Voilà bien là une preuve pour des gens qui réfléchissent"
    },
    {
      "key": 1913,
      "id": "16-12",
      "translation": "Pour vous, Il a assujetti la nuit et le jour; le soleil et la lune. Et à Son ordre sont assujetties les étoiles. Voilà bien là des preuves pour des gens qui raisonnent"
    },
    {
      "key": 1914,
      "id": "16-13",
      "translation": "Ce qu'Il a créé pour vous sur la terre a des couleurs diverses. Voilà bien là une preuve pour des gens qui se rappellent"
    },
    {
      "key": 1915,
      "id": "16-14",
      "translation": "Et c'est Lui qui a assujetti la mer afin que vous en mangiez une chair fraîche, et que vous en retiriez des parures que vous portez. Et tu vois les bateaux fendre la mer avec bruit, pour que vous partiez en quête de Sa grâce et afin que vous soyez reconnaissants"
    },
    {
      "key": 1916,
      "id": "16-15",
      "translation": "Et Il a implanté des montagnes immobiles dans la terre afin qu'elle ne branle pas en vous emportant avec elle de même que des rivières et des sentiers, pour que vous vous guidiez"
    },
    {
      "key": 1917,
      "id": "16-16",
      "translation": "ainsi que des points de repère. Et au moyen des étoiles [les gens] se guident"
    },
    {
      "key": 1918,
      "id": "16-17",
      "translation": "Celui qui crée est-il semblable à celui qui ne crée rien? Ne vous souvenez-vous pas"
    },
    {
      "key": 1919,
      "id": "16-18",
      "translation": "Et si vous comptez les bienfaits d'Allah, vous ne saurez pas les dénombrer. Car Allah est Pardonneur, et Miséricordieux"
    },
    {
      "key": 1920,
      "id": "16-19",
      "translation": "Et Allah sait ce que vous cachez et ce que vous divulguez"
    },
    {
      "key": 1921,
      "id": "16-20",
      "translation": "Et ceux qu'ils invoquent en dehors d'Allah ne créent rien, et ils sont eux-mêmes créés"
    },
    {
      "key": 1922,
      "id": "16-21",
      "translation": "Ils sont morts, et non pas vivants, et ils ne savent pas quand ils seront ressuscités"
    },
    {
      "key": 1923,
      "id": "16-22",
      "translation": "Votre Dieu est un Dieu unique. Ceux qui ne croient pas en l'au-delà leurs cœurs nient (l'unicité d'Allah) et ils sont remplis d'orgueil"
    },
    {
      "key": 1924,
      "id": "16-23",
      "translation": "Nul doute qu'Allah sait ce qu'ils cachent et ce qu'ils divulguent. Et assurément Il n'aime pas les orgueilleux"
    },
    {
      "key": 1925,
      "id": "16-24",
      "translation": "Et lorsqu'on leur dit: «Qu'est-ce que votre Seigneur a fait descendre?» Ils disent: «Des légendes anciennes!»"
    },
    {
      "key": 1926,
      "id": "16-25",
      "translation": "Qu'ils portent donc, au Jour de la Résurrection, tous les fardeaux de leurs propres œuvres ainsi qu'une partie des fardeaux de ceux qu'ils égarent, sans le savoir; combien est mauvais [le fardeau] qu'ils portent"
    },
    {
      "key": 1927,
      "id": "16-26",
      "translation": "Ceux qui ont vécu avant eux, certes, ont comploté, mais Allah attaqua les bases mêmes de leur bâtisse. Le toit s'écroula au-dessus d'eux et le châtiment les surprit d'où ils ne l'avaient pas pressenti"
    },
    {
      "key": 1928,
      "id": "16-27",
      "translation": "Puis, le Jour de la Résurrection, Il les couvrira d'ignominie, et [leur] dira: «Où sont Mes associés pour lesquels vous combattiez?» - Ceux qui ont le savoir diront: «L'ignominie et le malheur tombent aujourd'hui sur les mécréants»"
    },
    {
      "key": 1929,
      "id": "16-28",
      "translation": "Ceux à qui les Anges ôtent la vie, alors qu'ils sont injustes envers eux-mêmes, se soumettront humiliés, (et diront): «Nous ne faisions pas de mal!» - «Mais, en fait, Allah sait bien ce que vous faisiez»"
    },
    {
      "key": 1930,
      "id": "16-29",
      "translation": "Entrez donc par les portes de l'Enfer pour y demeurer éternellement. Combien est mauvaise la demeure des orgueilleux"
    },
    {
      "key": 1931,
      "id": "16-30",
      "translation": "Et on dira à ceux qui étaient pieux: «Qu'a fait descendre votre Seigneur?» Ils diront: «Un bien». Ceux qui font les bonnes œuvres auront un bien ici-bas; mais la demeure de l'au-delà est certes meilleure. Combien agréable sera la demeure des pieux"
    },
    {
      "key": 1932,
      "id": "16-31",
      "translation": "Les jardins du séjour (éternel), où ils entreront et sous lesquels coulent les ruisseaux. Ils auront là ce qu'ils voudront; c'est ainsi qu'Allah récompense les pieux"
    },
    {
      "key": 1933,
      "id": "16-32",
      "translation": "Ceux dont les Anges reprennent l'âme - alors qu'ils sont bons - [les Anges leur] disent: «Paix sur vous! Entrez au Paradis, pour ce que vous faisiez»"
    },
    {
      "key": 1934,
      "id": "16-33",
      "translation": "[Les infidèles] attendent-ils que les Anges leur viennent, ou que survienne l'ordre de ton Seigneur? Ainsi agissaient les gens avant eux. Allah ne les a pas lésés; mais ils faisaient du tort à eux-mêmes"
    },
    {
      "key": 1935,
      "id": "16-34",
      "translation": "Les méfaits qu'ils accomplissaient les atteindront, et ce dont ils se moquaient les cernera de toutes parts"
    },
    {
      "key": 1936,
      "id": "16-35",
      "translation": "Et les associateurs dirent: «Si Allah avait voulu, nous n'aurions pas adoré quoi que ce soit en dehors de Lui, ni nous ni nos ancêtres; et nous n'aurions rien interdit qu'Il n'ait interdit Lui-même». Ainsi agissaient les gens avant eux. N'incombe-t-il aux messagers sinon de transmettre le message en toute clarté"
    },
    {
      "key": 1937,
      "id": "16-36",
      "translation": "Nous avons envoyé dans chaque communauté un Messager, [pour leur dire]: «Adorez Allah et écartez-vous du Tâgût». Alors Allah en guida certains, mais il y en eut qui ont été destinés à l'égarement. Parcourez donc la terre, et regardez quelle fut la fin de ceux qui traitaient [Nos messagers] de menteurs"
    },
    {
      "key": 1938,
      "id": "16-37",
      "translation": "Même si tu désirais ardemment qu'ils soient guidés... [Sache] qu'Allah ne guide pas ceux qui s'égarent. Et ils n'auront pas de secoureurs"
    },
    {
      "key": 1939,
      "id": "16-38",
      "translation": "Et ils jurent par Allah en prononçant leurs serments les plus solennels: «Allah ne ressuscitera pas celui qui meurt». Bien au contraire! C'est une promesse véritable [de Sa part], mais la plupart des gens ne le savent pas"
    },
    {
      "key": 1940,
      "id": "16-39",
      "translation": "(Il les ressuscitera) afin qu'Il leur expose clairement ce en quoi ils divergeaient, et pour que ceux qui ont mécru sachent qu'ils ont été des menteurs"
    },
    {
      "key": 1941,
      "id": "16-40",
      "translation": "Quand Nous voulons une chose, Notre seule parole est: «Sois». Et, elle est"
    },
    {
      "key": 1942,
      "id": "16-41",
      "translation": "Et ceux qui, pour (la cause d') Allah, ont émigré après avoir subi des injustices, Nous les installerons dans une situation agréable dans la vie d'ici-bas. Et le salaire de la vie dernière sera plus grand encore s'ils savaient"
    },
    {
      "key": 1943,
      "id": "16-42",
      "translation": "Eux qui ont enduré et placé leur confiance en leur Seigneur"
    },
    {
      "key": 1944,
      "id": "16-43",
      "translation": "Nous n'avons envoyé, avant toi, que des hommes auxquels Nous avons fait des révélations. Demandez donc aux gens du rappel si vous ne savez pas"
    },
    {
      "key": 1945,
      "id": "16-44",
      "translation": "(Nous les avons envoyés) avec des preuves évidentes et des livres saints. Et vers toi, Nous avons fait descendre le Coran, pour que tu exposes clairement aux gens ce qu'on a fait descendre pour eux et afin qu'ils réfléchissent"
    },
    {
      "key": 1946,
      "id": "16-45",
      "translation": "Ceux qui complotaient des méfaits sont-ils à l'abri de ce qu'Allah les engloutisse en terre ou que leur vienne le châtiment d'où ils ne s'attendaient point"
    },
    {
      "key": 1947,
      "id": "16-46",
      "translation": "Ou bien qu'Il les saisisse en pleine activité sans qu'ils puissent échapper (au châtiment d'Allah)"
    },
    {
      "key": 1948,
      "id": "16-47",
      "translation": "Ou bien qu'Il les saisisse en plein effroi? Mais vraiment, votre Seigneur est Compatissant et Miséricordieux"
    },
    {
      "key": 1949,
      "id": "16-48",
      "translation": "N'ont-ils point vu que les ombres de toute chose qu'Allah a créée s'allongent à droite et à gauche, en se prosternant devant Allah, en toute humilité"
    },
    {
      "key": 1950,
      "id": "16-49",
      "translation": "Et c'est devant Allah que se prosterne tout être vivant dans les cieux, et sur la terre; ainsi que les Anges qui ne s'enflent pas d'orgueil"
    },
    {
      "key": 1951,
      "id": "16-50",
      "translation": "Ils craignent leur Seigneur, au-dessus d'eux, et font ce qui leur est commandé"
    },
    {
      "key": 1952,
      "id": "16-51",
      "translation": "Allah dit: «Ne prenez pas deux divinités. Il n'est qu'un Dieu unique. Donc, ne craignez que Moi»"
    },
    {
      "key": 1953,
      "id": "16-52",
      "translation": "C'est à Lui qu'appartient ce qui est dans les cieux et sur la terre; c'est à Lui que l'obéissance perpétuelle est due. Craindriez-vous donc, d'autres qu'Allah"
    },
    {
      "key": 1954,
      "id": "16-53",
      "translation": "Et tout ce que vous avez comme bienfait provient d'Allah. Puis quand le malheur vous touche, c'est Lui que vous implorez à haute voix"
    },
    {
      "key": 1955,
      "id": "16-54",
      "translation": "Et une fois qu'Il a dissipé votre malheur, voilà qu'une partie d'entre vous se mettent à donner des associés à leur Seigneur"
    },
    {
      "key": 1956,
      "id": "16-55",
      "translation": "méconnaissant ainsi ce que Nous leur avons donné. Jouissez donc [pour un temps!] Bientôt vous saurez"
    },
    {
      "key": 1957,
      "id": "16-56",
      "translation": "Et ils assignent une partie [des biens] que Nous leur avons attribués à [des idoles] qu'ils ne connaissent pas. Par Allah! Vous serez certes interrogés sur ce que vous inventiez"
    },
    {
      "key": 1958,
      "id": "16-57",
      "translation": "Et ils assignent à Allah des filles. Gloire et pureté à Lui! Et à eux-mêmes, cependant, (ils assignent) ce qu'ils désirent (des fils)"
    },
    {
      "key": 1959,
      "id": "16-58",
      "translation": "Et lorsqu'on annonce à l'un d'eux une fille, son visage s'assombrit et une rage profonde [l'envahit]"
    },
    {
      "key": 1960,
      "id": "16-59",
      "translation": "Il se cache des gens, à cause du malheur qu'on lui a annoncé. Doit-il la garder malgré la honte ou l'enfouira-t-il dans la terre? Combien est mauvais leur jugement"
    },
    {
      "key": 1961,
      "id": "16-60",
      "translation": "C'est à ceux qui ne croient pas en l'au-delà que revient le mauvais qualificatif (qu'ils ont attribué à Allah). Tandis qu'à Allah [Seul] est le qualificatif suprême. Et c'est Lui le Tout Puissant, le Sage"
    },
    {
      "key": 1962,
      "id": "16-61",
      "translation": "Si Allah s'en prenait aux gens pour leurs méfaits, Il ne laisserait sur cette terre aucun être vivant. Mais Il les renvoie jusqu'à un terme fixé. Puis, quand leur terme vient, ils ne peuvent ni le retarder d'une heure ni l'avancer"
    },
    {
      "key": 1963,
      "id": "16-62",
      "translation": "Et ils assignent à Allah ce qu'ils détestent [pour eux-mêmes]. Et leurs langues profèrent un mensonge quand ils disent que la plus belle récompense leur sera réservée. C'est le Feu, sans nul doute, qui leur sera réservé et ils y seront envoyés, les premiers"
    },
    {
      "key": 1964,
      "id": "16-63",
      "translation": "Par Allah! Nous avons effectivement envoyé (des messagers) à des communautés avant toi. Mais le Diable leur enjoliva ce qu'ils faisaient. C'est lui qui est, leur allié, aujourd'hui [dans ce monde]. Et ils auront un châtiment douloureux [dans l'au-delà]"
    },
    {
      "key": 1965,
      "id": "16-64",
      "translation": "Et Nous n'avons fait descendre sur toi le Livre qu'afin que tu leur montres clairement le motif de leur dissension, de même qu'un guide et une miséricorde pour des gens croyants"
    },
    {
      "key": 1966,
      "id": "16-65",
      "translation": "Allah a fait descendre du ciel une eau avec laquelle Il revivifie la terre après sa mort. Il y a vraiment là une preuve pour des gens qui entendent"
    },
    {
      "key": 1967,
      "id": "16-66",
      "translation": "Il y a certes un enseignement pour vous dans les bestiaux: Nous vous abreuvons de ce qui est dans leurs ventres, - [un produit] extrait du [mélange] des excréments [intestinaux] et du sang - un lait pur, délicieux pour les buveurs"
    },
    {
      "key": 1968,
      "id": "16-67",
      "translation": "Des fruits des palmiers et des vignes, vous retirez une boisson enivrante et un aliment excellent. Il y a vraiment là un signe pour des gens qui raisonnent"
    },
    {
      "key": 1969,
      "id": "16-68",
      "translation": "[Et voilà] ce que ton Seigneur révéla aux abeilles: «Prenez des demeures dans les montagnes, les arbres, et les treillages que [les hommes] font"
    },
    {
      "key": 1970,
      "id": "16-69",
      "translation": "Puis mangez de toute espèce de fruits, et suivez les sentiers de votre Seigneur, rendus faciles pour vous. De leur ventre, sort une liqueur, aux couleurs variées, dans laquelle il y a une guérison pour les gens. Il y a vraiment là une preuve pour des gens qui réfléchissent"
    },
    {
      "key": 1971,
      "id": "16-70",
      "translation": "Allah vous a créés! Puis Il vous fera mourir. Tel parmi vous sera reconduit jusqu'à l'âge le plus vil, de sorte qu'après avoir su, il arrive à ne plus rien savoir. Allah est, certes, Omniscient et Omnipotent"
    },
    {
      "key": 1972,
      "id": "16-71",
      "translation": "Allah a favorisé les uns d'entre vous par rapport aux autres dans [la répartition] de Ses dons. Ceux qui ont été favorisés ne sont nullement disposés à donner leur portion à ceux qu'ils possèdent de plein droit [esclaves] au point qu'ils y deviennent associés à part égale. Nieront-ils les bienfaits d'Allah"
    },
    {
      "key": 1973,
      "id": "16-72",
      "translation": "Allah vous a fait à partir de vous-mêmes des épouses, et de vos épouses Il vous a donné des enfants et des petits-enfants. Et Il vous a attribué de bonnes choses. Croient-ils donc au faux et nient-ils le bienfait d'Allah"
    },
    {
      "key": 1974,
      "id": "16-73",
      "translation": "Et ils adorent, en dehors d'Allah, ce qui ne peut leur procurer aucune nourriture des cieux et de la terre et qui n'est capable de rien"
    },
    {
      "key": 1975,
      "id": "16-74",
      "translation": "N'attribuez donc pas à Allah des semblables. Car Allah sait, tandis que vous ne savez pas"
    },
    {
      "key": 1976,
      "id": "16-75",
      "translation": "Allah propose en parabole un esclave appartenant [à son maître], dépourvu de tout pouvoir, et un homme à qui Nous avons accordé de Notre part une bonne attribution dont il dépense en secret et en public. [Ces deux hommes] sont-ils égaux? Louange à Allah! Mais la plupart d'entre eux ne savent pas"
    },
    {
      "key": 1977,
      "id": "16-76",
      "translation": "Et Allah propose en parabole deux hommes: l'un d'eux est muet, dépourvu de tout pouvoir et totalement à la charge de son maître; Quelque lieu où celui-ci l'envoie, il ne rapporte rien de bon; serait-il l'égal de celui qui ordonne la justice et qui est sur le droit chemin"
    },
    {
      "key": 1978,
      "id": "16-77",
      "translation": "C'est à Allah qu'appartient l'inconnaissable des cieux et de la terre. Et l'ordre [concernant] l'Heure ne sera que comme un clin d'œil ou plus bref encore! Car Allah est, certes, Omnipotent"
    },
    {
      "key": 1979,
      "id": "16-78",
      "translation": "Et Allah vous a fait sortir des ventres de vos mères, dénués de tout savoir, et vous a donné l'ouïe, les yeux et les cœurs (l'intelligence), afin que vous; soyez reconnaissants"
    },
    {
      "key": 1980,
      "id": "16-79",
      "translation": "N'ont-ils pas vu les oiseaux assujettis [au vol] dans l'atmosphère du ciel sans que rien ne les retienne en dehors d'Allah? Il y a vraiment là des preuves pour des gens qui croient"
    },
    {
      "key": 1981,
      "id": "16-80",
      "translation": "Et Allah vous a fait de vos maisons une habitation, tout comme Il vous a procuré des maisons faites de peaux de bêtes que vous trouvez légères, le jour où vous vous déplacez et le jour où vous vous campez. De leur laine, de leur poil et de leur crin (Il vous a procuré) des effets et des objets dont vous jouissez pour un certain délai"
    },
    {
      "key": 1982,
      "id": "16-81",
      "translation": "Et de ce qu'Il a créé, Allah vous a procuré des ombres. Et Il vous a procuré des abris dans les montagnes. Et Il vous a procuré des vêtements qui vous protègent de la chaleur, ainsi que des vêtements [cuirasses, armures] qui vous protègent de votre propre violence. C'est ainsi qu'Allah parachève sur vous Son bienfait, peut-être que vous vous soumettez"
    },
    {
      "key": 1983,
      "id": "16-82",
      "translation": "S'ils se détournent... il ne t'incombe que la communication claire"
    },
    {
      "key": 1984,
      "id": "16-83",
      "translation": "Ils reconnaissent le bienfait d'Allah; puis, ils le renient. Et la plupart d'entre eux sont des ingrats"
    },
    {
      "key": 1985,
      "id": "16-84",
      "translation": "(Et rappelle-toi) le jour où de chaque communauté Nous susciterons un témoin, on ne permettra pas aux infidèles (de s'excuser), et on ne leur demandera pas de revenir [sur ce qui a provoqué la colère d'Allah]"
    },
    {
      "key": 1986,
      "id": "16-85",
      "translation": "Et quand les injustes verront le châtiment, on ne leur accordera ni allégement ni répit"
    },
    {
      "key": 1987,
      "id": "16-86",
      "translation": "Quand les associateurs verront ceux qu'ils associaient à Allah, ils diront: «O notre Seigneur, voilà nos divinités que nous invoquions en dehors de Toi». Mais [leurs associés] leur adresseront la parole: «Vous êtes assurément des menteurs»"
    },
    {
      "key": 1988,
      "id": "16-87",
      "translation": "Ils offriront ce jour-là à Allah la soumission, et ce qu'ils avaient inventé sera perdu pour eux"
    },
    {
      "key": 1989,
      "id": "16-88",
      "translation": "Ceux qui ne croyaient pas et obstruaient le sentier d'Allah, Nous leur ajouterons châtiment sur châtiment, pour la corruption qu'ils semaient (sur terre)"
    },
    {
      "key": 1990,
      "id": "16-89",
      "translation": "Et le jour où dans chaque communauté, Nous susciterons parmi eux-mêmes un témoin contre eux, Et Nous t'emmenerons comme témoin contre ceux-ci. Et Nous avons fait descendre sur toi le Livre, comme un exposé explicite de toute chose, ainsi qu'un guide, une grâce et une bonne annonce aux Musulmans"
    },
    {
      "key": 1991,
      "id": "16-90",
      "translation": "Certes, Allah commande l'équité, la bienfaisance et l'assistance aux proches. Et Il interdit la turpitude, l'acte répréhensible et la rébellion. Il vous exhorte afin que vous vous souveniez"
    },
    {
      "key": 1992,
      "id": "16-91",
      "translation": "Soyez fidèles au pacte d'Allah après l'avoir contracté et ne violez pas vos serments après les avoir solennellement prêtés et avoir pris Allah comme garant [de votre bonne foi]. Vraiment Allah sait ce que vous faites"
    },
    {
      "key": 1993,
      "id": "16-92",
      "translation": "Et ne faites pas comme celle qui défaisait brin par brin sa quenouille après l'avoir solidement filée, en prenant vos serments comme un moyen pour vous tromper les uns les autres, du fait que (vous avez trouvé) une communauté plus forte et plus nombreuse que l'autre. Allah ne fait, par là, que vous éprouver. Et, certes, Il vous montrera clairement, au Jour de la Résurrection ce sur quoi vous vous opposiez"
    },
    {
      "key": 1994,
      "id": "16-93",
      "translation": "Si Allah avait voulu, Il aurait certes fait de vous une seule communauté. Mais Il laisse s'égarer qui Il veut et guide qui Il veut. Et vous serez certes, interrogés sur ce que vous faisiez"
    },
    {
      "key": 1995,
      "id": "16-94",
      "translation": "Et ne prenez pas vos serments comme un moyen pour vous tromper les uns les autres, sinon [vos] pas glisseront après avoir été fermes, et vous goûterez le malheur pour avoir barré le sentier d'Allah. Et vous subirez un châtiment terrible"
    },
    {
      "key": 1996,
      "id": "16-95",
      "translation": "Et ne vendez pas à vil prix le pacte d'Allah. Ce qui se trouve auprès d'Allah est meilleur pour vous, si vous saviez"
    },
    {
      "key": 1997,
      "id": "16-96",
      "translation": "Tout ce que vous possédez s'épuisera, tandis que ce qui est auprès d'Allah durera. Et Nous récompenserons ceux qui ont été constants en fonction du meilleur de ce qu'ils faisaient"
    },
    {
      "key": 1998,
      "id": "16-97",
      "translation": "Quiconque, mâle ou femelle, fait une bonne œuvre tout en étant croyant, Nous lui ferons vivre une bonne vie. Et Nous les récompenserons, certes, en fonction des meilleures de leurs actions"
    },
    {
      "key": 1999,
      "id": "16-98",
      "translation": "Lorsque tu lis le Coran, demande la protection d'Allah contre le Diable banni"
    },
    {
      "key": 2000,
      "id": "16-99",
      "translation": "Il n'a aucun pouvoir sur ceux qui croient et qui placent leur confiance en leur Seigneur"
    },
    {
      "key": 2001,
      "id": "16-100",
      "translation": "Il n'a de pouvoir que sur ceux qui le prennent pour allié et qui deviennent associateurs à cause de lui"
    },
    {
      "key": 2002,
      "id": "16-101",
      "translation": "Quand Nous remplaçons un  par un autre - et Allah sait mieux ce qu'Il fait descendre - ils disent: «Tu n'es qu'un menteur». Mais la plupart d'entre eux ne savent pas"
    },
    {
      "key": 2003,
      "id": "16-102",
      "translation": "Dis: «C'est le Saint Esprit [Gabriel] qui l'a fait descendre de la part de ton Seigneur en toute vérité, afin de raffermir [la foi] de ceux qui croient, ainsi qu'un guide et une bonne annonce pour les Musulmans.»"
    },
    {
      "key": 2004,
      "id": "16-103",
      "translation": "Et Nous savons parfaitement qu'ils disent: «Ce n'est qu'un être humain qui lui enseigne (le Coran)». Or, la langue de celui auquel ils font allusion est étrangère [non arabe], et celle-ci est une langue arabe bien claire"
    },
    {
      "key": 2005,
      "id": "16-104",
      "translation": "Ceux qui ne croient pas aux versets d'Allah, Allah ne les guide pas. Et ils ont un châtiment douloureux"
    },
    {
      "key": 2006,
      "id": "16-105",
      "translation": "Seuls forgent le mensonge ceux qui ne croient pas aux versets d'Allah; et tels sont les menteurs"
    },
    {
      "key": 2007,
      "id": "16-106",
      "translation": "Quiconque a renié Allah après avoir cru... - sauf celui qui y a été contraint alors que son cœur demeure plein de la sérénité de la foi - mais ceux qui ouvrent délibérément leur cœur à la mécréance, ceux-là ont sur eux une colère d'Allah et ils ont un châtiment terrible"
    },
    {
      "key": 2008,
      "id": "16-107",
      "translation": "Il en est ainsi, parce qu'ils ont aimé la vie présente plus que l'au-delà. Et Allah, vraiment, ne guide pas les gens mécréants"
    },
    {
      "key": 2009,
      "id": "16-108",
      "translation": "Voilà ceux dont Allah a scellé les cœurs, l'ouïe, et les yeux. Ce sont eux les insouciants"
    },
    {
      "key": 2010,
      "id": "16-109",
      "translation": "Et nul doute que dans l'au-delà, ils seront les perdants"
    },
    {
      "key": 2011,
      "id": "16-110",
      "translation": "Quant à ceux qui ont émigré après avoir subi des épreuves, puis ont lutté et ont enduré, ton Seigneur après cela, est certes Pardonneur et Miséricordieux"
    },
    {
      "key": 2012,
      "id": "16-111",
      "translation": "(Rappelle-toi) le jour où chaque âme viendra, plaidant pour elle-même, et chaque âme sera pleinement rétribuée pour ce qu'elle aura œuvré sans qu'ils subissent la moindre injustice"
    },
    {
      "key": 2013,
      "id": "16-112",
      "translation": "Et Allah propose en parabole une ville: elle était en sécurité, tranquille; sa part de nourriture lui venait de partout en abondance. Puis elle se montra ingrate aux bienfaits d'Allah. Allah lui fit alors goûter la violence de la faim et de la peur [en punition] de ce qu'ils faisaient"
    },
    {
      "key": 2014,
      "id": "16-113",
      "translation": "En effet, un Messager des leurs est venu à eux, mais ils l'ont traité de menteur. Le châtiment, donc, les saisit parce qu'ils étaient injustes"
    },
    {
      "key": 2015,
      "id": "16-114",
      "translation": "Mangez donc de ce qu'Allah vous a attribué de licite et de bon. Et soyez reconnaissants pour les bienfaits d'Allah, si c'est Lui que vous adorez"
    },
    {
      "key": 2016,
      "id": "16-115",
      "translation": "Il vous a, en effet, interdit (la chair) de la bête morte, le sang, la chair de porc, et la bête sur laquelle un autre nom que celui d'Allah a été invoqué. Mais quiconque en mange sous contrainte, et n'est ni rebelle ni transgresseur, alors Allah est Pardonneur et Miséricordieux"
    },
    {
      "key": 2017,
      "id": "16-116",
      "translation": "Et ne dites pas, conformément aux mensonges proférés par vos langues: «Ceci est licite, et cela est illicite», pour forger le mensonge contre Allah. Certes, ceux qui forgent le mensonge contre Allah ne réussiront pas"
    },
    {
      "key": 2018,
      "id": "16-117",
      "translation": "Ce sera pour eux une piètre jouissance, mais un douloureux châtiment les attend"
    },
    {
      "key": 2019,
      "id": "16-118",
      "translation": "Aux Juifs, Nous avions interdit ce que Nous t'avons déjà relaté. Nous ne leur avons fait aucun tort; mais ils se faisaient du tort à eux-mêmes"
    },
    {
      "key": 2020,
      "id": "16-119",
      "translation": "Puis ton Seigneur envers ceux qui ont commis le mal par ignorance, et se sont par la suite repentis et ont amélioré leur conduite, ton Seigneur, après cela est certes Pardonneur et Miséricordieux"
    },
    {
      "key": 2021,
      "id": "16-120",
      "translation": "Abraham était un guide ('Umma) parfait. Il était soumis à Allah, voué exclusivement à Lui et il n'était point du nombre des associateurs"
    },
    {
      "key": 2022,
      "id": "16-121",
      "translation": "Il était reconnaissant pour Ses bienfaits et Allah l'avait élu et guidé vers un droit chemin"
    },
    {
      "key": 2023,
      "id": "16-122",
      "translation": "Nous lui avons donné une belle part ici-bas. Et il sera certes dans l'au-delà du nombre des gens de bien"
    },
    {
      "key": 2024,
      "id": "16-123",
      "translation": "Puis Nous t'avons révélé: «Suis la religion d'Abraham qui était voué exclusivement à Allah et n'était point du nombre des associateurs»"
    },
    {
      "key": 2025,
      "id": "16-124",
      "translation": "Le Sabbat n'a été imposé qu'à ceux qui divergeaient à son sujet. Au Jour de la Résurrection, ton Seigneur jugera certainement au sujet de ce dont ils divergeaient"
    },
    {
      "key": 2026,
      "id": "16-125",
      "translation": "Par la sagesse et la bonne exhortation appelle (les gens) au sentier de ton Seigneur. Et discute avec eux de la meilleure façon. Car c'est ton Seigneur qui connaît le mieux celui qui s'égare de Son sentier et c'est Lui qui connaît le mieux ceux qui sont bien guidés"
    },
    {
      "key": 2027,
      "id": "16-126",
      "translation": "Et si vous punissez, infligez [à l'agresseur] une punition égale au tort qu'il vous a fait. Et si vous endurez... cela est certes meilleur pour les endurants"
    },
    {
      "key": 2028,
      "id": "16-127",
      "translation": "Endure! Ton endurance [ne viendra] qu'avec (l'aide) d'Allah. Ne t'afflige pas pour eux. Et ne sois pas angoissé à cause de leurs complots"
    },
    {
      "key": 2029,
      "id": "16-128",
      "translation": "Certes, Allah est avec ceux qui [L'] ont craint avec piété et ceux qui sont bienfaisants"
    },
    {
      "key": 2030,
      "id": "17-1",
      "translation": "Gloire et Pureté à Celui qui de nuit, fit voyager Son serviteur, de la Mosquée Al-Harâm à la Mosquée Al-Aqsâ dont Nous avons béni l'alentour, afin de lui faire voir certaines de Nos merveilles. C'est Lui, vraiment, qui est l'Audient, le Clairvoyant"
    },
    {
      "key": 2031,
      "id": "17-2",
      "translation": "Et Nous avions donné à Moïse le Livre dont Nous avions fait un guide pour les Enfants d'Israël: «Ne prenez pas de protecteur en dehors de Moi»"
    },
    {
      "key": 2032,
      "id": "17-3",
      "translation": "[O vous], les descendants de ceux que Nous avons transportés dans l'arche avec Noé. Celui-ci était vraiment un serviteur fort reconnaissant"
    },
    {
      "key": 2033,
      "id": "17-4",
      "translation": "Nous avions décrété pour les Enfants d'Israël, (et annoncé) dans le Livre: «Par deux fois vous sèmerez la corruption sur terre et vous allez transgresser d'une façon excessive»"
    },
    {
      "key": 2034,
      "id": "17-5",
      "translation": "Lorsque vint l'accomplissement de la première de ces deux [prédictions,] Nous envoyâmes contre vous certains de Nos serviteurs doués d'une force terrible, qui pénétrèrent à l'intérieur des demeures. Et la prédiction fut accomplie"
    },
    {
      "key": 2035,
      "id": "17-6",
      "translation": "Ensuite, Nous vous donnâmes la revanche sur eux; et Nous vous renforçâmes en biens et en enfants. Et Nous vous fîmes [un peuple] plus nombreux"
    },
    {
      "key": 2036,
      "id": "17-7",
      "translation": "«Si vous faites le bien; vous le faites à vous-mêmes; et si vous faites le mal, vous le faites à vous [aussi]». Puis, quand vint la dernière [prédiction,] ce fut pour qu'ils affligent vos visages et entrent dans la Mosquée comme ils y étaient entrés la première fois, et pour qu'ils détruisent complètement ce dont ils se sont emparés"
    },
    {
      "key": 2037,
      "id": "17-8",
      "translation": "Il se peut que votre Seigneur vous fasse miséricorde. Mais si vous récidivez, Nous récidiverons. Et Nous avons assigné l'Enfer comme camp de détention aux infidèles"
    },
    {
      "key": 2038,
      "id": "17-9",
      "translation": "Certes, ce Coran guide vers ce qu'il y a de plus droit, et il annonce aux croyants qui font de bonnes œuvres qu'ils auront une grande récompense"
    },
    {
      "key": 2039,
      "id": "17-10",
      "translation": "et à ceux qui ne croient pas en l'au-delà, que Nous leur avons préparé un châtiment douloureux"
    },
    {
      "key": 2040,
      "id": "17-11",
      "translation": "L'homme appelle le mal comme il appelle le bien, car l'homme est très hâtif"
    },
    {
      "key": 2041,
      "id": "17-12",
      "translation": "Nous avons fait de la nuit et du jour deux signes, et Nous avons effacé le signe de la nuit, tandis que Nous avons rendu visible le signe du jour, pour que vous recherchiez des grâces de votre Seigneur, et que vous sachiez le nombre des années et le calcul du temps. Et Nous avons expliqué toute chose d'une manière détaillée"
    },
    {
      "key": 2042,
      "id": "17-13",
      "translation": "Et au cou de chaque homme, Nous avons attaché son œuvre. Et au Jour de la Résurrection, Nous lui sortirons un écrit qu'il trouvera déroulé"
    },
    {
      "key": 2043,
      "id": "17-14",
      "translation": "«Lis ton écrit. Aujourd'hui, tu te suffis d'être ton propre comptable»"
    },
    {
      "key": 2044,
      "id": "17-15",
      "translation": "Quiconque prend le droit chemin ne le prend que pour lui-même; et quiconque s'égare, ne s'égare qu'à son propre détriment. Et nul ne portera le fardeau d'autrui. Et Nous n'avons jamais puni [un peuple] avant de [lui] avoir envoyé un Messager"
    },
    {
      "key": 2045,
      "id": "17-16",
      "translation": "Et quand Nous voulons détruire une cité, Nous ordonnons à ses gens opulents [d'obéir à Nos prescriptions], mais (au contraire) ils se livrent à la perversité. Alors la Parole prononcée contre elle se réalise, et Nous la détruisons entièrement"
    },
    {
      "key": 2046,
      "id": "17-17",
      "translation": "Que de générations avons-nous exterminées, après Noé! Et ton Seigneur suffit qu'Il soit Parfaitement Connaisseur et Clairvoyant sur les péchés de Ses serviteurs"
    },
    {
      "key": 2047,
      "id": "17-18",
      "translation": "Quiconque désire [la vie] immédiate, Nous nous hâtons de donner ce que Nous voulons, à qui Nous voulons. Puis, Nous lui assignons l'Enfer où il brûlera méprisé et repoussé"
    },
    {
      "key": 2048,
      "id": "17-19",
      "translation": "Et ceux qui recherchent l'au-delà et fournissent les efforts qui y mènent, tout en étant croyants... alors l'effort de ceux-là sera reconnu"
    },
    {
      "key": 2049,
      "id": "17-20",
      "translation": "Nous accordons abondamment à tous, ceux-ci comme ceux-là, des dons de ton Seigneur. Et les dons de ton Seigneur ne sont refusés [à personne]"
    },
    {
      "key": 2050,
      "id": "17-21",
      "translation": "Regarde comment Nous favorisons certains sur d'autres. Et dans l'au-delà, il y a des rangs plus élevés et plus privilégiés"
    },
    {
      "key": 2051,
      "id": "17-22",
      "translation": "N'assigne point à Allah d'autre divinité; sinon tu te trouveras méprisé et abandonné"
    },
    {
      "key": 2052,
      "id": "17-23",
      "translation": "Et ton Seigneur a décrété: «N'adorez que Lui; et (marquez) de la bonté envers les père et mère: si l'un d'eux ou tous deux doivent atteindre la vieillesse auprès de toi, alors ne leur dis point: «Fi!» et ne les brusque pas, mais adresse-leur des paroles respectueuses"
    },
    {
      "key": 2053,
      "id": "17-24",
      "translation": "et par miséricorde, abaisse pour eux l'aile de l'humilité, et dis: «O mon Seigneur, fais-leur, à tous deux, miséricorde comme ils m'ont élevé tout petit»"
    },
    {
      "key": 2054,
      "id": "17-25",
      "translation": "Votre Seigneur connaît mieux ce qu'il y a dans vos âmes. Si vous êtes bons, Il est certes Pardonneur pour ceux qui Lui reviennent se repentant"
    },
    {
      "key": 2055,
      "id": "17-26",
      "translation": "«Et donne au proche parent ce qui lui est dû ainsi qu'au pauvre et au voyageur (en détresse). Et ne gaspille pas indûment"
    },
    {
      "key": 2056,
      "id": "17-27",
      "translation": "car les gaspilleurs sont les frères des diables; et le Diable est très ingrat envers son Seigneur"
    },
    {
      "key": 2057,
      "id": "17-28",
      "translation": "Si tu t'écartes d'eux à la recherche d'une miséricorde de Ton Seigneur, que tu espères, adresse-leur une parole bienveillante"
    },
    {
      "key": 2058,
      "id": "17-29",
      "translation": "Ne porte pas ta main enchaînée à ton cou [par avarice], et ne l'étend pas non plus trop largement, sinon tu te trouveras blâmé et chagriné"
    },
    {
      "key": 2059,
      "id": "17-30",
      "translation": "En vérité ton Seigneur étend Ses dons largement à qu'Il veut ou les accorde avec parcimonie. Il est, sur Ses serviteurs, Parfaitement Connaisseur et Clairvoyant"
    },
    {
      "key": 2060,
      "id": "17-31",
      "translation": "Et ne tuez pas vos enfants par crainte de pauvreté; c'est Nous qui attribuons leur subsistance, tout comme à vous. Les tuer, c'est vraiment, un énorme péché"
    },
    {
      "key": 2061,
      "id": "17-32",
      "translation": "Et n'approchez point la fornication. En vérité, c'est une turpitude et quel mauvais chemin"
    },
    {
      "key": 2062,
      "id": "17-33",
      "translation": "Et, sauf en droit, ne tuez point la vie qu'Allah a rendu sacrée. Quiconque est tué injustement, alors Nous avons donné pouvoir à son proche [parent]. Que celui-ci ne commette pas d'excès dans le meurtre, car il est déjà assisté (par la loi)"
    },
    {
      "key": 2063,
      "id": "17-34",
      "translation": "Et n'approchez les biens de l'orphelin que de la façon la meilleure, jusqu'à ce qu'il atteigne sa majorité. Et remplissez l'engagement, car on sera interrogé au sujet des engagements"
    },
    {
      "key": 2064,
      "id": "17-35",
      "translation": "Et donnez la pleine mesure quand vous mesurez, et pesez avec une balance exacte. C'est mieux [pour vous] et le résultat en sera meilleur"
    },
    {
      "key": 2065,
      "id": "17-36",
      "translation": "Et ne poursuis pas ce dont tu n'as aucune connaissance. L'ouïe, la vue et le cœur: sur tout cela, en vérité, on sera interrogé"
    },
    {
      "key": 2066,
      "id": "17-37",
      "translation": "Et ne foule pas la terre avec orgueil: tu ne sauras jamais fendre la terre et tu ne pourras jamais atteindre la hauteur des montagnes"
    },
    {
      "key": 2067,
      "id": "17-38",
      "translation": "Ce qui est mauvais en tout cela est détesté de ton Seigneur"
    },
    {
      "key": 2068,
      "id": "17-39",
      "translation": "Tout cela fait partie de ce que ton Seigneur t'a révélé de la Sagesse. N'assigne donc pas à Allah d'autre divinité, sinon tu seras jeté dans l'Enfer, blâmé et repoussé"
    },
    {
      "key": 2069,
      "id": "17-40",
      "translation": "Votre Seigneur, aurait-Il réservé exclusivement pour vous des fils, et Lui, aurait-Il pris pour Lui des filles parmi les Anges? Vous prononcez là une parole monstrueuse"
    },
    {
      "key": 2070,
      "id": "17-41",
      "translation": "Très certainement Nous avons exposé [tout ceci] dans ce Coran afin que [les gens] réfléchissent. Mais cela ne fait qu'augmenter leur répulsion"
    },
    {
      "key": 2071,
      "id": "17-42",
      "translation": "Dis: «S'il y avait des divinités avec Lui, comme ils le disent, elles auraient alors cherché un chemin [pour atteindre] le Détenteur du Trône»"
    },
    {
      "key": 2072,
      "id": "17-43",
      "translation": "Pureté à Lui! Il est plus haut et infiniment au-dessus de ce qu'ils disent"
    },
    {
      "key": 2073,
      "id": "17-44",
      "translation": "Les sept cieux et la terre et ceux qui s'y trouvent, célèbrent Sa gloire. Et il n'existe rien qui ne célèbre Sa gloire et Ses louanges. Mais vous ne comprenez pas leur façon de Le glorifier. Certes c'est Lui qui est Indulgent et Pardonneur"
    },
    {
      "key": 2074,
      "id": "17-45",
      "translation": "Et quand tu lis le Coran, Nous plaçons, entre toi et ceux qui ne croient pas en l'au-delà, un voile invisible"
    },
    {
      "key": 2075,
      "id": "17-46",
      "translation": "Nous avons mis des voiles sur leurs cœurs, de sorte qu'ils ne le comprennent pas: et dans leurs oreilles, une lourdeur. Et quand, dans le Coran, tu évoques Ton Seigneur l'Unique, ils tournent le dos par répulsion"
    },
    {
      "key": 2076,
      "id": "17-47",
      "translation": "Nous savons très bien ce qu'ils écoutent. Quand ils t'écoutent et qu'ils chuchotent entre eux, les injustes disent: «Vous ne suivez qu'un homme ensorcelé»"
    },
    {
      "key": 2077,
      "id": "17-48",
      "translation": "Vois ce à quoi ils te comparent! Ils s'égarent donc et sont incapables de trouver un chemin (vers la vérité)"
    },
    {
      "key": 2078,
      "id": "17-49",
      "translation": "Et ils disent: «Quand nous serons ossements et poussière, serons-nous ressuscités en une nouvelle création?»"
    },
    {
      "key": 2079,
      "id": "17-50",
      "translation": "Dis: «Soyez pierre ou fer"
    },
    {
      "key": 2080,
      "id": "17-51",
      "translation": "ou toute autre créature que vous puissiez concevoir.» Ils diront alors: «Qui donc nous fera revenir?» - Dis: «Celui qui vous a créés la première fois». Ils secoueront vers toi leurs têtes et diront: «Quand cela?» Dis: «Il se peut que ce soit proche"
    },
    {
      "key": 2081,
      "id": "17-52",
      "translation": "Le jour où Il vous appellera, vous Lui répondrez en Le glorifiant. Vous penserez cependant que vous n'êtes restés [sur terre] que peu de temps!»"
    },
    {
      "key": 2082,
      "id": "17-53",
      "translation": "Et dis à Mes serviteurs d'exprimer les meilleures paroles, car le Diable sème la discorde parmi eux. Le Diable est certes, pour l'homme, un ennemi déclaré"
    },
    {
      "key": 2083,
      "id": "17-54",
      "translation": "Votre Seigneur vous connaît mieux. S'Il veut, Il vous fera miséricorde, et s'Il veut, Il vous châtiera. Et Nous ne t'avons pas envoyé pour que tu sois leur protecteur"
    },
    {
      "key": 2084,
      "id": "17-55",
      "translation": "Et ton Seigneur est plus Connaisseur de ceux qui sont dans les cieux et sur la terre. Et parmi les prophètes, Nous avons donné à certains plus de faveurs qu'à d'autres. Et à David Nous avons donné le «Zabûr»"
    },
    {
      "key": 2085,
      "id": "17-56",
      "translation": "Dis: «Invoquez ceux que vous prétendez, (être des divinités) en dehors de Lui. Ils ne possèdent ni le moyen de dissiper votre malheur ni de le détourner"
    },
    {
      "key": 2086,
      "id": "17-57",
      "translation": "Ceux qu'ils invoquent, cherchent [eux-mêmes], à qui mieux, le moyen de se rapprocher le plus de leur Seigneur. Ils espèrent Sa miséricorde et craignent Son châtiment. Le châtiment de ton Seigneur est vraiment redouté"
    },
    {
      "key": 2087,
      "id": "17-58",
      "translation": "Il n'est point de cité [injuste] que Nous ne fassions périr avant le Jour de la Résurrection, ou que Nous ne punissions d'un dur châtiment. Cela est bien tracé dans le Livre [des décrets immuables]"
    },
    {
      "key": 2088,
      "id": "17-59",
      "translation": "Rien ne Nous empêche d'envoyer les miracles, si ce n'est que les Anciens les avaient traités de mensonges. Nous avions apporté aux Thamûd la chamelle qui était un [miracle] visible: mais ils lui firent du tort. En outre, Nous n'envoyons de miracles qu'à titre de menace"
    },
    {
      "key": 2089,
      "id": "17-60",
      "translation": "Et lorsque Nous te disions que ton Seigneur cerne tous les gens (par Sa puissance et Son savoir). Quant à la vision que Nous t'avons montrée, Nous ne l'avons faite que pour éprouver les gens, tout comme l'arbre maudit mentionné dans le Coran. Nous les menaçons, mais cela ne fait qu'augmenter leur grande transgression"
    },
    {
      "key": 2090,
      "id": "17-61",
      "translation": "Et lorsque Nous avons dit aux Anges: «Prosternez-vous devant Adam», ils se prosternèrent, à l'exception d'Iblis, qui dit: «Me prosternerai-je devant quelqu'un que Tu as créé d'argile?»"
    },
    {
      "key": 2091,
      "id": "17-62",
      "translation": "Il dit encore: «Vois-Tu? Celui que Tu as honoré au-dessus de moi, si Tu me donnais du répit jusqu'au Jour de la Résurrection, j'éprouverai, certes, sa descendance excepté un petit nombre [parmi eux]»"
    },
    {
      "key": 2092,
      "id": "17-63",
      "translation": "Et [Allah] dit: «Va-t-en! Quiconque d'entre eux te suivra... votre sanction sera l'Enfer, une ample rétribution"
    },
    {
      "key": 2093,
      "id": "17-64",
      "translation": "Excite, par ta voix, ceux d'entre eux que tu pourras, rassemble contre eux ta cavalerie et ton infanterie, associe-toi à eux dans leur biens et leurs enfants et fais-leur des promesses». Or, le Diable ne leur fait des promesses qu'en tromperie"
    },
    {
      "key": 2094,
      "id": "17-65",
      "translation": "Quant à Mes serviteurs, tu n'as aucun pouvoir sur eux». Et ton Seigneur suffit pour les protéger"
    },
    {
      "key": 2095,
      "id": "17-66",
      "translation": "Votre Seigneur est Celui qui fait voguer le vaisseau pour vous en mer, afin que vous alliez à la recherche de quelque grâce de Sa part; Certes Il est Miséricordieux envers vous"
    },
    {
      "key": 2096,
      "id": "17-67",
      "translation": "Et quand le mal vous touche en mer, ceux que vous invoquiez en dehors de Lui se perdent. Puis, quand Il vous sauve et vous ramène à terre, vous vous détournez. L'homme reste très ingrat"
    },
    {
      "key": 2097,
      "id": "17-68",
      "translation": "Etes-vous à l'abri de ce qu'Il vous fasse engloutir par un pan de terre, ou qu'Il envoie contre vous un ouragan (avec pluie en pierres) et que vous ne trouverez alors aucun protecteur"
    },
    {
      "key": 2098,
      "id": "17-69",
      "translation": "Ou êtes-vous à l'abri de ce qu'Il vous y ramène (en mer) une autre fois, qu'Il déchaîne contre vous un de ces vents à tout casser, puis qu'Il vous fasse noyer à cause de votre mécréance? Et alors vous ne trouverez personne pour vous défendre contre Nous"
    },
    {
      "key": 2099,
      "id": "17-70",
      "translation": "Certes, Nous avons honoré les fils d'Adam. Nous les avons transportés sur terre et sur mer, leur avons attribué de bonnes choses comme nourriture, et Nous les avons nettement préférés à plusieurs de Nos créatures"
    },
    {
      "key": 2100,
      "id": "17-71",
      "translation": "Le jour où Nous appellerons chaque groupement d'hommes par leur chef, ceux à qui on remettra leur livre dans la main droite liront leur livre (avec plaisir) et ne subiront pas la moindre injustice"
    },
    {
      "key": 2101,
      "id": "17-72",
      "translation": "Et quiconque aura été aveugle ici-bas, sera aveugle dans l'au-delà, et sera plus égaré [encore] par rapport à la bonne voie"
    },
    {
      "key": 2102,
      "id": "17-73",
      "translation": "Ils ont failli te détourner de ce que Nous t'avions révélé, [dans l'espoir] qu'à la place de ceci, tu inventes quelque chose d'autre et (l'imputes) à Nous. Et alors, ils t'auraient pris pour ami intime"
    },
    {
      "key": 2103,
      "id": "17-74",
      "translation": "Et si Nous ne t'avions pas raffermi, tu aurais bien failli t'incliner quelque peu vers eux"
    },
    {
      "key": 2104,
      "id": "17-75",
      "translation": "Alors, Nous t'aurions certes fait goûter le double [supplice] de la vie et le double [supplice] de la mort; et ensuite tu n'aurais pas trouvé de secoureur contre Nous"
    },
    {
      "key": 2105,
      "id": "17-76",
      "translation": "En vérité, ils ont failli t'inciter à fuir du pays pour t'en bannir. Mais dans ce cas, ils n'y seraient pas restés longtemps après toi"
    },
    {
      "key": 2106,
      "id": "17-77",
      "translation": "Telle fut la règle appliquée par Nous à Nos messagers que Nous avons envoyés avant toi. Et tu ne trouveras pas de changement en Notre règle"
    },
    {
      "key": 2107,
      "id": "17-78",
      "translation": "Accomplis la Salât au déclin du soleil jusqu'à l'obscurité de la nuit, et [fais] aussi la Lecture à l'aube, car la Lecture à l'aube a des témoins"
    },
    {
      "key": 2108,
      "id": "17-79",
      "translation": "Et de la nuit consacre une partie [avant l'aube] pour des Salât surérogatoires: afin que ton Seigneur te ressuscite en une position de gloire"
    },
    {
      "key": 2109,
      "id": "17-80",
      "translation": "Et dis: «O mon Seigneur, fais que j'entre par une entrée de vérité et que je sorte par une sortie de vérité; et accorde-moi de Ta part, un pouvoir bénéficiant de Ton secours»"
    },
    {
      "key": 2110,
      "id": "17-81",
      "translation": "Et dis: «La Vérité (l'Islam) est venue et l'Erreur a disparu. Car l'Erreur est destinée à disparaître»"
    },
    {
      "key": 2111,
      "id": "17-82",
      "translation": "Nous faisons descendre du Coran, ce qui est une guérison et une miséricorde pour les croyants. Cependant, cela ne fait qu'accroître la perdition des injustes"
    },
    {
      "key": 2112,
      "id": "17-83",
      "translation": "Et quand Nous comblons l'homme de bienfaits, il se détourne et se replie sur lui-même; et quand un mal le touche, le voilà profondément désespéré"
    },
    {
      "key": 2113,
      "id": "17-84",
      "translation": "Dis: «Chacun agit selon sa méthode, alors que votre Seigneur connaît mieux qui suit la meilleure voie»"
    },
    {
      "key": 2114,
      "id": "17-85",
      "translation": "Et ils t'interrogent au sujet de l'âme, - Dis: «L'âme relève de l'Ordre de mon Seigneur». Et on ne vous a donné que peu de connaissance"
    },
    {
      "key": 2115,
      "id": "17-86",
      "translation": "Si Nous voulons, Nous pouvons certes faire disparaître ce que Nous t'avons révélé; et tu n'y trouverais par la suite aucun défenseur contre Nous"
    },
    {
      "key": 2116,
      "id": "17-87",
      "translation": "Si ce n'est par une miséricorde de ton Seigneur, car en vérité Sa grâce sur toi est grande"
    },
    {
      "key": 2117,
      "id": "17-88",
      "translation": "Dis: «Même si les hommes et les djinns s'unissaient pour produire quelque chose de semblable à ce Coran, ils ne sauraient produire rien de semblable, même s'ils se soutenaient les uns les autres»"
    },
    {
      "key": 2118,
      "id": "17-89",
      "translation": "Et certes, Nous avons déployé pour les gens, dans ce Coran, toutes sortes d'exemples. Mais la plupart des gens s'obstinent à être mécréants"
    },
    {
      "key": 2119,
      "id": "17-90",
      "translation": "Et ils dirent: «Nous ne croirons pas en toi, jusqu'à ce que tu aies fait jaillir de terre, pour nous, une source"
    },
    {
      "key": 2120,
      "id": "17-91",
      "translation": "ou que tu aies un jardin de palmiers et de vignes, entre lesquels tu feras jaillir des ruisseaux en abondance"
    },
    {
      "key": 2121,
      "id": "17-92",
      "translation": "ou que tu fasses tomber sur nous, comme tu le prétends, le ciel en morceaux; ou que tu fasses venir Allah et les Anges en face de nous"
    },
    {
      "key": 2122,
      "id": "17-93",
      "translation": "ou que tu aies une maison [garnie] d'ornements; ou que tu sois monté au ciel. Encore ne croirons-nous pas à ta montée au ciel, jusqu'à ce que tu fasses descendre sur nous un Livre que nous puissions lire». Dis-[leur]: «Gloire à mon Seigneur! Ne suis-je qu'un être humain-Messager?»"
    },
    {
      "key": 2123,
      "id": "17-94",
      "translation": "Et rien n'empêcha les gens de croire, quand le guide leur est parvenu, si ce n'est qu'ils disaient: «Allah envoie-t-Il un être humain-Messager?»"
    },
    {
      "key": 2124,
      "id": "17-95",
      "translation": "Dis: «S'il y avait sur terre des Anges marchant tranquillement, Nous aurions certes fait descendre sur eux du ciel un Ange-Messager»"
    },
    {
      "key": 2125,
      "id": "17-96",
      "translation": "Dis: «Allah suffit comme témoin entre vous et moi». Il est, sur Ses serviteurs, Parfaitement Connaisseur et Clairvoyant"
    },
    {
      "key": 2126,
      "id": "17-97",
      "translation": "Celui qu'Allah guide, c'est lui le bien-guidé et ceux qu'Il égare... tu ne leur trouveras jamais d'alliés en dehors de Lui et au Jour de la Résurrection, Nous les rassemblerons traînés sur leurs visages, aveugles, muets et sourds. L'Enfer sera leur demeure: chaque fois que son feu s'affaiblit, Nous leur accroîtrons la flamme ardente"
    },
    {
      "key": 2127,
      "id": "17-98",
      "translation": "Telle sera leur sanction parce qu'ils ne croient pas en Nos preuves et disent: «Quand nous serons ossements et poussière, serons-nous ressuscités en une nouvelle création?»"
    },
    {
      "key": 2128,
      "id": "17-99",
      "translation": "N'ont-ils pas vu qu'Allah qui a créé les cieux et la terre est capable de créer leurs pareils? Il leur a fixé un terme, sur lequel il n'y a aucun doute, mais les injustes s'obstinent dans leur mécréance"
    },
    {
      "key": 2129,
      "id": "17-100",
      "translation": "Dis: «Si c'était vous qui possédiez les trésors de la miséricorde de mon Seigneur, vous lésineriez, certes, de peur de les dépenser. Et l'homme est très avare"
    },
    {
      "key": 2130,
      "id": "17-101",
      "translation": "Et certes, Nous donnâmes à Moïse neuf miracles évidents. Demande donc aux Enfants d'Israël, lorsqu'il leur vint et que Pharaon lui dit: «O Moïse, je pense que tu es ensorcelé»"
    },
    {
      "key": 2131,
      "id": "17-102",
      "translation": "Il dit: «Tu sais fort bien que ces choses [les miracles], seul le Seigneur des cieux et de la terre les a fait descendre comme autant de preuves illuminantes; et certes, O Pharaon, je te crois perdu»"
    },
    {
      "key": 2132,
      "id": "17-103",
      "translation": "[Pharaon] voulut donc les expulser du pays. Alors Nous les noyâmes tous, lui et ceux qui étaient avec lui"
    },
    {
      "key": 2133,
      "id": "17-104",
      "translation": "Et après lui, Nous dîmes aux Enfants d'Israël: «Habitez la terre». Puis, lorsque viendra la promesse de la (vie) dernière, Nous vous ferons venir en foule"
    },
    {
      "key": 2134,
      "id": "17-105",
      "translation": "Et c'est en toute vérité que Nous l'avons fait descendre (le Coran), et avec la vérité il est descendu, et Nous ne t'avons envoyé qu'en annonciateur et avertisseur"
    },
    {
      "key": 2135,
      "id": "17-106",
      "translation": "(Nous avons fait descendre) un Coran que Nous avons fragmenté, pour que tu le lises lentement aux gens. Et Nous l'avons fait descendre graduellement"
    },
    {
      "key": 2136,
      "id": "17-107",
      "translation": "Dis: «Croyez-y ou n'y croyez pas. Ceux à qui la connaissance a été donnée avant cela, lorsqu'on le leur récite, tombent, prosternés, le menton contre terre"
    },
    {
      "key": 2137,
      "id": "17-108",
      "translation": "et disent: «Gloire à notre Seigneur! La promesse de notre Seigneur est assurément accomplie»"
    },
    {
      "key": 2138,
      "id": "17-109",
      "translation": "Et ils tombent sur leur menton, pleurant, et cela augmente leur humilité"
    },
    {
      "key": 2139,
      "id": "17-110",
      "translation": "Dis: «Invoquez Allah, ou invoquez le Tout Miséricordieux. Quel que soit le nom par lequel vous l'appelez, Il a les plus beaux noms. Et dans ta Salât, ne récite pas à voix haute; et ne l'y abaisse pas trop, mais cherche le juste milieu entre les deux»"
    },
    {
      "key": 2140,
      "id": "17-111",
      "translation": "Et dis: «Louange à Allah qui ne S'est jamais attribué d'enfant, qui n'a point d'associé en la royauté et qui n'a jamais eu de protecteur de l'humiliation». Et proclame hautement Sa grandeur"
    },
    {
      "key": 2141,
      "id": "18-1",
      "translation": "Louange à Allah qui a fait descendre sur Son serviteur , le Livre, et n'y a point introduit de tortuosité (ambiguité)"
    },
    {
      "key": 2142,
      "id": "18-2",
      "translation": "[Un Livre] d'une parfaite droiture pour avertir d'une sévère punition venant de Sa part et pour annoncer aux croyants qui font de bonnes œuvres qu'il y aura pour eux une belle récompense"
    },
    {
      "key": 2143,
      "id": "18-3",
      "translation": "où ils demeureront éternellement"
    },
    {
      "key": 2144,
      "id": "18-4",
      "translation": "et pour avertir ceux qui disent: «Allah S'est attribué un enfant.»"
    },
    {
      "key": 2145,
      "id": "18-5",
      "translation": "Ni eux ni leurs ancêtres n'en savent rien. Quelle monstrueuse parole que celle qui sort de leurs bouches! Ce qu'ils disent n'est que mensonge"
    },
    {
      "key": 2146,
      "id": "18-6",
      "translation": "Tu vas peut-être te consumer de chagrin parce qu'ils se détournent de toi et ne croient pas en ce discours"
    },
    {
      "key": 2147,
      "id": "18-7",
      "translation": "Nous avons placé ce qu'il y a sur la terre pour l'embellir, afin d'éprouver (les hommes et afin de savoir) qui d'entre eux sont les meilleurs dans leurs actions"
    },
    {
      "key": 2148,
      "id": "18-8",
      "translation": "Puis, Nous allons sûrement transformer sa surface en un sol aride"
    },
    {
      "key": 2149,
      "id": "18-9",
      "translation": "Penses-tu que les gens de la Caverne et d'ar-Raquîm ont constitué une chose extraordinaire d'entre Nos prodiges"
    },
    {
      "key": 2150,
      "id": "18-10",
      "translation": "Quand les jeunes gens se furent réfugiés dans la caverne, ils dirent: «O notre Seigneur, donne-nous de Ta part une miséricorde; et assure nous la droiture dans tout ce qui nous concerne»"
    },
    {
      "key": 2151,
      "id": "18-11",
      "translation": "Alors, Nous avons assourdi leurs oreilles, dans la caverne pendant de nombreuses années"
    },
    {
      "key": 2152,
      "id": "18-12",
      "translation": "Ensuite, Nous les avons ressuscités, afin de savoir lequel des deux groupes saurait le mieux calculer la durée exacte de leur séjour"
    },
    {
      "key": 2153,
      "id": "18-13",
      "translation": "Nous allons te raconter leur récit en toute vérité. Ce sont des jeunes gens qui croyaient en leur Seigneur; et Nous leur avons accordé les plus grands moyens de se diriger [dans la bonne voie]"
    },
    {
      "key": 2154,
      "id": "18-14",
      "translation": "Nous avons fortifié leurs cœurs lorsqu'ils s'étaient levés pour dire: «Notre Seigneur est le Seigneur des cieux et de la terre: jamais nous n'invoquerons de divinité en dehors de Lui, sans quoi, nous transgresserions dans nos paroles"
    },
    {
      "key": 2155,
      "id": "18-15",
      "translation": "Voilà que nos concitoyens ont adopté en dehors de Lui des divinités. Que n'apportent-ils sur elles une preuve évidente? Quel pire injuste, donc que celui qui invente un mensonge contre Allah"
    },
    {
      "key": 2156,
      "id": "18-16",
      "translation": "Et quand vous vous serez séparés d'eux et de ce qu'ils adorent en dehors d'Allah, réfugiez-vous donc dans la caverne: votre Seigneur répandra de Sa miséricorde sur vous et disposera pour vous un adoucissement à votre sort"
    },
    {
      "key": 2157,
      "id": "18-17",
      "translation": "Tu aurais vu le soleil, quand il se lève, s'écarter de leur caverne vers la droite, et quand il se couche, passer à leur gauche, tandis qu'eux-mêmes sont là dans une partie spacieuse (de la caverne)... Cela est une des merveilles d'Allah. Celui qu'Allah guide, c'est lui le bien-guidé. Et quiconque Il égare, tu ne trouveras alors pour lui aucun allié pour le mettre sur la bonne voie"
    },
    {
      "key": 2158,
      "id": "18-18",
      "translation": "Et tu les aurais cru éveillés, alors qu'ils dorment. Et Nous les tournons sur le côté droit et sur le côté gauche, tandis que leur chien est à l'entrée, pattes étendues. Si tu les avais aperçus, certes tu leur aurais tourné le dos en fuyant; et tu aurais été assurément rempli d'effroi devant eux"
    },
    {
      "key": 2159,
      "id": "18-19",
      "translation": "Et c'est ainsi que Nous les ressuscitâmes, afin qu'ils s'interrogent entre eux. L'un parmi eux dit: «Combien de temps avez-vous demeuré là?» Ils dirent: «Nous avons demeuré un jour ou une partie d'un jour». D'autres dirent: «Votre Seigneur sait mieux combien [de temps] vous y avez demeuré. Envoyez donc l'un de vous à la ville avec votre argent que voici, pour qu'il voie quel aliment est le plus pur et qu'il vous en apporte de quoi vous nourrir. Qu'il agisse avec tact; et qu'il ne donne l'éveil à personne sur vous"
    },
    {
      "key": 2160,
      "id": "18-20",
      "translation": "Si jamais ils vous attrapent, ils vous lapideront ou vous feront retourner à leur religion, et vous ne réussirez alors plus jamais»"
    },
    {
      "key": 2161,
      "id": "18-21",
      "translation": "Et c'est ainsi que Nous fîmes qu'ils furent découverts, afin qu'ils [les gens de la cité] sachent que la promesse d'Allah est vérité et qu'il n'y ait point de doute au sujet de l'Heure. Aussi se disputèrent-ils à leur sujet et déclarèrent-ils: «Construisez sur eux un édifice. Leur Seigneur les connaît mieux». Mais ceux qui l'emportèrent [dans la discussion] dirent: «Elevons sur eux un sanctuaire»"
    },
    {
      "key": 2162,
      "id": "18-22",
      "translation": "Ils diront: «ils étaient trois et le quatrième était leur chien». Et ils diront en conjecturant sur leur mystère qu'ils étaient cinq, le sixième étant leur chien et ils diront: «sept, le huitième étant leur chien». Dis: «Mon Seigneur connaît mieux leur nombre. Il n'en est que peu qui le savent». Ne discute à leur sujet que d'une façon apparente et ne consulte personne en ce qui les concerne"
    },
    {
      "key": 2163,
      "id": "18-23",
      "translation": "Et ne dis jamais, à propos d'une chose: «Je la ferai sûrement demain»"
    },
    {
      "key": 2164,
      "id": "18-24",
      "translation": "sans ajouter: «Si Allah le veut», et invoque ton Seigneur quand tu oublies et dis: «Je souhaite que mon Seigneur me guide et me mène plus près de ce qui est correct»"
    },
    {
      "key": 2165,
      "id": "18-25",
      "translation": "Or, ils demeurèrent dans leur caverne trois cents ans et en ajoutèrent neuf (années)"
    },
    {
      "key": 2166,
      "id": "18-26",
      "translation": "Dis: «Allah sait mieux combien de temps ils demeurèrent là. A Lui appartient l'Inconnaissable des cieux et de la terre. Comme Il est Voyant et Audient! Ils n'ont aucun allié en dehors de Lui et Il n'associe personne à Son commandement"
    },
    {
      "key": 2167,
      "id": "18-27",
      "translation": "Et récite ce qui t'a été révélé du Livre de ton Seigneur. Nul ne peut changer Ses paroles. Et tu ne trouveras, en dehors de Lui, aucun refuge"
    },
    {
      "key": 2168,
      "id": "18-28",
      "translation": "Fais preuve de patience [en restant] avec ceux qui invoquent leur Seigneur matin et soir, désirant Sa Face. Et que tes yeux ne se détachent point d'eux, en cherchant (le faux) brillant de la vie sur terre. Et n'obéis pas à celui dont Nous avons rendu le cœur inattentif à Notre Rappel, qui poursuit sa passion et dont le comportement est outrancier"
    },
    {
      "key": 2169,
      "id": "18-29",
      "translation": "Et dis: «La vérité émane de votre Seigneur». Quiconque le veut, qu'il croie, quiconque le veut qu'il mécroie». Nous avons préparé pour les injustes un Feu dont les flammes les cernent. Et s'ils implorent à boire on les abreuvera d'une eau comme du métal fondu brûlant les visages. Quelle mauvaise boisson et quelle détestable demeure"
    },
    {
      "key": 2170,
      "id": "18-30",
      "translation": "Ceux qui croient et font de bonnes œuvres... vraiment Nous ne laissons pas perdre la récompense de celui qui fait le bien"
    },
    {
      "key": 2171,
      "id": "18-31",
      "translation": "Voilà ceux qui auront les jardins du séjour (éternel) sous lesquels coulent les ruisseaux. Ils y seront parés de bracelets d'or et se vêtiront d'habits verts de soie fine et de brocart, accoudés sur des divans (bien ornés). Quelle bonne récompense et quelle belle demeure"
    },
    {
      "key": 2172,
      "id": "18-32",
      "translation": "Donne-leur l'exemple de deux hommes: à l'un d'eux Nous avons assigné deux jardins de vignes que Nous avons entourés de palmiers et Nous avons mis entre les deux jardins des champs cultivés"
    },
    {
      "key": 2173,
      "id": "18-33",
      "translation": "Les deux jardins produisaient leur récolte sans jamais manquer. Et Nous avons fait jaillir entre eux un ruisseau"
    },
    {
      "key": 2174,
      "id": "18-34",
      "translation": "Et il avait des fruits et dit alors à son compagnon avec qui il conversait: «Je possède plus de biens que toi, et je suis plus puissant que toi grâce à mon clan»"
    },
    {
      "key": 2175,
      "id": "18-35",
      "translation": "Il entra dans son jardin coupable envers lui-même [par sa mécréance]; il dit: «Je ne pense pas que ceci puisse jamais périr"
    },
    {
      "key": 2176,
      "id": "18-36",
      "translation": "et je ne pense pas que l'Heure viendra. Et si on me ramène vers mon Seigneur, je trouverai certes meilleur lieu de retour que ce jardin"
    },
    {
      "key": 2177,
      "id": "18-37",
      "translation": "Son compagnon lui dit, tout en conversant avec lui: «Serais-tu mécréant envers Celui qui t'a créé de terre, puis de sperme et enfin t'a façonné en homme"
    },
    {
      "key": 2178,
      "id": "18-38",
      "translation": "Quant à moi, c'est Allah qui est mon Seigneur; et je n'associe personne à mon Seigneur"
    },
    {
      "key": 2179,
      "id": "18-39",
      "translation": "En entrant dans ton jardin, que ne dis-tu: «Telle est la volonté (et la grâce) d'Allah! Il n'y a de puissance que par Allah». Si tu me vois moins pourvu que toi en biens et en enfants"
    },
    {
      "key": 2180,
      "id": "18-40",
      "translation": "il se peut que mon Seigneur, bientôt, me donne quelque chose de meilleur que ton jardin, qu'Il envoie sur [ce dernier], du ciel, quelque calamité, et que son sol devienne glissant"
    },
    {
      "key": 2181,
      "id": "18-41",
      "translation": "ou que son eau tarisse de sorte que tu ne puisses plus la retrouver»"
    },
    {
      "key": 2182,
      "id": "18-42",
      "translation": "Et sa récolte fut détruite et il se mit alors à se tordre les deux mains à cause de ce qu'il y avait dépensé, cependant que ses treilles étaient complètement ravagées. Et il disait: «Que je souhaite n'avoir associé personne à mon Seigneur!»"
    },
    {
      "key": 2183,
      "id": "18-43",
      "translation": "Il n'eut aucun groupe de gens pour le secourir contre (la punition) d'Allah. Et il ne put se secourir lui-même"
    },
    {
      "key": 2184,
      "id": "18-44",
      "translation": "En l'occurrence, la souveraine protection appartient à Allah, le Vrai. Il accorde la meilleure récompense et le meilleur résultat"
    },
    {
      "key": 2185,
      "id": "18-45",
      "translation": "Et propose-leur l'exemple de la vie ici-bas. Elle est semblable à une eau que Nous faisons descendre du ciel; la végétation de la terre se mélange à elle. Puis elle devient de l'herbe desséchée que les vents dispersent. Allah est certes Puissant en toutes choses"
    },
    {
      "key": 2186,
      "id": "18-46",
      "translation": "Les biens et les enfants sont l'ornement de la vie de ce monde. Cependant, les bonnes œuvres qui persistent ont auprès de ton Seigneur une meilleure récompense et [suscitent] une belle espérance"
    },
    {
      "key": 2187,
      "id": "18-47",
      "translation": "Le jour où Nous ferons marcher les montagnes et où tu verras la terre nivelée (comme une plaine) et Nous les rassemblerons sans en omettre un seul"
    },
    {
      "key": 2188,
      "id": "18-48",
      "translation": "Et ils seront présentés en rangs devant ton Seigneur. «Vous voilà venus à Nous comme Nous vous avons créés la première fois. Pourtant vous prétendiez que Nous ne remplirions pas Nos promesses»"
    },
    {
      "key": 2189,
      "id": "18-49",
      "translation": "Et on déposera le livre (de chacun). Alors tu verras les criminels, effrayés à cause de ce qu'il y a dedans, dire: «Malheur à nous, qu'a donc ce livre à n'omettre de mentionner ni péché véniel ni péché capital?» Et ils trouveront devant eux tout ce qu'ils ont œuvré. Et ton Seigneur ne fait du tort à personne"
    },
    {
      "key": 2190,
      "id": "18-50",
      "translation": "Et lorsque nous dîmes aux Anges: «Prosternez-vous devant Adam», ils se prosternèrent, excepté Iblis [Satan] qui était du nombre des djinns et qui se révolta contre le commandement de son Seigneur. Allez-vous cependant le prendre, ainsi que sa descendance, pour alliés en dehors de Moi, alors qu'ils vous sont ennemis? Quel mauvais échange pour les injustes"
    },
    {
      "key": 2191,
      "id": "18-51",
      "translation": "Je ne les ai pas pris comme témoins de la création des cieux et de la terre, ni de la création de leurs propres personnes. Et Je n'ai pas pris comme aides ceux qui égarent"
    },
    {
      "key": 2192,
      "id": "18-52",
      "translation": "Et le jour où Il dira: «Appelez ceux que vous prétendiez être Mes associés». Ils les invoqueront; mais eux ne leur répondront pas, Nous aurons placé entre eux une vallée de perdition"
    },
    {
      "key": 2193,
      "id": "18-53",
      "translation": "Et les criminels verront le Feu. Ils seront alors convaincus qu'ils y tomberont et n'en trouveront pas d'échappatoire"
    },
    {
      "key": 2194,
      "id": "18-54",
      "translation": "Et assurément, Nous avons déployé pour les gens, dans ce Coran, toutes sortes d'exemples. L'homme cependant, est de tous les êtres le plus grand disputeur"
    },
    {
      "key": 2195,
      "id": "18-55",
      "translation": "Qu'est-ce qui a donc empêché les gens de croire, lorsque le guide leur est venu, ainsi que de demander pardon à leur Seigneur, si ce n'est qu'ils veulent subir le sort des Anciens, ou se trouver face à face avec le châtiment"
    },
    {
      "key": 2196,
      "id": "18-56",
      "translation": "Et Nous n'envoyons des messagers que pour annoncer la bonne nouvelle et avertir. Et ceux qui ont mécru disputent avec de faux arguments, afin d'infirmer la vérité et prennent en raillerie Mes versets (le Coran) ainsi que ce (châtiment) dont on les a avertis"
    },
    {
      "key": 2197,
      "id": "18-57",
      "translation": "Quel pire injuste que celui à qui on a rappelé les versets de son Seigneur et qui en détourna le dos en oubliant ce que ses deux mains ont commis? Nous avons placé des voiles sur leurs cœurs, de sorte qu'ils ne comprennent pas (le Coran), et mis une lourdeur dans leurs oreilles. Même si tu les appelles vers la bonne voie, jamais ils ne pourront donc se guider"
    },
    {
      "key": 2198,
      "id": "18-58",
      "translation": "Et ton Seigneur est le Pardonneur, le Détenteur de la miséricorde. S'Il s'en prenait à eux pour ce qu'ils ont acquis, Il leur hâterait certes le châtiment. Mais il y a pour eux un terme fixé (pour l'accomplissement des menaces) contre lequel ils ne trouveront aucun refuge"
    },
    {
      "key": 2199,
      "id": "18-59",
      "translation": "Et voilà les villes que Nous avons fait périr quand leurs peuples commirent des injustices et Nous avons fixé un rendez-vous pour leur destruction"
    },
    {
      "key": 2200,
      "id": "18-60",
      "translation": "(Rappelle-toi) quand Moïse dit à son valet: «Je n'arrêterai pas avant d'avoir atteint le confluent des deux mers, dussé-je marcher de longues années»"
    },
    {
      "key": 2201,
      "id": "18-61",
      "translation": "Puis, lorsque tous deux eurent atteint le confluent, Ils oublièrent leur poisson qui prit alors librement son chemin dans la mer"
    },
    {
      "key": 2202,
      "id": "18-62",
      "translation": "Puis, lorsque tous deux eurent dépassé [cet endroit,] il dit à son valet: «Apporte-nous notre déjeuner: nous avons rencontré de la fatigue dans notre présent voyage»"
    },
    {
      "key": 2203,
      "id": "18-63",
      "translation": "[Le valet lui] dit: «Quand nous avons pris refuge près du rocher, vois-tu, j'ai oublié le poisson - le Diable seul m'a fait oublier de (te) le rappeler - et il a curieusement pris son chemin dans la mer»"
    },
    {
      "key": 2204,
      "id": "18-64",
      "translation": "[Moïse] dit: «Voilà ce que nous cherchions». Puis, ils retournèrent sur leurs pas, suivant leurs traces"
    },
    {
      "key": 2205,
      "id": "18-65",
      "translation": "Ils trouvèrent l'un de Nos serviteurs à qui Nous avions donné une grâce, de Notre part, et à qui Nous avions enseigné une science émanant de Nous"
    },
    {
      "key": 2206,
      "id": "18-66",
      "translation": "Moïse lui dit: «Puis-je te suivre, à la condition que tu m'apprennes de ce qu'on t'a appris concernant une bonne direction?»"
    },
    {
      "key": 2207,
      "id": "18-67",
      "translation": "[L'autre] dit: «Vraiment, tu ne pourras jamais être patient avec moi"
    },
    {
      "key": 2208,
      "id": "18-68",
      "translation": "Comment endurerais-tu sur des choses que tu n'embrasses pas par ta connaissance?»"
    },
    {
      "key": 2209,
      "id": "18-69",
      "translation": "[Moïse] lui dit: «Si Allah veut, tu me trouveras patient; et je ne désobéirai à aucun de tes ordres»"
    },
    {
      "key": 2210,
      "id": "18-70",
      "translation": "«Si tu me suis, dit [l'autre,] ne m'interroge sur rien tant que je ne t'en aurai pas fait mention»"
    },
    {
      "key": 2211,
      "id": "18-71",
      "translation": "Alors les deux partirent. Et après qu'ils furent montés sur un bateau, l'homme y fit une brèche. [Moïse] lui dit: «Est-ce pour noyer ses occupants que tu l'as ébréché? Tu as commis, certes, une chose monstrueuse!»"
    },
    {
      "key": 2212,
      "id": "18-72",
      "translation": "[L'autre] répondit: «N'ai-je pas dit que tu ne pourrais pas garder patience en ma compagnie?»"
    },
    {
      "key": 2213,
      "id": "18-73",
      "translation": "«Ne t'en prends pas à moi, dit [Moïse,] pour un oubli de ma part; et ne m'impose pas de grande difficulté dans mon affaire»"
    },
    {
      "key": 2214,
      "id": "18-74",
      "translation": "Puis ils partirent tous deux; et quand ils eurent rencontré un enfant, [l'homme] le tua. Alors [Moïse] lui dit: «As-tu tué un être innocent, qui n'a tué personne? Tu as commis certes, une chose affreuse!»"
    },
    {
      "key": 2215,
      "id": "18-75",
      "translation": "[L'autre] lui dit: «Ne t'ai je pas dit que tu ne pourrais pas garder patience en ma compagnie?»"
    },
    {
      "key": 2216,
      "id": "18-76",
      "translation": "«Si, après cela, je t'interroge sur quoi que ce soit, dit [Moïse], alors ne m'accompagne plus. Tu seras alors excusé de te séparer de moi»"
    },
    {
      "key": 2217,
      "id": "18-77",
      "translation": "Ils partirent donc tous deux; et quand ils furent arrivés à un village habité, ils demandèrent à manger à ses habitants; mais ceux-ci refusèrent de leur donner l'hospitalité. Ensuite, ils y trouvèrent un mur sur le point de s'écrouler. L'homme le redressa. Alors [Moïse] lui dit: «Si tu voulais, tu aurais bien pu réclamer pour cela un salaire»"
    },
    {
      "key": 2218,
      "id": "18-78",
      "translation": "«Ceci [marque] la séparation entre toi et moi, dit [l'homme,] Je vais t'apprendre l'interprétation de ce que tu n'as pu supporter avec patience"
    },
    {
      "key": 2219,
      "id": "18-79",
      "translation": "Pour ce qui est du bateau, il appartenait à des pauvres gens qui travaillaient en mer. Je voulais donc le rendre défectueux, car il y avait derrière eux un roi qui saisissait de force tout bateau"
    },
    {
      "key": 2220,
      "id": "18-80",
      "translation": "Quant au garçon, ses père et mère étaient des croyants; nous avons craint qu'il ne leur imposât la rébellion et la mécréance"
    },
    {
      "key": 2221,
      "id": "18-81",
      "translation": "Nous avons donc voulu que leur Seigneur leur accordât en échange un autre plus pur et plus affectueux"
    },
    {
      "key": 2222,
      "id": "18-82",
      "translation": "Et quant au mur, il appartenait à deux garçons orphelins de la ville, et il y avait dessous un trésor à eux; et leur père était un homme vertueux. Ton Seigneur a donc voulu que tous deux atteignent leur maturité et qu'ils extraient, [eux-mêmes] leur trésor, par une miséricorde de ton Seigneur. Je ne l'ai d'ailleurs pas fait de mon propre chef. Voilà l'interprétation de ce que tu n'as pas pu endurer avec patience»"
    },
    {
      "key": 2223,
      "id": "18-83",
      "translation": "Et ils t'interrogent sur Dûl-Qarnayn. Dis: «Je vais vous en citer quelque fait mémorable»"
    },
    {
      "key": 2224,
      "id": "18-84",
      "translation": "Vraiment, Nous avons affermi sa puissance sur terre, et Nous lui avons donné libre voie à toute chose"
    },
    {
      "key": 2225,
      "id": "18-85",
      "translation": "Il suivit donc une voie"
    },
    {
      "key": 2226,
      "id": "18-86",
      "translation": "Et quand il eut atteint le Couchant, il trouva que le soleil se couchait dans une source boueuse, et, auprès d'elle il trouva une peuplade [impie]. Nous dîmes: «O Dûl-Qarnayn! ou tu les châties, ou tu uses de bienveillance à leur égard»"
    },
    {
      "key": 2227,
      "id": "18-87",
      "translation": "Il dit: «Quant à celui qui est injuste, nous le châtierons; ensuite il sera ramené vers son Seigneur qui le punira d'un châtiment terrible"
    },
    {
      "key": 2228,
      "id": "18-88",
      "translation": "Et quant à celui qui croit et fait bonne œuvre, il aura, en retour, la plus belle récompense. Et nous lui donnerons des ordres faciles à exécuter»"
    },
    {
      "key": 2229,
      "id": "18-89",
      "translation": "Puis, il suivit (une autre) voie"
    },
    {
      "key": 2230,
      "id": "18-90",
      "translation": "Et quand il eut atteint le Levant, il trouva que le soleil se levait sur une peuplade à laquelle Nous n'avions pas donné de voile pour s'en protéger"
    },
    {
      "key": 2231,
      "id": "18-91",
      "translation": "Il en fut ainsi et Nous embrassons de Notre Science ce qu'il détenait"
    },
    {
      "key": 2232,
      "id": "18-92",
      "translation": "Puis, il suivit (une autre) voie"
    },
    {
      "key": 2233,
      "id": "18-93",
      "translation": "Et quand il eut atteint un endroit situé entre les Deux Barrières (montagnes), il trouva derrière elles une peuplade qui ne comprenait presque aucun langage"
    },
    {
      "key": 2234,
      "id": "18-94",
      "translation": "Ils dirent: «O Dûl-Qarnayn, les Yâ jûj et les Mâ jûj commettent du désordre sur terre. Est-ce que nous pourrons t'accorder un tribut pour construire une barrière entre eux et nous?»"
    },
    {
      "key": 2235,
      "id": "18-95",
      "translation": "Il dit: «Ce que Mon Seigneur m'a conféré vaut mieux (que vos dons). Aidez-moi donc avec votre force et je construirai un remblai entre vous et eux"
    },
    {
      "key": 2236,
      "id": "18-96",
      "translation": "Apportez-moi des blocs de fer». Puis, lorsqu'il en eut comblé l'espace entre les deux montagnes, il dit: «Soufflez!» Puis, lorsqu'il l'eut rendu une fournaise, il dit: «Apportez-moi du cuivre fondu, que je le déverse dessus»"
    },
    {
      "key": 2237,
      "id": "18-97",
      "translation": "Ainsi, ils ne purent guère l'escalader ni l'ébrécher non plus"
    },
    {
      "key": 2238,
      "id": "18-98",
      "translation": "Il dit: «C'est une miséricorde de la part de mon Seigneur. Mais, lorsque la promesse de mon Seigneur viendra, Il le nivellera. Et la promesse de mon Seigneur est vérité»"
    },
    {
      "key": 2239,
      "id": "18-99",
      "translation": "Nous les laisserons, ce jour-là, déferler comme les flots les uns sur les autres, et on soufflera dans la Trompe et Nous les rassemblerons tous"
    },
    {
      "key": 2240,
      "id": "18-100",
      "translation": "Et ce jour-là Nous présenterons de près l'Enfer aux mécréants"
    },
    {
      "key": 2241,
      "id": "18-101",
      "translation": "dont les yeux étaient couverts d'un voile qui les empêchait de penser à Moi, et ils ne pouvaient rien entendre non plus"
    },
    {
      "key": 2242,
      "id": "18-102",
      "translation": "Ceux qui ont mécru, comptent-ils donc pouvoir prendre, pour alliés, Mes serviteurs en dehors de Moi? Nous avons préparé l'Enfer comme résidence pour les mécréants"
    },
    {
      "key": 2243,
      "id": "18-103",
      "translation": "Dis: «Voulez-vous que Nous vous apprenions lesquels sont les plus grands perdants, en œuvres"
    },
    {
      "key": 2244,
      "id": "18-104",
      "translation": "Ceux dont l'effort, dans la vie présente, s'est égaré, alors qu'ils s'imaginent faire le bien"
    },
    {
      "key": 2245,
      "id": "18-105",
      "translation": "Ceux-là qui ont nié les signes de leur Seigneur, ainsi que Sa rencontre. Leurs actions sont donc vaines». Nous ne leur assignerons pas de poids au Jour de la Résurrection"
    },
    {
      "key": 2246,
      "id": "18-106",
      "translation": "C'est que leur rétribution sera l'Enfer, pour avoir mécru et pris en raillerie Mes signes (enseignements) et Mes messagers"
    },
    {
      "key": 2247,
      "id": "18-107",
      "translation": "Ceux qui croient et font de bonnes œuvres auront pour résidence les Jardins du «Firdaws,» (Paradis)"
    },
    {
      "key": 2248,
      "id": "18-108",
      "translation": "où ils demeureront éternellement, sans désirer aucun changement"
    },
    {
      "key": 2249,
      "id": "18-109",
      "translation": "Dis: «Si la mer était une encre [pour écrire] les paroles de mon Seigneur, certes la mer s'épuiserait avant que ne soient épuisées les paroles de mon Seigneur, quand même Nous lui apporterions son équivalent comme renfort.»"
    },
    {
      "key": 2250,
      "id": "18-110",
      "translation": "Dis: «Je suis en fait un être humain comme vous. Il m'a été révélé que votre Dieu est un Dieu unique! Quiconque, donc, espère rencontrer son Seigneur, qu'il fasse de bonnes actions et qu'il n'associe dans son adoration aucun autre à son Seigneur»"
    },
    {
      "key": 2251,
      "id": "19-1",
      "translation": "Kâf, Hâ', Yâ', 'Aîn, Sâd"
    },
    {
      "key": 2252,
      "id": "19-2",
      "translation": "C'est un récit de la miséricorde de ton Seigneur envers Son serviteur Zacharie"
    },
    {
      "key": 2253,
      "id": "19-3",
      "translation": "Lorsqu'il invoqua son Seigneur d'une invocation secrète"
    },
    {
      "key": 2254,
      "id": "19-4",
      "translation": "et dit: «O mon Seigneur, mes os sont affaiblis et ma tête s'est enflammée de cheveux blancs. [Cependant], je n'ai jamais été malheureux [déçu] en te priant, ô mon Seigneur"
    },
    {
      "key": 2255,
      "id": "19-5",
      "translation": "Je crains [le comportement] de mes héritiers, après moi. Et ma propre femme est stérile. Accorde-moi, de Ta part, un descendant"
    },
    {
      "key": 2256,
      "id": "19-6",
      "translation": "qui hérite de moi et hérite de la famille de Jacob. Et fais qu'il te soit agréable, ô mon Seigneur»"
    },
    {
      "key": 2257,
      "id": "19-7",
      "translation": "«O Zacharie, Nous t'annonçons la bonne nouvelle d'un fils. Son nom sera Yahya [Jean]. Nous ne lui avons pas donné auparavant d'homonyme»"
    },
    {
      "key": 2258,
      "id": "19-8",
      "translation": "Et [Zacharie dit]: «O mon Seigneur, comment aurai-je un fils, quand ma femme est stérile et que je suis très avancé en vieillesse?»"
    },
    {
      "key": 2259,
      "id": "19-9",
      "translation": "[Allah] lui dit: «Ainsi sera-t-il! Ton Seigneur a dit: «Ceci m'est facile. Et avant cela, Je t'ai créé alors que tu n'étais rien»"
    },
    {
      "key": 2260,
      "id": "19-10",
      "translation": "«O mon Seigneur, dit [Zacharie], accorde-moi un signe». «Ton signe, dit [Allah,] sera que tu ne pourras pas parler aux gens pendant trois nuits tout en étant bien portant"
    },
    {
      "key": 2261,
      "id": "19-11",
      "translation": "Il sortit donc du sanctuaire vers son peuple; puis il leur fit signe de prier matin et soir"
    },
    {
      "key": 2262,
      "id": "19-12",
      "translation": "«O Yahya, tiens fermement au Livre (la Thora)!» Nous lui donnâmes la sagesse alors qu'il était enfant"
    },
    {
      "key": 2263,
      "id": "19-13",
      "translation": "ainsi que la tendresse de Notre part et la pureté. Il était pieux"
    },
    {
      "key": 2264,
      "id": "19-14",
      "translation": "et dévoué envers ses père et mère; et ne fut ni violent ni désobéissant"
    },
    {
      "key": 2265,
      "id": "19-15",
      "translation": "Que la paix soit sur lui le jour où il naquit, le jour où il mourra, et le jour où il sera ressuscité vivant"
    },
    {
      "key": 2266,
      "id": "19-16",
      "translation": "Mentionne, dans le Livre (le Coran), Marie, quand elle se retira de sa famille en un lieu vers l'Orient"
    },
    {
      "key": 2267,
      "id": "19-17",
      "translation": "Elle mit entre elle et eux un voile. Nous lui envoyâmes Notre Esprit (Gabriel), qui se présenta à elle sous la forme d'un homme parfait"
    },
    {
      "key": 2268,
      "id": "19-18",
      "translation": "Elle dit: «Je me réfugie contre toi auprès du Tout Miséricordieux. Si tu es pieux, [ne m'approche point].»"
    },
    {
      "key": 2269,
      "id": "19-19",
      "translation": "Il dit: «Je suis en fait un Messager de ton Seigneur pour te faire don d'un fils pur»"
    },
    {
      "key": 2270,
      "id": "19-20",
      "translation": "Elle dit: «Comment aurais-je un fils, quand aucun homme ne m'a touchée, et que je ne suis pas prostituée?»"
    },
    {
      "key": 2271,
      "id": "19-21",
      "translation": "Il dit: «Ainsi sera-t-il! Cela M'est facile, a dit ton Seigneur! Et Nous ferons de lui un signe pour les gens, et une miséricorde de Notre part. C'est une affaire déjà décidée»"
    },
    {
      "key": 2272,
      "id": "19-22",
      "translation": "Elle devint donc enceinte [de l'enfant], et elle se retira avec lui en un lieu éloigné"
    },
    {
      "key": 2273,
      "id": "19-23",
      "translation": "Puis les douleurs de l'enfantement l'amenèrent au tronc du palmier, et elle dit: «Malheur à moi! Que je fusse morte avant cet instant! Et que je fusse totalement oubliée!»"
    },
    {
      "key": 2274,
      "id": "19-24",
      "translation": "Alors, il l'appela d'au-dessous d'elle, [lui disant:] «Ne t'afflige pas. Ton Seigneur a placé à tes pieds une source"
    },
    {
      "key": 2275,
      "id": "19-25",
      "translation": "Secoue vers toi le tronc du palmier: il fera tomber sur toi des dattes fraîches et mûres"
    },
    {
      "key": 2276,
      "id": "19-26",
      "translation": "Mange donc et bois et que ton œil se réjouisse! Si tu vois quelqu'un d'entre les humains, dis [lui:] «Assurément, j'ai voué un jeûne au Tout Miséricordieux: je ne parlerai donc aujourd'hui à aucun être humain»"
    },
    {
      "key": 2277,
      "id": "19-27",
      "translation": "Puis elle vint auprès des siens en le portant [le bébé]. Ils dirent: «O Marie, tu as fait une chose monstrueuse"
    },
    {
      "key": 2278,
      "id": "19-28",
      "translation": "«Sœur de Hârûn, ton père n'était pas un homme de mal et ta mère n'était pas une prostituée»"
    },
    {
      "key": 2279,
      "id": "19-29",
      "translation": "Elle fit alors un signe vers lui [le bébé]. Ils dirent: «Comment parlerions-nous à un bébé au berceau?»"
    },
    {
      "key": 2280,
      "id": "19-30",
      "translation": "Mais (le bébé) dit: «Je suis vraiment le serviteur d'Allah. Il m'a donné le Livre et m'a désigné Prophète"
    },
    {
      "key": 2281,
      "id": "19-31",
      "translation": "Où que je sois, Il m'a rendu béni; et Il m'a recommandé, tant que je vivrai, la prière et la Zakât"
    },
    {
      "key": 2282,
      "id": "19-32",
      "translation": "et la bonté envers ma mère. Il ne m'a fait ni violent ni malheureux"
    },
    {
      "key": 2283,
      "id": "19-33",
      "translation": "Et que la paix soit sur moi le jour où je naquis, le jour où je mourrai, et le jour où je serai ressuscité vivant.»"
    },
    {
      "key": 2284,
      "id": "19-34",
      "translation": "Tel est Issa (Jésus), fils de Marie: parole de vérité, dont ils doutent"
    },
    {
      "key": 2285,
      "id": "19-35",
      "translation": "Il ne convient pas à Allah de S'attribuer un fils. Gloire et Pureté à Lui! Quand Il décide d'une chose, Il dit seulement: «Sois!» et elle est"
    },
    {
      "key": 2286,
      "id": "19-36",
      "translation": "Certes, Allah est mon Seigneur tout comme votre Seigneur. Adorez-le donc. Voilà un droit chemin»"
    },
    {
      "key": 2287,
      "id": "19-37",
      "translation": "[par la suite,] les sectes divergèrent entre elles. Alors, malheur aux mécréants lors de la vue d'un jour terrible"
    },
    {
      "key": 2288,
      "id": "19-38",
      "translation": "Comme ils entendront et verront bien le jour où ils viendront à Nous! Mais aujourd'hui, les injustes sont dans un égarement évident"
    },
    {
      "key": 2289,
      "id": "19-39",
      "translation": "Et avertis-les du jour du Regret, quand tout sera réglé; alors qu'ils sont [dans ce monde] inattentifs et qu'ils ne croient pas"
    },
    {
      "key": 2290,
      "id": "19-40",
      "translation": "C'est Nous, en vérité, qui hériterons la terre et tout ce qui s'y trouve, et c'est à Nous qu'ils seront ramenés"
    },
    {
      "key": 2291,
      "id": "19-41",
      "translation": "Et mentionne dans le Livre, Abraham. C'était un très véridique et un Prophète"
    },
    {
      "key": 2292,
      "id": "19-42",
      "translation": "Lorsqu'il dit à son père: «O mon père, pourquoi adores-tu ce qui n'entend ni ne voit, et ne te profite en rien"
    },
    {
      "key": 2293,
      "id": "19-43",
      "translation": "O mon père, il m'est venu de la science ce que tu n'as pas reçu; suis-moi, donc, je te guiderai sur une voie droite"
    },
    {
      "key": 2294,
      "id": "19-44",
      "translation": "O mon père, n'adore pas le Diable, car le Diable désobéit au Tout Miséricordieux"
    },
    {
      "key": 2295,
      "id": "19-45",
      "translation": "O mon père, je crains qu'un châtiment venant du Tout Miséricordieux ne te touche et que tu ne deviennes un allié du Diable»"
    },
    {
      "key": 2296,
      "id": "19-46",
      "translation": "Il dit: «O Abraham, aurais-tu du dédain pour mes divinités? Si tu ne cesses pas, certes je te lapiderai, éloigne-toi de moi pour bien longtemps»"
    },
    {
      "key": 2297,
      "id": "19-47",
      "translation": "«Paix sur toi», dit Abraham. «J'implorerai mon Seigneur de te pardonner car Il m'a toujours comblé de Ses bienfaits"
    },
    {
      "key": 2298,
      "id": "19-48",
      "translation": "Je me sépare de vous, ainsi que de ce que vous invoquez, en dehors d'Allah, et j'invoquerai mon Seigneur. J'espère ne pas être malheureux dans mon appel à mon Seigneur»"
    },
    {
      "key": 2299,
      "id": "19-49",
      "translation": "Puis, lorsqu'il se fut séparé d'eux et de ce qu'ils adoraient en dehors d'Allah, Nous lui fîmes don d'Isaac et de Jacob; et de chacun Nous fîmes un prophète"
    },
    {
      "key": 2300,
      "id": "19-50",
      "translation": "Et Nous leur donnâmes de par Notre miséricorde, et Nous leur accordâmes un langage sublime de vérité"
    },
    {
      "key": 2301,
      "id": "19-51",
      "translation": "Et mentionne dans le Livre Moïse. C'était vraiment un élu, et c'était un Messager et un prophète"
    },
    {
      "key": 2302,
      "id": "19-52",
      "translation": "Du côté droit du Mont (Sinaï) Nous l'appelâmes et Nous le fîmes approcher tel un confident"
    },
    {
      "key": 2303,
      "id": "19-53",
      "translation": "Et par Notre miséricorde, Nous lui donnâmes Aaron son frère comme prophète"
    },
    {
      "key": 2304,
      "id": "19-54",
      "translation": "Et mentionne Ismaël, dans le Livre. Il était fidèle à ses promesses; et c'était un Messager et un prophète"
    },
    {
      "key": 2305,
      "id": "19-55",
      "translation": "Et il commandait à sa famille la prière et la Zakât; et il était agréé auprès de son Seigneur"
    },
    {
      "key": 2306,
      "id": "19-56",
      "translation": "Et mentionne Idris, dans le Livre. C'était un véridique et un prophète"
    },
    {
      "key": 2307,
      "id": "19-57",
      "translation": "Et Nous l'élevâmes à un haut rang"
    },
    {
      "key": 2308,
      "id": "19-58",
      "translation": "Voilà ceux qu'Allah a comblés de faveurs, parmi les prophètes, parmi les descendants d'Adam, et aussi parmi ceux que Nous avons transportés en compagnie de Noé, et parmi la descendance d'Abraham et d'Israël, et parmi ceux que Nous avons guidés et choisis. Quand les versets du Tout Miséricordieux leur étaient récités, ils tombaient prosternés en pleurant"
    },
    {
      "key": 2309,
      "id": "19-59",
      "translation": "Puis leur succédèrent des générations qui délaissèrent la prière et suivirent leurs passions. Ils se trouveront en perdition"
    },
    {
      "key": 2310,
      "id": "19-60",
      "translation": "sauf celui qui se repent, croit et fait le bien: ceux-là entreront dans le Paradis et ne seront point lésés"
    },
    {
      "key": 2311,
      "id": "19-61",
      "translation": "aux jardins du séjour (éternel) que le Tout Miséricordieux a promis à Ses serviteurs, [qui ont cru] au mystère. Car Sa promesse arrivera sans nul doute"
    },
    {
      "key": 2312,
      "id": "19-62",
      "translation": "On n'y entend nulle parole insignifiante; seulement: «Salâm»; et ils auront là leur nourriture, matin et soir"
    },
    {
      "key": 2313,
      "id": "19-63",
      "translation": "Voilà le Paradis dont Nous ferons hériter ceux de Nos serviteurs qui auront été pieux"
    },
    {
      "key": 2314,
      "id": "19-64",
      "translation": "«Nous ne descendons que sur ordre de ton Seigneur. A Lui tout ce qui est devant nous, tout ce qui est derrière nous et tout ce qui est entre les deux. Ton Seigneur n'oublie rien"
    },
    {
      "key": 2315,
      "id": "19-65",
      "translation": "Il est le Seigneur des cieux et de la terre et de tout ce qui est entre eux. Adore-Le donc, et sois constant dans Son adoration. Lui connais-tu un homonyme?»"
    },
    {
      "key": 2316,
      "id": "19-66",
      "translation": "Et l'homme dit: «Une fois mort, me sortira-t-on vivant?»"
    },
    {
      "key": 2317,
      "id": "19-67",
      "translation": "L'homme ne se rappelle-t-il pas qu'avant cela, c'est Nous qui l'avons créé, alors qu'il n'était rien"
    },
    {
      "key": 2318,
      "id": "19-68",
      "translation": "Par ton Seigneur! Assurément, Nous les rassemblerons, eux et les diables. Puis, Nous les placerons autour de l'Enfer, agenouillés"
    },
    {
      "key": 2319,
      "id": "19-69",
      "translation": "Ensuite, Nous arracherons de chaque groupe ceux d'entre eux qui étaient les plus obstinés contre le Tout Miséricordieux"
    },
    {
      "key": 2320,
      "id": "19-70",
      "translation": "Puis nous sommes Le meilleur à savoir ceux qui méritent le plus d'y être brûlés"
    },
    {
      "key": 2321,
      "id": "19-71",
      "translation": "Il n'y a personne parmi vous qui ne passera pas par [L'Enfer]: Car [il s'agit là] pour ton Seigneur d'une sentence irrévocable"
    },
    {
      "key": 2322,
      "id": "19-72",
      "translation": "Ensuite, Nous délivrerons ceux qui étaient pieux et Nous y laisserons les injustes agenouillés"
    },
    {
      "key": 2323,
      "id": "19-73",
      "translation": "Et lorsque Nos versets évidents leur sont récités les mécréants disent à ceux qui croient: «Lequel des deux groupes a la situation la plus confortable et la meilleure compagnie?»"
    },
    {
      "key": 2324,
      "id": "19-74",
      "translation": "Combien de générations, avant eux, avons-Nous fait périr, qui les surpassaient en biens et en apparence"
    },
    {
      "key": 2325,
      "id": "19-75",
      "translation": "Dis: «Celui qui est dans l'égarement, que le Tout Miséricordieux prolonge sa vie pour un certain temps, jusqu'à ce qu'ils voient soit le châtiment, soit l'Heure dont ils sont menacés. Alors, ils sauront qui a la pire situation et la troupe la plus faible»"
    },
    {
      "key": 2326,
      "id": "19-76",
      "translation": "Allah accroît la rectitude de ceux qui suivent le bon chemin, et les bonnes œuvres durables méritent auprès de ton Seigneur une meilleure récompense et une meilleure destination"
    },
    {
      "key": 2327,
      "id": "19-77",
      "translation": "As-tu vu celui qui ne croit pas à Nos versets et dit: «On me donnera certes des biens et des enfants»"
    },
    {
      "key": 2328,
      "id": "19-78",
      "translation": "Est-il au courant de l'Inconnaissable ou a-t-il pris un engagement avec le Tout Miséricordieux"
    },
    {
      "key": 2329,
      "id": "19-79",
      "translation": "Bien au contraire! Nous enregistrerons ce qu'il dit et accroîtrons son châtiment"
    },
    {
      "key": 2330,
      "id": "19-80",
      "translation": "C'est Nous qui hériterons ce dont il parle, tandis qu'il viendra à Nous, tout seul"
    },
    {
      "key": 2331,
      "id": "19-81",
      "translation": "Ils ont adopté des divinités en dehors d'Allah pour qu'ils leur soient des protecteurs (contre le châtiment)"
    },
    {
      "key": 2332,
      "id": "19-82",
      "translation": "Bien au contraire! [ces divinités] renieront leur adoration et seront pour eux des adversaires"
    },
    {
      "key": 2333,
      "id": "19-83",
      "translation": "N'as-tu pas vu que Nous avons envoyé contre les mécréants des diables qui les excitent furieusement [à désobéir]"
    },
    {
      "key": 2334,
      "id": "19-84",
      "translation": "Ne te hâte donc pas contre eux: Nous tenons un compte précis de [tous leurs actes]"
    },
    {
      "key": 2335,
      "id": "19-85",
      "translation": "(Rappelle-toi) le jour où Nous rassemblerons les pieux sur des montures et en grande pompe, auprès du Tout Miséricordieux"
    },
    {
      "key": 2336,
      "id": "19-86",
      "translation": "et pousserons les criminels à l'Enfer comme (un troupeau) à l'abreuvoir"
    },
    {
      "key": 2337,
      "id": "19-87",
      "translation": "ils ne disposeront d'aucune intercession, sauf celui qui aura pris un engagement avec le Tout Miséricordieux"
    },
    {
      "key": 2338,
      "id": "19-88",
      "translation": "Et ils ont dit: «Le Tout Miséricordieux S'est attribué un enfant!»"
    },
    {
      "key": 2339,
      "id": "19-89",
      "translation": "Vous avancez certes là une chose abominable"
    },
    {
      "key": 2340,
      "id": "19-90",
      "translation": "Peu s'en faut que les cieux ne s'entrouvrent à ces mots, que la terre ne se fende et que les montagnes ne s'écroulent"
    },
    {
      "key": 2341,
      "id": "19-91",
      "translation": "du fait qu'ils ont attribué un enfant au Tout Miséricordieux"
    },
    {
      "key": 2342,
      "id": "19-92",
      "translation": "alors qu'il ne convient nullement au Tout Miséricordieux d'avoir un enfant"
    },
    {
      "key": 2343,
      "id": "19-93",
      "translation": "Tous ceux qui sont dans les cieux et sur la terre se rendront auprès du Tout Miséricordieux, [sans exception], en serviteurs"
    },
    {
      "key": 2344,
      "id": "19-94",
      "translation": "Il les a certes dénombrés et bien comptés"
    },
    {
      "key": 2345,
      "id": "19-95",
      "translation": "Et au Jour de la Résurrection, chacun d'eux se rendra seul auprès de Lui"
    },
    {
      "key": 2346,
      "id": "19-96",
      "translation": "A ceux qui croient et font de bonnes œuvres, le Tout Miséricordieux accordera Son amour"
    },
    {
      "key": 2347,
      "id": "19-97",
      "translation": "Nous l'avons rendu (le Coran) facile [à comprendre] en ta langue, afin que tu annonces par lui la bonne nouvelle aux gens pieux, et que, tu avertisses un peuple irréductible"
    },
    {
      "key": 2348,
      "id": "19-98",
      "translation": "Que de générations avant eux avons-Nous fait périr! En retrouves-tu un seul individu? ou en entends-tu le moindre murmure"
    },
    {
      "key": 2349,
      "id": "20-1",
      "translation": "Tâ-Hâ"
    },
    {
      "key": 2350,
      "id": "20-2",
      "translation": "Nous n'avons point fait descendre sur toi le Coran pour que tu sois malheureux"
    },
    {
      "key": 2351,
      "id": "20-3",
      "translation": "si ce n'est qu'un Rappel pour celui qui redoute (Allah)"
    },
    {
      "key": 2352,
      "id": "20-4",
      "translation": "(et comme) une révélation émanant de Celui qui a créé la terre et les cieux sublimes"
    },
    {
      "key": 2353,
      "id": "20-5",
      "translation": "Le Tout Miséricordieux S'est établi «Istawâ» sur le Trône"
    },
    {
      "key": 2354,
      "id": "20-6",
      "translation": "A Lui appartient ce qui est dans les cieux, sur la terre, ce qui est entre eux et ce qui est sous le sol humide"
    },
    {
      "key": 2355,
      "id": "20-7",
      "translation": "Et si tu élèves la voix, Il connaît certes les secrets, même les plus cachés"
    },
    {
      "key": 2356,
      "id": "20-8",
      "translation": "Allah! Point de divinité que Lui! Il possède les noms les plus beaux"
    },
    {
      "key": 2357,
      "id": "20-9",
      "translation": "Le récit de Moïse t'est-il parvenu"
    },
    {
      "key": 2358,
      "id": "20-10",
      "translation": "Lorsqu'il vit du feu, il dit à sa famille: «Restez ici! Je vois du feu de loin; peut-être vous en apporterai-je un tison, ou trouverai-je auprès du feu de quoi me guider»"
    },
    {
      "key": 2359,
      "id": "20-11",
      "translation": "Puis, lorsqu'il y arriva, il fut interpellé: «Moïse"
    },
    {
      "key": 2360,
      "id": "20-12",
      "translation": "Je suis ton Seigneur. Enlève tes sandales: car tu es dans la vallée sacrée, Tuwâ"
    },
    {
      "key": 2361,
      "id": "20-13",
      "translation": "Moi, Je t'ai choisi. Ecoute donc ce qui va être révélé"
    },
    {
      "key": 2362,
      "id": "20-14",
      "translation": "Certes, c'est Moi Allah: point de divinité que Moi. Adore-Moi donc et accomplis la Salât pour te souvenir de Moi"
    },
    {
      "key": 2363,
      "id": "20-15",
      "translation": "L'Heure va certes arriver. Je la cache à peine, pour que chaque âme soit rétribuée selon ses efforts"
    },
    {
      "key": 2364,
      "id": "20-16",
      "translation": "Que celui qui n'y croit pas et qui suit sa propre passion ne t'en détourne pas. Sinon tu périras"
    },
    {
      "key": 2365,
      "id": "20-17",
      "translation": "Et qu'est-ce qu'il y a dans ta main droite, ô Moïse?»"
    },
    {
      "key": 2366,
      "id": "20-18",
      "translation": "Il dit: «C'est mon bâton sur lequel je m'appuie, qui me sert à effeuiller (les arbres) pour mes moutons et j'en fais d'autres usages»"
    },
    {
      "key": 2367,
      "id": "20-19",
      "translation": "[Allah lui] dit: «Jette-le, O Moïse»"
    },
    {
      "key": 2368,
      "id": "20-20",
      "translation": "Il le jeta: et le voici un serpent qui rampait"
    },
    {
      "key": 2369,
      "id": "20-21",
      "translation": "[Allah] dit: «Saisis-le et ne crains rien: Nous le ramènerons à son premier état"
    },
    {
      "key": 2370,
      "id": "20-22",
      "translation": "Et serre ta main sous ton aisselle: elle en sortira blanche sans aucun mal, et ce sera là un autre prodige"
    },
    {
      "key": 2371,
      "id": "20-23",
      "translation": "afin que Nous te fassions voir de Nos prodiges les plus importants"
    },
    {
      "key": 2372,
      "id": "20-24",
      "translation": "Rends-toi auprès de Pharaon car il a outrepassé toute limite"
    },
    {
      "key": 2373,
      "id": "20-25",
      "translation": "[Moïse] dit: «Seigneur, ouvre-moi ma poitrine"
    },
    {
      "key": 2374,
      "id": "20-26",
      "translation": "et facilite ma mission"
    },
    {
      "key": 2375,
      "id": "20-27",
      "translation": "et dénoue un nœud en ma langue"
    },
    {
      "key": 2376,
      "id": "20-28",
      "translation": "afin qu'ils comprennent mes paroles"
    },
    {
      "key": 2377,
      "id": "20-29",
      "translation": "et assigne-moi un assistant de ma famille"
    },
    {
      "key": 2378,
      "id": "20-30",
      "translation": "Aaron, mon frère"
    },
    {
      "key": 2379,
      "id": "20-31",
      "translation": "accrois par lui ma force"
    },
    {
      "key": 2380,
      "id": "20-32",
      "translation": "et associe-le à ma mission"
    },
    {
      "key": 2381,
      "id": "20-33",
      "translation": "afin que nous Te glorifions beaucoup"
    },
    {
      "key": 2382,
      "id": "20-34",
      "translation": "et que nous T'invoquions beaucoup"
    },
    {
      "key": 2383,
      "id": "20-35",
      "translation": "Et Toi, certes, Tu es Très Clairvoyant sur nous»"
    },
    {
      "key": 2384,
      "id": "20-36",
      "translation": "[Allah] dit: «Ta demande est exaucée, ô Moïse"
    },
    {
      "key": 2385,
      "id": "20-37",
      "translation": "Et Nous t'avons déjà favorisé une première fois"
    },
    {
      "key": 2386,
      "id": "20-38",
      "translation": "lorsque Nous révélâmes à ta mère ce qui fut révélé"
    },
    {
      "key": 2387,
      "id": "20-39",
      "translation": "«Mets-le dans le coffret, puis jette celui-ci dans les flots pour qu'ensuite le fleuve le lance sur la rive; un ennemi à Moi et à lui le prendra». Et J'ai répandu sur toi une affection de Ma part, afin que tu sois élevé sous Mon œil"
    },
    {
      "key": 2388,
      "id": "20-40",
      "translation": "Et vœilà que ta sœur (te suivait en) marchant et disait: «Puis-je vous indiquer quelqu'un qui se chargera de lui?» Ainsi, Nous te rapportâmes à ta mère afin que son oil se réjouisse et qu'elle ne s'afflige plus. Tu tuas ensuite un individu; Nous te sauvâmes des craintes qui t'oppressaient; et Nous t'imposâmes plusieurs épreuves. Puis tu demeuras des années durant chez les habitants de Madyan. Ensuite tu es venu, ô Moïse, conformément à un décret"
    },
    {
      "key": 2389,
      "id": "20-41",
      "translation": "Et je t'ai assigné à Moi-Même"
    },
    {
      "key": 2390,
      "id": "20-42",
      "translation": "Pars, toi et ton frère, avec Mes prodiges; et ne négligez pas de M'invoquer"
    },
    {
      "key": 2391,
      "id": "20-43",
      "translation": "Allez vers Pharaon: il s'est vraiment rebellé"
    },
    {
      "key": 2392,
      "id": "20-44",
      "translation": "Puis, parlez-lui gentiment. Peut-être se rappellera-t-il ou [Me] craindra-t-il"
    },
    {
      "key": 2393,
      "id": "20-45",
      "translation": "Ils dirent: «O notre Seigneur, nous craignons qu'il ne nous maltraite indûment, ou qu'il dépasse les limites»"
    },
    {
      "key": 2394,
      "id": "20-46",
      "translation": "Il dit: «Ne craignez rien. Je suis avec vous: J'entends et Je vois"
    },
    {
      "key": 2395,
      "id": "20-47",
      "translation": "Allez donc chez lui; puis, dites-lui: «Nous sommes tous deux, les messagers de ton Seigneur. Envoie donc les Enfants d'Israël en notre compagnie et ne les châtie plus. Nous sommes venus à toi avec une preuve de la part de ton Seigneur. Et que la paix soit sur quiconque suit le droit chemin"
    },
    {
      "key": 2396,
      "id": "20-48",
      "translation": "Il nous a été révélé que le châtiment est pour celui qui refuse d'avoir foi et qui tourne le dos»"
    },
    {
      "key": 2397,
      "id": "20-49",
      "translation": "Alors [Pharaon] dit: «Qui donc est votre Seigneur, ô Moïse?»"
    },
    {
      "key": 2398,
      "id": "20-50",
      "translation": "«Notre Seigneur, dit Moïse, est Celui qui a donné à chaque chose sa propre nature puis l'a dirigée»"
    },
    {
      "key": 2399,
      "id": "20-51",
      "translation": "«Qu'en est-il donc des générations anciennes?» dit Pharaon"
    },
    {
      "key": 2400,
      "id": "20-52",
      "translation": "Moïse dit: «La connaissance de leur sort est auprès de mon Seigneur, dans un livre. Mon Seigneur [ne commet] ni erreur ni oubli"
    },
    {
      "key": 2401,
      "id": "20-53",
      "translation": "C'est Lui qui vous a assigné la terre comme berceau et vous y a tracé des chemins; et qui du ciel a fait descendre de l'eau avec laquelle Nous faisons germer des couples de plantes de toutes sortes.»"
    },
    {
      "key": 2402,
      "id": "20-54",
      "translation": "«Mangez et faites paître votre bétail». Voilà bien là des signes pour les doués d'intelligence"
    },
    {
      "key": 2403,
      "id": "20-55",
      "translation": "C'est d'elle (la terre) que Nous vous avons créés, et en elle Nous vous retournerons, et d'elle Nous vous ferons sortir une fois encore"
    },
    {
      "key": 2404,
      "id": "20-56",
      "translation": "Certes Nous lui avons montré tous Nos prodiges; mais il les a démentis et a refusé (de croire)"
    },
    {
      "key": 2405,
      "id": "20-57",
      "translation": "Il dit: «Es-tu venu à nous, ô Moïse, pour nous faire sortir de notre terre par ta magie"
    },
    {
      "key": 2406,
      "id": "20-58",
      "translation": "Nous t'apporterons assurément une magie semblable. Fixe entre nous et toi un rendez-vous auquel ni nous ni toi ne manquerons, dans un lieu convenable»"
    },
    {
      "key": 2407,
      "id": "20-59",
      "translation": "Alors Moïse dit: «Votre rendez-vous, c'est le jour de la fête. Et que les gens se rassemblent dans la matinée»"
    },
    {
      "key": 2408,
      "id": "20-60",
      "translation": "Pharaon, donc, se retira. Ensuite il rassembla sa ruse puis vint (au rendez-vous)"
    },
    {
      "key": 2409,
      "id": "20-61",
      "translation": "Moïse leur dit: «Malheur à vous! Ne forgez pas de mensonge contre Allah: sinon par un châtiment Il vous anéantira. Celui qui forge (un mensonge) est perdu»"
    },
    {
      "key": 2410,
      "id": "20-62",
      "translation": "Là-dessus, ils se mirent à disputer entre eux de leur affaire et tinrent secrètes leurs discussions"
    },
    {
      "key": 2411,
      "id": "20-63",
      "translation": "Ils dirent: «Voici deux magiciens qui, par leur magie, veulent vous faire abandonner votre terre et emporter votre doctrine idéale"
    },
    {
      "key": 2412,
      "id": "20-64",
      "translation": "Rassemblez donc votre ruse puis venez en rangs serrés. Et celui qui aura le dessus aujourd'hui aura réussi»"
    },
    {
      "key": 2413,
      "id": "20-65",
      "translation": "Ils dirent: «O Moïse, ou tu jettes, [le premier ton bâton] ou que nous soyons les premiers à jeter?»"
    },
    {
      "key": 2414,
      "id": "20-66",
      "translation": "Il dit: «Jetez plutôt». Et voilà que leurs cordes et leurs bâtons lui parurent ramper par l'effet de leur magie"
    },
    {
      "key": 2415,
      "id": "20-67",
      "translation": "Moïse ressentit quelque peur en lui-même"
    },
    {
      "key": 2416,
      "id": "20-68",
      "translation": "Nous lui dîmes: «N'aie pas peur, c'est toi qui auras le dessus"
    },
    {
      "key": 2417,
      "id": "20-69",
      "translation": "Jette ce qu'il y a dans ta main droite; cela dévorera ce qu'ils ont fabriqué. Ce qu'ils ont fabriqué n'est qu'une ruse de magicien; et le magicien ne réussit pas, où qu'il soit»"
    },
    {
      "key": 2418,
      "id": "20-70",
      "translation": "Les magiciens se jetèrent prosternés, disant: «Nous avons foi en le Seigneur d'Aaron et de Moïse»"
    },
    {
      "key": 2419,
      "id": "20-71",
      "translation": "Alors Pharaon dit: «Avez-vous cru en lui avant que je ne vous y autorise? C'est lui votre chef qui vous a enseigné la magie. Je vous ferai sûrement, couper mains et jambes opposées, et vous ferai crucifier aux troncs des palmiers, et vous saurez, avec certitude, qui de nous est plus fort en châtiment et qui est le plus durable»"
    },
    {
      "key": 2420,
      "id": "20-72",
      "translation": "«Par celui qui nous a créés, dirent-ils, nous ne te préférerons jamais à ce qui nous est parvenu comme preuves évidentes. Décrète donc ce que tu as à décréter. Tes décrets ne touchent que cette présente vie"
    },
    {
      "key": 2421,
      "id": "20-73",
      "translation": "Nous croyons en notre Seigneur, afin qu'Il nous pardonne nos fautes ainsi que la magie à laquelle tu nous as contraints». Et Allah est meilleur et éternel"
    },
    {
      "key": 2422,
      "id": "20-74",
      "translation": "Quiconque vient en criminel à son Seigneur, aura certes l'Enfer où il ne meurt ni ne vit"
    },
    {
      "key": 2423,
      "id": "20-75",
      "translation": "Et quiconque vient auprès de Lui en croyant, après avoir fait de bonnes œuvres, voilà donc ceux qui auront les plus hauts rangs"
    },
    {
      "key": 2424,
      "id": "20-76",
      "translation": "les jardins du séjour (éternel), sous lesquels coulent les ruisseaux, où ils demeureront éternellement. Et voilà la récompense de ceux qui se purifient [de la mécréance et des péchés]"
    },
    {
      "key": 2425,
      "id": "20-77",
      "translation": "Nous révélâmes à Moïse: «Pars la nuit, à la tête de Mes serviteurs, puis, trace-leur un passage à sec dans la mer: sans craindre une poursuite et sans éprouver aucune peur»"
    },
    {
      "key": 2426,
      "id": "20-78",
      "translation": "Pharaon les poursuivit avec ses armées. La mer les submergea bel et bien"
    },
    {
      "key": 2427,
      "id": "20-79",
      "translation": "Pharaon égara ainsi son peuple et ne le mît pas sur le droit chemin"
    },
    {
      "key": 2428,
      "id": "20-80",
      "translation": "O Enfants d'Israël, Nous vous avons déjà délivrés de votre ennemi, et Nous vous avons donné rendez-vous sur le flanc droit du Mont. Et Nous avons fait descendre sur vous la manne et les cailles"
    },
    {
      "key": 2429,
      "id": "20-81",
      "translation": "«Mangez des bonnes choses que Nous vous avons attribuées et ne vous montrez pas ingrats, sinon Ma colère s'abattra sur vous: et celui sur qui Ma colère s'abat, va sûrement vers l'abîme"
    },
    {
      "key": 2430,
      "id": "20-82",
      "translation": "Et je suis Grand Pardonneur à celui qui se repent, croit, fait bonne œuvre, puis se met sur le bon chemin»"
    },
    {
      "key": 2431,
      "id": "20-83",
      "translation": "«Pourquoi Moïse t'es-tu hâté de quitter ton peuple?»"
    },
    {
      "key": 2432,
      "id": "20-84",
      "translation": "Ils sont là sur mes traces, dit Moïse. Et je me suis hâté vers Toi, Seigneur, afin que Tu sois satisfait"
    },
    {
      "key": 2433,
      "id": "20-85",
      "translation": "Allah dit: «Nous avons mis ton peuple à l'épreuve après ton départ. Et le Sâmirî les a égarés»"
    },
    {
      "key": 2434,
      "id": "20-86",
      "translation": "Moïse retourna donc vers son peuple, courroucé et chagriné; il dit: «O mon peuple, votre Seigneur ne vous a-t-Il pas déjà fait une belle promesse? L'alliance a-t-elle donc été trop longue pour vous? ou avez-vous désiré que la colère de votre Seigneur s'abatte sur vous, pour avoir trahi votre engagement envers moi?»"
    },
    {
      "key": 2435,
      "id": "20-87",
      "translation": "Ils dirent: «Ce n'est pas de notre propre gré que nous avons manqué à notre engagement envers toi. Mais nous fûmes chargés de fardeaux d'ornements du peuple (de Pharaon); nous les avons donc jetés (sur le feu) tout comme le Sâmirî les a lancés"
    },
    {
      "key": 2436,
      "id": "20-88",
      "translation": "Puis il en a fait sortir pour eux un veau, un corps à mugissement. Et ils ont dit: «C'est votre divinité et la divinité de Moïse; il a donc oublié»"
    },
    {
      "key": 2437,
      "id": "20-89",
      "translation": "Quoi! Ne voyaient-ils pas qu'il [le veau] ne leur rendait aucune parole et qu'il ne possédait aucun moyen de leur nuire ou de leur faire du bien"
    },
    {
      "key": 2438,
      "id": "20-90",
      "translation": "Certes, Aaron leur avait bien dit auparavant: «O mon peuple, vous êtes tombés dans la tentation (à cause du veau). Or, c'est le Tout Miséricordieux qui est vraiment votre Seigneur. Suivez-moi donc et obéissez à mon commandement»"
    },
    {
      "key": 2439,
      "id": "20-91",
      "translation": "Ils dirent: «Nous continuerons à y être attachés, jusqu'à ce que Moïse retourne vers nous»"
    },
    {
      "key": 2440,
      "id": "20-92",
      "translation": "Alors [Moïse] dit: «Qu'est-ce qui t'a empêché, Aaron, quand tu les as vus s'égarer"
    },
    {
      "key": 2441,
      "id": "20-93",
      "translation": "de me suivre? As-tu donc désobéi à mon commandement?»"
    },
    {
      "key": 2442,
      "id": "20-94",
      "translation": "[Aaron] dit: «O fils de ma mère, ne me prends ni par la barbe ni par la tête. Je craignais que tu ne dises: «Tu as divisé les enfants d'Israël et tu n'as pas observé mes ordres»"
    },
    {
      "key": 2443,
      "id": "20-95",
      "translation": "Alors [Moïse] dit: «Quel a été ton dessein? O Sâmirî?»"
    },
    {
      "key": 2444,
      "id": "20-96",
      "translation": "Il dit: «J'ai vu ce qu'ils n'ont pas vu: j'ai donc pris une poignée de la trace de l'Envoyé; puis, je l'ai lancée. Voilà ce que mon âme m'a suggéré»"
    },
    {
      "key": 2445,
      "id": "20-97",
      "translation": "«Va-t-en, dit [Moïse]. Dans la vie, tu auras à dire (à tout le monde): «Ne me touchez pas!» Et il y aura pour toi un rendez-vous que tu ne pourras manquer. Regarde ta divinité que tu as adorée avec assiduité. Nous la brûlerons certes, et ensuite, nous disperserons [sa cendre] dans les flots"
    },
    {
      "key": 2446,
      "id": "20-98",
      "translation": "En vérité, votre seul Dieu est Allah en dehors de qui il n'y a point de divinité. De Sa science Il embrasse tout"
    },
    {
      "key": 2447,
      "id": "20-99",
      "translation": "C'est ainsi que Nous te racontons les récits de ce qui s'est passé. C'est bien un rappel de Notre part que Nous t'avons apporté"
    },
    {
      "key": 2448,
      "id": "20-100",
      "translation": "Quiconque s'en détourne (de ce Coran), portera au jour de la résurrection un fardeau"
    },
    {
      "key": 2449,
      "id": "20-101",
      "translation": "ils resteront éternellement dans cet état, et quel mauvais fardeau pour eux au Jour de la Résurrection"
    },
    {
      "key": 2450,
      "id": "20-102",
      "translation": "Le jour où l'on soufflera dans la Trompe, ce jour-là Nous rassemblerons les criminels tout bleus (de peur)"
    },
    {
      "key": 2451,
      "id": "20-103",
      "translation": "Ils chuchoteront entre eux: «Vous n'êtes restés là que dix [jours]!»"
    },
    {
      "key": 2452,
      "id": "20-104",
      "translation": "Nous connaissons parfaitement ce qu'ils diront lorsque l'un d'entre eux dont la conduite est exemplaire dira: «Vous n'êtes restés qu'un jour»"
    },
    {
      "key": 2453,
      "id": "20-105",
      "translation": "Et ils t'interrogent au sujet des montagnes. Dis: «Mon Seigneur les dispersera comme la poussière"
    },
    {
      "key": 2454,
      "id": "20-106",
      "translation": "et les laissera comme une plaine dénudée"
    },
    {
      "key": 2455,
      "id": "20-107",
      "translation": "dans laquelle tu ne verras ni tortuosité, ni dépression"
    },
    {
      "key": 2456,
      "id": "20-108",
      "translation": "Ce jour-là, ils suivront le Convocateur sans tortuosité; et les voix baisseront devant le Tout Miséricordieux. Tu n'entendras alors qu'un chuchotement"
    },
    {
      "key": 2457,
      "id": "20-109",
      "translation": "Ce jour-là, l'intercession ne profitera qu'à celui auquel le Tout Miséricordieux aura donné Sa permission et dont Il agréera la parole"
    },
    {
      "key": 2458,
      "id": "20-110",
      "translation": "Il connaît ce qui est devant eux et ce qui est derrière eux, alors qu'eux-mêmes ne Le cernent pas de leur science"
    },
    {
      "key": 2459,
      "id": "20-111",
      "translation": "Et les visages s'humilieront devant Le Vivant, Celui qui subsiste par Lui-même «al-Qayyûm», et malheureux sera celui qui [se présentera devant Lui] chargé d'une iniquité"
    },
    {
      "key": 2460,
      "id": "20-112",
      "translation": "Et quiconque aura fait de bonnes œuvres tout en étant croyant, ne craindra ni injustice ni oppression"
    },
    {
      "key": 2461,
      "id": "20-113",
      "translation": "C'est ainsi que nous l'avons fait descendre un Coran en [langue] arabe, et Nous y avons multiplié les menaces, afin qu'ils deviennent pieux ou qu'il les incite à s'exhorter"
    },
    {
      "key": 2462,
      "id": "20-114",
      "translation": "Que soit éxalté Allah, le Vrai Souverain! Ne te hâte pas [de réciter] le Coran avant que ne te soit achevée sa révélation. Et dis: «O mon Seigneur, accroît mes connaissances!»"
    },
    {
      "key": 2463,
      "id": "20-115",
      "translation": "En effet, Nous avons auparavant fait une recommandation à Adam; mais il oublia; et Nous n'avons pas trouvé chez lui de résolution ferme"
    },
    {
      "key": 2464,
      "id": "20-116",
      "translation": "Et quand Nous dîmes aux Anges: «Prosternez-vous devant Adam», ils se prosternèrent, excepté Iblis qui refusa"
    },
    {
      "key": 2465,
      "id": "20-117",
      "translation": "Alors Nous dîmes: «O Adam, celui-là est vraiment un ennemi pour toi et ton épouse. Prenez garde qu'il vous fasse sortir du Paradis, car alors tu seras malheureux"
    },
    {
      "key": 2466,
      "id": "20-118",
      "translation": "Car tu n'y auras pas faim ni ne seras nu"
    },
    {
      "key": 2467,
      "id": "20-119",
      "translation": "tu n'y auras pas soif ni ne seras frappé par l'ardeur du soleil»"
    },
    {
      "key": 2468,
      "id": "20-120",
      "translation": "Puis le Diable le tenta en disant: «O Adam, t'indiquerai-je l'arbre de l'éternité et un royaume impérissable?»"
    },
    {
      "key": 2469,
      "id": "20-121",
      "translation": "Tous deux (Adam et Eve) en mangèrent. Alors leur apparut leur nudité. Ils se mirent à se couvrir avec des feuilles du paradis. Adam désobéit ainsi à son Seigneur et il s'égara"
    },
    {
      "key": 2470,
      "id": "20-122",
      "translation": "Son Seigneur l'a ensuite élu, agréé son repentir et l'a guidé"
    },
    {
      "key": 2471,
      "id": "20-123",
      "translation": "Il dit: «Descendez d'ici, (Adam et Eve), [Vous serez] tous (avec vos descendants) ennemis les uns des autres. Puis, si jamais un guide vous vient de Ma part, quiconque suit Mon guide ne s'égarera ni ne sera malheureux"
    },
    {
      "key": 2472,
      "id": "20-124",
      "translation": "Et quiconque se détourne de Mon Rappel, mènera certes, une vie pleine de gêne, et le Jour de la Résurrection Nous l'amènerons aveugle au rassemblement»"
    },
    {
      "key": 2473,
      "id": "20-125",
      "translation": "Il dira: «O mon Seigneur, pourquoi m'as-Tu amené aveugle alors qu'auparavant je voyais?»"
    },
    {
      "key": 2474,
      "id": "20-126",
      "translation": "[Allah lui] dira: «De même que Nos Signes (enseignements) t'étaient venus et que tu les as oubliés, ainsi aujourd'hui tu es oublié»"
    },
    {
      "key": 2475,
      "id": "20-127",
      "translation": "Ainsi sanctionnons-nous l'outrancier qui ne croit pas aux révélations de son Seigneur. Et certes, le châtiment de l'au-delà est plus sévère et plus durable"
    },
    {
      "key": 2476,
      "id": "20-128",
      "translation": "Cela ne leur a-t-il pas servi de direction, que Nous ayons fait périr avant eux tant de générations dans les demeures desquelles ils marchent maintenant? Voilà bien là des leçons pour les doués d'intelligence"
    },
    {
      "key": 2477,
      "id": "20-129",
      "translation": "N'eussent-été un décret préalable de ton Seigneur et aussi un terme déjà fixé, (leur châtiment) aurait été inévitable (et immédiat)"
    },
    {
      "key": 2478,
      "id": "20-130",
      "translation": "Supporte patiemment ce qu'ils disent et célèbre Sa louange, avant le lever du soleil, avant son coucher et pendant la nuit; et exalte Sa Gloire aux extrémités du jour. Peut-être auras-tu satisfaction"
    },
    {
      "key": 2479,
      "id": "20-131",
      "translation": "Et ne tends point les yeux vers ce dont Nous avons donné jouissance temporaire à certains groupes d'entre eux, comme décor de la vie présente, afin de les éprouver par cela. Ce qu'Allah fournit (au Paradis) est meilleur et plus durable"
    },
    {
      "key": 2480,
      "id": "20-132",
      "translation": "Et commande à ta famille la Salât, et fais-la avec persévérance. Nous ne te demandons point de nourriture: c'est à Nous de te nourrir. La bonne fin est réservée à la piété"
    },
    {
      "key": 2481,
      "id": "20-133",
      "translation": "Et ils disent: «Pourquoi ne nous apporte-t-il pas un miracle de son Seigneur? La Preuve (le Coran) de ce que contiennent les Ecritures anciennes ne leur est-elle pas venue"
    },
    {
      "key": 2482,
      "id": "20-134",
      "translation": "Et si Nous les avions fait périr par un châtiment avant lui, ils auraient certainement dit: «O notre Seigneur, pourquoi ne nous as-Tu pas envoyé de Messager? Nous aurions alors suivi Tes enseignements avant d'avoir été humiliés et jetés dans l'ignominie»"
    },
    {
      "key": 2483,
      "id": "20-135",
      "translation": "Dis: «Chacun attend. Attendez donc! Vous saurez bientôt qui sont les gens du droit chemin et qui sont les bien-guidés»"
    },
    {
      "key": 2484,
      "id": "21-1",
      "translation": "[L'échéance] du règlement de leur compte approche pour les hommes, alors que dans leur insouciance ils s'en détournent"
    },
    {
      "key": 2485,
      "id": "21-2",
      "translation": "Aucun rappel [de révélation] récente ne leur vient de leur Seigneur, sans qu'ils ne l'entendent en s'amusant"
    },
    {
      "key": 2486,
      "id": "21-3",
      "translation": "leurs cœurs distraits; et les injustes tiennent des conversations secrètes et disent: «Ce n'est là qu'un être humain semblable à vous? Allez-vous donc vous adonner à la magie alors que vous voyez clair?»"
    },
    {
      "key": 2487,
      "id": "21-4",
      "translation": "Il a répondu: «Mon Seigneur sait tout ce qui se dit au ciel et sur la terre; et Il est l'Audient, l'Omniscient»"
    },
    {
      "key": 2488,
      "id": "21-5",
      "translation": "Mais ils dirent: «Voilà plutôt un amas de rêves! Ou bien Il l'a inventé. Ou, c'est plutôt un poète. Qu'il nous apporte donc un signe [identique] à celui dont furent chargés les premiers envoyés»"
    },
    {
      "key": 2489,
      "id": "21-6",
      "translation": "Pas une seule cité parmi celles que Nous avons fait périr avant eux n'avait cru [à la vue des miracles]. Ceux-ci croiront-ils donc"
    },
    {
      "key": 2490,
      "id": "21-7",
      "translation": "Nous n'avons envoyé avant toi que des hommes à qui Nous faisions des révélations. Demandez donc aux érudits du Livre, si vous ne savez pas"
    },
    {
      "key": 2491,
      "id": "21-8",
      "translation": "Et Nous n'en n'avons pas fait des corps qui ne consommaient pas de nourriture. Et ils n'étaient pas éternels"
    },
    {
      "key": 2492,
      "id": "21-9",
      "translation": "Puis Nous réalisâmes la promesse (qui leur avait été faite). Nous les sauvâmes avec ceux que Nous voulûmes [sauver]. Et Nous fîmes périr les outranciers"
    },
    {
      "key": 2493,
      "id": "21-10",
      "translation": "Nous avons assurément fait descendre vers vous un livre où se trouve votre rappel [ou votre renom]. Ne comprenez-vous donc pas"
    },
    {
      "key": 2494,
      "id": "21-11",
      "translation": "Et que de cités qui ont commis des injustices, Nous avons brisées; et Nous avons créé d'autres peuples après eux"
    },
    {
      "key": 2495,
      "id": "21-12",
      "translation": "Quand [ces gens] sentirent Notre rigueur ils s'en enfuirent hâtivement"
    },
    {
      "key": 2496,
      "id": "21-13",
      "translation": "Ne galopez point. Retournez plutôt au grand luxe où vous étiez et dans vos demeures, afin que vous soyez interrogés"
    },
    {
      "key": 2497,
      "id": "21-14",
      "translation": "Ils dirent: «Malheur à nous! Nous étions vraiment injustes»"
    },
    {
      "key": 2498,
      "id": "21-15",
      "translation": "Telle ne cessa d'être leur lamentation jusqu'à ce que Nous les eûmes moissonnés et éteints"
    },
    {
      "key": 2499,
      "id": "21-16",
      "translation": "Ce n'est pas par jeu que Nous avons créé le ciel et la terre et ce qui est entre eux"
    },
    {
      "key": 2500,
      "id": "21-17",
      "translation": "Si Nous avions voulu prendre une distraction, Nous l'aurions prise de Nous-mêmes, si vraiment Nous avions voulu le faire"
    },
    {
      "key": 2501,
      "id": "21-18",
      "translation": "Bien au contraire, Nous lançons contre le faux la vérité qui le subjugue, et le voilà qui disparaît. Et malheur à vous pour ce que vous attribuez [injustement à Allah]"
    },
    {
      "key": 2502,
      "id": "21-19",
      "translation": "A Lui seul appartiennent tous ceux qui sont dans les cieux et sur la terre. Ceux qui sont auprès de Lui [les Anges] ne se considèrent point trop grands pour L'adorer et ne s'en lassent pas"
    },
    {
      "key": 2503,
      "id": "21-20",
      "translation": "Ils exaltent Sa Gloire nuit et jour et ne s'interrompent point"
    },
    {
      "key": 2504,
      "id": "21-21",
      "translation": "Ont-ils pris des divinités qui peuvent ressusciter (les morts) de la terre"
    },
    {
      "key": 2505,
      "id": "21-22",
      "translation": "S'il y avait dans le ciel et la terre des divinités autres qu'Allah, tous deux seraient certes dans le désordre. Gloire, donc à Allah, Seigneur du Trône; Il est au-dessus de ce qu'ils Lui attribuent"
    },
    {
      "key": 2506,
      "id": "21-23",
      "translation": "Il n'est pas interrogé sur ce qu'Il fait, mais ce sont eux qui devront rendre compte [de leurs actes]"
    },
    {
      "key": 2507,
      "id": "21-24",
      "translation": "Ont-ils pris des divinités en dehors de Lui? Dis: «Apportez votre preuve». Ceci est la révélation de ceux qui sont avec moi et de ceux qui étaient avant moi. Mais la plupart d'entre eux ne connaissent pas la vérité et s'en écartent"
    },
    {
      "key": 2508,
      "id": "21-25",
      "translation": "Et Nous n'avons envoyé avant toi aucun Messager à qui Nous n'ayons révélé: «Point de divinité en dehors de Moi. Adorez-Moi donc»"
    },
    {
      "key": 2509,
      "id": "21-26",
      "translation": "Et ils dirent: «Le Tout Miséricordieux s'est donné un enfant». Pureté à Lui! Mais ce sont plutôt des serviteurs honorés"
    },
    {
      "key": 2510,
      "id": "21-27",
      "translation": "Ils ne devancent pas Son Commandement et agissent selon Ses ordres"
    },
    {
      "key": 2511,
      "id": "21-28",
      "translation": "Il sait ce qui est devant eux et ce qui derrière eux. Et ils n'intercèdent qu'en faveur de ceux qu'Il a agréés [tout en étant] pénétrés de Sa crainte"
    },
    {
      "key": 2512,
      "id": "21-29",
      "translation": "Et quiconque d'entre eux dirait: «Je suis une divinité en dehors de Lui». Nous le rétribuerons de l'Enfer. C'est ainsi que Nous rétribuons les injustes"
    },
    {
      "key": 2513,
      "id": "21-30",
      "translation": "Ceux qui ont mécru, n'ont-ils pas vu que les cieux et la terre formaient une masse compacte? Ensuite Nous les avons séparés et fait de l'eau toute chose vivante. Ne croiront-ils donc pas"
    },
    {
      "key": 2514,
      "id": "21-31",
      "translation": "Et Nous avons placé des montagnes fermes dans la terre, afin qu'elle ne s'ébranle pas en les [entraînant]. Et Nous y avons placé des défilés servant de chemins afin qu'ils se guident"
    },
    {
      "key": 2515,
      "id": "21-32",
      "translation": "Et Nous avons fait du ciel un toit protégé. Et cependant ils se détournent de ses merveilles"
    },
    {
      "key": 2516,
      "id": "21-33",
      "translation": "Et c'est Lui qui a créé la nuit et le jour, le soleil et la lune; chacun voguant dans une orbite"
    },
    {
      "key": 2517,
      "id": "21-34",
      "translation": "Et Nous n'avons attribué l'immortalité à nul homme avant toi. Est-ce que si tu meurs, toi, ils seront, eux, éternels"
    },
    {
      "key": 2518,
      "id": "21-35",
      "translation": "Toute âme doit goûter la mort. Nous vous éprouverons par le mal et par le bien [à titre] de tentation. Et c'est à Nous que vous serez ramenés"
    },
    {
      "key": 2519,
      "id": "21-36",
      "translation": "Quand les mécréants te voient, ils ne te prennent qu'en dérision (disant): «Quoi! Est-ce-là celui qui médit de vos divinités?» Et ils nient [tout] rappel du Tout Miséricordieux"
    },
    {
      "key": 2520,
      "id": "21-37",
      "translation": "L'homme a été créé prompt dans sa nature. Je vous montrerai Mes signes [la réalisation de Mes menaces]. Ne me hâtez donc pas"
    },
    {
      "key": 2521,
      "id": "21-38",
      "translation": "Et ils disent: «A quand cette promesse si vous êtes véridiques?»"
    },
    {
      "key": 2522,
      "id": "21-39",
      "translation": "Si [seulement] les mécréants connaissaient le moment où ils ne pourront empêcher le feu de leurs visages ni de leurs dos, et où ils ne seront point secourus"
    },
    {
      "key": 2523,
      "id": "21-40",
      "translation": "Mais non, cela leur viendra subitement et ils seront alors stupéfaits; ils ne pourront pas le repousser et on ne leur donnera pas de répit"
    },
    {
      "key": 2524,
      "id": "21-41",
      "translation": "On s'est moqué de messagers venus avant toi. Et ceux qui se sont moqués d'eux, se virent frapper de toutes parts par l'objet même de leurs moqueries"
    },
    {
      "key": 2525,
      "id": "21-42",
      "translation": "Dis: «Qui vous protège la nuit et le jour, contre le [châtiment] du Tout Miséricordieux?» Pourtant ils se détournent du rappel de leur Seigneur"
    },
    {
      "key": 2526,
      "id": "21-43",
      "translation": "Ont-ils donc des divinités en dehors de Nous, qui peuvent les protéger? Mais celles-ci ne peuvent ni se secourir elles-mêmes, ni se faire assister contre Nous"
    },
    {
      "key": 2527,
      "id": "21-44",
      "translation": "Au contraire Nous avons accordé une jouissance [temporaire] à ceux-là comme à leurs ancêtres jusqu'à un âge avancé. Ne voient-ils pas que Nous venons à la terre que Nous réduisons de tous côtés? Seront-ils alors les vainqueurs"
    },
    {
      "key": 2528,
      "id": "21-45",
      "translation": "Dis: «Je vous avertis par ce qui m'est révélé». Les sourds, cependant, n'entendent pas l'appel quand on les avertit"
    },
    {
      "key": 2529,
      "id": "21-46",
      "translation": "Si un souffle du châtiment de ton Seigneur les effleurait, ils diraient alors: «Malheur à nous! Nous étions vraiment injustes»"
    },
    {
      "key": 2530,
      "id": "21-47",
      "translation": "Au Jour de la Résurrection, Nous placerons les balances exactes. Nulle âme ne sera lésée en rien, fût-ce du poids d'un grain de moutarde que Nous ferons venir. Nous suffisons largement pour dresser les comptes"
    },
    {
      "key": 2531,
      "id": "21-48",
      "translation": "Nous avons déjà apporté à Moïse et Aaron le Livre du discernement (la Thora) ainsi qu'une lumière et un rappel pour les gens pieux"
    },
    {
      "key": 2532,
      "id": "21-49",
      "translation": "qui craignent leur Seigneur malgré qu'ils ne Le voient pas, et redoutent l'Heure (la fin du monde)"
    },
    {
      "key": 2533,
      "id": "21-50",
      "translation": "Et ceci [le Coran] est un rappel béni que Nous avons fait descendre. Allez-vous donc le renier"
    },
    {
      "key": 2534,
      "id": "21-51",
      "translation": "En effet, Nous avons mis auparavant Abraham sur le droit chemin. Et Nous en avions bonne connaissance"
    },
    {
      "key": 2535,
      "id": "21-52",
      "translation": "Quand il dit à son père et à son peuple: «Que sont ces statues auxquelles vous vous attachez?»"
    },
    {
      "key": 2536,
      "id": "21-53",
      "translation": "ils dirent: «Nous avons trouvé nos ancêtres les adorant»"
    },
    {
      "key": 2537,
      "id": "21-54",
      "translation": "Il dit: «Certainement, vous avez été, vous et vos ancêtres, dans un égarement évident»"
    },
    {
      "key": 2538,
      "id": "21-55",
      "translation": "Ils dirent: «Viens-tu à nous avec la vérité ou plaisantes-tu?»"
    },
    {
      "key": 2539,
      "id": "21-56",
      "translation": "Il dit: «Mais votre Seigneur est plutôt le Seigneur des cieux et de la terre, et c'est Lui qui les a créés. Et je suis un de ceux qui en témoignent"
    },
    {
      "key": 2540,
      "id": "21-57",
      "translation": "Et par Allah! Je ruserai certes contre vos idoles une fois que vous serez partis»"
    },
    {
      "key": 2541,
      "id": "21-58",
      "translation": "Il les mit en pièces, hormis [la statue] la plus grande. Peut-être qu'ils reviendraient vers elle"
    },
    {
      "key": 2542,
      "id": "21-59",
      "translation": "Ils dirent: «Qui a fait cela à nos divinités? Il est certes parmi les injustes»"
    },
    {
      "key": 2543,
      "id": "21-60",
      "translation": "(Certains) dirent: «Nous avons entendu un jeune homme médire d'elles; il s'appelle Abraham»"
    },
    {
      "key": 2544,
      "id": "21-61",
      "translation": "Ils dirent: «Amenez-le sous les yeux des gens afin qu'ils puissent témoigner"
    },
    {
      "key": 2545,
      "id": "21-62",
      "translation": "(Alors) ils dirent: «Est-ce toi qui as fait cela à nos divinités, Abraham?»"
    },
    {
      "key": 2546,
      "id": "21-63",
      "translation": "Il dit: «C'est la plus grande d'entre elles que voici, qui l'a fait. Demandez-leur donc, si elles peuvent parler»"
    },
    {
      "key": 2547,
      "id": "21-64",
      "translation": "Se ravisant alors, ils se dirent entre eux: «C'est vous qui êtes les vrais injustes»"
    },
    {
      "key": 2548,
      "id": "21-65",
      "translation": "Puis ils firent volte-face et dirent: «Tu sais bien que celles-ci ne parlent pas»"
    },
    {
      "key": 2549,
      "id": "21-66",
      "translation": "Il dit: «Adorez-vous donc, en dehors d'Allah, ce qui ne saurait en rien vous être utile ni vous nuire non plus"
    },
    {
      "key": 2550,
      "id": "21-67",
      "translation": "Fi de vous et de ce que vous adorez en dehors d'Allah! Ne raisonnez-vous pas?»"
    },
    {
      "key": 2551,
      "id": "21-68",
      "translation": "Ils dirent: «Brûlez-le. Secourez vos divinités si vous voulez faire quelque chose (pour elles)»"
    },
    {
      "key": 2552,
      "id": "21-69",
      "translation": "Nous dîmes: «O feu, sois pour Abraham une fraîcheur salutaire»"
    },
    {
      "key": 2553,
      "id": "21-70",
      "translation": "Ils voulaient ruser contre lui, mais ce sont eux que Nous rendîmes les plus grands perdants"
    },
    {
      "key": 2554,
      "id": "21-71",
      "translation": "Et Nous le sauvâmes, ainsi que Lot, vers une terre que Nous avions bénie pour tout l'univers"
    },
    {
      "key": 2555,
      "id": "21-72",
      "translation": "Et Nous lui donnâmes Isaac et, de surcroît Jacob, desquels Nous fîmes des gens de bien"
    },
    {
      "key": 2556,
      "id": "21-73",
      "translation": "Nous les fîmes des dirigeants qui guidaient par Notre ordre. Et Nous leur révélâmes de faire le bien, d'accomplir la prière et d'acquitter la Zakât. Et ils étaient Nos adorateurs"
    },
    {
      "key": 2557,
      "id": "21-74",
      "translation": "Et Lot! Nous lui avons apporté la capacité de juger et le savoir, et Nous l'avons sauvé de la cité où se commettaient les vices; ces gens étaient vraiment des gens du mal, des pervers"
    },
    {
      "key": 2558,
      "id": "21-75",
      "translation": "et Nous l'avons fait entrer en Notre miséricorde. Il était vraiment du nombre des gens de bien"
    },
    {
      "key": 2559,
      "id": "21-76",
      "translation": "Et Noé, quand auparavant il fit son appel. Nous l'exauçâmes et Nous le sauvâmes, ainsi que sa famille, de la grande angoisse"
    },
    {
      "key": 2560,
      "id": "21-77",
      "translation": "et Nous le secourûmes contre le peuple qui traitait Nos prodiges de mensonges. Ils furent vraiment des gens du Mal. Nous les noyâmes donc tous"
    },
    {
      "key": 2561,
      "id": "21-78",
      "translation": "Et David, et Salomon, quand ils eurent à juger au sujet d'un champ cultivé où des moutons appartenant à une peuplade étaient allés paître, la nuit. Et Nous étions témoin de leur jugement"
    },
    {
      "key": 2562,
      "id": "21-79",
      "translation": "Nous la fîmes comprendre à Salomon. Et à chacun Nous donnâmes la faculté de juger et le savoir. Et Nous asservîmes les montagnes à exalter Notre Gloire en compagnie de David, ainsi que les oiseaux. Et c'est Nous qui sommes le Faiseur"
    },
    {
      "key": 2563,
      "id": "21-80",
      "translation": "Nous lui (David) apprîmes la fabrication des cottes de mailles afin qu'elles vous protègent contre vos violences mutuelles (la guerre). En êtes-vous donc reconnaissants"
    },
    {
      "key": 2564,
      "id": "21-81",
      "translation": "Et (Nous avons soumis) à Salomon le vent impétueux qui, par son ordre, se dirigea vers la terre que Nous avions bénie. Et Nous sommes à même de tout savoir"
    },
    {
      "key": 2565,
      "id": "21-82",
      "translation": "et parmi les diables il en était qui plongeaient pour lui et faisaient d'autres travaux encore, et Nous les surveillions Nous-mêmes"
    },
    {
      "key": 2566,
      "id": "21-83",
      "translation": "Et Job, quand il implora son Seigneur: «Le mal m'a touché. Mais Toi, tu es le plus miséricordieux des miséricordieux»"
    },
    {
      "key": 2567,
      "id": "21-84",
      "translation": "Nous l'exauçâmes, enlevâmes le mal qu'il avait, lui rendîmes les siens et autant qu'eux avec eux, par miséricorde de Notre part et en tant que rappel aux adorateurs"
    },
    {
      "key": 2568,
      "id": "21-85",
      "translation": "Et Ismaël, Idris, et Dûl-Kifl! qui étaient tous endurants"
    },
    {
      "key": 2569,
      "id": "21-86",
      "translation": "que Nous fîmes entrer en Notre miséricorde car ils étaient vraiment du nombre des gens de bien"
    },
    {
      "key": 2570,
      "id": "21-87",
      "translation": "Et Dû'n-Nûn (Jonas) quand il partit, irrité. Il pensa que Nous N'allions pas l'éprouver. Puis il fit, dans les ténèbres, l'appel que voici: «Pas de divinité à part Toi! Pureté à Toi! J'ai été vraiment du nombre des injustes»"
    },
    {
      "key": 2571,
      "id": "21-88",
      "translation": "Nous l'exauçâmes et le sauvâmes de son angoisse. Et c'est ainsi que Nous sauvons les croyants"
    },
    {
      "key": 2572,
      "id": "21-89",
      "translation": "Et Zacharie, quand il implora son Seigneur: «Ne me laisse pas seul, Seigneur, alors que Tu es le meilleur des héritiers»"
    },
    {
      "key": 2573,
      "id": "21-90",
      "translation": "Nous l'exauçâmes, lui donnâmes Yahya et guérîmes son épouse. Ils concouraient au bien et Nous invoquaient par amour et par crainte. Et ils étaient humbles devant Nous"
    },
    {
      "key": 2574,
      "id": "21-91",
      "translation": "Et celle [la vierge Marie] qui avait préservé sa chasteté! Nous insufflâmes en elle un souffle (de vie) venant de Nous et fîmes d'elle ainsi que de son fils, un signe [miracle] pour l'univers"
    },
    {
      "key": 2575,
      "id": "21-92",
      "translation": "Certes, cette communauté qui est la vôtre est une communauté unique, et Je suis votre Seigneur. Adorez-Moi donc"
    },
    {
      "key": 2576,
      "id": "21-93",
      "translation": "Ils se sont divisés en sectes. Mais tous, retourneront à Nous"
    },
    {
      "key": 2577,
      "id": "21-94",
      "translation": "Quiconque fait de bonnes œuvres tout en étant croyant, on ne méconnaîtra pas son effort, et Nous le lui inscrivons [à son actif]"
    },
    {
      "key": 2578,
      "id": "21-95",
      "translation": "Il est défendu [aux habitants] d'une cité que Nous avons fait périr de revenir [à la vie d'ici-bas]"
    },
    {
      "key": 2579,
      "id": "21-96",
      "translation": "Jusqu'à ce que soient relâchés les Yâjûj et les Mâjûj et qu'ils se précipiteront de chaque hauteur"
    },
    {
      "key": 2580,
      "id": "21-97",
      "translation": "c'est alors que la vraie promesse s'approchera, tandis que les regards de ceux qui ont mécru se figent: «Malheur à nous! Nous y avons été inattentifs. Bien plus, nous étions des injustes»"
    },
    {
      "key": 2581,
      "id": "21-98",
      "translation": "«Vous serez, vous et ce que vous adoriez en dehors d'Allah, le combustible de l'Enfer, vous vous y rendrez tous"
    },
    {
      "key": 2582,
      "id": "21-99",
      "translation": "Si ceux-là étaient vraiment des divinités, ils n'y entreraient pas; et tous y demeureront éternellement"
    },
    {
      "key": 2583,
      "id": "21-100",
      "translation": "Ils y pousseront des gémissements, et n'y entendront rien"
    },
    {
      "key": 2584,
      "id": "21-101",
      "translation": "En seront écartés, ceux à qui étaient précédemment promises de belles récompenses de Notre part"
    },
    {
      "key": 2585,
      "id": "21-102",
      "translation": "Ils n'entendront pas son sifflement et jouiront éternellement de ce que leurs âmes désirent"
    },
    {
      "key": 2586,
      "id": "21-103",
      "translation": "La grande terreur ne les affligera pas, et les Anges les accueilleront: «voici le jour qui vous a été promis»"
    },
    {
      "key": 2587,
      "id": "21-104",
      "translation": "Le jour où Nous plierons le ciel comme on plie le rouleau des livres. Tout comme Nous avons commencé la première création, ainsi Nous la répéterons; c'est une promesse qui Nous incombe et Nous l'accomplirons"
    },
    {
      "key": 2588,
      "id": "21-105",
      "translation": "Et Nous avons certes écrit dans le Zabûr, après l'avoir mentionné (dans le Livre céleste), que la terre sera héritée par Mes bons serviteurs»"
    },
    {
      "key": 2589,
      "id": "21-106",
      "translation": "Il y a en cela [ces enseignements] une communication à un peuple d'adorateurs"
    },
    {
      "key": 2590,
      "id": "21-107",
      "translation": "Et Nous ne t'avons envoyé qu'en miséricorde pour l'univers"
    },
    {
      "key": 2591,
      "id": "21-108",
      "translation": "Dis: «Voilà ce qui m'est révélé: Votre Dieu est un Dieu unique; Etes-vous Soumis?» [décidés à embrasser l'Islam]"
    },
    {
      "key": 2592,
      "id": "21-109",
      "translation": "Si ensuite ils se détournent dis alors: «Je vous ai avertis en toute équité; je ne sais si ce qui vous est promis est proche ou lointain"
    },
    {
      "key": 2593,
      "id": "21-110",
      "translation": "Il connaît ce que vous dites à haute voix et ce que vous cachez"
    },
    {
      "key": 2594,
      "id": "21-111",
      "translation": "Et je ne sais pas; ceci est peut-être une tentation pour vous et une jouissance pour un certain temps»"
    },
    {
      "key": 2595,
      "id": "21-112",
      "translation": "Il dit: «Seigneur, juge en toute justice! Et Notre Seigneur le Tout Miséricordieux, c'est Lui dont le secours est imploré contre vos assertions»"
    },
    {
      "key": 2596,
      "id": "22-1",
      "translation": "O hommes! Craignez votre Seigneur. Le séisme [qui précédera] l'Heure est une chose terrible"
    },
    {
      "key": 2597,
      "id": "22-2",
      "translation": "Le jour où vous le verrez, toute nourrice oubliera ce qu'elle allaitait, et toute femelle enceinte avortera de ce qu'elle portait. Et tu verras les gens ivres, alors qu'ils ne le sont pas. Mais le châtiment d'Allah est dur"
    },
    {
      "key": 2598,
      "id": "22-3",
      "translation": "Et il y a des gens qui discutent au sujet d'Allah sans aucune science, et qui suivent tout diable rebelle"
    },
    {
      "key": 2599,
      "id": "22-4",
      "translation": "Il a été prescrit à l'égard de ce dernier qu'il égarera quiconque le prendra pour maître, et qu'il le guidera vers le châtiment de la fournaise"
    },
    {
      "key": 2600,
      "id": "22-5",
      "translation": "O hommes! Si vous doutez au sujet de la Résurrection, c'est Nous qui vous avons créés de terre, puis d'une goutte de sperme, puis d'une adhérence puis d'un embryon [normalement] formé aussi bien qu'informe pour vous montrer [Notre Omnipotence] et Nous déposerons dans les matrices ce que Nous voulons jusqu'à un terme fixé. Puis Nous vous en sortirons [à l'état] de bébé, pour qu'ensuite vous atteignez votre maturité. Il en est parmi vous qui meurent [jeunes] tandis que d'autres parviennent au plus vil de l'âge si bien qu'ils ne savent plus rien de ce qu'ils connaissaient auparavant. De même tu vois la terre desséchée: dès que Nous y faisons descendre de l'eau elle remue, se gonfle, et fait pousser toutes sortes de splendides couples de végétaux"
    },
    {
      "key": 2601,
      "id": "22-6",
      "translation": "Il en est ainsi parce qu'Allah est la vérité; et c'est Lui qui rend la vie aux morts; et c'est Lui qui est Omnipotent"
    },
    {
      "key": 2602,
      "id": "22-7",
      "translation": "Et que l'Heure arrivera; pas de doute à son sujet, et qu'Allah ressuscitera ceux qui sont dans les tombeaux"
    },
    {
      "key": 2603,
      "id": "22-8",
      "translation": "Or, il y a des gens qui discutent au sujet d'Allah sans aucune science, ni guide, ni Livre pour les éclairer"
    },
    {
      "key": 2604,
      "id": "22-9",
      "translation": "affichant une attitude orgueilleuse pour égarer les gens du sentier d'Allah. A lui l'ignominie ici-bas; et Nous Lui ferons goûter le Jour de la Résurrection, le châtiment de la fournaise"
    },
    {
      "key": 2605,
      "id": "22-10",
      "translation": "Voilà, pour ce que tes deux mains ont préparé (ici-bas)! Cependant, Allah n'est point injuste envers Ses serviteurs"
    },
    {
      "key": 2606,
      "id": "22-11",
      "translation": "Il en est parmi les gens qui adorent Allah marginalement. S'il leur arrive un bien, ils s'en tranquillisent, et s'il leur arrive une épreuve, ils détournent leur visage, perdant ainsi (le bien) de l'ici-bas et de l'au-delà. Telle est la perte évidente"
    },
    {
      "key": 2607,
      "id": "22-12",
      "translation": "Ils invoquent en dehors d'Allah, ce qui ne peut ni leur nuire ni leur profiter. Tel est l'égarement profond"
    },
    {
      "key": 2608,
      "id": "22-13",
      "translation": "Ils invoquent ce dont le mal est certainement plus proche que l'utilité. Quel mauvais allié, et quel mauvais compagnon"
    },
    {
      "key": 2609,
      "id": "22-14",
      "translation": "Ceux qui croient et font de bonnes œuvres, Allah les fait entrer aux Jardins sous lesquels coulent les ruisseaux, car Allah fait certes ce qu'Il veut"
    },
    {
      "key": 2610,
      "id": "22-15",
      "translation": "Celui qui pense qu'Allah ne le secourra pas dans l'ici-bas et dans l'au-delà qu'il tende une corde jusqu'au ciel, puis qu'il la coupe, et qu'il voie si sa ruse va faire disparaître ce qui l'enrage"
    },
    {
      "key": 2611,
      "id": "22-16",
      "translation": "C'est ainsi que Nous le fîmes descendre (Le Coran) en versets clairs et qu'Allah guide qui Il veut"
    },
    {
      "key": 2612,
      "id": "22-17",
      "translation": "Certes, ceux qui ont cru, les Juifs, les Sabéens [les adorateurs des étoiles], les Nazaréens, les Mages et ceux qui donnent à Allah des associés, Allah tranchera entre eux le jour du Jugement, car Allah est certes témoin de toute chose"
    },
    {
      "key": 2613,
      "id": "22-18",
      "translation": "N'as-tu pas vu que c'est devant Allah que se prosternent tous ceux qui sont dans les cieux et tous ceux qui sont sur la terre, le soleil, la lune, les étoiles, les montagnes, les arbres, les animaux, ainsi que beaucoup de gens? Il y en a aussi beaucoup qui méritent le châtiment. Et quiconque Allah avilit n'a personne pour l'honorer, car Allah fait ce qu'il veut"
    },
    {
      "key": 2614,
      "id": "22-19",
      "translation": "Voici deux clans adverses qui disputaient au sujet de leur Seigneur. A ceux qui ne croient pas, on taillera des vêtements de feu, tandis que sur leurs têtes on versera de l'eau bouillante"
    },
    {
      "key": 2615,
      "id": "22-20",
      "translation": "qui fera fondre ce qui est dans leurs ventres de même que leurs peaux"
    },
    {
      "key": 2616,
      "id": "22-21",
      "translation": "Et il y aura pour eux des maillets de fer"
    },
    {
      "key": 2617,
      "id": "22-22",
      "translation": "Toutes les fois qu'ils voudront en sortir (pour échapper) à la détresse, on les y remettra et (on leur dira): «Goûtez au châtiment de la Fournaise"
    },
    {
      "key": 2618,
      "id": "22-23",
      "translation": "Certes Allah introduit ceux qui croient et font de bonnes œuvres aux Jardins sous lesquels coulent les ruisseaux. Là, ils seront parés de bracelets d'or, et aussi de perles; et leurs vêtements y seront de soie"
    },
    {
      "key": 2619,
      "id": "22-24",
      "translation": "Ils ont été guidés vers la bonne parole et ils ont été guidés vers le chemin Du Digne des louanges"
    },
    {
      "key": 2620,
      "id": "22-25",
      "translation": "Mais ceux qui mécroient et qui obstruent le sentier d'Allah et celui de la Mosquée sacrée, que Nous avons établie pour les gens: aussi bien les résidents que ceux de passage... Quiconque cherche à y commettre un sacrilège injustement, Nous lui ferons goûter un châtiment douloureux"
    },
    {
      "key": 2621,
      "id": "22-26",
      "translation": "Et quand Nous indiquâmes pour Abraham le lieu de la Maison (La Kaaba) [en lui disant]: «Ne M'associe rien; et purifie Ma Maison pour ceux qui tournent autour, pour ceux qui s'y tiennent debout et pour ceux qui s'y inclinent et se prosternent»"
    },
    {
      "key": 2622,
      "id": "22-27",
      "translation": "Et fais aux gens une annonce pour le Hajj. Ils viendront vers toi, à pied, et aussi sur toute monture, venant de tout chemin éloigné"
    },
    {
      "key": 2623,
      "id": "22-28",
      "translation": "pour participer aux avantages qui leur ont été accordés et pour invoquer le nom d'Allah aux jours fixés, sur la bête de cheptel qu'Il leur a attribuée, «Mangez-en vous-mêmes et faites-en manger le besogneux misérable"
    },
    {
      "key": 2624,
      "id": "22-29",
      "translation": "Puis qu'ils mettent fin à leurs interdits (qu'ils nettoient leurs corps), qu'ils remplissent leurs vœux, et qu'ils fassent les circuits autour de l'Antique Maison»"
    },
    {
      "key": 2625,
      "id": "22-30",
      "translation": "Voilà [ce qui doit être observé] et quiconque prend en haute considération les limites sacrées d'Allah cela lui sera meilleur auprès de son Seigneur. Le bétail, sauf ce qu'on vous a cité, vous a été rendu licite. Abstenez-vous de la souillure des idoles et abstenez-vous des paroles mensongères"
    },
    {
      "key": 2626,
      "id": "22-31",
      "translation": "(Soyez) exclusivement [acquis à la religion] d'Allah ne Lui associez rien; car quiconque associe à Allah, c'est comme s'il tombait du haut du ciel et que les oiseaux le happaient, ou que le vent le précipitait dans un abîme très profond"
    },
    {
      "key": 2627,
      "id": "22-32",
      "translation": "Voilà [ce qui est prescrit]. Et quiconque exalte les injonctions sacrées d'Allah, s'inspire en effet de la piété des cœurs"
    },
    {
      "key": 2628,
      "id": "22-33",
      "translation": "[De ces bêtes-là] vous tirez des avantages jusqu'à un terme fixé; puis son lieu d'immolation est auprès de l'Antique Maison"
    },
    {
      "key": 2629,
      "id": "22-34",
      "translation": "A chaque communauté, Nous avons assigné un rite sacrificiel, afin qu'ils prononcent le nom d'Allah sur la bête de cheptel qu'Il leur a attribuée. Votre Dieu est certes un Dieu unique. Soumettez-vous donc à Lui. Et fais bonne annonce à ceux qui s'humilient"
    },
    {
      "key": 2630,
      "id": "22-35",
      "translation": "ceux dont les cœurs frémissent quand le nom d'Allah est mentionné, ceux qui endurent ce qui les atteint et ceux qui accomplissent la Salât et dépensent de ce que Nous leur avons attribué"
    },
    {
      "key": 2631,
      "id": "22-36",
      "translation": "Nous vous avons désigné les chameaux (et les vaches) bien portants pour certains rites établis par Allah. Il y a en eux pour vous un bien. Prononcez donc sur eux le nom d'Allah, quand ils ont eu la patte attachée, [prêts à être immolés]. Puis, lorsqu'ils gisent sur le flanc, mangez-en, et nourrissez-en le besogneux discret et le mendiant. Ainsi Nous vous les avons assujettis afin que vous soyez reconnaissants"
    },
    {
      "key": 2632,
      "id": "22-37",
      "translation": "Ni leurs chairs ni leurs sangs n'atteindront Allah, mais ce qui L'atteint de votre part c'est la piété. Ainsi vous les a-t-Il assujettis afin que vous proclamiez la grandeur d'Allah, pour vous avoir mis sur le droit chemin. Et annonce la bonne nouvelle aux bienfaisants"
    },
    {
      "key": 2633,
      "id": "22-38",
      "translation": "Allah prend la défense de ceux qui croient. Allah n'aime aucun traître ingrat"
    },
    {
      "key": 2634,
      "id": "22-39",
      "translation": "Autorisation est donnée à ceux qui sont attaqués (de se défendre) - parce que vraiment ils sont lésés; et Allah est certes Capable de les secourir"
    },
    {
      "key": 2635,
      "id": "22-40",
      "translation": "ceux qui ont été expulsés de leurs demeures, - contre toute justice, simplement parce qu'ils disaient: «Allah est notre Seigneur». - Si Allah ne repoussait pas les gens les uns par les autres, les ermitages seraient démolis, ainsi que les églises, les synagogues et les mosquées où le nom d'Allah est beaucoup invoqué. Allah soutient, certes, ceux qui soutiennent (Sa Religion). Allah est assurément Fort et Puissant"
    },
    {
      "key": 2636,
      "id": "22-41",
      "translation": "ceux qui, si Nous leur donnons la puissance sur terre, accomplissent la Salât, acquittent la Zakât, ordonnent le convenable et interdisent le blâmable. Cependant, l'issue finale de toute chose appartient à Allah"
    },
    {
      "key": 2637,
      "id": "22-42",
      "translation": "Et s'ils te traitent de menteur, [sache que] le peuple de Noé, les 'Aad, les Tamûd avant eux, ont aussi crié au mensonge (à l'égard de leurs messagers)"
    },
    {
      "key": 2638,
      "id": "22-43",
      "translation": "de même que le peuple d'Abraham, le peuple de Lot"
    },
    {
      "key": 2639,
      "id": "22-44",
      "translation": "et les gens de Madyan. Et Moïse fut traité de menteur. Puis, J'ai donné un répit aux mécréants; ensuite Je les ai saisis. Et quelle fut Ma réprobation"
    },
    {
      "key": 2640,
      "id": "22-45",
      "translation": "Que de cités, donc, avons-Nous fait périr, parce qu'elles commettaient des tyrannies. Elles sont réduites à des toits écroulés: Que de puits désertés! Que de palais édifiés (et désertés aussi)"
    },
    {
      "key": 2641,
      "id": "22-46",
      "translation": "Que ne voyagent-ils sur la terre afin d'avoir des cœurs pour comprendre, et des oreilles pour entendre? Car ce ne sont pas les yeux qui s'aveuglent, mais, ce sont les cœurs dans les poitrines qui s'aveuglent"
    },
    {
      "key": 2642,
      "id": "22-47",
      "translation": "Et ils te demandent de hâter [l'arrivée] du châtiment. Jamais Allah ne manquera à Sa promesse. Cependant, un jour auprès de ton Seigneur, équivaut à mille ans de ce que vous comptez"
    },
    {
      "key": 2643,
      "id": "22-48",
      "translation": "A combien de cités n'ai-Je pas donné répit alors qu'elles commettaient des tyrannies? Ensuite, Je les ais saisies. Vers Moi est le devenir"
    },
    {
      "key": 2644,
      "id": "22-49",
      "translation": "Dis: «O hommes! Je ne suis pour vous, en vérité, qu'un avertisseur explicite»"
    },
    {
      "key": 2645,
      "id": "22-50",
      "translation": "Ceux donc qui croient et font de bonnes œuvres auront pardon et faveurs généreuses"
    },
    {
      "key": 2646,
      "id": "22-51",
      "translation": "tandis que ceux qui s'efforcent à échapper (au châtiment mentionné dans) Nos versets, ceux-là sont les gens de l'Enfer"
    },
    {
      "key": 2647,
      "id": "22-52",
      "translation": "Nous n'avons envoyé, avant toi, ni Messager ni prophète qui n'ait récité (ce qui lui a été révélé) sans que le Diable n'ait essayé d'intervenir [pour semer le doute dans le cœur des gens au sujet] de sa récitation. Allah abroge ce que le Diable suggère, et Allah renforce Ses versets. Allah est Omniscient et Sage"
    },
    {
      "key": 2648,
      "id": "22-53",
      "translation": "Afin de faire, de ce que jette le Diable, une tentation pour ceux qui ont une maladie au cœur et ceux qui ont le cœur dur... Les injustes sont certes dans un schisme profond"
    },
    {
      "key": 2649,
      "id": "22-54",
      "translation": "Et afin que ceux à qui le savoir a été donné sachent que (le Coran) est en effet, la Vérité venant de ton Seigneur, qu'ils y croient alors, et que leurs cœurs s'y soumettent en toute humilité. Allah guide certes vers le droit chemin ceux qui croient"
    },
    {
      "key": 2650,
      "id": "22-55",
      "translation": "Et ceux qui mécroient ne cesseront d'être en doute à son sujet, jusqu'à ce que l'Heure les surprenne à l'improviste ou que les atteigne le châtiment d'un jour terrifiant"
    },
    {
      "key": 2651,
      "id": "22-56",
      "translation": "La souveraineté ce jour-là appartiendra à Allah qui jugera parmi eux. Ceux qui auront cru et fait de bonnes œuvres seront dans les Jardins du délice"
    },
    {
      "key": 2652,
      "id": "22-57",
      "translation": "et quant aux infidèles qui auront traité Nos révélations de mensonges, ils auront un châtiment avilissant"
    },
    {
      "key": 2653,
      "id": "22-58",
      "translation": "Ceux qui émigrent dans le sentier d'Allah et qui sont tués ou meurent, Allah leur accordera certes une belle récompense, car Allah est le meilleur des donateurs"
    },
    {
      "key": 2654,
      "id": "22-59",
      "translation": "Il les fera, certes, entrer en un lieu qu'ils agréeront, et Allah est certes Omniscient et Indulgent"
    },
    {
      "key": 2655,
      "id": "22-60",
      "translation": "Ainsi en est-il. Quiconque châtie de la même façon dont il a été châtié, et qu'ensuite il est victime d'un nouvel outrage, Allah l'aidera, car Allah est certainement Absoluteur et Pardonneur"
    },
    {
      "key": 2656,
      "id": "22-61",
      "translation": "C'est ainsi qu'Allah fait pénétrer la nuit dans le jour, et fait pénétrer le jour dans la nuit. Allah est, certes, Audient et Clairvoyant"
    },
    {
      "key": 2657,
      "id": "22-62",
      "translation": "C'est ainsi qu'Allah est Lui le Vrai, alors que ce qu'ils invoquent en dehors de Lui est le faux; c'est Allah qui est le Sublime, le Grand"
    },
    {
      "key": 2658,
      "id": "22-63",
      "translation": "N'as-tu pas vu qu'Allah fait descendre l'eau du ciel, et la terre devient alors verte? Allah est Plein de bonté et Parfaitement Connaisseur"
    },
    {
      "key": 2659,
      "id": "22-64",
      "translation": "A Lui appartient ce qui est dans les cieux et sur la terre. Allah est le seul qui se suffit à Lui-Même et qui est Le Digne de louange"
    },
    {
      "key": 2660,
      "id": "22-65",
      "translation": "N'as-tu pas vu qu'Allah vous a soumis tout ce qui est sur la terre ainsi que le vaisseau qui vogue sur la mer par Son ordre? Il retient le ciel de tomber sur la terre, sauf quand Il le permettra. Car Allah est Plein de bonté et de miséricorde envers les hommes"
    },
    {
      "key": 2661,
      "id": "22-66",
      "translation": "C'est Lui qui vous donne la vie puis vous donne la mort, puis vous fait revivre. Vraiment l'homme est très ingrat"
    },
    {
      "key": 2662,
      "id": "22-67",
      "translation": "A chaque communauté, Nous avons assigné un culte à suivre. Qu'ils ne disputent donc point avec toi l'ordre reçu! Et appelle à ton Seigneur. Tu es certes sur une voie droite"
    },
    {
      "key": 2663,
      "id": "22-68",
      "translation": "Et s'ils discutent avec toi, alors dis: «C'est Allah qui connaît mieux ce que vous faites"
    },
    {
      "key": 2664,
      "id": "22-69",
      "translation": "Allah jugera entre vous, au Jour de la Résurrection, ce en quoi vous divergez»"
    },
    {
      "key": 2665,
      "id": "22-70",
      "translation": "Ne sais-tu pas qu'Allah sait ce qu'il y a dans le ciel et sur la terre? Tout cela est dans un Livre, et cela est pour Allah bien facile"
    },
    {
      "key": 2666,
      "id": "22-71",
      "translation": "Et ils adorent en dehors d'Allah, ce en quoi Il n'a fait descendre aucune preuve et ce dont ils n'ont aucune connaissance. Et il n'y aura pas de protecteur pour les injustes"
    },
    {
      "key": 2667,
      "id": "22-72",
      "translation": "Et quand on leur récite Nos versets bien clairs, tu discerneras la réprobation sur les visages de ceux qui ont mécru. Peu s'en faut qu'ils ne se jettent sur ceux qui leur récitent Nos versets. Dis: «Vous informerai-je de quelque chose de plus terrible? - Le Feu: Allah l'a promis à ceux qui ont mécru. Et quel triste devenir!»"
    },
    {
      "key": 2668,
      "id": "22-73",
      "translation": "O hommes! Une parabole vous est proposée, écoutez-la: «Ceux que vous invoquez en dehors d'Allah ne sauraient même pas créer une mouche, quand même ils s'uniraient pour cela. Et si la mouche les dépouillait de quelque chose, ils ne sauraient le lui reprendre. Le solliciteur et le sollicité sont [également] faibles!»"
    },
    {
      "key": 2669,
      "id": "22-74",
      "translation": "Ils n'ont pas estimé Allah à sa juste valeur; Allah est certes Fort et Puissant"
    },
    {
      "key": 2670,
      "id": "22-75",
      "translation": "Allah choisit des messagers parmi les Anges et parmi les hommes. Allah est Audient et Clairvoyant"
    },
    {
      "key": 2671,
      "id": "22-76",
      "translation": "Il sait ce qui est devant eux et derrière eux. Et c'est vers Allah que tout retournera"
    },
    {
      "key": 2672,
      "id": "22-77",
      "translation": "O vous qui croyez! Inclinez-vous, prosternez-vous, adorez votre Seigneur, et faites le bien. Peut-être réussirez vous"
    },
    {
      "key": 2673,
      "id": "22-78",
      "translation": "Et luttez pour Allah avec tout l'effort qu'Il mérite. C'est Lui qui vous a élus; et Il ne vous a imposé aucune gêne dans la religion, celle de votre père Abraham, lequel vous a déjà nommés «Musulmans» avant (ce Livre) et dans ce (Livre), afin que le Messager soit témoin contre vous, et que vous soyez vous-mêmes témoins contre les gens. Accomplissez donc la Salât, acquittez la Zakât et attachez-vous fortement à Allah. C'est Lui votre Maître. Quel Excellent Maître! Et quel Excellent Soutien"
    },
    {
      "key": 2674,
      "id": "23-1",
      "translation": "Bienheureux sont certes les croyants"
    },
    {
      "key": 2675,
      "id": "23-2",
      "translation": "ceux qui sont humbles dans leur Salât"
    },
    {
      "key": 2676,
      "id": "23-3",
      "translation": "qui se détournent des futilités"
    },
    {
      "key": 2677,
      "id": "23-4",
      "translation": "qui s'acquittent de la Zakât"
    },
    {
      "key": 2678,
      "id": "23-5",
      "translation": "et qui préservent leurs sexes [de tout rapport]"
    },
    {
      "key": 2679,
      "id": "23-6",
      "translation": "si ce n'est qu'avec leurs épouses ou les esclaves qu'ils possèdent, car là vraiment, on ne peut les blâmer"
    },
    {
      "key": 2680,
      "id": "23-7",
      "translation": "alors que ceux qui cherchent au-delà de ces limites sont des transgresseurs"
    },
    {
      "key": 2681,
      "id": "23-8",
      "translation": "et qui veillent à la sauvegarde des dépôts confiés à eux et honorent leurs engagements"
    },
    {
      "key": 2682,
      "id": "23-9",
      "translation": "et qui observent strictement leur Salât"
    },
    {
      "key": 2683,
      "id": "23-10",
      "translation": "Ce sont eux les héritiers"
    },
    {
      "key": 2684,
      "id": "23-11",
      "translation": "qui hériteront le Paradis pour y demeurer éternellement"
    },
    {
      "key": 2685,
      "id": "23-12",
      "translation": "Nous avons certes créé l'homme d'un extrait d'argile"
    },
    {
      "key": 2686,
      "id": "23-13",
      "translation": "puis Nous en fîmes une goutte de sperme dans un reposoir solide"
    },
    {
      "key": 2687,
      "id": "23-14",
      "translation": "Ensuite, Nous avons fait du sperme une adhérence; et de l'adhérence Nous avons créé un embryon; puis, de cet embryon Nous avons créé des os et Nous avons revêtu les os de chair. Ensuite, Nous l'avons transformé en une tout autre création. Gloire à Allah le Meilleur des créateurs"
    },
    {
      "key": 2688,
      "id": "23-15",
      "translation": "Et puis, après cela vous mourrez"
    },
    {
      "key": 2689,
      "id": "23-16",
      "translation": "Et puis au Jour de la Résurrection vous serez ressuscités"
    },
    {
      "key": 2690,
      "id": "23-17",
      "translation": "Nous avons créé, au-dessus de vous, sept cieux. Et Nous ne sommes pas inattentifs à la création"
    },
    {
      "key": 2691,
      "id": "23-18",
      "translation": "Et Nous avons fait descendre l'eau du ciel avec mesure. Puis Nous l'avons maintenue dans la terre, cependant que Nous sommes bien Capable de la faire disparaître"
    },
    {
      "key": 2692,
      "id": "23-19",
      "translation": "Avec elle, Nous avons produit pour vous des jardins de palmiers et de vignes, dans lesquels vous avez des fruits abondants et desquels vous mangez"
    },
    {
      "key": 2693,
      "id": "23-20",
      "translation": "ainsi qu'un arbre (l'olivier) qui pousse au Mont Sinaî, en produisant l'huile servant à oindre et où les mangeurs trempent leur pain"
    },
    {
      "key": 2694,
      "id": "23-21",
      "translation": "Vous avez certes dans les bestiaux, un sujet de méditation. Nous vous donnons à boire de ce qu'ils ont dans le ventre, et vous y trouvez également maintes utilités; et vous vous en nourrissez"
    },
    {
      "key": 2695,
      "id": "23-22",
      "translation": "Sur eux ainsi que sur des vaisseaux vous êtes transportés"
    },
    {
      "key": 2696,
      "id": "23-23",
      "translation": "Nous envoyâmes Noé vers son peuple. Il dit: «O mon peuple, adorez Allah. Vous n'avez pas d'autre divinité en dehors de Lui. Ne [Le] craignez-vous pas?»"
    },
    {
      "key": 2697,
      "id": "23-24",
      "translation": "Alors les notables de son peuple qui avaient mécru dirent: «Celui-ci n'est qu'un être humain comme vous voulant se distinguer à votre détriment. Si Allah avait voulu, ce sont des Anges qu'Il aurait fait descendre. Jamais nous n'avons entendu cela chez nos ancêtres les plus reculés"
    },
    {
      "key": 2698,
      "id": "23-25",
      "translation": "Ce n'est en vérité qu'un homme atteint de folie, observez-le donc durant quelque temps"
    },
    {
      "key": 2699,
      "id": "23-26",
      "translation": "Il dit: «Seigneur! Apporte-moi secours parce qu'ils me traitent de menteur»"
    },
    {
      "key": 2700,
      "id": "23-27",
      "translation": "Nous lui révélâmes: «Construis l'arche sous Nos yeux et selon Notre révélation. Et quand Notre commandement viendra et que le four bouillonnera, achemine là-dedans un couple de chaque espèce, ainsi que ta famille, sauf ceux d'entre eux contre qui la parole a déjà été prononcée; et ne t'adresse pas à Moi au sujet des injustes, car ils seront fatalement noyés"
    },
    {
      "key": 2701,
      "id": "23-28",
      "translation": "Et lorsque tu seras installé, toi et ceux qui sont avec toi, dans l'arche, dis: «Louange à Allah qui nous a sauvés du peuple des injustes»"
    },
    {
      "key": 2702,
      "id": "23-29",
      "translation": "Et dis: «Seigneur, fais-moi débarquer d'un débarquement béni. Tu es Celui qui procure le meilleur débarquement»"
    },
    {
      "key": 2703,
      "id": "23-30",
      "translation": "Voilà bien là des signes. Nous sommes certes Celui qui éprouve"
    },
    {
      "key": 2704,
      "id": "23-31",
      "translation": "Puis, après eux, Nous avons créé d'autres générations"
    },
    {
      "key": 2705,
      "id": "23-32",
      "translation": "Nous envoyâmes parmi elles un Messager [issu] d'elles pour leur dire: «Adorez Allah. Vous n'avez pas d'autre divinité en dehors de Lui. Ne le craignez-vous pas?»"
    },
    {
      "key": 2706,
      "id": "23-33",
      "translation": "Les notables de son peuple qui avaient mécru et traité de mensonge la rencontre de l'au-delà, et auxquels Nous avions accordé le luxe dans la vie présente, dirent: «Celui-ci n'est qu'un être humain comme vous, mangeant de ce que vous mangez, et buvant de ce que vous buvez"
    },
    {
      "key": 2707,
      "id": "23-34",
      "translation": "Si vous obéissez à un homme comme vous, vous serez alors perdants"
    },
    {
      "key": 2708,
      "id": "23-35",
      "translation": "Vous promet-il, quand vous serez morts, et devenus poussière et ossements, que vous serez sortis [de vos sépulcres]"
    },
    {
      "key": 2709,
      "id": "23-36",
      "translation": "Loin, loin, ce qu'on vous promet"
    },
    {
      "key": 2710,
      "id": "23-37",
      "translation": "Ce n'est là que notre vie présente: nous mourons et nous vivons; et nous ne serons jamais ressuscités"
    },
    {
      "key": 2711,
      "id": "23-38",
      "translation": "Ce n'est qu'un homme qui forge un mensonge contre Allah; et nous ne croirons pas en lui»"
    },
    {
      "key": 2712,
      "id": "23-39",
      "translation": "Il dit: «Seigneur! Apporte-moi secours parce qu'ils me traitent de menteur»"
    },
    {
      "key": 2713,
      "id": "23-40",
      "translation": "[Allah] dit: «Oui, bientôt ils en viendront aux regrets»"
    },
    {
      "key": 2714,
      "id": "23-41",
      "translation": "Le cri, donc, les saisit en toute justice; puis Nous les rendîmes semblables à des débris emportés par le torrent. Que disparaissent à jamais les injustes"
    },
    {
      "key": 2715,
      "id": "23-42",
      "translation": "Puis après eux Nous avons créé d'autres générations"
    },
    {
      "key": 2716,
      "id": "23-43",
      "translation": "Nulle communauté ne peut avancer ni reculer son terme"
    },
    {
      "key": 2717,
      "id": "23-44",
      "translation": "Ensuite, Nous envoyâmes successivement Nos messagers. Chaque fois qu'un messager se présentait à sa communauté, ils le traitaient de menteur. Et Nous les fîmes succéder les unes aux autres [dans la destruction], et Nous en fîmes des thèmes de récits légendaires. Que disparaissent à jamais les gens qui ne croient pas"
    },
    {
      "key": 2718,
      "id": "23-45",
      "translation": "Ensuite, Nous envoyâmes Moïse et son frère Aaron avec Nos prodiges et une preuve évidente"
    },
    {
      "key": 2719,
      "id": "23-46",
      "translation": "vers Pharaon et ses notables mais ceux-ci s'enflèrent d'orgueil: ils étaient des gens hautains"
    },
    {
      "key": 2720,
      "id": "23-47",
      "translation": "Ils dirent: «Croirons-nous en deux hommes comme nous dont les congénères sont nos esclaves»"
    },
    {
      "key": 2721,
      "id": "23-48",
      "translation": "Ils les traitèrent [tous deux] de menteurs et ils furent donc parmi les anéantis"
    },
    {
      "key": 2722,
      "id": "23-49",
      "translation": "Et Nous avions apporté le Livre à Moïse afin qu'ils se guident"
    },
    {
      "key": 2723,
      "id": "23-50",
      "translation": "Et Nous fîmes du fils de Marie, ainsi que de sa mère, un prodige; et Nous donnâmes à tous deux asile sur une colline bien stable et dotée d'une source"
    },
    {
      "key": 2724,
      "id": "23-51",
      "translation": "O Messagers! Mangez de ce qui est permis et agréable et faites du bien. Car Je sais parfaitement ce que vous faites"
    },
    {
      "key": 2725,
      "id": "23-52",
      "translation": "Cette communauté, la vôtre, est une seule communauté, tandis que Je suis votre Seigneur. Craignez-Moi donc»"
    },
    {
      "key": 2726,
      "id": "23-53",
      "translation": "Mais ils se sont divisés en sectes, chaque secte exultant de ce qu'elle détenait"
    },
    {
      "key": 2727,
      "id": "23-54",
      "translation": "Laisse-les dans leur égarement pour un certain temps"
    },
    {
      "key": 2728,
      "id": "23-55",
      "translation": "Pensent-ils que ce que Nous leur accordons, en biens et en enfants"
    },
    {
      "key": 2729,
      "id": "23-56",
      "translation": "[soit une avance] que Nous Nous empressons de leur faire sur les biens [de la vie future]? Au contraire, ils n'en sont pas conscients"
    },
    {
      "key": 2730,
      "id": "23-57",
      "translation": "Ceux qui, de la crainte de leur Seigneur, sont pénétrés"
    },
    {
      "key": 2731,
      "id": "23-58",
      "translation": "qui croient aux versets de leur Seigneur"
    },
    {
      "key": 2732,
      "id": "23-59",
      "translation": "qui n'associent rien à leur Seigneur"
    },
    {
      "key": 2733,
      "id": "23-60",
      "translation": "qui donnent ce qu'ils donnent, tandis que leurs cœurs sont pleins de crainte [à la pensée] qu'ils doivent retourner à leur Seigneur"
    },
    {
      "key": 2734,
      "id": "23-61",
      "translation": "Ceux-là se précipitent vers les bonnes actions et sont les premiers à les accomplir"
    },
    {
      "key": 2735,
      "id": "23-62",
      "translation": "Nous n'imposons à personne que selon sa capacité. Et auprès de Nous existe un Livre qui dit la vérité, et ils ne seront pas lésés"
    },
    {
      "key": 2736,
      "id": "23-63",
      "translation": "Mais leurs cœurs restent dans l'ignorance à l'égard de cela [le Coran]. [En outre] ils ont d'autres actes (vils) qu'ils accomplissent"
    },
    {
      "key": 2737,
      "id": "23-64",
      "translation": "jusqu'à ce que par le châtiment Nous saisissions les plus aisés parmi eux et voilà qu'ils crient au secours"
    },
    {
      "key": 2738,
      "id": "23-65",
      "translation": "«Ne criez pas aujourd'hui. Nul ne vous protègera contre Nous"
    },
    {
      "key": 2739,
      "id": "23-66",
      "translation": "Mes versets vous étaient récités auparavant; mais vous vous [en] détourniez"
    },
    {
      "key": 2740,
      "id": "23-67",
      "translation": "s'enflant d'orgueil, et vous les dénigriez au cours de vos veillées»"
    },
    {
      "key": 2741,
      "id": "23-68",
      "translation": "Ne méditent-ils donc pas sur la parole (le Coran)? Ou est-ce que leur est venu ce qui n'est jamais venu à leurs premiers ancêtres"
    },
    {
      "key": 2742,
      "id": "23-69",
      "translation": "Ou n'ont-ils pas connu leur Messager, au point de le renier"
    },
    {
      "key": 2743,
      "id": "23-70",
      "translation": "Ou diront-ils: «Il est fou?» Au contraire, c'est la vérité qu'il leur a apportée. Et la plupart d'entre eux dédaignent la vérité"
    },
    {
      "key": 2744,
      "id": "23-71",
      "translation": "Si la vérité était conforme à leurs passions, les cieux et la terre et ceux qui s'y trouvent seraient, certes, corrompus. Au contraire, Nous leur avons donné leur rappel. Mais ils s'en détournent"
    },
    {
      "key": 2745,
      "id": "23-72",
      "translation": "Ou leur demandes-tu une rétribution? Mais la rétribution de ton Seigneur est meilleure. Et c'est Lui, le Meilleur des pourvoyeurs"
    },
    {
      "key": 2746,
      "id": "23-73",
      "translation": "Et tu les appelles, certes, vers le droit chemin"
    },
    {
      "key": 2747,
      "id": "23-74",
      "translation": "Or, ceux qui ne croient pas à l'au-delà sont bien écartés de ce chemin"
    },
    {
      "key": 2748,
      "id": "23-75",
      "translation": "Si Nous leur faisions miséricorde et écartions d'eux le mal, ils persisteraient certainement dans leur transgression, confus et hésitants"
    },
    {
      "key": 2749,
      "id": "23-76",
      "translation": "Nous les avons certes saisis du châtiment, mais ils ne se sont pas soumis à leur Seigneur; de même qu'ils ne [Le] supplient point"
    },
    {
      "key": 2750,
      "id": "23-77",
      "translation": "jusqu'au jour où Nous ouvrirons sur eux une porte au dur châtiment, et voilà qu'ils en seront désespérés"
    },
    {
      "key": 2751,
      "id": "23-78",
      "translation": "Et c'est Lui qui a créé pour vous l'ouïe, les yeux et les cœurs. Mais vous êtes rarement reconnaissants"
    },
    {
      "key": 2752,
      "id": "23-79",
      "translation": "C'est Lui qui vous a répandus sur la terre, et c'est vers Lui que vous serez rassemblés"
    },
    {
      "key": 2753,
      "id": "23-80",
      "translation": "Et c'est Lui qui donne la vie et qui donne la mort; et l'alternance de la nuit et du jour dépend de Lui. Ne raisonnerez-vous donc pas"
    },
    {
      "key": 2754,
      "id": "23-81",
      "translation": "Ils ont plutôt tenu les mêmes propos que les anciens"
    },
    {
      "key": 2755,
      "id": "23-82",
      "translation": "Ils ont dit: «lorsque nous serons morts et que nous serons poussière et ossements, serons-nous vraiment ressuscités"
    },
    {
      "key": 2756,
      "id": "23-83",
      "translation": "On nous a promis cela, ainsi qu'à nos ancêtres auparavant; ce ne sont que de vieilles sornettes»"
    },
    {
      "key": 2757,
      "id": "23-84",
      "translation": "Dis: «A qui appartient la terre et ceux qui y sont? si vous savez»"
    },
    {
      "key": 2758,
      "id": "23-85",
      "translation": "Ils diront: «A Allah». Dis: «Ne vous souvenez-vous donc pas?»"
    },
    {
      "key": 2759,
      "id": "23-86",
      "translation": "Dis: «Qui est le Seigneur des sept cieux et le Seigneur du Trône sublime?»"
    },
    {
      "key": 2760,
      "id": "23-87",
      "translation": "Ils diront: [ils appartiennent] «A Allah». Dis: «Ne craignez-vous donc pas?»"
    },
    {
      "key": 2761,
      "id": "23-88",
      "translation": "Dis: «Qui détient dans sa main la royauté absolue de toute chose, et qui protège et n'a pas besoin d'être protégé? [Dites], si vous le savez!»"
    },
    {
      "key": 2762,
      "id": "23-89",
      "translation": "Ils diront: «Allah». Dis: «Comment donc se fait-il que vous soyez ensorcelés?» [au point de ne pas croire en Lui]"
    },
    {
      "key": 2763,
      "id": "23-90",
      "translation": "Nous leur avons plutôt apporté la vérité et ils sont assurément des menteurs"
    },
    {
      "key": 2764,
      "id": "23-91",
      "translation": "Allah ne S'est point attribué d'enfant et il n'existe point de divinité avec Lui; sinon, chaque divinité s'en irait avec ce qu'elle a créé, et certaines seraient supérieures aux autres. (Gloire et pureté) à Allah! Il est Supérieur à tout ce qu'ils décrivent"
    },
    {
      "key": 2765,
      "id": "23-92",
      "translation": "[Il est] Connaisseur de toute chose visible et invisible! Il est bien au-dessus de ce qu'ils [Lui] associent"
    },
    {
      "key": 2766,
      "id": "23-93",
      "translation": "Dis: «Seigneur, si jamais Tu me montres ce qui leur est promis"
    },
    {
      "key": 2767,
      "id": "23-94",
      "translation": "alors, Seigneur, ne me place pas parmi les gens injustes"
    },
    {
      "key": 2768,
      "id": "23-95",
      "translation": "Nous sommes Capable, certes, de te montrer ce que Nous leur promettons"
    },
    {
      "key": 2769,
      "id": "23-96",
      "translation": "Repousse le mal par ce qui est meilleur. Nous savons très bien ce qu'ils décrivent"
    },
    {
      "key": 2770,
      "id": "23-97",
      "translation": "Et dis: «Seigneur, je cherche Ta protection, contre les incitations des diables"
    },
    {
      "key": 2771,
      "id": "23-98",
      "translation": "et je cherche Ta protection, Seigneur, contre leur présence auprès de moi»"
    },
    {
      "key": 2772,
      "id": "23-99",
      "translation": "Puis, lorsque la mort vient à l'un deux, il dit: «Mon Seigneur! Fais-moi revenir (sur terre)"
    },
    {
      "key": 2773,
      "id": "23-100",
      "translation": "afin que je fasse du bien dans ce que je délaissais». Non, c'est simplement une parole qu'il dit. Derrière eux, cependant, il y a une barrière, jusqu'au jour où ils seront ressuscités»"
    },
    {
      "key": 2774,
      "id": "23-101",
      "translation": "Puis quand on soufflera dans la Trompe, il n'y aura plus de parenté entre eux ce jour là, et ils ne se poseront pas de questions"
    },
    {
      "key": 2775,
      "id": "23-102",
      "translation": "Ceux dont la balance est lourde seront les bienheureux"
    },
    {
      "key": 2776,
      "id": "23-103",
      "translation": "et ceux dont la balance est légère seront ceux qui ont ruiné leurs propres âmes et ils demeureront éternellement dans l'Enfer"
    },
    {
      "key": 2777,
      "id": "23-104",
      "translation": "Le feu brûlera leurs visages et ils auront les lèvres crispées"
    },
    {
      "key": 2778,
      "id": "23-105",
      "translation": "«Mes versets ne vous étaient-ils pas récités et vous les traitiez alors de mensonges?»"
    },
    {
      "key": 2779,
      "id": "23-106",
      "translation": "Ils dirent: «Seigneur! Notre malheur nous a vaincus, et nous étions des gens égarés"
    },
    {
      "key": 2780,
      "id": "23-107",
      "translation": "Seigneur, fais nous-en sortir! Et si nous récidivons, nous serons alors des injustes»"
    },
    {
      "key": 2781,
      "id": "23-108",
      "translation": "Il dit: «Soyez-y refoulés (humiliés) et ne Me parlez plus»"
    },
    {
      "key": 2782,
      "id": "23-109",
      "translation": "Il y eut un groupe de Mes serviteurs qui dirent: «Seigneur, nous croyons; pardonne-nous donc et fais-nous miséricorde, car Tu es le meilleur des Miséricordieux»"
    },
    {
      "key": 2783,
      "id": "23-110",
      "translation": "mais vous les avez pris en raillerie jusqu'à oublier de M'invoquer, et vous vous riiez d'eux"
    },
    {
      "key": 2784,
      "id": "23-111",
      "translation": "Vraiment, Je les ai récompensés aujourd'hui pour ce qu'ils ont enduré; et ce sont eux les triomphants"
    },
    {
      "key": 2785,
      "id": "23-112",
      "translation": "Il dira: «Combien d'années êtes-vous restés sur terre?»"
    },
    {
      "key": 2786,
      "id": "23-113",
      "translation": "Ils diront: «Nous y avons demeuré un jour, ou une partie d'un jour. Interroge donc ceux qui comptent.»"
    },
    {
      "key": 2787,
      "id": "23-114",
      "translation": "Il dira: «Vous n'y avez demeuré que peu [de temps], si seulement vous saviez"
    },
    {
      "key": 2788,
      "id": "23-115",
      "translation": "Pensiez-vous que Nous vous avions créés sans but, et que vous ne seriez pas ramenés vers Nous?»"
    },
    {
      "key": 2789,
      "id": "23-116",
      "translation": "Que soit exalté Allah, le vrai Souverain! Pas de divinité en dehors de Lui, le Seigneur du Trône sublime"
    },
    {
      "key": 2790,
      "id": "23-117",
      "translation": "Et quiconque invoque avec Allah une autre divinité, sans avoir la preuve évidente [de son existence], aura à en rendre compte à son Seigneur. En vérité, les mécréants, ne réussiront pas"
    },
    {
      "key": 2791,
      "id": "23-118",
      "translation": "Et dis: «Seigneur, pardonne et fais miséricorde. C'est Toi le Meilleur des miséricordieux»"
    },
    {
      "key": 2792,
      "id": "24-1",
      "translation": "Voici une Sourate que Nous avons fait descendre et que Nous avons imposée, et Nous y avons fait descendre des versets explicites afin que vous vous souveniez»"
    },
    {
      "key": 2793,
      "id": "24-2",
      "translation": "La fornicatrice et le fornicateur, fouettez-les chacun de cent coups de fouet. Et ne soyez point pris de pitié pour eux dans l'exécution de la loi d'Allah - si vous croyez en Allah et au Jour dernier. Et qu'un groupe de croyants assiste à leur punition"
    },
    {
      "key": 2794,
      "id": "24-3",
      "translation": "Le fornicateur n'épousera qu'une fornicatrice ou une associatrice. Et la fornicatrice ne sera épousée que par un fornicateur ou un associateur; et cela a été interdit aux croyants"
    },
    {
      "key": 2795,
      "id": "24-4",
      "translation": "Et ceux qui lancent des accusations contre des femmes chastes sans produire par la suite quatre témoins, fouettez-les de quatre-vingts coups de fouet, et n'acceptez plus jamais leur témoignage. Et ceux-là sont les pervers"
    },
    {
      "key": 2796,
      "id": "24-5",
      "translation": "à l'exception de ceux qui, après cela, se repentent et se réforment, car Allah est Pardonneur et Miséricordieux"
    },
    {
      "key": 2797,
      "id": "24-6",
      "translation": "Et quant à ceux qui lancent des accusations contre leurs propres épouses, sans avoir d'autres témoins qu'eux-mêmes, le témoignage de l'un d'eux doit être une quadruple attestation par Allah qu'il est du nombre des véridiques"
    },
    {
      "key": 2798,
      "id": "24-7",
      "translation": "et la cinquième [attestation] est «que la malédiction d'Allah tombe sur lui s'il est du nombre des menteurs»"
    },
    {
      "key": 2799,
      "id": "24-8",
      "translation": "Et on ne lui infligera pas le châtiment [de la lapidation] si elle atteste quatre fois par Allah qu'il [son mari] est certainement du nombre des menteurs"
    },
    {
      "key": 2800,
      "id": "24-9",
      "translation": "et la cinquième [attestation] est que la colère d'Allah soit sur elle, s'il était du nombre des véridiques"
    },
    {
      "key": 2801,
      "id": "24-10",
      "translation": "Et, n'étaient la grâce d'Allah sur vous et Sa miséricorde...! Allah est Grand Accueillant au repentir et Sage"
    },
    {
      "key": 2802,
      "id": "24-11",
      "translation": "Ceux qui sont venus avec la calomnie sont un groupe d'entre vous. Ne pensez pas que c'est un mal pour vous, mais plutôt, c'est un bien pour vous. A chacun d'eux ce qu'il s'est acquis comme péché. Celui d'entre eux qui s'est chargé de la plus grande part aura un énorme châtiment"
    },
    {
      "key": 2803,
      "id": "24-12",
      "translation": "Pourquoi, lorsque vous l'avez entendue [cette calomnie], les croyants et les croyantes n'ont-ils pas, en eux-mêmes, conjecturé favorablement, et n'ont-ils pas dit: «C'est une calomnie évidente?»"
    },
    {
      "key": 2804,
      "id": "24-13",
      "translation": "Pourquoi n'ont-ils pas produit [à l'appui de leurs accusations] quatre témoins? S'ils ne produisent pas de témoins, alors ce sont eux, auprès d'Allah, les menteurs"
    },
    {
      "key": 2805,
      "id": "24-14",
      "translation": "N'eussent-été la grâce d'Allah sur vous et Sa miséricorde ici-bas comme dans l'au-delà, un énorme châtiment vous aurait touchés pour cette (calomnie) dans laquelle vous vous êtes lancés"
    },
    {
      "key": 2806,
      "id": "24-15",
      "translation": "quand vous colportiez la nouvelle avec vos langues et disiez de vos bouches ce dont vous n'aviez aucun savoir; et vous le comptiez comme insignifiant alors qu'auprès d'Allah cela est énorme"
    },
    {
      "key": 2807,
      "id": "24-16",
      "translation": "Et pourquoi, lorsque vous l'entendiez, ne disiez-vous pas: «Nous ne devons pas en parler. Gloire à Toi (ô Allah)! C'est une énorme calomnie»"
    },
    {
      "key": 2808,
      "id": "24-17",
      "translation": "Allah vous exhorte à ne plus jamais revenir à une chose pareille si vous êtes croyants"
    },
    {
      "key": 2809,
      "id": "24-18",
      "translation": "Allah vous expose clairement les versets et Allah est Omniscient et Sage"
    },
    {
      "key": 2810,
      "id": "24-19",
      "translation": "Ceux qui aiment que la turpitude se propage parmi les croyants auront un châtiment douloureux, ici-bas comme dans l'au-delà. Allah sait, et vous, vous ne savez pas"
    },
    {
      "key": 2811,
      "id": "24-20",
      "translation": "Et n'eussent été la grâce d'Allah sur vous et Sa miséricorde et (n'eût été) qu'Allah est Compatissant et Miséricordieux"
    },
    {
      "key": 2812,
      "id": "24-21",
      "translation": "O vous qui avez cru! Ne suivez pas les pas du Diable. Quiconque suit les pas du Diable, [sachez que] celui-ci ordonne la turpitude et le blâmable. Et n'eussent été la grâce d'Allah envers vous et Sa miséricorde, nul d'entre vous n'aurait jamais été pur. Mais Allah purifie qui Il veut. Et Allah est Audient et Omniscient"
    },
    {
      "key": 2813,
      "id": "24-22",
      "translation": "Et que les détenteurs de richesse et d'aisance parmi vous, ne jurent pas de ne plus faire des dons aux proches, aux pauvres, et à ceux qui émigrent dans le sentier d'Allah. Qu'ils pardonnent et absolvent. N'aimez-vous pas qu'Allah vous pardonne? et Allah est Pardonneur et Miséricordieux"
    },
    {
      "key": 2814,
      "id": "24-23",
      "translation": "Ceux qui lancent des accusations contre des femmes vertueuses, chastes [qui ne pensent même pas à commettre la turpitude] et croyantes sont maudits ici-bas comme dans l'au-delà; et ils auront un énorme châtiment"
    },
    {
      "key": 2815,
      "id": "24-24",
      "translation": "Le jour où leurs langues, leurs mains et leurs pieds témoigneront contre eux de ce qu'ils faisaient"
    },
    {
      "key": 2816,
      "id": "24-25",
      "translation": "Ce Jour-là, Allah leur donnera leur pleine et vraie rétribution; et ils sauront que c'est Allah qui est le Vrai de toute évidence"
    },
    {
      "key": 2817,
      "id": "24-26",
      "translation": "Les mauvaises [femmes] aux mauvais [hommes], et les mauvais [hommes] aux mauvaises [femmes]. De même, les bonnes [femmes] aux bons [hommes], et les bons [hommes] aux bonnes [femmes]. Ceux-là sont innocents de ce que les autres disent. Ils ont un pardon et une récompense généreuse"
    },
    {
      "key": 2818,
      "id": "24-27",
      "translation": "O vous qui croyez! N'entrez pas dans des maisons autres que les vôtres avant de demander la permission [d'une façon délicate] et de saluer leurs habitants. Cela est meilleur pour vous. Peut-être vous souvenez-vous"
    },
    {
      "key": 2819,
      "id": "24-28",
      "translation": "Si vous n'y trouvez personne, alors n'y entrez pas avant que permission vous soit donnée. Et si on vous dit: «Retournez», eh bien, retournez. Cela est plus pur pour vous. Et Allah, de ce que vous faites est Omniscient"
    },
    {
      "key": 2820,
      "id": "24-29",
      "translation": "Nul grief contre vous à entrer dans des maisons inhabitées où se trouve un bien pour vous. Allah sait ce que vous divulguez et ce que vous cachez"
    },
    {
      "key": 2821,
      "id": "24-30",
      "translation": "Dis aux croyants de baisser leurs regards et de garder leur chasteté. C'est plus pur pour eux. Allah est, certes, Parfaitement Connaisseur de ce qu'ils font"
    },
    {
      "key": 2822,
      "id": "24-31",
      "translation": "Et dis aux croyantes de baisser leurs regards, de garder leur chasteté, et de ne montrer de leurs atours que ce qui en paraît et qu'elles rabattent leur voile sur leurs poitrines; et qu'elles ne montrent leurs atours qu'à leurs maris, ou à leurs pères, ou aux pères de leurs maris, ou à leurs fils, ou aux fils de leurs maris, ou à leurs frères, ou aux fils de leurs frères, ou aux fils de leurs sœurs, ou aux femmes musulmanes, ou aux esclaves qu'elles possèdent, ou aux domestiques mâles impuissants, ou aux garçons impubères qui ignorent tout des parties cachées des femmes. Et qu'elles ne frappent pas avec leurs pieds de façon que l'on sache ce qu'elles cachent de leurs parures. Et repentez-vous tous devant Allah, ô croyants, afin que vous récoltiez le succès"
    },
    {
      "key": 2823,
      "id": "24-32",
      "translation": "Mariez les célibataires d'entre vous et les gens de bien parmi vos esclaves, hommes et femmes. S'ils sont besogneux, Allah les rendra riches par Sa grâce. Car (la grâce d') Allah est immense et Il est Omniscient"
    },
    {
      "key": 2824,
      "id": "24-33",
      "translation": "Et que ceux qui n'ont pas de quoi se marier, cherchent à rester chastes jusqu'à ce qu'Allah les enrichisse par Sa grâce. Ceux de vos esclaves qui cherchent un contrat d'affranchissement, concluez ce contrat avec eux si vous reconnaissez du bien en eux; et donnez-leur des biens d'Allah qu'Il vous a accordés. Et dans votre recherche des profits passagers de la vie présente, ne contraignez pas vos femmes esclaves à la prostitution, si elles veulent rester chastes. Si on les y contraint, Allah leur accorde après qu'elles aient été contraintes, Son pardon et Sa miséricorde"
    },
    {
      "key": 2825,
      "id": "24-34",
      "translation": "Nous avons effectivement fait descendre vers vous des versets clairs, donnant une parabole de ceux qui ont vécu avant vous, et une exhortation pour les pieux"
    },
    {
      "key": 2826,
      "id": "24-35",
      "translation": "Allah est la Lumière des cieux et de la terre. Sa lumière est semblable à une niche où se trouve une lampe. La lampe est dans un (récipient de) cristal et celui-ci ressemble à un astre de grand éclat; son combustible vient d'un arbre béni: un olivier ni oriental ni occidental dont l'huile semble éclairer sans même que le feu la touche. Lumière sur lumière. Allah guide vers Sa lumière qui Il veut. Allah propose aux hommes des paraboles et Allah est Omniscient"
    },
    {
      "key": 2827,
      "id": "24-36",
      "translation": "Dans des maisons [des mosquées] qu'Allah a permis que l'on élève, et où Son Nom est invoqué; Le glorifient en elles matin et après-midi"
    },
    {
      "key": 2828,
      "id": "24-37",
      "translation": "des hommes que ni le négoce, ni le troc ne distraient de l'invocation d'Allah, de l'accomplissement de la Salât et de l'acquittement de la Zakât, et qui redoutent un Jour où les cœurs seront bouleversés ainsi que les regards"
    },
    {
      "key": 2829,
      "id": "24-38",
      "translation": "Afin qu'Allah les récompense de la meilleure façon pour ce qu'ils ont fait [de bien]. Et Il leur ajoutera de Sa grâce. Allah attribue à qui Il veut sans compter"
    },
    {
      "key": 2830,
      "id": "24-39",
      "translation": "Quant à ceux qui ont mécru, leurs actions sont comme un mirage dans une plaine désertique que l'assoiffé prend pour de l'eau. Puis quand il y arrive, il s'aperçoit que ce n'était rien; mais y trouve Allah qui lui règle son compte en entier, car Allah est prompt à compter"
    },
    {
      "key": 2831,
      "id": "24-40",
      "translation": "[Les actions des mécréants] sont encore semblables à des ténèbres sur une mer profonde: des vagues la recouvrent, [vagues] au dessus desquelles s'élèvent [d'autres] vagues, sur lesquelles il y a [d'épais] nuages. Ténèbres [entassées] les unes au-dessus des autres. Quand quelqu'un étend la main, il ne la distingue presque pas. Celui qu'Allah prive de lumière n'a aucune lumière"
    },
    {
      "key": 2832,
      "id": "24-41",
      "translation": "N'as-tu pas vu qu'Allah est glorifié par tous ceux qui sont dans les cieux et la terre; ainsi que par les oiseaux déployant leurs ailes? Chacun, certes, a appris sa façon de L'adorer et de Le glorifier. Allah sait parfaitement ce qu'ils font"
    },
    {
      "key": 2833,
      "id": "24-42",
      "translation": "C'est à Allah qu'appartient la royauté des cieux et de la terre. Et vers Allah sera le retour final"
    },
    {
      "key": 2834,
      "id": "24-43",
      "translation": "N'as-tu pas vu qu'Allah pousse les nuages? Ensuite Il les réunit et Il en fait un amas, et tu vois la pluie sortir de son sein. Et Il fait descendre du ciel, de la grêle [provenant] des nuages [comparables] à des montagnes. Il en frappe qui Il veut et l'écarte de qui Il veut. Peu s'en faut que l'éclat de son éclair ne ravisse la vue"
    },
    {
      "key": 2835,
      "id": "24-44",
      "translation": "Allah fait alterner la nuit et le jour. Il y a là un sujet de réflexion pour ceux qui ont des yeux"
    },
    {
      "key": 2836,
      "id": "24-45",
      "translation": "Et Allah a créé d'eau tout animal. Il y en a qui marche sur le ventre, d'autres marchent sur deux pattes, et d'autres encore marchent sur quatre. Allah crée ce qu'Il veut et Allah est Omnipotent"
    },
    {
      "key": 2837,
      "id": "24-46",
      "translation": "Nous avons certes fait descendre des versets explicites. Et Allah guide qui Il veut vers un droit chemin"
    },
    {
      "key": 2838,
      "id": "24-47",
      "translation": "Et ils disent: «Nous croyons en Allah et au messager et nous obéissons». Puis après cela, une partie d'entre eux fait volte-face. Ce ne sont point ceux-là les croyants"
    },
    {
      "key": 2839,
      "id": "24-48",
      "translation": "Et quand on les appelle vers Allah et Son messager pour que celui-ci juge parmi eux, voilà que quelques-uns d'entre eux s'éloignent"
    },
    {
      "key": 2840,
      "id": "24-49",
      "translation": "Mais s'ils ont le droit en leur faveur, ils viennent à lui, soumis"
    },
    {
      "key": 2841,
      "id": "24-50",
      "translation": "Y a-t-il une maladie dans leurs cœurs? ou doutent-ils ? ou craignent-ils qu'Allah les opprime, ainsi que Son messager? Non!... mais ce sont eux les injustes"
    },
    {
      "key": 2842,
      "id": "24-51",
      "translation": "La seule parole des croyants, quand on les appelle vers Allah et Son messager, pour que celui-ci juge parmi eux, est: «Nous avons entendu et nous avons obéi». Et voilà ceux qui réussissent"
    },
    {
      "key": 2843,
      "id": "24-52",
      "translation": "Et quiconque obéit à Allah et à Son messager, et craint Allah et Le redoute... alors, voilà ceux qui récoltent le succès"
    },
    {
      "key": 2844,
      "id": "24-53",
      "translation": "Et ils jurent par Allah en serments solennels que si tu le leur ordonnais, ils sortiraient à coup sûr (au combat). Dis: «Ne jurez donc pas. [Votre] obéissance [verbale] est bien connue. Allah est Parfaitement Connaisseur de ce que vous faites»"
    },
    {
      "key": 2845,
      "id": "24-54",
      "translation": "Dis: «Obéissez à Allah et obéissez au messager. S'ils se détournent, ...il [le messager] n'est alors responsable que de ce dont il est chargé; et vous assumez ce dont vous êtes chargés. Et si vous lui obéissez, vous serez bien guidés». Et il n'incombe au messager que de transmettre explicitement (son message)"
    },
    {
      "key": 2846,
      "id": "24-55",
      "translation": "Allah a promis à ceux d'entre vous qui ont cru et fait les bonnes œuvres qu'Il leur donnerait la succession sur terre comme Il l'a donnée à ceux qui les ont précédés. Il donnerait force et suprématie à leur religion qu'Il a agréée pour eux. Il leur changerait leur ancienne peur en sécurité. Ils M'adorent et ne M'associent rien et celui qui mécroit par la suite, ce sont ceux-là les pervers"
    },
    {
      "key": 2847,
      "id": "24-56",
      "translation": "Accomplissez la Salât, acquittez la Zakât et obéissez au messager, afin que vous ayez la miséricorde"
    },
    {
      "key": 2848,
      "id": "24-57",
      "translation": "Ne pense point que ceux qui ne croient pas puissent s'opposer à l'autorité d'Allah sur terre. Le Feu sera leur refuge. Quelle mauvaise destination"
    },
    {
      "key": 2849,
      "id": "24-58",
      "translation": "O vous qui avez-cru! Que les esclaves que vous possédez vous demandent permission avant d'entrer, ainsi que ceux des vôtres qui n'ont pas encore atteint la puberté, à trois moments: avant la Salât de l'aube, à midi quand vous enlevez vos vêtements, ainsi qu'après la Salât de la nuit; trois occasions de vous dévêtir. En dehors de ces moments, nul reproche ni à vous ni à eux d'aller et venir, les uns chez les autres. C'est ainsi qu'Allah vous expose clairement Ses versets, et Allah est Omniscient et Sage"
    },
    {
      "key": 2850,
      "id": "24-59",
      "translation": "Et quand les enfants parmi vous atteignent la puberté, qu'ils demandent permission avant d'entrer, comme font leurs aînés. C'est ainsi qu'Allah vous expose clairement Ses versets, et Allah est Omniscient et Sage"
    },
    {
      "key": 2851,
      "id": "24-60",
      "translation": "Et quant aux femmes atteintes par la ménopause qui n'espèrent plus le mariage, nul reproche à elles d'enlever leurs vêtements de [sortie], sans cependant exhiber leurs atours et si elle cherchent la chasteté c'est mieux pour elles. Allah est Audient et Omniscient"
    },
    {
      "key": 2852,
      "id": "24-61",
      "translation": "Il n'y a pas d'empêchement à l'aveugle, au boiteux, au malade, ainsi qu'à vous-mêmes de manger dans vos maisons, ou dans les maisons de vos pères, ou dans celles de vos mères, ou de vos frères, ou de vos sœurs, ou de vos oncles paternels, ou de vos tantes paternelles ou de vos oncles maternels, ou de vos tantes maternelles, ou dans celles dont vous possédez les clefs, ou chez vos amis. Nul empêchement à vous, non plus, de manger ensemble, ou séparément. Quand donc vous entrez dans des maisons, adressez-vous mutuellement des salutations venant d'Allah, bénies et agréables. C'est ainsi qu'Allah vous expose Ses versets, afin que vous compreniez"
    },
    {
      "key": 2853,
      "id": "24-62",
      "translation": "Les vrais croyants sont ceux qui croient en Allah et en Son messager, et qui, lorsqu'ils sont en sa compagnie pour une affaire d'intérêt général, ne s'en vont pas avant de lui avoir demandé la permission. Ceux qui te demandent cette permission sont ceux qui croient en Allah et en Son messager. Si donc ils te demandent la permission pour une affaire personnelle, donne-la à qui tu veux d'entre eux; et implore le pardon d'Allah pour eux, car Allah est Pardonneur et Miséricordieux"
    },
    {
      "key": 2854,
      "id": "24-63",
      "translation": "Ne considérez pas l'appel du messager comme un appel que vous vous adresseriez les uns aux autres. Allah connaît certes ceux des vôtres qui s'en vont secrètement en s'entrecachant. Que ceux, donc, qui s'opposent à son commandement prennent garde qu'une épreuve ne les atteigne, ou que ne les atteigne un châtiment douloureux"
    },
    {
      "key": 2855,
      "id": "24-64",
      "translation": "C'est à Allah, vraiment, qu'appartient tout ce qui est dans les cieux et sur la terre. Il sait parfaitement l'état dans lequel vous êtes, et le Jour où les hommes seront ramenés vers Lui, Il les informera alors de ce qu'ils œuvraient. Allah est Omniscient"
    },
    {
      "key": 2856,
      "id": "25-1",
      "translation": "Qu'on exalte la Bénédiction de Celui qui a fait descendre le Livre de Discernement sur Son serviteur, afin qu'il soit un avertisseur à l'univers"
    },
    {
      "key": 2857,
      "id": "25-2",
      "translation": "Celui à qui appartient la royauté des cieux et de la terre, qui ne S'est point attribué d'enfant, qui n'a point d'associé en Sa royauté et qui a créé toute chose en lui donnant ses justes proportions"
    },
    {
      "key": 2858,
      "id": "25-3",
      "translation": "Mais ils ont adopté en dehors de Lui des divinités qui, étant elles-mêmes créées, ne créent rien, et qui ne possèdent la faculté de faire ni le mal ni le bien pour elles-mêmes, et qui ne sont maîtresses ni de la mort, ni de la vie, ni de la résurrection"
    },
    {
      "key": 2859,
      "id": "25-4",
      "translation": "Les mécréants disent: «Tout ceci n'est qu'un mensonge qu'il  a inventé, et où d'autres gens l'ont aidé». Or, ils commettent là une injustice et un mensonge"
    },
    {
      "key": 2860,
      "id": "25-5",
      "translation": "Et ils disent: «Ce sont des contes d'anciens qu'il se fait écrire! On les lui dicte matin et soir!»"
    },
    {
      "key": 2861,
      "id": "25-6",
      "translation": "Dis: «L'a fait descendre Celui qui connaît les secrets dans les cieux et la terre. Et Il est Pardonneur et Miséricordieux"
    },
    {
      "key": 2862,
      "id": "25-7",
      "translation": "Et ils disent: «Qu'est-ce donc que ce Messager qui mange de la nourriture et circule dans les marchés? Que n'a-t-on fait descendre vers lui un Ange qui eût été avertisseur en sa compagnie"
    },
    {
      "key": 2863,
      "id": "25-8",
      "translation": "Ou que ne lui a-t-on lancé un trésor? Ou que n'a-t-il un jardin à lui, dont il pourrait manger (les fruits)?» Les injustes disent: «Vous ne suivez qu'un homme ensorcelé»"
    },
    {
      "key": 2864,
      "id": "25-9",
      "translation": "Vois à quoi ils te comparent! Ils se sont égarés. Ils ne pourront trouver aucun chemin"
    },
    {
      "key": 2865,
      "id": "25-10",
      "translation": "Béni soit Celui qui, s'Il le veut, t'accordera bien mieux que cela: des Jardins sous lesquels coulent les ruisseaux; et Il t'assignera des châteaux"
    },
    {
      "key": 2866,
      "id": "25-11",
      "translation": "Mais ils ont plutôt qualifié l'Heure de mensonge. Nous avons cependant préparé, pour quiconque qualifie l'Heure de mensonge, une Flamme brûlante"
    },
    {
      "key": 2867,
      "id": "25-12",
      "translation": "Lorsque de loin elle les voit, ils entendront sa fureur et ses pétillements"
    },
    {
      "key": 2868,
      "id": "25-13",
      "translation": "Et quand on les y aura jetés, dans un étroit réduit, les mains liées derrière le cou, ils souhaiteront alors leur destruction complète"
    },
    {
      "key": 2869,
      "id": "25-14",
      "translation": "«Aujourd'hui, ne souhaitez pas la destruction une seule fois, mais souhaitez-en plusieurs"
    },
    {
      "key": 2870,
      "id": "25-15",
      "translation": "Dis: «Est-ce mieux ceci? ou bien le Paradis éternel qui a été promis aux pieux, comme récompense et destination dernière"
    },
    {
      "key": 2871,
      "id": "25-16",
      "translation": "Ils auront là tout ce qu'ils désireront et une demeure éternelle. C'est une promesse incombant à ton Seigneur"
    },
    {
      "key": 2872,
      "id": "25-17",
      "translation": "Et le jour où Il les rassemblera, eux et ceux qu'ils adoraient en dehors d'Allah, Il dira: «Est-ce vous qui avez égaré Mes serviteurs que voici, ou ont-ils eux-mêmes perdu le sentier?»"
    },
    {
      "key": 2873,
      "id": "25-18",
      "translation": "Ils diront: «Gloire à Toi! Il ne nous convenait nullement de prendre en dehors de Toi des patrons protecteurs mais Tu les as comblés de jouissance ainsi que leurs ancêtres au point qu'ils en ont oublié le livre du rappel [le Coran]. Et ils ont été des gens perdus»"
    },
    {
      "key": 2874,
      "id": "25-19",
      "translation": "«Ils vous ont démentis en ce que vous dites. Il n'y aura pour vous ni échappatoire ni secours (possible). Et quiconque des vôtres est injuste, Nous lui ferons goûter un grand châtiment»"
    },
    {
      "key": 2875,
      "id": "25-20",
      "translation": "Et Nous n'avons envoyé avant toi que des messagers qui mangeaient de la nourriture et circulaient dans les marchés. Et Nous avons fait de certains d'entre vous une épreuve pour les autres -endurerez-vous avec constance? - Et ton Seigneur demeure Clairvoyant"
    },
    {
      "key": 2876,
      "id": "25-21",
      "translation": "Et ceux qui n'espèrent pas Nous rencontrer disent: «Si seulement on avait fait descendre sur nous des Anges ou si nous pouvions voir notre Seigneur!» En effet, ils se sont enflés d'orgueil en eux-mêmes, et ont dépassé les limites de l'arrogance"
    },
    {
      "key": 2877,
      "id": "25-22",
      "translation": "Le jour où ils verront les Anges, ce ne sera pas une bonne nouvelle, ce jour-là, pour les injustes, ils (les Anges) diront: «Barrage totalement défendu»"
    },
    {
      "key": 2878,
      "id": "25-23",
      "translation": "Nous avons considéré l'œuvre qu'ils ont accomplie et Nous l'avons réduite en poussière éparpillée"
    },
    {
      "key": 2879,
      "id": "25-24",
      "translation": "Les gens du Paradis seront, ce jour-là, en meilleure demeure et au plus beau lieu de repos"
    },
    {
      "key": 2880,
      "id": "25-25",
      "translation": "Et le jour où le ciel sera fendu par les nuages et qu'on fera descendre des Anges"
    },
    {
      "key": 2881,
      "id": "25-26",
      "translation": "ce jour-là, la vraie royauté appartient au Tout Miséricordieux, et ce sera un Jour difficile aux infidèles"
    },
    {
      "key": 2882,
      "id": "25-27",
      "translation": "Le jour où l'injuste se mordra les deux mains et dira: «[Hélas pour moi!] Si seulement j'avais suivi chemin avec le Messager"
    },
    {
      "key": 2883,
      "id": "25-28",
      "translation": "Malheur à moi! Hélas! Si seulement je n'avais pas pris «un tel» pour ami"
    },
    {
      "key": 2884,
      "id": "25-29",
      "translation": "Il m'a, en effet, égaré loin du rappel [le Coran], après qu'il me soit parvenu». Et le Diable déserte l'homme (après l'avoir tenté)"
    },
    {
      "key": 2885,
      "id": "25-30",
      "translation": "Et le Messager dit: «Seigneur, mon peuple a vraiment pris ce Coran pour une chose délaissée!»"
    },
    {
      "key": 2886,
      "id": "25-31",
      "translation": "C'est ainsi que Nous fîmes à chaque prophète un ennemi parmi les criminels. Mais ton Seigneur suffit comme guide et comme soutien"
    },
    {
      "key": 2887,
      "id": "25-32",
      "translation": "Et ceux qui ne croient pas disent: «Pourquoi n'a-t-on pas fait descendre sur lui le Coran en une seule fois?» Nous l'avons révélé ainsi pour raffermir ton cœur. Et Nous l'avons récité soigneusement"
    },
    {
      "key": 2888,
      "id": "25-33",
      "translation": "Ils ne t'apporteront aucune parabole, sans que Nous ne t'apportions la vérité avec la meilleure interprétation"
    },
    {
      "key": 2889,
      "id": "25-34",
      "translation": "Ceux qui seront traînés [ensemble] sur leurs visages vers l'Enfer, ceux-là seront dans la pire des situations et les plus égarés hors du chemin droit"
    },
    {
      "key": 2890,
      "id": "25-35",
      "translation": "En effet, Nous avons apporté à Moïse le Livre et lui avons assigné son frère Aaron comme assistant"
    },
    {
      "key": 2891,
      "id": "25-36",
      "translation": "Puis Nous avons dit: «Allez tous deux vers les gens qui ont traité de mensonge Nos preuves». Nous les avons ensuite détruits complètement"
    },
    {
      "key": 2892,
      "id": "25-37",
      "translation": "Et le peuple de Noé, quand ils eurent démenti les messagers, Nous les noyâmes et en fîmes pour les gens un signe d'avertissement. Et Nous avons préparé pour les injustes un châtiment douloureux"
    },
    {
      "key": 2893,
      "id": "25-38",
      "translation": "Et les 'Aad, les Thamûd, les gens d'Ar-Rass et de nombreuses générations intermédiaires"
    },
    {
      "key": 2894,
      "id": "25-39",
      "translation": "A tous, cependant, Nous avions fait des paraboles et Nous les avions tous anéantis d'une façon brutale"
    },
    {
      "key": 2895,
      "id": "25-40",
      "translation": "Ils sont passés par la cité sur laquelle est tombée une pluie de malheurs. Ne la voient-ils donc pas? Mais ils n'espèrent pas de résurrection"
    },
    {
      "key": 2896,
      "id": "25-41",
      "translation": "Et quand ils te voient, ils ne te prennent qu'en raillerie: «Est-ce là celui qu'Allah a envoyé comme Messager"
    },
    {
      "key": 2897,
      "id": "25-42",
      "translation": "Peu s'en est fallu qu'il ne nous égare de nos divinités, si ce n'était notre attachement patient à elles!». Cependant, ils sauront quand ils verront le châtiment, qui est le plus égaré en son chemin"
    },
    {
      "key": 2898,
      "id": "25-43",
      "translation": "Ne vois-tu pas celui qui a fait de sa passion sa divinité? Est-ce à toi d'être un garant pour lui"
    },
    {
      "key": 2899,
      "id": "25-44",
      "translation": "Ou bien penses-tu que la plupart d'entre eux entendent ou comprennent? Ils ne sont en vérité comparables qu'à des bestiaux. Ou plutôt, ils sont plus égarés encore du sentier"
    },
    {
      "key": 2900,
      "id": "25-45",
      "translation": "N'as-tu pas vu comment ton Seigneur étend l'ombre? S'Il avait voulu, certes, Il l'aurait faite immobile. Puis Nous lui fîmes du soleil son indice"
    },
    {
      "key": 2901,
      "id": "25-46",
      "translation": "puis Nous la saisissons [pour la ramener] vers Nous avec facilité"
    },
    {
      "key": 2902,
      "id": "25-47",
      "translation": "Et c'est Lui qui vous fit de la nuit un vêtement, du sommeil un repos et qui fit du jour un retour à la vie active"
    },
    {
      "key": 2903,
      "id": "25-48",
      "translation": "Et c'est Lui qui envoya les vents comme une annonce précédant Sa miséricorde. Nous fîmes descendre du ciel une eau pure et purifiante"
    },
    {
      "key": 2904,
      "id": "25-49",
      "translation": "pour faire revivre par elle une contrée morte, et donner à boire aux multiples bestiaux et hommes que Nous avons créés"
    },
    {
      "key": 2905,
      "id": "25-50",
      "translation": "Nous l'avions répartie entre eux afin qu'ils se rappellent (de Nous). Mais la plupart des gens se refusent à tout sauf à être ingrats"
    },
    {
      "key": 2906,
      "id": "25-51",
      "translation": "Or, si Nous avions voulu, Nous aurions certes envoyé dans chaque cité un avertisseur"
    },
    {
      "key": 2907,
      "id": "25-52",
      "translation": "N'obéis donc pas aux infidèles; et avec ceci (le Coran), lutte contre eux vigoureusement"
    },
    {
      "key": 2908,
      "id": "25-53",
      "translation": "Et c'est Lui qui donne libre cours aux deux mers: l'une douce, rafraîchissante, l'autre salée, amère. Et Il assigne entre les deux une zone intermédiaire et un barrage infranchissable"
    },
    {
      "key": 2909,
      "id": "25-54",
      "translation": "Et c'est Lui qui de l'eau a créé une espèce humaine qu'Il unit par les liens de la parenté et de l'alliance. Et ton Seigneur demeure Omnipotent"
    },
    {
      "key": 2910,
      "id": "25-55",
      "translation": "Mais ils adorent en dehors d'Allah, ce qui ne leur profite point, ni ne leur nuit! Et l'infidèle sera toujours l'allié des ennemis de son Seigneur"
    },
    {
      "key": 2911,
      "id": "25-56",
      "translation": "Or, Nous ne t'avons envoyé que comme annonciateur et avertisseur"
    },
    {
      "key": 2912,
      "id": "25-57",
      "translation": "Dis: «Je ne vous en demande aucun salaire (pour moi même). Toutefois, celui qui veut suivre un chemin conduisant vers son Seigneur [est libre de dépenser dans la voie d'Allah]»"
    },
    {
      "key": 2913,
      "id": "25-58",
      "translation": "Et place ta confiance en Le Vivant qui ne meurt jamais. Et par Sa louange, glorifie-Le. Il suffit comme Parfait Connaisseur des péchés de Ses serviteurs"
    },
    {
      "key": 2914,
      "id": "25-59",
      "translation": "C'est Lui qui, en six jours, a créé les cieux, la terre et tout ce qui existe entre eux, et le Tout Miséricordieux S'est établi «Istawâ» ensuite sur le Trône. Interroge donc qui est bien informé de Lui"
    },
    {
      "key": 2915,
      "id": "25-60",
      "translation": "Et quand on leur dit: «Prosternez-vous devant le Tout Miséricordieux», ils disent: «Qu'est-ce donc que le Tout Miséricordieux? Allons-nous nous prosterner devant ce que tu nous commandes?» - Et cela accroît leur répulsion"
    },
    {
      "key": 2916,
      "id": "25-61",
      "translation": "Que soit béni Celui qui a placé au ciel des constellations et y a placé un luminaire (le soleil) et aussi une lune éclairante"
    },
    {
      "key": 2917,
      "id": "25-62",
      "translation": "Et c'est Lui qui a assigné une alternance à la nuit et au jour pour quiconque veut y réfléchir ou montrer sa reconnaissance"
    },
    {
      "key": 2918,
      "id": "25-63",
      "translation": "Les serviteurs du Tout Miséricordieux sont ceux qui marchent humblement sur terre, qui, lorsque les ignorants s'adressent à eux, disent: «Paix»"
    },
    {
      "key": 2919,
      "id": "25-64",
      "translation": "qui passent les nuits prosternés et debout devant leur Seigneur"
    },
    {
      "key": 2920,
      "id": "25-65",
      "translation": "qui disent: «Seigneur, écarte de nous le châtiment de l'Enfer». - car son châtiment est permanent"
    },
    {
      "key": 2921,
      "id": "25-66",
      "translation": "Quels mauvais gîte et lieu de séjour"
    },
    {
      "key": 2922,
      "id": "25-67",
      "translation": "Qui, lorsqu'ils dépensent, ne sont ni prodigues ni avares mais se tiennent au juste milieu"
    },
    {
      "key": 2923,
      "id": "25-68",
      "translation": "Qui n'invoquent pas d'autre dieu avec Allah et ne tuent pas la vie qu'Allah a rendue sacrée, sauf à bon droit; qui ne commettent pas de fornication - car quiconque fait cela encourra une punition"
    },
    {
      "key": 2924,
      "id": "25-69",
      "translation": "et le châtiment lui sera doublé, au Jour de la Résurrection, et il y demeurera éternellement couvert d'ignominie"
    },
    {
      "key": 2925,
      "id": "25-70",
      "translation": "sauf celui qui se repent, croit et accomplit une bonne œuvre; ceux-là Allah changera leurs mauvaises actions en bonnes, et Allah est Pardonneur et Miséricordieux"
    },
    {
      "key": 2926,
      "id": "25-71",
      "translation": "et quiconque se repent et accomplit une bonne œuvre c'est vers Allah qu'aboutira son retour"
    },
    {
      "key": 2927,
      "id": "25-72",
      "translation": "Ceux qui ne donnent pas de faux témoignages; et qui, lorsqu'ils passent auprès d'une frivolité, s'en écartent noblement"
    },
    {
      "key": 2928,
      "id": "25-73",
      "translation": "qui lorsque les versets de leur Seigneur leur sont rappelés, ne deviennent ni sourds ni aveugles"
    },
    {
      "key": 2929,
      "id": "25-74",
      "translation": "et qui disent: «Seigneur, donne-nous, en nos épouses et nos descendants, la joie des yeux, et fais de nous un guide pour les pieux»"
    },
    {
      "key": 2930,
      "id": "25-75",
      "translation": "Ceux-là auront pour récompense un lieu élevé [du Paradis] à cause de leur endurance, et ils y seront accueillis avec le salut et la paix"
    },
    {
      "key": 2931,
      "id": "25-76",
      "translation": "pour y demeurer éternellement. Quel beau gîte et lieu de séjour"
    },
    {
      "key": 2932,
      "id": "25-77",
      "translation": "Dis: «Mon Seigneur ne se souciera pas de vous sans votre prière; mais vous avez, démenti (le Prophète). Votre [châtiment] sera inévitable et permanent"
    },
    {
      "key": 2933,
      "id": "26-1",
      "translation": "T'â, Sîn, Mim"
    },
    {
      "key": 2934,
      "id": "26-2",
      "translation": "Voici les versets du Livre explicite"
    },
    {
      "key": 2935,
      "id": "26-3",
      "translation": "Il se peut que tu te consumes de chagrin parce qu'ils ne sont pas croyants"
    },
    {
      "key": 2936,
      "id": "26-4",
      "translation": "Si Nous voulions, Nous ferions descendre du ciel sur eux un prodige devant lequel leurs nuques resteront courbées"
    },
    {
      "key": 2937,
      "id": "26-5",
      "translation": "Aucun nouveau rappel ne leur vient du Tout Miséricordieux sans qu'ils ne l'esquivent"
    },
    {
      "key": 2938,
      "id": "26-6",
      "translation": "Et ils ont traité de mensonge [tout ce qui leur vient du Seigneur]. Il leur viendra bientôt des nouvelles de ce dont ils se raillent"
    },
    {
      "key": 2939,
      "id": "26-7",
      "translation": "N'ont-ils pas observé la terre, combien Nous y avons fait pousser de couples généreux de toutes sortes"
    },
    {
      "key": 2940,
      "id": "26-8",
      "translation": "Voilà bien là une preuve! Et la plupart d'entre eux ne croient pas"
    },
    {
      "key": 2941,
      "id": "26-9",
      "translation": "Et ton Seigneur est en vérité Lui le Tout Puissant, le Très Miséricordieux"
    },
    {
      "key": 2942,
      "id": "26-10",
      "translation": "Et lorsque ton Seigneur appela Moïse: «Rends-toi auprès du peuple injuste"
    },
    {
      "key": 2943,
      "id": "26-11",
      "translation": "[auprès du] peuple de Pharaon» ne craindront-ils pas (Allah)"
    },
    {
      "key": 2944,
      "id": "26-12",
      "translation": "Il dit: «Seigneur, je crains qu'ils ne me traitent de menteur"
    },
    {
      "key": 2945,
      "id": "26-13",
      "translation": "que ma poitrine ne se serre, et que ma langue ne soit embarrassée: Mande donc Aaron"
    },
    {
      "key": 2946,
      "id": "26-14",
      "translation": "Ils ont un crime à me reprocher; je crains donc qu'ils ne me tuent»"
    },
    {
      "key": 2947,
      "id": "26-15",
      "translation": "Mais [Allah lui] dit: «Jamais! Allez tous deux avec Nos prodiges, Nous resterons avec vous et Nous écouterons"
    },
    {
      "key": 2948,
      "id": "26-16",
      "translation": "Rendez-vous donc tous deux auprès de Pharaon, puis dites: «Nous sommes les messagers du Seigneur de l'univers"
    },
    {
      "key": 2949,
      "id": "26-17",
      "translation": "pour que tu renvoies les Enfants d'Israël avec nous»"
    },
    {
      "key": 2950,
      "id": "26-18",
      "translation": "«Ne t'avons-nous pas, dit Pharaon, élevé chez nous tout enfant? Et n'as-tu pas demeuré parmi nous des années de ta vie"
    },
    {
      "key": 2951,
      "id": "26-19",
      "translation": "Puis tu as commis le méfait que tu as fait, en dépit de toute reconnaissance»"
    },
    {
      "key": 2952,
      "id": "26-20",
      "translation": "«Je l'ai fait, dit Moïse, alors que j'étais encore du nombre des égarés"
    },
    {
      "key": 2953,
      "id": "26-21",
      "translation": "Je me suis donc enfui de vous quand j'ai eu peur de vous: puis, mon Seigneur m'a donné la sagesse et m'a désigné parmi Ses messagers"
    },
    {
      "key": 2954,
      "id": "26-22",
      "translation": "Est-ce là un bienfait de ta part [que tu me rappelles] avec reproche, alors que tu as asservi les Enfants d'Israël?»"
    },
    {
      "key": 2955,
      "id": "26-23",
      "translation": "«Et qu'est-ce que le Seigneur de l'univers?» dit Pharaon"
    },
    {
      "key": 2956,
      "id": "26-24",
      "translation": "«Le Seigneur des cieux et de la terre et de ce qui existe entre eux, dit [Moïse], si seulement vous pouviez en être convaincus!»"
    },
    {
      "key": 2957,
      "id": "26-25",
      "translation": "[Pharaon] dit à ceux qui l'entouraient: «N'entendez-vous pas"
    },
    {
      "key": 2958,
      "id": "26-26",
      "translation": "[Moïse] continue: «... Votre Seigneur, et le Seigneur de vos plus anciens ancêtres»"
    },
    {
      "key": 2959,
      "id": "26-27",
      "translation": "«Vraiment, dit [Pharaon], votre messager qui vous a été envoyé, est un fou»"
    },
    {
      "key": 2960,
      "id": "26-28",
      "translation": "[Moïse] ajouta: «... Le Seigneur du Levant et du Couchant et de ce qui est entre les deux; si seulement vous compreniez!»"
    },
    {
      "key": 2961,
      "id": "26-29",
      "translation": "«Si tu adoptes, dit [Pharaon], une autre divinité que moi, je te mettrai parmi les prisonniers»"
    },
    {
      "key": 2962,
      "id": "26-30",
      "translation": "«Et même si je t'apportais, dit [Moïse], une chose (une preuve) évidente"
    },
    {
      "key": 2963,
      "id": "26-31",
      "translation": "«Apporte-la, dit [Pharaon], si tu es du nombre des véridiques»"
    },
    {
      "key": 2964,
      "id": "26-32",
      "translation": "[Moïse] jeta donc son bâton et le voilà devenu un serpent manifeste"
    },
    {
      "key": 2965,
      "id": "26-33",
      "translation": "Et il tira sa main et voilà qu'elle était blanche (étincelante) à ceux qui regardaient"
    },
    {
      "key": 2966,
      "id": "26-34",
      "translation": "[Pharaon] dit aux notables autour de lui: «Voilà en vérité un magicien savant"
    },
    {
      "key": 2967,
      "id": "26-35",
      "translation": "Il veut par sa magie vous expulser de votre terre. Que commandez-vous?»"
    },
    {
      "key": 2968,
      "id": "26-36",
      "translation": "Ils dirent: «Remets-les à plus tard, [lui] et son frère, et envoie des gens dans les villes, pour rassembler"
    },
    {
      "key": 2969,
      "id": "26-37",
      "translation": "et t'amener tout grand magicien savant»"
    },
    {
      "key": 2970,
      "id": "26-38",
      "translation": "Les magiciens furent donc réunis en rendez-vous au jour convenu"
    },
    {
      "key": 2971,
      "id": "26-39",
      "translation": "Et il fut dit aux gens: «Est-ce que vous allez vous réunir"
    },
    {
      "key": 2972,
      "id": "26-40",
      "translation": "afin que nous suivions les magiciens, si ce sont eux les vainqueurs?»"
    },
    {
      "key": 2973,
      "id": "26-41",
      "translation": "Puis, lorsque les magiciens arrivèrent, ils dirent à Pharaon: «Y aura-t-il vraiment une récompense pour nous, si nous sommes les vainqueurs?»"
    },
    {
      "key": 2974,
      "id": "26-42",
      "translation": "Il dit: «Oui, bien sûr, vous serez alors parmi mes proches!»"
    },
    {
      "key": 2975,
      "id": "26-43",
      "translation": "Moïse leur dit: «Jetez ce que vous avez à jeter»"
    },
    {
      "key": 2976,
      "id": "26-44",
      "translation": "Ils jetèrent donc leurs cordes et leurs bâtons et dirent: «Par la puissance de Pharaon!... C'est nous qui serons les vainqueurs»"
    },
    {
      "key": 2977,
      "id": "26-45",
      "translation": "Puis Moïse jeta son bâton, et voilà qu'il happait ce qu'ils avaient fabriqué"
    },
    {
      "key": 2978,
      "id": "26-46",
      "translation": "Alors les magiciens tombèrent prosternés"
    },
    {
      "key": 2979,
      "id": "26-47",
      "translation": "disant: «Nous croyons au Seigneur de l'univers"
    },
    {
      "key": 2980,
      "id": "26-48",
      "translation": "Le Seigneur de Moïse et d'Aaron»"
    },
    {
      "key": 2981,
      "id": "26-49",
      "translation": "[Pharaon] dit: «Avez-vous cru en lui avant que je ne vous le permette? En vérité, c'est lui votre chef, qui vous a enseigné la magie! Eh bien, vous saurez bientôt! Je vous couperai, sûrement, mains et jambes opposées, et vous crucifierai tous»"
    },
    {
      "key": 2982,
      "id": "26-50",
      "translation": "Ils disent: «Il n'y a pas de mal! Car c'est vers notre Seigneur que nous retournerons"
    },
    {
      "key": 2983,
      "id": "26-51",
      "translation": "Nous convoitons que notre Seigneur nous pardonne nos fautes pour avoir été les premiers à croire»"
    },
    {
      "key": 2984,
      "id": "26-52",
      "translation": "Et Nous révélâmes à Moïse [ceci]: «Pars de nuit avec Mes serviteurs, car vous serez poursuivis»"
    },
    {
      "key": 2985,
      "id": "26-53",
      "translation": "Puis, Pharaon envoya des rassembleurs [dire] dans les villes"
    },
    {
      "key": 2986,
      "id": "26-54",
      "translation": "«Ce sont, en fait, une bande peu nombreuse"
    },
    {
      "key": 2987,
      "id": "26-55",
      "translation": "mais ils nous irritent"
    },
    {
      "key": 2988,
      "id": "26-56",
      "translation": "tandis que nous sommes tous vigilants»"
    },
    {
      "key": 2989,
      "id": "26-57",
      "translation": "Ainsi, Nous les fîmes donc sortir des jardins, des sources"
    },
    {
      "key": 2990,
      "id": "26-58",
      "translation": "des trésors et d'un lieu de séjour agréable"
    },
    {
      "key": 2991,
      "id": "26-59",
      "translation": "Il en fut ainsi! Et Nous les donnâmes en héritage aux enfants d'Israël"
    },
    {
      "key": 2992,
      "id": "26-60",
      "translation": "Au lever du soleil, ils les poursuivirent"
    },
    {
      "key": 2993,
      "id": "26-61",
      "translation": "Puis, quand les deux partis se virent, les compagnons de Moïse dirent: «Nous allons être rejoints»"
    },
    {
      "key": 2994,
      "id": "26-62",
      "translation": "Il dit: «Jamais, car j'ai avec moi mon Seigneur qui va me guider»"
    },
    {
      "key": 2995,
      "id": "26-63",
      "translation": "Alors Nous révélâmes à Moïse: «Frappe la mer de ton bâton». Elle se fendit alors, et chaque versant fut comme une énorme montagne"
    },
    {
      "key": 2996,
      "id": "26-64",
      "translation": "Nous fîmes approcher les autres [Pharaon et son peuple]"
    },
    {
      "key": 2997,
      "id": "26-65",
      "translation": "Et Nous sauvâmes Moïse et tous ceux qui étaient avec lui"
    },
    {
      "key": 2998,
      "id": "26-66",
      "translation": "ensuite Nous noyâmes les autres"
    },
    {
      "key": 2999,
      "id": "26-67",
      "translation": "Voilà bien là un prodige, mais la plupart d'entre eux ne croient pas"
    },
    {
      "key": 3000,
      "id": "26-68",
      "translation": "Et ton Seigneur, c'est en vérité Lui le Tout Puissant, le Très Miséricordieux"
    },
    {
      "key": 3001,
      "id": "26-69",
      "translation": "Et récite-leur la nouvelle d'Abraham"
    },
    {
      "key": 3002,
      "id": "26-70",
      "translation": "Quand il dit à son père et à son peuple: «Qu'adorez-vous?»"
    },
    {
      "key": 3003,
      "id": "26-71",
      "translation": "Ils dirent: «Nous adorons des idoles et nous leurs restons attachés»"
    },
    {
      "key": 3004,
      "id": "26-72",
      "translation": "Il dit: «Vous entendent-elles lorsque vous [les] appelez"
    },
    {
      "key": 3005,
      "id": "26-73",
      "translation": "ou vous profitent-elles? ou vous nuisent-elles?»"
    },
    {
      "key": 3006,
      "id": "26-74",
      "translation": "Ils dirent: «Non! mais nous avons trouvé nos ancêtres agissant ainsi»"
    },
    {
      "key": 3007,
      "id": "26-75",
      "translation": "Il dit: «Que dites-vous de ce que vous adoriez"
    },
    {
      "key": 3008,
      "id": "26-76",
      "translation": "Vous et vos vieux ancêtres"
    },
    {
      "key": 3009,
      "id": "26-77",
      "translation": "Ils sont tous pour moi des ennemis sauf le Seigneur de l'univers"
    },
    {
      "key": 3010,
      "id": "26-78",
      "translation": "qui m'a créé, et c'est Lui qui me guide"
    },
    {
      "key": 3011,
      "id": "26-79",
      "translation": "et c'est Lui qui me nourrit et me donne à boire"
    },
    {
      "key": 3012,
      "id": "26-80",
      "translation": "et quand je suis malade, c'est Lui qui me guérit"
    },
    {
      "key": 3013,
      "id": "26-81",
      "translation": "et qui me fera mourir, puis me redonnera la vie"
    },
    {
      "key": 3014,
      "id": "26-82",
      "translation": "et c'est de Lui que je convoite le pardon de mes fautes le Jour de la Rétribution"
    },
    {
      "key": 3015,
      "id": "26-83",
      "translation": "Seigneur, accorde-moi sagesse (et savoir) et fais-moi rejoindre les gens de bien"
    },
    {
      "key": 3016,
      "id": "26-84",
      "translation": "fais que j'aie une mention honorable sur les langues de la postérité"
    },
    {
      "key": 3017,
      "id": "26-85",
      "translation": "et fais de moi l'un des héritiers du Jardin des délices"
    },
    {
      "key": 3018,
      "id": "26-86",
      "translation": "et pardonne à mon père: car il a été du nombre des égarés"
    },
    {
      "key": 3019,
      "id": "26-87",
      "translation": "et ne me couvre pas d'ignominie, le jour où l'on sera ressuscité"
    },
    {
      "key": 3020,
      "id": "26-88",
      "translation": "le jour où ni les biens, ni les enfants ne seront d'aucune utilité"
    },
    {
      "key": 3021,
      "id": "26-89",
      "translation": "sauf celui qui vient à Allah avec un cœur sain»"
    },
    {
      "key": 3022,
      "id": "26-90",
      "translation": "On rapprochera alors le Paradis pour les pieux"
    },
    {
      "key": 3023,
      "id": "26-91",
      "translation": "et l'on exposera aux errants la Fournaise"
    },
    {
      "key": 3024,
      "id": "26-92",
      "translation": "et on leur dira: «Où sont ceux que vous adoriez"
    },
    {
      "key": 3025,
      "id": "26-93",
      "translation": "en dehors d'Allah? vous secourent-ils? ou se secourent-ils eux-mêmes?»"
    },
    {
      "key": 3026,
      "id": "26-94",
      "translation": "Ils y seront donc jetés pêle-mêle, et les errants aussi"
    },
    {
      "key": 3027,
      "id": "26-95",
      "translation": "ainsi que toutes les légions de Iblis"
    },
    {
      "key": 3028,
      "id": "26-96",
      "translation": "Ils diront, tout en s'y querellant"
    },
    {
      "key": 3029,
      "id": "26-97",
      "translation": "«Par Allah! Nous étions certes dans un égarement évident"
    },
    {
      "key": 3030,
      "id": "26-98",
      "translation": "quand nous faisions de vous les égaux du Seigneur de l'univers"
    },
    {
      "key": 3031,
      "id": "26-99",
      "translation": "Ce ne sont que les criminels qui nous ont égarés"
    },
    {
      "key": 3032,
      "id": "26-100",
      "translation": "Et nous n'avons pas d'intercesseurs"
    },
    {
      "key": 3033,
      "id": "26-101",
      "translation": "ni d'ami chaleureux"
    },
    {
      "key": 3034,
      "id": "26-102",
      "translation": "Si un retour nous était possible, alors nous serions parmi les croyants!»"
    },
    {
      "key": 3035,
      "id": "26-103",
      "translation": "Voilà bien là un signe; cependant, la plupart d'entre eux ne croient pas"
    },
    {
      "key": 3036,
      "id": "26-104",
      "translation": "Et ton Seigneur, c'est Lui vraiment le Puissant, le Très Miséricordieux"
    },
    {
      "key": 3037,
      "id": "26-105",
      "translation": "Le peuple de Noé traita de menteurs les Messagers"
    },
    {
      "key": 3038,
      "id": "26-106",
      "translation": "lorsque Noé, leur frère, (contribule) leur dit: «Ne craindrez-vous pas [Allah]"
    },
    {
      "key": 3039,
      "id": "26-107",
      "translation": "Je suis pour vous un messager digne de confiance"
    },
    {
      "key": 3040,
      "id": "26-108",
      "translation": "Craignez Allah donc et obéissez-moi"
    },
    {
      "key": 3041,
      "id": "26-109",
      "translation": "Et je ne vous demande pas de salaire pour cela; mon salaire n'incombe qu'au Seigneur de l'univers"
    },
    {
      "key": 3042,
      "id": "26-110",
      "translation": "Craignez Allah donc, et obéissez-moi»"
    },
    {
      "key": 3043,
      "id": "26-111",
      "translation": "Ils dirent: «Croirons-nous en toi, alors que ce sont les plus vils qui te suivent"
    },
    {
      "key": 3044,
      "id": "26-112",
      "translation": "Il dit: «Je ne sais pas ce que ceux-là faisaient"
    },
    {
      "key": 3045,
      "id": "26-113",
      "translation": "Leur compte n'incombe qu'à mon Seigneur. Si seulement vous êtes conscients"
    },
    {
      "key": 3046,
      "id": "26-114",
      "translation": "Je ne suis pas celui qui repousse les croyants"
    },
    {
      "key": 3047,
      "id": "26-115",
      "translation": "Je ne suis qu'un avertisseur explicite»"
    },
    {
      "key": 3048,
      "id": "26-116",
      "translation": "Ils dirent: «Si tu ne cesses pas, Noé, tu seras certainement du nombre des lapidés!»"
    },
    {
      "key": 3049,
      "id": "26-117",
      "translation": "Il dit: «O mon Seigneur, mon peuple me traite de menteur"
    },
    {
      "key": 3050,
      "id": "26-118",
      "translation": "Tranche donc clairement entre eux et moi; et sauve-moi ainsi que ceux des croyants qui sont avec moi»"
    },
    {
      "key": 3051,
      "id": "26-119",
      "translation": "Nous le sauvâmes donc, de même que ceux qui étaient avec lui dans l'arche, pleinement chargée"
    },
    {
      "key": 3052,
      "id": "26-120",
      "translation": "Et ensuite nous noyâmes le reste (les infidèles)"
    },
    {
      "key": 3053,
      "id": "26-121",
      "translation": "Voilà bien là un signe. Cependant, la plupart d'entre eux ne croient pas"
    },
    {
      "key": 3054,
      "id": "26-122",
      "translation": "Et Ton Seigneur, c'est lui vraiment le Puissant, le Très Miséricordieux"
    },
    {
      "key": 3055,
      "id": "26-123",
      "translation": "Les 'Aad traitèrent de menteurs les Envoyés"
    },
    {
      "key": 3056,
      "id": "26-124",
      "translation": "Et quand Hûd, leur frère (contribule), leur dit: «Ne craindrez-vous pas [Allah]?»"
    },
    {
      "key": 3057,
      "id": "26-125",
      "translation": "Je suis pour vous un messager digne de confiance"
    },
    {
      "key": 3058,
      "id": "26-126",
      "translation": "Craignez Allah donc et obéissez-moi"
    },
    {
      "key": 3059,
      "id": "26-127",
      "translation": "Et je ne vous demande pas de salaire pour cela; mon salaire n'incombe qu'au Seigneur de l'univers"
    },
    {
      "key": 3060,
      "id": "26-128",
      "translation": "Bâtissez-vous par frivolité sur chaque colline un monument"
    },
    {
      "key": 3061,
      "id": "26-129",
      "translation": "Et édifiez-vous des châteaux comme si vous deviez demeurer éternellement"
    },
    {
      "key": 3062,
      "id": "26-130",
      "translation": "Et quand vous sévissez contre quelqu'un, vous le faites impitoyablement"
    },
    {
      "key": 3063,
      "id": "26-131",
      "translation": "Craignez Allah donc et obéissez-moi"
    },
    {
      "key": 3064,
      "id": "26-132",
      "translation": "Craignez Celui qui vous a pourvus de [toutes les bonnes choses] que vous connaissez"
    },
    {
      "key": 3065,
      "id": "26-133",
      "translation": "qui vous a pourvus de bestiaux et d'enfants"
    },
    {
      "key": 3066,
      "id": "26-134",
      "translation": "de jardins et de sources"
    },
    {
      "key": 3067,
      "id": "26-135",
      "translation": "Je crains pour vous le châtiment d'un Jour terrible»"
    },
    {
      "key": 3068,
      "id": "26-136",
      "translation": "Ils dirent: «Que tu nous exhortes ou pas, cela nous est parfaitement égal"
    },
    {
      "key": 3069,
      "id": "26-137",
      "translation": "Ce ne sont là que des mours des anciens"
    },
    {
      "key": 3070,
      "id": "26-138",
      "translation": "Nous ne serons nullement châtiés»"
    },
    {
      "key": 3071,
      "id": "26-139",
      "translation": "Ils le traitèrent donc de menteur. Et nous les fîmes périr. Voilà bien là un signe! Cependant, la plupart d'entre eux ne croient pas"
    },
    {
      "key": 3072,
      "id": "26-140",
      "translation": "Et Ton Seigneur, c'est Lui vraiment le Puissant, le Très Miséricordieux"
    },
    {
      "key": 3073,
      "id": "26-141",
      "translation": "Les Thamûd traitèrent de menteurs les Messagers"
    },
    {
      "key": 3074,
      "id": "26-142",
      "translation": "Quand Sâlih, leur frère (contribule) leur dit: «Ne craindrez-vous pas [Allah]?»"
    },
    {
      "key": 3075,
      "id": "26-143",
      "translation": "Je suis pour vous un messager digne de confiance"
    },
    {
      "key": 3076,
      "id": "26-144",
      "translation": "Craignez Allah donc et obéissez-moi"
    },
    {
      "key": 3077,
      "id": "26-145",
      "translation": "Je ne vous demande pas de salaire pour cela, mon salaire n'incombe qu'au Seigneur de l'univers"
    },
    {
      "key": 3078,
      "id": "26-146",
      "translation": "Vous laissera-t-on en sécurité dans votre présente condition"
    },
    {
      "key": 3079,
      "id": "26-147",
      "translation": "Au milieu de jardins, de sources"
    },
    {
      "key": 3080,
      "id": "26-148",
      "translation": "de cultures et de palmiers aux fruits digestes"
    },
    {
      "key": 3081,
      "id": "26-149",
      "translation": "Creusez-vous habilement des maisons dans les montagnes"
    },
    {
      "key": 3082,
      "id": "26-150",
      "translation": "Craignez Allah donc et obéissez-moi"
    },
    {
      "key": 3083,
      "id": "26-151",
      "translation": "N'obéissez pas à l'ordre des outranciers"
    },
    {
      "key": 3084,
      "id": "26-152",
      "translation": "qui sèment le désordre sur la terre et n'améliorent rien»"
    },
    {
      "key": 3085,
      "id": "26-153",
      "translation": "Ils dirent: «Tu n'es qu'un ensorcelé"
    },
    {
      "key": 3086,
      "id": "26-154",
      "translation": "Tu n'es qu'un homme comme nous. Apporte donc un prodige, si tu es du nombre des véridiques»"
    },
    {
      "key": 3087,
      "id": "26-155",
      "translation": "Il dit: «Voici une chamelle: à elle de boire un jour convenu, et à vous de boire un jour"
    },
    {
      "key": 3088,
      "id": "26-156",
      "translation": "Et ne lui infligez aucun mal, sinon le châtiment d'un jour terrible vous saisira»"
    },
    {
      "key": 3089,
      "id": "26-157",
      "translation": "Mais ils la tuèrent. Eh bien, ils eurent à regretter"
    },
    {
      "key": 3090,
      "id": "26-158",
      "translation": "Le châtiment, en effet, les saisit. Voilà bien là un prodige. Cependant, la plupart d'entre eux ne croient pas"
    },
    {
      "key": 3091,
      "id": "26-159",
      "translation": "Et ton Seigneur, c'est en vérité Lui le Tout Puissant, le Très Miséricordieux"
    },
    {
      "key": 3092,
      "id": "26-160",
      "translation": "Le peuple de Lot traita de menteurs les Messagers"
    },
    {
      "key": 3093,
      "id": "26-161",
      "translation": "quand leur frère Lot leur dit: «Ne craindrez-vous pas [Allah]"
    },
    {
      "key": 3094,
      "id": "26-162",
      "translation": "Je suis pour vous un messager digne de confiance"
    },
    {
      "key": 3095,
      "id": "26-163",
      "translation": "Craignez Allah donc et obéissez-moi"
    },
    {
      "key": 3096,
      "id": "26-164",
      "translation": "Je ne vous demande pas de salaire pour cela; mon salaire n'incombe qu'au Seigneur de l'univers"
    },
    {
      "key": 3097,
      "id": "26-165",
      "translation": "Accomplissez-vous l'acte charnel avec les mâles de ce monde"
    },
    {
      "key": 3098,
      "id": "26-166",
      "translation": "Et délaissez-vous les épouses que votre Seigneur a créées pour vous? Mais vous n'êtes que des gens transgresseurs»"
    },
    {
      "key": 3099,
      "id": "26-167",
      "translation": "Ils dirent: «Si tu ne cesses pas, Lot, tu seras certainement du nombre des expulsés»"
    },
    {
      "key": 3100,
      "id": "26-168",
      "translation": "Il dit: «Je déteste vraiment ce que vous faites"
    },
    {
      "key": 3101,
      "id": "26-169",
      "translation": "Seigneur, sauve-moi ainsi que ma famille de ce qu'ils font»"
    },
    {
      "key": 3102,
      "id": "26-170",
      "translation": "Nous le sauvâmes alors, lui et toute sa famille"
    },
    {
      "key": 3103,
      "id": "26-171",
      "translation": "sauf une vieille qui fut parmi les exterminés"
    },
    {
      "key": 3104,
      "id": "26-172",
      "translation": "Puis Nous détruisîmes les autres"
    },
    {
      "key": 3105,
      "id": "26-173",
      "translation": "et Nous fîmes pleuvoir sur eux une pluie (de pierres). Et quelle pluie fatale pour ceux qui sont avertis"
    },
    {
      "key": 3106,
      "id": "26-174",
      "translation": "Voilà bien là un prodige. Cependant, la plupart d'entre eux ne croient pas"
    },
    {
      "key": 3107,
      "id": "26-175",
      "translation": "Et ton Seigneur, c'est en vérité Lui le Tout Puissant, le Très Miséricordieux"
    },
    {
      "key": 3108,
      "id": "26-176",
      "translation": "Les gens d'Al-Ayka traitèrent de menteurs les Messagers"
    },
    {
      "key": 3109,
      "id": "26-177",
      "translation": "Lorsque Chuayb leur dit: «Ne craindrez-vous pas [Allah]»"
    },
    {
      "key": 3110,
      "id": "26-178",
      "translation": "Je suis pour vous un messager digne de confiance"
    },
    {
      "key": 3111,
      "id": "26-179",
      "translation": "Craignez Allah donc et obéissez-moi"
    },
    {
      "key": 3112,
      "id": "26-180",
      "translation": "et je ne vous demande pas de salaire pour cela; mon salaire n'incombe qu'au Seigneur de l'univers"
    },
    {
      "key": 3113,
      "id": "26-181",
      "translation": "Donnez la pleine mesure et n'en faites rien perdre [aux gens]"
    },
    {
      "key": 3114,
      "id": "26-182",
      "translation": "et pesez avec une balance exacte"
    },
    {
      "key": 3115,
      "id": "26-183",
      "translation": "Ne donnez pas aux gens moins que leur dû; et ne commettez pas de désordre et de corruption sur terre"
    },
    {
      "key": 3116,
      "id": "26-184",
      "translation": "Et craignez Celui qui vous a créés, vous et les anciennes générations»"
    },
    {
      "key": 3117,
      "id": "26-185",
      "translation": "Ils dirent: «Tu es certes du nombre des ensorcelés"
    },
    {
      "key": 3118,
      "id": "26-186",
      "translation": "Tu n'es qu'un homme comme nous; et vraiment nous pensons que tu es du nombre des menteurs"
    },
    {
      "key": 3119,
      "id": "26-187",
      "translation": "Fais donc tomber sur nous des morceaux du ciel si tu es du nombre des véridiques!»"
    },
    {
      "key": 3120,
      "id": "26-188",
      "translation": "Il dit: «Mon Seigneur sait mieux ce que vous faites»"
    },
    {
      "key": 3121,
      "id": "26-189",
      "translation": "Mais ils le traitèrent de menteur. Alors, le châtiment du jour de l'Ombre les saisit. Ce fut le châtiment d'un jour terrible"
    },
    {
      "key": 3122,
      "id": "26-190",
      "translation": "Voilà bien là un prodige. Cependant, la plupart d'entre eux ne croient pas"
    },
    {
      "key": 3123,
      "id": "26-191",
      "translation": "Et ton Seigneur, c'est en vérité Lui le Tout Puissant, le Très Miséricordieux"
    },
    {
      "key": 3124,
      "id": "26-192",
      "translation": "Ce (Coran) ci, c'est le Seigneur de l'univers qui l'a fait descendre"
    },
    {
      "key": 3125,
      "id": "26-193",
      "translation": "et l'Esprit fidèle est descendu avec cela"
    },
    {
      "key": 3126,
      "id": "26-194",
      "translation": "sur ton cœur, pour que tu sois du nombre des avertisseurs"
    },
    {
      "key": 3127,
      "id": "26-195",
      "translation": "en une langue arabe très claire"
    },
    {
      "key": 3128,
      "id": "26-196",
      "translation": "Et ceci était déjà mentionné dans les Ecrits des anciens (envoyés)"
    },
    {
      "key": 3129,
      "id": "26-197",
      "translation": "N'est-ce pas pour eux un signe, que les savants des Enfants d'Israël le sachent"
    },
    {
      "key": 3130,
      "id": "26-198",
      "translation": "Si Nous l'avions fait descendre sur quelqu'un des non-Arabes"
    },
    {
      "key": 3131,
      "id": "26-199",
      "translation": "et que celui-ci le leur eut récité, ils n'y auraient pas cru"
    },
    {
      "key": 3132,
      "id": "26-200",
      "translation": "Ainsi l'avons Nous fait pénétrer [le doute] dans les cœurs des criminels"
    },
    {
      "key": 3133,
      "id": "26-201",
      "translation": "mais ils n'y [le Coran] croiront pas avant de voir le châtiment douloureux"
    },
    {
      "key": 3134,
      "id": "26-202",
      "translation": "qui viendra sur eux soudain, sans qu'ils s'en rendent compte"
    },
    {
      "key": 3135,
      "id": "26-203",
      "translation": "alors ils diront: «Est-ce qu'on va nous donner du répit?»"
    },
    {
      "key": 3136,
      "id": "26-204",
      "translation": "Est-ce qu'ils cherchent à hâter Notre châtiment"
    },
    {
      "key": 3137,
      "id": "26-205",
      "translation": "Vois-tu si Nous leur permettions de jouir, des années durant"
    },
    {
      "key": 3138,
      "id": "26-206",
      "translation": "et qu'ensuite leur arrive ce dont on les menaçait"
    },
    {
      "key": 3139,
      "id": "26-207",
      "translation": "les jouissances qu'on leur a permises ne leur serviraient à rien"
    },
    {
      "key": 3140,
      "id": "26-208",
      "translation": "Et Nous ne faisons pas périr de cité avant qu'elle n'ait eu des avertisseurs"
    },
    {
      "key": 3141,
      "id": "26-209",
      "translation": "[à titre de] rappel, et Nous ne sommes pas injuste"
    },
    {
      "key": 3142,
      "id": "26-210",
      "translation": "Et ce ne sont point les diables qui sont descendus avec ceci (le Coran)"
    },
    {
      "key": 3143,
      "id": "26-211",
      "translation": "cela ne leur convient pas; et ils n'auraient pu le faire"
    },
    {
      "key": 3144,
      "id": "26-212",
      "translation": "Car ils sont écartés de toute écoute (du message divin)"
    },
    {
      "key": 3145,
      "id": "26-213",
      "translation": "N'invoque donc pas une autre divinité avec Allah, sinon tu seras du nombre des châtiés"
    },
    {
      "key": 3146,
      "id": "26-214",
      "translation": "Et avertis les gens qui te sont les plus proches"
    },
    {
      "key": 3147,
      "id": "26-215",
      "translation": "Et abaisse ton aile [sois bienveillant] pour les croyants qui te suivent"
    },
    {
      "key": 3148,
      "id": "26-216",
      "translation": "Mais s'ils te désobéissent, dis-leur: «Moi, je désavoue ce que vous faites»"
    },
    {
      "key": 3149,
      "id": "26-217",
      "translation": "Et place ta confiance dans le Tout Puissant, le Très Miséricordieux"
    },
    {
      "key": 3150,
      "id": "26-218",
      "translation": "qui te voit quand tu te lèves"
    },
    {
      "key": 3151,
      "id": "26-219",
      "translation": "et (voit) tes gestes parmi ceux qui se prosternent"
    },
    {
      "key": 3152,
      "id": "26-220",
      "translation": "C'est Lui vraiment, l'Audient, l'Omniscient"
    },
    {
      "key": 3153,
      "id": "26-221",
      "translation": "Vous apprendrai-Je sur qui les diables descendent"
    },
    {
      "key": 3154,
      "id": "26-222",
      "translation": "Ils descendent sur tout calomniateur, pécheur"
    },
    {
      "key": 3155,
      "id": "26-223",
      "translation": "Ils tendent l'oreille... Cependant, la plupart d'entre eux sont menteurs"
    },
    {
      "key": 3156,
      "id": "26-224",
      "translation": "Et quant aux poètes, ce sont les égarés qui les suivent"
    },
    {
      "key": 3157,
      "id": "26-225",
      "translation": "Ne vois-tu pas qu'ils divaguent dans chaque vallée"
    },
    {
      "key": 3158,
      "id": "26-226",
      "translation": "et qu'ils disent ce qu'ils ne font pas"
    },
    {
      "key": 3159,
      "id": "26-227",
      "translation": "à part ceux qui croient et font de bonnes œuvres, qui invoquent souvent le nom d'Allah et se défendent contre les torts qu'on leur fait. Les injustes verront bientôt le revirement qu'ils [éprouveront]"
    },
    {
      "key": 3160,
      "id": "27-1",
      "translation": "T'â, Sîn. Voici les versets du Coran et d'un Livre explicite"
    },
    {
      "key": 3161,
      "id": "27-2",
      "translation": "un guide et une bonne annonce aux croyants"
    },
    {
      "key": 3162,
      "id": "27-3",
      "translation": "qui accomplissent la Salât, acquittent la Zakât et croient avec certitude en l'au-delà"
    },
    {
      "key": 3163,
      "id": "27-4",
      "translation": "Quant à ceux qui ne croient pas en l'au-delà, Nous embellissons [à leurs yeux] leurs actions, et alors ils deviennent confus et hésitants"
    },
    {
      "key": 3164,
      "id": "27-5",
      "translation": "Ce sont eux qui subiront le pire châtiment, tandis qu'ils seront dans l'au-delà les plus grands perdants"
    },
    {
      "key": 3165,
      "id": "27-6",
      "translation": "Certes, c'est toi qui reçois le Coran, de la part d'un Sage, d'un Savant"
    },
    {
      "key": 3166,
      "id": "27-7",
      "translation": "(Rappelle) quand Moïse dit à sa famille: «J'ai aperçu un feu; je vais vous en apporter des nouvelles, ou bien je vous apporterai un tison allumé afin que vous vous réchauffiez»"
    },
    {
      "key": 3167,
      "id": "27-8",
      "translation": "Lorsqu'il y arriva, on l'appela, - béni soit Celui qui est dans le feu et Celui qui est tout autour, et gloire à Allah, Seigneur de l'univers"
    },
    {
      "key": 3168,
      "id": "27-9",
      "translation": "«O Moïse, c'est Moi, Allah le Tout Puissant, le Sage»"
    },
    {
      "key": 3169,
      "id": "27-10",
      "translation": "Et: «Jette ton bâton». Quand il le vit remuer comme un serpent, il tourna le dos [pour fuir] sans revenir sur ses pas. «N'aie pas peur, Moïse. Les Messagers n'ont point peur auprès de Moi"
    },
    {
      "key": 3170,
      "id": "27-11",
      "translation": "Sauf celui qui a commis une injustice puis a remplacé le mal par le bien... alors Je suis Pardonneur et Miséricordieux»"
    },
    {
      "key": 3171,
      "id": "27-12",
      "translation": "Et introduis ta main dans l'ouverture de ta tunique. Elle sortira blanche et sans aucun mal - un des neuf prodiges à Pharaon et à son peuple, car ils sont vraiment des gens pervers»"
    },
    {
      "key": 3172,
      "id": "27-13",
      "translation": "Et lorsque Nos prodiges leur parvinrent, clairs et explicites, ils dirent: «C'est là une magie évidente!»"
    },
    {
      "key": 3173,
      "id": "27-14",
      "translation": "Ils les nièrent injustement et orgueilleusement, tandis qu'en eux-mêmes ils y croyaient avec certitude. Regarde donc ce qu'il est advenu des corrupteurs"
    },
    {
      "key": 3174,
      "id": "27-15",
      "translation": "Nous avons effectivement donné à David et à Salomon une science; et ils dirent: «Louange à Allah qui nous a favorisés à beaucoup de Ses serviteurs croyants»"
    },
    {
      "key": 3175,
      "id": "27-16",
      "translation": "Et Salomon hérita de David et dit: «O hommes! On nous a appris le langage des oiseaux; et on nous a donné part de toutes choses. C'est là vraiment la grâce évidente"
    },
    {
      "key": 3176,
      "id": "27-17",
      "translation": "Et furent rassemblées pour Salomon, ses armées de djinns, d'hommes et d'oiseaux, et furent placées en rangs"
    },
    {
      "key": 3177,
      "id": "27-18",
      "translation": "Quand ils arrivèrent à la Vallée des Fourmis, une fourmi dit: «O fourmis, entrez dans vos demeures, [de peur] que Salomon et ses armées ne vous écrasent [sous leurs pieds] sans s'en rendre compte»"
    },
    {
      "key": 3178,
      "id": "27-19",
      "translation": "Il sourit, amusé par ses propos et dit: «Permets-moi Seigneur, de rendre grâce pour le bienfait dont Tu m'as comblé ainsi que mes père et mère, et que je fasse une bonne œuvre que tu agrées et fais-moi entrer, par Ta miséricorde, parmi Tes serviteurs vertueux»"
    },
    {
      "key": 3179,
      "id": "27-20",
      "translation": "Puis il passa en revue les oiseaux et dit: «Pourquoi ne vois-je pas la huppe? est-elle parmi les absents"
    },
    {
      "key": 3180,
      "id": "27-21",
      "translation": "Je la châtierai sévèrement! ou je l'égorgerai! ou bien elle m'apportera un argument explicite»"
    },
    {
      "key": 3181,
      "id": "27-22",
      "translation": "Mais elle n'était restée (absente) que peu de temps et dit: «J'ai appris ce que tu n'as point appris; et je te rapporte de Saba' une nouvelle sûre"
    },
    {
      "key": 3182,
      "id": "27-23",
      "translation": "J'ai trouvé qu'une femme est leur reine, que de toute chose elle a été comblée et qu'elle a un trône magnifique"
    },
    {
      "key": 3183,
      "id": "27-24",
      "translation": "Je l'ai trouvée, elle et son peuple, se prosternant devant le soleil au lieu d'Allah. Le Diable leur a embelli leurs actions, et les a détournés du droit chemin, et ils ne sont pas bien guidés"
    },
    {
      "key": 3184,
      "id": "27-25",
      "translation": "Que ne se prosternent-ils devant Allah qui fait sortir ce qui est caché dans les cieux et la terre, et qui sait ce que vous cachez et aussi ce que vous divulguez"
    },
    {
      "key": 3185,
      "id": "27-26",
      "translation": "Allah! Point de divinité à part Lui, le Seigneur du Trône Immense"
    },
    {
      "key": 3186,
      "id": "27-27",
      "translation": "Alors, Salomon dit: «Nous allons voir si tu as dis la vérité ou si tu as menti"
    },
    {
      "key": 3187,
      "id": "27-28",
      "translation": "Pars avec ma lettre que voici; puis lance-la à eux; ensuite tiens-toi à l'écart d'eux pour voir ce que sera leur réponse"
    },
    {
      "key": 3188,
      "id": "27-29",
      "translation": "La reine dit: «O notables! Une noble lettre m'a été lancée"
    },
    {
      "key": 3189,
      "id": "27-30",
      "translation": "Elle vient de Salomon; et c'est: «Au nom d'Allah, le Tout Miséricordieux, le Très Miséricordieux"
    },
    {
      "key": 3190,
      "id": "27-31",
      "translation": "Ne soyez pas hautains avec moi et venez à moi en toute soumission»"
    },
    {
      "key": 3191,
      "id": "27-32",
      "translation": "Elle dit: «O notables! Conseillez-moi sur cette affaire: je ne déciderai rien sans que vous ne soyez présents (pour me conseiller)»"
    },
    {
      "key": 3192,
      "id": "27-33",
      "translation": "Ils dirent: «Nous sommes détenteurs d'une force et d'une puissance redoutable. Le commandement cependant t'appartient. Regarde donc ce que tu veux ordonner»"
    },
    {
      "key": 3193,
      "id": "27-34",
      "translation": "Elle dit: «En vérité, quand les rois entrent dans une cité ils la corrompent, et font de ses honorables citoyens des humiliés. Et c'est ainsi qu'ils agissent"
    },
    {
      "key": 3194,
      "id": "27-35",
      "translation": "Moi, je vais leur envoyer un présent, puis je verrai ce que les envoyés ramèneront»"
    },
    {
      "key": 3195,
      "id": "27-36",
      "translation": "Puis, lorsque [la délégation] arriva auprès de Salomon, celui-ci dit: «Est-ce avec des biens que vous voulez m'aider? alors que ce qu'Allah m'a procuré est meilleur que ce qu'Il vous a procuré. Mais c'est vous plutôt qui vous réjouissez de votre cadeau"
    },
    {
      "key": 3196,
      "id": "27-37",
      "translation": "Retourne vers eux. Nous viendrons avec des armées contre lesquelles ils n'auront aucune résistance, et nous les en expulserons tout humiliés et méprisés"
    },
    {
      "key": 3197,
      "id": "27-38",
      "translation": "Il dit: «O notables! Qui de vous m'apportera son trône avant qu'ils ne viennent à moi soumis?»"
    },
    {
      "key": 3198,
      "id": "27-39",
      "translation": "Un djinn redoutable dit: «Je te l'apporterai avant que tu ne te lèves de ta place: pour cela, je suis fort et digne de confiance»"
    },
    {
      "key": 3199,
      "id": "27-40",
      "translation": "Quelqu'un qui avait une connaissance du Livre dit: «Je te l'apporterai avant que tu n'aies cligné de l'œil». Quand ensuite, Salomon a vu le trône installé auprès de lui, il dit: «Cela est de la grâce de mon Seigneur, pour m'éprouver si je suis reconnaissant ou si je suis ingrat. Quiconque est reconnaissant c'est dans son propre intérêt qu'il le fait, et quiconque est ingrat... alors mon Seigneur Se suffit à Lui-même et Il est Généreux»"
    },
    {
      "key": 3200,
      "id": "27-41",
      "translation": "Et il dit [encore]: «Rendez-lui son trône méconnaissable, nous verrons alors si elle sera guidée ou si elle est du nombre de ceux qui ne sont pas guidés»"
    },
    {
      "key": 3201,
      "id": "27-42",
      "translation": "Quand elle fut venue on lui dit: «Est-ce que ton trône est ainsi?» Elle dit: «C'est comme s'il l'était». - [Salomon dit]: «Le savoir nous a été donné avant elle; et nous étions déjà soumis»"
    },
    {
      "key": 3202,
      "id": "27-43",
      "translation": "Or, ce qu'elle adorait en dehors d'Allah l'empêchait (d'être croyante) car elle faisait partie d'un peuple mécréant"
    },
    {
      "key": 3203,
      "id": "27-44",
      "translation": "On lui dit: «Entre dans le palais». Puis, quand elle le vit, elle le prit pour de l'eau profonde et elle se découvrit les jambes. Alors, [Salomon] lui dit: «Ceci est un palais pavé de cristal». - Elle dit: «Seigneur, je me suis fait du tort à moi-même: Je me soumets avec Salomon à Allah, Seigneur de l'univers»"
    },
    {
      "key": 3204,
      "id": "27-45",
      "translation": "Nous envoyâmes effectivement vers les Thamûd leur frère Sâlih. [qui leur dit]: «Adorez Allah». Et voilà qu'ils se divisèrent en deux groupes qui se disputèrent"
    },
    {
      "key": 3205,
      "id": "27-46",
      "translation": "Il dit: «O mon peuple, pourquoi cherchez-vous à hâter le mal plutôt que le bien? Si seulement vous demandiez pardon à Allah? Peut-être vous serait-il fait miséricorde"
    },
    {
      "key": 3206,
      "id": "27-47",
      "translation": "Ils dirent: «Nous voyons en toi et en ceux qui sont avec toi, des porteurs de malheur». Il dit: «Votre sort dépend d'Allah. Mais vous êtes plutôt des gens qu'on soumet à la tentation"
    },
    {
      "key": 3207,
      "id": "27-48",
      "translation": "Et il y avait dans la ville un groupe de neuf individus qui semaient le désordre sur terre et ne faisaient rien de bon"
    },
    {
      "key": 3208,
      "id": "27-49",
      "translation": "Ils dirent: «Jurons par Allah que nous l'attaquerons de nuit, lui et sa famille. Ensuite nous dirons à celui qui est chargé de le venger: «Nous n'avons pas assisté à l'assassinat de sa famille, et nous sommes sincères»"
    },
    {
      "key": 3209,
      "id": "27-50",
      "translation": "Ils ourdirent une ruse et Nous ourdîmes une ruse sans qu'ils s'en rendent compte"
    },
    {
      "key": 3210,
      "id": "27-51",
      "translation": "Regarde donc ce qu'a été la conséquence de leur stratagème: Nous les fîmes périr, eux et tout leur peuple"
    },
    {
      "key": 3211,
      "id": "27-52",
      "translation": "Voilà donc leurs maisons désertes à cause de leurs méfaits. C'est bien là un avertissement pour des gens qui savent"
    },
    {
      "key": 3212,
      "id": "27-53",
      "translation": "Et Nous sauvâmes ceux qui avaient cru et étaient pieux"
    },
    {
      "key": 3213,
      "id": "27-54",
      "translation": "[Et rappelle-leur] Lot, quand il dit à son peuple: «Vous livrez-vous à la turpitude [l'homosexualité] alors que vous voyez clair»"
    },
    {
      "key": 3214,
      "id": "27-55",
      "translation": "Vous allez aux hommes au lieu de femmes pour assouvir vos désirs? Vous êtes plutôt un peuple ignorant"
    },
    {
      "key": 3215,
      "id": "27-56",
      "translation": "Puis son peuple n'eut que cette réponse: «Expulsez de votre cité la famille de Lot! Car ce sont des gens qui affectent la pureté"
    },
    {
      "key": 3216,
      "id": "27-57",
      "translation": "Nous le sauvâmes ainsi que sa famille, sauf sa femme pour qui Nous avions déterminé qu'elle serait du nombre des exterminés"
    },
    {
      "key": 3217,
      "id": "27-58",
      "translation": "Et Nous fîmes pleuvoir sur eux une pluie (de pierres). Et quelle mauvaise pluie que celle des gens prévenus"
    },
    {
      "key": 3218,
      "id": "27-59",
      "translation": "Dis: «Louange à Allah et paix sur Ses serviteurs qu'Il a élus!» Lequel est meilleur: Allah ou bien ce qu'ils Lui associent"
    },
    {
      "key": 3219,
      "id": "27-60",
      "translation": "N'est-ce pas Lui qui a créé les cieux et la terre et qui vous a fait descendre du ciel une eau avec laquelle Nous avons fait pousser des jardins pleins de beauté. Vous n'étiez nullement capables de faire pousser leurs arbres. Y a-t-il donc une divinité avec Allah? Non, mais ce sont des gens qui Lui donnent des égaux"
    },
    {
      "key": 3220,
      "id": "27-61",
      "translation": "N'est-ce pas Lui qui a établi la terre comme lieu de séjour, placé des rivières à travers elle, lui a assigné des montagnes fermes et établi une séparation entre les deux mers, - Y a-t-il donc une divinité avec Allah? Non, mais la plupart d'entre eux ne savent pas"
    },
    {
      "key": 3221,
      "id": "27-62",
      "translation": "N'est-ce pas Lui qui répond à l'angoissé quand il L'invoque, et qui enlève le mal, et qui vous fait succéder sur la terre, génération après génération, - Y a-t-il donc une divinité avec Allah? C'est rare que vous vous rappeliez"
    },
    {
      "key": 3222,
      "id": "27-63",
      "translation": "N'est-ce pas Lui qui vous guide dans les ténèbres de la terre et de la mer, et qui envoie les vents, comme une bonne annonce précédent Sa grâce. - Y a-t-il donc une divinité avec Allah? Allah est Très Elevé au-dessus de ce qu'ils [Lui] associent"
    },
    {
      "key": 3223,
      "id": "27-64",
      "translation": "N'est-ce pas Lui qui commence la création, puis la refait, et qui vous nourrit du ciel et de la terre. Y a-t-il donc une divinité avec Allah? Dis: «Apportez votre preuve, si vous êtes véridiques!»"
    },
    {
      "key": 3224,
      "id": "27-65",
      "translation": "Dis: «Nul de ceux qui sont dans les cieux et sur la terre ne connaît l'Inconnaissable, à part Allah». Et ils ne savent pas quand ils seront ressuscités"
    },
    {
      "key": 3225,
      "id": "27-66",
      "translation": "Mais leurs sciences se sont rejointes au sujet de l'autre monde. Ils doutent plutôt là-dessus. Ou plutôt ils sont aveugles à son sujet"
    },
    {
      "key": 3226,
      "id": "27-67",
      "translation": "Et ceux qui ne croient pas disent: «Est-ce que, quand nous serons poussière, nous et nos pères, est-ce que vraiment on nous fera sortir (de nos tombes)"
    },
    {
      "key": 3227,
      "id": "27-68",
      "translation": "Certes, on nous l'a promis à nous et à nos pères, auparavant. Ce ne sont que des contes d'anciens!»"
    },
    {
      "key": 3228,
      "id": "27-69",
      "translation": "Dis: «Parcourez la terre et voyez ce qu'il est advenu des criminels»"
    },
    {
      "key": 3229,
      "id": "27-70",
      "translation": "Et ne t'afflige pas sur eux et ne sois pas angoissé à cause de leur complot"
    },
    {
      "key": 3230,
      "id": "27-71",
      "translation": "Et ils disent: «Pour quand cette promesse si vous êtes véridiques?»"
    },
    {
      "key": 3231,
      "id": "27-72",
      "translation": "Dis: «Il se peut qu'une partie de ce que vous cherchez à hâter soit déjà sur vos talons»"
    },
    {
      "key": 3232,
      "id": "27-73",
      "translation": "Certes, ton Seigneur est pourvoyeur de grâce aux hommes, mais la plupart d'entre eux ne sont pas reconnaissants"
    },
    {
      "key": 3233,
      "id": "27-74",
      "translation": "Certes, ton Seigneur sait ce que cachent leurs poitrines et ce qu'ils divulguent"
    },
    {
      "key": 3234,
      "id": "27-75",
      "translation": "Et il n'y a rien de caché, dans le ciel et la terre, qui ne soit dans un Livre explicite"
    },
    {
      "key": 3235,
      "id": "27-76",
      "translation": "Ce Coran raconte aux Enfants d'Israël la plupart des sujets sur lesquels ils divergent"
    },
    {
      "key": 3236,
      "id": "27-77",
      "translation": "cependant qu'il est pour les croyants un guide et une miséricorde"
    },
    {
      "key": 3237,
      "id": "27-78",
      "translation": "Ton Seigneur décidera certes entre eux par Son jugement; et Il est le Tout Puissant, le Sage"
    },
    {
      "key": 3238,
      "id": "27-79",
      "translation": "Place donc ta confiance en Allah, car tu es de toute évidence dans la vérité et le bon droit"
    },
    {
      "key": 3239,
      "id": "27-80",
      "translation": "Tu ne peux faire entendre les morts ni faire entendre l'appel aux sourds quand ils s'enfuient en tournant le dos"
    },
    {
      "key": 3240,
      "id": "27-81",
      "translation": "Et tu ne peux non plus guider les aveugles hors de leur égarement. Tu ne feras entendre que ceux qui croient en Nos versets et se soumettent"
    },
    {
      "key": 3241,
      "id": "27-82",
      "translation": "Et quand la Parole tombera sur eux, Nous leur ferons sortir de terre une bête qui leur parlera; les gens n'étaient nullement convaincus de la vérité de Nos signes [ou versets]"
    },
    {
      "key": 3242,
      "id": "27-83",
      "translation": "Et le jour où Nous rassemblerons, de chaque communauté, une foule de ceux qui démentaient Nos révélations, et qu'ils seront placés en rangs"
    },
    {
      "key": 3243,
      "id": "27-84",
      "translation": "puis, quand ils seront arrivés, [Allah] dira: «Avez-vous traité de mensonges Mes signes sans les avoir embrassés de votre savoir? Ou que faisiez-vous donc?»"
    },
    {
      "key": 3244,
      "id": "27-85",
      "translation": "Et la Parole leur tombera dessus à cause de leurs méfaits. Et ils ne pourront rien dire"
    },
    {
      "key": 3245,
      "id": "27-86",
      "translation": "N'ont-ils pas vu qu'en vérité, Nous avons désigné la nuit pour qu'ils y aient du repos, et le jour pour voir? Voilà bien des preuves pour des gens qui croient"
    },
    {
      "key": 3246,
      "id": "27-87",
      "translation": "Et le jour où l'on soufflera dans la Trompe, tous ceux qui sont dans les cieux et ceux qui sont dans la terre seront effrayés, - sauf ceux qu'Allah a voulu [préserver]! - Et tous viendront à Lui en s'humiliant"
    },
    {
      "key": 3247,
      "id": "27-88",
      "translation": "Et tu verras les montagnes - tu les crois figées - alors qu'elles passent comme des nuages. Telle est l'œuvre d'Allah qui a tout façonné à la perfection. Il est Parfaitement Connaisseur de ce que vous faites"
    },
    {
      "key": 3248,
      "id": "27-89",
      "translation": "Quiconque viendra avec le bien aura bien mieux, et ce jour-là, ils seront à l'abri de tout effroi"
    },
    {
      "key": 3249,
      "id": "27-90",
      "translation": "Et quiconque viendra avec le mal... alors ils seront culbutés le visage dans le Feu. N'êtes-vous pas uniquement rétribués selon ce que vous œuvriez?»"
    },
    {
      "key": 3250,
      "id": "27-91",
      "translation": "«Il m'a été seulement commandé d'adorer le Seigneur de cette Ville (la Mecque) qu'Il a sanctifiée, - et à Lui toute chose - et il m'a été commandé d'être du nombre des Musulmans"
    },
    {
      "key": 3251,
      "id": "27-92",
      "translation": "et de réciter le Coran». Quiconque se guide, c'est pour lui-même en effet qu'il se guide. Et quiconque s'égare..., alors dis: «Je ne suis que l'un des avertisseurs»"
    },
    {
      "key": 3252,
      "id": "27-93",
      "translation": "Dis: «Louange à Allah! Il vous fera voir Ses preuves, et vous les reconnaîtrez». Ton Seigneur n'est pas inattentif à ce que vous faites"
    },
    {
      "key": 3253,
      "id": "28-1",
      "translation": "T'â, Sîn, Mîm"
    },
    {
      "key": 3254,
      "id": "28-2",
      "translation": "Voici les versets du Livre explicite"
    },
    {
      "key": 3255,
      "id": "28-3",
      "translation": "Nous te racontons en toute vérité, de l'histoire de Moïse et de Pharaon, à l'intention des gens qui croient"
    },
    {
      "key": 3256,
      "id": "28-4",
      "translation": "Pharaon était hautain sur terre; il répartit en clans ses habitants, afin d'abuser de la faiblesse de l'un d'eux: Il égorgeait leurs fils et laissait vivantes leurs femmes. Il était vraiment parmi les fauteurs de désordre"
    },
    {
      "key": 3257,
      "id": "28-5",
      "translation": "Mais Nous voulions favoriser ceux qui avaient été faibles sur terre et en faire des dirigeants et en faire les héritiers"
    },
    {
      "key": 3258,
      "id": "28-6",
      "translation": "et les établir puissamment sur terre, et faire voir à Pharaon, à Hâmân, et à leurs soldats, ce dont ils redoutaient"
    },
    {
      "key": 3259,
      "id": "28-7",
      "translation": "Et Nous révélâmes à la mère de Moïse [ceci]: «Allaite-le. Et quand tu craindras pour lui, jette-le dans le flot. Et n'aie pas peur et ne t'attriste pas: Nous te le rendrons et ferons de lui un Messager»"
    },
    {
      "key": 3260,
      "id": "28-8",
      "translation": "Les gens de Pharaon le recueillirent, pour qu'il leur soit un ennemi et une source d'affliction! Pharaon, Hâmân et leurs soldats étaient fautifs"
    },
    {
      "key": 3261,
      "id": "28-9",
      "translation": "Et la femme de Pharaon dit: «(Cet enfant) réjouira mon œil et le tien! Ne le tuez pas. Il pourrait nous être utile ou le prendrons-nous pour enfant». Et ils ne pressentaient rien"
    },
    {
      "key": 3262,
      "id": "28-10",
      "translation": "Et le cœur de la mère de Moïse devint vide. Peu s'en fallut qu'elle ne divulguât tout, si Nous n'avions pas renforcé son cœur pour qu'elle restât du nombre des croyants"
    },
    {
      "key": 3263,
      "id": "28-11",
      "translation": "Elle dit à sa sœur: «Suis-le» elle l'aperçut alors de loin sans qu'ils ne s'en rendent compte"
    },
    {
      "key": 3264,
      "id": "28-12",
      "translation": "Nous lui avions interdit auparavant (le sein) des nourrices. Elle (la sœur de Moïse) dit donc: «Voulez-vous que je vous indique les gens d'une maison qui s'en chargeront pour vous tout en étant bienveillants à son égard?»"
    },
    {
      "key": 3265,
      "id": "28-13",
      "translation": "Ainsi Nous le rendîmes à sa mère, afin que son œil se réjouisse, qu'elle ne s'affligeât pas et qu'elle sût que la promesse d'Allah est vraie. Mais la plupart d'entre eux ne savent pas"
    },
    {
      "key": 3266,
      "id": "28-14",
      "translation": "Et quand il eut atteint sa maturité et sa pleine formation, Nous lui donnâmes la faculté de juger et une science. C'est ainsi que Nous récompensons les bienfaisants"
    },
    {
      "key": 3267,
      "id": "28-15",
      "translation": "Il entra dans la ville à un moment d'inattention de ses habitants; il y trouva deux hommes qui se battaient, l'un était de ses partisans et l'autre de ses adversaires. L'homme de son parti l'appela au secours contre son ennemi. Moïse lui donna un coup de poing qui l'acheva. - [Moïse] dit: «Cela est l'œuvre du Diable. C'est vraiment un ennemi, un égareur évident»"
    },
    {
      "key": 3268,
      "id": "28-16",
      "translation": "Il dit: «Seigneur, je me suis fait du tort à moi-même; pardonne-moi». Et Il lui pardonna. C'est Lui vraiment le Pardonneur, le Miséricordieux"
    },
    {
      "key": 3269,
      "id": "28-17",
      "translation": "Il dit: «Seigneur, grâce aux bienfaits dont tu m'as comblé, jamais je ne soutiendrai les criminels»"
    },
    {
      "key": 3270,
      "id": "28-18",
      "translation": "Le lendemain matin, il se trouva en ville, craintif et regardant autour de lui, quand voilà que celui qui lui avait demandé secours la veille, l'appelait à grands cris. Moïse lui dit: «Tu es certes un provocateur déclaré»"
    },
    {
      "key": 3271,
      "id": "28-19",
      "translation": "Quand il voulut porter un coup à leur ennemi commun, il (l'Israélite) dit: «O Moïse, veux-tu me tuer comme tu as tué un homme hier? Tu ne veux être qu'un tyran sur terre; et tu ne veux pas être parmi les bienfaiteurs»"
    },
    {
      "key": 3272,
      "id": "28-20",
      "translation": "Et c'est alors qu'un homme vint du bout de la ville en courant et dit: «O Moïse, les notables sont en train de se concerter à ton sujet pour te tuer. Quitte (la ville). C'est le conseil que je te donne»"
    },
    {
      "key": 3273,
      "id": "28-21",
      "translation": "Il sortit de là, craintif, regardant autour de lui. Il dit: «Seigneur, sauve-moi de [ce] peuple injuste!»"
    },
    {
      "key": 3274,
      "id": "28-22",
      "translation": "Et lorsqu'il se dirigea vers Madyan, il dit: «Je souhaite que mon Seigneur me guide sur la voie droite»"
    },
    {
      "key": 3275,
      "id": "28-23",
      "translation": "Et quand il fut arrivé au point d'eau de Madyan, il y trouva un attroupement de gens abreuvant [leurs bêtes] et il trouva aussi deux femmes se tenant à l'écart et retenant [leurs bêtes]. Il dit: «Que voulez-vous?» Elles dirent: «Nous n'abreuverons que quand les bergers seront partis; et notre père est fort âgé»"
    },
    {
      "key": 3276,
      "id": "28-24",
      "translation": "Il abreuva [les bêtes] pour elles puis retourna à l'ombre et dit: «Seigneur, j'ai grand besoin du bien que tu feras descendre vers moi»"
    },
    {
      "key": 3277,
      "id": "28-25",
      "translation": "Puis l'une des deux femmes vint à lui, d'une démarche timide, et lui dit: «Mon père t'appelle pour te récompenser pour avoir abreuvé pour nous». Et quand il fut venu auprès de lui et qu'il lui eut raconté son histoire, il (le vieillard) dit: «N'aie aucune crainte: tu as échappé aux gens injustes»"
    },
    {
      "key": 3278,
      "id": "28-26",
      "translation": "L'une d'elles dit: «O mon père, engage-le [à ton service] moyennant salaire, car le meilleur à engager c'est celui qui est fort et digne de confiance»"
    },
    {
      "key": 3279,
      "id": "28-27",
      "translation": "Il dit: «Je voudrais te marier à l'une de mes deux filles que voici, à condition que tu travailles à mon service durant huit ans. Si tu achèves dix [années], ce sera de ton bon gré; je ne veux cependant rien t'imposer d'excessif. Tu me trouveras, si Allah le veut, du nombre des gens de bien»"
    },
    {
      "key": 3280,
      "id": "28-28",
      "translation": "«C'est (conclu) entre toi et moi, dit [Moïse]. Quel que soit celui des deux termes que je m'assigne, il n'y aura nulle pression sur moi. Et Allah est Garant de ce que nous disons»"
    },
    {
      "key": 3281,
      "id": "28-29",
      "translation": "Puis, lorsque Moïse eut accompli la période convenue et qu'il se mit en route avec sa famille, il vit un feu du côté du Mont. Il dit à sa famille: «Demeurez ici. J'ai vu du feu. Peut-être vous en apporterai-je une nouvelle ou un tison de feu afin que vous vous réchauffiez»"
    },
    {
      "key": 3282,
      "id": "28-30",
      "translation": "Puis quand il y arriva, on l'appela, du flanc droit de la vallée, dans la place bénie, à partir de l'arbre: «O Moïse! C'est Moi Allah, le Seigneur de l'univers»"
    },
    {
      "key": 3283,
      "id": "28-31",
      "translation": "Et: «Jette ton bâton» Puis quand il le vit remuer comme si c'était un serpent, il tourna le dos sans même se retourner. «O Moïse! Approche et n'aie pas peur: tu es du nombre de ceux qui sont en sécurité"
    },
    {
      "key": 3284,
      "id": "28-32",
      "translation": "Introduis ta main dans l'ouverture de ta tunique: elle sortira blanche sans aucun mal. Et serre ton bras contre toi pour ne pas avoir peur. Voilà donc deux preuves de ton Seigneur pour Pharaon et ses notables. Ce sont vraiment des gens pervers»"
    },
    {
      "key": 3285,
      "id": "28-33",
      "translation": "«Seigneur, dit [Moïse], j'ai tué un des leurs et je crains qu'ils ne me tuent"
    },
    {
      "key": 3286,
      "id": "28-34",
      "translation": "Mais Aaron, mon frère, est plus éloquent que moi. Envoie-le donc avec moi comme auxiliaire, pour déclarer ma véracité: je crains, vraiment, qu'ils ne me traitent de menteur»"
    },
    {
      "key": 3287,
      "id": "28-35",
      "translation": "[Allah] dit: «Nous allons, par ton frère, fortifier ton bras, et vous donner des arguments irréfutables; ils ne sauront vous atteindre, grâce à Nos signes [Nos miracles]. Vous deux et ceux qui vous suivront seront les vainqueurs"
    },
    {
      "key": 3288,
      "id": "28-36",
      "translation": "Puis, quand Moïse vint à eux avec Nos prodiges évidents, ils dirent: «Ce n'est là que magie inventée. Jamais nous n'avons entendu parler de cela chez nos premiers ancêtres»"
    },
    {
      "key": 3289,
      "id": "28-37",
      "translation": "Et Moïse dit: «Mon Seigneur connaît mieux qui est venu de Sa part avec la guidée, et à qui appartiendra la Demeure finale. Vraiment, les injustes ne réussiront pas»"
    },
    {
      "key": 3290,
      "id": "28-38",
      "translation": "Et Pharaon dit: «O notables, je ne connais pas de divinité pour vous, autre que moi. Hâmân, allume-moi du feu sur l'argile puis construis-moi une tour peut-être alors monterai-je jusqu'au Dieu de Moïse. Je pense plutôt qu'il est du nombre des menteurs»"
    },
    {
      "key": 3291,
      "id": "28-39",
      "translation": "Et il s'enfla d'orgueil sur terre ainsi que ses soldats, sans aucun droit. Et ils pensèrent qu'ils ne seraient pas ramenés vers Nous"
    },
    {
      "key": 3292,
      "id": "28-40",
      "translation": "Nous le saisîmes donc, ainsi que ses soldats, et les jetâmes dans le flot. Regarde donc ce qu'il est advenu des injustes"
    },
    {
      "key": 3293,
      "id": "28-41",
      "translation": "Nous fîmes d'eux des dirigeants qui appellent les gens au Feu. Et au Jour de la Résurrection ils ne seront pas secourus"
    },
    {
      "key": 3294,
      "id": "28-42",
      "translation": "Nous les fîmes suivre, dans cette vie ici-bas, d'une malédiction. Et au Jour de la Résurrection, ils seront parmi les honnis"
    },
    {
      "key": 3295,
      "id": "28-43",
      "translation": "Nous avons en effet, donné le Livre à Moïse, - après avoir fait périr les anciennes générations, - en tant que preuves illuminantes pour les gens, ainsi que guidée et miséricorde afin qu'ils se souviennent"
    },
    {
      "key": 3296,
      "id": "28-44",
      "translation": "Tu n'étais pas sur le versant ouest (du Sinaï), quand Nous avons décrété les commandements à Moïse; tu n'étais pas parmi les témoins"
    },
    {
      "key": 3297,
      "id": "28-45",
      "translation": "Mais Nous avons fait naître des générations dont l'âge s'est prolongé. Et tu n'étais pas [non plus] résident parmi les gens de Madyan leur récitant Nos versets; mais c'est Nous qui envoyons les Messagers"
    },
    {
      "key": 3298,
      "id": "28-46",
      "translation": "Et tu n'étais pas au flanc du Mont Tor quand Nous avons appelé. Mais (tu es venu comme) une miséricorde de ton Seigneur, pour avertir un peuple à qui nul avertisseur avant toi n'est venu, afin qu'ils se souviennent"
    },
    {
      "key": 3299,
      "id": "28-47",
      "translation": "Si un malheur les atteignait en rétribution de ce que leurs propres mains avaient préparé, ils diraient: «Seigneur, pourquoi ne nous as-Tu pas envoyé un Messager? Nous aurions alors suivi Tes versets et nous aurions été croyants»"
    },
    {
      "key": 3300,
      "id": "28-48",
      "translation": "Mais quand la vérité leur est venue de Notre part, ils ont dit: «Si seulement il avait reçu la même chose que Moïse! «Est-ce qu'ils n'ont pas nié ce qui auparavant fut apporté à Moïse? Ils dirent: «Deux magies se sont mutuellement soutenues!» Et ils dirent: «Nous n'avons foi en aucune»"
    },
    {
      "key": 3301,
      "id": "28-49",
      "translation": "Dis-leur: «Apportez donc un Livre venant d'Allah qui soit meilleur guide que ces deux-là, et je le suivrai si vous êtes véridiques»"
    },
    {
      "key": 3302,
      "id": "28-50",
      "translation": "Mais s'ils ne te répondent pas, sache alors que c'est seulement leurs passions qu'ils suivent. Et qui est plus égaré que celui qui suit sa passion sans une guidée d'Allah? Allah vraiment, ne guide pas les gens injustes"
    },
    {
      "key": 3303,
      "id": "28-51",
      "translation": "Nous leur avons déjà exposé la Parole (le Coran) afin qu'ils se souviennent"
    },
    {
      "key": 3304,
      "id": "28-52",
      "translation": "Ceux à qui, avant lui [le Coran], Nous avons apporté le Livre, y croient"
    },
    {
      "key": 3305,
      "id": "28-53",
      "translation": "Et quand on le leur récite, ils disent: «Nous y croyons. Ceci est bien la vérité émanant de notre Seigneur. Déjà avant son arrivée, nous étions Soumis»"
    },
    {
      "key": 3306,
      "id": "28-54",
      "translation": "Voilà ceux qui recevront deux fois leur récompense pour leur endurance, pour avoir répondu au mal par le bien, et pour avoir dépensé de ce que Nous leur avons attribué"
    },
    {
      "key": 3307,
      "id": "28-55",
      "translation": "et quand ils entendent des futilités, ils s'en détournent et disent: «A nous nos actions, et à vous les vôtres. Paix sur vous. Nous ne recherchons pas les ignorants»"
    },
    {
      "key": 3308,
      "id": "28-56",
      "translation": "Tu ne diriges pas celui que tu aimes: mais c'est Allah qui guide qui Il veut. Il connaît mieux cependant les bien-guidés"
    },
    {
      "key": 3309,
      "id": "28-57",
      "translation": "Et ils dirent: «Si nous suivons avec toi la bonne voie, on nous arrachera de notre terre». - Ne les avons-Nous pas établis dans une enceinte sacrée, sûre, vers laquelle des produits de toute sorte sont apportés comme attribution de Notre part? Mais la plupart d'entre eux ne savent pas"
    },
    {
      "key": 3310,
      "id": "28-58",
      "translation": "Et combien avons-Nous fait périr de cités qui étaient ingrates (alors que leurs habitants vivaient dans l'abondance), et voilà qu'après eux leurs demeures ne sont que très peu habitées, et c'est Nous qui en fûmes l'héritier"
    },
    {
      "key": 3311,
      "id": "28-59",
      "translation": "Ton Seigneur ne fait pas périr des cités avant d'avoir envoyé dans leur métropole un Messager pour leur réciter Nos versets. Et Nous ne faisons périr les cités que lorsque leurs habitants sont injustes"
    },
    {
      "key": 3312,
      "id": "28-60",
      "translation": "Tout ce qui vous a été donné est la jouissance éphémère de la vie ici-bas et sa parure, alors que ce qui est auprès d'Allah est meilleur et plus durable... Ne comprenez-vous donc pas"
    },
    {
      "key": 3313,
      "id": "28-61",
      "translation": "Celui à qui Nous avons fait une belle promesse dont il verra l'accomplissement, est-il comparable à celui à qui Nous avons accordé la jouissance de la vie présente et qui sera ensuite le Jour de la Résurrection, de ceux qui comparaîtront (devant Nous)"
    },
    {
      "key": 3314,
      "id": "28-62",
      "translation": "Et le jour où Il les appellera, Il dira: «Où sont ceux que vous prétendiez être Mes associés?»"
    },
    {
      "key": 3315,
      "id": "28-63",
      "translation": "Ceux contre qui la Parole se réalisera diront: «Voici, Seigneur, ceux que nous avons séduits. Nous les avons séduits comme nous nous sommes dévoyés nous-mêmes. Nous les désavouons devant Toi: ce n'est pas nous qu'ils adoraient»"
    },
    {
      "key": 3316,
      "id": "28-64",
      "translation": "Et on [leur] dira: «Appelez vos associés». Ils les appelleront, mais ceux-ci ne leur répondront pas. Quand ils verront le châtiment, ils désireront alors avoir suivi le chemin droit (dans la vie d'ici-bas)"
    },
    {
      "key": 3317,
      "id": "28-65",
      "translation": "Et le jour où Il les appellera et qu'Il dira: «Que répondiez-vous aux Messagers?»"
    },
    {
      "key": 3318,
      "id": "28-66",
      "translation": "Ce jour-là, leurs arguments deviendront obscurs et ils ne se poseront point de questions"
    },
    {
      "key": 3319,
      "id": "28-67",
      "translation": "Mais celui qui se sera repenti, qui aura cru et fait le bien, il se peut qu'il soit parmi ceux qui réussissent"
    },
    {
      "key": 3320,
      "id": "28-68",
      "translation": "Ton Seigneur crée ce qu'Il veut et Il choisit; il ne leur a jamais appartenu de choisir. Gloire à Allah! Il transcende ce qu'ils associent à Lui"
    },
    {
      "key": 3321,
      "id": "28-69",
      "translation": "Ton Seigneur sait ce que cachent leurs poitrines et ce qu'ils divulguent"
    },
    {
      "key": 3322,
      "id": "28-70",
      "translation": "C'est lui Allah. Pas de divinité à part Lui. A Lui la louange ici-bas comme dans l'au-delà. A Lui appartient le jugement. Et vers Lui vous serez ramenés"
    },
    {
      "key": 3323,
      "id": "28-71",
      "translation": "Dis: «Que diriez-vous? Si Allah vous assignait la nuit en permanence jusqu'au Jour de la Résurrection, quelle divinité autre qu'Allah pourrait vous apporter une lumière? N'entendez-vous donc pas?»"
    },
    {
      "key": 3324,
      "id": "28-72",
      "translation": "Dis: «Que diriez-vous? Si Allah vous assignait le jour en permanence jusqu'au Jour de la Résurrection, quelle divinité autre qu'Allah pourrait vous apporter une nuit durant laquelle vous reposeriez? N'observez-vous donc pas?»"
    },
    {
      "key": 3325,
      "id": "28-73",
      "translation": "C'est de par Sa miséricorde qu'Il vous a assigné la nuit et le jour: pour que vous vous y reposiez et cherchiez de Sa grâce, et afin que vous soyez reconnaissants"
    },
    {
      "key": 3326,
      "id": "28-74",
      "translation": "Et le jour où Il les appellera, Il dira: «Où sont ceux que vous prétendiez être Mes associés?»"
    },
    {
      "key": 3327,
      "id": "28-75",
      "translation": "Cependant, Nous ferons sortir de chaque communauté un témoin, puis Nous dirons: «Apportez votre preuve décisive». Ils sauront alors que la Vérité est à Allah; et que ce qu'ils avaient inventé les a abandonnés"
    },
    {
      "key": 3328,
      "id": "28-76",
      "translation": "En vérité, Coré [Karoûn] était du peuple de Moïse mais il était empli de violence envers eux. Nous lui avions donné des trésors dont les clefs pesaient lourd à toute une bande de gens forts. Son peuple lui dit: «Ne te réjouis point. Car Allah n'aime pas les arrogants"
    },
    {
      "key": 3329,
      "id": "28-77",
      "translation": "Et recherche à travers ce qu'Allah t'a donné, la Demeure dernière. Et n'oublie pas ta part en cette vie. Et sois bienfaisant comme Allah a été bienfaisant envers toi. Et ne recherche pas la corruption sur terre. Car Allah n'aime point les corrupteurs»"
    },
    {
      "key": 3330,
      "id": "28-78",
      "translation": "Il dit: «C'est par une science que je possède que ceci m'est venu». Ne savait-il pas qu'avant lui Allah avait fait périr des générations supérieures à lui en force et plus riches en biens? Et les criminels ne seront pas interrogés sur leurs péchés»"
    },
    {
      "key": 3331,
      "id": "28-79",
      "translation": "Il sortit à son peuple dans tout son apparat. Ceux qui aimaient la vie présente dirent: «Si seulement nous avions comme ce qui a été donné à Coré. Il a été doté, certes, d'une immense fortune»"
    },
    {
      "key": 3332,
      "id": "28-80",
      "translation": "Tandis que ceux auxquels le savoir a été donné dirent: «Malheur à vous! La récompense d'Allah est meilleure pour celui qui croit et fait le bien». Mais elle ne sera reçue que par ceux qui endurent"
    },
    {
      "key": 3333,
      "id": "28-81",
      "translation": "Nous fîmes donc que la terre l'engloutît, lui et sa maison. Aucun clan en dehors d'Allah ne fut là pour le secourir, et il ne pût se secourir lui-même"
    },
    {
      "key": 3334,
      "id": "28-82",
      "translation": "Et ceux qui, la veille, souhaitaient d'être à sa place, se mirent à dire: «Ah! Il est vrai qu'Allah augmente la part de qui Il veut, parmi Ses serviteurs, ou la restreint. Si Allah ne nous avait pas favorisés, Il nous aurait certainement fait engloutir. Ah! Il est vrai que ceux qui ne croient pas ne réussissent pas»"
    },
    {
      "key": 3335,
      "id": "28-83",
      "translation": "Cette Demeure dernière, Nous la réservons à ceux qui ne recherchent, ni à s'élever sur terre, ni à y semer la corruption. Cependant, l'heureuse fin appartient aux pieux"
    },
    {
      "key": 3336,
      "id": "28-84",
      "translation": "Quiconque viendra avec le bien, aura meilleur que cela encore; et quiconque viendra avec le mal, (qu'il sache que) ceux qui commettront des méfaits ne seront rétribués que selon ce qu'ils ont commis"
    },
    {
      "key": 3337,
      "id": "28-85",
      "translation": "Celui qui t'a prescrit le Coran te ramènera certainement là où tu (souhaites) retourner. Dis: «Mon Seigneur connaît mieux celui qui a apporté la guidée et celui qui est dans un égarement évident"
    },
    {
      "key": 3338,
      "id": "28-86",
      "translation": "Tu n'espérais nullement que le Livre te serait révélé. Ceci n'a été que par une miséricorde de ton Seigneur. Ne sois donc jamais un soutien pour les infidèles"
    },
    {
      "key": 3339,
      "id": "28-87",
      "translation": "et que ceux-ci ne te détournent point des versets d'Allah une fois qu'on les a fait descendre vers toi. Appelle les gens vers ton Seigneur et ne sois point du nombre des Associateurs"
    },
    {
      "key": 3340,
      "id": "28-88",
      "translation": "Et n'invoque nulle autre divinité avec Allah. Point de divinité à part Lui. Tout doit périr, sauf Son Visage. A Lui appartient le jugement; et vers Lui vous serez ramenés"
    },
    {
      "key": 3341,
      "id": "29-1",
      "translation": "Alif, Lâm, Mîm"
    },
    {
      "key": 3342,
      "id": "29-2",
      "translation": "Est-ce que les gens pensent qu'on les laissera dire: «Nous croyons!» sans les éprouver"
    },
    {
      "key": 3343,
      "id": "29-3",
      "translation": "Certes, Nous avons éprouvé ceux qui ont vécu avant eux; [Ainsi] Allah connaît ceux qui disent la vérité et ceux qui mentent"
    },
    {
      "key": 3344,
      "id": "29-4",
      "translation": "Ou bien ceux qui commettent des méfaits, comptent-ils pouvoir Nous échapper? Comme leur jugement est mauvais"
    },
    {
      "key": 3345,
      "id": "29-5",
      "translation": "Celui qui espère rencontrer Allah, le terme fixé par Allah va certainement venir. Et c'est Lui l'Audient, l'Omniscient"
    },
    {
      "key": 3346,
      "id": "29-6",
      "translation": "Et quiconque lutte, ne lutte que pour lui-même, car Allah peut Se passer de tout l'univers"
    },
    {
      "key": 3347,
      "id": "29-7",
      "translation": "Et quant à ceux qui croient et font de bonnes œuvres, Nous leur effacerons leurs méfaits, et Nous les rétribuerons de la meilleure récompense pour ce qu'ils auront accompli"
    },
    {
      "key": 3348,
      "id": "29-8",
      "translation": "Et Nous avons enjoint à l'homme de bien traiter ses père et mère, et «si ceux-ci te forcent à M'associer, ce dont tu n'as aucun savoir, alors ne leur obéis pas». Vers Moi est votre retour, et alors Je vous informerai de ce que vous faisiez"
    },
    {
      "key": 3349,
      "id": "29-9",
      "translation": "Et quant à ceux qui croient et font de bonnes œuvres, Nous les ferons certainement entrer parmi les gens de bien"
    },
    {
      "key": 3350,
      "id": "29-10",
      "translation": "Parmi les gens il en est qui disent: «Nous croyons en Allah» puis, si on les fait souffrir pour la cause d'Allah, ils considèrent l'épreuve de la part des hommes comme un châtiment d'Allah. Or, s'il vient du secours de ton Seigneur, ils diront certes: «Nous étions avec vous!» Allah n'est-Il pas le meilleur à savoir ce qu'il y a dans les poitrines de tout le monde"
    },
    {
      "key": 3351,
      "id": "29-11",
      "translation": "Allah connaît parfaitement les croyants et connaît parfaitement les hypocrites"
    },
    {
      "key": 3352,
      "id": "29-12",
      "translation": "Et ceux qui ne croient pas disent à ceux qui croient; «Suivez notre sentier, et que nous supportions vos fautes». Mais ils ne supporteront rien de leurs fautes. En vérité ce sont des menteurs"
    },
    {
      "key": 3353,
      "id": "29-13",
      "translation": "Et très certainement, ils porteront leurs fardeaux et d'autres fardeaux en plus de leurs propres fardeaux. et ils seront interrogés, le Jour de la Résurrection, sur ce qu'ils inventaient"
    },
    {
      "key": 3354,
      "id": "29-14",
      "translation": "Et en effet, Nous avons envoyé Noé vers son peuple. Il demeura parmi eux mille ans moins cinquante années. Puis le déluge les emporta alors qu'ils étaient injustes"
    },
    {
      "key": 3355,
      "id": "29-15",
      "translation": "Puis Nous le sauvâmes, lui et les gens de l'arche; et Nous en fîmes un avertissement pour l'univers"
    },
    {
      "key": 3356,
      "id": "29-16",
      "translation": "Et Abraham, quand il dit à son peuple; «Adorez Allah et craignez-Le: cela vous est bien meilleur si vous saviez»"
    },
    {
      "key": 3357,
      "id": "29-17",
      "translation": "Vous n'adorez que des idoles, en dehors d'Allah, et vous forgez un mensonge. Ceux que vous adorez en dehors d'Allah ne possèdent aucun moyen pour vous procurer nourriture; recherchez votre subsistance auprès d'Allah. Adorez-Le et soyez-Lui reconnaissants. C'est à Lui que vous serez ramenés"
    },
    {
      "key": 3358,
      "id": "29-18",
      "translation": "Et si vous criez au mensonge, d'autres nations avant vous, ont aussi traité (leurs prophètes) de menteurs. Au Messager, cependant, n'incombe que la transmission claire"
    },
    {
      "key": 3359,
      "id": "29-19",
      "translation": "Ne voient-ils pas comment Allah commence la création puis la refait? Cela est facile pour Allah"
    },
    {
      "key": 3360,
      "id": "29-20",
      "translation": "Dis: «Parcourez la terre et voyez comment Il a commencé la création. Puis comment Allah crée la génération ultime. Car Allah est Omnipotent»"
    },
    {
      "key": 3361,
      "id": "29-21",
      "translation": "Il châtie qui Il veut et fait miséricorde à qui Il veut; et c'est vers Lui que vous serez ramenés"
    },
    {
      "key": 3362,
      "id": "29-22",
      "translation": "Et vous ne pourrez vous opposer à Sa puissance ni sur terre, ni au ciel; et il n'y a pas pour vous, en dehors d'Allah, ni allié ni secoureur"
    },
    {
      "key": 3363,
      "id": "29-23",
      "translation": "Et ceux qui ne croient pas aux versets d'Allah et à Sa rencontre, désespèrent de Ma miséricorde. Et ceux-là auront un châtiment douloureux"
    },
    {
      "key": 3364,
      "id": "29-24",
      "translation": "Son peuple ne fit d'autre réponse que: «tuez-le ou brûlez-le». Mais Allah le sauva du feu. C'est bien là des signes pour des gens qui croient"
    },
    {
      "key": 3365,
      "id": "29-25",
      "translation": "Et [Abraham] dit: «En effet, c'est pour cimenter des liens entre vous-mêmes dans la vie présente, que vous avez adopté des idoles, en dehors d'Allah. Ensuite, le Jour de la Résurrection, les uns rejetteront les autres, et les uns maudiront les autres, tandis que vous aurez le Feu pour refuge, n'aurez pas de protecteurs"
    },
    {
      "key": 3366,
      "id": "29-26",
      "translation": "Lot crut en lui. Il dit: «Moi, j'émigre vers mon Seigneur, car c'est Lui le Tout Puissant, le Sage»"
    },
    {
      "key": 3367,
      "id": "29-27",
      "translation": "Nous lui donnâmes Isaac et Jacob, et plaçâmes dans sa descendance la prophétie et le Livre. Nous lui accordâmes sa récompense ici-bas, tandis que dans l'au-delà, il sera parmi les gens de bien"
    },
    {
      "key": 3368,
      "id": "29-28",
      "translation": "Et Lot, quand il dit à son peuple: «Vraiment, vous commettez la turpitude où nul dans l'univers ne vous a précédés"
    },
    {
      "key": 3369,
      "id": "29-29",
      "translation": "Aurez-vous commerce charnel avec des mâles? Pratiquerez-vous le brigandage? Commettrez-vous le blâmable dans votre assemblée?» Mais son peuple ne fit d'autre réponse que: «Fais que le châtiment d'Allah nous vienne, si tu es du nombre des véridiques»"
    },
    {
      "key": 3370,
      "id": "29-30",
      "translation": "Il dit: «Seigneur, donne-moi victoire sur ce peuple de corrupteurs!»"
    },
    {
      "key": 3371,
      "id": "29-31",
      "translation": "Et quand Nos Anges apportèrent à Abraham la bonne annonce, ils dirent: «Nous allons anéantir les habitants de cette cité car ses habitants sont injustes»"
    },
    {
      "key": 3372,
      "id": "29-32",
      "translation": "Il dit: «Mais Lot s'y trouve!» Ils dirent: «Nous savons parfaitement qui y habite: nous le sauverons certainement, lui et sa famille, excepté sa femme qui sera parmi ceux qui périront»"
    },
    {
      "key": 3373,
      "id": "29-33",
      "translation": "Et quand Nos Anges vinrent à Lot, il fut affligé pour eux, et se sentit incapable de les protéger. Ils lui dirent: «Ne crains rien et ne t'afflige pas... Nous te sauverons ainsi que ta famille, excepté ta femme qui sera parmi ceux qui périront"
    },
    {
      "key": 3374,
      "id": "29-34",
      "translation": "Nous ferons tomber du ciel un châtiment sur les habitants de cette cité, pour leur perversité»"
    },
    {
      "key": 3375,
      "id": "29-35",
      "translation": "Et certainement, Nous avons laissé (des ruines de cette cité) un signe (d'avertissement) évident pour des gens qui comprennent"
    },
    {
      "key": 3376,
      "id": "29-36",
      "translation": "De même, aux Madyan (Nous envoyâmes) leur frère Chuayb qui leur dit: «O mon peuple, adorez Allah et attendez-vous au Jour dernier, et ne semez pas la corruption sur terre»"
    },
    {
      "key": 3377,
      "id": "29-37",
      "translation": "Mais ils le traitèrent de menteur. Le cataclysme les saisit, et au matin, ils gisaient sans vie dans leurs demeures"
    },
    {
      "key": 3378,
      "id": "29-38",
      "translation": "De même (Nous anéantîmes) les 'Aad et les Thamûd. - Vous le voyez clairement à travers leurs habitations - Le Diable, cependant, leur avait embelli leurs actions, au point de les repousser loin du Sentier; ils étaient pourtant invités à être clairvoyants"
    },
    {
      "key": 3379,
      "id": "29-39",
      "translation": "De même (Nous détruisîmes) Coré, Pharaon et Hâmân. Alors que Moïse leur apporta des preuves, ils s'enorgueillirent sur terre. Et ils n'ont pas pu [Nous] échapper"
    },
    {
      "key": 3380,
      "id": "29-40",
      "translation": "Nous saisîmes donc chacun pour son péché: Il y en eut sur qui Nous envoyâmes un ouragan; il y en eut que le Cri saisit; il y en eut que Nous fîmes engloutir par la terre; et il y en eut que Nous noyâmes. Cependant, Allah n'est pas tel à leur faire du tort; mais ils ont fait du tort à eux-mêmes"
    },
    {
      "key": 3381,
      "id": "29-41",
      "translation": "Ceux qui ont pris des protecteurs en dehors d'Allah ressemblent à l'araignée qui s'est donnée maison. Or la maison la plus fragile est celle de l'araignée. Si seulement ils savaient"
    },
    {
      "key": 3382,
      "id": "29-42",
      "translation": "Allah connaît toute chose qu'ils invoquent en dehors de Lui. Et c'est Lui le Tout Puissant, le Sage"
    },
    {
      "key": 3383,
      "id": "29-43",
      "translation": "Telles sont les paraboles que Nous citons aux gens; cependant, seuls les savants les comprennent"
    },
    {
      "key": 3384,
      "id": "29-44",
      "translation": "C'est pour une juste raison qu'Allah a créé les cieux et la terre. Voilà bien là une preuve pour les croyants"
    },
    {
      "key": 3385,
      "id": "29-45",
      "translation": "Récite ce qui t'est révélé du Livre et accomplis la Salât. En vérité la Salât préserve de la turpitude et du blâmable. Le rappel d'Allah est certes ce qu'il y a de plus grand. Et Allah sait ce que vous faites"
    },
    {
      "key": 3386,
      "id": "29-46",
      "translation": "Et ne discutez que de la meilleure façon avec les gens du Livre, sauf ceux d'entre eux qui sont injustes. Et dites: «Nous croyons en ce qu'on a fait descendre vers nous et descendre vers vous, tandis que notre Dieu et votre Dieu est le même, et c'est à Lui que nous nous soumettons»"
    },
    {
      "key": 3387,
      "id": "29-47",
      "translation": "C'est ainsi que Nous t'avons fait descendre le Livre (le Coran). Ceux à qui Nous avons donné le Livre y croient. Et parmi ceux-ci, il en est qui y croient. Seuls les mécréants renient Nos versets"
    },
    {
      "key": 3388,
      "id": "29-48",
      "translation": "Et avant cela, tu ne récitais aucun livre et tu n'en écrivais aucun de ta main droite. Sinon, ceux qui nient la vérité auraient eu des doutes"
    },
    {
      "key": 3389,
      "id": "29-49",
      "translation": "Il consiste plutôt en des versets évidents, (préservés) dans les poitrines de ceux à qui le savoir a été donné. Et seuls les injustes renient Nos versets"
    },
    {
      "key": 3390,
      "id": "29-50",
      "translation": "Et ils dirent: «Pourquoi n'a-t-on pas fait descendre sur lui des prodiges de la part de son Seigneur?» Dis: «Les prodiges sont auprès d'Allah. Moi, je ne suis qu'un avertisseur bien clair»"
    },
    {
      "key": 3391,
      "id": "29-51",
      "translation": "Ne leur suffit-il donc point que Nous ayons fait descendre sur toi le Livre et qu'il leur soit récité? Il y a assurément là une miséricorde et un rappel pour des gens qui croient"
    },
    {
      "key": 3392,
      "id": "29-52",
      "translation": "Dis: «Allah suffit comme témoin entre moi et vous». Il sait ce qui est dans les cieux et la terre. Et quant à ceux qui croient au faux et ne croient pas en Allah, ceux-là seront les perdants"
    },
    {
      "key": 3393,
      "id": "29-53",
      "translation": "Et ils te demandent de hâter [la venue] du châtiment. S'il n'y avait pas eu un terme fixé, le châtiment leur serait certes venu. Et assurément, il leur viendra soudain, sans qu'ils en aient conscience"
    },
    {
      "key": 3394,
      "id": "29-54",
      "translation": "Ils te demandent de hâter [la venue] du châtiment, tandis que l'Enfer cerne les mécréants de toutes parts"
    },
    {
      "key": 3395,
      "id": "29-55",
      "translation": "Le jour où le châtiment les enveloppera d'en haut et sous leurs pieds. Il [leur] dira: «Goûtez à ce que vous faisiez!»"
    },
    {
      "key": 3396,
      "id": "29-56",
      "translation": "O Mes serviteurs qui avaient cru! Ma terre est bien vaste. Adorez-Moi donc"
    },
    {
      "key": 3397,
      "id": "29-57",
      "translation": "Toute âme goûtera la mort. Ensuite c'est vers Nous que vous serez ramenés"
    },
    {
      "key": 3398,
      "id": "29-58",
      "translation": "Et quant à ceux qui croient et accomplissent de bonnes œuvres, Nous les installerons certes à l'étage dans le Paradis sous lequel coulent les ruisseaux, pour y demeurer éternellement. Quelle belle récompense que celle de ceux qui font le bien"
    },
    {
      "key": 3399,
      "id": "29-59",
      "translation": "qui endurent, et placent leur confiance en leur Seigneur"
    },
    {
      "key": 3400,
      "id": "29-60",
      "translation": "Que de bêtes ne se chargent point de leur propre nourriture! C'est Allah qui les nourrit ainsi que vous. Et c'est Lui l'Audient, l'Omniscient"
    },
    {
      "key": 3401,
      "id": "29-61",
      "translation": "Si tu leur demandes: «Qui a créé les cieux et la terre, et assujetti le soleil et la lune?», ils diront très certainement: «Allah». Comment se fait-il qu'ensuite ils se détournent (du chemin droit)"
    },
    {
      "key": 3402,
      "id": "29-62",
      "translation": "Allah dispense largement ou restreint Ses dons à qui Il veut parmi Ses serviteurs. Certes, Allah est Omniscient"
    },
    {
      "key": 3403,
      "id": "29-63",
      "translation": "Si tu leur demandes: «Qui a fait descendre du ciel une eau avec laquelle Il fit revivre la terre après sa mort?», ils diront très certainement: «Allah». Dis: «Louange à Allah!» Mais la plupart d'entre eux ne raisonnent pas"
    },
    {
      "key": 3404,
      "id": "29-64",
      "translation": "Cette vie d'ici-bas n'est qu'amusement et jeu. La Demeure de l'au-delà est assurément la vraie vie. S'ils savaient"
    },
    {
      "key": 3405,
      "id": "29-65",
      "translation": "Quand ils montent en bateau, ils invoquent Allah Lui vouant exclusivement leur culte. Une fois qu'Il les a sauvés [des dangers de la mer en les ramenant] sur la terre ferme, voilà qu'ils [Lui] donnent des associés"
    },
    {
      "key": 3406,
      "id": "29-66",
      "translation": "Qu'ils nient ce que nous leur avons donné et jouissent des biens de ce monde! Ils sauront bientôt"
    },
    {
      "key": 3407,
      "id": "29-67",
      "translation": "Ne voient-ils pas que vraiment Nous avons fait un sanctuaire sûr [la Mecque], alors que tout autour d'eux on enlève les gens? Croiront-ils donc au faux et nieront-ils les bienfaits d'Allah"
    },
    {
      "key": 3408,
      "id": "29-68",
      "translation": "Et quel pire injuste que celui qui invente un mensonge contre Allah, ou qui dément la Vérité quand elle lui parvient? N'est-ce pas dans l'Enfer une demeure pour les mécréants"
    },
    {
      "key": 3409,
      "id": "29-69",
      "translation": "Et quant à ceux qui luttent pour Notre cause, Nous les guiderons certes sur Nos sentiers. Allah est en vérité avec les bienfaisants"
    },
    {
      "key": 3410,
      "id": "30-1",
      "translation": "Alif, Lâm, Mîm"
    },
    {
      "key": 3411,
      "id": "30-2",
      "translation": "Les Romains ont été vaincus"
    },
    {
      "key": 3412,
      "id": "30-3",
      "translation": "dans le pays voisin, et après leur défaite ils seront les vainqueurs"
    },
    {
      "key": 3413,
      "id": "30-4",
      "translation": "dans quelques années. A Allah appartient le commandement, au début et à la fin, et ce jour-là les Croyants se réjouiront"
    },
    {
      "key": 3414,
      "id": "30-5",
      "translation": "du secours d'Allah. Il secourt qui Il veut et Il est le Tout Puissant, le Tout Miséricordieux"
    },
    {
      "key": 3415,
      "id": "30-6",
      "translation": "C'est [là] la promesse d'Allah. Allah ne manque jamais à Sa promesse mais la plupart des gens ne savent pas"
    },
    {
      "key": 3416,
      "id": "30-7",
      "translation": "Ils connaissent un aspect de la vie présente, tandis qu'ils sont inattentifs à l'au-delà"
    },
    {
      "key": 3417,
      "id": "30-8",
      "translation": "N'ont-ils pas médité en eux-mêmes? Allah n'a créé les cieux et la terre et ce qui est entre eux, qu'à juste raison et pour un terme fixé. Beaucoup de gens cependant ne croient pas en la rencontre de leur Seigneur"
    },
    {
      "key": 3418,
      "id": "30-9",
      "translation": "N'ont-ils pas parcouru la terre pour voir ce qu'il est advenu de ceux qui ont vécu avant eux? Ceux-là les surpassaient en puissance et avaient labouré et peuplé la terre bien plus qu'ils ne l'ont fait eux-mêmes. Leurs messagers leur vinrent avec des preuves évidentes. Ce n'est pas Allah qui leur fit du tort; mais ils se firent du tort à eux-mêmes"
    },
    {
      "key": 3419,
      "id": "30-10",
      "translation": "Puis, mauvaise fut la fin de ceux qui faisaient le mal, ayant traité de mensonges les versets d'Allah et les ayant raillés"
    },
    {
      "key": 3420,
      "id": "30-11",
      "translation": "C'est Allah qui commence la création; ensuite Il la refait; puis, vers Lui vous serez ramenés"
    },
    {
      "key": 3421,
      "id": "30-12",
      "translation": "Et le jour où l'Heure arrivera, les criminels seront frappés de désespoir"
    },
    {
      "key": 3422,
      "id": "30-13",
      "translation": "Et ils n'auront point d'intercesseurs parmi ceux qu'ils associaient [à Allah] et ils renieront même leurs divinités"
    },
    {
      "key": 3423,
      "id": "30-14",
      "translation": "Le jour où l'Heure arrivera, ce jour-là ils se sépareront [les uns des autres]"
    },
    {
      "key": 3424,
      "id": "30-15",
      "translation": "Ceux qui auront cru et accompli de bonnes œuvres se réjouiront dans un jardin"
    },
    {
      "key": 3425,
      "id": "30-16",
      "translation": "et quant à ceux qui n'auront pas cru et auront traité de mensonges Nos signes ainsi que la rencontre de l'au-delà, ceux-là seront emmenés au châtiment"
    },
    {
      "key": 3426,
      "id": "30-17",
      "translation": "Glorifiez Allah donc, soir et matin"
    },
    {
      "key": 3427,
      "id": "30-18",
      "translation": "A Lui toute louange dans les cieux et la terre, dans l'après-midi et au milieu de la journée"
    },
    {
      "key": 3428,
      "id": "30-19",
      "translation": "Du mort, Il fait sortir le vivant, et du vivant, Il fait sortir le mort. Et Il redonne la vie à la terre après sa mort. Et c'est ainsi que l'on vous fera sortir (à la résurrection)"
    },
    {
      "key": 3429,
      "id": "30-20",
      "translation": "Parmi Ses signes: Il vous a créés de terre, - puis, vous voilà des hommes qui se dispersent [dans le monde]"
    },
    {
      "key": 3430,
      "id": "30-21",
      "translation": "Et parmi Ses signes Il a créé de vous, pour vous, des épouses pour que vous viviez en tranquillité avec elles et Il a mis entre vous de l'affection et de la bonté. Il y a en cela des preuves pour des gens qui réfléchissent"
    },
    {
      "key": 3431,
      "id": "30-22",
      "translation": "Et parmi Ses signes la création des cieux et de la terre et la variété de vos idiomes et de vos couleurs. Il y a en cela des preuves pour les savants"
    },
    {
      "key": 3432,
      "id": "30-23",
      "translation": "Et parmi Ses signes votre sommeil la nuit et le jour, et aussi votre quête de Sa grâce. Il y a en cela des preuves pour des gens qui entendent"
    },
    {
      "key": 3433,
      "id": "30-24",
      "translation": "Et parmi Ses signes Il vous montre l'éclair avec crainte (de la foudre) et espoir (de la pluie), et fait descendre du ciel une eau avec laquelle Il redonne la vie à la terre après sa mort. Il y a en cela des preuves pour des gens qui raisonnent"
    },
    {
      "key": 3434,
      "id": "30-25",
      "translation": "Et parmi Ses signes le ciel et la terre sont maintenus par Son ordre; ensuite lorsqu'Il vous appellera d'un appel, voilà que de la terre vous surgirez"
    },
    {
      "key": 3435,
      "id": "30-26",
      "translation": "A Lui tous ceux qui sont dans les cieux et la terre: tous Lui sont entièrement soumis"
    },
    {
      "key": 3436,
      "id": "30-27",
      "translation": "Et c'est Lui qui commence la création puis la refait; et cela Lui est plus facile. Il a la transcendance absolue dans les cieux et sur la terre. C'est Lui le Tout Puissant, le Sage"
    },
    {
      "key": 3437,
      "id": "30-28",
      "translation": "Il vous a cité une parabole de vous-mêmes: Avez-vous associé vos esclaves à ce que Nous vous avons attribué en sorte que vous soyez tous égaux [en droit de propriété] et que vous les craignez [autant] que vous vous craignez mutuellement? C'est ainsi que Nous exposons Nos versets pour des gens qui raisonnent"
    },
    {
      "key": 3438,
      "id": "30-29",
      "translation": "Ceux qui ont été injustes ont plutôt suivi leurs propres passions, sans savoir. Qui donc peut guider celui qu'Allah égare? Et ils n'ont pas pour eux, de protecteur"
    },
    {
      "key": 3439,
      "id": "30-30",
      "translation": "Dirige tout ton être vers la religion exclusivement [pour Allah], telle est la nature qu'Allah a originellement donnée aux hommes - pas de changement à la création d'Allah -. Voilà la religion de droiture; mais la plupart des gens ne savent pas"
    },
    {
      "key": 3440,
      "id": "30-31",
      "translation": "Revenez repentants vers Lui; craignez-Le, accomplissez la Salât et ne soyez pas parmi les associateurs"
    },
    {
      "key": 3441,
      "id": "30-32",
      "translation": "parmi ceux qui ont divisé leur religion et sont devenus des sectes, chaque parti exultant de ce qu'il détenait"
    },
    {
      "key": 3442,
      "id": "30-33",
      "translation": "Et quand un mal touche les gens, ils invoquent leur Seigneur en revenant à Lui repentants. Puis s'Il leur fait goûter de Sa part une miséricorde, voilà qu'une partie d'entre eux donnent à leur Seigneur des associés"
    },
    {
      "key": 3443,
      "id": "30-34",
      "translation": "en sorte qu'ils deviennent ingrats envers ce que Nous leur avons donné. «Et jouissez donc. Vous saurez bientôt»"
    },
    {
      "key": 3444,
      "id": "30-35",
      "translation": "Avons-Nous fait descendre sur eux une autorité (un Livre) telle qu'elle parle de ce qu'ils Lui associaient"
    },
    {
      "key": 3445,
      "id": "30-36",
      "translation": "Et quand Nous faisons goûter une miséricorde aux gens, ils en exultent. Mais si un malheur les atteint à cause de ce que leurs propres mains ont préparé, voilà qu'ils désespèrent"
    },
    {
      "key": 3446,
      "id": "30-37",
      "translation": "N'ont-ils pas vu qu'Allah dispense Ses dons ou les restreint à qui Il veut? Il y a en cela des preuves pour des gens qui croient"
    },
    {
      "key": 3447,
      "id": "30-38",
      "translation": "Donne donc au proche parent son dû, ainsi qu'au pauvre, et au voyageur en détresse. Cela est meilleur pour ceux qui recherchent la face d'Allah (Sa satisfaction); et ce sont eux qui réussissent"
    },
    {
      "key": 3448,
      "id": "30-39",
      "translation": "Tout ce que vous donnerez à usure pour augmenter vos biens aux dépens des biens d'autrui ne les accroît pas auprès d'Allah, mais ce que vous donnez comme Zakât, tout en cherchant la Face d'Allah (Sa satisfaction)... Ceux-là verront [leurs récompenses] multipliées"
    },
    {
      "key": 3449,
      "id": "30-40",
      "translation": "C'est Allah qui vous a créés et vous a nourris. Ensuite Il vous fera mourir, puis Il vous redonnera vie. Y en a-t-il, parmi vos associés, qui fasse quoi que ce soit de tout cela? Gloire à Lui! Il transcende ce qu'on Lui associe"
    },
    {
      "key": 3450,
      "id": "30-41",
      "translation": "La corruption est apparue sur la terre et dans la mer à cause de ce que les gens ont accompli de leurs propres mains; afin qu'[Allah] leur fasse goûter une partie de ce qu'ils ont œuvré; peut-être reviendront-ils (vers Allah)"
    },
    {
      "key": 3451,
      "id": "30-42",
      "translation": "Dis: «Parcourez la terre et regardez ce qu'il est advenu de ceux qui ont vécu avant. La plupart d'entre eux étaient des associateurs»"
    },
    {
      "key": 3452,
      "id": "30-43",
      "translation": "Dirige tout ton être vers la religion de droiture, avant que ne vienne d'Allah un jour qu'on ne peut repousser. Ce jour-là [les gens] seront divisés"
    },
    {
      "key": 3453,
      "id": "30-44",
      "translation": "Celui qui aura mécru subira [les conséquences] de son infidélité. Et quiconque aura œuvré en bien... C'est pour eux-mêmes qu'ils préparent (leur avenir)"
    },
    {
      "key": 3454,
      "id": "30-45",
      "translation": "afin qu'[Allah] récompense par Sa grâce ceux qui croient et accomplissent les bonnes œuvres. En vérité, Il n'aime pas les infidèles"
    },
    {
      "key": 3455,
      "id": "30-46",
      "translation": "Parmi Ses signes, Il envoie les vents comme annonciateurs, pour vous faire goûter de Sa miséricorde et pour que le vaisseau vogue, par Son ordre, et que vous recherchiez de Sa grâce. Peut-être seriez-vous reconnaissants"
    },
    {
      "key": 3456,
      "id": "30-47",
      "translation": "Nous avons effectivement envoyé avant toi des Messagers vers leurs peuples et ils leur apportèrent les preuves. Nous Nous vengeâmes de ceux qui commirent les crimes [de la négation]; et c'était Notre devoir de secourir les croyants"
    },
    {
      "key": 3457,
      "id": "30-48",
      "translation": "Allah, c'est Lui qui envoie les vents qui soulèvent des nuages; puis Il les étend dans le ciel comme Il veut; et Il les met en morceaux. Tu vois alors la pluie sortir de leurs profondeurs. Puis, lorsqu'Il atteint avec elle qui Il veut parmi Ses serviteurs, les voilà qui se réjouissent"
    },
    {
      "key": 3458,
      "id": "30-49",
      "translation": "même s'ils étaient auparavant, avant qu'on ne l'ait fait descendre sur eux, désespérés"
    },
    {
      "key": 3459,
      "id": "30-50",
      "translation": "Regarde donc les effets de la miséricorde d'Allah comment Il redonne la vie à la terre après sa mort. C'est Lui qui fait revivre les morts et Il est Omnipotent"
    },
    {
      "key": 3460,
      "id": "30-51",
      "translation": "Et si Nous envoyons un vent et qu'ils voient jaunir [leur végétation], ils demeurent après cela ingrats (oubliant les bienfaits antérieurs)"
    },
    {
      "key": 3461,
      "id": "30-52",
      "translation": "En vérité, tu ne fais pas entendre les morts; et tu ne fais pas entendre aux sourds l'appel, s'ils s'en vont en tournant le dos"
    },
    {
      "key": 3462,
      "id": "30-53",
      "translation": "Tu n'es pas celui qui guide les aveugles hors de leur égarement. Tu ne fais entendre que ceux qui croient en Nos versets et qui sont alors entièrement soumis [musulmans]"
    },
    {
      "key": 3463,
      "id": "30-54",
      "translation": "Allah, c'est Lui qui vous a créés faibles; puis après la faiblesse, Il vous donne la force; puis après la force, Il vous réduit à la faiblesse et à la vieillesse: Il crée ce qu'Il veut et c'est Lui l'Omniscient, l'Omnipotent"
    },
    {
      "key": 3464,
      "id": "30-55",
      "translation": "Et le jour où l'Heure arrivera, les criminels jureront qu'ils n'ont demeuré qu'une heure. C'est ainsi qu'ils ont été détournés (de la vérité)"
    },
    {
      "key": 3465,
      "id": "30-56",
      "translation": "tandis que ceux à qui le savoir et la foi furent donnés diront: «Vous avez demeuré d'après le Décret d'Allah, jusqu'au Jour de la Résurrection, voici le Jour de la Résurrection, - mais vous ne saviez point»"
    },
    {
      "key": 3466,
      "id": "30-57",
      "translation": "ce jour-là donc, les excuses ne seront pas utiles aux injustes et on ne leur demandera pas à chercher à plaire à [Allah]"
    },
    {
      "key": 3467,
      "id": "30-58",
      "translation": "Et dans ce Coran, Nous avons certes cité, pour les gens, des exemples de toutes sortes. Et si tu leur apportes un prodige, ceux qui ne croient pas diront: «Certes, vous n'êtes que des imposteurs»"
    },
    {
      "key": 3468,
      "id": "30-59",
      "translation": "C'est ainsi qu'Allah scelle les cœurs de ceux qui ne savent pas"
    },
    {
      "key": 3469,
      "id": "30-60",
      "translation": "Sois donc patient, car la promesse d'Allah est vérité. Et que ceux qui ne croient pas fermement ne t'ébranlent pas"
    },
    {
      "key": 3470,
      "id": "31-1",
      "translation": "Alif, Lâm, Mîm"
    },
    {
      "key": 3471,
      "id": "31-2",
      "translation": "Voici les versets du Livre plein de sagesse"
    },
    {
      "key": 3472,
      "id": "31-3",
      "translation": "c'est un guide et une miséricorde aux bienfaisants"
    },
    {
      "key": 3473,
      "id": "31-4",
      "translation": "qui accomplissent la Salât, acquittent la Zakât et qui croient avec certitude en l'au-delà"
    },
    {
      "key": 3474,
      "id": "31-5",
      "translation": "Ceux-là sont sur le chemin droit de leur Seigneur et ce sont eux les bienheureux"
    },
    {
      "key": 3475,
      "id": "31-6",
      "translation": "Et, parmi les hommes, il est [quelqu'un] qui, dénué de science, achète de plaisants discours pour égarer hors du chemin d'Allah et pour le prendre en raillerie. Ceux-là subiront un châtiment avilissant"
    },
    {
      "key": 3476,
      "id": "31-7",
      "translation": "Et quand on lui récite Nos versets, il tourne le dos avec orgueil, comme s'il ne les avait point entendus, comme s'il y avait un poids dans ses oreilles. Fais-lui donc l'annonce d'un châtiment douloureux"
    },
    {
      "key": 3477,
      "id": "31-8",
      "translation": "Ceux qui croient et accomplissent les bonnes œuvres auront les Jardins des délices"
    },
    {
      "key": 3478,
      "id": "31-9",
      "translation": "pour y demeurer éternellement, - c'est en vérité une promesse d'Allah-. C'est Lui le Puissant, le Sage"
    },
    {
      "key": 3479,
      "id": "31-10",
      "translation": "Il a créé les cieux sans piliers que vous puissiez voir; et Il a enfoncé des montagnes fermes dans la terre pour l'empêcher de basculer avec vous; et Il y a propagé des animaux de toute espèce. Et du ciel, Nous avons fait descendre une eau, avec laquelle Nous avons fait pousser des plantes productives par couples de toute espèce"
    },
    {
      "key": 3480,
      "id": "31-11",
      "translation": "«Voilà la création d'Allah. Montrez-Moi donc ce qu'ont créé, ceux qui sont en dehors de Lui?» Mais les injustes sont dans un égarement évident"
    },
    {
      "key": 3481,
      "id": "31-12",
      "translation": "Nous avons effectivement donné à Luqmân la sagesse: «Sois reconnaissant à Allah, car quiconque est reconnaissant, n'est reconnaissant que pour soi-même; quant à celui qui est ingrat... En vérité, Allah se dispense de tout, et Il est digne de louange»"
    },
    {
      "key": 3482,
      "id": "31-13",
      "translation": "Et lorsque Luqmân dit à son fils tout en l'exhortant: «O mon fils, ne donne pas d'associé à Allah, car l'association à [Allah] est vraiment une injustice énorme.»"
    },
    {
      "key": 3483,
      "id": "31-14",
      "translation": "Nous avons commandé à l'homme [la bienfaisance envers] ses père et mère; sa mère l'a porté [subissant pour lui] peine sur peine: son sevrage a lieu à deux ans. «Sois reconnaissant envers Moi ainsi qu'envers tes parents. Vers Moi est la destination"
    },
    {
      "key": 3484,
      "id": "31-15",
      "translation": "Et si tous deux te forcent à M'associer ce dont tu n'as aucune connaissance, alors ne leur obéis pas; mais reste avec eux ici-bas de façon convenable. Et suis le sentier de celui qui se tourne vers Moi. Vers Moi, ensuite, est votre retour, et alors Je vous informerai de ce que vous faisiez»"
    },
    {
      "key": 3485,
      "id": "31-16",
      "translation": "«O mon enfant, fût-ce le poids d'un grain de moutarde, au fond d'un rocher, ou dans les cieux ou dans la terre, Allah le fera venir. Allah est infiniment Doux et Parfaitement Connaisseur"
    },
    {
      "key": 3486,
      "id": "31-17",
      "translation": "O mon enfant accomplis la Salât, commande le convenable, interdis le blâmable et endure ce qui t'arrive avec patience. Telle est la résolution à prendre dans toute entreprise"
    },
    {
      "key": 3487,
      "id": "31-18",
      "translation": "Et ne détourne pas ton visage des hommes, et ne foule pas la terre avec arrogance: car Allah n'aime pas le présomptueux plein de gloriole"
    },
    {
      "key": 3488,
      "id": "31-19",
      "translation": "Sois modeste dans ta démarche, et baisse ta voix, car la plus détestée des voix, c'est bien la voix des ânes»"
    },
    {
      "key": 3489,
      "id": "31-20",
      "translation": "Ne voyez-vous pas qu'Allah vous a assujetti ce qui est dans les cieux et sur la terre? Et Il vous a comblés de Ses bienfaits apparents et cachés. Et parmi les gens, il y en a qui disputent à propos d'Allah, sans science, ni guidée, ni Livre éclairant"
    },
    {
      "key": 3490,
      "id": "31-21",
      "translation": "Et quand on leur dit: «Suivez ce qu'Allah a fait descendre», ils disent: «Nous suivons plutôt ce sur quoi nous avons trouvé nos ancêtres». Est-ce donc même si le Diable les appelait au châtiment de la fournaise"
    },
    {
      "key": 3491,
      "id": "31-22",
      "translation": "Et quiconque soumet son être à Allah, tout en étant bienfaisant, s'accroche réellement à l'anse la plus ferme. La fin de toute chose appartient à Allah"
    },
    {
      "key": 3492,
      "id": "31-23",
      "translation": "Celui qui a mécru, que sa mécréance ne t'afflige pas: vers Nous sera leur retour et Nous les informerons de ce qu'ils faisaient. Allah connaît bien le contenu des poitrines"
    },
    {
      "key": 3493,
      "id": "31-24",
      "translation": "Nous leur donnons de la jouissance pour peu de temps; ensuite Nous les forcerons vers un dur châtiment"
    },
    {
      "key": 3494,
      "id": "31-25",
      "translation": "Si tu leur demandes: «Qui a créé les cieux et la terre?», ils diront, certes: «Allah!» Dis: «Louange à Allah!». Mais la plupart d'entre eux ne savent pas"
    },
    {
      "key": 3495,
      "id": "31-26",
      "translation": "A Allah appartient tout ce qui est dans les cieux et en terre. Allah est Celui qui se suffit à Lui-même, Il est Le Digne de louange"
    },
    {
      "key": 3496,
      "id": "31-27",
      "translation": "Quand bien même tous les arbres de la terre se changeraient en calames [plumes pour écrire], quand bien même l'océan serait un océan d'encre où conflueraient sept autres océans, les paroles d'Allah ne s'épuiseraient pas. Car Allah est Puissant et Sage"
    },
    {
      "key": 3497,
      "id": "31-28",
      "translation": "Votre création et votre résurrection [à tous] sont [aussi faciles à Allah] que s'il s'agissait d'une seule âme. Certes Allah est Audient et Clairvoyant"
    },
    {
      "key": 3498,
      "id": "31-29",
      "translation": "N'as-tu pas vu qu'Allah fait pénétrer la nuit dans le jour, et qu'il fait pénétrer le jour dans la nuit, et qu'Il a assujetti le soleil et la lune chacun poursuivant sa course jusqu'à un terme fixé? Et Allah est Parfaitement Connaisseur de ce que vous faites"
    },
    {
      "key": 3499,
      "id": "31-30",
      "translation": "Il en est ainsi parce qu'Allah est la Vérité, et que tout ce qu'ils invoquent en dehors de Lui est le Faux, et qu'Allah, c'est Lui le Haut, le Grand"
    },
    {
      "key": 3500,
      "id": "31-31",
      "translation": "N'as-tu pas vu que c'est par la grâce d'Allah que le vaisseau vogue dans la mer, afin qu'Il vous fasse voir de Ses merveilles? Il y a en cela des preuves pour tout homme patient et reconnaissant"
    },
    {
      "key": 3501,
      "id": "31-32",
      "translation": "Quand une vague les recouvre comme des ombres, ils invoquent Allah, vouant leur culte exclusivement à Lui; et lorsqu'Il les sauve, en les ramenant vers la terre ferme, certains d'entre eux deviennent réticents; mais, seul le grand traître et le grand ingrat renient Nos signes"
    },
    {
      "key": 3502,
      "id": "31-33",
      "translation": "O hommes! Craignez votre Seigneur et redoutez un jour où le père ne répondra en quoi que ce soit pour son enfant, ni l'enfant pour son père. La promesse d'Allah est vérité. Que la vie présente ne vous trompe donc pas, et que le Trompeur (Satan) ne vous induise pas en erreur sur Allah"
    },
    {
      "key": 3503,
      "id": "31-34",
      "translation": "La connaissance de l'Heure est auprès d'Allah; et c'est Lui qui fait tomber la pluie salvatrice; et Il sait ce qu'il y a dans les matrices. Et personne ne sait ce qu'il acquerra demain, et personne ne sait dans quelle terre il mourra. Certes, Allah est Omniscient et Parfaitement Connaisseur"
    },
    {
      "key": 3504,
      "id": "32-1",
      "translation": "Alif, Lâm, Mîm"
    },
    {
      "key": 3505,
      "id": "32-2",
      "translation": "La Révélation du Livre, nul doute là-dessus, émane du Seigneur de l'univers"
    },
    {
      "key": 3506,
      "id": "32-3",
      "translation": "Diront-ils qu'il  l'a inventé? Ceci est, au contraire, la vérité venant de ton Seigneur pour que tu avertisses un peuple à qui nul avertisseur avant toi n'est venu, afin qu'ils se guident"
    },
    {
      "key": 3507,
      "id": "32-4",
      "translation": "Allah qui a créé en six jours les cieux et la terre, et ce qui est entre eux. Ensuite Il S'est établi «Istawâ» sur le Trône. Vous n'avez, en dehors de Lui, ni allié ni intercesseur. Ne vous rappelez-vous donc pas"
    },
    {
      "key": 3508,
      "id": "32-5",
      "translation": "Du ciel à la terre, Il administre l'affaire, laquelle ensuite monte vers Lui en un jour équivalent à mille ans de votre calcul"
    },
    {
      "key": 3509,
      "id": "32-6",
      "translation": "C'est Lui le Connaisseur [des mondes] inconnus et visibles, le Puissant, le Miséricordieux"
    },
    {
      "key": 3510,
      "id": "32-7",
      "translation": "qui a bien fait tout ce qu'Il a créé. Et Il a commencé la création de l'homme à partir de l'argile"
    },
    {
      "key": 3511,
      "id": "32-8",
      "translation": "puis Il tira sa descendance d'une goutte d'eau vile [le sperme]"
    },
    {
      "key": 3512,
      "id": "32-9",
      "translation": "puis Il lui donna sa forme parfaite et lui insuffla de Son Esprit. Et Il vous a assigné l'ouïe, les yeux et le cœur. Que vous êtes peu reconnaissants"
    },
    {
      "key": 3513,
      "id": "32-10",
      "translation": "Et ils disent: «Quand nous serons perdus dans la terre [sous forme de poussière], redeviendrons-nous une création nouvelle?» En outre, ils ne croient pas en la rencontre avec leur Seigneur"
    },
    {
      "key": 3514,
      "id": "32-11",
      "translation": "Dis: «L'Ange de la mort qui est chargé de vous, vous fera mourir. Ensuite, vous serez ramenés vers Votre Seigneur»"
    },
    {
      "key": 3515,
      "id": "32-12",
      "translation": "Si tu voyais alors les criminels [comparaître], têtes basses devant leur Seigneur! «Notre Seigneur, Nous avons vu et entendu, renvoie-nous donc afin que nous puissions faire du bien; nous croyons [maintenant] avec certitude»"
    },
    {
      "key": 3516,
      "id": "32-13",
      "translation": "«Si Nous voulions, Nous apporterions à chaque âme sa guidée. Mais la parole venant de Moi doit être réalisée: «J'emplirai l'Enfer de djinns et d'hommes réunis»"
    },
    {
      "key": 3517,
      "id": "32-14",
      "translation": "«Goûtez donc! Pour avoir oublié la rencontre de votre jour que voici. Nous aussi Nous vous avons oubliés. Goûtez au châtiment éternel pour ce que vous faisiez»"
    },
    {
      "key": 3518,
      "id": "32-15",
      "translation": "Seuls croient en Nos versets ceux qui, lorsqu'on les leur rappelle, tombent prosternés et, par des louanges à leur Seigneur, célèbrent Sa gloire et ne s'enflent pas d'orgueil"
    },
    {
      "key": 3519,
      "id": "32-16",
      "translation": "Ils s'arrachent de leurs lits pour invoquer leur Seigneur, par crainte et espoir; et ils font largesse de ce que Nous leur attribuons"
    },
    {
      "key": 3520,
      "id": "32-17",
      "translation": "Aucun être ne sait ce qu'on a réservé pour eux comme réjouissance pour les yeux, en récompense de ce qu'ils œuvraient"
    },
    {
      "key": 3521,
      "id": "32-18",
      "translation": "Celui qui est croyant est-il comparable au pervers? (Non), ils ne sont point égaux"
    },
    {
      "key": 3522,
      "id": "32-19",
      "translation": "Ceux qui croient et accomplissent les bonnes œuvres, auront leur résidence dans les Jardins du Refuge, en récompense de ce qu'ils œuvraient"
    },
    {
      "key": 3523,
      "id": "32-20",
      "translation": "Et quant à ceux qui auront été pervers, leur refuge sera le Feu: toutes les fois qu'ils voudront en sortir, ils y seront ramenés, et on leur dira: «Goûtez au châtiment du Feu auquel vous refusiez de croire»"
    },
    {
      "key": 3524,
      "id": "32-21",
      "translation": "Nous leur ferons certainement goûter au châtiment ici-bas, avant le grand châtiment afin qu'ils retournent (vers le chemin droit)"
    },
    {
      "key": 3525,
      "id": "32-22",
      "translation": "Qui est plus injuste que celui à qui les versets d'Allah sont rappelés et qui ensuite s'en détourne? Nous nous vengerons certes des criminels"
    },
    {
      "key": 3526,
      "id": "32-23",
      "translation": "Nous avons effectivement donné à Moïse le Livre - ne sois donc pas en doute sur ta rencontre avec lui -, et l'avons assigné comme guide aux Enfants d'Israël"
    },
    {
      "key": 3527,
      "id": "32-24",
      "translation": "Et Nous avons désigné parmi eux des dirigeants qui guidaient (les gens) par Notre ordre aussi longtemps qu'ils enduraient et croyaient fermement en Nos versets"
    },
    {
      "key": 3528,
      "id": "32-25",
      "translation": "Ton Seigneur, c'est Lui qui décidera entre eux, au Jour de la Résurrection, de ce sur quoi ils divergeaient"
    },
    {
      "key": 3529,
      "id": "32-26",
      "translation": "N'est-ce pas pour eux une indication le fait qu'avant eux, Nous ayons fait périr tant de générations dans les maisons desquelles ils marchent? Il y a en cela des preuves! N'écouteront-ils donc pas"
    },
    {
      "key": 3530,
      "id": "32-27",
      "translation": "N'ont-ils pas vu que Nous poussons l'eau vers un sol aride, qu'ensuite Nous en faisons sortir une culture que consomment leurs bestiaux et eux-mêmes? Ne voient-ils donc pas"
    },
    {
      "key": 3531,
      "id": "32-28",
      "translation": "Et ils disent: «A quand cette victoire, si vous êtes véridiques?»"
    },
    {
      "key": 3532,
      "id": "32-29",
      "translation": "Dis: «Le jour de la Victoire, il sera inutile aux infidèles de croire! Et aucun délai ne leur sera donné»"
    },
    {
      "key": 3533,
      "id": "32-30",
      "translation": "Eloigne-toi d'eux et attends. Eux aussi demeurent dans l'attente"
    },
    {
      "key": 3534,
      "id": "33-1",
      "translation": "O Prophète! Crains Allah et n'obéis pas aux infidèles et aux hypocrites, car Allah demeure Omniscient et Sage"
    },
    {
      "key": 3535,
      "id": "33-2",
      "translation": "Et suis ce qui t'est révélé émanant de Ton Seigneur. Car Allah est Parfaitement Connaisseur de ce que vous faites"
    },
    {
      "key": 3536,
      "id": "33-3",
      "translation": "Et place ta confiance en Allah. Allah te suffit comme protecteur"
    },
    {
      "key": 3537,
      "id": "33-4",
      "translation": "Allah n'a pas placé à l'homme deux cœurs dans sa poitrine. Il n'a point assimilé à vos mères vos épouses [à qui vous dites en les répudiant]: «Tu es [aussi illicite] pour moi que le dos de ma mère». Il n'a point fait de vos enfants adoptifs vos propres enfants. Ce sont des propos [qui sortent] de votre bouche. Mais Allah dit la vérité et c'est Lui qui met [l'homme] dans la bonne direction"
    },
    {
      "key": 3538,
      "id": "33-5",
      "translation": "Appelez-les du nom de leurs pères: c'est plus équitable devant Allah. Mais si vous ne connaissez pas leurs pères, alors considérez-les comme vos frères en religion ou vos alliés. Nul blâme sur vous pour ce que vous faites par erreur, mais (vous serez blâmés pour) ce que vos cœurs font délibérément. Allah, cependant, est Pardonneur et Miséricordieux"
    },
    {
      "key": 3539,
      "id": "33-6",
      "translation": "Le Prophète a plus de droit sur les croyants qu'ils n'en ont sur eux-mêmes; et ses épouses sont leurs mères. Les liens de consanguinité ont [dans les successions] la priorité [sur les liens] unissant les croyants [de Médine] et les émigrés [de la Mecque] selon le livre d'Allah, à moins que vous ne fassiez un testament convenable en faveur de vos frères en religion. Et cela est inscrit dans le Livre"
    },
    {
      "key": 3540,
      "id": "33-7",
      "translation": "Lorsque Nous prîmes des prophètes leur engagement, de même que de toi, de Noé, d'Abraham, de Moïse, et de Jésus fils de Marie: et Nous avons pris d'eux un engagement solennel"
    },
    {
      "key": 3541,
      "id": "33-8",
      "translation": "afin [qu'Allah] interroge les véridiques sur leur sincérité. Et Il a préparé aux infidèles un châtiment douloureux"
    },
    {
      "key": 3542,
      "id": "33-9",
      "translation": "O vous qui croyez! Rappelez-vous le bienfait d'Allah sur vous, quand des troupes vous sont venues et que Nous avons envoyé contre elles un vent et des troupes que vous n'avez pas vues. Allah demeure Clairvoyant sur ce que vous faites"
    },
    {
      "key": 3543,
      "id": "33-10",
      "translation": "Quand ils vous vinrent d'en haut et d'en bas [de toutes parts], et que les regards étaient troublés, et les cœurs remontaient aux gorges, et vous faisiez sur Allah toutes sortes de suppositions"
    },
    {
      "key": 3544,
      "id": "33-11",
      "translation": "Les croyants furent alors éprouvés et secoués d'une dure secousse"
    },
    {
      "key": 3545,
      "id": "33-12",
      "translation": "Et quand les hypocrites et ceux qui ont la maladie [le doute] au cœur disaient: «Allah et Son messager ne nous ont promis que tromperie»"
    },
    {
      "key": 3546,
      "id": "33-13",
      "translation": "De même, un groupe d'entre eux dit: «Gens de Yathrib! Ne demeurez pas ici. Retournez [chez vous]». Un groupe d'entre eux demande au Prophète la permission de partir en disant: «Nos demeures sont sans protection», alors qu'elles ne l'étaient pas: ils ne voulaient que s'enfuir"
    },
    {
      "key": 3547,
      "id": "33-14",
      "translation": "Et si une percée avait été faite sur eux par les flancs de la ville et qu'ensuite on leur avait demandé de renier leur foi, ils auraient accepté certes, et n'auraient guère tardé"
    },
    {
      "key": 3548,
      "id": "33-15",
      "translation": "tandis qu'auparavant ils avaient pris l'engagement envers Allah qu'ils ne tourneraient pas le dos. Et il sera demandé compte de tout engagement vis-à-vis d'Allah"
    },
    {
      "key": 3549,
      "id": "33-16",
      "translation": "Dis: «Jamais la fuite ne vous sera utile si c'est la mort (sans combat) ou le meurtre (dans le combat) que vous fuyez; dans ce cas, vous ne jouirez (de la vie) que peu (de temps)»"
    },
    {
      "key": 3550,
      "id": "33-17",
      "translation": "Dis: «Quel est celui qui peut vous protéger d'Allah, s'Il vous veut du mal ou s'Il veut vous accorder une miséricorde?» Et ils ne trouveront pour eux-mêmes en dehors d'Allah, ni allié ni secoureur"
    },
    {
      "key": 3551,
      "id": "33-18",
      "translation": "Certes, Allah connaît ceux d'entre vous qui suscitent des obstacles, ainsi que ceux qui disent à leurs frères: «Venez à nous», tandis qu'ils ne déploient que peu d'ardeur au combat"
    },
    {
      "key": 3552,
      "id": "33-19",
      "translation": "avares à votre égard. Puis, quand leur vient la peur, tu les vois te regarder avec des yeux révulsés, comme ceux de quelqu'un qui s'est évanoui par peur de la mort. Une fois la peur passée, ils vous lacèrent avec des langues affilées, alors qu'ils sont chiches à faire le bien. Ceux-là n'ont jamais cru. Allah donc, rend vaines leurs actions. Et cela est facile à Allah"
    },
    {
      "key": 3553,
      "id": "33-20",
      "translation": "Ils pensent que les coalisés ne sont pas partis. Or si les coalisés revenaient, [ces gens-là] souhaiteraient être des nomades parmi les Bédouins et [se contenteraient] de demander de vos nouvelles. S'ils étaient parmi vous, ils n'auraient combattu que très peu"
    },
    {
      "key": 3554,
      "id": "33-21",
      "translation": "En effet, vous avez dans le Messager d'Allah un excellent modèle [à suivre], pour quiconque espère en Allah et au Jour dernier et invoque Allah fréquemment"
    },
    {
      "key": 3555,
      "id": "33-22",
      "translation": "Et quand les croyants virent les coalisés, ils dirent: «Voilà ce qu'Allah et Son messager nous avaient promis; et Allah et Son messager disaient la vérité». Et cela ne fit que croître leur foi et leur soumission"
    },
    {
      "key": 3556,
      "id": "33-23",
      "translation": "Il est, parmi les croyants, des hommes qui ont été sincères dans leur engagement envers Allah. Certains d'entre eux ont atteint leur fin, et d'autres attendent encore; et ils n'ont varié aucunement (dans leur engagement)"
    },
    {
      "key": 3557,
      "id": "33-24",
      "translation": "afin qu'Allah récompense les véridiques pour leur sincérité, et châtie, s'Il veut, les hypocrites, ou accepte leur repentir. Car Allah est Pardonneur et Miséricordieux"
    },
    {
      "key": 3558,
      "id": "33-25",
      "translation": "Et Allah a renvoyé, avec leur rage, les infidèles sans qu'ils n'aient obtenu aucun bien, et Allah a épargné aux croyants le combat. Allah est Fort et Puissant"
    },
    {
      "key": 3559,
      "id": "33-26",
      "translation": "Et Il a fait descendre de leurs forteresses ceux des gens du Livre qui les avaient soutenus [les coalisés], et Il a jeté l'effroi dans leurs cœurs; un groupe d'entre eux vous tuiez, et un groupe vous faisiez prisonniers"
    },
    {
      "key": 3560,
      "id": "33-27",
      "translation": "Et Il vous a fait hériter leur terre, leurs demeures, leurs biens, et aussi une terre que vous n'aviez point foulée. Et Allah est Omnipotent"
    },
    {
      "key": 3561,
      "id": "33-28",
      "translation": "O Prophète! Dis à tes épouses: «Si c'est la vie présente que vous désirez et sa parure, alors venez! Je vous donnerai [les moyens] d'en jouir et vous libérerai [par un divorce] sans préjudice"
    },
    {
      "key": 3562,
      "id": "33-29",
      "translation": "Mais si c'est Allah que vous voulez et Son messager ainsi que la Demeure dernière, Allah a préparé pour les bienfaisantes parmi vous une énorme récompense"
    },
    {
      "key": 3563,
      "id": "33-30",
      "translation": "O femmes du Prophète! Celle d'entre vous qui commettra une turpitude prouvée, le châtiment lui sera doublé par deux fois! Et ceci est facile pour Allah"
    },
    {
      "key": 3564,
      "id": "33-31",
      "translation": "Et celle d'entre vous qui est entièrement soumise à Allah et à Son messager et qui fait le bien, Nous lui accorderons deux fois sa récompense, et Nous avons préparé pour elle une généreuse attribution"
    },
    {
      "key": 3565,
      "id": "33-32",
      "translation": "O femmes du Prophète! Vous n'êtes comparables à aucune autre femme. Si vous êtes pieuses, ne soyez pas trop complaisantes dans votre langage, afin que celui dont le cœur est malade [l'hypocrite] ne vous convoite pas. Et tenez un langage décent"
    },
    {
      "key": 3566,
      "id": "33-33",
      "translation": "Restez dans vos foyers; et ne vous exhibez pas à la manière des femmes d'avant l'Islam (Jâhiliyah). Accomplissez la Salât, acquittez la Zakât et obéissez à Allah et à Son messager. Allah ne veut que vous débarrasser de toute souillure, ô gens de la maison [du prophète], et veut vous purifier pleinement"
    },
    {
      "key": 3567,
      "id": "33-34",
      "translation": "Et gardez dans vos mémoires ce qui, dans vos foyers, est récité des versets d'Allah et de la sagesse. Allah est Doux et Parfaitement Connaisseur"
    },
    {
      "key": 3568,
      "id": "33-35",
      "translation": "Les Musulmans et Musulmanes, croyants et croyantes, obéissants et obéissantes, loyaux et loyales, endurants et endurantes, craignants et craignantes, donneurs et donneuses d'aumône, jeûnants et jeûnantes, gardiens de leur chasteté et gardiennes, invocateurs souvent d'Allah et invocatrices: Allah a préparé pour eux un pardon et une énorme récompense"
    },
    {
      "key": 3569,
      "id": "33-36",
      "translation": "Il n'appartient pas à un croyant ou à une croyante, une fois qu'Allah et Son messager ont décidé d'une chose d'avoir encore le choix dans leur façon d'agir. Et quiconque désobéit à Allah et à Son messager, s'est égaré certes, d'un égarement évident"
    },
    {
      "key": 3570,
      "id": "33-37",
      "translation": "Quand tu disais à celui qu'Allah avait comblé de bienfait, tout comme toi-même l'avais comblé: «Garde pour toi ton épouse et crains Allah», et tu cachais en ton âme ce qu'Allah allait rendre public. Tu craignais les gens, et c'est Allah qui est plus digne de ta crainte. Puis quand Zayd eut cessé toute relation avec elle, Nous te la fîmes épouser, afin qu'il n'y ait aucun empêchement pour les croyants d'épouser les femmes de leurs fils adoptifs, quand ceux-ci cessent toute relation avec elles. Le commandement d'Allah doit être exécuté"
    },
    {
      "key": 3571,
      "id": "33-38",
      "translation": "Nul grief à faire au Prophète en ce qu'Allah lui a imposé, conformément aux lois établies pour ceux qui vécurent antérieurement. Le commandement d'Allah est un décret inéluctable"
    },
    {
      "key": 3572,
      "id": "33-39",
      "translation": "Ceux qui communiquaient les messages d'Allah, Le craignaient et ne redoutaient nul autre qu'Allah. Et Allah suffit pour tenir le compte de tout"
    },
    {
      "key": 3573,
      "id": "33-40",
      "translation": "Muhammad n'a jamais été le père de l'un de vos hommes, mais le messager d'Allah et le dernier des prophètes. Allah est Omniscient"
    },
    {
      "key": 3574,
      "id": "33-41",
      "translation": "O vous qui croyez! Evoquez Allah d'une façon abondante"
    },
    {
      "key": 3575,
      "id": "33-42",
      "translation": "et glorifiez-Le à la pointe et au déclin du jour"
    },
    {
      "key": 3576,
      "id": "33-43",
      "translation": "C'est lui qui prie sur vous, - ainsi que Ses Anges -, afin qu'Il vous fasse sortir des ténèbres à la lumière; et Il est Miséricordieux envers les croyants"
    },
    {
      "key": 3577,
      "id": "33-44",
      "translation": "Leur salutation au jour où ils Le rencontreront sera: «Salâm» [paix], et Il leur a préparé une généreuse récompense"
    },
    {
      "key": 3578,
      "id": "33-45",
      "translation": "O Prophète! Nous t'avons envoyé [pour être] témoin, annonciateur, avertisseur"
    },
    {
      "key": 3579,
      "id": "33-46",
      "translation": "appelant (les gens) à Allah, par Sa permission; et comme une lampe éclairante"
    },
    {
      "key": 3580,
      "id": "33-47",
      "translation": "Et fais aux croyants la bonne annonce qu'ils recevront d'Allah une grande grâce"
    },
    {
      "key": 3581,
      "id": "33-48",
      "translation": "Et n'obéis pas aux infidèles et aux hypocrites, ne prête pas attention à leur méchanceté et place ta confiance en Allah et Allah suffit comme protecteur"
    },
    {
      "key": 3582,
      "id": "33-49",
      "translation": "O vous qui croyez! Quand vous vous mariez avec des croyantes et qu'ensuite vous divorcez d'avec elles avant de les avoir touchées, vous ne pouvez leur imposer un délai d'attente. Donnez-leur jouissance [d'un bien] et libérez-les [par un divorce] sans préjudice"
    },
    {
      "key": 3583,
      "id": "33-50",
      "translation": "O Prophète! Nous t'avons rendu licites tes épouses à qui tu as donné leur mahr (dot), ce que tu as possédé légalement parmi les captives [ou esclaves] qu'Allah t'a destinées, les filles de ton oncle paternel, les filles de tes tantes paternelles, les filles de ton oncle maternel, et les filles de tes tantes maternelles, - celles qui avaient émigré en ta compagnie -, ainsi que toute femme croyante si elle fait don de sa personne au Prophète, pourvu que le Prophète consente à se marier avec elle: c'est là un privilège pour toi, à l'exclusion des autres croyants. Nous savons certes, ce que Nous leur avons imposé au sujet de leurs épouses et des esclaves qu'ils possèdent, afin qu'il n'y eût donc point de blâme contre toi. Allah est Pardonneur et Miséricordieux"
    },
    {
      "key": 3584,
      "id": "33-51",
      "translation": "Tu fais attendre qui tu veux d'entre elles, et tu héberges chez toi qui tu veux. Puis il ne t'est fait aucun grief si tu invites chez toi l'une de celles que tu avais écartées. Voilà ce qui est le plus propre à les réjouir, à leur éviter tout chagrin et à leur faire accepter de bon cœur ce que tu leur as donné à toutes. Allah sait, cependant, ce qui est en vos cœurs. Et Allah est Omniscient et Indulgent"
    },
    {
      "key": 3585,
      "id": "33-52",
      "translation": "Il ne t'est plus permis désormais de prendre [d'autres] femmes, ni de changer d'épouses, même si leur beauté te plaît; - à l'exception des esclaves que tu possèdes. Et Allah observe toute chose"
    },
    {
      "key": 3586,
      "id": "33-53",
      "translation": "O vous qui croyez! N'entrez pas dans les demeures du Prophète, à moins qu'invitation ne vous soit faite à un repas, sans être là à attendre sa cuisson. Mais lorsqu'on vous appelle, alors, entrez. Puis, quand vous aurez mangé, dispersez-vous, sans chercher à vous rendre familiers pour causer. Cela faisait de la peine au Prophète, mais il se gênait de vous (congédier), alors qu'Allah ne se gêne pas de la vérité. Et si vous leur demandez (à ses femmes) quelque objet, demandez-le leur derrière un rideau: c'est plus pur pour vos cœurs et leurs cœurs; vous ne devez pas faire de la peine au Messager d'Allah, ni jamais vous marier avec ses épouses après lui; ce serait, auprès d'Allah, un énorme péché"
    },
    {
      "key": 3587,
      "id": "33-54",
      "translation": "Que vous divulguiez une chose ou que vous la cachiez,... Allah demeure Omniscient"
    },
    {
      "key": 3588,
      "id": "33-55",
      "translation": "Nul grief sur elles au sujet de leurs pères, leurs fils, leurs frères, les fils de leurs frères, les fils de leurs sœurs, leurs femmes [de suite] et les esclaves qu'elles possèdent. Et craignez Allah. Car Allah est témoin de toute chose"
    },
    {
      "key": 3589,
      "id": "33-56",
      "translation": "Certes, Allah et Ses Anges prient sur le Prophète; ô vous qui croyez priez sur lui et adressez [lui] vos salutations"
    },
    {
      "key": 3590,
      "id": "33-57",
      "translation": "Ceux qui offensent Allah et Son messager, Allah les maudit ici-bas, comme dans l'au-delà et leur prépare un châtiment avilissant"
    },
    {
      "key": 3591,
      "id": "33-58",
      "translation": "Et ceux qui offensent les croyants et les croyantes sans qu'ils l'aient mérité, se chargent d'une calomnie et d'un péché évident"
    },
    {
      "key": 3592,
      "id": "33-59",
      "translation": "O Prophète! Dis à tes épouses, à tes filles, et aux femmes des croyants, de ramener sur elles leurs grands voiles: elles en seront plus vite reconnues et éviteront d'être offensées. Allah est Pardonneur et Miséricordieux"
    },
    {
      "key": 3593,
      "id": "33-60",
      "translation": "Certes, si les hypocrites, ceux qui ont la maladie au cœur, et les alarmistes [semeurs de troubles] à Médine ne cessent pas, Nous t'inciterons contre eux, et alors, ils n'y resteront que peu de temps en ton voisinage"
    },
    {
      "key": 3594,
      "id": "33-61",
      "translation": "Ce sont des maudits. Où qu'on les trouve, ils seront pris et tués impitoyablement"
    },
    {
      "key": 3595,
      "id": "33-62",
      "translation": "Telle était la loi établie par Allah envers ceux qui ont vécu auparavant et tu ne trouveras pas de changement dans la loi d'Allah"
    },
    {
      "key": 3596,
      "id": "33-63",
      "translation": "Les gens t'interrogent au sujet de l'Heure. Dis: «Sa connaissance est exclusive à Allah». Qu'en sais-tu? Il se peut que l'Heure soit proche"
    },
    {
      "key": 3597,
      "id": "33-64",
      "translation": "Allah a maudit les infidèles et leur a préparé une fournaise"
    },
    {
      "key": 3598,
      "id": "33-65",
      "translation": "pour qu'ils y demeurent éternellement, sans trouver ni allié ni secoureur"
    },
    {
      "key": 3599,
      "id": "33-66",
      "translation": "Le jour où leurs visages seront tournés et retournés dans le Feu, ils diront: «Hélas pour nous! Si seulement nous avions obéi à Allah et obéi au Messager!»"
    },
    {
      "key": 3600,
      "id": "33-67",
      "translation": "Et ils dirent: «Seigneur, nous avons obéi à nos chefs et à nos grands. C'est donc eux qui nous ont égarés du Sentier"
    },
    {
      "key": 3601,
      "id": "33-68",
      "translation": "O notre Seigneur, inflige-leur deux fois le châtiment et maudis les d'une grande malédiction»"
    },
    {
      "key": 3602,
      "id": "33-69",
      "translation": "O vous qui croyez! Ne soyez pas comme ceux qui ont offensé Moïse. Allah l'a déclaré innocent de leurs accusations, car il était honorable auprès d'Allah"
    },
    {
      "key": 3603,
      "id": "33-70",
      "translation": "O vous qui croyez! Craignez Allah et parlez avec droiture"
    },
    {
      "key": 3604,
      "id": "33-71",
      "translation": "afin qu'Il améliore vos actions et vous pardonne vos péchés. Quiconque obéit à Allah et à Son messager obtient certes une grande réussite"
    },
    {
      "key": 3605,
      "id": "33-72",
      "translation": "Nous avions proposé aux cieux, à la terre et aux montagnes la responsabilité (de porter les charges de faire le bien et d'éviter le mal). Ils ont refusé de la porter et en ont eu peur, alors que l'homme s'en est chargé; car il est très injuste [envers lui-même] et très ignorant"
    },
    {
      "key": 3606,
      "id": "33-73",
      "translation": "[Il en est ainsi] afin qu'Allah châtie les hypocrites, hommes et femmes, et les associateurs et les associatrices et Allah accueille le repentir des croyants et des croyantes. Allah est Pardonneur et Miséricordieux"
    },
    {
      "key": 3607,
      "id": "34-1",
      "translation": "Louange à Allah à qui appartient tout ce qui est dans les cieux et tout ce qui est sur la terre. Et louange à Lui dans l'au-delà. Et c'est Lui le Sage, le Parfaitement Connaisseur"
    },
    {
      "key": 3608,
      "id": "34-2",
      "translation": "Il sait ce qui pénètre en terre et ce qui en sort, ce qui descend du ciel et ce qui y remonte. Et c'est Lui le Miséricordieux, le Pardonneur"
    },
    {
      "key": 3609,
      "id": "34-3",
      "translation": "Ceux qui ne croient pas disent: «L'Heure ne nous viendra pas». Dis: «Par mon Seigneur! Très certainement, elle vous viendra. [Mon Seigneur] le Connaisseur de l'Inconnaissable. Rien ne Lui échappe fût-il du poids d'un atome dans les cieux, comme sur la terre. Et rien n'existe de plus petit ni de plus grand, qui ne soit inscrit dans un Livre explicite"
    },
    {
      "key": 3610,
      "id": "34-4",
      "translation": "afin qu'Il récompense ceux qui croient et accomplissent les bonnes œuvres. Pour ceux-ci, il y aura un pardon et un don généreux"
    },
    {
      "key": 3611,
      "id": "34-5",
      "translation": "Et ceux qui s'efforcent de rendre vains Nos versets, ceux-là auront le châtiment d'un supplice douloureux"
    },
    {
      "key": 3612,
      "id": "34-6",
      "translation": "Et ceux à qui le savoir a été donné voient que ce qu'on t'a fait descendre de la part de ton Seigneur est la vérité qui guide au chemin du Tout Puissant, du Digne de Louange"
    },
    {
      "key": 3613,
      "id": "34-7",
      "translation": "Et ceux qui ne croient pas dirent: «Voulez-vous que l'on vous montre un homme qui vous prédise que lorsque vous serez complètement désintégrés, vous reparaîtrez, sans nul doute, en une nouvelle création"
    },
    {
      "key": 3614,
      "id": "34-8",
      "translation": "Invente-t-il un mensonge contre Allah? ou bien est-il fou?» [Non], mais ceux qui ne croient pas en l'au-delà sont voués au châtiment et à l'égarement lointain"
    },
    {
      "key": 3615,
      "id": "34-9",
      "translation": "Ne voient-ils donc pas ce qu'il y a comme ciel et comme terre devant et derrière eux? Si Nous voulions, Nous ferions que la terre les engloutisse, ou que des morceaux du ciel tombent sur eux. Il y a en cela une preuve pour tout serviteur repentant"
    },
    {
      "key": 3616,
      "id": "34-10",
      "translation": "Nous avons certes accordé une grâce à David de Notre part. O montagnes et oiseaux, répétez avec lui (les louanges d'Allah). Et pour lui, Nous avons amolli le fer"
    },
    {
      "key": 3617,
      "id": "34-11",
      "translation": "(en lui disant): «Fabrique des cottes de mailles complètes et mesure bien les mailles». Et faites le bien. Je suis Clairvoyant sur ce que vous faites"
    },
    {
      "key": 3618,
      "id": "34-12",
      "translation": "Et à Salomon (Nous avons assujetti) le vent, dont le parcours du matin équivaut à un mois (de marche) et le parcours du soir, un mois aussi. Et pour lui Nous avons fait couler la source de cuivre. Et parmi les djinns il y en a qui travaillaient sous ses ordres, par permission de son Seigneur. Quiconque d'entre eux, cependant, déviait de Notre ordre, Nous lui faisions goûter au châtiment de la fournaise"
    },
    {
      "key": 3619,
      "id": "34-13",
      "translation": "Ils exécutaient pour lui ce qu'il voulait: sanctuaires, statues, plateaux comme des bassins, et marmites bien ancrées. - «O famille de David, œuvrez par gratitude», alors qu'il y a eu peu de Mes serviteurs qui sont reconnaissants"
    },
    {
      "key": 3620,
      "id": "34-14",
      "translation": "Puis, quand Nous décidâmes sa mort, il n'y eut pour les avertir de sa mort que la «bête de terre», qui rongea sa canne. Puis lorsqu'il s'écroula, il apparut de toute évidence aux djinns que s'ils savaient vraiment l'inconnu, ils ne seraient pas restés dans le supplice humiliant [de la servitude]"
    },
    {
      "key": 3621,
      "id": "34-15",
      "translation": "Il y avait assurément, pour la tribu de Saba' un Signe dans leurs habitat: deux jardins, l'un à droite et l'autre à gauche. «Mangez de ce que votre Seigneur vous a attribué, et soyez-Lui reconnaissants: une bonne contrée et un Seigneur Pardonneur»"
    },
    {
      "key": 3622,
      "id": "34-16",
      "translation": "Mais ils se détournèrent. Nous déchaînâmes contre eux l'inondation du Barrage, et leur changeâmes leurs deux jardins en deux jardins aux fruits amers, tamaris et quelques jujubiers"
    },
    {
      "key": 3623,
      "id": "34-17",
      "translation": "Ainsi les rétribuâmes Nous pour leur mécréance. Saurions-Nous sanctionner un autre que le mécréant"
    },
    {
      "key": 3624,
      "id": "34-18",
      "translation": "Et Nous avions placé entre eux et les cités que Nous avions bénies, d'autres cités proéminentes, et Nous avions évalué les étapes de voyage entre elles. «Voyagez entre elles pendant des nuits et des jours, en sécurité»"
    },
    {
      "key": 3625,
      "id": "34-19",
      "translation": "Puis, ils dirent: «Seigneur, allonge les distances entre nos étapes», et ils se firent du tort à eux-mêmes. Nous fîmes d'eux, donc, des sujets de légendes et les désintégrâmes totalement. Il y a en cela des avertissements pour tous grand endurant et grand reconnaissant"
    },
    {
      "key": 3626,
      "id": "34-20",
      "translation": "Et Satan a très certainement rendu véridique sa conjecture à leur égard. Ils l'ont suivi donc, sauf un groupe parmi les croyants"
    },
    {
      "key": 3627,
      "id": "34-21",
      "translation": "Et pourtant il n'avait sur eux aucun pouvoir si ce n'est que Nous voulions distinguer celui qui croyait en l'au-delà et celui qui doutait. Ton Seigneur, cependant, assure la sauvegarde de toute chose"
    },
    {
      "key": 3628,
      "id": "34-22",
      "translation": "Dis: «Invoquez ceux qu'en dehors d'Allah vous prétendez [être des divinités]. Ils ne possèdent même pas le poids d'un atome, ni dans les cieux ni sur la terre. Ils n'ont jamais été associés à leur création et Il n'a personne parmi eux pour Le soutenir»"
    },
    {
      "key": 3629,
      "id": "34-23",
      "translation": "L'intercession auprès de Lui ne profite qu'à celui en faveur duquel Il la permet. Quand ensuite la frayeur se sera éloignée de leurs cœurs, ils diront: «Qu'a dit votre Seigneur?» Ils répondront: «La Vérité; C'est Lui le Sublime, le Grand»"
    },
    {
      "key": 3630,
      "id": "34-24",
      "translation": "Dis: «Qui vous nourrit du ciel et de la terre?» Dis: «Allah. C'est nous ou bien vous qui sommes sur une bonne voie, ou dans un égarement manifeste»"
    },
    {
      "key": 3631,
      "id": "34-25",
      "translation": "Dis: «Vous ne serez pas interrogés sur les crimes que nous avons commis, et nous ne serons pas interrogés sur ce que vous faites»"
    },
    {
      "key": 3632,
      "id": "34-26",
      "translation": "Dis: «Notre Seigneur nous réunira, puis Il tranchera entre nous, avec la vérité, car c'est Lui le Grand Juge, l'Omniscient»"
    },
    {
      "key": 3633,
      "id": "34-27",
      "translation": "Dis: «Montrez-moi ceux que vous Lui avez donnés comme associés. Eh bien, non! C'est plutôt Lui, Allah, le Puissant, le Sage»"
    },
    {
      "key": 3634,
      "id": "34-28",
      "translation": "Et Nous ne t'avons envoyé qu'en tant qu'annonciateur et avertisseur pour toute l'humanité. Mais la plupart des gens ne savent pas"
    },
    {
      "key": 3635,
      "id": "34-29",
      "translation": "Et ils disent: «A quand cette promesse, si vous êtes véridiques?»"
    },
    {
      "key": 3636,
      "id": "34-30",
      "translation": "Dis: «Le rendez-vous est pour un jour que vous ne saurez retarder d'une heure, ni avancer!»"
    },
    {
      "key": 3637,
      "id": "34-31",
      "translation": "Et ceux qui avaient mécru dirent: «Jamais nous ne croirons à ce Coran ni à ce qui l'a précédé». Et si tu pouvais voir quand les injustes seront debout devant leur Seigneur, se renvoyant la parole les uns aux autres! Ceux que l'on considérait comme faibles diront à ceux qui s'enorgueillissaient: «Sans vous, nous aurions certes été croyants»"
    },
    {
      "key": 3638,
      "id": "34-32",
      "translation": "Ceux qui s'enorgueillissaient diront à ceux qu'ils considéraient comme faibles: «Est-ce nous qui vous avons repoussés de la bonne direction après qu'elle vous fut venue? Mais vous étiez plutôt des criminels»"
    },
    {
      "key": 3639,
      "id": "34-33",
      "translation": "Et ceux que l'on considérait comme faibles diront à ceux qui s'enorgueillissaient: «C'était votre stratagème, plutôt, nuit et jour, de nous commander de ne pas croire en Allah et de Lui donner des égaux». Et ils cacheront leur regret quand ils verront le châtiment. Nous placerons des carcans aux cous de ceux qui ont mécru: les rétribuerait-on autrement que selon ce qu'ils œuvraient?»"
    },
    {
      "key": 3640,
      "id": "34-34",
      "translation": "Et Nous n'avons envoyé aucun avertisseur dans une cité sans que ses gens aisés n'aient dit: «Nous ne croyons pas au message avec lequel vous êtes envoyés»"
    },
    {
      "key": 3641,
      "id": "34-35",
      "translation": "Et ils dirent: «Nous avons davantage de richesses et d'enfants et nous ne serons pas châtiés»"
    },
    {
      "key": 3642,
      "id": "34-36",
      "translation": "Dis: «Mon Seigneur dispense avec largesse ou restreint Ses dons à qui Il veut. Mais la plupart des gens ne savent pas»"
    },
    {
      "key": 3643,
      "id": "34-37",
      "translation": "Ni vos biens ni vos enfants ne vous rapprocheront à proximité de Nous. Sauf celui qui croit et œuvre dans le bien. Ceux-là auront une double récompense pour ce qu'ils œuvraient, tandis qu'ils seront en sécurité, aux étages supérieurs (du Paradis)"
    },
    {
      "key": 3644,
      "id": "34-38",
      "translation": "Et quant à ceux qui s'efforcent à rendre Nos versets inefficients, ceux-là seront forcés de se présenter au châtiment"
    },
    {
      "key": 3645,
      "id": "34-39",
      "translation": "Dis: «Mon Seigneur dispense avec largesse ou restreint Ses dons à qui Il veut parmi ses serviteurs. Et toute dépense que vous faites [dans le bien], Il la remplace, et c'est Lui le Meilleur des donateurs»"
    },
    {
      "key": 3646,
      "id": "34-40",
      "translation": "Et un jour Il les rassemblera tous. Puis Il dira aux Anges: «Est-ce vous que ces gens-là adoraient?»"
    },
    {
      "key": 3647,
      "id": "34-41",
      "translation": "Ils diront: «Gloire à Toi! Tu es notre Allié en dehors d'eux. Ils adoraient plutôt les djinns, en qui la plupart d'entre eux croyaient"
    },
    {
      "key": 3648,
      "id": "34-42",
      "translation": "Ce jour-là donc, vous n'aurez aucun moyen pour profiter ou nuire les uns aux autres, tandis que Nous dirons aux injustes: «Goûtez au châtiment du Feu que vous traitiez de mensonge»"
    },
    {
      "key": 3649,
      "id": "34-43",
      "translation": "Et quand Nos versets édifiants leur sont récités, ils disent: Ce n'est là qu'un homme qui veut vous repousser de ce que vos ancêtres adoraient». Et ils disent: «Ceci (Le Coran) n'est qu'un mensonge inventé». Et ceux qui ne croient pas disent de la Vérité quand elle leur vient: «Ce n'est là qu'une magie évidente!»"
    },
    {
      "key": 3650,
      "id": "34-44",
      "translation": "[Pourtant] Nous ne leur avons pas donné de livres à étudier. Et Nous ne leur avons envoyé avant toi aucun avertisseur"
    },
    {
      "key": 3651,
      "id": "34-45",
      "translation": "Ceux d'avant eux avaient [aussi] démenti (leurs messagers). [Les Mecquois] n'ont pas atteint le dixième de ce que Nous leur avons donné [en force et en richesse]. Ils traitaient Mes Messagers de menteurs. Et quelle réprobation fut la mienne"
    },
    {
      "key": 3652,
      "id": "34-46",
      "translation": "Dis: «Je vous exhorte seulement à une chose: que pour Allah vous vous leviez, par deux ou isolément, et qu'ensuite vous réfléchissiez. Votre compagnon  n'est nullement possédé: il n'est pour vous qu'un avertisseur annonçant un dur châtiment»"
    },
    {
      "key": 3653,
      "id": "34-47",
      "translation": "Dis: «Ce que je vous demande comme salaire, c'est pour vous-mêmes. Car mon salaire n'incombe qu'à Allah. Il est Témoin de toute chose»"
    },
    {
      "key": 3654,
      "id": "34-48",
      "translation": "Dis: «Certes, mon Seigneur lance la Vérité, [à Ses messagers], Il est le Parfait Connaisseur des inconnaissables»"
    },
    {
      "key": 3655,
      "id": "34-49",
      "translation": "Dis: «La vérité [l'Islam] est venue. Et le Faux [la mécréance] ne peut rien commencer ni renouveler»"
    },
    {
      "key": 3656,
      "id": "34-50",
      "translation": "Dis: «Si je m'égare, je ne m'égare qu'à mes dépens; tandis que si je me guide, alors c'est grâce à ce que Mon Seigneur me révèle, car Il est Audient et Proche»"
    },
    {
      "key": 3657,
      "id": "34-51",
      "translation": "Si tu les voyais quand ils seront saisis de peur, - pas d'échappatoire pour eux -, et ils seront saisis de près"
    },
    {
      "key": 3658,
      "id": "34-52",
      "translation": "Ils diront alors: «Nous croyons en lui». - Mais comment atteindront-ils la foi de si loin"
    },
    {
      "key": 3659,
      "id": "34-53",
      "translation": "alors qu'auparavant ils y avaient effectivement mécru et ils offensent l'inconnu à partir d'un endroit éloigné"
    },
    {
      "key": 3660,
      "id": "34-54",
      "translation": "On les empêchera d'atteindre ce qu'ils désirent, comme cela fut fait auparavant avec leurs semblables, car ils se trouvaient dans un doute profond"
    },
    {
      "key": 3661,
      "id": "35-1",
      "translation": "Louange à Allah, Créateur des cieux et de la terre, qui a fait des Anges des messagers dotés de deux, trois ou quatre ailes. Il ajoute à la création ce qu'Il veut, car Allah est Omnipotent"
    },
    {
      "key": 3662,
      "id": "35-2",
      "translation": "Ce qu'Allah accorde en miséricorde aux gens, il n'est personne à pouvoir le retenir. Et ce qu'Il retient, il n'est personne à le relâcher après Lui. Et c'est Lui le Puissant, le Sage"
    },
    {
      "key": 3663,
      "id": "35-3",
      "translation": "O hommes! Rappelez-vous le bienfait d'Allah sur vous: existe-t-il en dehors d'Allah, un créateur qui du ciel et de la terre vous attribue votre subsistance? Point de divinité à part Lui! Comment pouvez-vous vous détourner [de cette vérité]"
    },
    {
      "key": 3664,
      "id": "35-4",
      "translation": "Et s'ils te traitent de menteur, certes on a traité de menteurs des Messagers avant toi. Vers Allah cependant, tout est ramené"
    },
    {
      "key": 3665,
      "id": "35-5",
      "translation": "O hommes! La promesse d'Allah est vérité. Ne laissez pas la vie présente vous tromper, et que le grand trompeur (Satan) ne vous trompe pas à propos d'Allah"
    },
    {
      "key": 3666,
      "id": "35-6",
      "translation": "Le Diable est pour vous un ennemi. Prenez-le donc pour ennemi. Il ne fait qu'appeler ses partisans pour qu'ils soient des gens de la Fournaise"
    },
    {
      "key": 3667,
      "id": "35-7",
      "translation": "Ceux qui ont mécru auront un dur châtiment, tandis que ceux qui croient et accomplissent les bonnes œuvres auront un pardon et une grosse récompense"
    },
    {
      "key": 3668,
      "id": "35-8",
      "translation": "Eh quoi! Celui à qui on a enjolivé sa mauvaise action au point qu'il la voit belle...? - Mais Allah égare qui Il veut, et guide qui Il veut - Que ton âme ne se répande donc pas en regrets pour eux: Allah est Parfaitement Savant de ce qu'ils fabriquent"
    },
    {
      "key": 3669,
      "id": "35-9",
      "translation": "Et c'est Allah qui envoie les vents qui soulèvent un nuage que Nous poussons ensuite vers une contrée morte; puis, Nous redonnons la vie à la terre après sa mort. C'est ainsi que se fera la Résurrection"
    },
    {
      "key": 3670,
      "id": "35-10",
      "translation": "Quiconque veut la puissance (qu'il la cherche auprès d'Allah) car la puissance tout entière est à Allah: vers Lui monte la bonne parole, et Il élève haut la bonne action. Et quand à ceux qui complotent de mauvaises actions, ils auront un dur châtiment. Cependant, leur stratagème est voué à l'échec"
    },
    {
      "key": 3671,
      "id": "35-11",
      "translation": "Et Allah vous a créés de terre, puis d'une goutte de sperme, Il vous a ensuite établis en couples. Nulle femelle ne porte ni ne met bas sans qu'Il le sache. Et aucune existence n'est prolongée ou abrégée sans que cela soit consigné dans un livre. Cela est vraiment facile pour Allah"
    },
    {
      "key": 3672,
      "id": "35-12",
      "translation": "Les deux mers ne sont pas identiques: [l'eau de] celle-ci est potable, douce et agréable à boire, et celle-là est salée, amère. Cependant de chacune vous mangez une chair fraîche, et vous extrayez un ornement que vous portez. Et tu vois le vaisseau fendre l'eau avec bruit, pour que vous cherchiez certains [des produits] de Sa grâce. Peut-être serez-vous reconnaissants"
    },
    {
      "key": 3673,
      "id": "35-13",
      "translation": "Il fait que la nuit pénètre le jour et que le jour pénètre la nuit. Et Il a soumis le soleil et la lune. Chacun d'eux s'achemine vers un terme fixé. Tel est Allah, votre Seigneur: à Lui appartient la royauté, tandis que ceux que vous invoquez, en dehors de Lui, ne sont même pas maîtres de la pellicule d'un noyau de datte"
    },
    {
      "key": 3674,
      "id": "35-14",
      "translation": "Si vous les invoquez, ils n'entendent pas votre invocation; et même s'ils entendaient, ils ne sauraient vous répondre. Et le jour du Jugement ils vont nier votre association. Nul ne peut te donner des nouvelles comme Celui qui est parfaitement informé"
    },
    {
      "key": 3675,
      "id": "35-15",
      "translation": "O hommes, vous êtes les indigents ayant besoin d'Allah, et c'est Allah, Lui qui se dispense de tout et Il est Le Digne de louange"
    },
    {
      "key": 3676,
      "id": "35-16",
      "translation": "S'Il voulait, Il vous ferait disparaître, et ferait surgir une nouvelle création"
    },
    {
      "key": 3677,
      "id": "35-17",
      "translation": "Et cela n'est point difficile pour Allah"
    },
    {
      "key": 3678,
      "id": "35-18",
      "translation": "Or, personne ne portera le fardeau d'autrui. Et si une âme surchargée [de péchés] appelle à l'aide, rien de sa charge ne sera supporté par une autre même si c'est un proche parent. Tu n'avertis en fait, que ceux qui craignent leur Seigneur malgré qu'ils ne Le voient pas, et qui accomplissent la Salât. Et quiconque se purifie ne se purifie que pour lui-même, et vers Allah est la destination"
    },
    {
      "key": 3679,
      "id": "35-19",
      "translation": "L'aveugle et celui qui voit ne sont pas semblables"
    },
    {
      "key": 3680,
      "id": "35-20",
      "translation": "ni les ténèbres et la lumière"
    },
    {
      "key": 3681,
      "id": "35-21",
      "translation": "ni l'ombre et la chaleur ardente"
    },
    {
      "key": 3682,
      "id": "35-22",
      "translation": "De même, ne sont pas semblables les vivants et les morts. Allah fait entendre qui Il veut, alors que toi, tu ne peux faire entendre ceux qui sont dans les tombeaux"
    },
    {
      "key": 3683,
      "id": "35-23",
      "translation": "Tu n'es qu'un avertisseur"
    },
    {
      "key": 3684,
      "id": "35-24",
      "translation": "Nous t'avons envoyé avec la Vérité en tant qu'annonciateur et avertisseur. Il n'est pas une nation qui n'ait déjà eu un avertisseur"
    },
    {
      "key": 3685,
      "id": "35-25",
      "translation": "Et s'ils te traitent de menteur, eh bien, ceux d'avant eux avaient traité (leurs Messagers) de menteurs, cependant que leurs Messagers leur avaient apporté les preuves, les Ecrits et le Livre illuminant"
    },
    {
      "key": 3686,
      "id": "35-26",
      "translation": "Puis J'ai saisi ceux qui ont mécru. Et quelle réprobation fut la Mienne"
    },
    {
      "key": 3687,
      "id": "35-27",
      "translation": "N'as-tu pas vu que, du ciel, Allah fait descendre l'eau? Puis Nous en faisons sortir des fruits de couleurs différentes. Et dans les montagnes, il y a des sillons blancs et rouges, de couleurs différentes, et des roches excessivement noires"
    },
    {
      "key": 3688,
      "id": "35-28",
      "translation": "Il y a pareillement des couleurs différentes, parmi les hommes, les animaux, et les bestiaux. Parmi Ses serviteurs, seuls les savants craignent Allah. Allah est, certes, Puissant et Pardonneur"
    },
    {
      "key": 3689,
      "id": "35-29",
      "translation": "Ceux qui récitent le Livre d'Allah, accomplissent la Salât, et dépensent, en secret et en public de ce que Nous leur avons attribué, espèrent ainsi faire un commerce qui ne périra jamais"
    },
    {
      "key": 3690,
      "id": "35-30",
      "translation": "afin [qu'Allah] les récompense pleinement et leur ajoute de Sa grâce. Il est Pardonneur et Reconnaissant"
    },
    {
      "key": 3691,
      "id": "35-31",
      "translation": "Et ce que Nous t'avons révélé du Livre est la Vérité confirmant ce qui l'a précédé. Certes Allah est Parfaitement Connaisseur et Clairvoyant sur Ses serviteurs"
    },
    {
      "key": 3692,
      "id": "35-32",
      "translation": "Ensuite, Nous fîmes héritiers du Livre ceux de Nos serviteurs que Nous avons choisis. Il en est parmi eux qui font du tort à eux-mêmes, d'autres qui se tiennent sur une voie moyenne, et d'autres avec la permission d'Allah devancent [tous les autres] par leurs bonnes actions; telle est la grâce infinie"
    },
    {
      "key": 3693,
      "id": "35-33",
      "translation": "Les jardins d'Eden où ils entreront, parés de bracelets en or ainsi que de perles; et là, leurs vêtements sont de soie"
    },
    {
      "key": 3694,
      "id": "35-34",
      "translation": "Et ils diront: «Louange à Allah qui a écarté de nous l'affliction. Notre Seigneur est certes Pardonneur et Reconnaissant"
    },
    {
      "key": 3695,
      "id": "35-35",
      "translation": "C'est Lui qui nous a installés, par Sa grâce, dans la Demeure de la stabilité, où nulle fatigue, nulle lassitude ne nous touchent»"
    },
    {
      "key": 3696,
      "id": "35-36",
      "translation": "Et ceux qui ont mécru auront le feu de l'Enfer: on ne les achève pas pour qu'ils meurent; on ne leur allège rien de ses tourments. C'est ainsi que Nous récompensons tout négateur obstiné"
    },
    {
      "key": 3697,
      "id": "35-37",
      "translation": "Et là, ils hurleront: «Seigneur, fais-nous sortir; nous ferons le bien, contrairement à ce que nous faisions». «Ne vous avons-Nous pas donné une vie assez longue pour que celui qui réfléchit réfléchisse? L'avertisseur, cependant, vous était venu. Et bien, goûtez (votre punition). Car pour les injustes, il n'y a pas de secoureur»"
    },
    {
      "key": 3698,
      "id": "35-38",
      "translation": "Allah connaît l'Inconnaissable dans les cieux et la terre. Il connaît le contenu des poitrines"
    },
    {
      "key": 3699,
      "id": "35-39",
      "translation": "C'est Lui qui a fait de vous des successeurs sur terre. Quiconque mécroit, sa mécréance retombera sur lui. Leur mécréance n'ajoute aux mécréants qu'opprobre auprès de leur Seigneur. Leur mécréance n'ajoute que perte aux mécréants"
    },
    {
      "key": 3700,
      "id": "35-40",
      "translation": "Dis: «Voyez-vous vos associés que vous invoquez en dehors d'Allah? Montrez-moi ce qu'ils ont créé de la terre. Ont-ils été associés à la création des cieux? Ou leur avons-Nous apporté un Livre qui contienne des preuves [pour ce qu'ils font?]» Non! Mais ce n'est qu'en tromperie que les injustes se font des promesses les uns aux autres"
    },
    {
      "key": 3701,
      "id": "35-41",
      "translation": "Allah retient les cieux et la terre pour qu'ils ne s'affaissent pas. Et s'ils s'affaissaient, nul autre après Lui ne pourra les retenir. Il est Indulgent et Pardonneur"
    },
    {
      "key": 3702,
      "id": "35-42",
      "translation": "Et ils ont juré solennellement par Allah, que si un avertisseur leur venait, ils seraient certes mieux guidés que n'importe quelle autre communauté. Puis, quand un avertisseur  leur est venu, cela n'a fait qu'accroître leur répulsion"
    },
    {
      "key": 3703,
      "id": "35-43",
      "translation": "par orgueil sur terre et par manœuvre perfide. Cependant, la manœuvre perfide n'enveloppe que ses propres auteurs. Attendent-ils donc un autre sort que celui des Anciens? Or, jamais tu ne trouveras de changement dans la règle d'Allah et jamais tu ne trouveras de déviation dans la règle d'Allah"
    },
    {
      "key": 3704,
      "id": "35-44",
      "translation": "N'ont-ils donc jamais parcouru la terre pour voir ce qu'il est advenu de ceux qui vécurent avant eux et qui étaient plus puissants qu'eux? Et rien, dans les cieux ni sur terre ne saurait réduire l'autorité d'Allah. Car Il est certes Omniscient, Omnipotent"
    },
    {
      "key": 3705,
      "id": "35-45",
      "translation": "Et si Allah s'en prenait aux gens pour ce qu'ils acquièrent, Il ne laisserait à la surface [de la terre] aucun être vivant. Mais Il leur donne un délai jusqu'à un terme fixé. Puis quand leur terme viendra... (Il se saisira d'eux) car Allah est Très Clairvoyant sur Ses serviteurs"
    },
    {
      "key": 3706,
      "id": "36-1",
      "translation": "Yâ Sîn"
    },
    {
      "key": 3707,
      "id": "36-2",
      "translation": "Par le Coran plein de sagesse"
    },
    {
      "key": 3708,
      "id": "36-3",
      "translation": "Tu  es certes du nombre des messagers"
    },
    {
      "key": 3709,
      "id": "36-4",
      "translation": "sur un chemin droit"
    },
    {
      "key": 3710,
      "id": "36-5",
      "translation": "C'est une révélation de la part du Tout Puissant, du Très Miséricordieux"
    },
    {
      "key": 3711,
      "id": "36-6",
      "translation": "pour que tu avertisses un peuple dont les ancêtres n'ont pas été avertis: ils sont donc insouciants"
    },
    {
      "key": 3712,
      "id": "36-7",
      "translation": "En effet, la Parole contre la plupart d'entre eux s'est réalisée: ils ne croiront donc pas"
    },
    {
      "key": 3713,
      "id": "36-8",
      "translation": "Nous mettrons des carcans à leurs cous, et il y en aura jusqu'aux mentons: et voilà qu'ils iront têtes dressées"
    },
    {
      "key": 3714,
      "id": "36-9",
      "translation": "et Nous mettrons une barrière devant eux et une barrière derrière eux; Nous les recouvrirons d'un voile: et voilà qu'ils ne pourront rien voir"
    },
    {
      "key": 3715,
      "id": "36-10",
      "translation": "Cela leur est égal que tu les avertisses ou que tu ne les avertisses pas: ils ne croiront jamais"
    },
    {
      "key": 3716,
      "id": "36-11",
      "translation": "Tu avertis seulement celui qui suit le Rappel (le Coran), et craint le Tout Miséricordieux, malgré qu'il ne Le voit pas. Annonce-lui un pardon et une récompense généreuse"
    },
    {
      "key": 3717,
      "id": "36-12",
      "translation": "C'est Nous qui ressuscitons les morts et écrivons ce qu'ils ont fait [pour l'au-delà] ainsi que leurs traces. Et Nous avons dénombré toute chose dans un registre explicite"
    },
    {
      "key": 3718,
      "id": "36-13",
      "translation": "Donne-leur comme exemple les habitants de la cité, quand lui vinrent les envoyés"
    },
    {
      "key": 3719,
      "id": "36-14",
      "translation": "Quand Nous leur envoyâmes deux [envoyés] et qu'ils les traitèrent de menteurs. Nous [les] renforçâmes alors par un troisième et ils dirent: «Vraiment, nous sommes envoyés à vous»"
    },
    {
      "key": 3720,
      "id": "36-15",
      "translation": "Mais ils [les gens] dirent: «Vous n'êtes que des hommes comme nous. Le Tout Miséricordieux n'a rien fait descendre et vous ne faites que mentir»"
    },
    {
      "key": 3721,
      "id": "36-16",
      "translation": "Ils [les messagers] dirent: «Notre Seigneur sait qu'en vérité nous sommes envoyés à vous"
    },
    {
      "key": 3722,
      "id": "36-17",
      "translation": "et il ne nous incombe que de transmettre clairement (notre message)»"
    },
    {
      "key": 3723,
      "id": "36-18",
      "translation": "Ils dirent: «Nous voyons en vous un mauvais présage. Si vous ne cessez pas, nous vous lapiderons et un douloureux châtiment de notre part vous touchera»"
    },
    {
      "key": 3724,
      "id": "36-19",
      "translation": "Ils dirent: «Votre mauvais présage est avec vous-mêmes. Est-ce que (c'est ainsi que vous agissez) quand on vous [le] rappelle? Mais vous êtes des gens outranciers!»"
    },
    {
      "key": 3725,
      "id": "36-20",
      "translation": "Et du bout de la ville, un homme vint en toute hâte et dit: «O mon peuple, suivez les messagers"
    },
    {
      "key": 3726,
      "id": "36-21",
      "translation": "suivez ceux qui ne vous demandent aucun salaire et qui sont sur la bonne voie"
    },
    {
      "key": 3727,
      "id": "36-22",
      "translation": "et qu'aurais-je à ne pas adorer Celui qui m'a créé? Et c'est vers Lui que vous serez ramenés"
    },
    {
      "key": 3728,
      "id": "36-23",
      "translation": "Prendrais-je en dehors de Lui des divinités? Si le Tout Miséricordieux me veut du mal, leur intercession de me servira à rien et ils ne me sauveront pas"
    },
    {
      "key": 3729,
      "id": "36-24",
      "translation": "Je serai alors dans un égarement évident"
    },
    {
      "key": 3730,
      "id": "36-25",
      "translation": "[Mais] je crois en votre Seigneur. Ecoutez-moi donc»"
    },
    {
      "key": 3731,
      "id": "36-26",
      "translation": "Alors, il [lui] fut dit: «Entre au Paradis». Il dit: «Ah si seulement mon peuple savait"
    },
    {
      "key": 3732,
      "id": "36-27",
      "translation": "en raison de quoi mon Seigneur m'a pardonné et mis au nombre des honorés»"
    },
    {
      "key": 3733,
      "id": "36-28",
      "translation": "Et après lui Nous ne fîmes descendre du ciel aucune armée. Nous ne voulions rien faire descendre sur son peuple"
    },
    {
      "key": 3734,
      "id": "36-29",
      "translation": "Ce ne fut qu'un seul Cri et les voilà éteints"
    },
    {
      "key": 3735,
      "id": "36-30",
      "translation": "Hélas pour les esclaves [les humains]! Jamais il ne leur vient de messager sans qu'ils ne s'en raillent"
    },
    {
      "key": 3736,
      "id": "36-31",
      "translation": "Ne voient-ils pas combien de générations avant eux Nous avons fait périr? Lesquelles ne retourneront jamais parmi eux"
    },
    {
      "key": 3737,
      "id": "36-32",
      "translation": "Et tous sans exception comparaîtront devant Nous"
    },
    {
      "key": 3738,
      "id": "36-33",
      "translation": "Une preuve pour eux est la terre morte, à laquelle Nous redonnons la vie, et d'où Nous faisons sortir des grains dont ils mangent"
    },
    {
      "key": 3739,
      "id": "36-34",
      "translation": "Nous y avons mis des jardins de palmiers et de vignes et y avons fait jaillir des sources"
    },
    {
      "key": 3740,
      "id": "36-35",
      "translation": "afin qu'ils mangent de Ses fruits et de ce que leurs mains ont produit. Ne seront-ils pas reconnaissants"
    },
    {
      "key": 3741,
      "id": "36-36",
      "translation": "Louange à Celui qui a créé tous les couples de ce que la terre fait pousser, d'eux-mêmes, et de ce qu'ils ne savent pas"
    },
    {
      "key": 3742,
      "id": "36-37",
      "translation": "Et une preuve pour eux est la nuit. Nous en écorchons le jour et ils sont alors dans les ténèbres"
    },
    {
      "key": 3743,
      "id": "36-38",
      "translation": "et le soleil court vers un gîte qui lui est assigné; telle est la détermination du Tout Puissant, de l'Omniscient"
    },
    {
      "key": 3744,
      "id": "36-39",
      "translation": "Et la lune, Nous lui avons déterminé des phases jusqu'à ce qu'elle devienne comme la palme vieillie"
    },
    {
      "key": 3745,
      "id": "36-40",
      "translation": "Le soleil ne peut rattraper la lune, ni la nuit devancer le jour; et chacun vogue dans une orbite"
    },
    {
      "key": 3746,
      "id": "36-41",
      "translation": "Et un (autre) signe pour eux est que Nous avons transporté leur descendance sur le bateau chargé"
    },
    {
      "key": 3747,
      "id": "36-42",
      "translation": "et Nous leur créâmes des semblables sur lesquels ils montent"
    },
    {
      "key": 3748,
      "id": "36-43",
      "translation": "Et si Nous le voulons, Nous les noyons; pour eux alors, pas de secoureur et ils ne seront pas sauvés"
    },
    {
      "key": 3749,
      "id": "36-44",
      "translation": "sauf par une miséricorde de Notre part, et à titre de jouissance pour un temps"
    },
    {
      "key": 3750,
      "id": "36-45",
      "translation": "Et quand on leur dit: «Craignez ce qu'il y a devant vous et ce qu'il y a derrière vous afin que vous ayez la miséricorde»"
    },
    {
      "key": 3751,
      "id": "36-46",
      "translation": "Or, pas une preuve ne leur vient, parmi les preuves de leur Seigneur sans qu'ils ne s'en détournent"
    },
    {
      "key": 3752,
      "id": "36-47",
      "translation": "Et quand on leur dit: «Dépensez de ce qu'Allah vous a attribué», ceux qui ont mécru disent à ceux qui ont cru: «Nourrirons-nous quelqu'un qu'Allah aurait nourri s'Il l'avait voulu? Vous n'êtes que dans un égarement évident»"
    },
    {
      "key": 3753,
      "id": "36-48",
      "translation": "Et ils disent: «A quand cette promesse si vous êtes véridiques?»"
    },
    {
      "key": 3754,
      "id": "36-49",
      "translation": "Ils n'attendent qu'un seul Cri qui les saisira alors qu'ils seront en train de disputer"
    },
    {
      "key": 3755,
      "id": "36-50",
      "translation": "Ils ne pourront donc ni faire de testament, ni retourner chez leurs familles"
    },
    {
      "key": 3756,
      "id": "36-51",
      "translation": "Et on soufflera dans la Trompe, et voilà que, des tombes, ils se précipiteront vers leur Seigneur"
    },
    {
      "key": 3757,
      "id": "36-52",
      "translation": "en disant: «Malheur à nous! Qui nous a ressuscités de là où nous dormions?» C'est ce que le Tout Miséricordieux avait promis; et les Messagers avaient dit vrai"
    },
    {
      "key": 3758,
      "id": "36-53",
      "translation": "Ce ne sera qu'un seul Cri, et voilà qu'ils seront tous amenés devant Nous"
    },
    {
      "key": 3759,
      "id": "36-54",
      "translation": "Ce jour-là, aucune âme ne sera lésée en rien. Et vous ne serez rétribués que selon ce que vous faisiez"
    },
    {
      "key": 3760,
      "id": "36-55",
      "translation": "Les gens du Paradis seront, ce jour-là, dans une occupation qui les remplit de bonheur"
    },
    {
      "key": 3761,
      "id": "36-56",
      "translation": "eux et leurs épouses sont sous des ombrages, accoudés sur les divans"
    },
    {
      "key": 3762,
      "id": "36-57",
      "translation": "Là ils auront des fruits et ils auront ce qu'ils réclameront"
    },
    {
      "key": 3763,
      "id": "36-58",
      "translation": "«Salâm» [paix et salut]! Parole de la part d'un Seigneur Très Miséricordieux"
    },
    {
      "key": 3764,
      "id": "36-59",
      "translation": "«O injustes! Tenez-vous à l'écart ce jour-là"
    },
    {
      "key": 3765,
      "id": "36-60",
      "translation": "Ne vous ai-Je pas engagés, enfants d'Adam, à ne pas adorer le Diable? Car il est vraiment pour vous un ennemi déclaré"
    },
    {
      "key": 3766,
      "id": "36-61",
      "translation": "et [ne vous ai-Je pas engagés] à M'adorer? Voilà un chemin bien droit"
    },
    {
      "key": 3767,
      "id": "36-62",
      "translation": "Et il a très certainement égaré un grand nombre d'entre vous. Ne raisonniez-vous donc pas"
    },
    {
      "key": 3768,
      "id": "36-63",
      "translation": "Voici l'Enfer qu'on vous promettait"
    },
    {
      "key": 3769,
      "id": "36-64",
      "translation": "Brûlez-y aujourd'hui, pour avoir mécru»"
    },
    {
      "key": 3770,
      "id": "36-65",
      "translation": "Ce jour-là, Nous scellerons leurs bouches, tandis que leurs mains Nous parleront et que leurs jambes témoigneront de ce qu'ils avaient accompli"
    },
    {
      "key": 3771,
      "id": "36-66",
      "translation": "Et si Nous voulions, Nous effacerions leurs yeux et ils courront vers le chemin. Mais comment alors pourront-ils voir"
    },
    {
      "key": 3772,
      "id": "36-67",
      "translation": "Et si Nous voulions, Nous les métamorphoserions sur place; alors ils ne sauront ni avancer ni revenir"
    },
    {
      "key": 3773,
      "id": "36-68",
      "translation": "A quiconque Nous accordons une longue vie, Nous faisons baisser sa forme. Ne comprendront-ils donc pas"
    },
    {
      "key": 3774,
      "id": "36-69",
      "translation": "Nous ne lui avons pas enseigné la poésie; cela ne lui convient pas non plus. Ceci n'est qu'un rappel et une Lecture [Coran] claire"
    },
    {
      "key": 3775,
      "id": "36-70",
      "translation": "pour qu'il avertisse celui qui est vivant et que la Parole se réalise contre les mécréants"
    },
    {
      "key": 3776,
      "id": "36-71",
      "translation": "Ne voient-ils donc pas que, parmi ce que Nos mains ont fait, Nous leur avons créé des bestiaux dont ils sont propriétaires"
    },
    {
      "key": 3777,
      "id": "36-72",
      "translation": "et Nous les leur avons soumis: certains leur servent de monture et d'autres de nourriture"
    },
    {
      "key": 3778,
      "id": "36-73",
      "translation": "et ils en retirent d'autres utilités et des boissons. Ne seront-ils donc pas reconnaissants"
    },
    {
      "key": 3779,
      "id": "36-74",
      "translation": "Et ils adoptèrent des divinités en dehors d'Allah, dans l'espoir d'être secourus"
    },
    {
      "key": 3780,
      "id": "36-75",
      "translation": "Celles-ci ne pourront pas les secourir, elles formeront au contraire une armée dressée contre eux"
    },
    {
      "key": 3781,
      "id": "36-76",
      "translation": "Que leurs paroles ne t'affligent donc pas! Nous savons ce qu'ils cachent et ce qu'ils divulguent"
    },
    {
      "key": 3782,
      "id": "36-77",
      "translation": "L'homme ne voit-il pas que Nous l'avons créé d'une goutte de sperme? Et le voilà [devenu] un adversaire déclaré"
    },
    {
      "key": 3783,
      "id": "36-78",
      "translation": "Il cite pour Nous un exemple, tandis qu'il oublie sa propre création; il dit: «Qui va redonner la vie à des ossements une fois réduits en poussière?»"
    },
    {
      "key": 3784,
      "id": "36-79",
      "translation": "Dis: «Celui qui les a créés une première fois, leur redonnera la vie. Il Se connaît parfaitement à toute création"
    },
    {
      "key": 3785,
      "id": "36-80",
      "translation": "c'est Lui qui, de l'arbre vert, a fait pour vous du feu, et voilà que de cela vous allumez"
    },
    {
      "key": 3786,
      "id": "36-81",
      "translation": "Celui qui a créé les cieux et la terre ne sera-t-Il pas capable de créer leur pareil? Oh que si! et Il est le grand Créateur, l'Omniscient"
    },
    {
      "key": 3787,
      "id": "36-82",
      "translation": "Quand Il veut une chose, Son commandement consiste à dire: «Sois», et c'est"
    },
    {
      "key": 3788,
      "id": "36-83",
      "translation": "Louange donc, à Celui qui détient en Sa main la royauté sur toute chose! Et c'est vers Lui que vous serez ramenés"
    },
    {
      "key": 3789,
      "id": "37-1",
      "translation": "Par ceux qui sont rangés en rangs"
    },
    {
      "key": 3790,
      "id": "37-2",
      "translation": "Par ceux qui poussent (les nuages) avec force"
    },
    {
      "key": 3791,
      "id": "37-3",
      "translation": "Par ceux qui récitent, en rappel"
    },
    {
      "key": 3792,
      "id": "37-4",
      "translation": "«Votre Dieu est en vérité unique"
    },
    {
      "key": 3793,
      "id": "37-5",
      "translation": "le Seigneur des cieux et de la terre et de ce qui existe entre eux et Seigneur des Levants»"
    },
    {
      "key": 3794,
      "id": "37-6",
      "translation": "Nous avons décoré le ciel le plus proche d'un décor: les étoiles"
    },
    {
      "key": 3795,
      "id": "37-7",
      "translation": "afin de le protéger contre tout diable rebelle"
    },
    {
      "key": 3796,
      "id": "37-8",
      "translation": "Ils ne pourront être à l'écoute des dignitaires suprêmes [les Anges]; car ils seront harcelés de tout côté"
    },
    {
      "key": 3797,
      "id": "37-9",
      "translation": "et refoulés. Et ils auront un châtiment perpétuel"
    },
    {
      "key": 3798,
      "id": "37-10",
      "translation": "Sauf celui qui saisit au vol quelque [information]; il est alors pourchassé par un météore transperçant"
    },
    {
      "key": 3799,
      "id": "37-11",
      "translation": "Demande-leur s'ils sont plus difficiles à créer que ceux que Nous avons créés? Car Nous les avons créés de boue collante"
    },
    {
      "key": 3800,
      "id": "37-12",
      "translation": "Mais tu t'étonnes, et ils se moquent"
    },
    {
      "key": 3801,
      "id": "37-13",
      "translation": "Et quand on le leur rappelle (le Coran), ils ne se rappellent pas"
    },
    {
      "key": 3802,
      "id": "37-14",
      "translation": "et quand ils voient un prodige, ils cherchent à s'en moquer"
    },
    {
      "key": 3803,
      "id": "37-15",
      "translation": "et disent: «Ceci n'est que magie évidente"
    },
    {
      "key": 3804,
      "id": "37-16",
      "translation": "Lorsque nous serons morts et que nous deviendrons poussière et ossements, serons-nous ressuscités"
    },
    {
      "key": 3805,
      "id": "37-17",
      "translation": "ainsi que nos premiers ancêtres?»"
    },
    {
      "key": 3806,
      "id": "37-18",
      "translation": "Dis: «Oui! et vous vous humilierez»"
    },
    {
      "key": 3807,
      "id": "37-19",
      "translation": "Il n'y aura qu'un seul Cri, et voilà qu'ils regarderont"
    },
    {
      "key": 3808,
      "id": "37-20",
      "translation": "et ils diront: «Malheur à nous! c'est le jour de la Rétribution»"
    },
    {
      "key": 3809,
      "id": "37-21",
      "translation": "«C'est le jour du Jugement que vous traitiez de mensonge»"
    },
    {
      "key": 3810,
      "id": "37-22",
      "translation": "«Rassemblez les injustes et leurs épouses et tout ce qu'ils adoraient"
    },
    {
      "key": 3811,
      "id": "37-23",
      "translation": "en dehors d'Allah. Puis conduisez-les au chemin de la Fournaise"
    },
    {
      "key": 3812,
      "id": "37-24",
      "translation": "Et arrêtez-les: car ils doivent être interrogés»"
    },
    {
      "key": 3813,
      "id": "37-25",
      "translation": "«Pourquoi ne vous portez-vous pas secours mutuellement?»"
    },
    {
      "key": 3814,
      "id": "37-26",
      "translation": "Mais ce jour-là, ils seront complètement soumis"
    },
    {
      "key": 3815,
      "id": "37-27",
      "translation": "et les uns se tourneront vers les autres s'interrogeant mutuellement"
    },
    {
      "key": 3816,
      "id": "37-28",
      "translation": "Ils diront: «C'est vous qui nous forciez (à la mécréance)»"
    },
    {
      "key": 3817,
      "id": "37-29",
      "translation": "«C'est vous plutôt (diront les chefs) qui ne vouliez pas croire"
    },
    {
      "key": 3818,
      "id": "37-30",
      "translation": "Et nous n'avions aucun pouvoir sur vous. C'est vous plutôt qui étiez des gens transgresseurs"
    },
    {
      "key": 3819,
      "id": "37-31",
      "translation": "La parole de notre Seigneur s'est donc réalisée contre nous; certes, nous allons goûter [au châtiment]"
    },
    {
      "key": 3820,
      "id": "37-32",
      "translation": "Nous vous avons induits en erreur car, en vérité, nous étions égarés nous-mêmes»"
    },
    {
      "key": 3821,
      "id": "37-33",
      "translation": "Ce jour-là donc, ils seront tous associés dans le châtiment"
    },
    {
      "key": 3822,
      "id": "37-34",
      "translation": "Ainsi traitons-Nous les criminels"
    },
    {
      "key": 3823,
      "id": "37-35",
      "translation": "Quand on leur disait: «Point de divinité à part Allah», ils se gonflaient d'orgueil"
    },
    {
      "key": 3824,
      "id": "37-36",
      "translation": "et disaient: «Allons-nous abandonner nos divinités pour un poète fou?»"
    },
    {
      "key": 3825,
      "id": "37-37",
      "translation": "Il est plutôt venu avec la Vérité et il a confirmé les messagers (précédents)"
    },
    {
      "key": 3826,
      "id": "37-38",
      "translation": "Vous allez certes, goûter au châtiment douloureux"
    },
    {
      "key": 3827,
      "id": "37-39",
      "translation": "Et vous ne serez rétribués que selon ce que vous œuvriez"
    },
    {
      "key": 3828,
      "id": "37-40",
      "translation": "sauf les serviteurs élus d'Allah"
    },
    {
      "key": 3829,
      "id": "37-41",
      "translation": "Ceux-là auront une rétribution bien connue"
    },
    {
      "key": 3830,
      "id": "37-42",
      "translation": "des fruits, et ils seront honorés"
    },
    {
      "key": 3831,
      "id": "37-43",
      "translation": "dans les Jardins du délice"
    },
    {
      "key": 3832,
      "id": "37-44",
      "translation": "sur des lits, face à face"
    },
    {
      "key": 3833,
      "id": "37-45",
      "translation": "On fera circuler entre eux une coupe d'eau remplie à une source"
    },
    {
      "key": 3834,
      "id": "37-46",
      "translation": "blanche, savoureuse à boire"
    },
    {
      "key": 3835,
      "id": "37-47",
      "translation": "Elle n'offusquera point leur raison et ne les enivrera pas"
    },
    {
      "key": 3836,
      "id": "37-48",
      "translation": "Et Ils auront auprès d'eux des belles aux grands yeux, au regard chaste"
    },
    {
      "key": 3837,
      "id": "37-49",
      "translation": "semblables au blanc bien préservé de l'œuf"
    },
    {
      "key": 3838,
      "id": "37-50",
      "translation": "Puis les uns se tourneront vers les autres s'interrogeant mutuellement"
    },
    {
      "key": 3839,
      "id": "37-51",
      "translation": "L'un d'eux dira: «J'avais un compagnon"
    },
    {
      "key": 3840,
      "id": "37-52",
      "translation": "qui disait: «Es-tu vraiment de ceux qui croient"
    },
    {
      "key": 3841,
      "id": "37-53",
      "translation": "Est-ce que quand nous mourrons et serons poussière et ossements, nous aurons à rendre des comptes?»"
    },
    {
      "key": 3842,
      "id": "37-54",
      "translation": "Il dira: «Est-ce que vous voudriez regarder d'en haut?»"
    },
    {
      "key": 3843,
      "id": "37-55",
      "translation": "Alors il regardera d'en haut et il le verra en plein dans la Fournaise"
    },
    {
      "key": 3844,
      "id": "37-56",
      "translation": "et dira: «Par Allah! Tu as bien failli causer ma perte"
    },
    {
      "key": 3845,
      "id": "37-57",
      "translation": "et sans le bienfait de mon Seigneur, j'aurais certainement été du nombre de ceux qu'on traîne [au supplice]"
    },
    {
      "key": 3846,
      "id": "37-58",
      "translation": "N'est-il pas vrai que nous ne mourrons"
    },
    {
      "key": 3847,
      "id": "37-59",
      "translation": "que de notre première mort et que nous ne serons pas châtiés?»"
    },
    {
      "key": 3848,
      "id": "37-60",
      "translation": "C'est cela, certes, le grand succès"
    },
    {
      "key": 3849,
      "id": "37-61",
      "translation": "C'est pour une chose pareille que doivent ouvrer ceux qui ouvrent"
    },
    {
      "key": 3850,
      "id": "37-62",
      "translation": "Est-ce que ceci est meilleur comme séjour, ou l'arbre de Zaqqûm"
    },
    {
      "key": 3851,
      "id": "37-63",
      "translation": "Nous l'avons assigné en épreuve aux injustes"
    },
    {
      "key": 3852,
      "id": "37-64",
      "translation": "C'est un arbre qui sort du fond de la Fournaise"
    },
    {
      "key": 3853,
      "id": "37-65",
      "translation": "Ses fruits sont comme des têtes de diables"
    },
    {
      "key": 3854,
      "id": "37-66",
      "translation": "Ils doivent certainement en manger et ils doivent s'en remplir le ventre"
    },
    {
      "key": 3855,
      "id": "37-67",
      "translation": "Ensuite ils auront par-dessus une mixture d'eau bouillante"
    },
    {
      "key": 3856,
      "id": "37-68",
      "translation": "Puis leur retour sera vers la Fournaise"
    },
    {
      "key": 3857,
      "id": "37-69",
      "translation": "C'est qu'ils ont trouvé leurs ancêtres dans l'égarement"
    },
    {
      "key": 3858,
      "id": "37-70",
      "translation": "et les voilà courant sur leurs traces"
    },
    {
      "key": 3859,
      "id": "37-71",
      "translation": "En effet, avant eux, la plupart des anciens se sont égarés"
    },
    {
      "key": 3860,
      "id": "37-72",
      "translation": "Et Nous avions certes envoyé parmi eux des avertisseurs"
    },
    {
      "key": 3861,
      "id": "37-73",
      "translation": "Regarde donc ce qu'il est advenu de ceux qui ont été avertis"
    },
    {
      "key": 3862,
      "id": "37-74",
      "translation": "Exception faite des élus, parmi les serviteurs d'Allah"
    },
    {
      "key": 3863,
      "id": "37-75",
      "translation": "Noé, en effet, fit appel à Nous qui sommes le Meilleur Répondeur (qui exauce les prières)"
    },
    {
      "key": 3864,
      "id": "37-76",
      "translation": "Et Nous le sauvâmes, lui et sa famille, de la grande angoisse"
    },
    {
      "key": 3865,
      "id": "37-77",
      "translation": "et Nous fîmes de sa descendance les seuls survivants"
    },
    {
      "key": 3866,
      "id": "37-78",
      "translation": "et Nous avons perpétué son souvenir dans la postérité"
    },
    {
      "key": 3867,
      "id": "37-79",
      "translation": "Paix sur Noé dans tout l'univers"
    },
    {
      "key": 3868,
      "id": "37-80",
      "translation": "Ainsi récompensons-Nous les bienfaisants"
    },
    {
      "key": 3869,
      "id": "37-81",
      "translation": "Il était, certes, un de Nos serviteurs croyants"
    },
    {
      "key": 3870,
      "id": "37-82",
      "translation": "Ensuite Nous noyâmes les autres"
    },
    {
      "key": 3871,
      "id": "37-83",
      "translation": "Du nombre de ses coreligionnaires, certes, fut Abraham"
    },
    {
      "key": 3872,
      "id": "37-84",
      "translation": "Quand il vint à son Seigneur avec un cœur sain"
    },
    {
      "key": 3873,
      "id": "37-85",
      "translation": "Quand il dit à son père et à son peuple: «Qu'est-ce que vous adorez?»"
    },
    {
      "key": 3874,
      "id": "37-86",
      "translation": "Cherchez-vous dans votre égarement, des divinités en dehors d'Allah"
    },
    {
      "key": 3875,
      "id": "37-87",
      "translation": "Que pensez-vous du Seigneur de l'univers?»"
    },
    {
      "key": 3876,
      "id": "37-88",
      "translation": "Puis, il jeta un regard attentif sur les étoiles"
    },
    {
      "key": 3877,
      "id": "37-89",
      "translation": "et dit: «Je suis malade»"
    },
    {
      "key": 3878,
      "id": "37-90",
      "translation": "Ils lui tournèrent le dos et s'en allèrent"
    },
    {
      "key": 3879,
      "id": "37-91",
      "translation": "Alors il se glissa vers leurs divinités et dit: «Ne mangez-vous pas"
    },
    {
      "key": 3880,
      "id": "37-92",
      "translation": "Qu'avez-vous à ne pas parler?»"
    },
    {
      "key": 3881,
      "id": "37-93",
      "translation": "Puis il se mit furtivement à les frapper de sa main droite"
    },
    {
      "key": 3882,
      "id": "37-94",
      "translation": "Alors [les gens] vinrent à lui en courant"
    },
    {
      "key": 3883,
      "id": "37-95",
      "translation": "Il [leur] dit: «Adorez-vous ce que vous-mêmes sculptez"
    },
    {
      "key": 3884,
      "id": "37-96",
      "translation": "alors que c'est Allah qui vous a créés, vous et ce que vous fabriquez?»"
    },
    {
      "key": 3885,
      "id": "37-97",
      "translation": "Ils dirent: «Qu'on lui construise un four et qu'on le lance dans la fournaise!»"
    },
    {
      "key": 3886,
      "id": "37-98",
      "translation": "Ils voulurent lui jouer un mauvais tour; mais ce sont eux que Nous mîmes à bas"
    },
    {
      "key": 3887,
      "id": "37-99",
      "translation": "Et il dit: «Moi, je pars vers mon Seigneur et Il me guidera"
    },
    {
      "key": 3888,
      "id": "37-100",
      "translation": "Seigneur, fais-moi don d'une [progéniture] d'entre les vertueux»"
    },
    {
      "key": 3889,
      "id": "37-101",
      "translation": "Nous lui fîmes donc la bonne annonce d'un garçon longanime"
    },
    {
      "key": 3890,
      "id": "37-102",
      "translation": "Puis quand celui-ci fut en âge de l'accompagner, [Abraham] dit: «O mon fils, je me vois en songe en train de t'immoler. Vois donc ce que tu en penses».  dit: «O mon cher père, fais ce qui t'es commandé: tu me trouveras, s'il plaît à Allah, du nombre des endurants»"
    },
    {
      "key": 3891,
      "id": "37-103",
      "translation": "Puis quand tous deux se furent soumis (à l'ordre d'Allah) et qu'il l'eut jeté sur le front"
    },
    {
      "key": 3892,
      "id": "37-104",
      "translation": "voilà que Nous l'appelâmes «Abraham"
    },
    {
      "key": 3893,
      "id": "37-105",
      "translation": "Tu as confirmé la vision. C'est ainsi que Nous récompensons les bienfaisants»"
    },
    {
      "key": 3894,
      "id": "37-106",
      "translation": "C'était là certes, l'épreuve manifeste"
    },
    {
      "key": 3895,
      "id": "37-107",
      "translation": "Et Nous le rançonnâmes d'une immolation généreuse"
    },
    {
      "key": 3896,
      "id": "37-108",
      "translation": "Et Nous perpétuâmes son renom dans la postérité"
    },
    {
      "key": 3897,
      "id": "37-109",
      "translation": "«Paix sur Abraham»"
    },
    {
      "key": 3898,
      "id": "37-110",
      "translation": "Ainsi récompensons-Nous les bienfaisants"
    },
    {
      "key": 3899,
      "id": "37-111",
      "translation": "car il était de Nos serviteurs croyants"
    },
    {
      "key": 3900,
      "id": "37-112",
      "translation": "Nous lui fîmes la bonne annonce d'Isaac comme prophète d'entre les gens vertueux"
    },
    {
      "key": 3901,
      "id": "37-113",
      "translation": "Et Nous le bénîmes ainsi que Isaac. Parmi leurs descendances il y a [l'homme] de bien et celui qui est manifestement injuste envers lui-même"
    },
    {
      "key": 3902,
      "id": "37-114",
      "translation": "Et Nous accordâmes certes à Moïse et Aaron des faveurs"
    },
    {
      "key": 3903,
      "id": "37-115",
      "translation": "Et les sauvâmes ainsi que leur peuple, de la grande angoisse"
    },
    {
      "key": 3904,
      "id": "37-116",
      "translation": "et les secourûmes, et ils furent eux les vainqueurs"
    },
    {
      "key": 3905,
      "id": "37-117",
      "translation": "Et Nous leur apportâmes le livre explicite"
    },
    {
      "key": 3906,
      "id": "37-118",
      "translation": "et les guidâmes vers le droit chemin"
    },
    {
      "key": 3907,
      "id": "37-119",
      "translation": "Et Nous perpétuâmes leur renom dans la postérité"
    },
    {
      "key": 3908,
      "id": "37-120",
      "translation": "«Paix sur Moïse et Aaron»"
    },
    {
      "key": 3909,
      "id": "37-121",
      "translation": "Ainsi récompensons-Nous les bienfaisants"
    },
    {
      "key": 3910,
      "id": "37-122",
      "translation": "car ils étaient du nombre de Nos serviteurs croyants"
    },
    {
      "key": 3911,
      "id": "37-123",
      "translation": "Elie était, certes, du nombre des Messagers"
    },
    {
      "key": 3912,
      "id": "37-124",
      "translation": "Quand il dit à son peuple: «Ne craignez-vous pas [Allah]?»"
    },
    {
      "key": 3913,
      "id": "37-125",
      "translation": "Invoquerez-vous Ball (une idole) et délaisserez-vous le Meilleur des créateurs"
    },
    {
      "key": 3914,
      "id": "37-126",
      "translation": "Allah, votre Seigneur et le Seigneur de vos plus anciens ancêtres?»"
    },
    {
      "key": 3915,
      "id": "37-127",
      "translation": "Ils le traitèrent de menteur. Et bien, ils seront emmenées (au châtiment)"
    },
    {
      "key": 3916,
      "id": "37-128",
      "translation": "Exception faite des serviteurs élus d'Allah"
    },
    {
      "key": 3917,
      "id": "37-129",
      "translation": "Et Nous perpétuâmes son renom dans la postérité"
    },
    {
      "key": 3918,
      "id": "37-130",
      "translation": "«Paix sur Elie et ses adeptes»"
    },
    {
      "key": 3919,
      "id": "37-131",
      "translation": "Ainsi récompensons-Nous les bienfaisants"
    },
    {
      "key": 3920,
      "id": "37-132",
      "translation": "car il était du nombre de Nos serviteurs croyants"
    },
    {
      "key": 3921,
      "id": "37-133",
      "translation": "Et Loût était, certes, du nombre des Messagers"
    },
    {
      "key": 3922,
      "id": "37-134",
      "translation": "Quand Nous le sauvâmes, lui et sa famille, tout entière"
    },
    {
      "key": 3923,
      "id": "37-135",
      "translation": "sauf une vieille femme qui devait disparaître avec les autres"
    },
    {
      "key": 3924,
      "id": "37-136",
      "translation": "Et Nous détruisîmes les autres"
    },
    {
      "key": 3925,
      "id": "37-137",
      "translation": "Et vous passez certainement auprès d'eux le matin"
    },
    {
      "key": 3926,
      "id": "37-138",
      "translation": "et la nuit. Ne raisonnez-vous donc pas"
    },
    {
      "key": 3927,
      "id": "37-139",
      "translation": "Jonas était certes, du nombre des Messagers"
    },
    {
      "key": 3928,
      "id": "37-140",
      "translation": "Quand il s'enfuit vers le bateau comble"
    },
    {
      "key": 3929,
      "id": "37-141",
      "translation": "Il prit part au tirage au sort qui le désigna pour être jeté [à la mer]"
    },
    {
      "key": 3930,
      "id": "37-142",
      "translation": "Le poisson l'avala alors qu'il était blâmable"
    },
    {
      "key": 3931,
      "id": "37-143",
      "translation": "S'il n'avait pas été parmi ceux qui glorifient Allah"
    },
    {
      "key": 3932,
      "id": "37-144",
      "translation": "il serait demeuré dans son ventre jusqu'au jour où l'on sera ressuscité"
    },
    {
      "key": 3933,
      "id": "37-145",
      "translation": "Nous le jetâmes sur la terre nue, indisposé qu'il était"
    },
    {
      "key": 3934,
      "id": "37-146",
      "translation": "Et Nous fîmes pousser au-dessus de lui un plant de courge"
    },
    {
      "key": 3935,
      "id": "37-147",
      "translation": "et l'envoyâmes ensuite (comme prophète) vers cent mille hommes ou plus"
    },
    {
      "key": 3936,
      "id": "37-148",
      "translation": "Ils crurent, et nous leur donnâmes jouissance de la vie pour un temps"
    },
    {
      "key": 3937,
      "id": "37-149",
      "translation": "Pose-leur donc la question: «Ton Seigneur aurait-Il des filles et eux des fils"
    },
    {
      "key": 3938,
      "id": "37-150",
      "translation": "Ou bien avons-Nous créé des Anges de sexe féminin, et en sont-ils témoins?»"
    },
    {
      "key": 3939,
      "id": "37-151",
      "translation": "Certes, ils disent dans leur mensonge"
    },
    {
      "key": 3940,
      "id": "37-152",
      "translation": "«Allah a engendré» mais ce sont certainement des menteurs"
    },
    {
      "key": 3941,
      "id": "37-153",
      "translation": "Aurait-Il choisi des filles de préférence à des fils"
    },
    {
      "key": 3942,
      "id": "37-154",
      "translation": "Qu'avez-vous donc à juger ainsi"
    },
    {
      "key": 3943,
      "id": "37-155",
      "translation": "Ne réfléchissez-vous donc pas"
    },
    {
      "key": 3944,
      "id": "37-156",
      "translation": "Ou avez-vous un argument évident"
    },
    {
      "key": 3945,
      "id": "37-157",
      "translation": "Apportez donc votre Livre si vous êtes véridiques!»"
    },
    {
      "key": 3946,
      "id": "37-158",
      "translation": "Et ils ont établi entre Lui et les djinns une parenté, alors que les djinns savent bien qu'ils [les mécréants] vont être emmenés (pour le châtiment)"
    },
    {
      "key": 3947,
      "id": "37-159",
      "translation": "Gloire à Allah. Il est au-dessus de ce qu'ils décrivent"
    },
    {
      "key": 3948,
      "id": "37-160",
      "translation": "Exception faite des serviteurs élus d'Allah"
    },
    {
      "key": 3949,
      "id": "37-161",
      "translation": "En vérité, vous et tout ce que vous adorez"
    },
    {
      "key": 3950,
      "id": "37-162",
      "translation": "ne pourrez tenter [personne]"
    },
    {
      "key": 3951,
      "id": "37-163",
      "translation": "excepté celui qui sera brûlé dans la Fournaise"
    },
    {
      "key": 3952,
      "id": "37-164",
      "translation": "Il n'y en a pas un, parmi nous, qui n'ait une place connue"
    },
    {
      "key": 3953,
      "id": "37-165",
      "translation": "nous sommes certes, les rangés en rangs"
    },
    {
      "key": 3954,
      "id": "37-166",
      "translation": "et c'est nous certes, qui célébrons la gloire [d'Allah]"
    },
    {
      "key": 3955,
      "id": "37-167",
      "translation": "Même s'ils disaient"
    },
    {
      "key": 3956,
      "id": "37-168",
      "translation": "«Si nous avions eu un Rappel de [nos] ancêtres"
    },
    {
      "key": 3957,
      "id": "37-169",
      "translation": "nous aurions été certes les serviteurs élus d'Allah"
    },
    {
      "key": 3958,
      "id": "37-170",
      "translation": "Ils y ont mécru et ils sauront bientôt"
    },
    {
      "key": 3959,
      "id": "37-171",
      "translation": "En effet, Notre Parole a déjà été donnée à Nos serviteurs, les Messagers"
    },
    {
      "key": 3960,
      "id": "37-172",
      "translation": "que ce sont eux qui seront secourus"
    },
    {
      "key": 3961,
      "id": "37-173",
      "translation": "et que Nos soldats auront le dessus"
    },
    {
      "key": 3962,
      "id": "37-174",
      "translation": "Eloigne-toi d'eux, jusqu'à un certain temps"
    },
    {
      "key": 3963,
      "id": "37-175",
      "translation": "et observe-les: ils verront bientôt"
    },
    {
      "key": 3964,
      "id": "37-176",
      "translation": "Quoi! est-ce Notre châtiment qu'ils cherchent à hâter"
    },
    {
      "key": 3965,
      "id": "37-177",
      "translation": "Quand il tombera dans leur place, ce sera alors un mauvais matin pour ceux qu'on a avertis"
    },
    {
      "key": 3966,
      "id": "37-178",
      "translation": "Et éloigne-toi d'eux jusqu'à un certain temps"
    },
    {
      "key": 3967,
      "id": "37-179",
      "translation": "et observe; ils verront bientôt"
    },
    {
      "key": 3968,
      "id": "37-180",
      "translation": "Gloire à ton Seigneur, le Seigneur de la puissance. Il est au-dessus de ce qu'ils décrivent"
    },
    {
      "key": 3969,
      "id": "37-181",
      "translation": "Et paix sur les Messagers"
    },
    {
      "key": 3970,
      "id": "37-182",
      "translation": "et louange à Allah, Seigneur de l'univers"
    },
    {
      "key": 3971,
      "id": "38-1",
      "translation": "Sâd. Par le Coran, au renom glorieux (dikr)"
    },
    {
      "key": 3972,
      "id": "38-2",
      "translation": "Ceux qui ont mécru sont plutôt dans l'orgueil et le schisme"
    },
    {
      "key": 3973,
      "id": "38-3",
      "translation": "Que de générations avant eux avons-Nous fait périr, qui ont crié, hélas, quand il n'était plus temps d'échapper"
    },
    {
      "key": 3974,
      "id": "38-4",
      "translation": "Et ils (les Mecquois) s'étonnèrent qu'un avertisseur parmi eux leur soit venu, et les infidèles disent: «C'est un magicien et un grand menteur"
    },
    {
      "key": 3975,
      "id": "38-5",
      "translation": "Réduira-t-il les divinités à un Seul Dieu? Voilà une chose vraiment étonnante»"
    },
    {
      "key": 3976,
      "id": "38-6",
      "translation": "Et leurs notables partirent en disant: «Allez-vous en, et restez constants à vos dieux: c'est là vraiment une chose souhaitable"
    },
    {
      "key": 3977,
      "id": "38-7",
      "translation": "Nous n'avons pas entendu cela dans la dernière religion (le Christianisme); ce n'est en vérité que pure invention"
    },
    {
      "key": 3978,
      "id": "38-8",
      "translation": "Quoi! C'est sur lui, parmi nous, qu'on aurait fait descendre le Rappel [le Coran]?» Plutôt ils sont dans le doute au sujet de Mon message. Ou plutôt ils n'ont pas encore goûté à Mon châtiment"
    },
    {
      "key": 3979,
      "id": "38-9",
      "translation": "Ou bien détiennent-ils les trésors de la miséricorde de ton Seigneur, le Puissant, le Dispensateur par excellence"
    },
    {
      "key": 3980,
      "id": "38-10",
      "translation": "Ou bien ont-ils le royaume des cieux et de la terre et de ce qui existe entre eux? Eh bien, qu'ils y montent par n'importe quel moyen"
    },
    {
      "key": 3981,
      "id": "38-11",
      "translation": "Une armée de coalisés qui, ici-même, sera mise en déroute"
    },
    {
      "key": 3982,
      "id": "38-12",
      "translation": "Avant eux, le peuple de Noé, les Aad et Pharaon l'homme aux pals (ou aux Pyramides)"
    },
    {
      "key": 3983,
      "id": "38-13",
      "translation": "et les Tamûd, le peuple de Lot, et les gens d'Al-Aykah, (ont tous démenti leurs Messagers). Voilà les coalisés"
    },
    {
      "key": 3984,
      "id": "38-14",
      "translation": "Il n'en est aucun qui n'ait traité les Messagers de menteurs. Et bien, Ma punition s'est avérée contre eux"
    },
    {
      "key": 3985,
      "id": "38-15",
      "translation": "Ceux-ci n'attendant qu'un seul Cri, sans répétition"
    },
    {
      "key": 3986,
      "id": "38-16",
      "translation": "Et ils disent: «Seigneur, hâte-nous notre part avant le jour des Comptes»"
    },
    {
      "key": 3987,
      "id": "38-17",
      "translation": "Endure ce qu'ils disent; et rappelle-toi David, Notre serviteur, doué de force [dans l'adoration] et plein de repentir [à Allah]"
    },
    {
      "key": 3988,
      "id": "38-18",
      "translation": "Nous soumîmes les montagnes à glorifier Allah, soir et matin, en sa compagnie"
    },
    {
      "key": 3989,
      "id": "38-19",
      "translation": "de même que les oiseaux assemblés en masse, tous ne faisant qu'obéir à lui [Allah]"
    },
    {
      "key": 3990,
      "id": "38-20",
      "translation": "Et Nous renforçâmes son royaume et lui donnâmes la sagesse et la faculté de bien juger"
    },
    {
      "key": 3991,
      "id": "38-21",
      "translation": "Et t'est-elle parvenue la nouvelle des disputeurs quand ils grimpèrent au mur du sanctuaire"
    },
    {
      "key": 3992,
      "id": "38-22",
      "translation": "Quand ils entrèrent auprès de David, il en fut effrayé. Ils dirent: «N'aie pas peur! Nous sommes tous deux en dispute; l'un de nous a fait du tort à l'autre. Juge donc en toute équité entre nous, ne sois pas injuste et guide-nous vers le chemin droit"
    },
    {
      "key": 3993,
      "id": "38-23",
      "translation": "Celui-ci est mon frère: il a quatre-vingt-dix-neuf brebis, tandis que je n'ai qu'une brebis. Il m'a dit: «Confie-la-moi» et dans la conversation, il a beaucoup fait pression sur moi»"
    },
    {
      "key": 3994,
      "id": "38-24",
      "translation": "Il [David] dit: «Il a été certes injuste envers toi en demandant de joindre ta brebis à ses brebis». Beaucoup de gens transgressent les droits de leurs associés, sauf ceux qui croient et accomplissent les bonnes œuvres - cependant ils sont bien rares -. Et David pensa alors que Nous l'avions mis à l'épreuve. Il demanda donc pardon à son Seigneur et tomba prosterné et se repentit"
    },
    {
      "key": 3995,
      "id": "38-25",
      "translation": "Nous lui pardonnâmes. Il aura une place proche de Nous et un beau refuge"
    },
    {
      "key": 3996,
      "id": "38-26",
      "translation": "«O David, Nous avons fait de toi un calife sur la terre. Juge donc en toute équité parmi les gens et ne suis pas la passion: sinon elle t'égarera du sentier d'Allah». Car ceux qui s'égarent du sentier d'Allah auront un dur châtiment pour avoir oublié le Jour des Comptes"
    },
    {
      "key": 3997,
      "id": "38-27",
      "translation": "Nous n'avons pas créé le ciel et la terre et ce qui existe entre eux en vain. C'est ce que pensent ceux qui ont mécru. Malheur à ceux qui ont mécru pour le feu [qui les attend]"
    },
    {
      "key": 3998,
      "id": "38-28",
      "translation": "Traiterons-Nous ceux qui croient et accomplissent les bonnes œuvres comme ceux qui commettent du désordre sur terre? ou traiterons-Nous les pieux comme les pervers"
    },
    {
      "key": 3999,
      "id": "38-29",
      "translation": "[Voici] un Livre béni que Nous avons fait descendre vers toi, afin qu'ils méditent sur ses versets et que les doués d'intelligence réfléchissent"
    },
    {
      "key": 4000,
      "id": "38-30",
      "translation": "Et à David Nous fîmes don de Salomon, - quel bon serviteur! - Il était plein de repentir"
    },
    {
      "key": 4001,
      "id": "38-31",
      "translation": "Quand un après-midi, on lui présenta de magnifiques chevaux de course"
    },
    {
      "key": 4002,
      "id": "38-32",
      "translation": "il dit: «Oui, je me suis complu à aimer les biens (de ce monde) au point [d'oublier] le rappel de mon Seigneur jusqu'à ce que [le soleil] se soit caché derrière son voile"
    },
    {
      "key": 4003,
      "id": "38-33",
      "translation": "Ramenez-les moi.» Alors il se mit à leur couper les pattes et les cous"
    },
    {
      "key": 4004,
      "id": "38-34",
      "translation": "Et Nous avions certes éprouvé Salomon en plaçant sur son siège un corps. Ensuite, il se repentit"
    },
    {
      "key": 4005,
      "id": "38-35",
      "translation": "Il dit: «Seigneur, pardonne-moi et fais-moi don d'un royaume tel que nul après moi n'aura de pareil. C'est Toi le grand Dispensateur»"
    },
    {
      "key": 4006,
      "id": "38-36",
      "translation": "Nous lui assujettîmes alors le vent qui, par son ordre, soufflait modérément partout où il voulait"
    },
    {
      "key": 4007,
      "id": "38-37",
      "translation": "De même que les diables, bâtisseurs et plongeurs de toutes sortes"
    },
    {
      "key": 4008,
      "id": "38-38",
      "translation": "Et d'autres encore, accouplés dans des chaînes"
    },
    {
      "key": 4009,
      "id": "38-39",
      "translation": "«Voilà Notre don; distribue-le ou retiens-le sans avoir à en rendre compte»"
    },
    {
      "key": 4010,
      "id": "38-40",
      "translation": "Et il a une place rapprochée de Nous et un beau refuge"
    },
    {
      "key": 4011,
      "id": "38-41",
      "translation": "Et rappelle-toi Job, Notre serviteur, lorsqu'il appela son Seigneur: «Le Diable m'a infligé détresse et souffrance»"
    },
    {
      "key": 4012,
      "id": "38-42",
      "translation": "Frappe [la terre] de ton pied: voici une eau fraîche pour te laver et voici de quoi boire"
    },
    {
      "key": 4013,
      "id": "38-43",
      "translation": "Et Nous lui rendîmes sa famille et la fîmes deux fois plus nombreuse, comme une miséricorde de Notre part et comme un rappel pour les gens doués d'intelligence"
    },
    {
      "key": 4014,
      "id": "38-44",
      "translation": "«Et prends dans ta main un faisceau de brindilles, puis frappe avec cela. Et ne viole pas ton serment». Oui, Nous l'avons trouvé vraiment endurant. Quel bon serviteur! Sans cesse il se repentait"
    },
    {
      "key": 4015,
      "id": "38-45",
      "translation": "Et rappelle-toi Abraham, Isaac et Jacob, Nos serviteurs puissants et clairvoyants"
    },
    {
      "key": 4016,
      "id": "38-46",
      "translation": "Nous avons fait d'eux l'objet d'une distinction particulière: le rappel de l'au-delà"
    },
    {
      "key": 4017,
      "id": "38-47",
      "translation": "Ils sont auprès de Nous, certes, parmi les meilleurs élus"
    },
    {
      "key": 4018,
      "id": "38-48",
      "translation": "Et rappelle-toi Ismaël et Elisée, et Dal Kifl, chacun d'eux parmi les meilleurs"
    },
    {
      "key": 4019,
      "id": "38-49",
      "translation": "Cela est un rappel. C'est aux pieux qu'appartient, en vérité, la meilleure retraite"
    },
    {
      "key": 4020,
      "id": "38-50",
      "translation": "Les Jardins d'Eden, aux portes ouvertes pour eux"
    },
    {
      "key": 4021,
      "id": "38-51",
      "translation": "où, accoudés, ils demanderont des fruits abondants et des boissons"
    },
    {
      "key": 4022,
      "id": "38-52",
      "translation": "Et auprès d'eux seront les belles au regard chaste, toutes du même âge"
    },
    {
      "key": 4023,
      "id": "38-53",
      "translation": "Voilà ce qui vous est promis pour le Jour des Comptes"
    },
    {
      "key": 4024,
      "id": "38-54",
      "translation": "Ce sera Notre attribution inépuisable"
    },
    {
      "key": 4025,
      "id": "38-55",
      "translation": "Voilà! Alors que les rebelles auront certes la pire retraite"
    },
    {
      "key": 4026,
      "id": "38-56",
      "translation": "L'Enfer où ils brûleront. Et quel affreux lit"
    },
    {
      "key": 4027,
      "id": "38-57",
      "translation": "Voilà! Qu'ils y goûtent: eau bouillante et eau purulente"
    },
    {
      "key": 4028,
      "id": "38-58",
      "translation": "et d'autres punitions du même genre"
    },
    {
      "key": 4029,
      "id": "38-59",
      "translation": "Voici un groupe qui entre précipitamment en même temps que vous, nulle bienvenue à eux. Ils vont brûler dans le Feu"
    },
    {
      "key": 4030,
      "id": "38-60",
      "translation": "Ils dirent: «Pas de bienvenue pour vous, plutôt. C'est vous qui avez préparé cela pour nous». Quel mauvais lieu de séjour"
    },
    {
      "key": 4031,
      "id": "38-61",
      "translation": "Ils dirent: «Seigneur, celui qui nous a préparé cela, ajoute-lui un double châtiment dans le Feu»"
    },
    {
      "key": 4032,
      "id": "38-62",
      "translation": "Et ils dirent: «Pourquoi ne voyons-nous pas des gens que nous comptions parmi les malfaiteurs"
    },
    {
      "key": 4033,
      "id": "38-63",
      "translation": "Est-ce que nous les avons raillés (à tort) ou échappent-ils à nos regards?»"
    },
    {
      "key": 4034,
      "id": "38-64",
      "translation": "Telles sont en vérité les querelles des gens du Feu"
    },
    {
      "key": 4035,
      "id": "38-65",
      "translation": "Dis: «Je ne suis qu'un avertisseur. Point de divinité à part Allah, l'Unique, le Dominateur Suprême"
    },
    {
      "key": 4036,
      "id": "38-66",
      "translation": "Seigneur des cieux et de la terre et de ce qui existe entre eux, le Puissant, le Grand Pardonneur»"
    },
    {
      "key": 4037,
      "id": "38-67",
      "translation": "Dis: «Ceci (le Coran) est une grande nouvelle"
    },
    {
      "key": 4038,
      "id": "38-68",
      "translation": "mais vous vous en détournez"
    },
    {
      "key": 4039,
      "id": "38-69",
      "translation": "Je n'avais aucune connaissance de la cohorte sublime au moment où elle disputait"
    },
    {
      "key": 4040,
      "id": "38-70",
      "translation": "Il m'est seulement révélé que je suis un avertisseur clair»"
    },
    {
      "key": 4041,
      "id": "38-71",
      "translation": "Quand ton Seigneur dit aux Anges: «Je vais créer d'argile un être humain"
    },
    {
      "key": 4042,
      "id": "38-72",
      "translation": "Quand Je l'aurai bien formé et lui aurai insufflé de Mon Esprit, jetez-vous devant lui, prosternés»"
    },
    {
      "key": 4043,
      "id": "38-73",
      "translation": "Alors tous les Anges se prosternèrent"
    },
    {
      "key": 4044,
      "id": "38-74",
      "translation": "à l'exception d'Iblîs qui s'enfla d'orgueil et fut du nombre des infidèles"
    },
    {
      "key": 4045,
      "id": "38-75",
      "translation": "(Allah) lui dit: «O Iblîs, qui t'a empêché de te prosterner devant ce que J'ai créé de Mes mains? T'enfles-tu d'orgueil ou te considères-tu parmi les hauts placés?»"
    },
    {
      "key": 4046,
      "id": "38-76",
      "translation": "«Je suis meilleur que lui, dit [Iblîs,] Tu m'as créé de feu et tu l'as créé d'argile»"
    },
    {
      "key": 4047,
      "id": "38-77",
      "translation": "(Allah) dit: «Sors d'ici, te voilà banni"
    },
    {
      "key": 4048,
      "id": "38-78",
      "translation": "et sur toi sera ma malédiction jusqu'au jour de la Rétribution»"
    },
    {
      "key": 4049,
      "id": "38-79",
      "translation": "«Seigneur, dit [Iblîs], donne-moi donc un délai, jusqu'au jour où ils seront ressuscités»"
    },
    {
      "key": 4050,
      "id": "38-80",
      "translation": "(Allah) dit: «Tu es de ceux à qui un délai est accordé"
    },
    {
      "key": 4051,
      "id": "38-81",
      "translation": "jusqu'au jour de l'Instant bien Connu»"
    },
    {
      "key": 4052,
      "id": "38-82",
      "translation": "«Par Ta puissance! dit [Satan]. Je les séduirai assurément tous"
    },
    {
      "key": 4053,
      "id": "38-83",
      "translation": "sauf Tes serviteurs élus parmi eux»"
    },
    {
      "key": 4054,
      "id": "38-84",
      "translation": "(Allah) dit: «En vérité, et c'est la vérité que je dis"
    },
    {
      "key": 4055,
      "id": "38-85",
      "translation": "J'emplirai certainement l'Enfer de toi et de tous ceux d'entre eux qui te suivront»"
    },
    {
      "key": 4056,
      "id": "38-86",
      "translation": "Dis: «Pour cela, je ne vous demande aucun salaire; et je ne suis pas un imposteur"
    },
    {
      "key": 4057,
      "id": "38-87",
      "translation": "Ceci [le Coran] n'est qu'un rappel à l'univers"
    },
    {
      "key": 4058,
      "id": "38-88",
      "translation": "Et certainement vous en aurez des nouvelles bientôt!»"
    },
    {
      "key": 4059,
      "id": "39-1",
      "translation": "La révélation du Livre vient d'Allah, le Puissant, le Sage"
    },
    {
      "key": 4060,
      "id": "39-2",
      "translation": "Nous t'avons fait descendre le Livre en toute vérité. Adore donc Allah en Lui vouant un culte exclusif"
    },
    {
      "key": 4061,
      "id": "39-3",
      "translation": "C'est à Allah qu'appartient la religion pure. Tandis que ceux qui prennent des protecteurs en dehors de Lui (disent): «Nous ne les adorons que pour qu'ils nous rapprochent davantage d'Allah». En vérité, Allah jugera parmi eux sur ce en quoi ils divergent. Allah ne guide pas celui qui est menteur et grand ingrat"
    },
    {
      "key": 4062,
      "id": "39-4",
      "translation": "Si Allah avait voulu S'attribuer un enfant, Il aurait certes choisi ce qu'Il eût voulu parmi ce qu'Il crée. Gloire à Lui! C'est Lui Allah, l'Unique, le Dominateur suprême"
    },
    {
      "key": 4063,
      "id": "39-5",
      "translation": "Il a créé les cieux et la terre en toute vérité. Il enroule la nuit sur le jour et enroule le jour sur la nuit, et Il a assujetti le soleil et la lune à poursuivre chacun sa course pour un terme fixé. C'est bien Lui le Puissant, le Grand Pardonneur"
    },
    {
      "key": 4064,
      "id": "39-6",
      "translation": "Il vous a créés d'une personne unique et a tiré d'elle son épouse. Et Il a fait descendre [créé] pour vous huit couples de bestiaux. Il vous crée dans les ventres de vos mères, création après création, dans trois ténèbres. Tel est Allah, votre Seigneur! A Lui appartient toute la Royauté. Point de divinité à part Lui. Comment pouvez-vous vous détourner [de son culte]"
    },
    {
      "key": 4065,
      "id": "39-7",
      "translation": "Si vous ne croyez pas, Allah se passe largement de vous. De Ses serviteurs cependant, Il n'agrée pas la mécréance. Et si vous êtes reconnaissants, Il l'agrée pour vous. Nul pécheur ne portera les péchés d'autrui. Ensuite, vers votre Seigneur sera votre retour: Il vous informera alors de ce que vous faisiez car Il connaît parfaitement le contenu des poitrines"
    },
    {
      "key": 4066,
      "id": "39-8",
      "translation": "Et quand un malheur touche l'homme, il appelle son Seigneur en se tournant vers Lui. Puis quand Il lui accorde de Sa part un bienfait, il oublie la raison pour laquelle il faisait appel, et il assigne à Allah des égaux, afin d'égarer (les gens) de son chemin. Dis «Jouis de ta mécréance un court moment. Tu fais partie des gens du Feu»"
    },
    {
      "key": 4067,
      "id": "39-9",
      "translation": "Est-ce que celui qui, aux heures de la nuit, reste en dévotion, prosterné et debout, prenant garde à l'au-delà et espérant la miséricorde de son Seigneur... Dis: «Sont-ils égaux, ceux qui savent et ceux qui ne savent pas?» Seuls les doués d'intelligence se rappellent"
    },
    {
      "key": 4068,
      "id": "39-10",
      "translation": "Dis: «O Mes serviteurs qui avez cru! Craignez votre Seigneur». Ceux qui ici-bas font le bien, auront une bonne [récompense]. La terre d'Allah est vaste et les endurants auront leur pleine récompense sans compter"
    },
    {
      "key": 4069,
      "id": "39-11",
      "translation": "Dis: «Il m'a été ordonné d'adorer Allah en Lui vouant exclusivement le culte"
    },
    {
      "key": 4070,
      "id": "39-12",
      "translation": "et il m'a été ordonné d'être le premier des Musulmans"
    },
    {
      "key": 4071,
      "id": "39-13",
      "translation": "Dis: «Je crains, si je désobéis à mon Seigneur, le châtiment d'un jour terrible»"
    },
    {
      "key": 4072,
      "id": "39-14",
      "translation": "Dis: «C'est Allah que j'adore, et Lui voue exclusivement mon culte"
    },
    {
      "key": 4073,
      "id": "39-15",
      "translation": "Adorez donc, en dehors de Lui, qui vous voudrez!» - Dis: «Les perdants sont ceux qui, au Jour de la Résurrection, auront causé la perte de leurs propres âmes et celles de leurs familles». C'est bien cela la perte évidente"
    },
    {
      "key": 4074,
      "id": "39-16",
      "translation": "Au-dessus d'eux ils auront des couches de feu et des couches au-dessous d'eux. Voilà ce dont Allah menace Ses esclaves. «O Mes esclaves, craignez-Moi donc!»"
    },
    {
      "key": 4075,
      "id": "39-17",
      "translation": "Et à ceux qui s'écartent des Taghût pour ne pas les adorer, tandis qu'ils reviennent à Allah, à eux la bonne nouvelle! Annonce la bonne nouvelle à Mes serviteurs"
    },
    {
      "key": 4076,
      "id": "39-18",
      "translation": "qui prêtent l'oreille à la Parole, puis suivent ce qu'elle contient de meilleur. Ce sont ceux-là qu'Allah a guidés et ce sont eux les doués d'intelligence"
    },
    {
      "key": 4077,
      "id": "39-19",
      "translation": "Et bien quoi! Celui contre qui s'avère le décret du châtiment,... est-ce que tu sauves celui qui est dans le Feu"
    },
    {
      "key": 4078,
      "id": "39-20",
      "translation": "Mais ceux qui auront craint leur Seigneur auront [pour demeure] des étages [au Paradis] au-dessus desquels d'autres étages sont construits et sous lesquels coulent les rivières. Promesse d'Allah! Allah ne manque pas à Sa promesse"
    },
    {
      "key": 4079,
      "id": "39-21",
      "translation": "Ne vois-tu pas qu'Allah fait descendre du ciel de l'eau, puis Il l'achemine vers des sources dans la terre; ensuite avec cela, Il fait sortir une culture aux couleurs diverses, laquelle se fane ensuite, de sorte que tu la vois jaunie; ensuite, Il la réduit en miettes. C'est là certainement un rappel aux [gens] doués d'intelligence"
    },
    {
      "key": 4080,
      "id": "39-22",
      "translation": "Est-ce que celui dont Allah ouvre la poitrine à l'Islam et qui détient ainsi une lumière venant de Son Seigneur... Malheur donc à ceux dont les cœurs sont endurcis contre le rappel d'Allah. Ceux-là sont dans un égarement évident"
    },
    {
      "key": 4081,
      "id": "39-23",
      "translation": "Allah a fait descendre le plus beau des récits, un Livre dont [certains versets] se ressemblent et se répètent. Les peaux de ceux qui redoutent leur Seigneur frissonnent (à l'entendre); puis leurs peaux et leurs cœurs s'apaisent au rappel d'Allah. Voilà le [Livre] guide d'Allah par lequel Il guide qui Il veut. Mais quiconque Allah égare n'a point de guide"
    },
    {
      "key": 4082,
      "id": "39-24",
      "translation": "Est-ce que celui qui, au Jour de la Résurrection, se sera protégé le visage contre le pire châtiment... Et l'on dira aux injustes: «Goûtez à ce que vous avez acquis»"
    },
    {
      "key": 4083,
      "id": "39-25",
      "translation": "Ceux qui ont vécu avant eux ont démenti (les Messagers), le châtiment leur est venu par où ils ne le pressentaient pas"
    },
    {
      "key": 4084,
      "id": "39-26",
      "translation": "Allah leur a fait goûter l'ignominie dans la vie présente. Le châtiment de l'au-delà, cependant, est plus grand, si seulement ils savaient"
    },
    {
      "key": 4085,
      "id": "39-27",
      "translation": "Nous avons, dans ce Coran, cité pour les gens des exemples de toutes sortes afin qu'ils se souviennent"
    },
    {
      "key": 4086,
      "id": "39-28",
      "translation": "Un Coran [en langue] arabe, dénué de tortuosité, afin qu'ils soient pieux"
    },
    {
      "key": 4087,
      "id": "39-29",
      "translation": "Allah a cité comme parabole un homme appartenant à des associés se querellant à son sujet et un [autre] homme appartenant à un seul homme: sont-ils égaux en exemple? Louanges à Allah! Mais la plupart d'entre eux ne savent pas"
    },
    {
      "key": 4088,
      "id": "39-30",
      "translation": "En vérité tu mourras et ils mourront eux aussi"
    },
    {
      "key": 4089,
      "id": "39-31",
      "translation": "ensuite, au Jour de la Résurrection, vous vous disputerez auprès de votre Seigneur"
    },
    {
      "key": 4090,
      "id": "39-32",
      "translation": "Quel pire injuste donc, que celui qui ment contre Allah et qui traite de mensonge la vérité quand elle lui vient? N'est-ce pas dans l'Enfer qu'il y a un refuge pour les mécréants"
    },
    {
      "key": 4091,
      "id": "39-33",
      "translation": "Tandis que celui qui vient avec la vérité et celui qui la confirme, ceux-là sont les pieux"
    },
    {
      "key": 4092,
      "id": "39-34",
      "translation": "Ils auront tout ce qu'ils désireront auprès de leur Seigneur; voilà la récompense des bienfaisants"
    },
    {
      "key": 4093,
      "id": "39-35",
      "translation": "afin qu'Allah leur efface les pires de leurs actions et les récompense selon ce qu'ils auront fait de meilleur"
    },
    {
      "key": 4094,
      "id": "39-36",
      "translation": "Allah ne suffit-Il pas à Son esclave [comme soutien]? Et ils te font peur avec ce qui est en dehors de Lui. Et quiconque Allah égare n'a point de guide"
    },
    {
      "key": 4095,
      "id": "39-37",
      "translation": "Quiconque Allah guide, nul ne peut l'égarer. Allah n'est-Il pas Puissant et Détenteur du pouvoir de châtier"
    },
    {
      "key": 4096,
      "id": "39-38",
      "translation": "Si tu leur demandais: «Qui a créé les cieux et la terre?», Ils diraient assurément: «Allah». Dis: «Voyez-vous ceux que vous invoquez en dehors d'Allah; si Allah me voulait du mal, est-ce que [ces divinités] pourraient dissiper Son mal? Ou s'Il me voulait une miséricorde, pourraient-elles retenir Sa miséricorde?» - Dis: «Allah me suffit: c'est en Lui que placent leur confiance ceux qui cherchent un appui»"
    },
    {
      "key": 4097,
      "id": "39-39",
      "translation": "Dis: «O mon peuple, agissez selon votre méthode, moi j'agirai [selon la mienne]. Bientôt vous saurez"
    },
    {
      "key": 4098,
      "id": "39-40",
      "translation": "sur qui s'abattra un châtiment qui l'avilira; et sur qui se justifiera un châtiment durable»"
    },
    {
      "key": 4099,
      "id": "39-41",
      "translation": "Nous t'avons fait descendre le Livre, pour les hommes, en toute vérité. Quiconque se guide [le fait] pour son propre bien; et quiconque s'égare, s'égare à son détriment. Tu n'es nullement responsable [de leurs propres affaires]"
    },
    {
      "key": 4100,
      "id": "39-42",
      "translation": "Allah reçoit les âmes au moment de leur mort ainsi que celles qui ne meurent pas au cours de leur sommeil. Il retient celles à qui Il a décrété la mort, tandis qu'Il renvoie les autres jusqu'à un terme fixé. Il y a certainement là des preuves pour des gens qui réfléchissent"
    },
    {
      "key": 4101,
      "id": "39-43",
      "translation": "Ont-ils adopté, en dehors d'Allah, des intercesseurs? Dis: «Quoi! Même s'ils ne détiennent rien et sont dépourvus de raison"
    },
    {
      "key": 4102,
      "id": "39-44",
      "translation": "Dis: «L'intercession toute entière appartient à Allah. A Lui la royauté des cieux et de la terre. Puis c'est vers Lui que vous serez ramenés»"
    },
    {
      "key": 4103,
      "id": "39-45",
      "translation": "Et quand Allah est mentionné seul (sans associés), les cœurs de ceux qui ne croient pas en l'au-delà se crispent et quand on mentionne ceux qui sont en dehors de Lui, voilà qu'ils se réjouissent"
    },
    {
      "key": 4104,
      "id": "39-46",
      "translation": "Dis: «O Allah, Créateur des cieux et de la terre, Connaisseur de tout ce que le monde ignore comme de ce qu'il perçoit, c'est Toi qui jugeras entre Tes serviteurs ce sur quoi ils divergeaient»"
    },
    {
      "key": 4105,
      "id": "39-47",
      "translation": "Si les injustes possédaient tout ce qui se trouve sur la terre, - et autant encore -, ils l'offriraient comme rançon pour échapper au pire châtiment le Jour de la Résurrection; et leur apparaîtra, de la part d'Allah, ce qu'ils n'avaient jamais imaginé"
    },
    {
      "key": 4106,
      "id": "39-48",
      "translation": "et leur apparaîtront les méfaits qu'ils ont commis, et ce dont ils se raillaient les enveloppera"
    },
    {
      "key": 4107,
      "id": "39-49",
      "translation": "Quand un malheur touche l'homme, il Nous invoque. Quand ensuite Nous lui accordons une faveur de Notre part, il dit: «Je ne la dois qu'à [ma] science». C'est une épreuve, plutôt; mais la plupart d'entre eux ne savent pas"
    },
    {
      "key": 4108,
      "id": "39-50",
      "translation": "Ainsi parlaient ceux qui vécurent avant eux. Mais ce qu'ils ont acquis ne leur a servi à rien"
    },
    {
      "key": 4109,
      "id": "39-51",
      "translation": "Ils furent donc atteints par les mauvaises conséquences de leurs acquis. Ceux de ces gens [les Mecquois] qui auront commis l'injustice seront atteints par les mauvaises conséquences de leurs acquis et ils ne pourront s'opposer à la puissance [d'Allah]"
    },
    {
      "key": 4110,
      "id": "39-52",
      "translation": "Ne savent-ils pas qu'Allah attribue Ses dons avec largesse ou les restreint à qui Il veut? Il y a en cela des preuves pour des gens qui croient"
    },
    {
      "key": 4111,
      "id": "39-53",
      "translation": "Dis: «O Mes serviteurs qui avez commis des excès à votre propre détriment, ne désespérez pas de la miséricorde d'Allah. Car Allah pardonne tous les péchés. Oui, c'est Lui le Pardonneur, le Très Miséricordieux"
    },
    {
      "key": 4112,
      "id": "39-54",
      "translation": "Et revenez repentant à votre Seigneur, et soumettez-vous à Lui, avant que ne vous vienne le châtiment et vous ne recevez alors aucun secours"
    },
    {
      "key": 4113,
      "id": "39-55",
      "translation": "Et suivez la meilleure révélation qui vous est descendue de la part de votre Seigneur, avant que le châtiment ne vous vienne soudain, sans que vous ne [le] pressentiez"
    },
    {
      "key": 4114,
      "id": "39-56",
      "translation": "avant qu'une âme ne dise: «Malheur à moi pour mes manquements envers Allah. Car j'ai été certes, parmi les railleurs»"
    },
    {
      "key": 4115,
      "id": "39-57",
      "translation": "ou qu'elle ne dise: «Si Allah m'avait guidée, j'aurais été certes, parmi les pieux»"
    },
    {
      "key": 4116,
      "id": "39-58",
      "translation": "ou bien qu'elle ne dise en voyant le châtiment: «Ah! S'il y avait pour moi un retour! Je serais alors parmi les bienfaisants»"
    },
    {
      "key": 4117,
      "id": "39-59",
      "translation": "«Oh que si! Mes versets te sont venus et tu les as traités de mensonge, tu t'es enflé d'orgueil et tu étais parmi les mécréants»"
    },
    {
      "key": 4118,
      "id": "39-60",
      "translation": "Et au Jour de la Résurrection, tu verras les visages de ceux qui mentaient sur Allah, assombris. N'est-ce pas dans l'Enfer qu'il y aura une demeure pour les orgueilleux"
    },
    {
      "key": 4119,
      "id": "39-61",
      "translation": "Et Allah sauvera ceux qui ont été pieux en leur faisant gagner [leur place au Paradis]. Nul mal ne les touchera et ils ne seront point affligés"
    },
    {
      "key": 4120,
      "id": "39-62",
      "translation": "Allah est le Créateur de toute chose, et de toute chose Il est Garant"
    },
    {
      "key": 4121,
      "id": "39-63",
      "translation": "Il détient les clefs des cieux et de la terre; et ceux qui ne croient pas aux versets d'Allah, ce sont ceux-là les perdants"
    },
    {
      "key": 4122,
      "id": "39-64",
      "translation": "Dis: «Me commanderez-vous d'adorer autre qu'Allah, O ignorants?»"
    },
    {
      "key": 4123,
      "id": "39-65",
      "translation": "En effet, il t'a été révélé, ainsi qu'à ceux qui t'ont précédé: «Si tu donnes des associés à Allah, ton œuvre sera certes vaine; et tu seras très certainement du nombre des perdants"
    },
    {
      "key": 4124,
      "id": "39-66",
      "translation": "Tout au contraire, adore Allah seul et sois du nombre des reconnaissants»"
    },
    {
      "key": 4125,
      "id": "39-67",
      "translation": "Ils n'ont pas estimé Allah comme Il devrait l'être alors qu'au Jour de la Résurrection, Il fera de la terre entière une poignée, et les cieux seront pliés dans Sa [main] droite. Gloire à Lui! Il est au-dessus de ce qu'ils Lui associent"
    },
    {
      "key": 4126,
      "id": "39-68",
      "translation": "Et on soufflera dans la Trompe, et voilà que ceux qui seront dans les cieux et ceux qui seront sur la terre seront foudroyés, sauf ceux qu'Allah voudra [épargner]. Puis on y soufflera de nouveau, et les voilà debout à regarder"
    },
    {
      "key": 4127,
      "id": "39-69",
      "translation": "Et la terre resplendira de la lumière de son Seigneur; le Livre sera déposé et on fera venir les prophètes et les témoins; on décidera parmi eux en toute équité et ils ne seront point lésés"
    },
    {
      "key": 4128,
      "id": "39-70",
      "translation": "et chaque âme sera pleinement rétribuée pour ce qu'elle aura œuvré. Il [Allah] connaît mieux ce qu'ils font"
    },
    {
      "key": 4129,
      "id": "39-71",
      "translation": "Et ceux qui avaient mécru seront conduits par groupes à l'Enfer. Puis, quand ils y parviendront, ses portes s'ouvriront et ses gardiens leur diront: «Des messagers [choisis] parmi vous ne vous sont-ils pas venus, vous récitant les versets de votre Seigneur et vous avertissant de la rencontre de votre jour que voici?» Ils diront: si, mais le décret du châtiment s'est avéré juste contre les mécréants"
    },
    {
      "key": 4130,
      "id": "39-72",
      "translation": "«Entrez, [leur] dira-t-on, par les portes de l'Enfer, pour y demeurer éternellement». Qu'il est mauvais le lieu de séjour des orgueilleux"
    },
    {
      "key": 4131,
      "id": "39-73",
      "translation": "Et ceux qui avaient craint leur Seigneur seront conduits par groupes au Paradis. Puis, quand ils y parviendront et que ses portes s'ouvriront ses gardiens leur diront: «Salut à vous! vous avez été bons: entrez donc, pour y demeurer éternellement»"
    },
    {
      "key": 4132,
      "id": "39-74",
      "translation": "Et ils diront: «Louange à Allah qui nous a tenu Sa promesse et nous a fait hériter la terre! Nous allons nous installer dans le Paradis là où nous voulons». Que la récompense de ceux qui font le bien est excellente"
    },
    {
      "key": 4133,
      "id": "39-75",
      "translation": "Et tu verras les Anges faisant cercle autour du Trône, célébrant les louanges de leur Seigneur et Le glorifiant. Et il sera jugé entre eux en toute équité, et l'on dira: «Louange à Allah, Seigneur de l'univers»"
    },
    {
      "key": 4134,
      "id": "40-1",
      "translation": "H'â, Mîm"
    },
    {
      "key": 4135,
      "id": "40-2",
      "translation": "La révélation du Livre vient d'Allah, le Puissant, l'Omniscient"
    },
    {
      "key": 4136,
      "id": "40-3",
      "translation": "Le Pardonneur des péchés, l'Accueillant au repentir, le Dur en punition, le Détenteur des faveurs. Point de divinité à part Lui et vers Lui est la destination"
    },
    {
      "key": 4137,
      "id": "40-4",
      "translation": "Seuls ceux qui ont mécru discutent les versets d'Allah. Que leurs activités dans le pays ne te trompent pas"
    },
    {
      "key": 4138,
      "id": "40-5",
      "translation": "Avant eux, le peuple de Noé a traité (Son Messager) de menteur, et les coalisés après eux (ont fait de même), et chaque communauté a conçu le dessein de s'emparer de Son Messager. Et ils ont discuté de faux arguments pour rejeter la vérité. Alors Je les ai saisis. Et quelle punition fut la Mienne"
    },
    {
      "key": 4139,
      "id": "40-6",
      "translation": "Ainsi s'avéra juste la Parole de ton Seigneur contre ceux qui ont mécru: «Ils seront les gens du Feu»"
    },
    {
      "key": 4140,
      "id": "40-7",
      "translation": "Ceux (les Anges) qui portent le Trône et ceux qui l'entourent célèbrent les louanges de leur Seigneur, croient en Lui et implorent le pardon pour ceux qui croient: «Seigneur! Tu étends sur toute chose Ta miséricorde et Ta science. Pardonne donc à ceux qui se repentent et suivent Ton chemin et protège-les du châtiment de l'Enfer"
    },
    {
      "key": 4141,
      "id": "40-8",
      "translation": "Seigneur! Fais-les entrer aux jardins d'Eden que Tu leur as promis, ainsi qu'aux vertueux parmi leurs ancêtres, leurs épouses et leurs descendants, car c'est Toi le Puissant, le Sage"
    },
    {
      "key": 4142,
      "id": "40-9",
      "translation": "Et préserve-les [du châtiment] des mauvaises actions. Quiconque Tu préserves [du châtiment] des mauvaises actions ce jour-là, Tu lui feras miséricorde». Et c'est là l'énorme succès"
    },
    {
      "key": 4143,
      "id": "40-10",
      "translation": "A ceux qui n'auront pas cru on proclamera: «l'aversion d'Allah [envers vous] est plus grande que votre aversion envers vous-mêmes, lorsque vous étiez appelés à la foi et que vous persistiez dans la mécréance»"
    },
    {
      "key": 4144,
      "id": "40-11",
      "translation": "Ils diront: «Notre Seigneur, Tu nous as fais mourir deux fois et redonné la vie deux fois: nous reconnaissons donc nos péchés. Y a-t-il un moyen d'en sortir?»"
    },
    {
      "key": 4145,
      "id": "40-12",
      "translation": "«... Il en est ainsi car lorsqu'Allah était invoqué seul (sans associé), vous ne croyiez pas; et si on Lui donnait des associés, alors vous croyiez. Le jugement appartient à Allah, le Très Haut, le Très Grand»"
    },
    {
      "key": 4146,
      "id": "40-13",
      "translation": "C'est Lui qui vous fait voir Ses preuves, et fait descendre du ciel, pour vous, une subsistance. Seul se rappelle celui qui revient [à Allah]"
    },
    {
      "key": 4147,
      "id": "40-14",
      "translation": "Invoquez Allah donc, en Lui vouant un culte exclusif quelque répulsion qu'en aient les mécréants"
    },
    {
      "key": 4148,
      "id": "40-15",
      "translation": "Il est Celui qui est élevé aux degrés les plus hauts, Possesseur du Trône, Il envoie par son ordre l'Esprit sur celui qu'Il veut parmi Ses serviteurs, afin que celui-ci avertisse du jour de la Rencontre"
    },
    {
      "key": 4149,
      "id": "40-16",
      "translation": "le jour où ils comparaîtront sans que rien en eux ne soit caché à Allah. A qui appartient la royauté, aujourd'hui? A Allah, l'Unique, le Dominateur"
    },
    {
      "key": 4150,
      "id": "40-17",
      "translation": "Ce jour-là, chaque âme sera rétribuée selon ce qu'elle aura acquis. Ce jour-là, pas d'injustice, car Allah est prompt dans [Ses] comptes"
    },
    {
      "key": 4151,
      "id": "40-18",
      "translation": "Et avertis-les du jour qui approche, quand les cœurs remonteront aux gorges, terrifiés (ou angoissés). Les injustes n'auront ni ami zélé, ni intercesseur écouté"
    },
    {
      "key": 4152,
      "id": "40-19",
      "translation": "Il (Allah) connaît la trahison des yeux, tout comme ce que les poitrines cachent"
    },
    {
      "key": 4153,
      "id": "40-20",
      "translation": "Et Allah juge en toute équité, tandis que ceux qu'ils invoquent en dehors de Lui ne jugent rien. En vérité c'est Allah qui est l'Audient, le Clairvoyant"
    },
    {
      "key": 4154,
      "id": "40-21",
      "translation": "Ne parcourent-ils pas la terre, pour voir ce qu'il est advenu de ceux qui ont vécu avant eux? Ils étaient [pourtant] plus forts qu'eux et ont laissé sur terre bien plus de vestiges. Allah les saisit pour leurs péchés et ils n'eurent point de protecteur contre Allah"
    },
    {
      "key": 4155,
      "id": "40-22",
      "translation": "Ce fut ainsi, parce que leurs Messagers leur avaient apporté les preuves, mais ils se montrèrent mécréants. Allah donc les saisit, car Il est fort et redoutable dans Son châtiment"
    },
    {
      "key": 4156,
      "id": "40-23",
      "translation": "Nous envoyâmes effectivement Moïse avec Nos signes et une preuve évidente"
    },
    {
      "key": 4157,
      "id": "40-24",
      "translation": "vers Pharaon, Hâmân et Coré. Mais ils dirent: «Magicien! Grand menteur!»"
    },
    {
      "key": 4158,
      "id": "40-25",
      "translation": "Puis, quand il leur eut apporté la vérité venant de Nous, ils dirent: «Tuez les fils de ceux qui ont cru avec lui, et laissez vivre leurs femmes». Et les ruses des mécréants ne vont qu'en pure perte"
    },
    {
      "key": 4159,
      "id": "40-26",
      "translation": "Et Pharaon dit: «Laissez-moi tuer Moïse. Et qu'il appelle son Seigneur! Je crains qu'il ne change votre religion ou qu'il ne fasse apparaître la corruption sur terre»"
    },
    {
      "key": 4160,
      "id": "40-27",
      "translation": "Moïse [lui] dit: «Je cherche auprès de mon Seigneur et le vôtre, protection contre tout orgueilleux qui ne croit pas au jour du Compte»"
    },
    {
      "key": 4161,
      "id": "40-28",
      "translation": "Et un homme croyant de la famille de Pharaon, qui dissimulait sa foi dit: «Tuez-vous un homme parce qu'il dit: «Mon Seigneur est Allah?» Alors qu'il est venu à vous avec les preuves évidentes de la part de votre Seigneur. S'il est menteur, son mensonge sera à son détriment; tandis que s'il est véridique, alors une partie de ce dont il vous menace tombera sur vous». Certes, Allah ne guide pas celui qui est outrancier et imposteur"
    },
    {
      "key": 4162,
      "id": "40-29",
      "translation": "«O mon peuple, triomphant sur la terre, vous avez la royauté aujourd'hui. Mais qui nous secourra de la rigueur d'Allah si elle nous vient?» Pharaon dit: «Je ne vous indique que ce que je considère bon. Je ne vous guide qu'au sentier de la droiture»"
    },
    {
      "key": 4163,
      "id": "40-30",
      "translation": "Et celui qui était croyant dit: «O mon peuple, je crains pour vous un jour semblable à celui des coalisés"
    },
    {
      "key": 4164,
      "id": "40-31",
      "translation": "Un sort semblable à celui du peuple de Noé, des 'Aad et des Thamûd et de ceux [qui vécurent] après eux». Allah ne veut [faire subir] aucune injustice aux serviteurs"
    },
    {
      "key": 4165,
      "id": "40-32",
      "translation": "«O mon peuple, je crains pour vous le jour de l'Appel Mutuel"
    },
    {
      "key": 4166,
      "id": "40-33",
      "translation": "Le jour où vous tournerez le dos en déroute, sans qu'il y ait pour vous de protecteur contre Allah». Et quiconque Allah égare, n'a point de guide"
    },
    {
      "key": 4167,
      "id": "40-34",
      "translation": "Certes, Joseph vous est venu auparavant avec les preuves évidentes, mais vous n'avez jamais cessé d'avoir des doutes sur ce qu'il vous avait apporté. Mais lorsqu'il mourut, vous dites alors: «Allah n'enverra plus jamais de Messager après lui». Ainsi Allah égare-t-Il celui qui est outrancier et celui qui doute"
    },
    {
      "key": 4168,
      "id": "40-35",
      "translation": "Ceux qui discutent les prodiges d'Allah sans qu'aucune preuve ne leur soit venue, [leur action] est grandement haïssable auprès d'Allah et auprès de ceux qui croient. Ainsi Allah scelle-t-Il le cœur de tout orgueilleux tyran"
    },
    {
      "key": 4169,
      "id": "40-36",
      "translation": "Et Pharaon dit: «O Hâmân, bâtis-moi une tour: peut-être atteindrai-je les voies"
    },
    {
      "key": 4170,
      "id": "40-37",
      "translation": "les voies des cieux, et apercevrai-je le Dieu de Moïse; mais je pense que celui-ci est menteur». Ainsi la mauvaise action de Pharaon lui parut enjolivée; et il fut détourné du droit chemin; et le stratagème de Pharaon n'est voué qu'à la destruction"
    },
    {
      "key": 4171,
      "id": "40-38",
      "translation": "Et celui qui avait cru dit: «O mon peuple, suivez-moi. Je vous guiderai au sentier de la droiture"
    },
    {
      "key": 4172,
      "id": "40-39",
      "translation": "O mon peuple, cette vie n'est que jouissance temporaire, alors que l'au-delà est vraiment la demeure de la stabilité"
    },
    {
      "key": 4173,
      "id": "40-40",
      "translation": "Quiconque fait une mauvaise action ne sera rétribué que par son pareil; et quiconque, mâle ou femelle, fait une bonne action tout en étant croyant, alors ceux-là entreront au Paradis pour y recevoir leur subsistance sans compter"
    },
    {
      "key": 4174,
      "id": "40-41",
      "translation": "O mon peuple, mais qu'ai-je à vous appeler au salut, alors que vous m'appelez au Feu"
    },
    {
      "key": 4175,
      "id": "40-42",
      "translation": "Vous m'invitez à nier Allah et à Lui donner des associés dont je n'ai aucun savoir, alors que je vous appelle au Tout Puissant, au Grand Pardonneur"
    },
    {
      "key": 4176,
      "id": "40-43",
      "translation": "Nul doute que ce à quoi vous m'appelez ne peut exaucer une invocation ni ici-bas ni dans l'au-delà. C'est vers Allah qu'est notre retour, et les outranciers sont eux les gens du Feu"
    },
    {
      "key": 4177,
      "id": "40-44",
      "translation": "Bientôt vous vous rappellerez ce que je vous dis; et je confie mon sort à Allah. Allah est, certes, Clairvoyant sur les serviteurs"
    },
    {
      "key": 4178,
      "id": "40-45",
      "translation": "Allah donc le protégea des méfaits de leurs ruses, alors que le pire châtiment cerna les gens de Pharaon"
    },
    {
      "key": 4179,
      "id": "40-46",
      "translation": "le Feu, auquel ils sont exposés matin et soir. Et le jour où l'Heure arrivera (il sera dit): «Faites entrer les gens de Pharaon au plus dur du châtiment»"
    },
    {
      "key": 4180,
      "id": "40-47",
      "translation": "Et quand ils se disputeront dans le Feu, les faibles diront à ceux qui s'enflaient d'orgueil: «Nous vous avions suivis: pourriez-vous nous préserver d'une partie du feu?»"
    },
    {
      "key": 4181,
      "id": "40-48",
      "translation": "Et ceux qui s'enflaient d'orgueil diront: «En vérité, nous y voilà tous». Allah a déjà rendu son jugement entre les serviteurs"
    },
    {
      "key": 4182,
      "id": "40-49",
      "translation": "Et ceux qui seront dans le Feu diront aux gardiens de l'Enfer: «Priez votre Seigneur de nous alléger un jour de [notre] supplice»"
    },
    {
      "key": 4183,
      "id": "40-50",
      "translation": "Ils diront: «vos Messagers, ne vous apportaient-ils pas les preuves évidentes?» Ils diront: «Si»! Ils [les gardiens] diront: «Eh bien, priez» ! Et l'invocation des mécréants n'est qu'aberration"
    },
    {
      "key": 4184,
      "id": "40-51",
      "translation": "Nous secourrons, certes, Nos Messagers et ceux qui croient, dans la vie présente tout comme au jour où les témoins [les Anges gardiens] se dresseront (le Jour du Jugement)"
    },
    {
      "key": 4185,
      "id": "40-52",
      "translation": "au jour où leur excuse ne sera pas utile aux injustes, tandis qu'il y aura pour eux la malédiction et la pire demeure"
    },
    {
      "key": 4186,
      "id": "40-53",
      "translation": "En effet, Nous avons apporté à Moïse la guidée, et fait hériter aux Enfants d'Israël, le Livre"
    },
    {
      "key": 4187,
      "id": "40-54",
      "translation": "une guidée et un rappel aux gens doués d'intelligence"
    },
    {
      "key": 4188,
      "id": "40-55",
      "translation": "Endure donc, car la promesse d'Allah est vérité, implore le pardon pour ton péché et célèbre la gloire et la louange de ton Seigneur, soir et matin"
    },
    {
      "key": 4189,
      "id": "40-56",
      "translation": "Ceux qui discutent sur les versets d'Allah sans qu'aucune preuve ne leur soit venue, n'ont dans leurs poitrines qu'orgueil. Ils n'atteindront pas leur but. Implore donc la protection d'Allah, car c'est Lui l'Audient, le Clairvoyant"
    },
    {
      "key": 4190,
      "id": "40-57",
      "translation": "La création des cieux et de la terre est quelque chose de plus grand que la création des gens. Mais la plupart des gens ne savent pas"
    },
    {
      "key": 4191,
      "id": "40-58",
      "translation": "L'aveugle et le voyant ne sont pas égaux, et ceux qui croient et accomplissent les bonnes œuvres ne peuvent être comparés à celui qui fait le mal. C'est rare que vous vous rappeliez"
    },
    {
      "key": 4192,
      "id": "40-59",
      "translation": "En vérité; L'Heure va arriver: pas de doute là-dessus; mais la plupart des gens n'y croient pas"
    },
    {
      "key": 4193,
      "id": "40-60",
      "translation": "Et votre Seigneur dit: «Appelez-Moi, Je vous répondrai. Ceux qui, par orgueil, se refusent à M'adorer entreront bientôt dans l'Enfer, humiliés»"
    },
    {
      "key": 4194,
      "id": "40-61",
      "translation": "Allah est celui qui vous a assigné la nuit pour que vous vous y reposiez, et le jour pour y voir clair. Allah est le Pourvoyeur de grâce aux hommes, mais la plupart des gens ne sont pas reconnaissants"
    },
    {
      "key": 4195,
      "id": "40-62",
      "translation": "Tel est votre Seigneur, Créateur de toute chose. Point de divinité à part Lui. Comment se fait-il que vous vous détourniez (du chemin droit)"
    },
    {
      "key": 4196,
      "id": "40-63",
      "translation": "Ainsi ceux qui nient les prodiges d'Allah se détournent-ils [du chemin droit]"
    },
    {
      "key": 4197,
      "id": "40-64",
      "translation": "C'est Allah qui vous a assigné la terre comme demeure stable et le ciel comme toit et vous a donné votre forme, - et quelle belle forme Il vous a donnée! - et Il vous a nourris de bonnes choses. Tel est Allah, votre Seigneur; gloire à Allah, Seigneur de l'univers"
    },
    {
      "key": 4198,
      "id": "40-65",
      "translation": "C'est Lui le Vivant. Point de divinité à part Lui. Appelez-Le donc, en Lui vouant un culte exclusif. Louange à Allah, Seigneur de l'univers"
    },
    {
      "key": 4199,
      "id": "40-66",
      "translation": "Dis: «Il m'a été interdit, une fois que les preuves me sont venues de mon Seigneur, d'adorer ceux que vous invoquez en dehors d'Allah, et il m'a été ordonné de me soumettre au Seigneur de l'univers»"
    },
    {
      "key": 4200,
      "id": "40-67",
      "translation": "C'est Lui qui vous a créés de terre, puis d'une goutte sperme, puis d'une adhérence; puis Il vous fait sortir petit enfant pour qu'ensuite vous atteigniez votre maturité et qu'ensuite vous deveniez vieux, - certains parmi vous meurent plus tôt, - et pour que vous atteigniez un terme fixé, afin que vous raisonniez"
    },
    {
      "key": 4201,
      "id": "40-68",
      "translation": "C'est Lui qui donne la vie et donne la mort. Puis quand Il décide une affaire, Il n'a qu'à dire: «Sois», et elle est"
    },
    {
      "key": 4202,
      "id": "40-69",
      "translation": "N'as-tu pas vu comment ceux qui discutent sur les versets d'Allah se laissent détourner"
    },
    {
      "key": 4203,
      "id": "40-70",
      "translation": "Ceux qui traitent de mensonge le Livre (le Coran) et ce avec quoi Nous avons envoyé Nos Messagers; ils sauront bientôt"
    },
    {
      "key": 4204,
      "id": "40-71",
      "translation": "quand, des carcans à leurs cous et avec des chaînes ils seront traînés"
    },
    {
      "key": 4205,
      "id": "40-72",
      "translation": "dans l'eau bouillante; et qu'ensuite ils brûleront dans le Feu"
    },
    {
      "key": 4206,
      "id": "40-73",
      "translation": "Puis on leur dira: «Où sont ceux que vous associez"
    },
    {
      "key": 4207,
      "id": "40-74",
      "translation": "à Allah?» «Ils se sont écartés de nous, diront-ils. Ou plutôt, nous n'invoquions rien, auparavant». Ainsi Allah égare-t-Il les mécréants"
    },
    {
      "key": 4208,
      "id": "40-75",
      "translation": "Voilà le prix de votre exultation sur terre, sans raison, ainsi que de votre joie immodérée"
    },
    {
      "key": 4209,
      "id": "40-76",
      "translation": "Franchissez les portes de l'Enfer pour y demeurer éternellement. Qu'il est mauvais le lieu de séjour des orgueilleux"
    },
    {
      "key": 4210,
      "id": "40-77",
      "translation": "Endure donc. La promesse d'Allah est vraie. Que Nous te montrions une partie de ce dont Nous les menaçons ou que Nous te fassions mourir (avant cela)... c'est vers Nous qu'ils seront ramenés"
    },
    {
      "key": 4211,
      "id": "40-78",
      "translation": "Certes, Nous avons envoyé avant toi des Messagers. Il en est dont Nous t'avons raconté l'histoire; et il en est dont Nous ne t'avons pas raconté l'histoire. Et il n'appartient pas à un Messager d'apporter un signe [ou ] si ce n'est avec la permission d'Allah. Lorsque le commandement d'Allah viendra, tout sera décidé en toute justice; et ceux qui profèrent des mensonges sont alors les perdants"
    },
    {
      "key": 4212,
      "id": "40-79",
      "translation": "C'est Allah qui vous a fait les bestiaux pour que vous en montiez et que vous en mangiez"
    },
    {
      "key": 4213,
      "id": "40-80",
      "translation": "et vous y avez des profits et afin que vous atteigniez sur eux une chose nécessaire qui vous tenait à cœur. C'est sur eux et sur les vaisseaux que vous êtes transportés"
    },
    {
      "key": 4214,
      "id": "40-81",
      "translation": "Et Il vous montre Ses merveilles. Quelles merveilles d'Allah niez-vous donc"
    },
    {
      "key": 4215,
      "id": "40-82",
      "translation": "Ne parcourent-ils donc pas la terre pour voir ce qu'il est advenu de ceux qui étaient avant eux? Ils étaient [pourtant] plus nombreux qu'eux et bien plus puissants et ils [avaient laissé] sur terre beaucoup plus de vestiges. Mais ce qu'ils ont acquis ne leur a servi à rien"
    },
    {
      "key": 4216,
      "id": "40-83",
      "translation": "Lorsque leurs Messagers leur apportaient les preuves évidentes, ils exultaient des connaissances qu'ils avaient. Et ce dont ils se moquaient les enveloppa"
    },
    {
      "key": 4217,
      "id": "40-84",
      "translation": "Puis, quand ils virent Notre rigueur ils dirent: «Nous croyons en Allah Seul, et nous renions ce que nous Lui donnions comme associés»"
    },
    {
      "key": 4218,
      "id": "40-85",
      "translation": "Mais leur croyance, au moment où ils eurent constaté Notre rigueur, ne leur profita point; Telle est la règle d'Allah envers Ses serviteurs dans le passé. Et c'est là que les mécréants se trouvèrent perdants"
    },
    {
      "key": 4219,
      "id": "41-1",
      "translation": "H'â, Mîm"
    },
    {
      "key": 4220,
      "id": "41-2",
      "translation": "[C'est] une Révélation descendue de la part du Tout Miséricordieux, du Très Miséricordieux"
    },
    {
      "key": 4221,
      "id": "41-3",
      "translation": "Un Livre dont les versets sont détaillés (et clairement exposés), un Coran [lecture] arabe pour des gens qui savent"
    },
    {
      "key": 4222,
      "id": "41-4",
      "translation": "annonciateur [d'une bonne nouvelle] et avertisseur. Mais la plupart d'entre eux se détournent; c'est qu'ils n'entendent pas"
    },
    {
      "key": 4223,
      "id": "41-5",
      "translation": "Et ils dirent: «Nos cœurs sont voilés contre ce à quoi tu nous appelles, nos oreilles sont sourdes. Et entre nous et toi, il y a une cloison. Agis donc de ton côté; nous agissons du nôtre.»"
    },
    {
      "key": 4224,
      "id": "41-6",
      "translation": "Dis: «Je ne suis qu'un homme comme vous. Il m'a été révélé que votre Dieu est un Dieu unique. Cherchez le droit chemin vers Lui et implorez son pardon». Et malheur aux Associateurs"
    },
    {
      "key": 4225,
      "id": "41-7",
      "translation": "qui n'acquittent pas la Zakât et ne croient pas en l'au-delà"
    },
    {
      "key": 4226,
      "id": "41-8",
      "translation": "Ceux qui croient et accomplissent de bonnes œuvres auront une énorme récompense jamais interrompue"
    },
    {
      "key": 4227,
      "id": "41-9",
      "translation": "Dis: «Renierez-vous [l'existence] de celui qui a créé la terre en deux jours et Lui donnerez-vous des égaux? Tel est le Seigneur de l'univers"
    },
    {
      "key": 4228,
      "id": "41-10",
      "translation": "c'est Lui qui a fermement fixé des montagnes au-dessus d'elle, l'a bénie et lui assigna ses ressources alimentaires en quatre jours d'égale durée. [Telle est la réponse] à ceux qui t'interrogent"
    },
    {
      "key": 4229,
      "id": "41-11",
      "translation": "Il S'est ensuite adressé au ciel qui était alors fumée et lui dit, ainsi qu'à la terre: «Venez tous deux, bon gré, mal gré». Tous deux dirent: «Nous venons obéissants»"
    },
    {
      "key": 4230,
      "id": "41-12",
      "translation": "Il décréta d'en faire sept cieux en deux jours et révéla à chaque ciel sa fonction. Et Nous avons décoré le ciel le plus proche de lampes [étoiles] et l'avons protégé. Tel est l'Ordre établi par le Puissant, l'Omniscient"
    },
    {
      "key": 4231,
      "id": "41-13",
      "translation": "S'ils s'en détournent, alors dis-leur; «Je vous ai avertis d'une foudre semblable à celle qui frappa les 'Aad et les Tamûd»"
    },
    {
      "key": 4232,
      "id": "41-14",
      "translation": "Quand les Messagers leur étaient venus, de devant eux et par derrière, [leur disant]: «N'adorez qu'Allah», ils dirent: «Si notre Seigneur avait voulu, Il aurait certainement fait descendre des Anges. Nous ne croyons donc pas [au message] avec lequel vous avez été envoyés»"
    },
    {
      "key": 4233,
      "id": "41-15",
      "translation": "Quant aux 'Aad, ils s'enflèrent d'orgueil sur terre injustement et dirent: «Qui est plus fort que nous?» Quoi! N'ont-ils pas vu qu'en vérité Allah qui les a créés est plus fort qu'eux? Et ils reniaient Nos signes"
    },
    {
      "key": 4234,
      "id": "41-16",
      "translation": "Nous déchaînâmes contre eux un vent violent et glacial en des jours néfastes, afin de leur faire goûter le châtiment de l'ignominie dans la vie présente. Le châtiment de l'au-delà cependant est plus ignominieux encore, et ils ne seront pas secourus"
    },
    {
      "key": 4235,
      "id": "41-17",
      "translation": "Et quant aux Tamûd, Nous les guidâmes; mais ils ont préféré l'aveuglement à la guidée. C'est alors qu'ils furent saisis par la foudre du supplice le plus humiliant pour ce qu'ils avaient acquis"
    },
    {
      "key": 4236,
      "id": "41-18",
      "translation": "Et Nous sauvâmes ceux qui croyaient et craignaient Allah"
    },
    {
      "key": 4237,
      "id": "41-19",
      "translation": "Et le jour où les ennemis d'Allah seront rassemblés en masse vers le Feu... Puis on les poussera [dans sa direction]"
    },
    {
      "key": 4238,
      "id": "41-20",
      "translation": "Alors, quand ils y seront, leur ouïe, leurs yeux et leurs peaux témoigneront contre eux de ce qu'ils œuvraient"
    },
    {
      "key": 4239,
      "id": "41-21",
      "translation": "Ils diront à leurs peaux: «Pourquoi avez-vous témoigné contre nous?» Elles diront: «C'est Allah qui nous a fait parler, Lui qui fait parler toute chose. C'est Lui qui vous a créés une première fois et c'est vers Lui que vous serez retournés»"
    },
    {
      "key": 4240,
      "id": "41-22",
      "translation": "Vous ne pouviez vous cacher au point que ni votre ouïe, ni vos yeux et ni vos peaux ne puissent témoigner contre vous. Mais vous pensiez qu'Allah ne savait pas beaucoup de ce que vous faisiez"
    },
    {
      "key": 4241,
      "id": "41-23",
      "translation": "Et c'est cette pensée que vous avez eue de votre Seigneur, qui vous a ruinés, de sorte que vous êtes devenus du nombre des perdants"
    },
    {
      "key": 4242,
      "id": "41-24",
      "translation": "S'ils endurent, le Feu sera leur lieu de séjour; et s'ils cherchent à s'excuser, ils ne seront pas excusés"
    },
    {
      "key": 4243,
      "id": "41-25",
      "translation": "Et Nous leur avons destiné des compagnons inséparables [des démons] qui leur ont enjolivé ce qui était devant et derrière eux. Et le décret s'est avéré juste contre eux, comme contre les autres communautés de djinns et d'hommes qui ont vécu avant eux. Ils sont certes perdants"
    },
    {
      "key": 4244,
      "id": "41-26",
      "translation": "Et ceux qui avaient mécru dirent: «Ne prêtez pas l'oreille à ce Coran, et faites du chahut (pendant sa récitation), afin d'avoir le dessus»"
    },
    {
      "key": 4245,
      "id": "41-27",
      "translation": "Nous ferons certes, goûter à ceux qui ne croient pas un dur châtiment, et les rétribuerons certes [d'une punition] pire que ce [que méritent] leurs méfaits"
    },
    {
      "key": 4246,
      "id": "41-28",
      "translation": "Ainsi, la rétribution des ennemis d'Allah sera le Feu où ils auront une demeure éternelle, comme punition pour avoir nié Nos versets [le Coran]"
    },
    {
      "key": 4247,
      "id": "41-29",
      "translation": "Et les mécréants diront: «Seigneur, fais-nous voir ceux des djinns et des humains qui nous ont égarés, afin que nous les placions tous sous nos pieds, pour qu'ils soient parmi les plus bas»"
    },
    {
      "key": 4248,
      "id": "41-30",
      "translation": "Ceux qui disent: «Notre Seigneur est Allah», et qui se tiennent dans le droit chemin, les Anges descendent sur eux. «N'ayez pas peur et ne soyez pas affligés; mais ayez la bonne nouvelle du Paradis qui vous était promis"
    },
    {
      "key": 4249,
      "id": "41-31",
      "translation": "Nous sommes vos protecteurs dans la vie présente et dans l'au-delà; et vous y aurez ce que vos âmes désireront et ce que vous réclamerez"
    },
    {
      "key": 4250,
      "id": "41-32",
      "translation": "un lieu d'accueil de la part d'un Très Grand Pardonneur, d'un Très Miséricordieux»"
    },
    {
      "key": 4251,
      "id": "41-33",
      "translation": "Et qui profère plus belles paroles que celui qui appelle à Allah, fait bonne œuvre et dit: «Je suis du nombre des Musulmans?»"
    },
    {
      "key": 4252,
      "id": "41-34",
      "translation": "La bonne action et la mauvaise ne sont pas pareilles. Repousse (le mal) par ce qui est meilleur; et voilà que celui avec qui tu avais une animosité devient tel un ami chaleureux"
    },
    {
      "key": 4253,
      "id": "41-35",
      "translation": "Mais (ce privilège) n'est donné qu'à ceux qui endurent et il n'est donné qu'au possesseur d'une grâce infinie"
    },
    {
      "key": 4254,
      "id": "41-36",
      "translation": "Et si jamais le Diable t'incite (à agir autrement), alors cherche refuge auprès d'Allah; c'est Lui, vraiment l'Audient, l'Omniscient"
    },
    {
      "key": 4255,
      "id": "41-37",
      "translation": "Parmi Ses merveilles, sont la nuit et le jour, le soleil et la lune: ne vous prosternez ni devant le soleil, ni devant la lune, mais prosternez-vous devant Allah qui les a créés, si c'est Lui que vous adorez"
    },
    {
      "key": 4256,
      "id": "41-38",
      "translation": "Mais s'ils s'enflent d'orgueil... ceux qui sont auprès de ton Seigneur [les Anges] Le glorifient, nuit et jour, sans jamais se lasser"
    },
    {
      "key": 4257,
      "id": "41-39",
      "translation": "Et parmi Ses merveilles est que tu vois la terre humiliée (toute nue). Puis aussitôt que Nous faisons descendre l'eau sur elle, elle se soulève et augmente [de volume]. Celui qui lui redonne la vie est certes Celui qui fera revivre les morts, car Il est Omnipotent"
    },
    {
      "key": 4258,
      "id": "41-40",
      "translation": "Ceux qui dénaturent le sens de Nos versets (le Coran) ne Nous échappent pas. Celui qui sera jeté au Feu sera-t-il meilleur que celui qui viendra en toute sécurité le Jour de la Résurrection? Faites ce que vous voulez car Il est Clairvoyant sur tout ce que vous faites"
    },
    {
      "key": 4259,
      "id": "41-41",
      "translation": "Ceux qui ne croient pas au Rappel [le Coran] quand il leur parvient... alors que c'est un Livre puissant [inattaquable]"
    },
    {
      "key": 4260,
      "id": "41-42",
      "translation": "Le faux ne l'atteint [d'aucune part], ni par devant ni par derrière: c'est une révélation émanant d'un Sage, Digne de louange"
    },
    {
      "key": 4261,
      "id": "41-43",
      "translation": "Il ne t'est dit que ce qui a été dit aux Messagers avant toi. Ton Seigneur est certes Détenteur du pardon et Détenteur aussi d'une punition douloureuse"
    },
    {
      "key": 4262,
      "id": "41-44",
      "translation": "Si Nous en avions fait un Coran en une langue autre que l'arabe, ils auraient dit: «Pourquoi ses versets n'ont-ils pas été exposés clairement? quoi? Un [Coran] non-arabe et [un Messager] arabe?» Dis: «Pour ceux qui croient, il est une guidée et une guérison». Et quant à ceux qui ne croient pas, il y a une surdité dans leurs oreilles et ils sont frappés d'aveuglement en ce qui le concerne; ceux-là sont appelés d'un endroit lointain"
    },
    {
      "key": 4263,
      "id": "41-45",
      "translation": "Nous avons effectivement donné à Moïse le Livre. Puis, il y eut controverse là-dessus. Et si ce n'était une parole préalable de ton Seigneur, on aurait certainement tranché entre eux. Ils sont vraiment, à son sujet, dans un doute troublant"
    },
    {
      "key": 4264,
      "id": "41-46",
      "translation": "Quiconque fait une bonne œuvre, c'est pour son bien. Et quiconque fait le mal, il le fait à ses dépens. Ton Seigneur, cependant, n'est point injuste envers les serviteurs"
    },
    {
      "key": 4265,
      "id": "41-47",
      "translation": "A Lui revient la connaissance de l'Heure. Aucun fruit ne sort de son enveloppe, aucune femelle ne conçoit ni ne met bas sans qu'Il n'en ait connaissance. Et le jour où Il les appellera: «Où sont Mes associés?», ils diront: «Nous Te déclarons qu'il n'y a point de témoin parmi nous»"
    },
    {
      "key": 4266,
      "id": "41-48",
      "translation": "Et ce qu'auparavant ils invoquaient les délaissera; et ils réaliseront qu'ils n'ont point d'échappatoire"
    },
    {
      "key": 4267,
      "id": "41-49",
      "translation": "L'homme ne se lasse pas d'implorer le bien. Si le mal le touche, le voilà désespéré, désemparé"
    },
    {
      "key": 4268,
      "id": "41-50",
      "translation": "Et si nous lui faisons goûter une miséricorde de Notre part, après qu'une détresse l'ait touché, il dit certainement: «Cela m'est dû! Et je ne pense pas que l'Heure se lèvera [un jour]. Et si je suis ramené vers mon Seigneur, je trouverai, près de Lui, la plus belle part». Nous informerons ceux qui ont mécru de ce qu'ils ont fait et Nous leur ferons sûrement goûter à un dur châtiment"
    },
    {
      "key": 4269,
      "id": "41-51",
      "translation": "Quand Nous comblons de bienfaits l'homme, il s'esquive et s'éloigne. Et quand un malheur le touche, il se livre alors à une longue prière"
    },
    {
      "key": 4270,
      "id": "41-52",
      "translation": "Dis: «Voyez-vous? Si ceci (le Coran) émane d'Allah et qu'ensuite vous le reniez; qui se trouvera plus égaré que celui qui s'éloigne dans la dissidence?»"
    },
    {
      "key": 4271,
      "id": "41-53",
      "translation": "Nous leur montrerons Nos signes dans l'univers et en eux-mêmes, jusqu'à ce qu'il leur devienne évident que c'est cela (le Coran), la vérité. Ne suffit-il pas que ton Seigneur soit témoin de toute chose"
    },
    {
      "key": 4272,
      "id": "41-54",
      "translation": "Ils sont dans le doute, n'est-ce pas, au sujet de la rencontre de leur Seigneur? C'est Lui certes qui embrasse toute chose (par Sa science et Sa puissance)"
    },
    {
      "key": 4273,
      "id": "42-1",
      "translation": "H'â, Mîm"
    },
    {
      "key": 4274,
      "id": "42-2",
      "translation": "Aîn, Sîn, Qâf"
    },
    {
      "key": 4275,
      "id": "42-3",
      "translation": "C'est ainsi qu'Allah, le Puissant, le Sage, te fait des révélations, comme à ceux qui ont vécu avant toi"
    },
    {
      "key": 4276,
      "id": "42-4",
      "translation": "A Lui appartient ce qui est dans les cieux et ce qui est sur la terre. Et Il est le Sublime, le Très Grand"
    },
    {
      "key": 4277,
      "id": "42-5",
      "translation": "Peu s'en faut que les cieux ne se fendent depuis leur faîte quand les anges glorifient leur Seigneur, célèbrent Ses louanges et implorent le pardon pour ceux qui sont sur la terre. Allah est certes le Pardonneur, le Très Miséricordieux"
    },
    {
      "key": 4278,
      "id": "42-6",
      "translation": "Et quant à ceux qui prennent des protecteurs en dehors de Lui, Allah veille à ce qu'ils font. Et tu n'es pas pour eux un garant"
    },
    {
      "key": 4279,
      "id": "42-7",
      "translation": "Et c'est ainsi que Nous t'avons révélé un Coran arabe, afin que tu avertisses la Mère des cités (la Mecque) et ses alentours et que tu avertisses du Jour du rassemblement, - sur lequel il n'y a pas de doute - Un groupe au Paradis et un groupe dans la fournaise ardente"
    },
    {
      "key": 4280,
      "id": "42-8",
      "translation": "Et si Allah avait voulu, Il en aurait fait une seule communauté. Mais Il fait entrer qui Il veut dans Sa miséricorde. Et les injustes n'auront ni maître ni secoureur"
    },
    {
      "key": 4281,
      "id": "42-9",
      "translation": "Ont-ils pris des maîtres en dehors de Lui ? C'est Allah qui est le seul Maître et c'est Lui qui redonne la vie aux morts; et c'est Lui qui est Omnipotent"
    },
    {
      "key": 4282,
      "id": "42-10",
      "translation": "Sur toutes vos divergences, le jugement appartient à Allah. Tel est Allah mon Seigneur; en Lui je place ma confiance et c'est à Lui que je retourne [repentant]"
    },
    {
      "key": 4283,
      "id": "42-11",
      "translation": "Créateur des cieux et de la terre. Il vous a donné des épouses [issues] de vous-mêmes et des bestiaux par couples; par ce moyen Il vous multiplie. Il n'y a rien qui Lui ressemble; et c'est Lui l'Audient, le Clairvoyant"
    },
    {
      "key": 4284,
      "id": "42-12",
      "translation": "Il possède les clefs [des trésors] des cieux et de la terre. Il attribue Ses dons avec largesse, ou les restreint à qui Il veut. Certes, Il est Omniscient"
    },
    {
      "key": 4285,
      "id": "42-13",
      "translation": "Il vous a légiféré en matière de religion, ce qu'Il avait enjoint à Noé, ce que Nous t'avons révélé, ainsi que ce que Nous avons enjoint à Abraham, à Moïse et à Jésus: «Etablissez la religion; et n'en faites pas un sujet de divisions». Ce à quoi tu appelles les associateurs leur paraît énorme Allah élit et rapproche de Lui qui Il veut et guide vers Lui celui qui se repent"
    },
    {
      "key": 4286,
      "id": "42-14",
      "translation": "Ils ne se sont divisés qu'après avoir reçu la science et ceci par rivalité entre eux. Et si ce n'était une parole préalable de ton Seigneur pour un terme fixé, on aurait certainement tranché entre eux. Ceux à qui le Livre a été donné en héritage après eux sont vraiment à son sujet, dans un doute troublant"
    },
    {
      "key": 4287,
      "id": "42-15",
      "translation": "Appelle donc (les gens) à cela; reste droit comme il t'a été commandé; ne suis pas leurs passions; et dis: «Je crois en tout ce qu'Allah a fait descendre comme Livre, et il m'a été commandé d'être équitable entre vous. Allah est notre Seigneur et votre Seigneur. A nous nos œuvres et à vous vos œuvres. Aucun argument [ne peut trancher] entre nous et vous. Allah nous regroupera tous. Et vers Lui est la destination»"
    },
    {
      "key": 4288,
      "id": "42-16",
      "translation": "Et ceux qui discutent au sujet d'Allah, après qu'il a été répondu à [Son appel], leur argumentation est auprès d'Allah sans valeur. Une colère tombera sur eux et ils auront un dur châtiment"
    },
    {
      "key": 4289,
      "id": "42-17",
      "translation": "C'est Allah qui a fait descendre le Livre en toute vérité, ainsi que la balance. Et qu'en sais-tu? Peut-être que l'Heure est proche"
    },
    {
      "key": 4290,
      "id": "42-18",
      "translation": "Ceux qui n'y croient pas cherchent à la hâter; tandis que ceux qui croient en sont craintifs et savent qu'elle est la pure vérité. Et ceux qui discutent à propos de l'Heure sont dans un égarement lointain"
    },
    {
      "key": 4291,
      "id": "42-19",
      "translation": "Allah est doux envers Ses serviteurs. Il attribue [Ses biens] à qui Il veut. Et c'est Lui le Fort, le Puissant"
    },
    {
      "key": 4292,
      "id": "42-20",
      "translation": "Quiconque désire labourer [le champ] de la vie future, Nous augmenterons pour lui son labour. Quiconque désire labourer [le champ] de la présente vie, Nous lui en accorderons de [ses jouissances]; mais il n'aura pas de part dans l'au-delà"
    },
    {
      "key": 4293,
      "id": "42-21",
      "translation": "Ou bien auraient-ils des associés [à Allah] qui auraient établi pour eux des lois religieuses qu'Allah n'a jamais permises? Or, si l'arrêt décisif n'avait pas été prononcé, il aurait été tranché entre eux. Les injustes auront certes un châtiment douloureux"
    },
    {
      "key": 4294,
      "id": "42-22",
      "translation": "Tu verras les injustes épouvantés par ce qu'ils ont fait, et le châtiment s'abattra sur eux (inéluctablement). Et ceux qui croient et accomplissent les bonnes œuvres, seront dans les sites fleuris des jardins, ayant ce qu'ils voudront auprès de leur Seigneur. Telle est la grande grâce"
    },
    {
      "key": 4295,
      "id": "42-23",
      "translation": "Telle est la [bonne nouvelle] qu'Allah annonce à ceux de Ses serviteurs qui croient et accomplissent les bonnes œuvres! Dis: «Je ne vous en demande aucun salaire si ce n'est l'affection eu égard à [nos liens] de parenté». Et quiconque accomplit une bonne action, Nous répondons par [une récompense] plus belle encore. Allah est certes Pardonneur et Reconnaissant"
    },
    {
      "key": 4296,
      "id": "42-24",
      "translation": "Ou bien ils disent il a inventé un mensonge contre Allah. Or, si Allah voulait, Il scellerait ton cœur. Par Ses Paroles cependant, Allah efface le faux et confirme le vrai. Il connaît parfaitement le contenu des poitrines"
    },
    {
      "key": 4297,
      "id": "42-25",
      "translation": "Et c'est lui qui agrée de Ses serviteurs le repentir, pardonne les méfaits et sait ce que vous faites"
    },
    {
      "key": 4298,
      "id": "42-26",
      "translation": "et exauce [les vœux] de ceux qui croient et accomplissent les bonnes œuvres et leur accroît Sa faveur, tandis que les mécréants ont un dur châtiment"
    },
    {
      "key": 4299,
      "id": "42-27",
      "translation": "Si Allah attribuait Ses dons avec largesse à [tous] Ses serviteurs, ils commettraient des abus sur la terre; mais, Il fait descendre avec mesure ce qu'Il veut. Il connaît parfaitement Ses serviteurs et en est Clairvoyant"
    },
    {
      "key": 4300,
      "id": "42-28",
      "translation": "Et c'est Lui qui fait descendre la pluie après qu'on en a désespéré, et répand Sa miséricorde. Et c'est Lui le Maître, le Digne de louange"
    },
    {
      "key": 4301,
      "id": "42-29",
      "translation": "Parmi Ses preuves est la création des cieux et de la terre et des êtres vivants qu'Il y a disséminés. Il a en outre le pouvoir de les réunir quand Il voudra"
    },
    {
      "key": 4302,
      "id": "42-30",
      "translation": "Tout malheur qui vous atteint est dû à ce que vos mains ont acquis. Et Il pardonne beaucoup"
    },
    {
      "key": 4303,
      "id": "42-31",
      "translation": "Vous ne pouvez pas échapper à la puissance d'Allah sur la terre; et vous n'avez en dehors d'Allah, ni maître ni défenseur"
    },
    {
      "key": 4304,
      "id": "42-32",
      "translation": "Et parmi Ses preuves, sont les vaisseaux à travers la mer, semblables à des montagnes"
    },
    {
      "key": 4305,
      "id": "42-33",
      "translation": "S'Il veut, Il calme le vent, et les voilà qui restent immobiles à sa surface. Ce sont certainement là des preuves pour tout [homme] endurant et reconnaissant"
    },
    {
      "key": 4306,
      "id": "42-34",
      "translation": "Ou bien, Il les détruit en punition de ce qu'ils ont acquis [comme péchés]. Cependant, Il pardonne beaucoup"
    },
    {
      "key": 4307,
      "id": "42-35",
      "translation": "Ceux qui disputent à propos de Nos preuves savent bien qu'ils n'ont pas d'échappatoire"
    },
    {
      "key": 4308,
      "id": "42-36",
      "translation": "Tout ce qui vous a été donné [comme bien] n'est que jouissance de la vie présente; mais ce qui est auprès d'Allah est meilleur et plus durable pour ceux qui ont cru et qui placent leur confiance en leur Seigneur"
    },
    {
      "key": 4309,
      "id": "42-37",
      "translation": "qui évitent [de commettre] les péchés les plus graves ainsi que les turpitudes, et qui pardonnent après s'être mis en colère"
    },
    {
      "key": 4310,
      "id": "42-38",
      "translation": "qui répondent à l'appel de leur Seigneur, accomplissent la Salât, se consultent entre eux à propos de leurs affaires, dépensent de ce que Nous leur attribuons"
    },
    {
      "key": 4311,
      "id": "42-39",
      "translation": "et qui, atteints par l'injustice, ripostent"
    },
    {
      "key": 4312,
      "id": "42-40",
      "translation": "La sanction d'une mauvaise action est une mauvaise action [une peine] identique. Mais quiconque pardonne et réforme, son salaire incombe à Allah. Il n'aime point les injustes"
    },
    {
      "key": 4313,
      "id": "42-41",
      "translation": "Quant à ceux qui ripostent après avoir été lésés, ...ceux-là pas de voie (recours légal) contre eux"
    },
    {
      "key": 4314,
      "id": "42-42",
      "translation": "il n'y a de voie [de recours] que contre ceux qui lèsent les gens et commettent des abus, contrairement au droit, sur la terre: ceux-là auront un châtiment douloureux"
    },
    {
      "key": 4315,
      "id": "42-43",
      "translation": "Et celui qui endure et pardonne, cela en vérité, fait partie des bonnes dispositions et de la résolution dans les affaires"
    },
    {
      "key": 4316,
      "id": "42-44",
      "translation": "Et quiconque Allah égare n'a aucun protecteur après Lui. Cependant tu verras les injustes dire, en voyant le châtiment: «Y a-t-il un moyen de retourner [sur terre]?»"
    },
    {
      "key": 4317,
      "id": "42-45",
      "translation": "Et tu les verras exposés devant l'Enfer, confondus dans l'avilissement, et regardant d'un œil furtif, tandis que ceux qui ont cru diront: «Les perdants sont certes, ceux qui au Jour de la Résurrection font leur propre perte et celle de leurs familles». Les injustes subiront certes un châtiment permanent"
    },
    {
      "key": 4318,
      "id": "42-46",
      "translation": "Ils n'auront pas de protecteur en dehors d'Allah pour les secourir et quiconque Allah égare n'a plus aucune voie"
    },
    {
      "key": 4319,
      "id": "42-47",
      "translation": "Répondez à l'appel de votre Seigneur avant que ne vienne un jour dont Allah ne reportera jamais le terme. Ce jour-là, nul refuge pour vous et vous ne pourrez point nier (vos péchés)"
    },
    {
      "key": 4320,
      "id": "42-48",
      "translation": "S'ils se détournent, ...Nous ne t'avons pas envoyé pour assurer leur sauvegarde: tu n'es chargé que de transmettre [le message]. Et lorsque Nous faisons goûter à l'homme une miséricorde venant de Nous, il en exulte; mais si un malheur les atteint pour ce que leurs mains ont perpétré..., l'homme est alors très ingrat"
    },
    {
      "key": 4321,
      "id": "42-49",
      "translation": "A Allah appartient la royauté des cieux et de la terre. Il crée ce qu'Il veut. Il fait don de filles à qui Il veut, et don de garçons à qui Il veut"
    },
    {
      "key": 4322,
      "id": "42-50",
      "translation": "ou bien Il donne à la fois garçons et filles; et Il rend stérile qui Il veut. Il est certes Omniscient et Omnipotent"
    },
    {
      "key": 4323,
      "id": "42-51",
      "translation": "Il n'a pas été donné à un mortel qu'Allah lui parle autrement que par révélation ou de derrière un voile, ou qu'Il [Lui] envoie un messager (Ange) qui révèle, par Sa permission, ce qu'Il [Allah] veut. Il est Sublime et Sage"
    },
    {
      "key": 4324,
      "id": "42-52",
      "translation": "Et c'est ainsi que Nous t'avons révélé un esprit [le Coran] provenant de Notre ordre. Tu n'avais aucune connaissance du Livre ni de la foi; mais Nous en avons fait une lumière par laquelle Nous guidons qui Nous voulons parmi Nos serviteurs. Et en vérité tu guides vers un chemin droit"
    },
    {
      "key": 4325,
      "id": "42-53",
      "translation": "le chemin d'Allah à Qui appartient ce qui est dans les cieux et ce qui est sur la terre. Oui c'est à Allah que s'acheminent toutes les choses"
    },
    {
      "key": 4326,
      "id": "43-1",
      "translation": "H'â, Mîm"
    },
    {
      "key": 4327,
      "id": "43-2",
      "translation": "Par le Livre explicite"
    },
    {
      "key": 4328,
      "id": "43-3",
      "translation": "Nous en avons fait un Coran arabe afin que vous raisonniez"
    },
    {
      "key": 4329,
      "id": "43-4",
      "translation": "Il est auprès de Nous, dans l'Ecriture-Mère (l'original au ciel), sublime et rempli de sagesse"
    },
    {
      "key": 4330,
      "id": "43-5",
      "translation": "Quoi! Allons-Nous vous dispenser du Rappel [le Coran] pour la raison que vous êtes des gens outranciers"
    },
    {
      "key": 4331,
      "id": "43-6",
      "translation": "Que de prophètes avons-Nous envoyés aux Anciens"
    },
    {
      "key": 4332,
      "id": "43-7",
      "translation": "et pas un prophète ne leur venait qu'ils ne le tournaient en dérision"
    },
    {
      "key": 4333,
      "id": "43-8",
      "translation": "Nous avons fait périr de plus redoutables qu'eux! Et on a déjà cité l'exemple des anciens"
    },
    {
      "key": 4334,
      "id": "43-9",
      "translation": "Et si tu leur demandes: «Qui a créé les cieux et la terre?» Ils diront très certainement: «Le Puissant, l'Omniscient les a créés»"
    },
    {
      "key": 4335,
      "id": "43-10",
      "translation": "Celui qui vous a donné la terre pour berceau et vous y a tracé des sentiers afin que vous vous guidiez"
    },
    {
      "key": 4336,
      "id": "43-11",
      "translation": "Celui qui a fait descendre l'eau du ciel avec mesure et avec laquelle Nous ranimons une cité morte [aride]. Ainsi vous serez ressuscités"
    },
    {
      "key": 4337,
      "id": "43-12",
      "translation": "Celui qui a créé les couples dans leur totalité et a fait pour vous, des vaisseaux et des bestiaux, des montures"
    },
    {
      "key": 4338,
      "id": "43-13",
      "translation": "afin que vous vous installiez sur leurs dos, et qu'ensuite, après vous y être installés, vous vous rappeliez le bienfait de votre Seigneur et que vous disiez: «Gloire à Celui qui nous a soumis tout cela alors que nous n'étions pas capables de les dominer"
    },
    {
      "key": 4339,
      "id": "43-14",
      "translation": "C'est vers notre Seigneur que nous retournerons»"
    },
    {
      "key": 4340,
      "id": "43-15",
      "translation": "Et ils Lui firent de Ses serviteurs une partie [de Lui-Même]. L'homme est vraiment un ingrat déclaré"
    },
    {
      "key": 4341,
      "id": "43-16",
      "translation": "Ou bien Se serait-Il attribué des filles parmi ce qu'Il crée et accordé à vous par préférence des fils"
    },
    {
      "key": 4342,
      "id": "43-17",
      "translation": "Or, quand on annonce à l'un d'eux (la naissance) d'une semblable de ce qu'il attribue au Tout Miséricordieux, son visage s'assombrit d'un chagrin profond"
    },
    {
      "key": 4343,
      "id": "43-18",
      "translation": "Quoi! Cet être (la fille) élevé au milieu des parures et qui, dans la dispute, est incapable de se défendre par une argumentation claire et convaincante"
    },
    {
      "key": 4344,
      "id": "43-19",
      "translation": "Et ils firent des Anges qui sont les serviteurs du Tout Miséricordieux des [êtres] féminins! Etaient-ils témoins de leur création? Leur témoignage sera alors inscrit; et ils seront interrogés"
    },
    {
      "key": 4345,
      "id": "43-20",
      "translation": "Et ils dirent: «Si le Tout Miséricordieux avait voulu, nous ne les aurions pas adorés». Ils n'en ont aucune connaissance; ils ne font que se livrer à des conjectures"
    },
    {
      "key": 4346,
      "id": "43-21",
      "translation": "Ou bien, leur avions-Nous donné avant lui [le Coran] un Livre auquel ils seraient fermement attachés"
    },
    {
      "key": 4347,
      "id": "43-22",
      "translation": "Mais plutôt ils dirent: «Nous avons trouvé nos ancêtres sur une religion, et nous nous guidons sur leurs traces»"
    },
    {
      "key": 4348,
      "id": "43-23",
      "translation": "Et c'est ainsi que Nous n'avons pas envoyé avant toi d'avertisseur en une cité, sans que ses gens aisés n'aient dit: «Nous avons trouvé nos ancêtres sur une religion et nous suivons leurs traces»"
    },
    {
      "key": 4349,
      "id": "43-24",
      "translation": "Il dit: «Même si je viens à vous avec une meilleure direction que celle sur laquelle vous avez trouvé vos ancêtres?» Ils dirent: «Nous ne croyons pas au message avec lequel vous avez été envoyés»"
    },
    {
      "key": 4350,
      "id": "43-25",
      "translation": "Nous Nous vengeâmes d'eux. Regarde ce qu'il est advenu de ceux qui criaient au mensonge"
    },
    {
      "key": 4351,
      "id": "43-26",
      "translation": "Et lorsqu'Abraham dit à son père et à son peuple: «Je désavoue totalement ce que vous adorez"
    },
    {
      "key": 4352,
      "id": "43-27",
      "translation": "à l'exception de Celui qui m'a créé, car c'est Lui en vérité qui me guidera»"
    },
    {
      "key": 4353,
      "id": "43-28",
      "translation": "Et il en fit une parole qui devait se perpétuer parmi sa descendance. Peut-être reviendront-ils"
    },
    {
      "key": 4354,
      "id": "43-29",
      "translation": "Mais à ces gens ainsi qu'à leurs ancêtres J'ai accordé la jouissance jusqu'à ce que leur vinrent la Vérité (le Coran) et un Messager explicite"
    },
    {
      "key": 4355,
      "id": "43-30",
      "translation": "Et quand la Vérité leur vint, ils dirent: «C'est de la magie et nous n'y croyons pas»"
    },
    {
      "key": 4356,
      "id": "43-31",
      "translation": "Et ils dirent: «Pourquoi n'a-t-on pas fait descendre ce Coran sur un haut personnage de l'une des deux cités?» (la Mecque et Tâ'îf)"
    },
    {
      "key": 4357,
      "id": "43-32",
      "translation": "Est-ce eux qui distribuent la miséricorde de ton Seigneur? C'est Nous qui avons réparti entre eux leur subsistance dans la vie présente et qui les avons élevés en grades les uns sur les autres, afin que les uns prennent les autres à leur service. La miséricorde de ton Seigneur vaut mieux, cependant, que ce qu'ils amassent"
    },
    {
      "key": 4358,
      "id": "43-33",
      "translation": "Si les hommes ne devaient pas constituer une seule communauté (mécréante), Nous aurions certes pourvu les maisons de ceux qui ne croient pas au Tout Miséricordieux, de toits d'argent avec des escaliers pour y monter"
    },
    {
      "key": 4359,
      "id": "43-34",
      "translation": "(Nous aurions pourvu) leurs maisons de portes et de divans où ils s'accouderaient"
    },
    {
      "key": 4360,
      "id": "43-35",
      "translation": "ainsi que des ornements. Et tout cela ne serait que jouissance temporaire de la vie d'ici-bas, alors que l'au-delà, auprès de ton Seigneur, est pour les pieux"
    },
    {
      "key": 4361,
      "id": "43-36",
      "translation": "Et quiconque s'aveugle (et s'écarte) du rappel du Tout Miséricordieux, Nous lui désignons un diable qui devient son compagnon inséparable"
    },
    {
      "key": 4362,
      "id": "43-37",
      "translation": "Ils [Les diables] détournent certes [les hommes] du droit chemin, tandis que ceux-ci s'estiment être bien guidés"
    },
    {
      "key": 4363,
      "id": "43-38",
      "translation": "Lorsque cet [homme] vient à Nous, il dira [à son démon]: «Hélas! Que n'y a-t-il entre toi et moi la distance entre les deux orients [l'Est et l'Ouest]» - Quel mauvais compagnon [que tu es]"
    },
    {
      "key": 4364,
      "id": "43-39",
      "translation": "Il ne vous profitera point ce jour-là - du moment que vous avez été injustes - que vous soyez associés dans le châtiment"
    },
    {
      "key": 4365,
      "id": "43-40",
      "translation": "Est-ce donc toi qui fait entendre les sourds ou qui guide les aveugles et ceux qui sont dans un égarement évident"
    },
    {
      "key": 4366,
      "id": "43-41",
      "translation": "Soit que Nous t'enlevons [te ferons mourir] et alors Nous Nous vengerons d'eux"
    },
    {
      "key": 4367,
      "id": "43-42",
      "translation": "ou bien que Nous te ferons voir ce que Nous leur avons promis [le châtiment]; car Nous avons sur eux un pouvoir certain"
    },
    {
      "key": 4368,
      "id": "43-43",
      "translation": "Tiens fermement à ce qui t'a été révélé car tu es sur le droit chemin"
    },
    {
      "key": 4369,
      "id": "43-44",
      "translation": "C'est certainement un rappel [le Coran] pour toi et ton peuple. Et vous en serez interrogés"
    },
    {
      "key": 4370,
      "id": "43-45",
      "translation": "Et demande à ceux de Nos messagers que Nous avons envoyés avant toi, si Nous avons institué, en dehors du Tout Miséricordieux, des divinités à adorer"
    },
    {
      "key": 4371,
      "id": "43-46",
      "translation": "Nous avons effectivement envoyé Moïse avec Nos miracles, à Pharaon et à ses notables. Il dit: «Je suis le Messager du Seigneur de l'univers»"
    },
    {
      "key": 4372,
      "id": "43-47",
      "translation": "Puis, lorsqu'il vint à eux avec Nos miracles, voilà qu'ils en rirent"
    },
    {
      "key": 4373,
      "id": "43-48",
      "translation": "Chaque miracle que Nous leur montrions était plus probant que son précédent. Et Nous les saisîmes par le châtiment, peut-être reviendront-ils [vers Nous]"
    },
    {
      "key": 4374,
      "id": "43-49",
      "translation": "Et ils dirent: «O magicien! Implore pour nous ton Seigneur au nom de l'engagement qu'Il a pris envers toi. Nous suivrons le droit chemin»"
    },
    {
      "key": 4375,
      "id": "43-50",
      "translation": "Puis quand Nous eûmes écarté d'eux le châtiment, voilà qu'ils violèrent leurs engagements"
    },
    {
      "key": 4376,
      "id": "43-51",
      "translation": "Et Pharaon fit une proclamation à son peuple et dit: «O mon peuple! Le royaume de Misr [l'Egypte] ne m'appartient-il pas ainsi que ces canaux qui coulent à mes pieds? N'observez-vous donc pas"
    },
    {
      "key": 4377,
      "id": "43-52",
      "translation": "Ne suis-je pas meilleur que ce misérable qui sait à peine s'exprimer"
    },
    {
      "key": 4378,
      "id": "43-53",
      "translation": "Pourquoi ne lui a-t-on pas lancé des bracelets d'or? Pourquoi les Anges ne l'ont-ils pas accompagné?»"
    },
    {
      "key": 4379,
      "id": "43-54",
      "translation": "Ainsi chercha-t-il à étourdir son peuple et ainsi lui obéirent-ils car ils étaient des gens pervers"
    },
    {
      "key": 4380,
      "id": "43-55",
      "translation": "Puis lorsqu'ils Nous eurent irrité, Nous Nous vengeâmes d'eux et les noyâmes tous"
    },
    {
      "key": 4381,
      "id": "43-56",
      "translation": "Nous fîmes d'eux un antécédant et un exemple [une leçon] pour la postérité"
    },
    {
      "key": 4382,
      "id": "43-57",
      "translation": "Quand on cite l'exemple du fils de Marie, ton peuple s'en détourne"
    },
    {
      "key": 4383,
      "id": "43-58",
      "translation": "en disant: «Nos dieux sont-ils meilleurs, ou bien lui?» Ce n'est que par polémique qu'ils te le citent comme exemple. Ce sont plutôt des gens chicaniers"
    },
    {
      "key": 4384,
      "id": "43-59",
      "translation": "Il (Jésus) n'était qu'un Serviteur que Nous avions comblé de bienfaits et que Nous avions désigné en exemple aux Enfants d'Israël"
    },
    {
      "key": 4385,
      "id": "43-60",
      "translation": "Si Nous voulions, Nous ferions de vous des Anges qui vous succéderaient sur la terre"
    },
    {
      "key": 4386,
      "id": "43-61",
      "translation": "Il sera un signe au sujet de l'Heure. N'en doutez point. Et suivez-moi: voilà un droit chemin"
    },
    {
      "key": 4387,
      "id": "43-62",
      "translation": "Que le Diable ne vous détourne point! Car il est pour vous un ennemi déclaré"
    },
    {
      "key": 4388,
      "id": "43-63",
      "translation": "Et quand Jésus apporta les preuves, il dit: «Je suis venu à vous avec la sagesse et pour vous expliquer certains de vos sujets de désaccord. Craignez Allah donc et obéissez-moi"
    },
    {
      "key": 4389,
      "id": "43-64",
      "translation": "Allah est en vérité mon Seigneur et votre Seigneur. Adorez-Le donc. Voilà un droit chemin»"
    },
    {
      "key": 4390,
      "id": "43-65",
      "translation": "Mais les factions divergèrent entre elles. Malheur donc aux injustes du châtiment d'un jour douloureux"
    },
    {
      "key": 4391,
      "id": "43-66",
      "translation": "Attendent-ils seulement que l'Heure leur vienne à l'improviste, sans qu'ils ne s'en rendent compte"
    },
    {
      "key": 4392,
      "id": "43-67",
      "translation": "Les amis, ce jour-là, seront ennemis les uns des autres; excepté les pieux"
    },
    {
      "key": 4393,
      "id": "43-68",
      "translation": "«O Mes serviteurs! Vous ne devez avoir aucune crainte aujourd'hui; vous ne serez point affligés"
    },
    {
      "key": 4394,
      "id": "43-69",
      "translation": "Ceux qui croient en Nos signes et sont musulmans"
    },
    {
      "key": 4395,
      "id": "43-70",
      "translation": "«Entrez au Paradis, vous et vos épouses, vous y serez fêtés»"
    },
    {
      "key": 4396,
      "id": "43-71",
      "translation": "On fera circuler parmi eux des plats d'or et des coupes; et il y aura là [pour eux] tout ce que les âmes désirent et ce qui réjouit les yeux;» - et vous y demeurerez éternellement"
    },
    {
      "key": 4397,
      "id": "43-72",
      "translation": "Tel est le Paradis qu'on vous fait hériter pour ce que vous faisiez"
    },
    {
      "key": 4398,
      "id": "43-73",
      "translation": "Il y aura là pour vous beaucoup de fruits dont vous mangerez»"
    },
    {
      "key": 4399,
      "id": "43-74",
      "translation": "Quant aux criminels, ils demeureront éternellement dans le châtiment de l'Enfer"
    },
    {
      "key": 4400,
      "id": "43-75",
      "translation": "qui ne sera jamais interrompu pour eux et où ils seront en désespoir"
    },
    {
      "key": 4401,
      "id": "43-76",
      "translation": "Nous ne leur avons fait aucun tort, mais c'étaient eux les injustes"
    },
    {
      "key": 4402,
      "id": "43-77",
      "translation": "et ils crieront: «O Mâlik! Que ton Seigneur nous achève!» Il dira: «En vérité, vous êtes pour y demeurer [éternellement]»"
    },
    {
      "key": 4403,
      "id": "43-78",
      "translation": "«Certes, Nous vous avions apporté la Vérité; mais la plupart d'entre vous détestaient la Vérité»"
    },
    {
      "key": 4404,
      "id": "43-79",
      "translation": "Ont-ils pris quelque décision [entre eux]? Car c'est Nous qui décidons"
    },
    {
      "key": 4405,
      "id": "43-80",
      "translation": "Ou bien escomptent-ils que Nous n'entendons pas leur secret ni leurs délibérations? Mais si! Nos Anges prennent note auprès d'eux"
    },
    {
      "key": 4406,
      "id": "43-81",
      "translation": "Dis: «Si le Tout Miséricordieux avait un enfant, alors je serais le premier à l'adorer»"
    },
    {
      "key": 4407,
      "id": "43-82",
      "translation": "Gloire au Seigneur des cieux et de la terre, Seigneur du Trône; Il transcende ce qu'ils décrivent"
    },
    {
      "key": 4408,
      "id": "43-83",
      "translation": "Laisse-les donc s'enfoncer dans leur fausseté et s'amuser jusqu'à ce qu'ils rencontrent le jour qui leur est promis"
    },
    {
      "key": 4409,
      "id": "43-84",
      "translation": "C'est Lui qui est Dieu dans le ciel et Dieu sur terre; et c'est Lui le Sage, l'Omniscient"
    },
    {
      "key": 4410,
      "id": "43-85",
      "translation": "Et béni soit Celui à qui appartient la souveraineté des cieux et de la terre et de ce qui est entre eux. Il détient la science de l'Heure. Et c'est vers Lui que vous serez ramenés"
    },
    {
      "key": 4411,
      "id": "43-86",
      "translation": "Et ceux qu'ils invoquent en dehors de Lui n'ont aucun pouvoir d'intercession, à l'exception de ceux qui auront témoigné de la vérité en pleine connaissance de cause"
    },
    {
      "key": 4412,
      "id": "43-87",
      "translation": "Et si tu leur demandes qui les a créés, ils diront très certainement: «Allah». Comment se fait-il donc qu'ils se détournent"
    },
    {
      "key": 4413,
      "id": "43-88",
      "translation": "Et sa parole (la parole du Prophète à Allah): «Seigneur, ce sont là des gens qui ne croient pas»"
    },
    {
      "key": 4414,
      "id": "43-89",
      "translation": "Et bien, éloigne-toi d'eux (pardonne-leur); et dis: «Salut!» Car ils sauront bientôt"
    },
    {
      "key": 4415,
      "id": "44-1",
      "translation": "H'â, Mîm"
    },
    {
      "key": 4416,
      "id": "44-2",
      "translation": "Par le Livre (le Coran) explicite"
    },
    {
      "key": 4417,
      "id": "44-3",
      "translation": "Nous l'avons fait descendre en une nuit bénie, Nous sommes en vérité Celui qui avertit"
    },
    {
      "key": 4418,
      "id": "44-4",
      "translation": "durant laquelle est décidé tout ordre sage"
    },
    {
      "key": 4419,
      "id": "44-5",
      "translation": "c'est là un commandement venant de Nous. C'est Nous qui envoyons [les Messagers]"
    },
    {
      "key": 4420,
      "id": "44-6",
      "translation": "à titre de miséricorde de la part de ton Seigneur, car c'est Lui l'Audient, l'Omniscient"
    },
    {
      "key": 4421,
      "id": "44-7",
      "translation": "Seigneur des cieux et de la terre et de ce qui est entre eux, si seulement vous pouviez en avoir la conviction"
    },
    {
      "key": 4422,
      "id": "44-8",
      "translation": "Point de divinité à part Lui. Il donne la vie et donne la mort, et Il est votre Seigneur et le Seigneur de vos premiers ancêtres"
    },
    {
      "key": 4423,
      "id": "44-9",
      "translation": "Mais ces gens-là, dans le doute, s'amusent"
    },
    {
      "key": 4424,
      "id": "44-10",
      "translation": "Eh bien, attends le jour où le ciel apportera une fumée visible"
    },
    {
      "key": 4425,
      "id": "44-11",
      "translation": "qui couvrira les gens. Ce sera un châtiment douloureux"
    },
    {
      "key": 4426,
      "id": "44-12",
      "translation": "«Seigneur, éloigne de nous le châtiment. Car, [à présent] nous croyons»"
    },
    {
      "key": 4427,
      "id": "44-13",
      "translation": "D'où leur vient cette prise de conscience alors qu'un Messager explicite leur est déjà venu"
    },
    {
      "key": 4428,
      "id": "44-14",
      "translation": "Puis ils s'en détournèrent en disant: «C'est un homme instruit [par d'autres], un possédé»"
    },
    {
      "key": 4429,
      "id": "44-15",
      "translation": "Nous dissiperons le châtiment pour peu de temps; car vous récidiverez"
    },
    {
      "key": 4430,
      "id": "44-16",
      "translation": "Le jour où Nous userons de la plus grande violence et Nous Nous vengerons"
    },
    {
      "key": 4431,
      "id": "44-17",
      "translation": "Et avant eux Nous avons déjà éprouvé le peuple de Pharaon, quand un noble Messager leur était venu"
    },
    {
      "key": 4432,
      "id": "44-18",
      "translation": "[leur disant]: «Livrez-moi les serviteurs d'Allah! Je suis pour vous un Messager digne de confiance"
    },
    {
      "key": 4433,
      "id": "44-19",
      "translation": "Ne vous montrez pas hautains vis-à-vis d'Allah, car je vous apporte une preuve évidente"
    },
    {
      "key": 4434,
      "id": "44-20",
      "translation": "Et je cherche protection auprès de mon Seigneur et votre Seigneur, pour que vous ne me lapidiez pas"
    },
    {
      "key": 4435,
      "id": "44-21",
      "translation": "Si vous ne voulez pas croire en moi, éloignez-vous de moi»"
    },
    {
      "key": 4436,
      "id": "44-22",
      "translation": "Il invoqua alors son Seigneur: «Ce sont des gens criminels»"
    },
    {
      "key": 4437,
      "id": "44-23",
      "translation": "«Voyage de nuit avec Mes serviteurs; vous serez poursuivis"
    },
    {
      "key": 4438,
      "id": "44-24",
      "translation": "Laisse la mer calme; [telle que tu l'as franchie] ce sont, des armées [vouées] à la noyade»"
    },
    {
      "key": 4439,
      "id": "44-25",
      "translation": "Que de jardins et de sources ils laissèrent [derrière eux]"
    },
    {
      "key": 4440,
      "id": "44-26",
      "translation": "que de champs et de superbes résidences"
    },
    {
      "key": 4441,
      "id": "44-27",
      "translation": "que de délices au sein desquels ils se réjouissaient"
    },
    {
      "key": 4442,
      "id": "44-28",
      "translation": "Il en fut ainsi et Nous fîmes qu'un autre peuple en hérita"
    },
    {
      "key": 4443,
      "id": "44-29",
      "translation": "Ni le ciel ni la terre ne les pleurèrent et ils n'eurent aucun délai"
    },
    {
      "key": 4444,
      "id": "44-30",
      "translation": "Et certes, Nous sauvâmes les Enfants d'Israël du châtiment avilissant"
    },
    {
      "key": 4445,
      "id": "44-31",
      "translation": "de Pharaon qui était hautain et outrancier"
    },
    {
      "key": 4446,
      "id": "44-32",
      "translation": "A bon escient Nous les choisîmes parmi tous les peuples de l'univers"
    },
    {
      "key": 4447,
      "id": "44-33",
      "translation": "et leur apportâmes des miracles de quoi les mettre manifestement à l'épreuve"
    },
    {
      "key": 4448,
      "id": "44-34",
      "translation": "Ceux-là (les Mecquois) disent"
    },
    {
      "key": 4449,
      "id": "44-35",
      "translation": "«Il n'y a pour nous qu'une mort, la première. Et nous ne serons pas ressuscités"
    },
    {
      "key": 4450,
      "id": "44-36",
      "translation": "Faites donc revenir nos ancêtres, si vous êtes véridiques»"
    },
    {
      "key": 4451,
      "id": "44-37",
      "translation": "Sont-ils les meilleurs ou le peuple de Tubba' et ceux qui les ont précédés? Nous les avons fait périr parce que vraiment ils étaient criminels"
    },
    {
      "key": 4452,
      "id": "44-38",
      "translation": "Ce n'est pas par divertissement que Nous avons créé les cieux et la terre et ce qui est entre eux"
    },
    {
      "key": 4453,
      "id": "44-39",
      "translation": "Nous ne les avons créés qu'en toute vérité. Mais la plupart d'entre eux ne savent pas"
    },
    {
      "key": 4454,
      "id": "44-40",
      "translation": "En vérité, le Jour de la Décision sera leur rendez-vous à tous"
    },
    {
      "key": 4455,
      "id": "44-41",
      "translation": "le jour où un allié ne sera d'aucune utilité à un [autre] allié; et ils ne seront point secourus non plus"
    },
    {
      "key": 4456,
      "id": "44-42",
      "translation": "sauf celui à qui Allah fera miséricorde. Car c'est Lui, le Puissant, le Très Miséricordieux"
    },
    {
      "key": 4457,
      "id": "44-43",
      "translation": "Certes l'arbre de Zakkûm"
    },
    {
      "key": 4458,
      "id": "44-44",
      "translation": "sera la nourriture du grand pécheur"
    },
    {
      "key": 4459,
      "id": "44-45",
      "translation": "Comme du métal en fusion; il bouillonnera dans les ventres"
    },
    {
      "key": 4460,
      "id": "44-46",
      "translation": "comme le bouillonnement de l'eau surchauffée"
    },
    {
      "key": 4461,
      "id": "44-47",
      "translation": "Qu'on le saisisse et qu'on l'emporte en plein dans la fournaise"
    },
    {
      "key": 4462,
      "id": "44-48",
      "translation": "qu'on ensuite sur sa tête de l'eau bouillante comme châtiment"
    },
    {
      "key": 4463,
      "id": "44-49",
      "translation": "Goûte! Toi [qui prétendait être] le puissant, le noble"
    },
    {
      "key": 4464,
      "id": "44-50",
      "translation": "Voilà ce dont vous doutiez"
    },
    {
      "key": 4465,
      "id": "44-51",
      "translation": "Les pieux seront dans une demeure sûre"
    },
    {
      "key": 4466,
      "id": "44-52",
      "translation": "parmi des jardins et des sources"
    },
    {
      "key": 4467,
      "id": "44-53",
      "translation": "Ils porteront des vêtements de satin et de brocart et seront placés face à face"
    },
    {
      "key": 4468,
      "id": "44-54",
      "translation": "C'est ainsi! Et Nous leur donnerons pour épouses des houris aux grands yeux"
    },
    {
      "key": 4469,
      "id": "44-55",
      "translation": "Ils y demanderont en toute quiétude toutes sortes de fruits"
    },
    {
      "key": 4470,
      "id": "44-56",
      "translation": "Ils n'y goûteront pas à la mort sauf leur mort première. Et [Allah] les protègera du châtiment de la Fournaise"
    },
    {
      "key": 4471,
      "id": "44-57",
      "translation": "c'est là une grâce de ton Seigneur. Et c'est là l'énorme succès"
    },
    {
      "key": 4472,
      "id": "44-58",
      "translation": "Nous ne l'avons facilité dans ta langue, qu'afin qu'ils se rappellent"
    },
    {
      "key": 4473,
      "id": "44-59",
      "translation": "Attends donc. Eux aussi attendent"
    },
    {
      "key": 4474,
      "id": "45-1",
      "translation": "H'â, Mîm"
    },
    {
      "key": 4475,
      "id": "45-2",
      "translation": "La révélation du Livre émane d'Allah, le Puissant, le Sage"
    },
    {
      "key": 4476,
      "id": "45-3",
      "translation": "Il y a certes dans les cieux et la terre des preuves pour les croyants"
    },
    {
      "key": 4477,
      "id": "45-4",
      "translation": "Et dans votre propre création, et dans ce qu'Il dissémine comme animaux, il y a des signes pour des gens qui croient avec certitude"
    },
    {
      "key": 4478,
      "id": "45-5",
      "translation": "De même dans l'alternance de la nuit et du jour, et dans ce qu'Allah fait descendre du ciel comme subsistance [pluie] par laquelle Il redonne la vie à la terre une fois morte, et dans la distribution des vents, il y a des signes pour des gens qui raisonnent"
    },
    {
      "key": 4479,
      "id": "45-6",
      "translation": "Voilà les versets d'Allah que Nous te récitons en toute vérité. Alors dans quelle parole croiront-ils après [la parole] d'Allah et après Ses signes"
    },
    {
      "key": 4480,
      "id": "45-7",
      "translation": "Malheur à tout grand imposteur pécheur"
    },
    {
      "key": 4481,
      "id": "45-8",
      "translation": "Il entend les versets d'Allah qu'on lui récite puis persiste dans son orgueil, comme s'il ne les avait jamais entendus. Annonce-lui donc un châtiment douloureux"
    },
    {
      "key": 4482,
      "id": "45-9",
      "translation": "S'il a connaissance de quelques-uns de Nos versets, il les tourne en dérision. Ceux-là auront un châtiment avilissant"
    },
    {
      "key": 4483,
      "id": "45-10",
      "translation": "l'Enfer est à leurs trousses. Ce qu'ils auront acquis ne leur servira à rien, ni ce qu'ils auront pris comme protecteurs, en dehors d'Allah. Ils auront un énorme châtiment"
    },
    {
      "key": 4484,
      "id": "45-11",
      "translation": "Ceci [le Coran] est un guide. Et ceux qui récusent les versets de leur Seigneur auront le supplice d'un châtiment douloureux"
    },
    {
      "key": 4485,
      "id": "45-12",
      "translation": "Allah c'est Lui qui vous a assujetti la mer, afin que les vaisseaux y voguent, par Son ordre, et que vous alliez en quête de sa grâce afin que vous soyez reconnaissants"
    },
    {
      "key": 4486,
      "id": "45-13",
      "translation": "Et Il vous a assujetti tout ce qui est dans les cieux et sur la terre, le tout venant de Lui. Il y a là des signes pour des gens qui réfléchissent"
    },
    {
      "key": 4487,
      "id": "45-14",
      "translation": "Dis à ceux qui ont cru de pardonner à ceux qui n'espèrent pas les jours d'Allah afin qu'Il rétribue [chaque] peuple pour les acquis qu'ils faisaient"
    },
    {
      "key": 4488,
      "id": "45-15",
      "translation": "Quiconque fait le bien, le fait pour lui-même; et quiconque agit mal, agit contre lui-même. Puis vous serez ramenés vers votre Seigneur"
    },
    {
      "key": 4489,
      "id": "45-16",
      "translation": "Nous avons effectivement apporté aux Enfants d'Israël le Livre, la sagesse, la prophétie, et leur avons attribué de bonnes choses, et les préférâmes aux autres humains [leurs contemporains]"
    },
    {
      "key": 4490,
      "id": "45-17",
      "translation": "Et Nous leur avons apporté des preuves évidentes de l'Ordre. Ils ne divergèrent qu'après que la science leur fut venue par agressivité entre eux. Ton Seigneur décidera parmi eux, au Jour de la Résurrection, sur ce en quoi ils divergeaient"
    },
    {
      "key": 4491,
      "id": "45-18",
      "translation": "Puis Nous t'avons mis sur la voie de l'Ordre [une religion claire et parfaite]. Suis-la donc et ne suis pas les passions de ceux qui ne savent pas"
    },
    {
      "key": 4492,
      "id": "45-19",
      "translation": "Ils ne te seront d'aucune utilité vis-à-vis d'Allah. Les injustes sont vraiment alliés les uns des autres; tandis qu'Allah est le Protecteur des pieux"
    },
    {
      "key": 4493,
      "id": "45-20",
      "translation": "Ceci [le Coran] constitue pour les hommes une source de clarté, un guide et une miséricorde pour des gens qui croient avec certitude"
    },
    {
      "key": 4494,
      "id": "45-21",
      "translation": "Ceux qui commettent des mauvaises actions comptent-ils que Nous allons les traiter comme ceux qui croient et accomplissent les bonnes œuvres, dans leur vie et dans leur mort? Comme ils jugent mal"
    },
    {
      "key": 4495,
      "id": "45-22",
      "translation": "Et Allah a créé les cieux et la terre en toute vérité et afin que chaque âme soit rétribuée selon ce qu'elle a acquis. Ils ne seront cependant pas lésés"
    },
    {
      "key": 4496,
      "id": "45-23",
      "translation": "Vois-tu celui qui prend sa passion pour sa propre divinité? Et Allah l'égare sciemment et scelle son ouïe et son cœur et étend un voile sur sa vue. Qui donc peut le guider après Allah? Ne vous rappelez-vous donc pas"
    },
    {
      "key": 4497,
      "id": "45-24",
      "translation": "Et ils dirent: «Il n'y a pour nous que la vie d'ici-bas: nous mourons et nous vivons et seul le temps nous fait périr». Ils n'ont de cela aucune connaissance: ils ne font qu'émettre des conjectures"
    },
    {
      "key": 4498,
      "id": "45-25",
      "translation": "Et quand on leur récite Nos versets bien clairs, leur seul argument est de dire: «Faites revenir nos ancêtres si vous êtes véridiques»"
    },
    {
      "key": 4499,
      "id": "45-26",
      "translation": "Dis: «Allah vous donne la vie puis Il vous donne la mort. Ensuite Il vous réunira le Jour de la Résurrection, il n'y a pas de doute à ce sujet, mais la plupart des gens ne savent pas"
    },
    {
      "key": 4500,
      "id": "45-27",
      "translation": "A Allah appartient le royaume des cieux et de la terre. Et le jour où l'Heure arrivera, ce jour-là, les imposteurs seront perdus"
    },
    {
      "key": 4501,
      "id": "45-28",
      "translation": "Et tu verras chaque communauté agenouillée. Chaque communauté sera appelée vers son livre. On vous rétribuera aujourd'hui selon ce que vous œuvriez"
    },
    {
      "key": 4502,
      "id": "45-29",
      "translation": "Voilà Notre Livre. Il parle de vous en toute vérité car Nous enregistrions [tout] ce que vous faisiez»"
    },
    {
      "key": 4503,
      "id": "45-30",
      "translation": "Ceux qui ont cru et fait de bonnes œuvres, leur Seigneur les fera entrer dans Sa miséricorde. Voilà le succès évident"
    },
    {
      "key": 4504,
      "id": "45-31",
      "translation": "Et quant à ceux qui ont mécru [il sera dit]: «Mes versets ne vous étaient-ils pas récités? Mais vous vous enfliez d'orgueil et vous étiez des gens criminels»"
    },
    {
      "key": 4505,
      "id": "45-32",
      "translation": "Et quand on disait: «La promesse d'Allah est vérité; et l'Heure n'est pas l'objet d'un doute», vous disiez: «Nous ne savons pas ce que c'est que l'Heure; et nous ne faisions à son sujet que de simples conjectures et nous ne sommes pas convaincus [qu'elle arrivera]"
    },
    {
      "key": 4506,
      "id": "45-33",
      "translation": "Et leur apparaîtra [la laideur] de leurs mauvaises actions. Et ce dont ils se moquaient les cernera"
    },
    {
      "key": 4507,
      "id": "45-34",
      "translation": "Et on leur dira: «Aujourd'hui Nous vous oublions comme vous avez oublié la rencontre de votre jour que voici. Votre refuge est le Feu; et vous n'aurez point de secoureurs"
    },
    {
      "key": 4508,
      "id": "45-35",
      "translation": "Cela parce que vous preniez en raillerie les versets d'Allah et que la vie d'ici-bas vous trompait». Ce jour-là on ne les en fera pas sortir et on ne les excusera pas non plus"
    },
    {
      "key": 4509,
      "id": "45-36",
      "translation": "Louange à Allah, Seigneur des cieux et Seigneur de la terre: Seigneur de l'univers"
    },
    {
      "key": 4510,
      "id": "45-37",
      "translation": "Et à Lui la grandeur dans les cieux et la terre. Et c'est Lui le Puissant, le Sage"
    },
    {
      "key": 4511,
      "id": "46-1",
      "translation": "H'â, Mîm"
    },
    {
      "key": 4512,
      "id": "46-2",
      "translation": "La révélation du Livre émane d'Allah, le Puissant, le Sage"
    },
    {
      "key": 4513,
      "id": "46-3",
      "translation": "Nous n'avons créé les cieux et la terre et ce qui est entre eux qu'en toute vérité et [pour] un terme fixé. Ceux qui ont mécru se détournent de ce dont ils ont été avertis"
    },
    {
      "key": 4514,
      "id": "46-4",
      "translation": "Dis: «Que pensez-vous de ceux que vous invoquez en dehors d'Allah? Montrez-moi donc ce qu'ils ont créé de la terre! Ou ont-ils dans les cieux une participation avec Dieu? Apportez-moi un Livre antérieur à celui-ci (le Coran) ou même un vestige d'une science, si vous êtes véridiques»"
    },
    {
      "key": 4515,
      "id": "46-5",
      "translation": "Et qui est plus égaré que celui qui invoque en dehors d'Allah, et que la vie ne saura lui répondre jusqu'au Jour de la Résurrection? Et elles [leurs divinités] sont indifférentes à leur invocation"
    },
    {
      "key": 4516,
      "id": "46-6",
      "translation": "Et quand les gens seront rassemblés [pour le Jugement] elles seront leurs ennemies et nieront leur adoration [pour elles]"
    },
    {
      "key": 4517,
      "id": "46-7",
      "translation": "Et quand on leur récite Nos versets bien clairs, ceux qui ont mécru disent à propos de la vérité, une fois venue à eux: «C'est de la magie manifeste»"
    },
    {
      "key": 4518,
      "id": "46-8",
      "translation": "Ou bien ils disent: «Il l'a inventé!» Dis: «Si je l'ai inventé alors vous ne pourrez rien pour moi contre [la punition] d'Allah. Il sait parfaitement ce que vous propagez (en calomnies contre le Coran): Allah est suffisant comme témoin entre moi et vous. Et c'est Lui le Pardonneur, le Très Miséricordieux»"
    },
    {
      "key": 4519,
      "id": "46-9",
      "translation": "Dis: «Je ne suis pas une innovation parmi les messagers; et je ne sais pas ce que l'on fera de moi, ni de vous. Je ne fais que suivre ce qui m'est révélé; et je ne suis qu'un avertisseur clair»"
    },
    {
      "key": 4520,
      "id": "46-10",
      "translation": "Dis: «Que direz-vous si [cette révélation s'avère] venir d'Allah et que vous n'y croyez pas qu'un témoin parmi les fils d'Israël en atteste la conformité [au Pentateuque] et y croit pendant que vous, vous le repoussez avec orgueil... En vérité Allah ne guide pas les gens injustes!»"
    },
    {
      "key": 4521,
      "id": "46-11",
      "translation": "Et ceux qui ont mécru dirent à ceux qui ont cru: «Si ceci était un bien, ils (les pauvres) ne nous y auraient pas devancés». Et comme ils ne se seront pas laissés guider par lui ils diront: «Ce n'est qu'un vieux mensonge!»"
    },
    {
      "key": 4522,
      "id": "46-12",
      "translation": "Et avant lui, il y avait le Livre de Moïse, comme guide et comme miséricorde. Et ceci est [un Livre] confirmateur, en langue arabe, pour avertir ceux qui font du tort et pour faire la bonne annonce aux bienfaisants"
    },
    {
      "key": 4523,
      "id": "46-13",
      "translation": "Ceux qui disent: «Notre Seigneur est Allah» et qui ensuite se tiennent sur le droit chemin. Ils ne doivent avoir aucune crainte et ne seront point affligés"
    },
    {
      "key": 4524,
      "id": "46-14",
      "translation": "Ceux-là sont les gens du Paradis où ils demeureront éternellement, en récompense de ce qu'ils faisaient"
    },
    {
      "key": 4525,
      "id": "46-15",
      "translation": "Et Nous avons enjoint à l'homme de la bonté envers ses père et mère: sa mère l'a péniblement porté et en a péniblement accouché; et sa gestation et sevrage durent trente mois; puis quand il atteint ses pleines forces et atteint quarante ans, il dit: «O Seigneur! Inspire-moi pour que je rende grâce au bienfait dont Tu m'as comblé ainsi qu'à mes père et mère, et pour que je fasse une bonne œuvre que Tu agrées. Et fais que ma postérité soit de moralité saine. Je me repens à Toi et je suis du nombre des Soumis»"
    },
    {
      "key": 4526,
      "id": "46-16",
      "translation": "Ce sont ceux-là dont Nous acceptons le meilleur de ce qu'ils œuvrent et passons sur leurs méfaits, (ils seront) parmi les gens du Paradis, selon la promesse véridique qui leur était faite"
    },
    {
      "key": 4527,
      "id": "46-17",
      "translation": "Quant à celui qui dit à ses père et mère: «Fi de vous deux! Me promettez-vous qu'on me fera sortir de terre alors que des générations avant moi ont passé?» Et les deux, implorant le secours d'Allah, [lui dirent]: «Malheur à toi! Crois. Car la promesse d'Allah est véridique». Mais il (répond): «Ce ne sont que des contes d'Anciens»"
    },
    {
      "key": 4528,
      "id": "46-18",
      "translation": "Ce sont ceux-là qui ont mérité la sentence [prescrite] en même temps que des communautés déjà passées avant eux parmi les djinns et les hommes. Ils étaient réellement perdants"
    },
    {
      "key": 4529,
      "id": "46-19",
      "translation": "Et il y a des rangs [de mérite] pour chacun, selon ce qu'ils ont fait afin qu'Allah leur attribue la pleine récompense de leurs œuvres; et ils ne seront point lésés"
    },
    {
      "key": 4530,
      "id": "46-20",
      "translation": "Et le jour où ceux qui ont mécru seront présentés au Feu (il leur sera dit): «Vous avez dissipé vos [biens] excellents et vous en avez joui pleinement durant votre vie sur terre: on vous rétribue donc aujourd'hui du châtiment avilissant, pour l'orgueil dont vous vous enfliez injustement sur terre, et pour votre perversité"
    },
    {
      "key": 4531,
      "id": "46-21",
      "translation": "Et rappelle-toi le frère des 'Aad (Hûd) quand il avertit son peuple à Al-Ahqâf - alors qu'avant et après lui, des avertisseurs sont passés - [en disant]: «N'adorez qu'Allah. Je crains pour vous le châtiment d'un jour terrible»"
    },
    {
      "key": 4532,
      "id": "46-22",
      "translation": "Ils dirent: «Es-tu venu à nous pour nous détourner de nos divinités? Eh bien, apporte-nous ce que tu nous promets si tu es du nombre des véridiques»"
    },
    {
      "key": 4533,
      "id": "46-23",
      "translation": "Il dit: «La science n'est qu'auprès d'Allah. Je vous transmets cependant le message avec lequel j'ai été envoyé. Mais je vois que vous êtes des gens ignorants»"
    },
    {
      "key": 4534,
      "id": "46-24",
      "translation": "Puis, voyant un nuage se dirigeant vers leurs vallées ils dirent; «Voici un nuage qui nous apporte de la pluie». Au contraire! c'est cela même que vous cherchiez à hâter: C'est un vent qui contient un châtiment douloureux"
    },
    {
      "key": 4535,
      "id": "46-25",
      "translation": "détruisant tout, par le commandement de son Seigneur. Puis le lendemain on ne voyait plus que leurs demeures. Ainsi rétribuons-Nous les gens criminels"
    },
    {
      "key": 4536,
      "id": "46-26",
      "translation": "En effet, Nous les avions consolidés dans des positions que Nous ne vous avons pas données. Et Nous leur avions assigné une ouïe, des yeux et des cœurs, mais ni leur ouïe, ni leurs yeux, ni leurs cœurs ne leur ont profité en quoi que ce soit, parce qu'ils niaient les signes d'Allah. Et ce dont ils se moquaient les cerna"
    },
    {
      "key": 4537,
      "id": "46-27",
      "translation": "Nous avons assurément fait périr les cités autour de vous; et Nous avons diversifié les signes afin qu'ils reviennent (de leur mécréance)"
    },
    {
      "key": 4538,
      "id": "46-28",
      "translation": "Pourquoi donc ne les secourent pas, ceux qu'ils avaient pris, en dehors d'Allah, comme divinités pour [soi-disant] les rapprocher de Lui? Ceux-ci, au contraire, les abandonnèrent; telle est leur imposture et voilà ce qu'ils inventaient comme mensonges"
    },
    {
      "key": 4539,
      "id": "46-29",
      "translation": "(Rappelle-toi) lorsque Nous dirigeâmes vers toi une troupe de djinns pour qu'ils écoutent le Coran. Quand ils assistèrent [à sa lecture] ils dirent: «Ecoutez attentivement»... Puis, quand ce fut terminé, ils retournèrent à leur peuple en avertisseurs"
    },
    {
      "key": 4540,
      "id": "46-30",
      "translation": "Ils dirent: «O notre peuple! Nous venons d'entendre un Livre qui a été descendu après Moïse, confirmant ce qui l'a précédé. Il guide vers la vérité et vers un chemin droit"
    },
    {
      "key": 4541,
      "id": "46-31",
      "translation": "O notre peuple! Répondez au prédicateur d'Allah et croyez en lui. Il [Allah] vous pardonnera une partie de vos péchés et vous protègera contre un châtiment douloureux"
    },
    {
      "key": 4542,
      "id": "46-32",
      "translation": "Et quiconque ne répond pas au prédicateur d'Allah ne saura échapper au pouvoir [d'Allah] sur terre. Et il n'aura pas de protecteurs en dehors de Lui. Ceux-là sont dans un égarement évident"
    },
    {
      "key": 4543,
      "id": "46-33",
      "translation": "Ne voient-ils pas qu'Allah qui a créé les cieux et la terre, et qui n'a pas été fatigué par leur création, est capable en vérité de redonner la vie aux morts? Mais si. Il est certes Omnipotent"
    },
    {
      "key": 4544,
      "id": "46-34",
      "translation": "Et le jour où seront présentés au Feu ceux qui ont mécru (on leur dira): «Ceci n'est-il pas la vérité?» Ils diront: «Mais si, par notre Seigneur». Il dira: «Eh bien, goûtez le châtiment pour votre mécréance»"
    },
    {
      "key": 4545,
      "id": "46-35",
      "translation": "Endure  donc, comme ont enduré les messagers doués de fermeté; et ne te montre pas trop pressé de les voir subir [leur châtiment]. Le jour où ils verront ce qui leur est promis, il leur semblera qu'ils n'étaient restés [sur terre] qu'une heure d'un jour. Voilà une communication. Qui sera donc anéanti sinon les gens pervers"
    },
    {
      "key": 4546,
      "id": "47-1",
      "translation": "Ceux qui ont mécru et obstrué le chemin d'Allah, Il a rendu leurs œuvres vaines"
    },
    {
      "key": 4547,
      "id": "47-2",
      "translation": "Et ceux qui ont cru et accompli de bonnes œuvres et ont cru en ce qui a été descendu sur Muhammad - et c'est la vérité venant de leur Seigneur - Il leur efface leurs méfaits et améliore leur condition"
    },
    {
      "key": 4548,
      "id": "47-3",
      "translation": "Il en est ainsi parce que ceux qui ont mécru ont suivi le Faux et que ceux qui ont cru ont suivi la Vérité émanant de leur Seigneur. C'est ainsi qu'Allah propose leurs exemples aux gens"
    },
    {
      "key": 4549,
      "id": "47-4",
      "translation": "Lorsque vous rencontrez (au combat) ceux qui ont mécru frappez-en les cous. Puis, quand vous les avez dominés, enchaînez-les solidement. Ensuite, c'est soit la libération gratuite, soit la rançon, jusqu'à ce que la guerre dépose ses fardeaux. Il en est ainsi, car si Allah voulait, Il se vengerait Lui-même contre eux, mais c'est pour vous éprouver les uns par les autres. Et ceux qui seront tués dans le chemin d'Allah, Il ne rendra jamais vaines leurs actions"
    },
    {
      "key": 4550,
      "id": "47-5",
      "translation": "Il les guidera et améliorera leur condition"
    },
    {
      "key": 4551,
      "id": "47-6",
      "translation": "et les fera entrer au Paradis qu'Il leur aura fait connaître"
    },
    {
      "key": 4552,
      "id": "47-7",
      "translation": "O vous qui croyez! si vous faites triompher (la cause d') Allah, Il vous fera triompher et raffermira vos pas"
    },
    {
      "key": 4553,
      "id": "47-8",
      "translation": "Et quant à ceux qui ont mécru, il y aura un malheur pour eux, et Il rendra leurs œuvres vaines"
    },
    {
      "key": 4554,
      "id": "47-9",
      "translation": "C'est parce qu'ils ont de la répulsion pour ce qu'Allah a fait descendre. Il a rendu donc vaines leurs œuvres"
    },
    {
      "key": 4555,
      "id": "47-10",
      "translation": "N'ont-il pas parcouru la terre pour voir ce qu'il est advenu de leurs prédécesseurs? Allah les a détruits. Pareilles fins sont réservées aux mécréants"
    },
    {
      "key": 4556,
      "id": "47-11",
      "translation": "C'est qu'Allah est vraiment le Protecteur de ceux qui ont cru; tandis que les mécréants n'ont pas de protecteur"
    },
    {
      "key": 4557,
      "id": "47-12",
      "translation": "Ceux qui croient et accomplissent de bonnes œuvres Allah les fera entrer dans des Jardins sous lesquels coulent les ruisseaux. Et ceux qui mécroient jouissent et mangent comme mangent les bestiaux; et le Feu sera leur lieu de séjour"
    },
    {
      "key": 4558,
      "id": "47-13",
      "translation": "Et que de cités, bien plus fortes que ta cité qui t'a expulsé, avons-Nous fait périr, et ils n'eurent point de secoureur"
    },
    {
      "key": 4559,
      "id": "47-14",
      "translation": "Est-ce que celui qui se base sur une preuve claire venant de son Seigneur est comparable à ceux dont on a embelli les mauvaises actions et qui ont suivi leurs propres passions"
    },
    {
      "key": 4560,
      "id": "47-15",
      "translation": "Voici la description du Paradis qui a été promis aux pieux: il y aura là des ruisseaux d'une eau jamais malodorante, et des ruisseaux d'un lait au goût inaltérable, et des ruisseaux d'un vin délicieux à boire, ainsi que des ruisseaux d'un miel purifié. Et il y a là, pour eux, des fruits de toutes sortes, ainsi qu'un pardon de la part de leur Seigneur. [Ceux-là] seront-ils pareils à ceux qui s'éternisent dans le Feu et qui sont abreuvés d'une eau bouillante qui leur déchire les entrailles"
    },
    {
      "key": 4561,
      "id": "47-16",
      "translation": "Et il en est parmi eux qui t'écoutent. Une fois sortis de chez toi ils disent à ceux qui ont reçu la science: «Qu'a-t-il dit tout à l'heure?» Ce sont ceux-là dont Allah a scellé les cœurs et qui suivent leurs propres passions"
    },
    {
      "key": 4562,
      "id": "47-17",
      "translation": "Quant à ceux qui se mirent sur la bonne voie, Il les guida encore plus et leur inspira leur piété"
    },
    {
      "key": 4563,
      "id": "47-18",
      "translation": "Qu'est-ce qu'ils attendent sinon que l'Heure leur vienne à l'improviste? Or ses signes avant-coureurs sont certes déjà venus. Et comment pourront-ils se rappeler quand elle leur viendra (à l'improviste)"
    },
    {
      "key": 4564,
      "id": "47-19",
      "translation": "Sache donc qu'en vérité il n'y a point de divinité à part Allah et implore le pardon pour ton péché, ainsi que pour les croyants et les croyantes. Allah connaît vos activités (sur terre) et votre lieu de repos (dans l'au-delà)"
    },
    {
      "key": 4565,
      "id": "47-20",
      "translation": "Ceux qui ont cru disent: «Ah! Si une Sourate descendait!» Puis, quand on fait descendre une Sourate explicite et qu'on y mentionne le combat, tu vois ceux qui ont une maladie au cœur te regarder du regard de celui qui s'évanouit devant la mort. Seraient bien préférables pour eux"
    },
    {
      "key": 4566,
      "id": "47-21",
      "translation": "une obéissance et une parole convenable. Puis, quand l'affaire est décidée, il serait mieux pour eux certes, de se montrer sincères vis-à-vis d'Allah"
    },
    {
      "key": 4567,
      "id": "47-22",
      "translation": "Si vous vous détournez, ne risquez-vous pas de semer la corruption sur terre et de rompre vos liens de parenté"
    },
    {
      "key": 4568,
      "id": "47-23",
      "translation": "Ce sont ceux-là qu'Allah a maudits, a rendus sourds et a rendu leurs yeux aveugles"
    },
    {
      "key": 4569,
      "id": "47-24",
      "translation": "Ne méditent-ils pas sur le Coran? Ou y a-t-il des cadenas sur leurs cœurs"
    },
    {
      "key": 4570,
      "id": "47-25",
      "translation": "Ceux qui sont revenus sur leurs pas après que le droit chemin leur a été clairement exposé, le Diable les a séduits et trompés"
    },
    {
      "key": 4571,
      "id": "47-26",
      "translation": "C'est parce qu'ils ont dit à ceux qui ont de la répulsion pour la révélation d'Allah: «Nous allons vous obéir dans certaines choses». Allah cependant connaît ce qu'ils cachent"
    },
    {
      "key": 4572,
      "id": "47-27",
      "translation": "Qu'adviendra-t-il d'eux quand les Anges les achèveront, frappant leurs faces et leurs dos"
    },
    {
      "key": 4573,
      "id": "47-28",
      "translation": "Cela parce qu'ils ont suivi ce qui courrouce Allah, et qu'ils ont de la répulsion pour [ce qui attire] Son agrément. Il a donc rendu vaines leurs œuvres"
    },
    {
      "key": 4574,
      "id": "47-29",
      "translation": "Ou bien est-ce que ceux qui ont une maladie au cœur escomptent qu'Allah ne saura jamais faire apparaître leur haine"
    },
    {
      "key": 4575,
      "id": "47-30",
      "translation": "Or, si Nous voulions Nous te les montrerions. Tu les reconnaîtrais certes à leurs traits; et tu les reconnaîtrais très certainement au ton de leur parler. Et Allah connaît bien vos actions"
    },
    {
      "key": 4576,
      "id": "47-31",
      "translation": "Nous vous éprouverons certes afin de distinguer ceux d'entre vous qui luttent [pour la cause d'Allah] et qui endurent, et afin d'éprouver [faire apparaître] vos nouvelles"
    },
    {
      "key": 4577,
      "id": "47-32",
      "translation": "Ceux qui ont mécru et obstrué le chemin d'Allah et se sont mis dans le clan opposé au Messager après que le droit chemin leur fut clairement exposé, ne sauront nuire à Allah en quoi que ce soit. Il rendra vaines leurs œuvres"
    },
    {
      "key": 4578,
      "id": "47-33",
      "translation": "O vous qui avez cru ! Obéissez à Allah, obéissez au Messager, et ne rendez pas vaines vos œuvres"
    },
    {
      "key": 4579,
      "id": "47-34",
      "translation": "Ceux qui ont mécru et obstrué le chemin d'Allah puis sont morts tout en étant mécréants, Allah ne leur pardonnera jamais"
    },
    {
      "key": 4580,
      "id": "47-35",
      "translation": "Ne faiblissez donc pas et n'appelez pas à la paix alors que vous êtes les plus hauts, qu'Allah est avec vous, et qu'Il ne vous frustrera jamais [du mérite] de vos œuvres"
    },
    {
      "key": 4581,
      "id": "47-36",
      "translation": "La vie présente n'est que jeu et amusement; alors que si vous croyez et craignez, [Allah], Il vous accordera vos récompenses et ne vous demandera pas vos biens"
    },
    {
      "key": 4582,
      "id": "47-37",
      "translation": "S'Il vous les demandait importunément, vous deviendriez avares et Il ferait apparaître vos haines"
    },
    {
      "key": 4583,
      "id": "47-38",
      "translation": "Vous voilà appelés à faire des dépenses dans le chemin d'Allah. Certains parmi vous se montrent avares. Quiconque cependant est avare, l'est à son détriment. Allah est le Suffisant à Soi-même alors que vous êtes les besogneux. Et si vous vous détournez, Il vous remplacera par un peuple autre que vous, et ils ne seront pas comme vous"
    },
    {
      "key": 4584,
      "id": "48-1",
      "translation": "En vérité Nous t'avons accordé une victoire éclatante"
    },
    {
      "key": 4585,
      "id": "48-2",
      "translation": "afin qu'Allah te pardonne tes péchés, passés et futurs, qu'Il parachève sur toi Son bienfait et te guide sur une voie droite"
    },
    {
      "key": 4586,
      "id": "48-3",
      "translation": "et qu'Allah te donne un puissant secours"
    },
    {
      "key": 4587,
      "id": "48-4",
      "translation": "C'est Lui qui a fait descendre la quiétude dans les cœurs des croyants afin qu'ils ajoutent une foi à leur foi. A Allah appartiennent les armées des cieux et de la terre; et Allah est Omniscient et Sage"
    },
    {
      "key": 4588,
      "id": "48-5",
      "translation": "afin qu'Il fasse entrer les croyants et les croyantes dans des Jardins sous lesquels coulent les ruisseaux où ils demeureront éternellement et afin de leur effacer leurs méfaits. Cela est auprès d'Allah un énorme succès"
    },
    {
      "key": 4589,
      "id": "48-6",
      "translation": "Et afin qu'Il châtie les hypocrites, hommes et femmes, et les associateurs et les associatrices, qui pensent du mal d'Allah. Qu'un mauvais sort tombe sur eux. Allah est courroucé contre eux, les a maudits, et leur a préparé l'Enfer. Quelle mauvaise destination"
    },
    {
      "key": 4590,
      "id": "48-7",
      "translation": "A Allah appartiennent les armées des cieux et de la terre; et Allah est Puissant et Sage"
    },
    {
      "key": 4591,
      "id": "48-8",
      "translation": "Nous t'avons envoyé en tant que témoin, annonciateur de la bonne nouvelle et avertisseur"
    },
    {
      "key": 4592,
      "id": "48-9",
      "translation": "pour que vous croyiez en Allah et en Son messager, que vous l'honoriez, reconnaissiez Sa dignité, et Le glorifiez matin et soir"
    },
    {
      "key": 4593,
      "id": "48-10",
      "translation": "Ceux qui te prêtent serment d'allégeance ne font que prêter serment à Allah: la main d'Allah est au-dessus de leurs mains. Quiconque viole le serment ne le viole qu'à son propre détriment; et quiconque remplit son engagement envers Allah, Il lui apportera bientôt une énorme récompense"
    },
    {
      "key": 4594,
      "id": "48-11",
      "translation": "Ceux des Bédouins qui ont été laissés en arrière te diront: «Nos biens et nos familles nous ont retenus: implore donc pour nous le pardon». Ils disent avec leurs langues ce qui n'est pas dans leurs cœurs. Dis: «Qui donc peut quelque chose pour vous auprès d'Allah s'Il veut vous faire du mal ou s'Il veut vous faire du bien? Mais Allah est Parfaitement Connaisseur de ce que vous œuvrez"
    },
    {
      "key": 4595,
      "id": "48-12",
      "translation": "Vous pensiez plutôt que le Messager et les croyants ne retourneraient jamais plus à leur famille. Et cela vous a été embelli dans vos cœurs; et vous avez eu de mauvaises pensées. Et vous fûtes des gens perdus»"
    },
    {
      "key": 4596,
      "id": "48-13",
      "translation": "Et quiconque ne croit pas en Allah et en Son messager... alors, pour les mécréants, Nous avons préparé une fournaise ardente"
    },
    {
      "key": 4597,
      "id": "48-14",
      "translation": "A Allah appartient la souveraineté des cieux et de la terre. Il pardonne à qui Il veut et châtie qui Il veut. Allah demeure cependant, Pardonneur et Miséricordieux"
    },
    {
      "key": 4598,
      "id": "48-15",
      "translation": "Ceux qui restèrent en arrière diront, quand vous vous dirigez vers le butin pour vous en emparer; «Laissez-nous vous suivre». Ils voudraient changer la parole d'Allah. Dis: «Jamais vous ne nous suivrez: ainsi Allah a déjà annoncé». Mais ils diront: «Vous êtes plutôt envieux à notre égard». Mais ils ne comprenaient en réalité que peu"
    },
    {
      "key": 4599,
      "id": "48-16",
      "translation": "Dis à ceux des Bédouins qui restèrent en arrière: «vous serez bientôt appelés contre des gens d'une force redoutable. Vous les combattrez à moins qu'ils n'embrassent l'Islam, si vous obéissez, Allah vous donnera une belle récompense, et si vous vous détournez comme vous vous êtes détournés auparavant, Il vous châtiera d'un châtiment douloureux»"
    },
    {
      "key": 4600,
      "id": "48-17",
      "translation": "Nul grief n'est à faire à l'aveugle, ni au boiteux ni au malade. Et quiconque obéit à Allah et à Son messager, Il le fera entrer dans des Jardins sous lesquels coulent les ruisseaux. Quiconque cependant se détourne, Il le châtiera d'un douloureux châtiment"
    },
    {
      "key": 4601,
      "id": "48-18",
      "translation": "Allah a très certainement agréé les croyants quand ils t'ont prêté le serment d'allégeance sous l'arbre. Il a su ce qu'il y avait dans leurs cœurs, et a fait descendre sur eux la quiétude, et Il les a récompensés par une victoire proche"
    },
    {
      "key": 4602,
      "id": "48-19",
      "translation": "ainsi qu'un abondant butin qu'ils ramasseront. Allah est Puissant et Sage"
    },
    {
      "key": 4603,
      "id": "48-20",
      "translation": "Allah vous a promis un abondant butin que vous prendrez et Il a hâté pour vous Celle-ci et repoussé de vous les mains des gens, afin que tout cela soit un signe pour les croyants et qu'Il vous guide dans un droit chemin"
    },
    {
      "key": 4604,
      "id": "48-21",
      "translation": "Il vous promet un autre butin que vous ne seriez jamais capables de remporter et qu'Allah a embrassé en Sa puissance, car Allah est Omnipotent"
    },
    {
      "key": 4605,
      "id": "48-22",
      "translation": "Et si ceux qui ont mécru vous combattent, ils se détourneront, certes; puis ils ne trouveront ni allié ni secoureur"
    },
    {
      "key": 4606,
      "id": "48-23",
      "translation": "Telle est la règle d'Allah appliquée aux générations passées. Et tu ne trouveras jamais de changement à la règle d'Allah"
    },
    {
      "key": 4607,
      "id": "48-24",
      "translation": "C'est Lui qui, dans la vallée de la Mecque, a écarté leurs mains de vous, de même qu'Il a écarté vos mains d'eux, après vous avoir fait triompher sur eux. Et Allah voit parfaitement ce que vous œuvrez"
    },
    {
      "key": 4608,
      "id": "48-25",
      "translation": "Ce sont eux qui ont mécru et qui vous ont obstrué le chemin de la Mosquée Sacrée [et ont empêché] que les offrandes entravées parvinssent à leur lieu d'immolation. S'il n'y avait pas eu des hommes croyants et des femmes croyantes (parmi les Mecquois) que vous ne connaissiez pas et que vous auriez pu piétiner sans le savoir, vous rendant ainsi coupables d'une action répréhensible... [Tout cela s'est fait] pour qu'Allah fasse entrer qui Il veut dans Sa miséricorde. Et s'ils [les croyants] s'étaient signalés, Nous aurions certes châtié d'un châtiment douloureux ceux qui avaient mécru parmi [les Mecquois]"
    },
    {
      "key": 4609,
      "id": "48-26",
      "translation": "Quand ceux qui ont mécru eurent mis dans leurs cœurs la fureur, [la] fureur de l'ignorance... Puis Allah fit descendre Sa quiétude sur Son Messager ainsi que sur les croyants, et les obligea à une parole de piété, dont ils étaient les plus dignes et les plus proches. Allah est Omniscient"
    },
    {
      "key": 4610,
      "id": "48-27",
      "translation": "Allah a été véridique en la vision par laquelle Il annonça à Son messager en toute vérité: vous entrerez dans la Mosquée Sacrée si Allah veut, en toute sécurité, ayant rasé vos têtes ou coupé vos cheveux, sans aucune crainte. Il savait donc ce que vous ne saviez pas. Il a placé en deçà de cela (la trêve de Houdaybiya) une victoire proche"
    },
    {
      "key": 4611,
      "id": "48-28",
      "translation": "C'est Lui qui a envoyé Son messager avec la guidée et la religion de vérité [l'Islam] pour la faire triompher sur toute autre religion. Allah suffit comme témoin"
    },
    {
      "key": 4612,
      "id": "48-29",
      "translation": "Muhammad est le Messager d'Allah. Et ceux qui sont avec lui sont durs envers les mécréants, miséricordieux entre eux. Tu les vois inclinés, prosternés, recherchant d'Allah grâce et agrément. Leurs visages sont marqués par la trace laissée par la prosternation. Telle est leur image dans la Thora. Et l'image que l'on donne d'eux dans l'Evangile est celle d'une semence qui sort sa pousse, puis se raffermit, s'épaissit, et ensuite se dresse sur sa tige, à l'émerveillement des semeurs. [Allah] par eux [les croyants] remplit de dépit les mécréants. Allah promet à ceux d'entre eux qui croient et font de bonnes œuvres, un pardon et une énorme récompense"
    },
    {
      "key": 4613,
      "id": "49-1",
      "translation": "O vous qui avez cru! Ne devancez pas Allah et Son messager. Et craignez Allah. Allah est Audient et Omniscient"
    },
    {
      "key": 4614,
      "id": "49-2",
      "translation": "O vous qui avez cru! N'élevez pas vos voix au-dessus de la voix du Prophète, et ne haussez pas le ton en lui parlant, comme vous le haussez les uns avec les autres, sinon vos œuvres deviendraient vaines sans que vous vous en rendiez compte"
    },
    {
      "key": 4615,
      "id": "49-3",
      "translation": "Ceux qui auprès du Messager d'Allah baissent leurs voix sont ceux dont Allah a éprouvé les cœurs pour la piété. Ils auront un pardon et une énorme récompense"
    },
    {
      "key": 4616,
      "id": "49-4",
      "translation": "Ceux qui t'appellent à haute voix de derrière les appartements, la plupart d'entre eux ne raisonnent pas"
    },
    {
      "key": 4617,
      "id": "49-5",
      "translation": "Et s'ils patientaient jusqu'à ce que tu sortes à eux ce serait certes mieux pour eux. Allah cependant, est Pardonneur et Miséricordieux"
    },
    {
      "key": 4618,
      "id": "49-6",
      "translation": "O vous qui avez cru! Si un pervers vous apporte une nouvelle, voyez bien clair [de crainte] que par inadvertance vous ne portiez atteinte à des gens et que vous ne regrettiez par la suite ce que vous avez fait"
    },
    {
      "key": 4619,
      "id": "49-7",
      "translation": "Et sachez que le Messager d'Allah est parmi vous. S'il vous obéissait dans maintes affaires, vous seriez en difficultés. Mais Allah vous a fait aimer la foi et l'a embellie dans vos cœurs et vous a fait détester la mécréance, la perversité et la désobéissance. Ceux-là sont les bien dirigés"
    },
    {
      "key": 4620,
      "id": "49-8",
      "translation": "c'est là en effet une grâce d'Allah et un bienfait. Allah est Omniscient et Sage"
    },
    {
      "key": 4621,
      "id": "49-9",
      "translation": "Et si deux groupes de croyants se combattent, faites la conciliation entre eux. Si l'un d'eux se rebelle contre l'autre, combattez le groupe qui se rebelle, jusqu'à ce qu'il se conforme à l'ordre d'Allah. Puis, s'il s'y conforme, réconciliez-les avec justice et soyez équitables car Allah aime les équitables"
    },
    {
      "key": 4622,
      "id": "49-10",
      "translation": "Les croyants ne sont que des frères. Etablissez la concorde entre vos frères, et craignez Allah, afin qu'on vous fasse miséricorde"
    },
    {
      "key": 4623,
      "id": "49-11",
      "translation": "O vous qui avez cru! Qu'un groupe ne se raille pas d'un autre groupe: ceux-ci sont peut-être meilleurs qu'eux. Et que des femmes ne se raillent pas d'autres femmes: celles-ci sont peut-être meilleures qu'elles. Ne vous dénigrez pas et ne vous lancez pas mutuellement des sobriquets (injurieux). Quel vilain mot que «perversion» lorsqu'on a déjà la foi. Et quiconque ne se repent pas... Ceux-là sont les injustes"
    },
    {
      "key": 4624,
      "id": "49-12",
      "translation": "O vous qui avez cru! Evitez de trop conjecturer [sur autrui] car une partie des conjectures est péché. Et n'espionnez pas; et ne médisez pas les uns des autres. L'un de vous aimerait-il manger la chair de son frère mort? (Non!) vous en aurez horreur. Et craignez Allah. Car Allah est Grand Accueillant au repentir, Très Miséricordieux"
    },
    {
      "key": 4625,
      "id": "49-13",
      "translation": "O hommes! Nous vous avons créés d'un mâle et d'une femelle, et Nous avons fait de vous des nations et des tribus, pour que vous vous entre-connaissiez. Le plus noble d'entre vous, auprès d'Allah, est le plus pieux. Allah est certes Omniscient et Grand Connaisseur"
    },
    {
      "key": 4626,
      "id": "49-14",
      "translation": "Les Bédouins ont dit: «Nous avons la foi». Dis: «Vous n'avez pas encore la foi. Dites plutôt: Nous nous sommes simplement soumis, car la foi n'a pas encore pénétré dans vos cœurs. Et si vous obéissez à Allah et à Son messager, Il ne vous fera rien perdre de vos œuvres». Allah est Pardonneur et Miséricordieux"
    },
    {
      "key": 4627,
      "id": "49-15",
      "translation": "Les vrais croyants sont seulement ceux qui croient en Allah et en Son messager, qui par la suite ne doutent point et qui luttent avec leurs biens et leurs personnes dans le chemin d'Allah. Ceux-là sont les véridiques"
    },
    {
      "key": 4628,
      "id": "49-16",
      "translation": "Dis: «Est-ce vous qui apprendrez à Allah votre religion, alors qu'Allah sait tout ce qui est dans les cieux et sur la terre?» Et Allah est Omniscient"
    },
    {
      "key": 4629,
      "id": "49-17",
      "translation": "Ils te rappellent leur conversion à l'Islam comme si c'était une faveur de leur part. Dis: «Ne me rappelez pas votre conversion à l'Islam comme une faveur. C'est tout au contraire une faveur dont Allah vous a comblés en vous dirigeant vers la foi, si toutefois vous êtes véridiques»"
    },
    {
      "key": 4630,
      "id": "49-18",
      "translation": "Allah connaît l'Inconnaissable des cieux et de la terre et Allah est Clairvoyant sur ce que vous faites"
    },
    {
      "key": 4631,
      "id": "50-1",
      "translation": "Qâf. Par le Coran glorieux"
    },
    {
      "key": 4632,
      "id": "50-2",
      "translation": "Mais ils s'étonnent que l'un des leurs leur vint comme avertisseur; et les mécréants dirent: «Ceci est une chose étonnante"
    },
    {
      "key": 4633,
      "id": "50-3",
      "translation": "Quoi! Quand nous serons morts et réduits en poussière...? Ce serait revenir de loin!»"
    },
    {
      "key": 4634,
      "id": "50-4",
      "translation": "Certes, Nous savons ce que la terre rongera d'eux [de leurs corps]; et Nous avons un Livre où tout est conservé"
    },
    {
      "key": 4635,
      "id": "50-5",
      "translation": "Plutôt, ils traitent de mensonge la vérité qui leur est venue: les voilà donc dans une situation confuse"
    },
    {
      "key": 4636,
      "id": "50-6",
      "translation": "N'ont-ils donc pas observé le ciel au-dessus d'eux, comment Nous l'avons bâti et embelli; et comment il est sans fissures"
    },
    {
      "key": 4637,
      "id": "50-7",
      "translation": "Et la terre, Nous l'avons étendue et Nous y avons enfoncé fermement des montagnes et y avons fait pousser toutes sortes de magnifiques couples de [végétaux]"
    },
    {
      "key": 4638,
      "id": "50-8",
      "translation": "à titre d'appel à la clairvoyance et un rappel pour tout serviteur repentant"
    },
    {
      "key": 4639,
      "id": "50-9",
      "translation": "Et Nous avons fait descendre du ciel une eau bénie, avec laquelle Nous avons fait pousser des jardins et le grain qu'on moissonne"
    },
    {
      "key": 4640,
      "id": "50-10",
      "translation": "ainsi que les hauts palmiers aux régimes superposés"
    },
    {
      "key": 4641,
      "id": "50-11",
      "translation": "comme subsistance pour les serviteurs. Et par elle (l'eau) Nous avons redonné la vie à une contrée morte. Ainsi se fera la résurrection"
    },
    {
      "key": 4642,
      "id": "50-12",
      "translation": "Avant eux, le peuple de Noé, les gens d'Ar-Rass et les Thamûd crièrent au mensonge"
    },
    {
      "key": 4643,
      "id": "50-13",
      "translation": "de même que les 'Aad et Pharaon et les frères de Lot"
    },
    {
      "key": 4644,
      "id": "50-14",
      "translation": "et les gens d'Al-Aykah et le peuple de Tubba'. Tous traitèrent les Messagers de menteurs. C'est ainsi que Ma menace se justifia"
    },
    {
      "key": 4645,
      "id": "50-15",
      "translation": "Quoi? Avons-Nous été fatigué par la première création? Mais ils sont dans la confusion [au sujet] d'une création nouvelle"
    },
    {
      "key": 4646,
      "id": "50-16",
      "translation": "Nous avons effectivement créé l'homme et Nous savons ce que son âme lui suggère et Nous sommes plus près de lui que sa veine jugulaire"
    },
    {
      "key": 4647,
      "id": "50-17",
      "translation": "quand les deux recueillants, assis à droite et à gauche, recueillent"
    },
    {
      "key": 4648,
      "id": "50-18",
      "translation": "Il ne prononce pas une parole sans avoir auprès de lui un observateur prêt à l'inscrire"
    },
    {
      "key": 4649,
      "id": "50-19",
      "translation": "L'agonie de la mort fait apparaître la vérité: «Voilà ce dont tu t'écartais»"
    },
    {
      "key": 4650,
      "id": "50-20",
      "translation": "Et l'on soufflera dans la Trompe: Voilà le jour de la Menace"
    },
    {
      "key": 4651,
      "id": "50-21",
      "translation": "Alors chaque âme viendra accompagnée d'un conducteur et d'un témoin"
    },
    {
      "key": 4652,
      "id": "50-22",
      "translation": "«Tu restais indifférent à cela. Et bien, Nous ôtons ton voile; ta vue est perçante aujourd'hui"
    },
    {
      "key": 4653,
      "id": "50-23",
      "translation": "Et son compagnon dira: «Voilà ce qui est avec moi, tout prêt»"
    },
    {
      "key": 4654,
      "id": "50-24",
      "translation": "«Vous deux, jetez dans l'Enfer tout mécréant endurci et rebelle"
    },
    {
      "key": 4655,
      "id": "50-25",
      "translation": "acharné à empêcher le bien, transgresseur, douteur"
    },
    {
      "key": 4656,
      "id": "50-26",
      "translation": "celui qui plaçait à côté d'Allah une autre divinité. Jetez-le donc dans le dur châtiment»"
    },
    {
      "key": 4657,
      "id": "50-27",
      "translation": "Son camarade (le Diable) dira: «Seigneur, ce n'est pas moi qui l'ai fait transgresser; mais il était déjà dans un profond égarement»"
    },
    {
      "key": 4658,
      "id": "50-28",
      "translation": "Alors [Allah] dira:«Ne vous disputez pas devant moi! Alors que Je vous ai déjà fait part de la menace"
    },
    {
      "key": 4659,
      "id": "50-29",
      "translation": "Chez Moi, la parole ne change pas; et Je n'opprime nullement les serviteurs»"
    },
    {
      "key": 4660,
      "id": "50-30",
      "translation": "Le jour où Nous dirons à l'Enfer; «Es-tu rempli?» Il dira: «Y en a-t-il encore?»"
    },
    {
      "key": 4661,
      "id": "50-31",
      "translation": "Le Paradis sera rapproché à proximité des pieux"
    },
    {
      "key": 4662,
      "id": "50-32",
      "translation": "«Voilà ce qui vous a été promis, [ainsi qu'] à tout homme plein de repentir et respectueux [des prescriptions divines]"
    },
    {
      "key": 4663,
      "id": "50-33",
      "translation": "qui redoute le Tout Miséricordieux bien qu'il ne Le voit pas, et qui vient [vers Lui] avec un cœur porté à l'obéissance"
    },
    {
      "key": 4664,
      "id": "50-34",
      "translation": "Entrez-y en toute sécurité,». Voilà le jour de l'éternité"
    },
    {
      "key": 4665,
      "id": "50-35",
      "translation": "Il y aura là pour eux tout ce qu'ils voudront. Et auprès de Nous il y a davantage encore"
    },
    {
      "key": 4666,
      "id": "50-36",
      "translation": "combien avons-Nous fait périr, avant eux, de générations bien plus fortes qu'eux. Ils avaient parcouru les contrées, cherchant [vainement] où fuir"
    },
    {
      "key": 4667,
      "id": "50-37",
      "translation": "Il y a bien là un rappel pour quiconque a un cœur, prête l'oreille tout en étant témoin"
    },
    {
      "key": 4668,
      "id": "50-38",
      "translation": "En effet Nous avons créé les cieux et la terre et ce qui existe entre eux en six jours, sans éprouver la moindre lassitude"
    },
    {
      "key": 4669,
      "id": "50-39",
      "translation": "Endure donc ce qu'ils disent; et célèbre la louange de ton Seigneur avant le lever du soleil et avant [son] coucher"
    },
    {
      "key": 4670,
      "id": "50-40",
      "translation": "et célèbre Sa gloire, une partie de la nuit et à la suite des prosternations [prières]"
    },
    {
      "key": 4671,
      "id": "50-41",
      "translation": "Et sois à l'écoute, le jour où le Crieur criera d'un endroit proche"
    },
    {
      "key": 4672,
      "id": "50-42",
      "translation": "le jour où ils entendront en toute vérité le Cri. Voilà le Jour de la Résurrection"
    },
    {
      "key": 4673,
      "id": "50-43",
      "translation": "C'est Nous qui donnons la vie et donnons la mort, et vers Nous sera la destination"
    },
    {
      "key": 4674,
      "id": "50-44",
      "translation": "le jour où la terre se fendra, les [rejetant] précipitamment. Ce sera un rassemblement facile pour Nous"
    },
    {
      "key": 4675,
      "id": "50-45",
      "translation": "Nous savons mieux ce qu'ils disent. Tu n'as pas pour mission d'exercer sur eux une contrainte. Rappelle donc, par le Coran celui qui craint Ma menace"
    },
    {
      "key": 4676,
      "id": "51-1",
      "translation": "Par les vents qui éparpillent"
    },
    {
      "key": 4677,
      "id": "51-2",
      "translation": "Par les porteurs de fardeaux"
    },
    {
      "key": 4678,
      "id": "51-3",
      "translation": "Par les glisseurs agiles"
    },
    {
      "key": 4679,
      "id": "51-4",
      "translation": "Par les distributeurs selon un commandement"
    },
    {
      "key": 4680,
      "id": "51-5",
      "translation": "Ce qui vous est promis est certainement vrai"
    },
    {
      "key": 4681,
      "id": "51-6",
      "translation": "Et la Rétribution arrivera inévitablement"
    },
    {
      "key": 4682,
      "id": "51-7",
      "translation": "Par le ciel aux voies parfaitement tracées"
    },
    {
      "key": 4683,
      "id": "51-8",
      "translation": "vous divergez sur ce que vous dites"
    },
    {
      "key": 4684,
      "id": "51-9",
      "translation": "Est détourné de lui quiconque a été détourné de la foi"
    },
    {
      "key": 4685,
      "id": "51-10",
      "translation": "Maudits soient les menteurs"
    },
    {
      "key": 4686,
      "id": "51-11",
      "translation": "qui sont plongés dans l'insouciance"
    },
    {
      "key": 4687,
      "id": "51-12",
      "translation": "Ils demandent: «A quand le jour de la Rétribution?»"
    },
    {
      "key": 4688,
      "id": "51-13",
      "translation": "Le jour où ils seront éprouvés au Feu"
    },
    {
      "key": 4689,
      "id": "51-14",
      "translation": "«Goûtez à votre épreuve [punition]; voici ce que vous cherchiez à hâter»"
    },
    {
      "key": 4690,
      "id": "51-15",
      "translation": "Les pieux seront dans des Jardins et [parmi] des sources"
    },
    {
      "key": 4691,
      "id": "51-16",
      "translation": "recevant ce que leur Seigneur leur aura donné. Car ils ont été auparavant des bienfaisants"
    },
    {
      "key": 4692,
      "id": "51-17",
      "translation": "ils dormaient peu, la nuit"
    },
    {
      "key": 4693,
      "id": "51-18",
      "translation": "et aux dernières heures de la nuit ils imploraient le pardon [d'Allah]"
    },
    {
      "key": 4694,
      "id": "51-19",
      "translation": "et dans leurs biens, il y avait un droit au mendiant et au déshérité"
    },
    {
      "key": 4695,
      "id": "51-20",
      "translation": "Il y a sur terre des preuves pour ceux qui croient avec certitude"
    },
    {
      "key": 4696,
      "id": "51-21",
      "translation": "ainsi qu'en vous-mêmes. N'observez-vous donc pas"
    },
    {
      "key": 4697,
      "id": "51-22",
      "translation": "Et il y a dans le ciel votre subsistance et ce qui vous a été promis"
    },
    {
      "key": 4698,
      "id": "51-23",
      "translation": "Par le Seigneur du ciel et de la terre! Ceci est tout aussi vrai que le fait que vous parliez"
    },
    {
      "key": 4699,
      "id": "51-24",
      "translation": "T'est-il parvenu le récit des visiteurs honorables d'Abraham"
    },
    {
      "key": 4700,
      "id": "51-25",
      "translation": "Quand ils entrèrent chez lui et dirent: «Paix!», il [leur] dit: «Paix, visiteurs inconnus»"
    },
    {
      "key": 4701,
      "id": "51-26",
      "translation": "Puis il alla discrètement à sa famille et apporta un veau gras"
    },
    {
      "key": 4702,
      "id": "51-27",
      "translation": "Ensuite il l'approcha d'eux... «Ne mangez-vous pas?» dit-il"
    },
    {
      "key": 4703,
      "id": "51-28",
      "translation": "Il ressentit alors de la peur vis-à-vis d'eux. Ils dirent: «N'aie pas peur». Et ils lui annoncèrent [la naissance] d'un garçon plein de savoir"
    },
    {
      "key": 4704,
      "id": "51-29",
      "translation": "Alors sa femme s'avança en criant, se frappa le visage et dit: «Une vieille femme stérile...»"
    },
    {
      "key": 4705,
      "id": "51-30",
      "translation": "Ils dirent: «Ainsi a dit ton Seigneur. C'est Lui vraiment le Sage, l'Omniscient»"
    },
    {
      "key": 4706,
      "id": "51-31",
      "translation": "Alors [Abraham] dit: «Quelle est donc votre mission, ô envoyés?»"
    },
    {
      "key": 4707,
      "id": "51-32",
      "translation": "Ils dirent: «Nous avons été envoyés vers des gens criminels"
    },
    {
      "key": 4708,
      "id": "51-33",
      "translation": "pour lancer sur eux des pierres de glaise"
    },
    {
      "key": 4709,
      "id": "51-34",
      "translation": "marquées auprès de ton Seigneur à l'intention des outranciers»"
    },
    {
      "key": 4710,
      "id": "51-35",
      "translation": "Nous en fîmes sortir alors ce qu'il y avait comme croyants"
    },
    {
      "key": 4711,
      "id": "51-36",
      "translation": "mais Nous n'y trouvâmes qu'une seule maison de gens soumis"
    },
    {
      "key": 4712,
      "id": "51-37",
      "translation": "Et Nous y laissâmes un signe pour ceux qui redoutent le douloureux châtiment"
    },
    {
      "key": 4713,
      "id": "51-38",
      "translation": "[Il y a même un signe] en Moïse quand Nous l'envoyâmes, avec une preuve évidente, vers Pharaon"
    },
    {
      "key": 4714,
      "id": "51-39",
      "translation": "Mais [celui-ci] se détourna confiant en sa puissance, et dit: «C'est un magicien ou un possédé!»"
    },
    {
      "key": 4715,
      "id": "51-40",
      "translation": "Nous le saisîmes ainsi que ses troupes, puis les jetâmes dans les flots, pour son comportement blâmable"
    },
    {
      "key": 4716,
      "id": "51-41",
      "translation": "De même pour les 'Aad, quand Nous envoyâmes contre eux le vent dévastateur"
    },
    {
      "key": 4717,
      "id": "51-42",
      "translation": "n'épargnant rien sur son passage sans le réduire en poussière"
    },
    {
      "key": 4718,
      "id": "51-43",
      "translation": "De même pour les Thamûd, quand il leur fut dit: «Jouissez jusqu'à un certain temps!»"
    },
    {
      "key": 4719,
      "id": "51-44",
      "translation": "Ils défièrent le commandement de leur Seigneur. La foudre les saisit alors qu'ils regardaient"
    },
    {
      "key": 4720,
      "id": "51-45",
      "translation": "Ils ne purent ni se mettre debout ni être secourus"
    },
    {
      "key": 4721,
      "id": "51-46",
      "translation": "De même, pour le peuple de Noé auparavant. Ils étaient des gens pervers"
    },
    {
      "key": 4722,
      "id": "51-47",
      "translation": "Le ciel, Nous l'avons construit par Notre puissance: et Nous l'étendons [constamment] dans l'immensité"
    },
    {
      "key": 4723,
      "id": "51-48",
      "translation": "Et la terre, Nous l'avons étendue. Et de quelle excellente façon Nous l'avons nivelée"
    },
    {
      "key": 4724,
      "id": "51-49",
      "translation": "Et de toute chose Nous avons créé [deux éléments] de couple. Peut-être vous rappellerez-vous"
    },
    {
      "key": 4725,
      "id": "51-50",
      "translation": "«Fuyez donc vers Allah. Moi, je suis pour vous de Sa part, un avertisseur explicite"
    },
    {
      "key": 4726,
      "id": "51-51",
      "translation": "Ne placez pas avec Allah une autre divinité. Je suis pour vous de Sa part, un avertisseur explicite»"
    },
    {
      "key": 4727,
      "id": "51-52",
      "translation": "Ainsi, aucun Messager n'est venu à leurs prédécesseurs sans qu'ils n'aient dit: «C'est un magicien ou un possédé!»"
    },
    {
      "key": 4728,
      "id": "51-53",
      "translation": "Est-ce qu'ils se sont transmis cette injonction? Ils sont plutôt des gens transgresseurs"
    },
    {
      "key": 4729,
      "id": "51-54",
      "translation": "Détourne-toi d'eux, tu ne seras pas blâmé [à leur sujet]"
    },
    {
      "key": 4730,
      "id": "51-55",
      "translation": "Et rappelle; car le rappel profite aux croyants"
    },
    {
      "key": 4731,
      "id": "51-56",
      "translation": "Je n'ai créé les djinns et les hommes que pour qu'ils M'adorent"
    },
    {
      "key": 4732,
      "id": "51-57",
      "translation": "Je ne cherche pas d'eux une subsistance; et Je ne veux pas qu'ils me nourrissent"
    },
    {
      "key": 4733,
      "id": "51-58",
      "translation": "En vérité, c'est Allah qui est le Grand Pourvoyeur, Le Détenteur de la force, l'Inébranlable"
    },
    {
      "key": 4734,
      "id": "51-59",
      "translation": "Ceux qui ont été injustes auront une part [de tourments] pareille à celle de leurs compagnons. Qu'ils ne soient pas trop pressés"
    },
    {
      "key": 4735,
      "id": "51-60",
      "translation": "Malheur donc à ceux qui ont mécru à cause du jour dont ils sont menacés"
    },
    {
      "key": 4736,
      "id": "52-1",
      "translation": "Par At-Tûr"
    },
    {
      "key": 4737,
      "id": "52-2",
      "translation": "Et par un Livre écrit"
    },
    {
      "key": 4738,
      "id": "52-3",
      "translation": "sur un parchemin déployé"
    },
    {
      "key": 4739,
      "id": "52-4",
      "translation": "Et par la Maison peuplée"
    },
    {
      "key": 4740,
      "id": "52-5",
      "translation": "Et par la Voûte élevée"
    },
    {
      "key": 4741,
      "id": "52-6",
      "translation": "Et par la Mer portée à ébullition! (au Jour dernier)"
    },
    {
      "key": 4742,
      "id": "52-7",
      "translation": "Le châtiment de ton Seigneur aura lieu inévitablement"
    },
    {
      "key": 4743,
      "id": "52-8",
      "translation": "Nul ne pourra le repousser"
    },
    {
      "key": 4744,
      "id": "52-9",
      "translation": "Le jour où le ciel sera agité d'un tourbillonnement"
    },
    {
      "key": 4745,
      "id": "52-10",
      "translation": "et les montagnes se mettront en marche"
    },
    {
      "key": 4746,
      "id": "52-11",
      "translation": "Ce jour-là, malheur à ceux qui traitent (les signes d'Allah) de mensonges"
    },
    {
      "key": 4747,
      "id": "52-12",
      "translation": "ceux qui s'ébattent dans des discours frivoles"
    },
    {
      "key": 4748,
      "id": "52-13",
      "translation": "le jour où ils seront brutalement poussés au feu de l'Enfer"
    },
    {
      "key": 4749,
      "id": "52-14",
      "translation": "Voilà le feu que vous traitiez de mensonge"
    },
    {
      "key": 4750,
      "id": "52-15",
      "translation": "Est-ce que cela est de la magie? Ou bien ne voyez-vous pas clair"
    },
    {
      "key": 4751,
      "id": "52-16",
      "translation": "Brûlez dedans! Supportez ou ne supportez pas, ce sera égal pour vous: vous n'êtes rétribués que selon ce que vous faisiez"
    },
    {
      "key": 4752,
      "id": "52-17",
      "translation": "Les pieux seront dans des Jardins et dans des délices"
    },
    {
      "key": 4753,
      "id": "52-18",
      "translation": "se réjouissant de ce que leur Seigneur leur aura donné, et leur Seigneur les aura protégés du châtiment de la Fournaise"
    },
    {
      "key": 4754,
      "id": "52-19",
      "translation": "«En récompense de ce que vous faisiez, mangez et buvez en toute sérénité"
    },
    {
      "key": 4755,
      "id": "52-20",
      "translation": "accoudés sur des lits bien rangés», et Nous leur ferons épouser des houris aux grands yeux noirs"
    },
    {
      "key": 4756,
      "id": "52-21",
      "translation": "Ceux qui auront cru et que leurs descendants auront suivis dans la foi, Nous ferons que leurs descendants les rejoignent. Et Nous ne diminuerons en rien le mérite de leurs œuvres, chacun étant tenu responsable de ce qu'il aura acquis"
    },
    {
      "key": 4757,
      "id": "52-22",
      "translation": "Nous les pourvoirons abondamment des fruits et des viandes qu'ils désireront"
    },
    {
      "key": 4758,
      "id": "52-23",
      "translation": "Là, ils se passeront les uns les autres une coupe qui ne provoquera ni vanité ni incrimination"
    },
    {
      "key": 4759,
      "id": "52-24",
      "translation": "Et parmi eux circuleront des garçons à leur service, pareils à des perles bien conservées"
    },
    {
      "key": 4760,
      "id": "52-25",
      "translation": "Et ils se tourneront les uns vers les autres s'interrogeant"
    },
    {
      "key": 4761,
      "id": "52-26",
      "translation": "Ils diront: «Nous vivions au milieu des nôtres dans la crainte [d'Allah]"
    },
    {
      "key": 4762,
      "id": "52-27",
      "translation": "Puis Allah nous a favorisés et protégés du châtiment du Samûm"
    },
    {
      "key": 4763,
      "id": "52-28",
      "translation": "Antérieurement, nous L'invoquions. C'est Lui certes, le Charitable, le Très Miséricordieux»"
    },
    {
      "key": 4764,
      "id": "52-29",
      "translation": "Rappelle donc et par la grâce de ton Seigneur tu n'es ni un devin ni un possédé"
    },
    {
      "key": 4765,
      "id": "52-30",
      "translation": "Ou bien ils disent: «C'est un poète! Attendons pour lui le coup de la mort»"
    },
    {
      "key": 4766,
      "id": "52-31",
      "translation": "Dis: «Attendez! Je suis avec vous parmi ceux qui attendent»"
    },
    {
      "key": 4767,
      "id": "52-32",
      "translation": "Est-ce leur raison qui leur commande cela? Ou sont-ils des gens outranciers"
    },
    {
      "key": 4768,
      "id": "52-33",
      "translation": "Ou bien ils disent: «Il l'a inventé lui-même?» Non... mais ils ne croient pas"
    },
    {
      "key": 4769,
      "id": "52-34",
      "translation": "Eh bien, qu'ils produisent un récit pareil à lui (le Coran), s'ils sont véridiques"
    },
    {
      "key": 4770,
      "id": "52-35",
      "translation": "Ont-ils été créé à partir de rien ou sont-ils eux les créateurs"
    },
    {
      "key": 4771,
      "id": "52-36",
      "translation": "Ou ont-ils créé les cieux et la terre? Mais ils n'ont plutôt aucune conviction"
    },
    {
      "key": 4772,
      "id": "52-37",
      "translation": "Possèdent-ils les trésors de ton Seigneur? Ou sont-ils eux les maîtres souverains"
    },
    {
      "key": 4773,
      "id": "52-38",
      "translation": "Ont-ils une échelle d'où ils écoutent? Que celui des leurs qui reste à l'écoute apporte une preuve évidente"
    },
    {
      "key": 4774,
      "id": "52-39",
      "translation": "[Allah] aurait-Il les filles, tandis que vous, les fils"
    },
    {
      "key": 4775,
      "id": "52-40",
      "translation": "Ou leur demandes-tu un salaire, de sorte qu'ils soient grevés d'une lourde dette"
    },
    {
      "key": 4776,
      "id": "52-41",
      "translation": "Ou bien détiennent-ils l'Inconnaissable pour le mentionner par écrit"
    },
    {
      "key": 4777,
      "id": "52-42",
      "translation": "Ou cherchent-ils un stratagème? Mais ce sont ceux qui ont mécru qui sont victimes de leur propre stratagème"
    },
    {
      "key": 4778,
      "id": "52-43",
      "translation": "Ou ont-ils une autre divinité à part Allah? Qu'Allah soit glorifié et purifié de tout ce qu'ils associent"
    },
    {
      "key": 4779,
      "id": "52-44",
      "translation": "Et s'ils voient tomber des fragments du ciel, ils disent: «Ce sont des nuages superposés»"
    },
    {
      "key": 4780,
      "id": "52-45",
      "translation": "Laisse-les donc, jusqu'à ce qu'ils rencontrent leur jour où ils seront foudroyés"
    },
    {
      "key": 4781,
      "id": "52-46",
      "translation": "le jour où leur ruse ne leur servira à rien, où ils ne seront pas secourus"
    },
    {
      "key": 4782,
      "id": "52-47",
      "translation": "Les injustes auront un châtiment préalable. Mais la plupart d'entre eux ne savent pas"
    },
    {
      "key": 4783,
      "id": "52-48",
      "translation": "Et supporte patiemment la décision de ton Seigneur. Car en vérité, tu es sous Nos yeux. Et célèbre la gloire de ton Seigneur quand tu te lèves"
    },
    {
      "key": 4784,
      "id": "52-49",
      "translation": "Glorifie-Le une partie de la nuit et au déclin des étoiles"
    },
    {
      "key": 4785,
      "id": "53-1",
      "translation": "Par l'étoile à son déclin"
    },
    {
      "key": 4786,
      "id": "53-2",
      "translation": "Votre compagnon ne s'est pas égaré et n'a pas été induit en erreur"
    },
    {
      "key": 4787,
      "id": "53-3",
      "translation": "et il ne prononce rien sous l'effet de la passion"
    },
    {
      "key": 4788,
      "id": "53-4",
      "translation": "ce n'est rien d'autre qu'une révélation inspirée"
    },
    {
      "key": 4789,
      "id": "53-5",
      "translation": "Que lui a enseigné [l'Ange Gabriel] à la force prodigieuse"
    },
    {
      "key": 4790,
      "id": "53-6",
      "translation": "doué de sagacité; c'est alors qu'il se montra sous sa forme réelle [angélique]"
    },
    {
      "key": 4791,
      "id": "53-7",
      "translation": "alors qu'il se trouvait à l'horizon supérieur"
    },
    {
      "key": 4792,
      "id": "53-8",
      "translation": "Puis il se rapprocha et descendit encore plus bas"
    },
    {
      "key": 4793,
      "id": "53-9",
      "translation": "et fut à deux portées d'arc, ou plus près encore"
    },
    {
      "key": 4794,
      "id": "53-10",
      "translation": "Il révéla à Son serviteur ce qu'Il révéla"
    },
    {
      "key": 4795,
      "id": "53-11",
      "translation": "Le cœur n'a pas menti en ce qu'il a vu"
    },
    {
      "key": 4796,
      "id": "53-12",
      "translation": "Lui contestez-vous donc ce qu'il voit"
    },
    {
      "key": 4797,
      "id": "53-13",
      "translation": "Il l'a pourtant vu, lors d'une autre descente"
    },
    {
      "key": 4798,
      "id": "53-14",
      "translation": "près de la Sidrat-ul-Muntahâ"
    },
    {
      "key": 4799,
      "id": "53-15",
      "translation": "près d'elle se trouve le jardin de Ma'w"
    },
    {
      "key": 4800,
      "id": "53-16",
      "translation": "au moment où le lotus était couvert de ce qui le couvrait"
    },
    {
      "key": 4801,
      "id": "53-17",
      "translation": "la vue n'a nullement dévié ni outrepassé la mesure"
    },
    {
      "key": 4802,
      "id": "53-18",
      "translation": "Il a bien vu certaines des grandes merveilles de son Seigneur"
    },
    {
      "key": 4803,
      "id": "53-19",
      "translation": "Que vous en semble [des divinités], Lât et Uuzzâ"
    },
    {
      "key": 4804,
      "id": "53-20",
      "translation": "ainsi que Manât, cette troisième autre"
    },
    {
      "key": 4805,
      "id": "53-21",
      "translation": "Sera-ce à vous le garçon et à Lui la fille"
    },
    {
      "key": 4806,
      "id": "53-22",
      "translation": "Que voilà donc un partage injuste"
    },
    {
      "key": 4807,
      "id": "53-23",
      "translation": "Ce ne sont que des noms que vous avez inventés, vous et vos ancêtres. Allah n'a fait descendre aucune preuve à leur sujet. Ils ne suivent que la conjecture et les passions de [leurs] âmes, alors que la guidée leur est venue de leur Seigneur"
    },
    {
      "key": 4808,
      "id": "53-24",
      "translation": "Ou bien l'homme aura-t-il tout ce qu'il désire"
    },
    {
      "key": 4809,
      "id": "53-25",
      "translation": "A Allah appartiennent la vie future et la vie d'ici-bas"
    },
    {
      "key": 4810,
      "id": "53-26",
      "translation": "Et que d'Anges dans les cieux dont l'intercession ne sert à rien, sinon qu'après qu'Allah l'aura permis, en faveur de qui Il veut et qu'Il agrée"
    },
    {
      "key": 4811,
      "id": "53-27",
      "translation": "Ceux qui ne croient pas en l'au-delà donnent aux Anges des noms de femmes"
    },
    {
      "key": 4812,
      "id": "53-28",
      "translation": "alors qu'ils n'en ont aucune science: ils ne suivent que la conjecture, alors que la conjecture ne sert à rien contre la vérité"
    },
    {
      "key": 4813,
      "id": "53-29",
      "translation": "Ecarte-toi donc, de celui qui tourne le dos à Notre rappel et qui ne désire que la vie présente"
    },
    {
      "key": 4814,
      "id": "53-30",
      "translation": "Voilà toute la portée de leur savoir. Certes ton Seigneur connaît parfaitement celui qui s'égare de Son chemin et Il connaît parfaitement qui est bien guidé"
    },
    {
      "key": 4815,
      "id": "53-31",
      "translation": "A Allah appartient ce qui est dans les cieux et sur la terre afin qu'Il rétribue ceux qui font le mal selon ce qu'ils œuvrent, et récompense ceux qui font le bien par la meilleure [récompense]"
    },
    {
      "key": 4816,
      "id": "53-32",
      "translation": "ceux qui évitent les plus grands péchés ainsi que les turpitudes et [qui ne commettent] que des fautes légères. Certes, le pardon de Ton Seigneur est immense. C'est Lui qui vous connaît le mieux quand Il vous a produits de terre, et aussi quand vous étiez des embryons dans les ventres de vos mères. Ne vantez pas vous-mêmes votre pureté; c'est Lui qui connaît mieux ceux qui [Le] craignent"
    },
    {
      "key": 4817,
      "id": "53-33",
      "translation": "Vois-tu celui qui s'est détourné"
    },
    {
      "key": 4818,
      "id": "53-34",
      "translation": "donné peu et a [finalement] cessé de donner"
    },
    {
      "key": 4819,
      "id": "53-35",
      "translation": "Détient-il la science de l'Inconnaissable en sorte qu'il voit"
    },
    {
      "key": 4820,
      "id": "53-36",
      "translation": "Ne lui a-t-on pas annoncé ce qu'il y avait dans les feuilles de Moïse"
    },
    {
      "key": 4821,
      "id": "53-37",
      "translation": "et celles d'Abraham qui a tenu parfaitement [sa promesse de transmettre]"
    },
    {
      "key": 4822,
      "id": "53-38",
      "translation": "qu'aucune [âme] ne portera le fardeau (le péché) d'autrui"
    },
    {
      "key": 4823,
      "id": "53-39",
      "translation": "et qu'en vérité, l'homme n'obtient que [le fruit] de ses efforts"
    },
    {
      "key": 4824,
      "id": "53-40",
      "translation": "et que son effort, en vérité, lui sera présenté (le jour du Jugement)"
    },
    {
      "key": 4825,
      "id": "53-41",
      "translation": "Ensuite il en sera récompensé pleinement"
    },
    {
      "key": 4826,
      "id": "53-42",
      "translation": "et que tout aboutit, en vérité, vers ton Seigneur"
    },
    {
      "key": 4827,
      "id": "53-43",
      "translation": "et que c'est Lui qui a fait rire et qui a fait pleurer"
    },
    {
      "key": 4828,
      "id": "53-44",
      "translation": "et que c'est Lui qui a fait mourir et qui a ramené à la vie"
    },
    {
      "key": 4829,
      "id": "53-45",
      "translation": "et que c'est Lui qui a crée les deux éléments de couple, le mâle et la femelle"
    },
    {
      "key": 4830,
      "id": "53-46",
      "translation": "d'une goutte de sperme quand elle est éjaculée"
    },
    {
      "key": 4831,
      "id": "53-47",
      "translation": "et que la seconde création Lui incombe"
    },
    {
      "key": 4832,
      "id": "53-48",
      "translation": "et c'est Lui qui a enrichi et qui a fait acquérir"
    },
    {
      "key": 4833,
      "id": "53-49",
      "translation": "Et c'est Lui qui est le Seigneur de Sirius"
    },
    {
      "key": 4834,
      "id": "53-50",
      "translation": "et c'est Lui qui a fait périr les anciens 'Aad"
    },
    {
      "key": 4835,
      "id": "53-51",
      "translation": "ainsi que les Thamûd, et Il fit que rien n'en subsistât"
    },
    {
      "key": 4836,
      "id": "53-52",
      "translation": "ainsi que le peuple de Noé antérieurement, car ils étaient encore plus injustes et plus violents"
    },
    {
      "key": 4837,
      "id": "53-53",
      "translation": "de même qu'Il anéantit les villes renversées"
    },
    {
      "key": 4838,
      "id": "53-54",
      "translation": "Et les recouvrit de ce dont Il les recouvrit"
    },
    {
      "key": 4839,
      "id": "53-55",
      "translation": "Lequel donc des bienfaits de ton Seigneur mets-tu en doute"
    },
    {
      "key": 4840,
      "id": "53-56",
      "translation": "Voici un avertisseur analogue aux avertisseurs anciens"
    },
    {
      "key": 4841,
      "id": "53-57",
      "translation": "l'Imminente (L'heure du Jugement) s'approche"
    },
    {
      "key": 4842,
      "id": "53-58",
      "translation": "Rien d'autre en dehors d'Allah ne peut la dévoiler"
    },
    {
      "key": 4843,
      "id": "53-59",
      "translation": "Quoi! vous étonnez-vous de ce discours (le Coran)"
    },
    {
      "key": 4844,
      "id": "53-60",
      "translation": "Et vous [en] riez et n'[en] pleurez point"
    },
    {
      "key": 4845,
      "id": "53-61",
      "translation": "absorbés [que vous êtes] par votre distraction"
    },
    {
      "key": 4846,
      "id": "53-62",
      "translation": "Prosternez-vous donc à Allah et adorez-Le"
    },
    {
      "key": 4847,
      "id": "54-1",
      "translation": "L'Heure approche et la Lune s'est fendue"
    },
    {
      "key": 4848,
      "id": "54-2",
      "translation": "Et s'ils voient un prodige, ils s'en détournent et disent: «Une magie persistante»"
    },
    {
      "key": 4849,
      "id": "54-3",
      "translation": "Et ils [le] traitent de mensonge et suivent leurs propres impulsions, or chaque chose arrivera à son terme [et son but]"
    },
    {
      "key": 4850,
      "id": "54-4",
      "translation": "Ils ont pourtant reçu comme nouvelles de quoi les empêcher (du mal)"
    },
    {
      "key": 4851,
      "id": "54-5",
      "translation": "[Cela est] une sagesse parfaite. Mais les avertissements ne [leur] servent à rien"
    },
    {
      "key": 4852,
      "id": "54-6",
      "translation": "Détourne-toi d'eux. Le jour où l'appeleur appellera vers une chose affreuse"
    },
    {
      "key": 4853,
      "id": "54-7",
      "translation": "les regards baissés, ils sortiront des tombes comme des sauterelles éparpillées"
    },
    {
      "key": 4854,
      "id": "54-8",
      "translation": "courant, le cou tendu, vers l'appeleur. Les mécréants diront: «Voilà un jour difficile»"
    },
    {
      "key": 4855,
      "id": "54-9",
      "translation": "Avant eux, le peuple de Noé avait crié au mensonge. Ils traitèrent Notre serviteur de menteur et dirent: «C'est un possédé!» et il fut repoussé"
    },
    {
      "key": 4856,
      "id": "54-10",
      "translation": "Il invoqua donc son Seigneur: «Moi, je suis vaincu. Fais triompher (Ta cause)»"
    },
    {
      "key": 4857,
      "id": "54-11",
      "translation": "Nous ouvrîmes alors les portes du ciel à une eau torrentielle"
    },
    {
      "key": 4858,
      "id": "54-12",
      "translation": "et fîmes jaillir la terre en sources. Les eaux se rencontrèrent d'après un ordre qui était déjà décrété dans une chose [faite]"
    },
    {
      "key": 4859,
      "id": "54-13",
      "translation": "Et Nous le portâmes sur un objet [fait] de planches et de clous [l'arche]"
    },
    {
      "key": 4860,
      "id": "54-14",
      "translation": "voguant sous Nos yeux: récompense pour celui qu'on avait renié [Noé]"
    },
    {
      "key": 4861,
      "id": "54-15",
      "translation": "Et Nous la laissâmes, comme un signe [d'avertissement]. Y a-t-il quelqu'un pour réfléchir"
    },
    {
      "key": 4862,
      "id": "54-16",
      "translation": "Comment furent Mon châtiment et Mes avertissements"
    },
    {
      "key": 4863,
      "id": "54-17",
      "translation": "En effet, Nous avons rendu le Coran facile pour la méditation. Y a-t-il quelqu'un pour réfléchir"
    },
    {
      "key": 4864,
      "id": "54-18",
      "translation": "Les 'Aad ont traité de menteur (leur Messager). Comment furent Mon châtiment et Mes avertissements"
    },
    {
      "key": 4865,
      "id": "54-19",
      "translation": "Nous avons envoyé contre eux un vent violent et glacial, en un jour néfaste et interminable"
    },
    {
      "key": 4866,
      "id": "54-20",
      "translation": "il arrachait les gens comme des souches de palmiers déracinés"
    },
    {
      "key": 4867,
      "id": "54-21",
      "translation": "Comment furent Mon châtiment et Mes avertissements"
    },
    {
      "key": 4868,
      "id": "54-22",
      "translation": "En effet, Nous avons rendu le Coran facile pour la méditation. Y a-t-il quelqu'un pour réfléchir"
    },
    {
      "key": 4869,
      "id": "54-23",
      "translation": "Les Thamûd ont traité de mensonges les avertissements"
    },
    {
      "key": 4870,
      "id": "54-24",
      "translation": "Ils dirent: «Allons-nous suivre un seul homme (Sâlih) d'entre nous-mêmes? Nous serions alors dans l'égarement et la folie"
    },
    {
      "key": 4871,
      "id": "54-25",
      "translation": "Est-ce que le message a été envoyé à Lui à l'exception de nous tous? C'est plutôt un grand menteur, plein de prétention et d'orgueil»"
    },
    {
      "key": 4872,
      "id": "54-26",
      "translation": "Demain, ils sauront qui est le grand menteur plein de prétention et d'orgueil"
    },
    {
      "key": 4873,
      "id": "54-27",
      "translation": "Nous leur enverrons la chamelle, comme épreuve. Surveille-les donc et sois patient"
    },
    {
      "key": 4874,
      "id": "54-28",
      "translation": "Et informe-les que l'eau sera en partage entre eux [et la chamelle]; chacun boira à son tour"
    },
    {
      "key": 4875,
      "id": "54-29",
      "translation": "Puis ils appelèrent leur camarade qui prit [son épée] et [la] tua"
    },
    {
      "key": 4876,
      "id": "54-30",
      "translation": "Comment furent donc Mon châtiment et Mes avertissements"
    },
    {
      "key": 4877,
      "id": "54-31",
      "translation": "Nous lachâmes sur eux un seul Cri, et voilà qu'ils furent réduits à l'état de paille d'étable"
    },
    {
      "key": 4878,
      "id": "54-32",
      "translation": "Et vraiment, Nous avons rendu le Coran facile pour la méditation. Y a-t-il quelqu'un pour réfléchir"
    },
    {
      "key": 4879,
      "id": "54-33",
      "translation": "Le peuple de Lot traita de mensonges les avertissements"
    },
    {
      "key": 4880,
      "id": "54-34",
      "translation": "Nous lachâmes sur eux un ouragan, excepté la famille de Lot que Nous sauvâmes avant l'aube"
    },
    {
      "key": 4881,
      "id": "54-35",
      "translation": "à titre de bienfait de Notre part: ainsi récompensons-Nous celui qui est reconnaissant"
    },
    {
      "key": 4882,
      "id": "54-36",
      "translation": "Il les avait pourtant avertis de Nos représailles. Mais ils mirent les avertissements en doute"
    },
    {
      "key": 4883,
      "id": "54-37",
      "translation": "En effet, ils voulaient séduire ses hôtes. Nous aveuglâmes leurs yeux «Goûtez donc Mon châtiment et Mes avertissements»"
    },
    {
      "key": 4884,
      "id": "54-38",
      "translation": "En effet, au petit matin, un châtiment persistant les surprit"
    },
    {
      "key": 4885,
      "id": "54-39",
      "translation": "Goûtez donc Mon châtiment et Mes avertissements"
    },
    {
      "key": 4886,
      "id": "54-40",
      "translation": "Et vraiment, Nous avons rendu le Coran facile pour la méditation. Y a-t-il quelqu'un pour réfléchir"
    },
    {
      "key": 4887,
      "id": "54-41",
      "translation": "Les avertissements vinrent certes, aux gens de Pharaon"
    },
    {
      "key": 4888,
      "id": "54-42",
      "translation": "Ils traitèrent de mensonges tous Nos prodiges. Nous les saisîmes donc, de la saisie d'un Puissant Omnipotent"
    },
    {
      "key": 4889,
      "id": "54-43",
      "translation": "Vos mécréants sont-ils meilleurs que ceux-là? Ou bien y a-t-il dans les Ecritures une immunité pour vous"
    },
    {
      "key": 4890,
      "id": "54-44",
      "translation": "Ou bien ils disent: «Nous formons un groupe [fort] et nous vaincrons»"
    },
    {
      "key": 4891,
      "id": "54-45",
      "translation": "Leur rassemblement sera bientôt mis en déroute, et ils fuiront"
    },
    {
      "key": 4892,
      "id": "54-46",
      "translation": "L'Heure, plutôt, sera leur rendez-vous, et l'Heure sera plus terrible et plus amère"
    },
    {
      "key": 4893,
      "id": "54-47",
      "translation": "Les criminels sont certes, dans l'égarement et la folie"
    },
    {
      "key": 4894,
      "id": "54-48",
      "translation": "Le jour où on les traînera dans le Feu sur leurs visages, (on leur dira): «Goûtez au contact de Saqar [la chaleur brûlante de l'Enfer]»"
    },
    {
      "key": 4895,
      "id": "54-49",
      "translation": "Nous avons créé toute chose avec mesure"
    },
    {
      "key": 4896,
      "id": "54-50",
      "translation": "et Notre ordre est une seule [parole]; [il est prompt] comme un clin d'œil"
    },
    {
      "key": 4897,
      "id": "54-51",
      "translation": "En effet, nous avons fait périr des peuples semblables à vous. Y a-t-il quelqu'un pour s'en souvenir"
    },
    {
      "key": 4898,
      "id": "54-52",
      "translation": "Et tout ce qu'ils ont fait est mentionné dans les registres"
    },
    {
      "key": 4899,
      "id": "54-53",
      "translation": "et tout fait, petit et grand, est consigné"
    },
    {
      "key": 4900,
      "id": "54-54",
      "translation": "Les pieux seront dans des Jardins et parmi des ruisseaux"
    },
    {
      "key": 4901,
      "id": "54-55",
      "translation": "dans un séjour de vérité, auprès d'un Souverain Omnipotent"
    },
    {
      "key": 4902,
      "id": "55-1",
      "translation": "Le Tout Miséricordieux"
    },
    {
      "key": 4903,
      "id": "55-2",
      "translation": "Il a enseigné le Coran"
    },
    {
      "key": 4904,
      "id": "55-3",
      "translation": "Il a créé l'homme"
    },
    {
      "key": 4905,
      "id": "55-4",
      "translation": "Il lui a appris à s'exprimer clairement"
    },
    {
      "key": 4906,
      "id": "55-5",
      "translation": "Le soleil et la lune [évoluent] selon un calcul [minutieux]"
    },
    {
      "key": 4907,
      "id": "55-6",
      "translation": "Et l'herbe et les arbres se prosternent"
    },
    {
      "key": 4908,
      "id": "55-7",
      "translation": "Et quant au ciel, Il l'a élevé bien haut. Et Il a établi la balance"
    },
    {
      "key": 4909,
      "id": "55-8",
      "translation": "afin que vous ne transgressiez pas dans la pesée"
    },
    {
      "key": 4910,
      "id": "55-9",
      "translation": "Donnez [toujours] le poids exact et ne faussez pas la pesée"
    },
    {
      "key": 4911,
      "id": "55-10",
      "translation": "Quant à la terre, Il l'a étendue pour les êtres vivants"
    },
    {
      "key": 4912,
      "id": "55-11",
      "translation": "il s'y trouve des fruits, et aussi les palmiers aux fruits recouverts d'enveloppes"
    },
    {
      "key": 4913,
      "id": "55-12",
      "translation": "tout comme les grains dans leurs balles, et les plantes aromatiques"
    },
    {
      "key": 4914,
      "id": "55-13",
      "translation": "Lequel donc des bienfaits de votre Seigneur nierez-vous"
    },
    {
      "key": 4915,
      "id": "55-14",
      "translation": "Il a crée l'homme d'argile sonnante comme la poterie"
    },
    {
      "key": 4916,
      "id": "55-15",
      "translation": "et Il a créé les djinns de la flamme d'un feu sans fumée"
    },
    {
      "key": 4917,
      "id": "55-16",
      "translation": "Lequel donc des bienfaits de votre Seigneur nierez-vous"
    },
    {
      "key": 4918,
      "id": "55-17",
      "translation": "Seigneur des deux Levants et Seigneur des deux Couchants"
    },
    {
      "key": 4919,
      "id": "55-18",
      "translation": "Lequel donc des bienfaits de votre Seigneur nierez-vous"
    },
    {
      "key": 4920,
      "id": "55-19",
      "translation": "Il a donné libre cours aux deux mers pour se rencontrer"
    },
    {
      "key": 4921,
      "id": "55-20",
      "translation": "il y a entre elles une barrière qu'elles ne dépassent pas"
    },
    {
      "key": 4922,
      "id": "55-21",
      "translation": "Lequel donc des bienfaits de votre Seigneur nierez-vous"
    },
    {
      "key": 4923,
      "id": "55-22",
      "translation": "De ces deux [mers] sortent la perle et le corail"
    },
    {
      "key": 4924,
      "id": "55-23",
      "translation": "Lequel donc des bienfaits de votre Seigneur nierez-vous"
    },
    {
      "key": 4925,
      "id": "55-24",
      "translation": "A Lui appartiennent les vaisseaux élevés sur la mer comme des montagnes"
    },
    {
      "key": 4926,
      "id": "55-25",
      "translation": "Lequel donc des bienfaits de votre Seigneur nierez-vous"
    },
    {
      "key": 4927,
      "id": "55-26",
      "translation": "Tout ce qui est sur elle [la terre] doit disparaître"
    },
    {
      "key": 4928,
      "id": "55-27",
      "translation": "[Seule] subsistera La Face [Wajh] de ton Seigneur, plein de majesté et de noblesse"
    },
    {
      "key": 4929,
      "id": "55-28",
      "translation": "Lequel donc des bienfaits de votre Seigneur nierez-vous"
    },
    {
      "key": 4930,
      "id": "55-29",
      "translation": "Ceux qui sont dans les cieux et la terre L'implorent. Chaque jour, Il accomplit une œuvre nouvelle"
    },
    {
      "key": 4931,
      "id": "55-30",
      "translation": "Lequel donc des bienfaits de votre Seigneur nierez-vous"
    },
    {
      "key": 4932,
      "id": "55-31",
      "translation": "Nous allons bientôt entreprendre votre jugement, ô vous les deux charges [hommes et djinns]"
    },
    {
      "key": 4933,
      "id": "55-32",
      "translation": "Lequel donc des bienfaits de votre Seigneur nierez-vous"
    },
    {
      "key": 4934,
      "id": "55-33",
      "translation": "O peuple de djinns et d'hommes! Si vous pouvez sortir du domaine des cieux et de la terre, alors faites-le. Mais vous ne pourrez en sortir qu'à l'aide d'un pouvoir [illimité]"
    },
    {
      "key": 4935,
      "id": "55-34",
      "translation": "Lequel donc des bienfaits de votre Seigneur nierez-vous"
    },
    {
      "key": 4936,
      "id": "55-35",
      "translation": "Il sera lancé contre vous un jet de feu et de fumée [ou de cuivre fondu], et vous ne serez pas secourus"
    },
    {
      "key": 4937,
      "id": "55-36",
      "translation": "Lequel donc des bienfaits de votre Seigneur nierez-vous"
    },
    {
      "key": 4938,
      "id": "55-37",
      "translation": "Puis quand le ciel se fendra et deviendra alors écarlate comme le cuir rouge"
    },
    {
      "key": 4939,
      "id": "55-38",
      "translation": "Lequel des bienfaits de votre Seigneur nierez-vous"
    },
    {
      "key": 4940,
      "id": "55-39",
      "translation": "Alors, ni aux hommes ni aux djinns, on ne posera des questions à propos de leurs péchés"
    },
    {
      "key": 4941,
      "id": "55-40",
      "translation": "Lequel donc des bienfaits de votre Seigneur nierez-vous"
    },
    {
      "key": 4942,
      "id": "55-41",
      "translation": "On reconnaîtra les criminels à leurs traits. Ils seront donc saisis par les toupets et les pieds"
    },
    {
      "key": 4943,
      "id": "55-42",
      "translation": "Lequel donc des bienfaits de votre Seigneur nierez-vous"
    },
    {
      "key": 4944,
      "id": "55-43",
      "translation": "Voilà l'Enfer que les criminels traitaient de mensonge"
    },
    {
      "key": 4945,
      "id": "55-44",
      "translation": "Ils feront le va-et-vient entre lui (l'Enfer) et une eau bouillante extrêmement chaude"
    },
    {
      "key": 4946,
      "id": "55-45",
      "translation": "Lequel donc des bienfaits de votre Seigneur nierez-vous"
    },
    {
      "key": 4947,
      "id": "55-46",
      "translation": "Et pour celui qui aura craint de comparaître devant son Seigneur, il y aura deux jardins"
    },
    {
      "key": 4948,
      "id": "55-47",
      "translation": "Lequel donc des bienfaits de votre Seigneur nierez-vous"
    },
    {
      "key": 4949,
      "id": "55-48",
      "translation": "Aux branches touffues"
    },
    {
      "key": 4950,
      "id": "55-49",
      "translation": "Lequel donc des bienfaits de votre Seigneur nierez-vous"
    },
    {
      "key": 4951,
      "id": "55-50",
      "translation": "Ils y trouveront deux sources courantes"
    },
    {
      "key": 4952,
      "id": "55-51",
      "translation": "Lequel donc des bienfaits de votre Seigneur nierez-vous"
    },
    {
      "key": 4953,
      "id": "55-52",
      "translation": "Ils contiennent deux espèces de chaque fruit"
    },
    {
      "key": 4954,
      "id": "55-53",
      "translation": "Lequel donc des bienfaits de votre Seigneur nierez-vous"
    },
    {
      "key": 4955,
      "id": "55-54",
      "translation": "Ils seront accoudés sur des tapis doublés de brocart, et les fruits des deux jardins seront à leur portée (pour être cueillis)"
    },
    {
      "key": 4956,
      "id": "55-55",
      "translation": "Lequel donc des bienfaits de votre Seigneur nierez-vous"
    },
    {
      "key": 4957,
      "id": "55-56",
      "translation": "Ils y trouveront [les houris] aux regards chastes, qu'avant eux aucun homme ou djinn n'aura déflorées"
    },
    {
      "key": 4958,
      "id": "55-57",
      "translation": "Lequel donc des bienfaits de votre Seigneur nierez-vous"
    },
    {
      "key": 4959,
      "id": "55-58",
      "translation": "Elles seront [aussi belles] que le rubis et le corail"
    },
    {
      "key": 4960,
      "id": "55-59",
      "translation": "Lequel donc des bienfaits de votre Seigneur nierez-vous"
    },
    {
      "key": 4961,
      "id": "55-60",
      "translation": "Y a-t-il d'autre récompense pour le bien, que le bien"
    },
    {
      "key": 4962,
      "id": "55-61",
      "translation": "Lequel donc des bienfaits de votre Seigneur nierez-vous"
    },
    {
      "key": 4963,
      "id": "55-62",
      "translation": "En deçà de ces deux jardins il y aura deux autres jardins"
    },
    {
      "key": 4964,
      "id": "55-63",
      "translation": "Lequel donc des bienfaits de votre Seigneur nierez-vous"
    },
    {
      "key": 4965,
      "id": "55-64",
      "translation": "Ils sont d'un vert sombre"
    },
    {
      "key": 4966,
      "id": "55-65",
      "translation": "Lequel donc des bienfaits de votre Seigneur nierez-vous"
    },
    {
      "key": 4967,
      "id": "55-66",
      "translation": "Dans lesquelles il y aura deux sources jaillissantes"
    },
    {
      "key": 4968,
      "id": "55-67",
      "translation": "Lequel donc des bienfaits de votre Seigneur nierez-vous"
    },
    {
      "key": 4969,
      "id": "55-68",
      "translation": "Ils contiennent des fruits, des palmiers, et des grenadiers"
    },
    {
      "key": 4970,
      "id": "55-69",
      "translation": "Lequel donc des bienfaits de votre Seigneur nierez-vous"
    },
    {
      "key": 4971,
      "id": "55-70",
      "translation": "Là, il y aura des vertueuses et des belles"
    },
    {
      "key": 4972,
      "id": "55-71",
      "translation": "Lequel donc des bienfaits de votre Seigneur nierez-vous"
    },
    {
      "key": 4973,
      "id": "55-72",
      "translation": "Des houris cloîtrées dans les tentes"
    },
    {
      "key": 4974,
      "id": "55-73",
      "translation": "Lequel donc des bienfaits de votre Seigneur nierez-vous"
    },
    {
      "key": 4975,
      "id": "55-74",
      "translation": "qu'avant eux aucun homme ou djinn n'a déflorées"
    },
    {
      "key": 4976,
      "id": "55-75",
      "translation": "Lequel donc des bienfaits de votre Seigneur nierez-vous"
    },
    {
      "key": 4977,
      "id": "55-76",
      "translation": "Ils seront accoudés sur des coussins verts et des tapis épais et jolis"
    },
    {
      "key": 4978,
      "id": "55-77",
      "translation": "Lequel donc des bienfaits de votre Seigneur nierez-vous"
    },
    {
      "key": 4979,
      "id": "55-78",
      "translation": "Béni soit le Nom de ton Seigneur, Plein de Majesté et de Munificence"
    },
    {
      "key": 4980,
      "id": "56-1",
      "translation": "Quand l'événement (le Jugement) arrivera"
    },
    {
      "key": 4981,
      "id": "56-2",
      "translation": "nul ne traitera sa venue de mensonge"
    },
    {
      "key": 4982,
      "id": "56-3",
      "translation": "Il abaissera (les uns), il élèvera (les autres)"
    },
    {
      "key": 4983,
      "id": "56-4",
      "translation": "Quand la terre sera secouée violemment"
    },
    {
      "key": 4984,
      "id": "56-5",
      "translation": "et les montagnes seront réduites en miettes"
    },
    {
      "key": 4985,
      "id": "56-6",
      "translation": "et qu'elles deviendront poussière éparpillée"
    },
    {
      "key": 4986,
      "id": "56-7",
      "translation": "alors vous serez trois catégories"
    },
    {
      "key": 4987,
      "id": "56-8",
      "translation": "les gens de la droite - que sont les gens de la droite"
    },
    {
      "key": 4988,
      "id": "56-9",
      "translation": "Et les gens de la gauche - que sont les gens de la gauche"
    },
    {
      "key": 4989,
      "id": "56-10",
      "translation": "Les premiers (à suivre les ordres d'Allah sur la terre) ce sont eux qui seront les premiers (dans l'au-delà)"
    },
    {
      "key": 4990,
      "id": "56-11",
      "translation": "Ce sont ceux-là les plus rapprochés d'Allah"
    },
    {
      "key": 4991,
      "id": "56-12",
      "translation": "dans les Jardins des délices"
    },
    {
      "key": 4992,
      "id": "56-13",
      "translation": "une multitude d'élus parmi les premières [générations]"
    },
    {
      "key": 4993,
      "id": "56-14",
      "translation": "et un petit nombre parmi les dernières [générations]"
    },
    {
      "key": 4994,
      "id": "56-15",
      "translation": "sur des lits ornés [d'or et de pierreries]"
    },
    {
      "key": 4995,
      "id": "56-16",
      "translation": "s'y accoudant et se faisant face"
    },
    {
      "key": 4996,
      "id": "56-17",
      "translation": "Parmi eux circuleront des garçons éternellement jeunes"
    },
    {
      "key": 4997,
      "id": "56-18",
      "translation": "avec des coupes, des aiguières et un verre [rempli] d'une liqueur de source"
    },
    {
      "key": 4998,
      "id": "56-19",
      "translation": "qui ne leur provoquera ni maux de tête ni étourdissement"
    },
    {
      "key": 4999,
      "id": "56-20",
      "translation": "et des fruits de leur choix"
    },
    {
      "key": 5000,
      "id": "56-21",
      "translation": "et toute chair d'oiseau qu'ils désireront"
    },
    {
      "key": 5001,
      "id": "56-22",
      "translation": "Et ils auront des houris aux yeux, grands et beaux"
    },
    {
      "key": 5002,
      "id": "56-23",
      "translation": "pareilles à des perles en coquille"
    },
    {
      "key": 5003,
      "id": "56-24",
      "translation": "en récompense pour ce qu'ils faisaient"
    },
    {
      "key": 5004,
      "id": "56-25",
      "translation": "Ils n'y entendront ni futilité ni blasphème"
    },
    {
      "key": 5005,
      "id": "56-26",
      "translation": "mais seulement les propos: «Salâm! Salâm!»... [Paix! Paix]"
    },
    {
      "key": 5006,
      "id": "56-27",
      "translation": "Et les gens de la droite; que sont les gens de la droite"
    },
    {
      "key": 5007,
      "id": "56-28",
      "translation": "[Ils seront parmi] des jujubiers sans épines"
    },
    {
      "key": 5008,
      "id": "56-29",
      "translation": "et parmi des bananiers aux régimes bien fournis"
    },
    {
      "key": 5009,
      "id": "56-30",
      "translation": "dans une ombre étendue"
    },
    {
      "key": 5010,
      "id": "56-31",
      "translation": "[près] d'une eau coulant continuellement"
    },
    {
      "key": 5011,
      "id": "56-32",
      "translation": "et des fruits abondants"
    },
    {
      "key": 5012,
      "id": "56-33",
      "translation": "ni interrompus ni défendus"
    },
    {
      "key": 5013,
      "id": "56-34",
      "translation": "sur des lits surélevés"
    },
    {
      "key": 5014,
      "id": "56-35",
      "translation": "C'est Nous qui les avons créées à la perfection"
    },
    {
      "key": 5015,
      "id": "56-36",
      "translation": "et Nous les avons faites vierges"
    },
    {
      "key": 5016,
      "id": "56-37",
      "translation": "gracieuses, toutes de même âge"
    },
    {
      "key": 5017,
      "id": "56-38",
      "translation": "pour les gens de la droite"
    },
    {
      "key": 5018,
      "id": "56-39",
      "translation": "une multitude d'élus parmi les premières [générations]"
    },
    {
      "key": 5019,
      "id": "56-40",
      "translation": "et une multitude d'élus parmi les dernières [générations]"
    },
    {
      "key": 5020,
      "id": "56-41",
      "translation": "Et les gens de la gauche; que sont les gens de la gauche"
    },
    {
      "key": 5021,
      "id": "56-42",
      "translation": "ils seront au milieu d'un souffle brûlant et d'une eau bouillante"
    },
    {
      "key": 5022,
      "id": "56-43",
      "translation": "à l'ombre d'une fumée noire"
    },
    {
      "key": 5023,
      "id": "56-44",
      "translation": "ni fraîche, ni douce"
    },
    {
      "key": 5024,
      "id": "56-45",
      "translation": "Ils vivaient auparavant dans le luxe"
    },
    {
      "key": 5025,
      "id": "56-46",
      "translation": "Ils persistaient dans le grand péché [le polythéisme]"
    },
    {
      "key": 5026,
      "id": "56-47",
      "translation": "et disaient: «Quand nous mourrons et serons poussière et ossements, serons-nous ressuscités"
    },
    {
      "key": 5027,
      "id": "56-48",
      "translation": "ainsi que nos anciens ancêtres?..»"
    },
    {
      "key": 5028,
      "id": "56-49",
      "translation": "Dis: «En vérité les premiers et les derniers"
    },
    {
      "key": 5029,
      "id": "56-50",
      "translation": "seront réunis pour le rendez-vous d'un jour connu»"
    },
    {
      "key": 5030,
      "id": "56-51",
      "translation": "Et puis, vous, les égarés, qui traitiez (la Résurrection) de mensonge"
    },
    {
      "key": 5031,
      "id": "56-52",
      "translation": "vous mangerez certainement d'un arbre de Zaqqûm"
    },
    {
      "key": 5032,
      "id": "56-53",
      "translation": "vous vous en remplirez le ventre"
    },
    {
      "key": 5033,
      "id": "56-54",
      "translation": "puis vous boirez par-dessus cela de l'eau bouillante"
    },
    {
      "key": 5034,
      "id": "56-55",
      "translation": "vous en boirez comme boivent les chameaux assoiffés"
    },
    {
      "key": 5035,
      "id": "56-56",
      "translation": "Voilà le repas d'accueil qui leur sera servi, au jour de la Rétribution"
    },
    {
      "key": 5036,
      "id": "56-57",
      "translation": "C'est Nous qui vous avons créés. Pourquoi ne croiriez-vous donc pas [à la résurrection]"
    },
    {
      "key": 5037,
      "id": "56-58",
      "translation": "Voyez-vous donc ce que vous éjaculez"
    },
    {
      "key": 5038,
      "id": "56-59",
      "translation": "est-ce vous qui le créez ou [en] sommes Nous le Créateur"
    },
    {
      "key": 5039,
      "id": "56-60",
      "translation": "Nous avons prédéterminé la mort parmi vous. Nous ne serons point empêchés"
    },
    {
      "key": 5040,
      "id": "56-61",
      "translation": "de vous remplacer par vos semblables, et vous faire renaître dans [un état] que vous ne savez pas"
    },
    {
      "key": 5041,
      "id": "56-62",
      "translation": "Vous avez connu la première création. Ne vous rappelez-vous donc pas"
    },
    {
      "key": 5042,
      "id": "56-63",
      "translation": "Voyez-vous donc ce que vous labourez"
    },
    {
      "key": 5043,
      "id": "56-64",
      "translation": "Est-ce vous qui le cultivez? ou [en] sommes Nous le cultivateur"
    },
    {
      "key": 5044,
      "id": "56-65",
      "translation": "Si Nous voulions, Nous le réduirions en débris. Et vous ne cesseriez pas de vous étonner et [de crier]"
    },
    {
      "key": 5045,
      "id": "56-66",
      "translation": "«Nous voilà endettés"
    },
    {
      "key": 5046,
      "id": "56-67",
      "translation": "ou plutôt, exposés aux privations»"
    },
    {
      "key": 5047,
      "id": "56-68",
      "translation": "Voyez-vous donc l'eau que vous buvez"
    },
    {
      "key": 5048,
      "id": "56-69",
      "translation": "Est-ce vous qui l'avez fait descendre du nuage? ou [en] sommes Nous le descendeur"
    },
    {
      "key": 5049,
      "id": "56-70",
      "translation": "Si Nous voulions, Nous la rendrions salée. Pourquoi n'êtes-vous donc pas reconnaissants"
    },
    {
      "key": 5050,
      "id": "56-71",
      "translation": "Voyez-vous donc le feu que vous obtenez par frottement"
    },
    {
      "key": 5051,
      "id": "56-72",
      "translation": "Est-ce vous qui avez créé son arbre ou [en] sommes Nous le Créateur"
    },
    {
      "key": 5052,
      "id": "56-73",
      "translation": "Nous en avons fait un rappel (de l'Enfer), et un élément utile pour ceux qui en ont besoin"
    },
    {
      "key": 5053,
      "id": "56-74",
      "translation": "Glorifie donc le nom de ton Seigneur, le Très Grand"
    },
    {
      "key": 5054,
      "id": "56-75",
      "translation": "Non!.. Je jure par les positions des étoiles (dans le firmament)"
    },
    {
      "key": 5055,
      "id": "56-76",
      "translation": "Et c'est vraiment un serment solennel, si vous saviez"
    },
    {
      "key": 5056,
      "id": "56-77",
      "translation": "Et c'est certainement un Coran noble"
    },
    {
      "key": 5057,
      "id": "56-78",
      "translation": "dans un Livre bien gardé"
    },
    {
      "key": 5058,
      "id": "56-79",
      "translation": "que seuls les purifiés touchent"
    },
    {
      "key": 5059,
      "id": "56-80",
      "translation": "C'est une révélation de la part du Seigneur de l'Univers"
    },
    {
      "key": 5060,
      "id": "56-81",
      "translation": "Est-ce ce discours-là que vous traitez de mensonge"
    },
    {
      "key": 5061,
      "id": "56-82",
      "translation": "Et est-ce pour vous [une façon d'être reconnaissant] à votre subsistance que de traiter (le Coran) de mensonge"
    },
    {
      "key": 5062,
      "id": "56-83",
      "translation": "Lorsque le souffle de la vie remonte à la gorge (d'un moribond)"
    },
    {
      "key": 5063,
      "id": "56-84",
      "translation": "et qu'à ce moment là vous regardez"
    },
    {
      "key": 5064,
      "id": "56-85",
      "translation": "et que Nous sommes plus proche de lui que vous [qui l'entourez] mais vous ne [le] voyez point"
    },
    {
      "key": 5065,
      "id": "56-86",
      "translation": "Pourquoi donc, si vous croyez que vous n'avez pas de compte à rendre"
    },
    {
      "key": 5066,
      "id": "56-87",
      "translation": "ne la faites-vous pas revenir [cette âme], si vous êtes véridiques"
    },
    {
      "key": 5067,
      "id": "56-88",
      "translation": "Si celui-ci est du nombre des rapprochés (d'Allah)"
    },
    {
      "key": 5068,
      "id": "56-89",
      "translation": "alors (il aura) du repos, de la grâce et un Jardin de délices"
    },
    {
      "key": 5069,
      "id": "56-90",
      "translation": "Et s'il est du nombre des gens de la droite"
    },
    {
      "key": 5070,
      "id": "56-91",
      "translation": "il sera [accueilli par ces mots]: «Paix à toi» de la part des gens de la droite"
    },
    {
      "key": 5071,
      "id": "56-92",
      "translation": "Et s'il est de ceux qui avaient traité de mensonge (la résurrection) et s'étaient égarés"
    },
    {
      "key": 5072,
      "id": "56-93",
      "translation": "alors, il sera installé dans une eau bouillante"
    },
    {
      "key": 5073,
      "id": "56-94",
      "translation": "et il brûlera dans la Fournaise"
    },
    {
      "key": 5074,
      "id": "56-95",
      "translation": "C'est cela la pleine certitude"
    },
    {
      "key": 5075,
      "id": "56-96",
      "translation": "Glorifie donc le nom de ton Seigneur, le Très Grand"
    },
    {
      "key": 5076,
      "id": "57-1",
      "translation": "Tout ce qui est dans les cieux et la terre glorifie Allah. Et c'est Lui le Puissant, le Sage"
    },
    {
      "key": 5077,
      "id": "57-2",
      "translation": "A Lui appartient la souveraineté des cieux et de la terre. Il fait vivre et il fait mourir, et Il est Omnipotent"
    },
    {
      "key": 5078,
      "id": "57-3",
      "translation": "C'est Lui le Premier et le Dernier, l'Apparent et le Caché et Il est Omniscient"
    },
    {
      "key": 5079,
      "id": "57-4",
      "translation": "C'est Lui qui a créé les cieux et la terre en six jours puis Il S'est établi sur le Trône; Il sait ce qui pénètre dans la terre et ce qui en sort, et ce qui descend du ciel et ce qui y monte, et Il est avec vous où que vous soyez. Et Allah observe parfaitement ce que vous faites"
    },
    {
      "key": 5080,
      "id": "57-5",
      "translation": "A Lui appartient la souveraineté des cieux et de la terre. Et à Allah tout est ramené"
    },
    {
      "key": 5081,
      "id": "57-6",
      "translation": "Il fait pénétrer la nuit dans le jour et fait pénétrer le jour dans la nuit, et Il sait parfaitement le contenu des poitrines"
    },
    {
      "key": 5082,
      "id": "57-7",
      "translation": "Croyez en Allah et en Son Messager, et dépensez de ce dont Il vous a donné la lieutenance. Ceux d'entre vous qui croient et dépensent [pour la cause d'Allah] auront une grande récompense"
    },
    {
      "key": 5083,
      "id": "57-8",
      "translation": "Et qu'avez-vous à ne pas croire en Allah, alors que le Messager vous appelle à croire en votre Seigneur? Et [Allah] a déjà pris [acte] de votre engagement si vous êtes [sincères] dans votre foi"
    },
    {
      "key": 5084,
      "id": "57-9",
      "translation": "C'est Lui qui fait descendre sur Son serviteur des versets clairs, afin qu'il vous fasse sortir des ténèbres à la lumière; et assurément Allah est Compatissant envers vous, et Très Miséricordieux"
    },
    {
      "key": 5085,
      "id": "57-10",
      "translation": "Et qu'avez-vous à ne pas dépenser dans le chemin d'Allah, alors que c'est à Allah que revient l'héritage des cieux et de la terre? On ne peut comparer cependant celui d'entre vous qui a donné ses biens et combattu avant la conquête... ces derniers sont plus hauts en hiérarchie que ceux qui ont dépensé et ont combattu après. Or, à chacun, Allah a promis la plus belle récompense, et Allah est Grand Connaisseur de ce que vous faites"
    },
    {
      "key": 5086,
      "id": "57-11",
      "translation": "Quiconque fait à Allah un prêt sincère, Allah le lui multiplie, et il aura une généreuse récompense"
    },
    {
      "key": 5087,
      "id": "57-12",
      "translation": "Le jour où tu verras les croyants et les croyantes, leur lumière courant devant eux et à leur droite; (on leur dira): «Voici une bonne nouvelle pour vous, aujourd'hui: des Jardins sous lesquels coulent les ruisseaux pour y demeurer éternellement». Tel est l'énorme succès"
    },
    {
      "key": 5088,
      "id": "57-13",
      "translation": "Le jour où les hypocrites, hommes et femmes, diront à ceux qui croient: «Attendez que nous empruntions [un peu] de votre lumière». Il sera dit: «Revenez en arrière, et cherchez de la lumière». C'est alors qu'on éleva entre eux une muraille ayant une porte dont l'intérieur contient la miséricorde, et dont la face apparente a devant elle le châtiment [l'Enfer]"
    },
    {
      "key": 5089,
      "id": "57-14",
      "translation": "«N'étions-nous pas avec vous?» leur crieront-ils. «Oui, répondront [les autres] mais vous vous êtes laissés tenter, vous avez comploté (contre les croyants) et vous avez douté et de vains espoirs vous ont trompés, jusqu'à ce que vînt l'ordre d'Allah. Et le séducteur [le diable] vous a trompés au sujet d'Allah"
    },
    {
      "key": 5090,
      "id": "57-15",
      "translation": "Aujourd'hui donc, on n'acceptera de rançon ni de vous ni de ceux qui ont mécru. Votre asile est le Feu: c'est lui qui est votre compagnon inséparable. Et quelle mauvaise destination"
    },
    {
      "key": 5091,
      "id": "57-16",
      "translation": "Le moment n'est-il pas venu pour ceux qui ont cru, que leurs cœurs s'humilient à l'évocation d'Allah et devant ce qui est descendu de la vérité [le Coran]? Et de ne point être pareils à ceux qui ont reçu le Livre avant eux. Ceux-ci trouvèrent le temps assez long et leurs cœurs s'endurcirent, et beaucoup d'entre eux sont pervers"
    },
    {
      "key": 5092,
      "id": "57-17",
      "translation": "Sachez qu'Allah redonne la vie à la terre une fois morte. Certes, Nous vous avons exposé les preuves clairement afin que vous raisonniez"
    },
    {
      "key": 5093,
      "id": "57-18",
      "translation": "Ceux et celles qui font la charité et qui ont fait à Allah un prêt sincère, cela leur sera multiplié et ils auront une généreuse récompense"
    },
    {
      "key": 5094,
      "id": "57-19",
      "translation": "Ceux qui ont cru en Allah et en Ses messagers ceux-là sont les grands véridiques et les témoins auprès d'Allah. Ils auront leur récompense et leur lumière, tandis que ceux qui ont mécru et traité de mensonges Nos signes, ceux-là seront les gens de la Fournaise"
    },
    {
      "key": 5095,
      "id": "57-20",
      "translation": "Sachez que la vie présente n'est que jeu, amusement, vaine parure, une course à l'orgueil entre vous et une rivalité dans l'acquisition des richesses et des enfants. Elle est en cela pareille à une pluie: la végétation qui en vient émerveille les cultivateurs, puis elle se fane et tu la vois donc jaunie; ensuite elle devient des débris. Et dans l'au-delà, il y a un dur châtiment, et aussi pardon et agrément d'Allah. Et la vie présente n'est que jouissance trompeuse"
    },
    {
      "key": 5096,
      "id": "57-21",
      "translation": "Hâtez-vous vers un pardon de votre Seigneur ainsi qu'un Paradis aussi large que le ciel et la terre, préparé pour ceux qui ont cru en Allah et en Ses Messagers. Telle est la grâce d'Allah qu'Il donne à qui Il veut. Et Allah est le Détenteur de l'énorme grâce"
    },
    {
      "key": 5097,
      "id": "57-22",
      "translation": "Nul malheur n'atteint la terre ni vos personnes, qui ne soit enregistré dans un Livre avant que Nous ne l'ayons créé; et cela est certes facile à Allah"
    },
    {
      "key": 5098,
      "id": "57-23",
      "translation": "afin que vous ne vous tourmentiez pas au sujet de ce qui vous a échappé, ni n'exultiez pour ce qu'Il vous a donné. Et Allah n'aime point tout présomptueux plein de gloriole"
    },
    {
      "key": 5099,
      "id": "57-24",
      "translation": "Ceux qui sont avares et ordonnent aux gens l'avarice. Et quiconque se détourne... Allah Se suffit alors à Lui-même et Il est Digne de louange"
    },
    {
      "key": 5100,
      "id": "57-25",
      "translation": "Nous avons effectivement envoyé Nos Messagers avec des preuves évidentes, et fait descendre avec eux le Livre et la balance, afin que les gens établissent la justice. Et Nous avons fait descendre le fer, dans lequel il y a une force redoutable, aussi bien que des utilités pour les gens, et pour qu'Allah reconnaisse qui, dans l'Invisible, défendra Sa cause et celle de Ses Messagers. Certes, Allah est Fort et Puissant"
    },
    {
      "key": 5101,
      "id": "57-26",
      "translation": "Nous avons effectivement envoyé Noé et Abraham et accordé à leur descendance la prophétie et le Livre. Certains d'entre eux furent bien-guidés, tandis que beaucoup d'entre eux furent pervers"
    },
    {
      "key": 5102,
      "id": "57-27",
      "translation": "Ensuite, sur leurs traces, Nous avons fait suivre Nos [autres] messagers, et Nous les avons fait suivre de Jésus fils de Marie et lui avons apporté l'Evangile, et mis dans les cœurs de ceux qui le suivirent douceur et mansuétude. Le monachisme qu'ils inventèrent, Nous ne le leur avons nullement prescrit. [Ils devaient] seulement rechercher l'agrément d'Allah. Mais ils ne l'observèrent pas (ce monachisme) comme il se devait. Nous avons donné leur récompense à ceux d'entre eux qui crurent. Mais beaucoup d'entre eux furent des pervers"
    },
    {
      "key": 5103,
      "id": "57-28",
      "translation": "O Vous qui avez cru! Craignez Allah et croyez en Son messager pour qu'Il vous accorde deux parts de Sa miséricorde, et qu'Il vous assigne une lumière à l'aide de laquelle vous marcherez, et qu'Il vous pardonne, car Allah est Pardonneur et Très Miséricordieux"
    },
    {
      "key": 5104,
      "id": "57-29",
      "translation": "Cela afin que les gens du Livre sachent qu'ils ne peuvent en rien disposer de la grâce d'Allah et que la grâce est dans la main d'Allah. Il la donne à qui Il veut, et Allah est le Détenteur de la grâce immense"
    },
    {
      "key": 5105,
      "id": "58-1",
      "translation": "Allah a bien entendu la parole de celle qui discutait avec toi à propos de son époux et se plaignait à Allah. Et Allah entendait votre conversation, car Allah est Audient et Clairvoyant"
    },
    {
      "key": 5106,
      "id": "58-2",
      "translation": "Ceux d'entre vous qui répudient leurs femmes, en déclarant qu'elles sont pour eux comme le dos de leurs mères. alors qu'elles ne sont nullement leurs mères, car ils n'ont pour mères que celles qui les ont enfantés. Ils prononcent certes une parole blâmable et mensongère. Allah cependant est Indulgent et Pardonneur"
    },
    {
      "key": 5107,
      "id": "58-3",
      "translation": "Ceux qui comparent leurs femmes au dos de leurs mères puis reviennent sur ce qu'ils ont dit, doivent affranchir un esclave avant d'avoir aucun contact [conjugal] avec leur femme. C'est ce dont on vous exhorte. Et Allah est Parfaitement Connaisseur de ce que vous faites"
    },
    {
      "key": 5108,
      "id": "58-4",
      "translation": "Mais celui qui n'en trouve pas les moyens doit jeûner alors deux mois consécutifs avant d'avoir aucun contact [conjugal] avec sa femme. Mais s'il ne peut le faire non plus, alors qu'il nourrisse soixante pauvres. Cela, pour que vous croyiez en Allah et en Son messager. Voilà les limites imposées par Allah. Et les mécréants auront un châtiment douloureux"
    },
    {
      "key": 5109,
      "id": "58-5",
      "translation": "Ceux qui s'opposent à Allah et à Son messager seront culbutés comme furent culbutés leurs devanciers. Nous avons déjà fait descendre des preuves explicites, et les mécréants auront un châtiment avilissant"
    },
    {
      "key": 5110,
      "id": "58-6",
      "translation": "le jour où Allah les ressuscitera tous, puis les informera de ce qu'ils ont fait Allah l'a dénombré et ils l'auront oublié. Allah est témoin de toute chose"
    },
    {
      "key": 5111,
      "id": "58-7",
      "translation": "Ne vois-tu pas qu'Allah sait ce qui est dans les cieux et sur la terre? Pas de conversation secrète entre trois sans qu'Il ne soit leur quatrième, ni entre cinq sans qu'Il n'y ne soit leur sixième, ni moins ni plus que cela sans qu'Il ne soit avec eux, là où ils se trouvent. Ensuite, Il les informera au Jour de la Résurrection, de ce qu'ils faisaient, car Allah est Omniscient"
    },
    {
      "key": 5112,
      "id": "58-8",
      "translation": "Ne vois-tu pas ceux à qui les conversations secrètes ont été interdites? Puis, ils retournent à ce qui leur a été interdit, et se concertent pour pécher, transgresser et désobéir au Messager. Et quand ils viennent à toi, ils te saluent d'une façon dont Allah ne t'a pas salué, et disent en eux-mêmes: «Pourquoi Allah ne nous châtie pas pour ce que nous disons?» L'Enfer leur suffira, où ils brûleront. Et quelle mauvaise destination"
    },
    {
      "key": 5113,
      "id": "58-9",
      "translation": "O vous qui avez cru! Quand vous tenez des conversations secrètes, ne vous concertez pas pour pécher, transgresser et désobéir au Messager, mais concertez-vous dans la bonté et la piété. Et craignez Allah vers qui vous serez rassemblés"
    },
    {
      "key": 5114,
      "id": "58-10",
      "translation": "La conversation secrète n'est que [l'œuvre] du Diable pour attrister ceux qui ont cru. Mais il ne peut leur nuire en rien sans la permission d'Allah. Et c'est en Allah que les croyants doivent placer leur confiance"
    },
    {
      "key": 5115,
      "id": "58-11",
      "translation": "O vous qui avez cru! Quand on vous dit: «Faites place [aux autres] dans les assemblées», alors faites place. Allah vous ménagera une place (au Paradis). Et quand on vous dit de vous lever, levez-vous. Allah élèvera en degrés ceux d'entre vous qui auront cru et ceux qui auront reçu le savoir. Allah est Parfaitement Connaisseur de ce que vous faites"
    },
    {
      "key": 5116,
      "id": "58-12",
      "translation": "O vous qui avez cru! Quand vous avez un entretien confidentiel avec le Messager, faites précéder d'une aumône votre entretien: cela est meilleur pour vous et plus pur. Mais si vous n'en trouvez pas les moyens alors Allah est Pardonneur et très Miséricordieux"
    },
    {
      "key": 5117,
      "id": "58-13",
      "translation": "Appréhendez-vous de faire précéder d'aumônes votre entretien? Mais, si vous ne l'avez pas fait et qu'Allah a accueilli votre repentir, alors accomplissez la Salât, acquittez la Zakât, et obéissez à Allah et à Son messager. Allah est Parfaitement Connaisseur de ce que vous faites"
    },
    {
      "key": 5118,
      "id": "58-14",
      "translation": "N'as-tu pas vu ceux qui ont pris pour alliés des gens contre qui Allah S'est courroucé? Ils ne sont ni des vôtres, ni des leurs; et ils jurent mensongèrement, alors qu'ils savent"
    },
    {
      "key": 5119,
      "id": "58-15",
      "translation": "Allah leur a préparé un dur châtiment. Ce qu'ils faisaient alors était très mauvais"
    },
    {
      "key": 5120,
      "id": "58-16",
      "translation": "Prenant leurs serments comme boucliers, ils obstruent le chemin d'Allah. Ils auront donc un châtiment avilissant"
    },
    {
      "key": 5121,
      "id": "58-17",
      "translation": "Ni leurs biens, ni leurs enfants ne leur seront d'aucune utilité contre la [punition] d'Allah. Ce sont les gens du Feu où ils demeureront éternellement"
    },
    {
      "key": 5122,
      "id": "58-18",
      "translation": "Le jour où Allah les ressuscitera tous, ils Lui jureront alors comme ils vous jurent à vous-mêmes, pensant s'appuyer sur quelque chose de solide. Mais ce sont eux les menteurs"
    },
    {
      "key": 5123,
      "id": "58-19",
      "translation": "Le Diable les a dominés et leur a fait oublier le rappel d'Allah. Ceux-là sont le parti du Diable et c'est le parti du Diable qui sont assurément les perdants"
    },
    {
      "key": 5124,
      "id": "58-20",
      "translation": "Ceux qui s'opposent à Allah et à Son messager seront parmi les plus humiliés"
    },
    {
      "key": 5125,
      "id": "58-21",
      "translation": "Allah a prescrit: «Assurément, Je triompherai, Moi ainsi que Mes Messagers». En vérité Allah est Fort et Puissant"
    },
    {
      "key": 5126,
      "id": "58-22",
      "translation": "Tu n'en trouveras pas, parmi les gens qui croient en Allah et au Jour dernier, qui prennent pour amis ceux qui s'opposent à Allah et à Son Messager, fussent-ils leurs pères, leurs fils, leurs frères ou les gens de leur tribu. Il a prescrit la foi dans leurs cœurs et Il les a aidés de Son secours. Il les fera entrer dans des Jardins sous lesquels coulent les ruisseaux, où ils demeureront éternellement. Allah les agrée et ils L'agréent. Ceux-là sont le parti d'Allah. Le parti d'Allah est celui de ceux qui réussissent"
    },
    {
      "key": 5127,
      "id": "59-1",
      "translation": "Ce qui est dans les cieux et ce qui est sur la terre glorifient Allah, et Il est le Puissant, le Sage"
    },
    {
      "key": 5128,
      "id": "59-2",
      "translation": "C'est Lui qui a expulsé de leurs maisons, ceux parmi les gens du Livre qui ne croyaient pas, lors du premier exode. Vous ne pensiez pas qu'ils partiraient, et ils pensaient qu'en vérité leurs forteresses les défendraient contre Allah. Mais Allah est venu à eux par où ils ne s'attendaient point, et a lancé la terreur dans leurs cœurs. Ils démolissaient leurs maisons de leurs propres mains, autant que des mains des croyants. Tirez-en une leçon, ô vous qui êtes doués de clairvoyance"
    },
    {
      "key": 5129,
      "id": "59-3",
      "translation": "Et si Allah n'avait pas prescrit contre eux l'expatriation, Il les aurait certainement châtiés ici-bas; et dans l'au-delà ils auront le châtiment du feu"
    },
    {
      "key": 5130,
      "id": "59-4",
      "translation": "Il en est ainsi parce qu'ils se sont dressés contre Allah et Son messager. Et quiconque se dresse contre Allah... alors, vraiment Allah est dur en punition"
    },
    {
      "key": 5131,
      "id": "59-5",
      "translation": "Tout palmier que vous avez coupé ou que vous avez laissé debout sur ses racines, c'est avec la permission d'Allah et afin qu'Il couvre ainsi d'ignominie les pervers"
    },
    {
      "key": 5132,
      "id": "59-6",
      "translation": "Le butin provenant de leurs biens et qu'Allah a accordé sans combat à Son Messager, vous n'y aviez engagé ni chevaux, ni chameaux; mais Allah, donne à Ses messagers la domination sur qui Il veut et Allah est Omnipotent"
    },
    {
      "key": 5133,
      "id": "59-7",
      "translation": "Le butin provenant [des biens] des habitants des cités, qu'Allah a accordé sans combat à Son Messager, appartient à Allah, au Messager, aux proches parents, aux orphelins, aux pauvres et au voyageur en détresse, afin que cela ne circule pas parmi les seuls riches d'entre vous. Prenez ce que le Messager vous donne; et ce qu'il vous interdit, abstenez-vous en; et craignez Allah car Allah est dur en punition"
    },
    {
      "key": 5134,
      "id": "59-8",
      "translation": "[Il appartient aussi] aux émigrés besogneux qui ont été expulsés de leurs demeures et de leurs biens, tandis qu'ils recherchaient une grâce et un agrément d'Allah, et qu'ils portaient secours à (la cause d') Allah et à Son Messager. Ceux-là sont les véridiques"
    },
    {
      "key": 5135,
      "id": "59-9",
      "translation": "Il [appartient également] à ceux qui, avant eux, se sont installés dans le pays et dans la foi, qui aiment ceux qui émigrent vers eux, et ne ressentent dans leurs cœurs aucune envie pour ce que [ces immigrés] ont reçu, et qui [les] préfèrent à eux-mêmes, même s'il y a pénurie chez eux. Quiconque se prémunit contre sa propre avarice, ceux-là sont ceux qui réussissent"
    },
    {
      "key": 5136,
      "id": "59-10",
      "translation": "Et [il appartient également] à ceux qui sont venus après eux en disant: «Seigneur, pardonne-nous, ainsi qu'à nos frères qui nous ont précédés dans la foi; et ne mets dans nos cœurs aucune rancœur pour ceux qui ont cru. Seigneur, Tu es Compatissant et Très Miséricordieux»"
    },
    {
      "key": 5137,
      "id": "59-11",
      "translation": "N'as-tu pas vu les hypocrites disant à leurs confrères qui ont mécru parmi les gens du Livre: «Si vous êtes chassés, nous partirons certes avec vous et nous n'obéirons jamais à personne contre vous; et si vous êtes attaqués, nous vous secourrons certes». Et Allah atteste qu'en vérité ils sont des menteurs"
    },
    {
      "key": 5138,
      "id": "59-12",
      "translation": "S'ils sont chassés, ils ne partiront pas avec eux; et s'ils sont attaqués, ils ne les secourront pas; et même s'ils allaient à leur secours, ils tourneraient sûrement le dos; puis ils ne seront point secourus"
    },
    {
      "key": 5139,
      "id": "59-13",
      "translation": "Vous jetez dans leurs cœurs plus de terreur qu'Allah. C'est qu'ils sont des gens qui ne comprennent pas"
    },
    {
      "key": 5140,
      "id": "59-14",
      "translation": "Tous ne vous combattront que retranchés dans des cités fortifiées ou de derrière des murailles. Leurs dissensions internes sont extrêmes. Tu les croirait unis, alors que leurs cœurs sont divisés. C'est qu'ils sont des gens qui ne raisonnent pas"
    },
    {
      "key": 5141,
      "id": "59-15",
      "translation": "Ils sont semblables à ceux qui, peu de temps avant eux, ont goûté la conséquence de leur comportement et ils auront un châtiment douloureux"
    },
    {
      "key": 5142,
      "id": "59-16",
      "translation": "ils sont semblables au Diable quand il dit à l'homme: «Sois incrédule». Puis quand il a mécru, il dit: «Je te désavoue car je redoute Allah, le Seigneur de l'Univers»"
    },
    {
      "key": 5143,
      "id": "59-17",
      "translation": "Ils eurent pour destinée d'être tous deux dans le Feu pour y demeurer éternellement. Telle est la rétribution des injustes"
    },
    {
      "key": 5144,
      "id": "59-18",
      "translation": "O vous qui avez cru! Craignez Allah. Que chaque âme voit bien ce qu'elle a avancé pour demain. Et craignez Allah, car Allah est Parfaitement Connaisseur de ce que vous faites"
    },
    {
      "key": 5145,
      "id": "59-19",
      "translation": "Et ne soyez pas comme ceux qui ont oublié Allah; [Allah] leur a fait alors oublier leurs propres personnes; ceux-là sont les pervers"
    },
    {
      "key": 5146,
      "id": "59-20",
      "translation": "Ne seront pas égaux les gens du Feu et les gens du Paradis. Les gens du Paradis sont eux les gagnants"
    },
    {
      "key": 5147,
      "id": "59-21",
      "translation": "Si Nous avions fait descendre ce Coran sur une montagne, tu l'aurais vu s'humilier et se fendre par crainte d'Allah. Et ces paraboles Nous les citons aux gens afin qu'ils réfléchissent"
    },
    {
      "key": 5148,
      "id": "59-22",
      "translation": "C'est Lui Allah. Nulle divinité autre que Lui, le Connaisseur de l'Invisible tout comme du visible. C'est Lui, le Tout Miséricordieux, le Très Miséricordieux"
    },
    {
      "key": 5149,
      "id": "59-23",
      "translation": "C'est Lui, Allah. Nulle divinité autre que Lui; Le Souverain, Le Pur, L'Apaisant, Le Rassurant, Le Prédominant, Le Tout Puissant, Le Contraignant, L'Orgueilleux. Gloire à Allah! Il transcende ce qu'ils Lui associent"
    },
    {
      "key": 5150,
      "id": "59-24",
      "translation": "C'est Lui Allah, le Créateur, Celui qui donne un commencement à toute chose, le Formateur. A Lui les plus beaux noms. Tout ce qui est dans les cieux et la terre Le glorifie. Et c'est Lui le Puissant, le Sage"
    },
    {
      "key": 5151,
      "id": "60-1",
      "translation": "O vous qui avez cru! Ne prenez pas pour alliés Mon ennemi et le vôtre, leur offrant l'amitié, alors qu'ils ont nié ce qui vous est parvenu de la vérité. Ils expulsent le Messager et vous-mêmes parce que vous croyez en Allah, votre Seigneur. Si vous êtes sortis pour lutter dans Mon chemin et pour rechercher Mon agrément, leur témoignerez-vous secrètement de l'amitié, alors que Je connais parfaitement ce que vous cachez et ce que vous divulguez? Et quiconque d'entre vous le fait s'égare de la droiture du sentier"
    },
    {
      "key": 5152,
      "id": "60-2",
      "translation": "S'ils vous dominent, ils seront des ennemis pour vous et étendront en mal leurs mains et leurs langues vers vous; et ils aimeraient que vous deveniez mécréants"
    },
    {
      "key": 5153,
      "id": "60-3",
      "translation": "Ni vos proches parents ni vos enfants ne vous seront d'aucune utilité le Jour de la Résurrection, Il [Allah] décidera entre vous, et Allah est Clairvoyant sur ce que vous faites"
    },
    {
      "key": 5154,
      "id": "60-4",
      "translation": "Certes, vous avez eu un bel exemple [à suivre] en Abraham et en ceux qui étaient avec lui, quand ils dirent à leur peuple: «Nous vous désavouons, vous et ce que vous adorez en dehors d'Allah. Nous vous renions. Entre vous et nous, l'inimitié et la haine sont à jamais déclarées jusqu'à ce que vous croyiez en Allah, seul». Exception faite de la parole d'Abraham [adressée] à son père: «J'implorerai certes, le pardon [d'Allah] en ta faveur bien que je ne puisse rien pour toi auprès d'Allah». «Seigneur, c'est en Toi que nous mettons notre confiance et à Toi nous revenons [repentants]. Et vers Toi est le Devenir"
    },
    {
      "key": 5155,
      "id": "60-5",
      "translation": "Seigneur, ne fais pas de nous [un sujet] de tentation pour ceux qui ont mécru; et pardonne-nous, Seigneur, car c'est Toi le Puissant, le Sage»"
    },
    {
      "key": 5156,
      "id": "60-6",
      "translation": "Vous avez certes eu en eux un bel exemple [à suivre], pour celui qui espère en Allah et en le Jour dernier; mais quiconque se détourne... alors Allah Se suffit à Lui-même et est Digne de louange"
    },
    {
      "key": 5157,
      "id": "60-7",
      "translation": "Il se peut qu'Allah établisse de l'amitié entre vous et ceux d'entre eux dont vous avez été les ennemis. Et Allah est Omnipotent et Allah est Pardonneur et Très Miséricordieux"
    },
    {
      "key": 5158,
      "id": "60-8",
      "translation": "Allah ne vous défend pas d'être bienfaisants et équitables envers ceux qui ne vous ont pas combattus pour la religion et ne vous ont pas chassés de vos demeures. Car Allah aime les équitables"
    },
    {
      "key": 5159,
      "id": "60-9",
      "translation": "Allah vous défend seulement de prendre pour alliés ceux qui vous ont combattus pour la religion, chassés de vos demeures et ont aidé à votre expulsion. Et ceux qui les prennent pour alliés sont les injustes"
    },
    {
      "key": 5160,
      "id": "60-10",
      "translation": "O vous qui avez cru! Quand les croyantes viennent à vous en émigrées, éprouvez-les; Allah connaît mieux leur foi; si vous constatez qu'elles sont croyantes, ne les renvoyez pas aux mécréants. Elles ne sont pas licites [en tant qu'épouses] pour eux, et eux non plus ne sont pas licites [en tant qu'époux] pour elles. Et rendez-leur ce qu'ils ont dépensé (comme mahr). Il ne vous sera fait aucun grief en vous mariant avec elles quand vous leur aurez donné leur mahr. Et ne gardez pas de liens conjugaux avec les mécréantes. Réclamez ce que vous avez dépensé et que (les mécréants) aussi réclament ce qu'ils ont dépensé. Tel est le jugement d'Allah par lequel Il juge entre vous, et Allah est Omniscient et Sage"
    },
    {
      "key": 5161,
      "id": "60-11",
      "translation": "Et si quelqu'une de vos épouses s'échappe vers les mécréants, et que vous fassiez des représailles, restituez à ceux dont les épouses sont parties autant que ce qu'ils avaient dépensé. Craignez Allah en qui vous croyez"
    },
    {
      "key": 5162,
      "id": "60-12",
      "translation": "O Prophète! Quand les croyantes viennent te prêter serment d'allégeance, [et en jurent] qu'elles n'associeront rien à Allah, qu'elles ne voleront pas, qu'elles ne se livreront pas à l'adultère, qu'elles ne tueront pas leurs propres enfants, qu'elles ne commettront aucune infamie ni avec leurs mains ni avec leurs pieds et qu'elles ne désobéiront pas en ce qui est convenable, alors reçois leur serment d'allégeance, et implore d'Allah le pardon pour elles. Allah est certes, Pardonneur et Très Miséricordieux"
    },
    {
      "key": 5163,
      "id": "60-13",
      "translation": "O vous qui avez cru! Ne prenez pas pour alliés des gens contre lesquels Allah est courroucé et qui désespèrent de l'au-delà, tout comme les mécréants désespèrent des gens des tombeaux"
    },
    {
      "key": 5164,
      "id": "61-1",
      "translation": "Ce qui est dans les cieux et ce qui est sur la terre glorifient Allah, et Il est le Puissant, le Sage"
    },
    {
      "key": 5165,
      "id": "61-2",
      "translation": "O vous qui avez cru! Pourquoi dites-vous ce que vous ne faites pas"
    },
    {
      "key": 5166,
      "id": "61-3",
      "translation": "C'est une grande abomination auprès d'Allah que de dire ce que vous ne faites pas"
    },
    {
      "key": 5167,
      "id": "61-4",
      "translation": "Allah aime ceux qui combattent dans Son chemin en rang serré pareils à un édifice renforcé"
    },
    {
      "key": 5168,
      "id": "61-5",
      "translation": "Et quand Moïse dit à son peuple: «O mon peuple! Pourquoi me maltraitez-vous alors que vous savez que je suis vraiment le Messager d'Allah [envoyé] à vous?» Puis quand ils dévièrent, Allah fit dévier leurs cœurs, car Allah ne guide pas les gens pervers"
    },
    {
      "key": 5169,
      "id": "61-6",
      "translation": "Et quand Jésus fils de Marie dit: «O Enfants d'Israël, je suis vraiment le Messager d'Allah [envoyé] à vous, confirmateur de ce qui, dans la Thora, est antérieur à moi, et annonciateur d'un Messager à venir après moi, dont le nom sera «Ahmad». Puis quand celui-ci vint à eux avec des preuves évidentes, ils dirent: «C'est là une magie manifeste»"
    },
    {
      "key": 5170,
      "id": "61-7",
      "translation": "Et qui est plus injuste que celui qui invente un mensonge contre Allah, alors qu'il est appelé à l'Islam? Et Allah ne guide pas les gens injustes"
    },
    {
      "key": 5171,
      "id": "61-8",
      "translation": "Ils veulent éteindre de leurs bouches la lumière d'Allah, alors qu'Allah parachèvera Sa lumière en dépit de l'aversion des mécréants"
    },
    {
      "key": 5172,
      "id": "61-9",
      "translation": "C'est Lui qui a envoyé Son messager avec la guidée et la Religion de Vérité, pour la placer au-dessus de toute autre religion, en dépit de l'aversion des associateurs"
    },
    {
      "key": 5173,
      "id": "61-10",
      "translation": "O vous qui avez cru! vous indiquerai-je un commerce qui vous sauvera d'un châtiment douloureux"
    },
    {
      "key": 5174,
      "id": "61-11",
      "translation": "Vous croyez en Allah et en Son messager et vous combattez avec vos biens et vos personnes dans le chemin d'Allah, et cela vous est bien meilleur, si vous saviez"
    },
    {
      "key": 5175,
      "id": "61-12",
      "translation": "Il vous pardonnera vos péchés et vous fera entrer dans des Jardins sous lesquels coulent les ruisseaux, et dans des demeures agréables dans les jardins d'Eden. Voilà l'énorme succès"
    },
    {
      "key": 5176,
      "id": "61-13",
      "translation": "et Il vous accordera d'autres choses encore que vous aimez bien: un secours [venant] d'Allah et une victoire prochaine. Et annonce la bonne nouvelle aux croyants"
    },
    {
      "key": 5177,
      "id": "61-14",
      "translation": "O vous qui avez cru! Soyez les alliés d'Allah, à l'instar de ce que Jésus fils de Marie a dit aux apôtres: «Qui sont mes alliés (pour la cause) d'Allah?» - Les apôtres dirent: «Nous sommes les alliés d'Allah». Un groupe des Enfants d'Israël crut, tandis qu'un groupe nia. Nous aidâmes donc ceux qui crurent contre leur ennemi, et ils triomphèrent"
    },
    {
      "key": 5178,
      "id": "62-1",
      "translation": "Ce qui est dans les cieux et ce qui est sur la terre glorifient Allah, le Souverain, le Pur, le Puissant, le Sage"
    },
    {
      "key": 5179,
      "id": "62-2",
      "translation": "C'est Lui qui a envoyé à des gens sans Livre (les Arabes) un Messager des leurs qui leur récite Ses versets, les purifie et leur enseigne le Livre et la Sagesse, bien qu'ils étaient auparavant dans un égarement évident"
    },
    {
      "key": 5180,
      "id": "62-3",
      "translation": "ainsi qu'à d'autres parmi ceux qui ne les ont pas encore rejoints. C'est Lui le Puissant, le Sage"
    },
    {
      "key": 5181,
      "id": "62-4",
      "translation": "Telle est la grâce d'Allah qu'Il donne à qui Il veut. Et Allah est le Détenteur de l'énorme grâce"
    },
    {
      "key": 5182,
      "id": "62-5",
      "translation": "Ceux qui ont été chargés de la Thora mais qui ne l'ont pas appliquée sont pareils à l'âne qui porte des livres. Quel mauvais exemple que celui de ceux qui traitent de mensonges les versets d'Allah et Allah ne guide pas les gens injustes"
    },
    {
      "key": 5183,
      "id": "62-6",
      "translation": "Dis: «O vous qui pratiquez le judaïsme! Si vous prétendez être les bien-aimés d'Allah à l'exclusion des autres, souhaitez, donc la mort, si vous êtes véridiques»"
    },
    {
      "key": 5184,
      "id": "62-7",
      "translation": "Or, ils ne la souhaiteront jamais, à cause de ce que leurs mains ont préparé. Allah cependant connaît bien les injustes"
    },
    {
      "key": 5185,
      "id": "62-8",
      "translation": "Dis: «La mort que vous fuyez va certes vous rencontrer. Ensuite vous serez ramenés à Celui qui connaît parfaitement le monde Invisible et le monde visible et qui vous informera alors de ce que vous faisiez»"
    },
    {
      "key": 5186,
      "id": "62-9",
      "translation": "O vous qui avez cru! Quand on appelle à la Salât du jour du Vendredi, accourez à l'invocation d'Allah et laissez tout négoce. Cela est bien meilleur pour vous, si vous saviez"
    },
    {
      "key": 5187,
      "id": "62-10",
      "translation": "Puis quand la Salât est achevée, dispersez-vous sur terre et recherchez [quelque effet] de la grâce d'Allah, et invoquez beaucoup Allah afin que vous réussissiez"
    },
    {
      "key": 5188,
      "id": "62-11",
      "translation": "Quand ils entrevoient quelque commerce ou quelque divertissement, ils s'y dispersent et te laissent debout. Dis: «Ce qui est auprès d'Allah est bien meilleur que le divertissement et le commerce, et Allah est le Meilleur des pourvoyeurs»"
    },
    {
      "key": 5189,
      "id": "63-1",
      "translation": "Quand les hypocrites viennent à toi, ils disent: «Nous attestons que tu es certes le Messager d'Allah» Allah sait que tu es vraiment Son messager; et Allah atteste que les hypocrites sont assurément des menteurs"
    },
    {
      "key": 5190,
      "id": "63-2",
      "translation": "Ils prennent leurs serments pour bouclier et obstruent le chemin d'Allah. Quelles mauvaises choses que ce qu'ils faisaient"
    },
    {
      "key": 5191,
      "id": "63-3",
      "translation": "C'est parce qu'en vérité ils ont cru, puis rejeté la foi. Leurs cœurs donc, ont été scellés, de sorte qu'ils ne comprennent rien"
    },
    {
      "key": 5192,
      "id": "63-4",
      "translation": "Et quand tu les vois, leurs corps t'émerveillent; et s'ils parlent tu écoutes leur parole. Ils sont comme des bûches appuyées (contre des murs) et ils pensent que chaque cri est dirigé contre eux. L'ennemi c'est eux. Prends y garde. Qu'Allah les extermine! Comme les voilà détournés (du droit chemin)"
    },
    {
      "key": 5193,
      "id": "63-5",
      "translation": "Et quand on leur dit: «Venez que le Messager d'Allah implore le pardon pour vous», ils détournent leurs têtes, et tu les vois se détourner tandis qu'ils s'enflent d'orgueil"
    },
    {
      "key": 5194,
      "id": "63-6",
      "translation": "C'est égal, pour eux, que tu implores le pardon pour eux ou que tu ne le fasses pas: Allah ne leur pardonnera jamais, car Allah ne guide pas les gens pervers"
    },
    {
      "key": 5195,
      "id": "63-7",
      "translation": "Ce sont eux qui disent: «Ne dépensez point pour ceux qui sont auprès du Messager d'Allah, afin qu'ils se dispersent». Et c'est à Allah qu'appartiennent les trésors des cieux et de la terre, mais les hypocrites ne comprennent pas"
    },
    {
      "key": 5196,
      "id": "63-8",
      "translation": "Ils disent: «Si nous retournons à Médine, le plus puissant en fera assurément sortir le plus humble». Or c'est à Allah qu'est la puissance ainsi qu'à Son messager et aux croyants. Mais les hypocrites ne le savent pas"
    },
    {
      "key": 5197,
      "id": "63-9",
      "translation": "O vous qui avez cru! Que ni vos biens ni vos enfants ne vous distraient du rappel d'Allah. Et quiconque fait cela... alors ceux-là seront les perdants"
    },
    {
      "key": 5198,
      "id": "63-10",
      "translation": "Et dépensez de ce que Nous vous avons octroyé avant que la mort ne vienne à l'un de vous et qu'il dise alors: «Seigneur! si seulement Tu m'accordais un court délai: je ferais l'aumône et serais parmi les gens de bien»"
    },
    {
      "key": 5199,
      "id": "63-11",
      "translation": "Allah cependant n'accorde jamais de délai à une âme dont le terme est arrivé. Et Allah est Parfaitement Connaisseur de ce que vous faites"
    },
    {
      "key": 5200,
      "id": "64-1",
      "translation": "Ce qui est dans les cieux et ce qui est sur la terre glorifient Allah. A Lui la royauté et à Lui les louanges. Et Il est Omnipotent"
    },
    {
      "key": 5201,
      "id": "64-2",
      "translation": "C'est Lui qui vous a créés. Parmi vous [il y a] mécréant et croyant. Allah observe parfaitement ce que vous faites"
    },
    {
      "key": 5202,
      "id": "64-3",
      "translation": "Il a créé les cieux et la terre en toute vérité et vous a donné votre forme et quelle belle forme Il vous a donnée! Et vers Lui est le devenir"
    },
    {
      "key": 5203,
      "id": "64-4",
      "translation": "Il sait ce qui est dans les cieux et la terre, et Il sait ce que vous cachez ainsi que ce que vous divulguez. Et Allah connaît bien le contenu des poitrines"
    },
    {
      "key": 5204,
      "id": "64-5",
      "translation": "Ne vous est-elle pas parvenue, la nouvelle de ceux qui auparavant ont mécru et qui ont goûté la conséquence néfaste de leur acte; ils auront en outre un châtiment douloureux"
    },
    {
      "key": 5205,
      "id": "64-6",
      "translation": "Il en est ainsi parce que leurs messagers leur venaient avec des preuves évidentes, et qu'ils ont dit: «Sont-ce des hommes qui nous guideront?» Ils mécrurent alors et se détournèrent et Allah Se passa [d'eux] et Allah Se suffit à Lui-même et Il est Digne de louange"
    },
    {
      "key": 5206,
      "id": "64-7",
      "translation": "Ceux qui ont mécru prétendent qu'ils ne seront point ressuscités. Dis: «Mais si! Par mon Seigneur! Vous serez très certainement ressuscités; puis vous serez certes informés de ce que vous faisiez. Et cela est facile pour Allah»"
    },
    {
      "key": 5207,
      "id": "64-8",
      "translation": "Croyez en Allah donc et en Son messager, ainsi qu'en la Lumière [le Coran] que Nous avons fait descendre. Et Allah est Parfaitement Connaisseur de ce que vous faites"
    },
    {
      "key": 5208,
      "id": "64-9",
      "translation": "Le jour où Il vous réunira pour le jour du Rassemblement, ce sera le jour de la grande perte. Et celui qui croit en Allah et accomplit les bonnes œuvres, Il lui effacera ses mauvaises actions et le fera entrer dans des Jardins sous lesquels coulent les ruisseaux où ils demeureront éternellement. Voilà l'énorme succès"
    },
    {
      "key": 5209,
      "id": "64-10",
      "translation": "Et ceux qui ont mécru et traité de mensonges Nos versets, ceux-là sont les gens du Feu où ils demeureront éternellement. Et quelle mauvaise destination"
    },
    {
      "key": 5210,
      "id": "64-11",
      "translation": "Nul malheur n'atteint [l'homme] que par la permission d'Allah. Et quiconque croit en Allah, [Allah] guide son cœur. Allah est Omniscient"
    },
    {
      "key": 5211,
      "id": "64-12",
      "translation": "Obéissez à Allah et obéissez au Messager et si vous vous détournez... il n'incombe à Notre messager que de transmettre en clair (son message)"
    },
    {
      "key": 5212,
      "id": "64-13",
      "translation": "Allah nulle autre divinité que Lui! Et c'est à Allah que les croyants [doivent] s'en remettre"
    },
    {
      "key": 5213,
      "id": "64-14",
      "translation": "O vous qui avez cru, vous avez de vos épouses et de vos enfants un ennemi [une tentation]. Prenez-y garde donc. Mais si vous [les] excusez, passez sur [leurs] fautes et [leur] pardonnez, sachez qu'Allah est Pardonneur, Très Miséricordieux"
    },
    {
      "key": 5214,
      "id": "64-15",
      "translation": "Vos biens et vos enfants ne sont qu'une tentation, alors qu'auprès d'Allah est une énorme récompense"
    },
    {
      "key": 5215,
      "id": "64-16",
      "translation": "Craignez Allah, donc autant que vous pouvez, écoutez, obéissez et faites largesses. Ce sera un bien pour vous. Et quiconque a été protégé contre sa propre avidité... ceux-là sont ceux qui réussissent"
    },
    {
      "key": 5216,
      "id": "64-17",
      "translation": "Si vous faites à Allah un prêt sincère, Il le multipliera pour vous et vous pardonnera. Allah cependant est très Reconnaissant et Indulgent"
    },
    {
      "key": 5217,
      "id": "64-18",
      "translation": "Il est le Connaisseur du monde Invisible et visible, et Il est le Puissant, le Sage"
    },
    {
      "key": 5218,
      "id": "65-1",
      "translation": "O Prophète! Quand vous répudiez les femmes, répudiez-les conformément à leur période d'attente prescrite; et comptez la période; et craignez Allah votre Seigneur. Ne les faites pas sortir de leurs maisons, et qu'elles n'en sortent pas, à moins qu'elles n'aient commis une turpitude prouvée. Telles sont les lois d'Allah. Quiconque cependant transgresse les lois d'Allah, se fait du tort à lui-même. Tu ne sais pas si d'ici là Allah ne suscitera pas quelque chose de nouveau"
    },
    {
      "key": 5219,
      "id": "65-2",
      "translation": "Puis quand elles atteignent le terme prescrit, retenez-les de façon convenable, ou séparez-vous d'elles de façon convenable; et prenez deux hommes intègres parmi vous comme témoins. Et acquittez-vous du témoignage envers Allah. Voilà ce à quoi est exhorté celui qui croit en Allah et au Jour dernier. Et quiconque craint Allah, Il Lui donnera une issue favorable"
    },
    {
      "key": 5220,
      "id": "65-3",
      "translation": "et lui accordera Ses dons par [des moyens] sur lesquels il ne comptait pas. Et quiconque place sa confiance en Allah, Il [Allah] lui suffit. Allah atteint ce qu'Il Se propose, et Allah a assigné une mesure à chaque chose"
    },
    {
      "key": 5221,
      "id": "65-4",
      "translation": "Si vous avez des doutes à propos (de la période d'attente) de vos femmes qui n'espèrent plus avoir de règles, leur délai est de trois mois. De même pour celles qui n'ont pas encore de règles. Et quant à celles qui sont enceintes, leur période d'attente se terminera à leur accouchement. Quiconque craint Allah cependant, Il lui facilite les choses"
    },
    {
      "key": 5222,
      "id": "65-5",
      "translation": "Tel est le commandement d'Allah qu'Il a fait descendre vers vous. Quiconque craint Allah cependant, Il lui efface ses fautes et lui accorde une grosse récompense"
    },
    {
      "key": 5223,
      "id": "65-6",
      "translation": "Et faites que ces femmes habitent où vous habitez, et suivant vos moyens. Et ne cherchez pas à leur nuire en les contraignant à vivre à l'étroit. Et si elles sont enceintes, pourvoyez à leurs besoins jusqu'à ce qu'elles aient accouché. Puis, si elles allaitent [l'enfant né] de vous, donnez-leur leurs salaires. Et concertez vous [à ce sujet] de façon convenable. Et si vous rencontrez des difficultés réciproques, alors, une autre allaitera pour lui"
    },
    {
      "key": 5224,
      "id": "65-7",
      "translation": "Que celui qui est aisé dépense de sa fortune; et que celui dont les biens sont restreints dépense selon ce qu'Allah lui a accordé. Allah n'impose à personne que selon ce qu'Il lui a donné, et Allah fera succéder l'aisance à la gêne"
    },
    {
      "key": 5225,
      "id": "65-8",
      "translation": "Que de cités ont refusé avec insolence le commandement de leur Seigneur et de Ses messagers! Nous leur en demandâmes compte avec sévérité, et les châtiâmes d'un châtiment inouï"
    },
    {
      "key": 5226,
      "id": "65-9",
      "translation": "Elles goûtèrent donc la conséquence de leur comportement. Et le résultat final de leurs actions fut [leur] perdition"
    },
    {
      "key": 5227,
      "id": "65-10",
      "translation": "Allah a préparé pour eux un dur châtiment. Craignez Allah donc, ô vous qui êtes doués d'intelligence, vous qui avez la foi. Certes, Allah a fait descendre vers vous un rappel"
    },
    {
      "key": 5228,
      "id": "65-11",
      "translation": "un Messager qui vous récite les versets d'Allah comme preuves claires, afin de faire sortir ceux qui croient et accomplissent les bonnes œuvres des ténèbres à la lumière. Et quiconque croit en Allah et fait le bien, Il le fait entrer aux Jardins sous lesquels coulent les ruisseaux, pour y demeurer éternellement. Allah lui a fait une belle attribution"
    },
    {
      "key": 5229,
      "id": "65-12",
      "translation": "Allah qui a créé sept cieux et autant de terres. Entre eux [Son] commandement descend, afin que vous sachiez qu'Allah est en vérité Omnipotent et qu'Allah a embrassé toute chose de [Son] savoir"
    },
    {
      "key": 5230,
      "id": "66-1",
      "translation": "O Prophète! Pourquoi, en recherchant l'agrément de tes femmes, t'interdis-tu ce qu'Allah t'a rendu licite? Et Allah est Pardonneur, Très Miséricordieux"
    },
    {
      "key": 5231,
      "id": "66-2",
      "translation": "Allah vous a prescrit certes, de vous libérer de vos serments. Allah est votre Maître; et c'est Lui l'Omniscient, le Sage"
    },
    {
      "key": 5232,
      "id": "66-3",
      "translation": "Lorsque le Prophète confia un secret à l'une de ses épouses et qu'elle l'eut divulgué et qu'Allah l'en eut informé, celui-ci en fit connaître une partie et passa sur une partie. Puis, quand il l'en eut informée elle dit: «Qui t'en a donné nouvelle?» Il dit: «C'est l'Omniscient, le Parfaitement Connaisseur qui m'en a avisé»"
    },
    {
      "key": 5233,
      "id": "66-4",
      "translation": "Si vous vous repentez à Allah c'est que vos cœurs ont fléchi. Mais si vous vous soutenez l'une l'autre contre le Prophète, alors ses alliés seront Allah, Gabriel et les vertueux d'entre les croyants, et les Anges sont par surcroît [son] soutien"
    },
    {
      "key": 5234,
      "id": "66-5",
      "translation": "S'Il vous répudie, il se peut que son Seigneur lui donne en échange des épouses meilleures que vous, musulmanes, croyantes, obéissantes, repentantes, adoratrices, jeûneuses, déjà mariées ou vierges"
    },
    {
      "key": 5235,
      "id": "66-6",
      "translation": "O vous qui avez cru! Préservez vos personnes et vos familles, d'un Feu dont le combustible sera les gens et les pierres, surveillé par des Anges rudes, durs, ne désobéissant jamais à Allah en ce qu'Il leur commande, et faisant strictement ce qu'on leur ordonne"
    },
    {
      "key": 5236,
      "id": "66-7",
      "translation": "O vous qui avez mécru! Ne vous excusez pas aujourd'hui. Vous ne serez rétribués que selon ce que vous œuvriez"
    },
    {
      "key": 5237,
      "id": "66-8",
      "translation": "O vous qui avez cru! Repentez-vous à Allah d'un repentir sincère. Il se peut que votre Seigneur vous efface vos fautes et qu'Il vous fasse entrer dans des Jardins sous lesquels coulent les ruisseaux, le jour où Allah épargnera l'ignominie au Prophète et à ceux qui croient avec lui. Leur lumière courra devant eux et à leur droite; ils diront: «Seigneur, parfais-nous notre lumière et pardonne-nous. Car Tu es Omnipotent»"
    },
    {
      "key": 5238,
      "id": "66-9",
      "translation": "O Prophète! Mène la lutte contre les mécréants et les hypocrites et sois rude à leur égard. Leur refuge sera l'Enfer, et quelle mauvaise destination"
    },
    {
      "key": 5239,
      "id": "66-10",
      "translation": "Allah a cité en parabole pour ceux qui ont mécru la femme de Noé et la femme de Lot. Elles étaient sous l'autorité de deux vertueux de Nos serviteurs. Toutes deux les trahirent et ils ne furent d'aucune aide pour [ces deux femmes] vis-à-vis d'Allah. Et il [leur] fut dit: «Entrez au Feu toutes les deux, avec ceux qui y entrent»"
    },
    {
      "key": 5240,
      "id": "66-11",
      "translation": "et Allah a cité en parabole pour ceux qui croient, la femme de Pharaon, quand elle dit «Seigneur, construis-moi auprès de Toi une maison dans le Paradis, et sauve-moi de Pharaon et de son œuvre; et sauve-moi des gens injustes»"
    },
    {
      "key": 5241,
      "id": "66-12",
      "translation": "De même, Marie, la fille d''Imran qui avait préservé sa virginité; Nous y insufflâmes alors de Notre Esprit. Elle avait déclaré véridiques les paroles de son Seigneur ainsi que Ses Livres: elle fut parmi les dévoués"
    },
    {
      "key": 5242,
      "id": "67-1",
      "translation": "Béni soit celui dans la main de qui est la royauté, et Il est Omnipotent"
    },
    {
      "key": 5243,
      "id": "67-2",
      "translation": "Celui qui a créé la mort et la vie afin de vous éprouver (et de savoir) qui de vous est le meilleur en œuvre, et c'est Lui le Puissant, le Pardonneur"
    },
    {
      "key": 5244,
      "id": "67-3",
      "translation": "Celui qui a créé sept cieux superposés sans que tu voies de disproportion en la création du Tout Miséricordieux. Ramène [sur elle] le regard. Y vois-tu une brèche quelconque"
    },
    {
      "key": 5245,
      "id": "67-4",
      "translation": "Puis, retourne ton regard à deux fois: le regard te reviendra humilié et frustré"
    },
    {
      "key": 5246,
      "id": "67-5",
      "translation": "Nous avons effectivement embelli le ciel le plus proche avec des lampes [des étoiles] dont Nous avons fait des projectiles pour lapider les diables et Nous leur avons préparé le châtiment de la Fournaise"
    },
    {
      "key": 5247,
      "id": "67-6",
      "translation": "Ceux qui ont mécru à leur Seigneur auront le châtiment de l'Enfer. Et quelle mauvaise destination"
    },
    {
      "key": 5248,
      "id": "67-7",
      "translation": "Quand ils y seront jetés, ils lui entendront un gémissement, tandis qu'il bouillonne"
    },
    {
      "key": 5249,
      "id": "67-8",
      "translation": "Peu s'en faut que, de rage, il n'éclate. Toutes les fois qu'un groupe y est jeté, ses gardiens leur demandent: «Quoi! ne vous est-il pas venu d'avertisseur?»"
    },
    {
      "key": 5250,
      "id": "67-9",
      "translation": "Ils dirent: «Mais si! un avertisseur nous était venu certes, mais nous avons crié au mensonge et avons dit: Allah n'a rien fait descendre: vous n'êtes que dans un grand égarement»"
    },
    {
      "key": 5251,
      "id": "67-10",
      "translation": "Et ils dirent: «Si nous avions écouté ou raisonné, nous ne serions pas parmi les gens de la Fournaise»"
    },
    {
      "key": 5252,
      "id": "67-11",
      "translation": "Ils ont reconnu leur péché. Que les gens de la Fournaise soient anéantis à jamais"
    },
    {
      "key": 5253,
      "id": "67-12",
      "translation": "Ceux qui redoutent leur Seigneur bien qu'ils ne L'aient jamais vu auront un pardon et une grande récompense"
    },
    {
      "key": 5254,
      "id": "67-13",
      "translation": "Que vous cachiez votre parole ou la divulguiez Il connaît bien le contenu des poitrines"
    },
    {
      "key": 5255,
      "id": "67-14",
      "translation": "Ne connaît-Il pas ce qu'Il a créé alors que c'est Lui le Compatissant, le Parfaitement Connaisseur"
    },
    {
      "key": 5256,
      "id": "67-15",
      "translation": "C'est Lui qui vous a soumis la terre: parcourez donc ses grandes étendues. Mangez de ce qu'Il vous fournit. Vers Lui est la Résurrection"
    },
    {
      "key": 5257,
      "id": "67-16",
      "translation": "Etes-vous à l'abri que Celui qui est au ciel vous enfouisse en la terre? Et voici qu'elle tremble"
    },
    {
      "key": 5258,
      "id": "67-17",
      "translation": "Ou êtes-vous à l'abri que Celui qui est au ciel envoie contre vous un ouragan de pierres? Vous saurez ainsi quel fut Mon avertissement"
    },
    {
      "key": 5259,
      "id": "67-18",
      "translation": "En effet, ceux d'avant eux avaient crié au mensonge. Quelle fut alors Ma réprobation"
    },
    {
      "key": 5260,
      "id": "67-19",
      "translation": "N'ont-ils pas vu les oiseaux au-dessus d'eux, déployant et repliant leurs ailes tour à tour? Seul le Tout Miséricordieux les soutient. Car Il est sur toute chose, Clairvoyant"
    },
    {
      "key": 5261,
      "id": "67-20",
      "translation": "Quel est celui qui constituerait pour vous une armée [capable] de vous secourir, en dehors du Tout Miséricordieux? En vérité les mécréants sont dans l'illusion complète"
    },
    {
      "key": 5262,
      "id": "67-21",
      "translation": "Ou quel est celui qui vous donnera votre subsistance s'Il s'arrête de fournir Son attribution? Mais ils persistent dans leur insolence et dans leur répulsion"
    },
    {
      "key": 5263,
      "id": "67-22",
      "translation": "Qui est donc mieux guidé? Celui qui marche face contre terre ou celui qui marche redressé sur un chemin droit"
    },
    {
      "key": 5264,
      "id": "67-23",
      "translation": "Dis: «C'est Lui qui vous a créés et vous a donné l'ouïe, les yeux et les cœurs». Mais vous êtes rarement reconnaissants"
    },
    {
      "key": 5265,
      "id": "67-24",
      "translation": "Dis: «C'est Lui qui vous a répandus sur la terre, et c'est vers Lui que vous serez rassemblés»"
    },
    {
      "key": 5266,
      "id": "67-25",
      "translation": "Et ils disent: «A quand cette promesse si vous êtes véridiques?»"
    },
    {
      "key": 5267,
      "id": "67-26",
      "translation": "Dis: «Allah seul [en] a la connaissance. Et moi je ne suis qu'un avertisseur clair»"
    },
    {
      "key": 5268,
      "id": "67-27",
      "translation": "Puis, quand ils verront (le châtiment) de près, les visages de ceux qui ont mécru seront affligés. Et il leur sera dit: «Voilà ce que vous réclamiez»"
    },
    {
      "key": 5269,
      "id": "67-28",
      "translation": "Dis: «Que vous en semble? Qu'Allah me fasse périr ainsi que ceux qui sont avec moi ou qu'Il nous fasse miséricorde, qui protégera alors les mécréants d'un châtiment douloureux?»"
    },
    {
      "key": 5270,
      "id": "67-29",
      "translation": "Dis: «C'est Lui, le Tout Miséricordieux. Nous croyons en Lui et c'est en Lui que nous plaçons notre confiance. Vous saurez bientôt qui est dans un égarement évident»"
    },
    {
      "key": 5271,
      "id": "67-30",
      "translation": "Dis: «Que vous en semble? Si votre eau était absorbée au plus profond de la terre, qui donc vous apporterait de l'eau de source?»"
    },
    {
      "key": 5272,
      "id": "68-1",
      "translation": "Nûn. Par la plume et ce qu'ils écrivent"
    },
    {
      "key": 5273,
      "id": "68-2",
      "translation": "Tu  n'es pas, par la grâce de ton Seigneur, un possédé"
    },
    {
      "key": 5274,
      "id": "68-3",
      "translation": "Et il y aura pour toi certes, une récompense jamais interrompue"
    },
    {
      "key": 5275,
      "id": "68-4",
      "translation": "Et tu es certes, d'une moralité éminente"
    },
    {
      "key": 5276,
      "id": "68-5",
      "translation": "Tu verras et ils verront"
    },
    {
      "key": 5277,
      "id": "68-6",
      "translation": "qui d'entre vous a perdu la raison"
    },
    {
      "key": 5278,
      "id": "68-7",
      "translation": "C'est ton Seigneur qui connaît mieux ceux qui s'égarent de Son chemin, et Il connaît mieux ceux qui suivent la bonne voie"
    },
    {
      "key": 5279,
      "id": "68-8",
      "translation": "N'obéis pas à ceux qui crient au mensonge"
    },
    {
      "key": 5280,
      "id": "68-9",
      "translation": "Ils aimeraient bien que tu transiges avec eux afin qu'ils transigent avec toi"
    },
    {
      "key": 5281,
      "id": "68-10",
      "translation": "Et n'obéis à aucun grand jureur, méprisable"
    },
    {
      "key": 5282,
      "id": "68-11",
      "translation": "grand diffamateur, grand colporteur de médisance"
    },
    {
      "key": 5283,
      "id": "68-12",
      "translation": "grand empêcheur du bien, transgresseur, grand pécheur"
    },
    {
      "key": 5284,
      "id": "68-13",
      "translation": "au cœur dur, et en plus de cela bâtard"
    },
    {
      "key": 5285,
      "id": "68-14",
      "translation": "Même s'il est doté de richesses et (de nombreux) enfants"
    },
    {
      "key": 5286,
      "id": "68-15",
      "translation": "Quand Nos versets lui sont récités, il dit: «Des contes d'anciens»"
    },
    {
      "key": 5287,
      "id": "68-16",
      "translation": "Nous le marquerons sur le museau [nez]"
    },
    {
      "key": 5288,
      "id": "68-17",
      "translation": "Nous les avons éprouvés comme Nous avons éprouvé les propriétaires du verger qui avaient juré d'en faire la récolte au matin"
    },
    {
      "key": 5289,
      "id": "68-18",
      "translation": "sans dire: «Si Allah le veut»"
    },
    {
      "key": 5290,
      "id": "68-19",
      "translation": "Une calamité de la part de ton Seigneur tomba dessus pendant qu'ils dormaient"
    },
    {
      "key": 5291,
      "id": "68-20",
      "translation": "et le matin, ce fut comme si tout avait été rasé"
    },
    {
      "key": 5292,
      "id": "68-21",
      "translation": "Le [lendemain] matin, ils s'appelèrent les uns les autres"
    },
    {
      "key": 5293,
      "id": "68-22",
      "translation": "«Partez tôt à votre champ si vous voulez le récolter»"
    },
    {
      "key": 5294,
      "id": "68-23",
      "translation": "Ils allèrent donc, tout en parlant entre eux à voix basse"
    },
    {
      "key": 5295,
      "id": "68-24",
      "translation": "«Ne laissez aucun pauvre y entrer aujourd'hui»"
    },
    {
      "key": 5296,
      "id": "68-25",
      "translation": "Ils partirent de bonne heure décidés à user d'avarice [envers les pauvres], convaincus que cela était en leur pouvoir"
    },
    {
      "key": 5297,
      "id": "68-26",
      "translation": "Puis, quand ils le virent [le jardin], ils dirent: «vraiment, nous avons perdu notre chemin"
    },
    {
      "key": 5298,
      "id": "68-27",
      "translation": "Ou plutôt nous sommes frustrés»"
    },
    {
      "key": 5299,
      "id": "68-28",
      "translation": "Le plus juste d'entre eux dit: «Ne vous avais-je pas dit: Si seulement vous avez rendu gloire à Allah!»"
    },
    {
      "key": 5300,
      "id": "68-29",
      "translation": "Ils dirent: «Gloire à notre Seigneur! Oui, nous avons été des injustes»"
    },
    {
      "key": 5301,
      "id": "68-30",
      "translation": "Puis ils s'adressèrent les uns aux autres, se faisant des reproches"
    },
    {
      "key": 5302,
      "id": "68-31",
      "translation": "Ils dirent: «Malheur à nous! Nous avons été des rebelles"
    },
    {
      "key": 5303,
      "id": "68-32",
      "translation": "Nous souhaitons que notre Seigneur nous le remplace par quelque chose de meilleur. Nous désirons nous rapprocher de notre Seigneur»"
    },
    {
      "key": 5304,
      "id": "68-33",
      "translation": "Tel fut le châtiment; et le châtiment de l'au-delà est plus grand encore, si seulement ils savaient"
    },
    {
      "key": 5305,
      "id": "68-34",
      "translation": "Les pieux auront auprès de leur Seigneur les Jardins du délice"
    },
    {
      "key": 5306,
      "id": "68-35",
      "translation": "Traiterons-Nous les soumis [à Allah] à la manière des criminels"
    },
    {
      "key": 5307,
      "id": "68-36",
      "translation": "Qu'avez-vous? Comment jugez-vous"
    },
    {
      "key": 5308,
      "id": "68-37",
      "translation": "Ou bien avez-vous un Livre dans lequel vous apprenez"
    },
    {
      "key": 5309,
      "id": "68-38",
      "translation": "qu'en vérité vous obtiendrez tout ce que vous désirez"
    },
    {
      "key": 5310,
      "id": "68-39",
      "translation": "Ou bien est-ce que vous avez obtenu de Nous des serments valables jusqu'au Jour de la Résurrection, Nous engageant à vous donner ce que vous décidez"
    },
    {
      "key": 5311,
      "id": "68-40",
      "translation": "Demande-leur qui d'entre eux en est garant"
    },
    {
      "key": 5312,
      "id": "68-41",
      "translation": "Ou encore, est-ce qu'ils ont des associés? Eh bien, qu'ils fassent venir leurs associés s'ils sont véridiques"
    },
    {
      "key": 5313,
      "id": "68-42",
      "translation": "Le jour où ils affronteront les horreurs [du Jugement] et où ils seront appelés à la Prosternation mais ils ne le pourront pas"
    },
    {
      "key": 5314,
      "id": "68-43",
      "translation": "Leurs regards seront abaissés, et l'avilissement les couvrira. Or, ils étaient appelés à la Prosternation au temps où ils étaient sains et saufs"
    },
    {
      "key": 5315,
      "id": "68-44",
      "translation": "Laisse-Moi donc avec quiconque traite de mensonge ce discours; Nous allons les mener graduellement par où ils ne savent pas"
    },
    {
      "key": 5316,
      "id": "68-45",
      "translation": "Et Je leur accorde un délai, car Mon stratagème est sûr"
    },
    {
      "key": 5317,
      "id": "68-46",
      "translation": "Ou bien est-ce que tu leur demandes un salaire, les accablant ainsi d'une lourde dette"
    },
    {
      "key": 5318,
      "id": "68-47",
      "translation": "Ou savent-ils l'Inconnaissable et c'est de là qu'ils écrivent [leurs mensonges]"
    },
    {
      "key": 5319,
      "id": "68-48",
      "translation": "Endure avec patience la sentence de ton Seigneur, et ne sois pas comme l'homme au Poisson [Jonas] qui appela (Allah) dans sa grande angoisse"
    },
    {
      "key": 5320,
      "id": "68-49",
      "translation": "Si un bienfait de son Seigneur ne l'avait pas atteint, il aurait été rejeté honni sur une terre déserte"
    },
    {
      "key": 5321,
      "id": "68-50",
      "translation": "Puis son Seigneur l'élut et le désigna au nombre des gens de bien"
    },
    {
      "key": 5322,
      "id": "68-51",
      "translation": "Peu s'en faut que ceux qui mécroient ne te transpercent par leurs regards, quand ils entendent le Coran, ils disent: «Il est certes fou!»"
    },
    {
      "key": 5323,
      "id": "68-52",
      "translation": "Et ce n'est qu'un Rappel, adressé aux mondes"
    },
    {
      "key": 5324,
      "id": "69-1",
      "translation": "L'inévitable [l'Heure qui montre la vérité]"
    },
    {
      "key": 5325,
      "id": "69-2",
      "translation": "Qu'est-ce que l'inévitable"
    },
    {
      "key": 5326,
      "id": "69-3",
      "translation": "Et qui te dira ce que c'est que l'inévitable"
    },
    {
      "key": 5327,
      "id": "69-4",
      "translation": "Les Thamûd et les 'Aad avaient traité de mensonge le cataclysme"
    },
    {
      "key": 5328,
      "id": "69-5",
      "translation": "Quant aux Thamûd, ils furent détruits par le [bruit] excessivement fort"
    },
    {
      "key": 5329,
      "id": "69-6",
      "translation": "Et quant aux 'Aad, ils furent détruits par un vent mugissant et furieux"
    },
    {
      "key": 5330,
      "id": "69-7",
      "translation": "qu'[Allah] déchaîna contre eux pendant sept nuits et huit jours consécutifs; tu voyais alors les gens renversés par terre comme des souches de palmiers évidées"
    },
    {
      "key": 5331,
      "id": "69-8",
      "translation": "En vois-tu le moindre vestige"
    },
    {
      "key": 5332,
      "id": "69-9",
      "translation": "Pharaon et ceux qui vécurent avant lui ainsi que les Villes renversées, commirent des fautes"
    },
    {
      "key": 5333,
      "id": "69-10",
      "translation": "Ils désobéirent au Messager de leur Seigneur. Celui-ci donc, les saisit d'une façon irrésistible"
    },
    {
      "key": 5334,
      "id": "69-11",
      "translation": "C'est Nous qui, quand l'eau déborda, vous avons chargés sur l'Arche"
    },
    {
      "key": 5335,
      "id": "69-12",
      "translation": "afin d'en faire pour vous un rappel que toute oreille fidèle conserve"
    },
    {
      "key": 5336,
      "id": "69-13",
      "translation": "Puis, quand d'un seul souffle, on soufflera dans la Trompe"
    },
    {
      "key": 5337,
      "id": "69-14",
      "translation": "et que la terre et les montagnes seront soulevées puis tassées d'un seul coup"
    },
    {
      "key": 5338,
      "id": "69-15",
      "translation": "ce jour-là alors, l'Evénement se produira"
    },
    {
      "key": 5339,
      "id": "69-16",
      "translation": "et le ciel se fendra et sera fragile, ce jour-là"
    },
    {
      "key": 5340,
      "id": "69-17",
      "translation": "Et sur ses côtés [se tiendront] les Anges, tandis que huit, ce jour-là, porteront au-dessus d'eux le Trône de ton Seigneur"
    },
    {
      "key": 5341,
      "id": "69-18",
      "translation": "Ce jour-là vous serez exposés; et rien de vous ne sera caché"
    },
    {
      "key": 5342,
      "id": "69-19",
      "translation": "Quant à celui à qui on aura remis le Livre en sa main droite, il dira: «Tenez! lisez mon livre"
    },
    {
      "key": 5343,
      "id": "69-20",
      "translation": "J'étais sûr d'y trouver mon compte»"
    },
    {
      "key": 5344,
      "id": "69-21",
      "translation": "Il jouira d'une vie agréable"
    },
    {
      "key": 5345,
      "id": "69-22",
      "translation": "dans un Jardin haut placé"
    },
    {
      "key": 5346,
      "id": "69-23",
      "translation": "dont les fruits sont à portée de la main"
    },
    {
      "key": 5347,
      "id": "69-24",
      "translation": "«Mangez et buvez agréablement pour ce que vous avez avancé dans les jours passés»"
    },
    {
      "key": 5348,
      "id": "69-25",
      "translation": "Quant à celui à qui on aura remis le Livre en sa main gauche, il dira: «Hélas pour moi! J'aurai souhaité qu'on ne m'ait pas remis mon livre"
    },
    {
      "key": 5349,
      "id": "69-26",
      "translation": "et ne pas avoir connu mon compte"
    },
    {
      "key": 5350,
      "id": "69-27",
      "translation": "Hélas, comme j'aurai souhaité que [ma première mort] fût la définitive"
    },
    {
      "key": 5351,
      "id": "69-28",
      "translation": "Ma fortune ne m'a servi à rien"
    },
    {
      "key": 5352,
      "id": "69-29",
      "translation": "Mon autorité est anéantie et m'a quitté!»"
    },
    {
      "key": 5353,
      "id": "69-30",
      "translation": "«Saisissez-le! Puis, mettez-lui un carcan"
    },
    {
      "key": 5354,
      "id": "69-31",
      "translation": "ensuite, brûlez-le dans la Fournaise"
    },
    {
      "key": 5355,
      "id": "69-32",
      "translation": "puis, liez-le avec une chaîne de soixante-dix coudées"
    },
    {
      "key": 5356,
      "id": "69-33",
      "translation": "car il ne croyait pas en Allah, le Très Grand"
    },
    {
      "key": 5357,
      "id": "69-34",
      "translation": "et n'incitait pas à nourrir le pauvre"
    },
    {
      "key": 5358,
      "id": "69-35",
      "translation": "Il n'a pour lui ici, aujourd'hui, point d'ami chaleureux [pour le protéger]"
    },
    {
      "key": 5359,
      "id": "69-36",
      "translation": "ni d'autre nourriture que du pus"
    },
    {
      "key": 5360,
      "id": "69-37",
      "translation": "que seuls les fautifs mangeront»"
    },
    {
      "key": 5361,
      "id": "69-38",
      "translation": "Mais non... Je jure par ce que vous voyez"
    },
    {
      "key": 5362,
      "id": "69-39",
      "translation": "ainsi que par ce que vous ne voyez pas"
    },
    {
      "key": 5363,
      "id": "69-40",
      "translation": "que ceci [le Coran] est la parole d'un noble Messager"
    },
    {
      "key": 5364,
      "id": "69-41",
      "translation": "et que ce n'est pas la parole d'un poète; mais vous ne croyez que très peu"
    },
    {
      "key": 5365,
      "id": "69-42",
      "translation": "ni la parole d'un devin, mais vous vous rappelez bien peu"
    },
    {
      "key": 5366,
      "id": "69-43",
      "translation": "C'est une révélation du Seigneur de l'Univers"
    },
    {
      "key": 5367,
      "id": "69-44",
      "translation": "Et s'il avait forgé quelques paroles qu'ils Nous avait attribuées"
    },
    {
      "key": 5368,
      "id": "69-45",
      "translation": "Nous l'aurions saisi de la main droite"
    },
    {
      "key": 5369,
      "id": "69-46",
      "translation": "ensuite, Nous lui aurions tranché l'aorte"
    },
    {
      "key": 5370,
      "id": "69-47",
      "translation": "Et nul d'entre vous n'aurait pu lui servir de rempart"
    },
    {
      "key": 5371,
      "id": "69-48",
      "translation": "C'est en vérité un rappel pour les pieux"
    },
    {
      "key": 5372,
      "id": "69-49",
      "translation": "Et Nous savons qu'il y a parmi vous qui le traitent de menteur"
    },
    {
      "key": 5373,
      "id": "69-50",
      "translation": "mais en vérité, ce sera un sujet de regret pour les mécréants"
    },
    {
      "key": 5374,
      "id": "69-51",
      "translation": "c'est là la véritable certitude"
    },
    {
      "key": 5375,
      "id": "69-52",
      "translation": "Glorifie donc le nom de ton Seigneur, le Très Grand"
    },
    {
      "key": 5376,
      "id": "70-1",
      "translation": "Un demandeur a réclamé un châtiment inéluctable"
    },
    {
      "key": 5377,
      "id": "70-2",
      "translation": "pour les mécréants, que nul ne pourrait repousser"
    },
    {
      "key": 5378,
      "id": "70-3",
      "translation": "et qui vient d'Allah, le Maître des voies d'ascension"
    },
    {
      "key": 5379,
      "id": "70-4",
      "translation": "Les Anges ainsi que l'Esprit montent vers Lui en un jour dont la durée est de cinquante mille ans"
    },
    {
      "key": 5380,
      "id": "70-5",
      "translation": "Supporte donc, d'une belle patience"
    },
    {
      "key": 5381,
      "id": "70-6",
      "translation": "Ils le (le châtiment) voient bien loin"
    },
    {
      "key": 5382,
      "id": "70-7",
      "translation": "alors que Nous le voyons bien proche"
    },
    {
      "key": 5383,
      "id": "70-8",
      "translation": "le jour où le ciel sera comme du métal en fusion"
    },
    {
      "key": 5384,
      "id": "70-9",
      "translation": "et les montagnes comme de la laine"
    },
    {
      "key": 5385,
      "id": "70-10",
      "translation": "où nul ami dévoué ne s'enquerra d'un ami"
    },
    {
      "key": 5386,
      "id": "70-11",
      "translation": "bien qu'ils se voient l'un l'autre. Le criminel aimerait pouvoir se racheter du châtiment de ce jour, en livrant ses enfants"
    },
    {
      "key": 5387,
      "id": "70-12",
      "translation": "sa compagne, son frère"
    },
    {
      "key": 5388,
      "id": "70-13",
      "translation": "même son clan qui lui donnait asile"
    },
    {
      "key": 5389,
      "id": "70-14",
      "translation": "et tout ce qui est sur la terre, tout, qui pourrait le sauver"
    },
    {
      "key": 5390,
      "id": "70-15",
      "translation": "Mais rien [ne le sauvera]. [L'Enfer] est un brasier"
    },
    {
      "key": 5391,
      "id": "70-16",
      "translation": "arrachant brutalement la peau du crâne"
    },
    {
      "key": 5392,
      "id": "70-17",
      "translation": "Il appellera celui qui tournait le dos et s'en allait"
    },
    {
      "key": 5393,
      "id": "70-18",
      "translation": "amassait et thésaurisait"
    },
    {
      "key": 5394,
      "id": "70-19",
      "translation": "Oui, l'homme a été créé instable [très inquiet]"
    },
    {
      "key": 5395,
      "id": "70-20",
      "translation": "quand le malheur le touche, il est abattu"
    },
    {
      "key": 5396,
      "id": "70-21",
      "translation": "et quand le bonheur le touche, il est grand refuseur"
    },
    {
      "key": 5397,
      "id": "70-22",
      "translation": "Sauf ceux qui pratiquent la Salât"
    },
    {
      "key": 5398,
      "id": "70-23",
      "translation": "qui sont assidus à leurs Salâts"
    },
    {
      "key": 5399,
      "id": "70-24",
      "translation": "et sur les biens desquels il y a un droit bien déterminé [la Zakât]"
    },
    {
      "key": 5400,
      "id": "70-25",
      "translation": "pour le mendiant et le déshérité"
    },
    {
      "key": 5401,
      "id": "70-26",
      "translation": "et qui déclarent véridique le Jour de la Rétribution"
    },
    {
      "key": 5402,
      "id": "70-27",
      "translation": "et ceux qui craignent le châtiment de leur Seigneur"
    },
    {
      "key": 5403,
      "id": "70-28",
      "translation": "car vraiment, il n'y a nulle assurance contre le châtiment de leur Seigneur"
    },
    {
      "key": 5404,
      "id": "70-29",
      "translation": "et qui se maintiennent dans la chasteté"
    },
    {
      "key": 5405,
      "id": "70-30",
      "translation": "et n'ont de rapports qu'avec leurs épouses ou les esclaves qu'ils possèdent car dans ce cas, ils ne sont pas blâmables"
    },
    {
      "key": 5406,
      "id": "70-31",
      "translation": "mais ceux qui cherchent [leur plaisir] en dehors de cela, sont des transgresseurs"
    },
    {
      "key": 5407,
      "id": "70-32",
      "translation": "et qui gardent les dépôts confiés à eux, et respectent leurs engagements scrupuleusement"
    },
    {
      "key": 5408,
      "id": "70-33",
      "translation": "et qui témoignent de la stricte vérité"
    },
    {
      "key": 5409,
      "id": "70-34",
      "translation": "et qui sont réguliers dans leur Salât"
    },
    {
      "key": 5410,
      "id": "70-35",
      "translation": "Ceux-là seront honorés dans des Jardins"
    },
    {
      "key": 5411,
      "id": "70-36",
      "translation": "Qu'ont donc, ceux qui ont mécru, à courir vers toi, le cou tendu"
    },
    {
      "key": 5412,
      "id": "70-37",
      "translation": "de droite et de gauche, [venant] par bandes"
    },
    {
      "key": 5413,
      "id": "70-38",
      "translation": "Chacun d'eux convoite-t-il qu'on le laisse entrer au Jardin des délices"
    },
    {
      "key": 5414,
      "id": "70-39",
      "translation": "Mais non! Nous les avons créés de ce qu'ils savent"
    },
    {
      "key": 5415,
      "id": "70-40",
      "translation": "Eh Non!... Je jure par le Seigneur des Levants et des Couchants que Nous sommes Capable"
    },
    {
      "key": 5416,
      "id": "70-41",
      "translation": "de les remplacer par de meilleurs qu'eux, et nul ne peut nous en empêcher"
    },
    {
      "key": 5417,
      "id": "70-42",
      "translation": "Laisse-les donc s'enfoncer (dans leur mécréance) et se divertir jusqu'à ce qu'ils rencontrent leur jour dont on les menaçait"
    },
    {
      "key": 5418,
      "id": "70-43",
      "translation": "le jour où ils sortiront des tombes, rapides comme s'ils couraient vers des pierres dressées"
    },
    {
      "key": 5419,
      "id": "70-44",
      "translation": "leurs yeux seront abaissés, l'avilissement les couvrira. C'est cela le jour dont on les menaçait"
    },
    {
      "key": 5420,
      "id": "71-1",
      "translation": "Nous avons envoyé Noé vers son peuple: «Avertis ton peuple, avant que leur vienne un châtiment douloureux»"
    },
    {
      "key": 5421,
      "id": "71-2",
      "translation": "Il [leur] dit: «O mon peuple, je suis vraiment pour vous, un avertisseur clair"
    },
    {
      "key": 5422,
      "id": "71-3",
      "translation": "Adorez Allah, craignez-Le et obéissez-moi"
    },
    {
      "key": 5423,
      "id": "71-4",
      "translation": "pour qu'Il vous pardonne vos péchés et qu'Il vous donne un délai jusqu'à un terme fixé. Mais quand vient le terme fixé par Allah, il ne saurait être différé si vous saviez!»"
    },
    {
      "key": 5424,
      "id": "71-5",
      "translation": "Il dit: «Seigneur! J'ai appelé mon peuple, nuit et jour"
    },
    {
      "key": 5425,
      "id": "71-6",
      "translation": "Mais mon appel n'a fait qu'accroître leur fuite"
    },
    {
      "key": 5426,
      "id": "71-7",
      "translation": "Et chaque fois que je les ai appelés pour que Tu leur pardonnes, ils ont mis leurs doigts dans leurs oreilles. se sont enveloppés de leurs vêtements, se sont entêtés et se sont montrés extrêmement orgueilleux"
    },
    {
      "key": 5427,
      "id": "71-8",
      "translation": "Ensuite, je les ai appelés ouvertement"
    },
    {
      "key": 5428,
      "id": "71-9",
      "translation": "Puis, je leur ai fait des proclamations publiques, et des confidences en secret"
    },
    {
      "key": 5429,
      "id": "71-10",
      "translation": "J'ai donc dit: «Implorez le pardon de votre Seigneur, car Il est grand Pardonneur"
    },
    {
      "key": 5430,
      "id": "71-11",
      "translation": "pour qu'Il vous envoie du ciel, des pluies abondantes"
    },
    {
      "key": 5431,
      "id": "71-12",
      "translation": "et qu'Il vous accorde beaucoup de biens et d'enfants, et vous donne des jardins et vous donne des rivières"
    },
    {
      "key": 5432,
      "id": "71-13",
      "translation": "Qu'avez-vous à ne pas vénérer Allah comme il se doit"
    },
    {
      "key": 5433,
      "id": "71-14",
      "translation": "alors qu'Il vous a créés par phases successives"
    },
    {
      "key": 5434,
      "id": "71-15",
      "translation": "N'avez-vous pas vu comment Allah a créé sept cieux superposés"
    },
    {
      "key": 5435,
      "id": "71-16",
      "translation": "et y a fait de la lune une lumière et du soleil une lampe"
    },
    {
      "key": 5436,
      "id": "71-17",
      "translation": "Et c'est Allah qui, de la terre, vous a fait croître comme des plantes"
    },
    {
      "key": 5437,
      "id": "71-18",
      "translation": "puis Il vous y fera retourner et vous en fera sortir véritablement"
    },
    {
      "key": 5438,
      "id": "71-19",
      "translation": "Et c'est Allah qui vous a fait de la terre un tapis"
    },
    {
      "key": 5439,
      "id": "71-20",
      "translation": "pour que vous vous acheminiez par ses voies spacieuses»"
    },
    {
      "key": 5440,
      "id": "71-21",
      "translation": "Noé dit: «Seigneur, ils m'ont désobéi et ils ont suivi celui dont les biens et les enfants n'ont fait qu'accroître la perte"
    },
    {
      "key": 5441,
      "id": "71-22",
      "translation": "Ils ont ourdi un immense stratagème"
    },
    {
      "key": 5442,
      "id": "71-23",
      "translation": "et ils ont dit: «N'abandonnez jamais vos divinités et n'abandonnez jamais Wadd, Suwâ, Yaghhû, Ya'ûq et Nasr"
    },
    {
      "key": 5443,
      "id": "71-24",
      "translation": "Elles [les idoles] ont déjà égaré plusieurs. Ne fais (Seigneur) croître les injustes qu'en égarement"
    },
    {
      "key": 5444,
      "id": "71-25",
      "translation": "A cause de leurs fautes, ils ont été noyés, puis on les a fait entrer au Feu, et ils n'ont pas trouvé en dehors d'Allah, de secoureurs»"
    },
    {
      "key": 5445,
      "id": "71-26",
      "translation": "Et Noé dit: «Seigneur, ne laisse sur la terre aucun infidèle"
    },
    {
      "key": 5446,
      "id": "71-27",
      "translation": "Si Tu les laisses [en vie], ils égareront Tes serviteurs et n'engendreront que des pécheurs infidèles"
    },
    {
      "key": 5447,
      "id": "71-28",
      "translation": "Seigneur! Pardonne-moi, et à mes père et mère et à celui qui entre dans ma demeure croyant, ainsi qu'aux croyants et croyantes; et ne fais croître les injustes qu'en perdition»"
    },
    {
      "key": 5448,
      "id": "72-1",
      "translation": "Dis: «Il m'a été révélé qu'un groupe de djinns prêtèrent l'oreille, puis dirent: «Nous avons certes entendu une Lecture [le Coran] merveilleuse"
    },
    {
      "key": 5449,
      "id": "72-2",
      "translation": "qui guide vers la droiture. Nous y avons cru, et nous n'associerons jamais personne à notre Seigneur"
    },
    {
      "key": 5450,
      "id": "72-3",
      "translation": "En vérité notre Seigneur - que Sa grandeur soit exaltée - ne S'est donné ni compagne, ni enfant"
    },
    {
      "key": 5451,
      "id": "72-4",
      "translation": "Notre insensé [Iblis] disait des extravagances contre Allah"
    },
    {
      "key": 5452,
      "id": "72-5",
      "translation": "Et nous pensions que ni les humains ni les djinns ne sauraient jamais proférer de mensonge contre Allah"
    },
    {
      "key": 5453,
      "id": "72-6",
      "translation": "Or, il y avait parmi les humains, des mâles qui cherchaient protection auprès des mâles parmi les djinns mais cela ne fit qu'accroître leur détresse"
    },
    {
      "key": 5454,
      "id": "72-7",
      "translation": "Et ils avaient pensé comme vous avez pensé qu'Allah ne ressusciterait jamais personne"
    },
    {
      "key": 5455,
      "id": "72-8",
      "translation": "Nous avions frôlé le ciel et nous l'avions trouvé plein d'une forte garde et de bolides"
    },
    {
      "key": 5456,
      "id": "72-9",
      "translation": "Nous y prenions place pour écouter. Mais quiconque prête l'oreille maintenant, trouve contre lui un bolide aux aguets"
    },
    {
      "key": 5457,
      "id": "72-10",
      "translation": "Nous ne savons pas si on veut du mal aux habitants de la terre ou si leur Seigneur veut les mettre sur le droit chemin"
    },
    {
      "key": 5458,
      "id": "72-11",
      "translation": "Il y a parmi nous des vertueux et [d'autres] qui le sont moins: nous étions divisés en différentes sectes"
    },
    {
      "key": 5459,
      "id": "72-12",
      "translation": "Nous pensions bien que nous ne saurions jamais réduire Allah à l'impuissance sur la terre et que nous ne saurions jamais le réduire à l'impuissance en nous enfuyant"
    },
    {
      "key": 5460,
      "id": "72-13",
      "translation": "Et lorsque nous avons entendu le guide [le Coran], nous y avons cru, et quiconque croit en son Seigneur ne craint alors ni diminution de récompense ni oppression"
    },
    {
      "key": 5461,
      "id": "72-14",
      "translation": "Il y a parmi nous les Musulmans, et il y en a les injustes [qui ont dévié]. Et ceux qui se sont convertis à l'Islam sont ceux qui ont cherché la droiture"
    },
    {
      "key": 5462,
      "id": "72-15",
      "translation": "Et quant aux injustes, ils formeront le combustible de l'Enfer"
    },
    {
      "key": 5463,
      "id": "72-16",
      "translation": "Et s'ils se maintenaient dans la bonne direction, Nous les aurions abreuvés, certes d'une eau abondante"
    },
    {
      "key": 5464,
      "id": "72-17",
      "translation": "afin de les y éprouver. Et quiconque se détourne du rappel de son Seigneur, Il l'achemine vers un châtiment sans cesse croissant"
    },
    {
      "key": 5465,
      "id": "72-18",
      "translation": "Les mosquées sont consacrées à Allah: n'invoquez donc personne avec Allah"
    },
    {
      "key": 5466,
      "id": "72-19",
      "translation": "Et quand le serviteur d'Allah s'est mis debout pour L'invoquer, ils faillirent se ruer en masse sur lui"
    },
    {
      "key": 5467,
      "id": "72-20",
      "translation": "Dis: «Je n'invoque que mon Seigneur et ne Lui associe personne»"
    },
    {
      "key": 5468,
      "id": "72-21",
      "translation": "Dis: «Je ne possède aucun moyen pour vous faire du mal, ni pour vous mettre sur le chemin droit»"
    },
    {
      "key": 5469,
      "id": "72-22",
      "translation": "Dis: «Vraiment, personne ne saura me protéger contre Allah; et jamais je ne trouverai de refuge en dehors de Lui"
    },
    {
      "key": 5470,
      "id": "72-23",
      "translation": "[Je ne puis que transmettre] une communication et des messages [émanant] d'Allah. Et quiconque désobéit à Allah et à Son Messager aura le feu de l'Enfer pour y demeurer éternellement"
    },
    {
      "key": 5471,
      "id": "72-24",
      "translation": "Puis, quand ils verront ce dont on les menaçait, ils sauront lesquels ont les secours les plus faibles et [lesquels] sont les moins nombreux"
    },
    {
      "key": 5472,
      "id": "72-25",
      "translation": "Dis: «Je ne sais pas si ce dont vous êtes menacés est proche, ou bien, si mon Seigneur va lui assigner un délai"
    },
    {
      "key": 5473,
      "id": "72-26",
      "translation": "[C'est Lui] qui connaît le mystère. Il ne dévoile Son mystère à personne"
    },
    {
      "key": 5474,
      "id": "72-27",
      "translation": "sauf à celui qu'Il agrée comme Messager et qu'Il fait précéder et suivre de gardiens vigilants"
    },
    {
      "key": 5475,
      "id": "72-28",
      "translation": "afin qu'Il sache s'ils ont bien transmis les messages de leur Seigneur. Il cerne (de Son savoir) ce qui est avec eux, et dénombre exactement toute chose"
    },
    {
      "key": 5476,
      "id": "73-1",
      "translation": "O toi, l'enveloppé [dans tes vêtements]"
    },
    {
      "key": 5477,
      "id": "73-2",
      "translation": "Lève-toi [pour prier], toute la nuit, excepté une petite partie"
    },
    {
      "key": 5478,
      "id": "73-3",
      "translation": "Sa moitié, ou un peu moins"
    },
    {
      "key": 5479,
      "id": "73-4",
      "translation": "ou un peu plus. Et récite le Coran, lentement et clairement"
    },
    {
      "key": 5480,
      "id": "73-5",
      "translation": "Nous allons te révéler des paroles lourdes (très importantes)"
    },
    {
      "key": 5481,
      "id": "73-6",
      "translation": "La prière pendant la nuit est plus efficace et plus propice pour la récitation"
    },
    {
      "key": 5482,
      "id": "73-7",
      "translation": "Tu as, dans la journée, à vaquer à de longues occupations"
    },
    {
      "key": 5483,
      "id": "73-8",
      "translation": "Et rappelle-toi le nom de ton Seigneur et consacre-toi totalement à Lui"
    },
    {
      "key": 5484,
      "id": "73-9",
      "translation": "le Seigneur du Levant et du Couchant. Il n'y a point de divinité à part Lui. Prends-Le donc comme Protecteur"
    },
    {
      "key": 5485,
      "id": "73-10",
      "translation": "Et endure ce qu'ils disent; et écarte-toi d'eux d'une façon convenable"
    },
    {
      "key": 5486,
      "id": "73-11",
      "translation": "Et laisse-moi avec ceux qui crient au mensonge et qui vivent dans l'aisance; et accorde-leur un court répit"
    },
    {
      "key": 5487,
      "id": "73-12",
      "translation": "Nous avons [pour eux] lourdes chaînes et Enfer"
    },
    {
      "key": 5488,
      "id": "73-13",
      "translation": "et nourriture à faire suffoquer, et châtiment douloureux"
    },
    {
      "key": 5489,
      "id": "73-14",
      "translation": "Le jour où la terre et les montagnes trembleront, tandis que les montagnes deviendront comme une dune de sable dispersée"
    },
    {
      "key": 5490,
      "id": "73-15",
      "translation": "Nous vous avons envoyé un Messager pour être témoin contre vous, de même que Nous avions envoyé un Messager à Pharaon"
    },
    {
      "key": 5491,
      "id": "73-16",
      "translation": "Pharaon désobéit alors au Messager. Nous le saisîmes donc rudement"
    },
    {
      "key": 5492,
      "id": "73-17",
      "translation": "Comment vous préserverez-vous, si vous mécroyez, d'un jour qui rendra les enfants comme des vieillards aux cheveux blancs"
    },
    {
      "key": 5493,
      "id": "73-18",
      "translation": "[et] durant lequel le ciel se fendra. Sa promesse s'accomplira sans doute"
    },
    {
      "key": 5494,
      "id": "73-19",
      "translation": "Ceci est un rappel. Que celui qui veut prenne une voie [menant] à son Seigneur"
    },
    {
      "key": 5495,
      "id": "73-20",
      "translation": "Ton Seigneur sait, certes, que tu  te tiens debout moins de deux tiers de la nuit, ou sa moitié, ou son tiers. De même qu'une partie de ceux qui sont avec toi. Allah détermine la nuit et le jour. Il sait que vous ne saurez jamais passer toute la nuit en prière. Il a usé envers vous avec indulgence. Récitez donc ce qui [vous] est possible du Coran. Il sait qu'il y aura parmi vous des malades, et d'autres qui voyageront sur la terre, en quête de la grâce d'Allah, et d'autres encore qui combattront dans le chemin d'Allah. Récite-en donc ce qui [vous] sera possible. Accomplissez la Salât, acquittez la Zakât, et faites à Allah un prêt sincère. Tout bien que vous vous préparez, vous le retrouverez auprès d'Allah, meilleur et plus grand en fait de récompense. Et implorez le pardon d'Allah. Car Allah est Pardonneur et Très Miséricordieux"
    },
    {
      "key": 5496,
      "id": "74-1",
      "translation": "O, toi ! Le revêtu d'un manteau"
    },
    {
      "key": 5497,
      "id": "74-2",
      "translation": "Lève-toi et avertis"
    },
    {
      "key": 5498,
      "id": "74-3",
      "translation": "Et de ton Seigneur, célèbre la grandeur"
    },
    {
      "key": 5499,
      "id": "74-4",
      "translation": "Et tes vêtements, purifie-les"
    },
    {
      "key": 5500,
      "id": "74-5",
      "translation": "Et de tout péché, écarte-toi"
    },
    {
      "key": 5501,
      "id": "74-6",
      "translation": "Et ne donne pas dans le but de recevoir davantage"
    },
    {
      "key": 5502,
      "id": "74-7",
      "translation": "Et pour ton Seigneur, endure"
    },
    {
      "key": 5503,
      "id": "74-8",
      "translation": "Quand on sonnera du Clairon"
    },
    {
      "key": 5504,
      "id": "74-9",
      "translation": "alors, ce jour-là sera un jour difficile"
    },
    {
      "key": 5505,
      "id": "74-10",
      "translation": "pas facile pour les mécréants"
    },
    {
      "key": 5506,
      "id": "74-11",
      "translation": "Laisse-Moi avec celui que J'ai créé seul"
    },
    {
      "key": 5507,
      "id": "74-12",
      "translation": "et à qui J'ai donné des biens étendus"
    },
    {
      "key": 5508,
      "id": "74-13",
      "translation": "et des enfants qui lui tiennent toujours compagnie"
    },
    {
      "key": 5509,
      "id": "74-14",
      "translation": "pour qui aussi J'ai aplani toutes difficultés"
    },
    {
      "key": 5510,
      "id": "74-15",
      "translation": "Cependant, il convoite [de Moi] que Je lui donne davantage"
    },
    {
      "key": 5511,
      "id": "74-16",
      "translation": "Pas du tout! Car il reniait nos versets (le Coran) avec entêtement"
    },
    {
      "key": 5512,
      "id": "74-17",
      "translation": "Je vais le contraindre à gravir une pente"
    },
    {
      "key": 5513,
      "id": "74-18",
      "translation": "Il a réfléchi. Et il a décidé"
    },
    {
      "key": 5514,
      "id": "74-19",
      "translation": "Qu'il périsse! Comme il a décidé"
    },
    {
      "key": 5515,
      "id": "74-20",
      "translation": "Encore une fois, qu'il périsse; comme il a décidé"
    },
    {
      "key": 5516,
      "id": "74-21",
      "translation": "Ensuite, il a regardé"
    },
    {
      "key": 5517,
      "id": "74-22",
      "translation": "Et il s'est renfrogné et a durci son visage"
    },
    {
      "key": 5518,
      "id": "74-23",
      "translation": "Ensuite il a tourné le dos et s'est enflé d'orgueil"
    },
    {
      "key": 5519,
      "id": "74-24",
      "translation": "Puis il a dit: «Ceci (le Coran) n'est que magie apprise"
    },
    {
      "key": 5520,
      "id": "74-25",
      "translation": "ce n'est là que la parole d'un humain»"
    },
    {
      "key": 5521,
      "id": "74-26",
      "translation": "Je vais le brûler dans le Feu intense (Saqar)"
    },
    {
      "key": 5522,
      "id": "74-27",
      "translation": "Et qui te dira ce qu'est Saqar"
    },
    {
      "key": 5523,
      "id": "74-28",
      "translation": "Il ne laisse rien et n'épargne rien"
    },
    {
      "key": 5524,
      "id": "74-29",
      "translation": "Il brûle la peau et la noircit"
    },
    {
      "key": 5525,
      "id": "74-30",
      "translation": "Ils sont dix neuf à y veiller"
    },
    {
      "key": 5526,
      "id": "74-31",
      "translation": "Nous n'avons assigné comme gardiens du Feu que des Anges. Cependant, Nous n'en avons fixé le nombre que pour éprouver les mécréants, et aussi afin que ceux à qui le Livre a été apporté soient convaincus, et que croisse la foi de ceux qui croient, et que ceux à qui le Livre a été apporté et les croyants n'aient point de doute; et pour que ceux qui ont au cœur quelque maladie ainsi que les mécréants disent: «Qu'a donc voulu Allah par cette parabole?» C'est ainsi qu'Allah égare qui Il veut et guide qui Il veut. Nul ne connaît les armées de ton Seigneur, à part Lui. Et ce n'est là qu'un rappel pour les humains"
    },
    {
      "key": 5527,
      "id": "74-32",
      "translation": "Non!... Par la lune"
    },
    {
      "key": 5528,
      "id": "74-33",
      "translation": "Et par la nuit quand elle se retire"
    },
    {
      "key": 5529,
      "id": "74-34",
      "translation": "Et par l'aurore quand elle se découvre"
    },
    {
      "key": 5530,
      "id": "74-35",
      "translation": "[Saqar] est l'un des plus grands [malheurs]"
    },
    {
      "key": 5531,
      "id": "74-36",
      "translation": "un avertissement, pour les humains"
    },
    {
      "key": 5532,
      "id": "74-37",
      "translation": "Pour qui d'entre vous, veut avancer ou reculer"
    },
    {
      "key": 5533,
      "id": "74-38",
      "translation": "Toute âme est l'otage de ce qu'elle a acquis"
    },
    {
      "key": 5534,
      "id": "74-39",
      "translation": "Sauf les gens de la droite (les élus)"
    },
    {
      "key": 5535,
      "id": "74-40",
      "translation": "dans des Jardins, ils s'interrogeront"
    },
    {
      "key": 5536,
      "id": "74-41",
      "translation": "au sujet des criminels"
    },
    {
      "key": 5537,
      "id": "74-42",
      "translation": "«Qu'est-ce qui vous a acheminés à Saqar?»"
    },
    {
      "key": 5538,
      "id": "74-43",
      "translation": "Ils diront: «Nous n'étions pas de ceux qui faisaient la Salât"
    },
    {
      "key": 5539,
      "id": "74-44",
      "translation": "et nous ne nourrissions pas le pauvre"
    },
    {
      "key": 5540,
      "id": "74-45",
      "translation": "et nous nous associions à ceux qui tenaient des conversations futiles"
    },
    {
      "key": 5541,
      "id": "74-46",
      "translation": "et nous traitions de mensonge le jour de la Rétribution"
    },
    {
      "key": 5542,
      "id": "74-47",
      "translation": "jusqu'à ce que nous vînt la vérité évidente [la mort]»"
    },
    {
      "key": 5543,
      "id": "74-48",
      "translation": "Ne leur profitera point donc, l'intercession des intercesseurs"
    },
    {
      "key": 5544,
      "id": "74-49",
      "translation": "Qu'ont-ils à se détourner du Rappel"
    },
    {
      "key": 5545,
      "id": "74-50",
      "translation": "Ils sont comme des onagres épouvantés"
    },
    {
      "key": 5546,
      "id": "74-51",
      "translation": "s'enfuyant devant un lion"
    },
    {
      "key": 5547,
      "id": "74-52",
      "translation": "Chacun d'eux voudrait plutôt qu'on lui apporte des feuilles tout étalées"
    },
    {
      "key": 5548,
      "id": "74-53",
      "translation": "Ah! Non! C'est plutôt qu'ils ne craignent pas l'au-delà"
    },
    {
      "key": 5549,
      "id": "74-54",
      "translation": "Ah! Non! Ceci est vraiment un Rappel"
    },
    {
      "key": 5550,
      "id": "74-55",
      "translation": "Quiconque veut, qu'il se le rappelle"
    },
    {
      "key": 5551,
      "id": "74-56",
      "translation": "Mais ils ne se rappelleront que si Allah veut. C'est Lui qui est Le plus digne d'être craint; et c'est Lui qui détient le pardon"
    },
    {
      "key": 5552,
      "id": "75-1",
      "translation": "Non!... Je jure par le Jour de la Résurrection"
    },
    {
      "key": 5553,
      "id": "75-2",
      "translation": "Mais non!, Je jure par l'âme qui ne cesse de se blâmer"
    },
    {
      "key": 5554,
      "id": "75-3",
      "translation": "L'homme, pense-t-il que Nous ne réunirons jamais ses os"
    },
    {
      "key": 5555,
      "id": "75-4",
      "translation": "Mais si! Nous sommes Capable de remettre à leur place les extrémités de ses doigts"
    },
    {
      "key": 5556,
      "id": "75-5",
      "translation": "L'homme voudrait plutôt continuer à vivre en libertin"
    },
    {
      "key": 5557,
      "id": "75-6",
      "translation": "Il interroge: «A quand, le Jour de la Résurrection?»"
    },
    {
      "key": 5558,
      "id": "75-7",
      "translation": "Lorsque la vue sera éblouie"
    },
    {
      "key": 5559,
      "id": "75-8",
      "translation": "et que la lune s'éclipsera"
    },
    {
      "key": 5560,
      "id": "75-9",
      "translation": "et que le soleil et la lune seront réunis"
    },
    {
      "key": 5561,
      "id": "75-10",
      "translation": "l'homme, ce jour-là, dira: «Où fuir?»"
    },
    {
      "key": 5562,
      "id": "75-11",
      "translation": "Non! Point de refuge"
    },
    {
      "key": 5563,
      "id": "75-12",
      "translation": "Vers ton Seigneur sera, ce jour-là, le retour"
    },
    {
      "key": 5564,
      "id": "75-13",
      "translation": "L'homme sera informé ce jour-là de ce qu'il aura avancé et de ce qu'il aura remis à plus tard"
    },
    {
      "key": 5565,
      "id": "75-14",
      "translation": "Mais l'homme sera un témoin perspicace contre lui-même"
    },
    {
      "key": 5566,
      "id": "75-15",
      "translation": "quand même il présenterait ses excuses"
    },
    {
      "key": 5567,
      "id": "75-16",
      "translation": "Ne remue pas ta langue pour hâter sa récitation"
    },
    {
      "key": 5568,
      "id": "75-17",
      "translation": "Son rassemblement (dans ton cœur et sa fixation dans ta mémoire) Nous incombent, ainsi que la façon de le réciter"
    },
    {
      "key": 5569,
      "id": "75-18",
      "translation": "Quand donc Nous le récitons, suis sa récitation"
    },
    {
      "key": 5570,
      "id": "75-19",
      "translation": "A Nous, ensuite incombera son explication"
    },
    {
      "key": 5571,
      "id": "75-20",
      "translation": "Mais vous aimez plutôt [la vie] éphémère"
    },
    {
      "key": 5572,
      "id": "75-21",
      "translation": "et vous délaissez l'au-delà"
    },
    {
      "key": 5573,
      "id": "75-22",
      "translation": "Ce jour-là, il y aura des visages resplendissants"
    },
    {
      "key": 5574,
      "id": "75-23",
      "translation": "qui regarderont leur Seigneur"
    },
    {
      "key": 5575,
      "id": "75-24",
      "translation": "et il y aura ce jour-là, des visages assombris"
    },
    {
      "key": 5576,
      "id": "75-25",
      "translation": "qui s'attendent à subir une catastrophe"
    },
    {
      "key": 5577,
      "id": "75-26",
      "translation": "Mais non! Quand [l'âme] en arrive aux clavicules"
    },
    {
      "key": 5578,
      "id": "75-27",
      "translation": "et qu'on dit: «Qui est exorciseur?»"
    },
    {
      "key": 5579,
      "id": "75-28",
      "translation": "et qu'il [l'agonisant] est convaincu que c'est la séparation (la mort)"
    },
    {
      "key": 5580,
      "id": "75-29",
      "translation": "et que la jambe s'enlace à la jambe"
    },
    {
      "key": 5581,
      "id": "75-30",
      "translation": "c'est vers ton Seigneur, ce jour-là que tu seras conduit"
    },
    {
      "key": 5582,
      "id": "75-31",
      "translation": "Mais il n'a ni cru, ni fait la Salât"
    },
    {
      "key": 5583,
      "id": "75-32",
      "translation": "par contre, il a démenti et tourné le dos"
    },
    {
      "key": 5584,
      "id": "75-33",
      "translation": "puis il s'en est allé vers sa famille, marchant avec orgueil"
    },
    {
      "key": 5585,
      "id": "75-34",
      "translation": "«Malheur à toi, malheur!»"
    },
    {
      "key": 5586,
      "id": "75-35",
      "translation": "Et encore malheur à toi, malheur"
    },
    {
      "key": 5587,
      "id": "75-36",
      "translation": "L'homme pense-t-il qu'on le laissera sans obligation à observer"
    },
    {
      "key": 5588,
      "id": "75-37",
      "translation": "N'était-il pas une goutte de sperme éjaculé"
    },
    {
      "key": 5589,
      "id": "75-38",
      "translation": "Et ensuite une adhérence Puis [Allah] l'a créée et formée harmonieusement"
    },
    {
      "key": 5590,
      "id": "75-39",
      "translation": "puis en a fait alors les deux éléments de couple: le mâle et la femelle"
    },
    {
      "key": 5591,
      "id": "75-40",
      "translation": "Celui-là [Allah] n'est-Il pas capable de faire revivre les morts"
    },
    {
      "key": 5592,
      "id": "76-1",
      "translation": "S'est-il écoulé pour l'homme un laps de temps durant lequel il n'était même pas une chose mentionnable"
    },
    {
      "key": 5593,
      "id": "76-2",
      "translation": "En effet, Nous avons créé l'homme d'une goutte de sperme mélangé [aux composantes diverses] pour le mettre à l'épreuve. [C'est pourquoi] Nous l'avons fait entendant et voyant"
    },
    {
      "key": 5594,
      "id": "76-3",
      "translation": "Nous l'avons guidé dans le chemin, - qu'il soit reconnaissant ou ingrat"
    },
    {
      "key": 5595,
      "id": "76-4",
      "translation": "Nous avons préparé pour les infidèles des chaînes, des carcans et une fournaise ardente"
    },
    {
      "key": 5596,
      "id": "76-5",
      "translation": "Les vertueux boiront d'une coupe dont le mélange sera de camphre"
    },
    {
      "key": 5597,
      "id": "76-6",
      "translation": "d'une source de laquelle boiront les serviteurs d'Allah et ils la feront jaillir en abondance"
    },
    {
      "key": 5598,
      "id": "76-7",
      "translation": "Ils accomplissent leurs vœux et ils redoutent un jour dont le mal s'étendra partout"
    },
    {
      "key": 5599,
      "id": "76-8",
      "translation": "et offrent la nourriture, malgré son amour, au pauvre, à l'orphelin et au prisonnier"
    },
    {
      "key": 5600,
      "id": "76-9",
      "translation": "(disant): «C'est pour le visage d'Allah que nous vous nourrissons: nous ne voulons de vous ni récompense ni gratitude"
    },
    {
      "key": 5601,
      "id": "76-10",
      "translation": "Nous redoutons, de notre Seigneur, un jour terrible et catastrophique»"
    },
    {
      "key": 5602,
      "id": "76-11",
      "translation": "Allah les protègera donc du mal de ce jour-là, et leur fera rencontrer la splendeur et la joie"
    },
    {
      "key": 5603,
      "id": "76-12",
      "translation": "et les rétribuera pour ce qu'ils auront enduré, en leur donnant le Paradis et des [vêtements] de soie"
    },
    {
      "key": 5604,
      "id": "76-13",
      "translation": "ils y seront accoudés sur des divans, n'y voyant ni soleil ni froid glacial"
    },
    {
      "key": 5605,
      "id": "76-14",
      "translation": "Ses ombrages les couvriront de près, et ses fruits inclinés bien bas [à portée de leurs mains]"
    },
    {
      "key": 5606,
      "id": "76-15",
      "translation": "Et l'on fera circuler parmi eux des récipients d'argent et des coupes cristallines"
    },
    {
      "key": 5607,
      "id": "76-16",
      "translation": "en cristal d'argent, dont le contenu a été savamment dosé"
    },
    {
      "key": 5608,
      "id": "76-17",
      "translation": "Et là, ils seront abreuvés d'une coupe dont le mélange sera de gingembre"
    },
    {
      "key": 5609,
      "id": "76-18",
      "translation": "puisé là-dedans à une source qui s'appelle Salsabîl"
    },
    {
      "key": 5610,
      "id": "76-19",
      "translation": "Et parmi eux, circuleront des garçons éternellement jeunes. Quand tu les verras, tu les prendras pour des perles éparpillées"
    },
    {
      "key": 5611,
      "id": "76-20",
      "translation": "Et quand tu regarderas là-bas, tu verras un délice et un vaste royaume"
    },
    {
      "key": 5612,
      "id": "76-21",
      "translation": "Ils porteront des vêtements verts de satin et de brocart. Et ils seront parés de bracelets d'argent. Et leur Seigneur les abreuvera d'une boisson très pure"
    },
    {
      "key": 5613,
      "id": "76-22",
      "translation": "Cela sera pour vous une récompense, et votre effort sera reconnu"
    },
    {
      "key": 5614,
      "id": "76-23",
      "translation": "En vérité c'est Nous qui avons fait descendre sur toi le Coran graduellement"
    },
    {
      "key": 5615,
      "id": "76-24",
      "translation": "Endure donc ce que ton Seigneur a décrété, et n'obéis ni au pécheur, parmi eux, ni au grand mécréant"
    },
    {
      "key": 5616,
      "id": "76-25",
      "translation": "Et invoque le nom de ton Seigneur, matin et après-midi"
    },
    {
      "key": 5617,
      "id": "76-26",
      "translation": "et prosterne-toi devant Lui une partie de la nuit; et glorifie-Le de longues [heures] pendant la nuit"
    },
    {
      "key": 5618,
      "id": "76-27",
      "translation": "Ces gens-là aiment [la vie] éphémère (la vie sur terre) et laissent derrière eux un jour bien lourd [le Jour du Jugement]"
    },
    {
      "key": 5619,
      "id": "76-28",
      "translation": "C'est Nous qui les avons créés et avons fortifié leur constitution. Quand Nous voulons, cependant, Nous les remplaçons [facilement] par leurs semblables"
    },
    {
      "key": 5620,
      "id": "76-29",
      "translation": "Ceci est un rappel. Que celui qui veut prenne donc le chemin vers son Seigneur"
    },
    {
      "key": 5621,
      "id": "76-30",
      "translation": "Cependant, vous ne saurez vouloir, à moins qu'Allah veuille. Et Allah est Omniscient et Sage"
    },
    {
      "key": 5622,
      "id": "76-31",
      "translation": "Il fait entrer qui Il veut dans Sa miséricorde. Et quant aux injustes, Il leur a préparé un châtiment douloureux"
    },
    {
      "key": 5623,
      "id": "77-1",
      "translation": "Par ceux qu'on envoie en rafales"
    },
    {
      "key": 5624,
      "id": "77-2",
      "translation": "et qui soufflent en tempête"
    },
    {
      "key": 5625,
      "id": "77-3",
      "translation": "Et qui dispersent largement [dans toutes les directions]"
    },
    {
      "key": 5626,
      "id": "77-4",
      "translation": "Par ceux qui séparent nettement (le bien et le mal)"
    },
    {
      "key": 5627,
      "id": "77-5",
      "translation": "et lancent un rappel"
    },
    {
      "key": 5628,
      "id": "77-6",
      "translation": "en guise d'excuse ou d'avertissement"
    },
    {
      "key": 5629,
      "id": "77-7",
      "translation": "Ce qui vous est promis est inéluctable"
    },
    {
      "key": 5630,
      "id": "77-8",
      "translation": "Quand donc les étoiles seront effacées"
    },
    {
      "key": 5631,
      "id": "77-9",
      "translation": "et que le ciel sera fendu"
    },
    {
      "key": 5632,
      "id": "77-10",
      "translation": "et que les montagnes seront pulvérisées"
    },
    {
      "key": 5633,
      "id": "77-11",
      "translation": "et que le moment (pour la réunion) des Messagers a été fixé"
    },
    {
      "key": 5634,
      "id": "77-12",
      "translation": "A quel jour tout cela a-t-il été renvoyé"
    },
    {
      "key": 5635,
      "id": "77-13",
      "translation": "Au Jour de la Décision. [le Jugement]"
    },
    {
      "key": 5636,
      "id": "77-14",
      "translation": "Et qui te dira ce qu'est le Jour de la Décision"
    },
    {
      "key": 5637,
      "id": "77-15",
      "translation": "Malheur, ce jour-là, à ceux qui criaient au mensonge"
    },
    {
      "key": 5638,
      "id": "77-16",
      "translation": "N'avons-nous pas fait périr les premières [générations]"
    },
    {
      "key": 5639,
      "id": "77-17",
      "translation": "Puis ne les avons-Nous pas fait suivre par les derniers"
    },
    {
      "key": 5640,
      "id": "77-18",
      "translation": "C'est ainsi que Nous agissons avec les criminels"
    },
    {
      "key": 5641,
      "id": "77-19",
      "translation": "Malheur, ce jour-là, à ceux qui criaient au mensonge"
    },
    {
      "key": 5642,
      "id": "77-20",
      "translation": "Ne vous avons-Nous pas créés d'une eau vile"
    },
    {
      "key": 5643,
      "id": "77-21",
      "translation": "que Nous avons placée dans un reposoir sûr"
    },
    {
      "key": 5644,
      "id": "77-22",
      "translation": "pour une durée connue"
    },
    {
      "key": 5645,
      "id": "77-23",
      "translation": "Nous l'avons décrété ainsi et Nous décrétons [tout] de façon parfaite"
    },
    {
      "key": 5646,
      "id": "77-24",
      "translation": "Malheur, ce jour-là, à ceux qui criaient au mensonge"
    },
    {
      "key": 5647,
      "id": "77-25",
      "translation": "N'avons-Nous pas fait de la terre un endroit les contenant tous"
    },
    {
      "key": 5648,
      "id": "77-26",
      "translation": "les vivants ainsi que les morts"
    },
    {
      "key": 5649,
      "id": "77-27",
      "translation": "Et n'y avons-Nous pas placé fermement de hautes montagnes? Et ne vous avons-Nous pas abreuvés d'eau douce"
    },
    {
      "key": 5650,
      "id": "77-28",
      "translation": "Malheur, ce jour-là, à ceux qui criaient au mensonge"
    },
    {
      "key": 5651,
      "id": "77-29",
      "translation": "Allez vers ce que vous traitiez alors de mensonge"
    },
    {
      "key": 5652,
      "id": "77-30",
      "translation": "Allez vers une ombre [fumée de l'Enfer] à trois branches"
    },
    {
      "key": 5653,
      "id": "77-31",
      "translation": "qui n'est ni ombreuse ni capable de protéger contre la flamme"
    },
    {
      "key": 5654,
      "id": "77-32",
      "translation": "car [le feu] jette des étincelles volumineuses comme des châteaux"
    },
    {
      "key": 5655,
      "id": "77-33",
      "translation": "et qu'on prendrait pour des chameaux jaunes"
    },
    {
      "key": 5656,
      "id": "77-34",
      "translation": "Malheur, ce jour-là, à ceux qui criaient au mensonge"
    },
    {
      "key": 5657,
      "id": "77-35",
      "translation": "Ce sera le jour où ils ne [peuvent] pas parler"
    },
    {
      "key": 5658,
      "id": "77-36",
      "translation": "et point ne leur sera donné permission de s'excuser"
    },
    {
      "key": 5659,
      "id": "77-37",
      "translation": "Malheur, ce jour-là, à ceux qui criaient au mensonge"
    },
    {
      "key": 5660,
      "id": "77-38",
      "translation": "C'est le Jour de la Décision [Jugement], où nous vous réunirons ainsi que les anciens"
    },
    {
      "key": 5661,
      "id": "77-39",
      "translation": "Si vous disposez d'une ruse, rusez donc contre Moi"
    },
    {
      "key": 5662,
      "id": "77-40",
      "translation": "Malheur, ce jour-là, à ceux qui criaient au mensonge"
    },
    {
      "key": 5663,
      "id": "77-41",
      "translation": "Les pieux seront parmi des ombrages et des sources"
    },
    {
      "key": 5664,
      "id": "77-42",
      "translation": "De même que des fruits selon leurs désirs"
    },
    {
      "key": 5665,
      "id": "77-43",
      "translation": "«Mangez et buvez agréablement, pour ce que vous faisiez»"
    },
    {
      "key": 5666,
      "id": "77-44",
      "translation": "C'est ainsi que Nous récompensons les bienfaisants"
    },
    {
      "key": 5667,
      "id": "77-45",
      "translation": "Malheur, ce jour-là, à ceux qui criaient au mensonge"
    },
    {
      "key": 5668,
      "id": "77-46",
      "translation": "«Mangez et jouissez un peu (ici-bas); vous êtes certes des criminels»"
    },
    {
      "key": 5669,
      "id": "77-47",
      "translation": "Malheur, ce jour-là, à ceux qui criaient au mensonge"
    },
    {
      "key": 5670,
      "id": "77-48",
      "translation": "Et quand on leur dit: «Inclinez-vous, ils ne s'inclinent pas"
    },
    {
      "key": 5671,
      "id": "77-49",
      "translation": "Malheur, ce jour-là, à ceux qui criaient au mensonge"
    },
    {
      "key": 5672,
      "id": "77-50",
      "translation": "Après cela, en quelle parole croiront-ils donc"
    },
    {
      "key": 5673,
      "id": "78-1",
      "translation": "Sur quoi s'interrogent-ils mutuellement"
    },
    {
      "key": 5674,
      "id": "78-2",
      "translation": "Sur la grande nouvelle"
    },
    {
      "key": 5675,
      "id": "78-3",
      "translation": "à propos de laquelle ils divergent"
    },
    {
      "key": 5676,
      "id": "78-4",
      "translation": "Eh bien non! Ils sauront bientôt"
    },
    {
      "key": 5677,
      "id": "78-5",
      "translation": "Encore une fois, non! Ils sauront bientôt"
    },
    {
      "key": 5678,
      "id": "78-6",
      "translation": "N'avons-Nous pas fait de la terre une couche"
    },
    {
      "key": 5679,
      "id": "78-7",
      "translation": "et (placé) les montagnes comme des piquets"
    },
    {
      "key": 5680,
      "id": "78-8",
      "translation": "Nous vous avons créés en couples"
    },
    {
      "key": 5681,
      "id": "78-9",
      "translation": "et désigné votre sommeil pour votre repos"
    },
    {
      "key": 5682,
      "id": "78-10",
      "translation": "et fait de la nuit un vêtement"
    },
    {
      "key": 5683,
      "id": "78-11",
      "translation": "et assigné le jour pour les affaires de la vie"
    },
    {
      "key": 5684,
      "id": "78-12",
      "translation": "et construit au-dessus de vous sept (cieux) renforcés"
    },
    {
      "key": 5685,
      "id": "78-13",
      "translation": "et [y] avons placé une lampe (le soleil) très ardente"
    },
    {
      "key": 5686,
      "id": "78-14",
      "translation": "et fait descendre des nuées une eau abondante"
    },
    {
      "key": 5687,
      "id": "78-15",
      "translation": "pour faire pousser par elle grains et plantes"
    },
    {
      "key": 5688,
      "id": "78-16",
      "translation": "et jardins luxuriants"
    },
    {
      "key": 5689,
      "id": "78-17",
      "translation": "Le Jour de la Décision [du Jugement] a son terme fixé"
    },
    {
      "key": 5690,
      "id": "78-18",
      "translation": "Le jour où l'on soufflera dans la Trompe, vous viendrez par troupes"
    },
    {
      "key": 5691,
      "id": "78-19",
      "translation": "et le ciel sera ouvert et [présentera] des portes"
    },
    {
      "key": 5692,
      "id": "78-20",
      "translation": "et les montagnes seront mises en marche et deviendront un mirage"
    },
    {
      "key": 5693,
      "id": "78-21",
      "translation": "L'Enfer demeure aux aguets"
    },
    {
      "key": 5694,
      "id": "78-22",
      "translation": "refuge pour les transgresseurs"
    },
    {
      "key": 5695,
      "id": "78-23",
      "translation": "Ils y demeureront pendant des siècles successifs"
    },
    {
      "key": 5696,
      "id": "78-24",
      "translation": "Ils n'y goûteront ni fraîcheur ni breuvage"
    },
    {
      "key": 5697,
      "id": "78-25",
      "translation": "Hormis une eau bouillante et un pus"
    },
    {
      "key": 5698,
      "id": "78-26",
      "translation": "comme rétribution équitable"
    },
    {
      "key": 5699,
      "id": "78-27",
      "translation": "Car ils ne s'attendaient pas à rendre compte"
    },
    {
      "key": 5700,
      "id": "78-28",
      "translation": "et traitaient de mensonges, continuellement, Nos versets"
    },
    {
      "key": 5701,
      "id": "78-29",
      "translation": "alors que Nous avons dénombré toutes choses en écrit"
    },
    {
      "key": 5702,
      "id": "78-30",
      "translation": "Goûtez-donc. Nous n'augmenterons pour vous que le châtiment"
    },
    {
      "key": 5703,
      "id": "78-31",
      "translation": "Pour les pieux ce sera une réussite"
    },
    {
      "key": 5704,
      "id": "78-32",
      "translation": "jardins et vignes"
    },
    {
      "key": 5705,
      "id": "78-33",
      "translation": "et des (belles) aux seins arrondis, d'une égale jeunesse"
    },
    {
      "key": 5706,
      "id": "78-34",
      "translation": "et coupes débordantes"
    },
    {
      "key": 5707,
      "id": "78-35",
      "translation": "Ils n'y entendront ni futilités ni mensonges"
    },
    {
      "key": 5708,
      "id": "78-36",
      "translation": "A titre de récompense de ton Seigneur et à titre de don abondant"
    },
    {
      "key": 5709,
      "id": "78-37",
      "translation": "du Seigneur des cieux et de la terre et de ce qui existe entre eux, le Tout Miséricordieux; ils n'osent nullement Lui adresser la parole"
    },
    {
      "key": 5710,
      "id": "78-38",
      "translation": "Le jour où l'Esprit et les Anges se dresseront en rangs, nul ne saura parler, sauf celui à qui le Tout Miséricordieux aura accordé la permission, et qui dira la vérité"
    },
    {
      "key": 5711,
      "id": "78-39",
      "translation": "Ce jour-là est inéluctable. Que celui qui veut prenne donc refuge auprès de son Seigneur"
    },
    {
      "key": 5712,
      "id": "78-40",
      "translation": "Nous vous avons avertis d'un châtiment bien proche, le jour où l'homme verra ce que ses deux mains ont préparé; et l'infidèle dira: «Hélas pour moi! Comme j'aurais aimé n'être que poussière»"
    },
    {
      "key": 5713,
      "id": "79-1",
      "translation": "Par ceux qui arrachent violemment"
    },
    {
      "key": 5714,
      "id": "79-2",
      "translation": "Et par ceux qui recueillent avec douceur"
    },
    {
      "key": 5715,
      "id": "79-3",
      "translation": "Et par ceux qui voguent librement"
    },
    {
      "key": 5716,
      "id": "79-4",
      "translation": "puis s'élancent à toute vitesse"
    },
    {
      "key": 5717,
      "id": "79-5",
      "translation": "et règlent les affaires"
    },
    {
      "key": 5718,
      "id": "79-6",
      "translation": "Le jour où [la terre] tremblera [au premier son du clairon]"
    },
    {
      "key": 5719,
      "id": "79-7",
      "translation": "immédiatement suivi du deuxième"
    },
    {
      "key": 5720,
      "id": "79-8",
      "translation": "Ce jour-là, il y aura des cœurs qui seront agités d'effroi"
    },
    {
      "key": 5721,
      "id": "79-9",
      "translation": "et leurs regards se baisseront"
    },
    {
      "key": 5722,
      "id": "79-10",
      "translation": "Ils disent: «Quoi! Serons-nous ramenés à notre vie première"
    },
    {
      "key": 5723,
      "id": "79-11",
      "translation": "quand nous serons ossements pourris?»"
    },
    {
      "key": 5724,
      "id": "79-12",
      "translation": "Ils disent: «ce sera alors un retour ruineux!»"
    },
    {
      "key": 5725,
      "id": "79-13",
      "translation": "Il n'y aura qu'une sommation"
    },
    {
      "key": 5726,
      "id": "79-14",
      "translation": "et voilà qu'ils seront sur la terre (ressuscités)"
    },
    {
      "key": 5727,
      "id": "79-15",
      "translation": "Le récit de Moïse t'est-il parvenu"
    },
    {
      "key": 5728,
      "id": "79-16",
      "translation": "Quand son Seigneur l'appela, dans Towâ, la vallée sanctifiée"
    },
    {
      "key": 5729,
      "id": "79-17",
      "translation": "«Va vers Pharaon. Vraiment, il s'est rebellé!»"
    },
    {
      "key": 5730,
      "id": "79-18",
      "translation": "Puis dis-lui: «Voudrais-tu te purifier"
    },
    {
      "key": 5731,
      "id": "79-19",
      "translation": "et que je te guide vers ton Seigneur afin que tu Le craignes?»"
    },
    {
      "key": 5732,
      "id": "79-20",
      "translation": "Il lui fit voir le très grand miracle"
    },
    {
      "key": 5733,
      "id": "79-21",
      "translation": "Mais il le qualifia de mensonge et désobéit"
    },
    {
      "key": 5734,
      "id": "79-22",
      "translation": "Ensuite, il tourna le dos, s'en alla précipitamment"
    },
    {
      "key": 5735,
      "id": "79-23",
      "translation": "rassembla [les gens] et leur fit une proclamation"
    },
    {
      "key": 5736,
      "id": "79-24",
      "translation": "et dit: «C'est moi votre Seigneur, le très haut»"
    },
    {
      "key": 5737,
      "id": "79-25",
      "translation": "Alors Allah le saisit de la punition exemplaire de l'au-delà et de celle d'ici-bas"
    },
    {
      "key": 5738,
      "id": "79-26",
      "translation": "Il y a certes là un sujet de réflexion pour celui qui craint"
    },
    {
      "key": 5739,
      "id": "79-27",
      "translation": "Etes-vous plus durs à créer? ou le ciel, qu'Il a pourtant construit"
    },
    {
      "key": 5740,
      "id": "79-28",
      "translation": "Il a élevé bien haut sa voûte, puis l'a parfaitement ordonné"
    },
    {
      "key": 5741,
      "id": "79-29",
      "translation": "Il a assombri sa nuit et fait luire son jour"
    },
    {
      "key": 5742,
      "id": "79-30",
      "translation": "Et quant à la terre, après cela, Il l'a étendue"
    },
    {
      "key": 5743,
      "id": "79-31",
      "translation": "Il a fait sortir d'elle son eau et son pâturage"
    },
    {
      "key": 5744,
      "id": "79-32",
      "translation": "et quant aux montagnes, Il les a ancrées"
    },
    {
      "key": 5745,
      "id": "79-33",
      "translation": "pour votre jouissance, vous et vos bestiaux"
    },
    {
      "key": 5746,
      "id": "79-34",
      "translation": "Puis quand viendra le grand cataclysme"
    },
    {
      "key": 5747,
      "id": "79-35",
      "translation": "le jour où l'homme se rappellera à quoi il s'est efforcé"
    },
    {
      "key": 5748,
      "id": "79-36",
      "translation": "l'Enfer sera pleinement visible à celui qui regardera"
    },
    {
      "key": 5749,
      "id": "79-37",
      "translation": "Quant à celui qui aura dépassé les limites"
    },
    {
      "key": 5750,
      "id": "79-38",
      "translation": "et aura préféré la vie présente"
    },
    {
      "key": 5751,
      "id": "79-39",
      "translation": "alors, l'Enfer sera son refuge"
    },
    {
      "key": 5752,
      "id": "79-40",
      "translation": "Et pour celui qui aura redouté de comparaître devant son Seigneur, et préservé son âme de la passion"
    },
    {
      "key": 5753,
      "id": "79-41",
      "translation": "le Paradis sera alors son refuge"
    },
    {
      "key": 5754,
      "id": "79-42",
      "translation": "Ils t'interrogent au sujet de l'Heure: «Quand va-t-elle jeter l'ancre?»"
    },
    {
      "key": 5755,
      "id": "79-43",
      "translation": "Quelle [science] en as-tu pour le leur dire"
    },
    {
      "key": 5756,
      "id": "79-44",
      "translation": "Son terme n'est connu que de ton Seigneur"
    },
    {
      "key": 5757,
      "id": "79-45",
      "translation": "Tu n'es que l'avertisseur de celui qui la redoute"
    },
    {
      "key": 5758,
      "id": "79-46",
      "translation": "Le jour où ils la verront, il leur semblera n'avoir demeuré qu'un soir ou un matin"
    },
    {
      "key": 5759,
      "id": "80-1",
      "translation": "Il s'est renfrogné et il s'est détourné"
    },
    {
      "key": 5760,
      "id": "80-2",
      "translation": "parce que l'aveugle est venu à lui"
    },
    {
      "key": 5761,
      "id": "80-3",
      "translation": "Qui te dit: peut-être [cherche]-t-il à se purifier"
    },
    {
      "key": 5762,
      "id": "80-4",
      "translation": "ou à se rappeler en sorte que le rappel lui profite"
    },
    {
      "key": 5763,
      "id": "80-5",
      "translation": "Quant à celui qui se complaît dans sa suffisance (pour sa richesse)"
    },
    {
      "key": 5764,
      "id": "80-6",
      "translation": "tu vas avec empressement à sa rencontre"
    },
    {
      "key": 5765,
      "id": "80-7",
      "translation": "Or, que t'importe qu'il ne se purifie pas»"
    },
    {
      "key": 5766,
      "id": "80-8",
      "translation": "Et quant à celui qui vient à toi avec empressement"
    },
    {
      "key": 5767,
      "id": "80-9",
      "translation": "tout en ayant la crainte"
    },
    {
      "key": 5768,
      "id": "80-10",
      "translation": "tu ne t'en soucies pas"
    },
    {
      "key": 5769,
      "id": "80-11",
      "translation": "N'agis plus ainsi! Vraiment ceci est un rappel"
    },
    {
      "key": 5770,
      "id": "80-12",
      "translation": "quiconque veut, donc, s'en rappelle"
    },
    {
      "key": 5771,
      "id": "80-13",
      "translation": "consigné dans des feuilles honorées"
    },
    {
      "key": 5772,
      "id": "80-14",
      "translation": "élevées, purifiées"
    },
    {
      "key": 5773,
      "id": "80-15",
      "translation": "entre les mains d'ambassadeurs"
    },
    {
      "key": 5774,
      "id": "80-16",
      "translation": "nobles, obéissants"
    },
    {
      "key": 5775,
      "id": "80-17",
      "translation": "Que périsse l'homme! Qu'il est ingrat"
    },
    {
      "key": 5776,
      "id": "80-18",
      "translation": "De quoi [Allah] l'a-t-Il créé"
    },
    {
      "key": 5777,
      "id": "80-19",
      "translation": "D'une goutte de sperme, Il le crée et détermine (son destin)"
    },
    {
      "key": 5778,
      "id": "80-20",
      "translation": "puis Il lui facilite le chemin"
    },
    {
      "key": 5779,
      "id": "80-21",
      "translation": "puis Il lui donne la mort et le met au tombeau"
    },
    {
      "key": 5780,
      "id": "80-22",
      "translation": "puis Il le ressuscitera quand Il voudra"
    },
    {
      "key": 5781,
      "id": "80-23",
      "translation": "Eh bien non! [L'homme] n'accomplit pas ce qu'Il lui commande"
    },
    {
      "key": 5782,
      "id": "80-24",
      "translation": "Que l'homme considère donc sa nourriture"
    },
    {
      "key": 5783,
      "id": "80-25",
      "translation": "C'est Nous qui versons l'eau abondante"
    },
    {
      "key": 5784,
      "id": "80-26",
      "translation": "puis Nous fendons la terre par fissures"
    },
    {
      "key": 5785,
      "id": "80-27",
      "translation": "et y faisons pousser grains"
    },
    {
      "key": 5786,
      "id": "80-28",
      "translation": "vignobles et légumes"
    },
    {
      "key": 5787,
      "id": "80-29",
      "translation": "oliviers et palmiers"
    },
    {
      "key": 5788,
      "id": "80-30",
      "translation": "jardins touffus"
    },
    {
      "key": 5789,
      "id": "80-31",
      "translation": "fruits et herbages"
    },
    {
      "key": 5790,
      "id": "80-32",
      "translation": "pour votre jouissance vous et vos bestiaux"
    },
    {
      "key": 5791,
      "id": "80-33",
      "translation": "Puis quand viendra le Fracas"
    },
    {
      "key": 5792,
      "id": "80-34",
      "translation": "le jour où l'homme s'enfuira de son frère"
    },
    {
      "key": 5793,
      "id": "80-35",
      "translation": "de sa mère, de son père"
    },
    {
      "key": 5794,
      "id": "80-36",
      "translation": "de sa compagne et de ses enfants"
    },
    {
      "key": 5795,
      "id": "80-37",
      "translation": "car chacun d'eux, ce jour-là, aura son propre cas pour l'occuper"
    },
    {
      "key": 5796,
      "id": "80-38",
      "translation": "Ce jour-là, il y aura des visages rayonnants"
    },
    {
      "key": 5797,
      "id": "80-39",
      "translation": "riants et réjouis"
    },
    {
      "key": 5798,
      "id": "80-40",
      "translation": "De même qu'il y aura, ce jour-là, des visages couverts de poussière"
    },
    {
      "key": 5799,
      "id": "80-41",
      "translation": "recouverts de ténèbres"
    },
    {
      "key": 5800,
      "id": "80-42",
      "translation": "Voilà les infidèles, les libertins"
    },
    {
      "key": 5801,
      "id": "81-1",
      "translation": "Quand le soleil sera obscurci"
    },
    {
      "key": 5802,
      "id": "81-2",
      "translation": "et que les étoiles deviendront ternes"
    },
    {
      "key": 5803,
      "id": "81-3",
      "translation": "et les montagnes mises en marche"
    },
    {
      "key": 5804,
      "id": "81-4",
      "translation": "et les chamelles à terme, négligées"
    },
    {
      "key": 5805,
      "id": "81-5",
      "translation": "et les bêtes farouches, rassemblées"
    },
    {
      "key": 5806,
      "id": "81-6",
      "translation": "et les mers allumées"
    },
    {
      "key": 5807,
      "id": "81-7",
      "translation": "et les âmes accouplées"
    },
    {
      "key": 5808,
      "id": "81-8",
      "translation": "et qu'on demandera à la fillette enterrée vivante"
    },
    {
      "key": 5809,
      "id": "81-9",
      "translation": "pour quel péché elle a été tuée"
    },
    {
      "key": 5810,
      "id": "81-10",
      "translation": "Et quand les feuilles seront déployées"
    },
    {
      "key": 5811,
      "id": "81-11",
      "translation": "et le ciel écorché"
    },
    {
      "key": 5812,
      "id": "81-12",
      "translation": "et la fournaise attisée"
    },
    {
      "key": 5813,
      "id": "81-13",
      "translation": "et le Paradis rapproché"
    },
    {
      "key": 5814,
      "id": "81-14",
      "translation": "chaque âme saura ce qu'elle a présenté"
    },
    {
      "key": 5815,
      "id": "81-15",
      "translation": "Non!... Je jure par les planètes qui gravitent"
    },
    {
      "key": 5816,
      "id": "81-16",
      "translation": "qui courent et disparaissent"
    },
    {
      "key": 5817,
      "id": "81-17",
      "translation": "par la nuit quand elle survient"
    },
    {
      "key": 5818,
      "id": "81-18",
      "translation": "et par l'aube quand elle exhale son souffle"
    },
    {
      "key": 5819,
      "id": "81-19",
      "translation": "Ceci [le Coran] est la parole d'un noble Messager"
    },
    {
      "key": 5820,
      "id": "81-20",
      "translation": "doué d'une grande force, et ayant un rang élevé auprès du Maître du Trône"
    },
    {
      "key": 5821,
      "id": "81-21",
      "translation": "obéi, là-haut, et digne de confiance"
    },
    {
      "key": 5822,
      "id": "81-22",
      "translation": "Votre compagnon  n'est nullement fou"
    },
    {
      "key": 5823,
      "id": "81-23",
      "translation": "il l'a effectivement vu (Gabriel), au clair horizon"
    },
    {
      "key": 5824,
      "id": "81-24",
      "translation": "et il ne garde pas avarement pour lui-même ce qui lui a été révélé"
    },
    {
      "key": 5825,
      "id": "81-25",
      "translation": "Et ceci [le Coran] n'est point la parole d'un diable banni"
    },
    {
      "key": 5826,
      "id": "81-26",
      "translation": "Où allez-vous donc"
    },
    {
      "key": 5827,
      "id": "81-27",
      "translation": "Ceci n'est qu'un rappel pour l'univers"
    },
    {
      "key": 5828,
      "id": "81-28",
      "translation": "pour celui d'entre vous qui veut suivre le chemin droit"
    },
    {
      "key": 5829,
      "id": "81-29",
      "translation": "Mais vous ne pouvez vouloir, que si Allah veut, [Lui], le Seigneur de l'Univers"
    },
    {
      "key": 5830,
      "id": "82-1",
      "translation": "Quand le ciel se rompra"
    },
    {
      "key": 5831,
      "id": "82-2",
      "translation": "et que les étoiles se disperseront"
    },
    {
      "key": 5832,
      "id": "82-3",
      "translation": "et que les mers confondront leurs eaux"
    },
    {
      "key": 5833,
      "id": "82-4",
      "translation": "et que les tombeaux seront bouleversés"
    },
    {
      "key": 5834,
      "id": "82-5",
      "translation": "toute âme saura alors ce qu'elle a accompli et ce qu'elle a remis de faire à plus tard"
    },
    {
      "key": 5835,
      "id": "82-6",
      "translation": "O homme! Qu'est-ce qui t'a trompé au sujet de ton Seigneur, le Noble"
    },
    {
      "key": 5836,
      "id": "82-7",
      "translation": "qui t'a créé, puis modelé et constitué harmonieusement"
    },
    {
      "key": 5837,
      "id": "82-8",
      "translation": "Il t'a façonné dans la forme qu'Il a voulue"
    },
    {
      "key": 5838,
      "id": "82-9",
      "translation": "Non...! [malgré tout] vous traitez la Rétribution de mensonge"
    },
    {
      "key": 5839,
      "id": "82-10",
      "translation": "alors que veillent sur vous des gardiens"
    },
    {
      "key": 5840,
      "id": "82-11",
      "translation": "de nobles scribes"
    },
    {
      "key": 5841,
      "id": "82-12",
      "translation": "qui savent ce que vous faites"
    },
    {
      "key": 5842,
      "id": "82-13",
      "translation": "Les bons seront, certes, dans un [jardin] de délice"
    },
    {
      "key": 5843,
      "id": "82-14",
      "translation": "et les libertins seront, certes, dans une fournaise"
    },
    {
      "key": 5844,
      "id": "82-15",
      "translation": "où ils brûleront, le jour de la Rétribution"
    },
    {
      "key": 5845,
      "id": "82-16",
      "translation": "incapables de s'en échapper"
    },
    {
      "key": 5846,
      "id": "82-17",
      "translation": "Et qui te dira ce qu'est le jour de la Rétribution"
    },
    {
      "key": 5847,
      "id": "82-18",
      "translation": "Encore une fois, qui te dira ce qu'est le jour de la Rétribution"
    },
    {
      "key": 5848,
      "id": "82-19",
      "translation": "Le jour où aucune âme ne pourra rien en faveur d'une autre âme. Et ce jour-là, le commandement sera à Allah"
    },
    {
      "key": 5849,
      "id": "83-1",
      "translation": "Malheur aux fraudeurs"
    },
    {
      "key": 5850,
      "id": "83-2",
      "translation": "qui, lorsqu'ils font mesurer pour eux-mêmes exigent la pleine mesure"
    },
    {
      "key": 5851,
      "id": "83-3",
      "translation": "et qui lorsqu'eux-mêmes mesurent ou pèsent pour les autres, [leur] causent perte"
    },
    {
      "key": 5852,
      "id": "83-4",
      "translation": "Ceux-là ne pensent-ils pas qu'ils seront ressuscités"
    },
    {
      "key": 5853,
      "id": "83-5",
      "translation": "en un jour terrible"
    },
    {
      "key": 5854,
      "id": "83-6",
      "translation": "le jour où les gens se tiendront debout devant le Seigneur de l'Univers"
    },
    {
      "key": 5855,
      "id": "83-7",
      "translation": "Non...! Mais en vérité le livre des libertins sera dans le Sijjîn"
    },
    {
      "key": 5856,
      "id": "83-8",
      "translation": "et qui te dira ce qu'est le Sijjîn"
    },
    {
      "key": 5857,
      "id": "83-9",
      "translation": "Un livre déjà cacheté (achevé)"
    },
    {
      "key": 5858,
      "id": "83-10",
      "translation": "Malheur, ce jour-là, aux négateurs"
    },
    {
      "key": 5859,
      "id": "83-11",
      "translation": "qui démentent le jour de la Rétribution"
    },
    {
      "key": 5860,
      "id": "83-12",
      "translation": "Or, ne le dément que tout transgresseur, pécheur"
    },
    {
      "key": 5861,
      "id": "83-13",
      "translation": "qui, lorsque Nos versets lui sont récités, dit: «[Ce sont] des contes d'anciens!»"
    },
    {
      "key": 5862,
      "id": "83-14",
      "translation": "Pas du tout, mais ce qu'ils ont accompli couvre leurs cœurs"
    },
    {
      "key": 5863,
      "id": "83-15",
      "translation": "Qu'ils prennent garde! En vérité ce jour-là un voile les empêchera de voir leur Seigneur"
    },
    {
      "key": 5864,
      "id": "83-16",
      "translation": "ensuite, ils brûleront certes, dans la Fournaise"
    },
    {
      "key": 5865,
      "id": "83-17",
      "translation": "on [leur] dira alors: «Voilà ce que vous traitiez de mensonge!»"
    },
    {
      "key": 5866,
      "id": "83-18",
      "translation": "Qu'ils prennent garde! Le livre des bons sera dans l'Illiyûn"
    },
    {
      "key": 5867,
      "id": "83-19",
      "translation": "et qui te dira ce qu'est l'Illiyûn"
    },
    {
      "key": 5868,
      "id": "83-20",
      "translation": "un livre cacheté"
    },
    {
      "key": 5869,
      "id": "83-21",
      "translation": "Les rapprochés [d'Allah: les Anges] en témoignent"
    },
    {
      "key": 5870,
      "id": "83-22",
      "translation": "Les bons seront dans [un Jardin] de délice"
    },
    {
      "key": 5871,
      "id": "83-23",
      "translation": "sur les divans, ils regardent"
    },
    {
      "key": 5872,
      "id": "83-24",
      "translation": "Tu reconnaîtras sur leurs visages, l'éclat de la félicité"
    },
    {
      "key": 5873,
      "id": "83-25",
      "translation": "On leur sert à boire un nectar pur, cacheté"
    },
    {
      "key": 5874,
      "id": "83-26",
      "translation": "laissant un arrière-goût de musc. Que ceux qui la convoitent entrent en compétition [pour l'acquérir]"
    },
    {
      "key": 5875,
      "id": "83-27",
      "translation": "Il est mélangé à la boisson de Tasnîm"
    },
    {
      "key": 5876,
      "id": "83-28",
      "translation": "source dont les rapprochés boivent"
    },
    {
      "key": 5877,
      "id": "83-29",
      "translation": "Les criminels riaient de ceux qui croyaient"
    },
    {
      "key": 5878,
      "id": "83-30",
      "translation": "et, passant près d'eux, ils se faisaient des œillades"
    },
    {
      "key": 5879,
      "id": "83-31",
      "translation": "et, retournant dans leurs familles, ils retournaient en plaisantant"
    },
    {
      "key": 5880,
      "id": "83-32",
      "translation": "et les voyant, ils disaient: «Ce sont vraiment ceux-là les égarés»"
    },
    {
      "key": 5881,
      "id": "83-33",
      "translation": "Or, ils n'ont pas été envoyés pour être leurs gardiens"
    },
    {
      "key": 5882,
      "id": "83-34",
      "translation": "Aujourd'hui, donc, ce sont ceux qui ont cru qui rient des infidèles"
    },
    {
      "key": 5883,
      "id": "83-35",
      "translation": "sur les divans, ils regardent"
    },
    {
      "key": 5884,
      "id": "83-36",
      "translation": "Est-ce que les infidèles ont eu la récompense de ce qu'ils faisaient"
    },
    {
      "key": 5885,
      "id": "84-1",
      "translation": "Quand le ciel se déchirera"
    },
    {
      "key": 5886,
      "id": "84-2",
      "translation": "et obéira à son Seigneur - et fera ce qu'il doit faire"
    },
    {
      "key": 5887,
      "id": "84-3",
      "translation": "et que la terre sera nivelée"
    },
    {
      "key": 5888,
      "id": "84-4",
      "translation": "et qu'elle rejettera ce qui est en son sein (les morts) et se videra"
    },
    {
      "key": 5889,
      "id": "84-5",
      "translation": "et qu'elle obéira à son Seigneur - et fera ce qu'elle doit faire"
    },
    {
      "key": 5890,
      "id": "84-6",
      "translation": "O homme! Toi qui t'efforces vers ton Seigneur sans relâche, tu Le rencontreras alors"
    },
    {
      "key": 5891,
      "id": "84-7",
      "translation": "Celui qui recevra son livre en sa main droite"
    },
    {
      "key": 5892,
      "id": "84-8",
      "translation": "sera soumis à un jugement facile"
    },
    {
      "key": 5893,
      "id": "84-9",
      "translation": "et retournera réjoui auprès de sa famille"
    },
    {
      "key": 5894,
      "id": "84-10",
      "translation": "Quant à celui qui recevra son livre derrière son dos"
    },
    {
      "key": 5895,
      "id": "84-11",
      "translation": "il invoquera la destruction sur lui-même"
    },
    {
      "key": 5896,
      "id": "84-12",
      "translation": "et il brûlera dans un feu ardent"
    },
    {
      "key": 5897,
      "id": "84-13",
      "translation": "Car il était tout joyeux parmi les siens"
    },
    {
      "key": 5898,
      "id": "84-14",
      "translation": "et il pensait que jamais il ne ressusciterait"
    },
    {
      "key": 5899,
      "id": "84-15",
      "translation": "Mais si! Certes, son Seigneur l'observait parfaitement"
    },
    {
      "key": 5900,
      "id": "84-16",
      "translation": "Non!... Je jure par le crépuscule"
    },
    {
      "key": 5901,
      "id": "84-17",
      "translation": "et par la nuit et ce qu'elle enveloppe"
    },
    {
      "key": 5902,
      "id": "84-18",
      "translation": "et par la lune quand elle devient pleine lune"
    },
    {
      "key": 5903,
      "id": "84-19",
      "translation": "Vous passerez, certes, par des états successifs"
    },
    {
      "key": 5904,
      "id": "84-20",
      "translation": "Qu'ont-ils à ne pas croire"
    },
    {
      "key": 5905,
      "id": "84-21",
      "translation": "et à ne pas se prosterner quand le Coran leur est lu"
    },
    {
      "key": 5906,
      "id": "84-22",
      "translation": "Mais ceux qui ne croient pas, le traitent plutôt de mensonge"
    },
    {
      "key": 5907,
      "id": "84-23",
      "translation": "Or, Allah sait bien ce qu'ils dissimulent"
    },
    {
      "key": 5908,
      "id": "84-24",
      "translation": "Annonce-leur donc un châtiment douloureux"
    },
    {
      "key": 5909,
      "id": "84-25",
      "translation": "Sauf ceux qui croient et accomplissent les bonnes œuvres: à eux une récompense jamais interrompue"
    },
    {
      "key": 5910,
      "id": "85-1",
      "translation": "Par le ciel aux constellations"
    },
    {
      "key": 5911,
      "id": "85-2",
      "translation": "et par le jour promis"
    },
    {
      "key": 5912,
      "id": "85-3",
      "translation": "et par le témoin et ce dont on témoigne"
    },
    {
      "key": 5913,
      "id": "85-4",
      "translation": "Périssent les gens de l'Uhdûd"
    },
    {
      "key": 5914,
      "id": "85-5",
      "translation": "par le feu plein de combustible"
    },
    {
      "key": 5915,
      "id": "85-6",
      "translation": "cependant qu'ils étaient assis tout autour"
    },
    {
      "key": 5916,
      "id": "85-7",
      "translation": "ils étaient ainsi témoins de ce qu'ils faisaient des croyants"
    },
    {
      "key": 5917,
      "id": "85-8",
      "translation": "à qui ils ne leur reprochaient que d'avoir cru en Allah, le Puissant, le Digne de louange"
    },
    {
      "key": 5918,
      "id": "85-9",
      "translation": "Auquel appartient la royauté des cieux et de la terre. Allah est témoin de toute chose"
    },
    {
      "key": 5919,
      "id": "85-10",
      "translation": "Ceux qui font subir des épreuves aux croyants et aux croyantes, puis ne se repentent pas, auront le châtiment de l'Enfer et le supplice du feu"
    },
    {
      "key": 5920,
      "id": "85-11",
      "translation": "Ceux qui croient et accomplissent les bonnes œuvres auront des Jardins sous lesquels coulent les ruisseaux. Cela est le grand succès"
    },
    {
      "key": 5921,
      "id": "85-12",
      "translation": "La riposte de ton Seigneur est redoutable"
    },
    {
      "key": 5922,
      "id": "85-13",
      "translation": "C'est Lui, certes, qui commence (la création) et la refait"
    },
    {
      "key": 5923,
      "id": "85-14",
      "translation": "Et c'est Lui le Pardonneur, le Tout-Affectueux"
    },
    {
      "key": 5924,
      "id": "85-15",
      "translation": "Le Maître du Trône, le Tout-Glorieux"
    },
    {
      "key": 5925,
      "id": "85-16",
      "translation": "Il réalise parfaitement tout ce qu'Il veut"
    },
    {
      "key": 5926,
      "id": "85-17",
      "translation": "T'est-il parvenu le récit des armées"
    },
    {
      "key": 5927,
      "id": "85-18",
      "translation": "de Pharaon, et de Thamûd"
    },
    {
      "key": 5928,
      "id": "85-19",
      "translation": "Mais ceux qui ne croient pas persistent à démentir"
    },
    {
      "key": 5929,
      "id": "85-20",
      "translation": "alors qu'Allah, derrière eux, les cerne de toutes parts"
    },
    {
      "key": 5930,
      "id": "85-21",
      "translation": "Mais c'est plutôt un Coran glorifié"
    },
    {
      "key": 5931,
      "id": "85-22",
      "translation": "préservé sur une Tablette (auprès d'Allah)"
    },
    {
      "key": 5932,
      "id": "86-1",
      "translation": "Par le ciel et par l'astre nocturne"
    },
    {
      "key": 5933,
      "id": "86-2",
      "translation": "Et qui te dira ce qu'est l'astre nocturne"
    },
    {
      "key": 5934,
      "id": "86-3",
      "translation": "C'est l'étoile vivement brillante"
    },
    {
      "key": 5935,
      "id": "86-4",
      "translation": "Il n'est pas d'âme qui n'ait sur elle un gardien"
    },
    {
      "key": 5936,
      "id": "86-5",
      "translation": "Que l'homme considère donc de quoi il a été créé"
    },
    {
      "key": 5937,
      "id": "86-6",
      "translation": "Il a été créé d'une giclée d'eau"
    },
    {
      "key": 5938,
      "id": "86-7",
      "translation": "sortie d'entre les lombes et les côtes"
    },
    {
      "key": 5939,
      "id": "86-8",
      "translation": "Allah est certes capable de le ressusciter"
    },
    {
      "key": 5940,
      "id": "86-9",
      "translation": "Le jour où les cœurs dévoileront leurs secrets"
    },
    {
      "key": 5941,
      "id": "86-10",
      "translation": "Il n'aura alors ni force ni secoureur"
    },
    {
      "key": 5942,
      "id": "86-11",
      "translation": "Par le ciel qui fait revenir la pluie"
    },
    {
      "key": 5943,
      "id": "86-12",
      "translation": "et par la terre qui se fend"
    },
    {
      "key": 5944,
      "id": "86-13",
      "translation": "Ceci [le Coran] est certes, une parole décisive [qui tranche entre le vrai et le faux]"
    },
    {
      "key": 5945,
      "id": "86-14",
      "translation": "et non point une plaisanterie frivole"
    },
    {
      "key": 5946,
      "id": "86-15",
      "translation": "Ils se servent d'une ruse"
    },
    {
      "key": 5947,
      "id": "86-16",
      "translation": "et Moi aussi Je me sers de Mon plan"
    },
    {
      "key": 5948,
      "id": "86-17",
      "translation": "Accorde (ô Prophète) donc un délai aux infidèles: accorde-leur un court délai"
    },
    {
      "key": 5949,
      "id": "87-1",
      "translation": "Glorifie le nom de ton Seigneur, le Très Haut"
    },
    {
      "key": 5950,
      "id": "87-2",
      "translation": "Celui Qui a créé et agencé harmonieusement"
    },
    {
      "key": 5951,
      "id": "87-3",
      "translation": "qui a décrété et guidé"
    },
    {
      "key": 5952,
      "id": "87-4",
      "translation": "et qui a fait pousser le pâturage"
    },
    {
      "key": 5953,
      "id": "87-5",
      "translation": "et en a fait ensuite un foin sombre"
    },
    {
      "key": 5954,
      "id": "87-6",
      "translation": "Nous te ferons réciter (le Coran), de sorte que tu n'oublieras"
    },
    {
      "key": 5955,
      "id": "87-7",
      "translation": "que ce qu'Allah veut. Car, Il connaît ce qui paraît au grand jour ainsi que ce qui est caché"
    },
    {
      "key": 5956,
      "id": "87-8",
      "translation": "Nous te mettrons sur la voie la plus facile"
    },
    {
      "key": 5957,
      "id": "87-9",
      "translation": "Rappelle, donc, où le Rappel doit être utile"
    },
    {
      "key": 5958,
      "id": "87-10",
      "translation": "Quiconque craint (Allah) s'[en] rappellera"
    },
    {
      "key": 5959,
      "id": "87-11",
      "translation": "et s'en écartera le grand malheureux"
    },
    {
      "key": 5960,
      "id": "87-12",
      "translation": "qui brûlera dans le plus grand Feu"
    },
    {
      "key": 5961,
      "id": "87-13",
      "translation": "où il ne mourra ni ne vivra"
    },
    {
      "key": 5962,
      "id": "87-14",
      "translation": "Réussit, certes, celui qui se purifie"
    },
    {
      "key": 5963,
      "id": "87-15",
      "translation": "et se rappelle le nom de son Seigneur, puis célèbre la Salât"
    },
    {
      "key": 5964,
      "id": "87-16",
      "translation": "Mais, vous préférez plutôt la vie présente"
    },
    {
      "key": 5965,
      "id": "87-17",
      "translation": "alors que l'au-delà est meilleur et plus durable"
    },
    {
      "key": 5966,
      "id": "87-18",
      "translation": "Ceci se trouve, certes, dans les Feuilles anciennes"
    },
    {
      "key": 5967,
      "id": "87-19",
      "translation": "les Feuilles d'Abraham et de Moïse"
    },
    {
      "key": 5968,
      "id": "88-1",
      "translation": "T'est-il parvenu le récit de l'enveloppante"
    },
    {
      "key": 5969,
      "id": "88-2",
      "translation": "Ce jour-là, il y aura des visages humiliés"
    },
    {
      "key": 5970,
      "id": "88-3",
      "translation": "préoccupés, harassés"
    },
    {
      "key": 5971,
      "id": "88-4",
      "translation": "Ils brûleront dans un Feu ardent"
    },
    {
      "key": 5972,
      "id": "88-5",
      "translation": "et seront abreuvés d'une source bouillante"
    },
    {
      "key": 5973,
      "id": "88-6",
      "translation": "Il n'y aura pour eux d'autre nourriture que des plantes épineuses [darî]"
    },
    {
      "key": 5974,
      "id": "88-7",
      "translation": "qui n'engraisse, ni n'apaise la faim"
    },
    {
      "key": 5975,
      "id": "88-8",
      "translation": "Ce jour-là, il y aura des visages épanouis"
    },
    {
      "key": 5976,
      "id": "88-9",
      "translation": "contents de leurs efforts"
    },
    {
      "key": 5977,
      "id": "88-10",
      "translation": "dans un haut Jardin"
    },
    {
      "key": 5978,
      "id": "88-11",
      "translation": "où ils n'entendent aucune futilité"
    },
    {
      "key": 5979,
      "id": "88-12",
      "translation": "Là, il y aura une source coulante"
    },
    {
      "key": 5980,
      "id": "88-13",
      "translation": "Là, des divans élevés"
    },
    {
      "key": 5981,
      "id": "88-14",
      "translation": "et des coupes posées"
    },
    {
      "key": 5982,
      "id": "88-15",
      "translation": "et des coussins rangés"
    },
    {
      "key": 5983,
      "id": "88-16",
      "translation": "et des tapis étalés"
    },
    {
      "key": 5984,
      "id": "88-17",
      "translation": "Ne considèrent-ils donc pas les chameaux, comment ils ont été créés"
    },
    {
      "key": 5985,
      "id": "88-18",
      "translation": "et le ciel comment il est élevé"
    },
    {
      "key": 5986,
      "id": "88-19",
      "translation": "et les montagnes comment elles sont dressées"
    },
    {
      "key": 5987,
      "id": "88-20",
      "translation": "et la terre comment elle est nivelée"
    },
    {
      "key": 5988,
      "id": "88-21",
      "translation": "Eh bien, rappelle! Tu n'es qu'un rappeleur"
    },
    {
      "key": 5989,
      "id": "88-22",
      "translation": "et tu n'es pas un dominateur sur eux"
    },
    {
      "key": 5990,
      "id": "88-23",
      "translation": "Sauf celui qui tourne le dos et ne croit pas"
    },
    {
      "key": 5991,
      "id": "88-24",
      "translation": "alors Allah le châtiera du plus grand châtiment"
    },
    {
      "key": 5992,
      "id": "88-25",
      "translation": "Vers Nous est leur retour"
    },
    {
      "key": 5993,
      "id": "88-26",
      "translation": "Ensuite, c'est à Nous de leur demander compte"
    },
    {
      "key": 5994,
      "id": "89-1",
      "translation": "Par l'Aube"
    },
    {
      "key": 5995,
      "id": "89-2",
      "translation": "Et par les dix nuits"
    },
    {
      "key": 5996,
      "id": "89-3",
      "translation": "Par le pair et l'impair"
    },
    {
      "key": 5997,
      "id": "89-4",
      "translation": "Et par la nuit quand elle s'écoule"
    },
    {
      "key": 5998,
      "id": "89-5",
      "translation": "N'est-ce pas là un serment, pour un doué d'intelligence"
    },
    {
      "key": 5999,
      "id": "89-6",
      "translation": "N'as-tu pas vu comment ton Seigneur a agi avec les 'Aad"
    },
    {
      "key": 6000,
      "id": "89-7",
      "translation": "[avec] Iram, [la cité] à la colonne remarquable"
    },
    {
      "key": 6001,
      "id": "89-8",
      "translation": "dont jamais pareille ne fut construite parmi les villes"
    },
    {
      "key": 6002,
      "id": "89-9",
      "translation": "et avec les Thamûd qui taillaient le rocher dans la vallée"
    },
    {
      "key": 6003,
      "id": "89-10",
      "translation": "ainsi qu'avec Pharaon, l'homme aux épieux"
    },
    {
      "key": 6004,
      "id": "89-11",
      "translation": "Tous, étaient des gens qui transgressaient dans [leurs] pays"
    },
    {
      "key": 6005,
      "id": "89-12",
      "translation": "et y avaient commis beaucoup de désordre"
    },
    {
      "key": 6006,
      "id": "89-13",
      "translation": "Donc, ton Seigneur déversa sur eux un fouet du châtiment"
    },
    {
      "key": 6007,
      "id": "89-14",
      "translation": "Car ton Seigneur demeure aux aguets"
    },
    {
      "key": 6008,
      "id": "89-15",
      "translation": "Quant à l'homme, lorsque son Seigneur l'éprouve en l'honorant et en le comblant de bienfaits, il dit: «Mon Seigneur m'a honoré»"
    },
    {
      "key": 6009,
      "id": "89-16",
      "translation": "Mais par contre, quand Il l'éprouve en lui restreignant sa subsistance, il dit: «Mon Seigneur m'a avili»"
    },
    {
      "key": 6010,
      "id": "89-17",
      "translation": "Mais non! C'est vous plutôt, qui n'êtes pas généreux envers les orphelins"
    },
    {
      "key": 6011,
      "id": "89-18",
      "translation": "qui ne vous incitez pas mutuellement à nourrir le pauvre"
    },
    {
      "key": 6012,
      "id": "89-19",
      "translation": "qui dévorez l'héritage avec une avidité vorace"
    },
    {
      "key": 6013,
      "id": "89-20",
      "translation": "et aimez les richesses d'un amour sans bornes"
    },
    {
      "key": 6014,
      "id": "89-21",
      "translation": "Prenez garde! Quand la terre sera complètement pulvérisée"
    },
    {
      "key": 6015,
      "id": "89-22",
      "translation": "et que ton Seigneur viendra ainsi que les Anges, rang par rang"
    },
    {
      "key": 6016,
      "id": "89-23",
      "translation": "et que ce jour-là, on amènera l'Enfer; ce jour-là, l'homme se rappellera. Mais à quoi lui servira de se souvenir"
    },
    {
      "key": 6017,
      "id": "89-24",
      "translation": "Il dira: «Hélas! Que n'ai-je fait du bien pour ma vie future"
    },
    {
      "key": 6018,
      "id": "89-25",
      "translation": "Ce jour-là donc, nul ne saura châtier comme Lui châtie"
    },
    {
      "key": 6019,
      "id": "89-26",
      "translation": "et nul ne saura garrotter comme Lui garrotte"
    },
    {
      "key": 6020,
      "id": "89-27",
      "translation": "«O toi, âme apaisée"
    },
    {
      "key": 6021,
      "id": "89-28",
      "translation": "retourne vers ton Seigneur, satisfaite et agréée"
    },
    {
      "key": 6022,
      "id": "89-29",
      "translation": "entre donc parmi Mes serviteurs"
    },
    {
      "key": 6023,
      "id": "89-30",
      "translation": "et entre dans Mon Paradis»"
    },
    {
      "key": 6024,
      "id": "90-1",
      "translation": "Non!... Je jure par cette Cité"
    },
    {
      "key": 6025,
      "id": "90-2",
      "translation": "et toi, tu es un résident dans cette cité"
    },
    {
      "key": 6026,
      "id": "90-3",
      "translation": "Et par le père et ce qu'il engendre"
    },
    {
      "key": 6027,
      "id": "90-4",
      "translation": "Nous avons, certes, créé l'homme pour une vie de lutte"
    },
    {
      "key": 6028,
      "id": "90-5",
      "translation": "Pense-t-il que personne ne pourra rien contre lui"
    },
    {
      "key": 6029,
      "id": "90-6",
      "translation": "Il dit: «J'ai gaspillé beaucoup de biens»"
    },
    {
      "key": 6030,
      "id": "90-7",
      "translation": "Pense-t-il que nul ne l'a vu"
    },
    {
      "key": 6031,
      "id": "90-8",
      "translation": "Ne lui avons Nous pas assigné deux yeux"
    },
    {
      "key": 6032,
      "id": "90-9",
      "translation": "et une langue et deux lèvres"
    },
    {
      "key": 6033,
      "id": "90-10",
      "translation": "Ne l'avons-Nous pas guidé aux deux voies"
    },
    {
      "key": 6034,
      "id": "90-11",
      "translation": "Or, il ne s'engage pas dans la voie difficile"
    },
    {
      "key": 6035,
      "id": "90-12",
      "translation": "Et qui te dira ce qu'est la voie difficile"
    },
    {
      "key": 6036,
      "id": "90-13",
      "translation": "C'est délier un joug [affranchir un esclave]"
    },
    {
      "key": 6037,
      "id": "90-14",
      "translation": "ou nourrir, en un jour de famine"
    },
    {
      "key": 6038,
      "id": "90-15",
      "translation": "un orphelin proche parent"
    },
    {
      "key": 6039,
      "id": "90-16",
      "translation": "ou un pauvre dans le dénuement"
    },
    {
      "key": 6040,
      "id": "90-17",
      "translation": "Et c'est être, en outre, de ceux qui croient et s'enjoignent mutuellement l'endurance, et s'enjoignent mutuellement la miséricorde"
    },
    {
      "key": 6041,
      "id": "90-18",
      "translation": "Ceux-là sont les gens de la droite"
    },
    {
      "key": 6042,
      "id": "90-19",
      "translation": "alors que ceux qui ne croient pas en Nos versets sont les gens de la gauche"
    },
    {
      "key": 6043,
      "id": "90-20",
      "translation": "Le Feu se refermera sur eux"
    },
    {
      "key": 6044,
      "id": "91-1",
      "translation": "Par le soleil et par sa clarté"
    },
    {
      "key": 6045,
      "id": "91-2",
      "translation": "Et par la lune quand elle le suit"
    },
    {
      "key": 6046,
      "id": "91-3",
      "translation": "Et par le jour quand il l'éclaire"
    },
    {
      "key": 6047,
      "id": "91-4",
      "translation": "Et par la nuit quand elle l'enveloppe"
    },
    {
      "key": 6048,
      "id": "91-5",
      "translation": "Et par le ciel et Celui qui l'a construit"
    },
    {
      "key": 6049,
      "id": "91-6",
      "translation": "Et par la terre et Celui qui l'a étendue"
    },
    {
      "key": 6050,
      "id": "91-7",
      "translation": "Et par l'âme et Celui qui l'a harmonieusement façonnée"
    },
    {
      "key": 6051,
      "id": "91-8",
      "translation": "et lui a alors inspiré son immoralité, de même que sa piété"
    },
    {
      "key": 6052,
      "id": "91-9",
      "translation": "A réussi, certes, celui qui la purifie"
    },
    {
      "key": 6053,
      "id": "91-10",
      "translation": "Et est perdu, certes, celui qui la corrompt"
    },
    {
      "key": 6054,
      "id": "91-11",
      "translation": "Les Thamûd, par leur transgression, ont crié au mensonge"
    },
    {
      "key": 6055,
      "id": "91-12",
      "translation": "lorsque le plus misérable d'entre eux se leva (pour tuer la chamelle)"
    },
    {
      "key": 6056,
      "id": "91-13",
      "translation": "Le Messager d'Allah leur avait dit: «La chamelle d'Allah! Laissez-la boire"
    },
    {
      "key": 6057,
      "id": "91-14",
      "translation": "Mais, ils le traitèrent de menteur, et la tuèrent. Leur Seigneur les détruisit donc, pour leur péché et étendit Son châtiment sur tous"
    },
    {
      "key": 6058,
      "id": "91-15",
      "translation": "Et Allah n'a aucune crainte des conséquences"
    },
    {
      "key": 6059,
      "id": "92-1",
      "translation": "Par la nuit quand elle enveloppe tout"
    },
    {
      "key": 6060,
      "id": "92-2",
      "translation": "Par le jour quand il éclaire"
    },
    {
      "key": 6061,
      "id": "92-3",
      "translation": "Et par ce qu'Il a créé, mâle et femelle"
    },
    {
      "key": 6062,
      "id": "92-4",
      "translation": "Vos efforts sont divergents"
    },
    {
      "key": 6063,
      "id": "92-5",
      "translation": "Celui qui donne et craint (Allah)"
    },
    {
      "key": 6064,
      "id": "92-6",
      "translation": "et déclare véridique la plus belle récompense"
    },
    {
      "key": 6065,
      "id": "92-7",
      "translation": "Nous lui faciliterons la voie au plus grand bonheur"
    },
    {
      "key": 6066,
      "id": "92-8",
      "translation": "Et quant à celui qui est avare, se dispense (de l'adoration d'Allah)"
    },
    {
      "key": 6067,
      "id": "92-9",
      "translation": "et traite de mensonge la plus belle récompense"
    },
    {
      "key": 6068,
      "id": "92-10",
      "translation": "Nous lui faciliterons la voie à la plus grande difficulté"
    },
    {
      "key": 6069,
      "id": "92-11",
      "translation": "et à rien ne lui serviront ses richesses quand il sera jeté (au Feu)"
    },
    {
      "key": 6070,
      "id": "92-12",
      "translation": "C'est à Nous, certes, de guider"
    },
    {
      "key": 6071,
      "id": "92-13",
      "translation": "à Nous appartient, certes, la vie dernière et la vie présente"
    },
    {
      "key": 6072,
      "id": "92-14",
      "translation": "Je vous ai donc avertis d'un Feu qui flambe"
    },
    {
      "key": 6073,
      "id": "92-15",
      "translation": "où ne brûlera que le damné"
    },
    {
      "key": 6074,
      "id": "92-16",
      "translation": "qui dément et tourne le dos"
    },
    {
      "key": 6075,
      "id": "92-17",
      "translation": "alors qu'en sera écarté le pieux"
    },
    {
      "key": 6076,
      "id": "92-18",
      "translation": "qui donne ses biens pour se purifier"
    },
    {
      "key": 6077,
      "id": "92-19",
      "translation": "et auprès de qui personne ne profite d'un bienfait intéressé"
    },
    {
      "key": 6078,
      "id": "92-20",
      "translation": "mais seulement pour la recherche de La Face de son Seigneur le Très Haut"
    },
    {
      "key": 6079,
      "id": "92-21",
      "translation": "Et certes, il sera bientôt satisfait"
    },
    {
      "key": 6080,
      "id": "93-1",
      "translation": "Par le Jour Montant"
    },
    {
      "key": 6081,
      "id": "93-2",
      "translation": "Et par la nuit quand elle couvre tout"
    },
    {
      "key": 6082,
      "id": "93-3",
      "translation": "Ton Seigneur ne t'a ni abandonné, ni détesté"
    },
    {
      "key": 6083,
      "id": "93-4",
      "translation": "La vie dernière t'est, certes, meilleure que la vie présente"
    },
    {
      "key": 6084,
      "id": "93-5",
      "translation": "Ton Seigneur t'accordera certes [Ses faveurs], et alors tu seras satisfait"
    },
    {
      "key": 6085,
      "id": "93-6",
      "translation": "Ne t'a-t-Il pas trouvé orphelin? Alors Il t'a accueilli"
    },
    {
      "key": 6086,
      "id": "93-7",
      "translation": "Ne t'a-t-Il pas trouvé égaré? Alors Il t'a guidé"
    },
    {
      "key": 6087,
      "id": "93-8",
      "translation": "Ne t'a-t-Il pas trouvé pauvre? Alors Il t'a enrichi"
    },
    {
      "key": 6088,
      "id": "93-9",
      "translation": "Quant à l'orphelin, donc, ne le maltraite pas"
    },
    {
      "key": 6089,
      "id": "93-10",
      "translation": "Quant au demandeur, ne le repousse pas"
    },
    {
      "key": 6090,
      "id": "93-11",
      "translation": "Et quant au bienfait de ton Seigneur, proclame-le"
    },
    {
      "key": 6091,
      "id": "94-1",
      "translation": "N'avons-Nous pas ouvert pour toi ta poitrine"
    },
    {
      "key": 6092,
      "id": "94-2",
      "translation": "Et ne t'avons-Nous pas déchargé du fardeau"
    },
    {
      "key": 6093,
      "id": "94-3",
      "translation": "qui accablait ton dos"
    },
    {
      "key": 6094,
      "id": "94-4",
      "translation": "Et exalté pour toi ta renommée"
    },
    {
      "key": 6095,
      "id": "94-5",
      "translation": "A côté de la difficulté est, certes, une facilité"
    },
    {
      "key": 6096,
      "id": "94-6",
      "translation": "A côté de la difficulté est, certes, une facilité"
    },
    {
      "key": 6097,
      "id": "94-7",
      "translation": "Quand tu te libères, donc, lève-toi"
    },
    {
      "key": 6098,
      "id": "94-8",
      "translation": "et à ton Seigneur aspire"
    },
    {
      "key": 6099,
      "id": "95-1",
      "translation": "Par le figuier et l'olivier"
    },
    {
      "key": 6100,
      "id": "95-2",
      "translation": "Et par le Mont Sînîn"
    },
    {
      "key": 6101,
      "id": "95-3",
      "translation": "Et par cette Cité sûre"
    },
    {
      "key": 6102,
      "id": "95-4",
      "translation": "Nous avons certes créé l'homme dans la forme la plus parfaite"
    },
    {
      "key": 6103,
      "id": "95-5",
      "translation": "Ensuite, Nous l'avons ramené au niveau le plus bas"
    },
    {
      "key": 6104,
      "id": "95-6",
      "translation": "sauf ceux qui croient et accomplissent les bonnes œuvres: ceux-là auront une récompense jamais interrompue"
    },
    {
      "key": 6105,
      "id": "95-7",
      "translation": "Après cela, qu'est-ce qui te fait traiter la rétribution de mensonge"
    },
    {
      "key": 6106,
      "id": "95-8",
      "translation": "Allah n'est-Il pas le plus sage des Juges"
    },
    {
      "key": 6107,
      "id": "96-1",
      "translation": "Lis, au nom de ton Seigneur qui a créé"
    },
    {
      "key": 6108,
      "id": "96-2",
      "translation": "qui a créé l'homme d'une adhérence"
    },
    {
      "key": 6109,
      "id": "96-3",
      "translation": "Lis! Ton Seigneur est le Très Noble"
    },
    {
      "key": 6110,
      "id": "96-4",
      "translation": "qui a enseigné par la plume [le calame]"
    },
    {
      "key": 6111,
      "id": "96-5",
      "translation": "a enseigné à l'homme ce qu'il ne savait pas"
    },
    {
      "key": 6112,
      "id": "96-6",
      "translation": "Prenez-garde! Vraiment l'homme devient rebelle"
    },
    {
      "key": 6113,
      "id": "96-7",
      "translation": "dès qu'il estime qu'il peut se suffire à lui-même (à cause de sa richesse)"
    },
    {
      "key": 6114,
      "id": "96-8",
      "translation": "Mais, c'est vers ton Seigneur qu'est le retour"
    },
    {
      "key": 6115,
      "id": "96-9",
      "translation": "As-tu vu celui qui interdit"
    },
    {
      "key": 6116,
      "id": "96-10",
      "translation": "à un serviteur d'Allah de célébrer la Salât"
    },
    {
      "key": 6117,
      "id": "96-11",
      "translation": "Vois-tu s'il est sur la bonne voie"
    },
    {
      "key": 6118,
      "id": "96-12",
      "translation": "ou s'il ordonne la piété"
    },
    {
      "key": 6119,
      "id": "96-13",
      "translation": "Vois-tu s'il dément et tourne le dos"
    },
    {
      "key": 6120,
      "id": "96-14",
      "translation": "Ne sait-il pas que vraiment Allah voit"
    },
    {
      "key": 6121,
      "id": "96-15",
      "translation": "Mais non! S'il ne cesse pas, Nous le saisirons certes, par le toupet"
    },
    {
      "key": 6122,
      "id": "96-16",
      "translation": "le toupet d'un menteur, d'un pécheur"
    },
    {
      "key": 6123,
      "id": "96-17",
      "translation": "Qu'il appelle donc son assemblée"
    },
    {
      "key": 6124,
      "id": "96-18",
      "translation": "Nous appellerons les gardiens [de l'Enfer]"
    },
    {
      "key": 6125,
      "id": "96-19",
      "translation": "Non! Ne lui obéis pas; mais prosterne-toi et rapproche-toi"
    },
    {
      "key": 6126,
      "id": "97-1",
      "translation": "Nous l'avons certes, fait descendre (le Coran) pendant la nuit d'Al-Qadr"
    },
    {
      "key": 6127,
      "id": "97-2",
      "translation": "Et qui te dira ce qu'est la nuit d'Al-Qadr"
    },
    {
      "key": 6128,
      "id": "97-3",
      "translation": "La nuit d'Al-Qadr est meilleure que mille mois"
    },
    {
      "key": 6129,
      "id": "97-4",
      "translation": "Durant celle-ci descendent les Anges ainsi que l'Esprit, par permission de leur Seigneur pour tout ordre"
    },
    {
      "key": 6130,
      "id": "97-5",
      "translation": "Elle est paix et salut jusqu'à l'apparition de l'aube"
    },
    {
      "key": 6131,
      "id": "98-1",
      "translation": "Les infidèles parmi les gens du Livre, ainsi que les Associateurs, ne cesseront pas de mécroire jusqu'à ce que leur vienne la Preuve évidente"
    },
    {
      "key": 6132,
      "id": "98-2",
      "translation": "un Messager, de la part d'Allah, qui leur récite des feuilles purifiées"
    },
    {
      "key": 6133,
      "id": "98-3",
      "translation": "dans lesquelles se trouvent des prescriptions d'une rectitude parfaite"
    },
    {
      "key": 6134,
      "id": "98-4",
      "translation": "Et ceux à qui le Livre a été donné ne se sont divisés qu'après que la preuve leur fut venue"
    },
    {
      "key": 6135,
      "id": "98-5",
      "translation": "Il ne leur a été commandé, cependant, que d'adorer Allah, Lui vouant un culte exclusif, d'accomplir la Salât et d'acquitter la Zakât. Et voilà la religion de droiture"
    },
    {
      "key": 6136,
      "id": "98-6",
      "translation": "Les infidèles parmi les gens du Livre, ainsi que les Associateurs iront au feu de l'Enfer, pour y demeurer éternellement. De toute la création, ce sont eux les pires"
    },
    {
      "key": 6137,
      "id": "98-7",
      "translation": "Quant à ceux qui croient et accomplissent les bonnes œuvres, ce sont les meilleurs de toute la création"
    },
    {
      "key": 6138,
      "id": "98-8",
      "translation": "Leur récompense auprès d'Allah sera les Jardins de séjour, sous lesquels coulent les ruisseaux, pour y demeurer éternellement. Allah les agrée et ils L'agréent. Telle sera [la récompense] de celui qui craint son Seigneur"
    },
    {
      "key": 6139,
      "id": "99-1",
      "translation": "Quand la terre tremblera d'un violent tremblement"
    },
    {
      "key": 6140,
      "id": "99-2",
      "translation": "et que la terre fera sortir ses fardeaux"
    },
    {
      "key": 6141,
      "id": "99-3",
      "translation": "et que l'homme dira: «Qu'a-t-elle?»"
    },
    {
      "key": 6142,
      "id": "99-4",
      "translation": "ce jour-là, elle contera son histoire"
    },
    {
      "key": 6143,
      "id": "99-5",
      "translation": "selon ce que ton Seigneur lui aura révélé [ordonné]"
    },
    {
      "key": 6144,
      "id": "99-6",
      "translation": "Ce jour-là, les gens sortiront séparément pour que leur soient montrées leurs œuvres"
    },
    {
      "key": 6145,
      "id": "99-7",
      "translation": "Quiconque fait un bien fût-ce du poids d'un atome, le verra"
    },
    {
      "key": 6146,
      "id": "99-8",
      "translation": "et quiconque fait un mal fût-ce du poids d'un atome, le verra"
    },
    {
      "key": 6147,
      "id": "100-1",
      "translation": "Par les coursiers qui halètent"
    },
    {
      "key": 6148,
      "id": "100-2",
      "translation": "qui font jaillir des étincelles"
    },
    {
      "key": 6149,
      "id": "100-3",
      "translation": "qui attaquent au matin"
    },
    {
      "key": 6150,
      "id": "100-4",
      "translation": "et font ainsi voler la poussière"
    },
    {
      "key": 6151,
      "id": "100-5",
      "translation": "et pénètrent au centre de la troupe ennemie"
    },
    {
      "key": 6152,
      "id": "100-6",
      "translation": "L'homme est, certes, ingrat envers son Seigneur"
    },
    {
      "key": 6153,
      "id": "100-7",
      "translation": "et pourtant, il est certes, témoin de cela"
    },
    {
      "key": 6154,
      "id": "100-8",
      "translation": "et pour l'amour des richesses il est certes ardent"
    },
    {
      "key": 6155,
      "id": "100-9",
      "translation": "Ne sait-il donc pas que lorsque ce qui est dans les tombes sera bouleversé"
    },
    {
      "key": 6156,
      "id": "100-10",
      "translation": "et que sera dévoilé ce qui est dans les poitrines"
    },
    {
      "key": 6157,
      "id": "100-11",
      "translation": "ce jour-là, certes, leur Seigneur sera Parfaitement Connaisseur d'eux"
    },
    {
      "key": 6158,
      "id": "101-1",
      "translation": "Le fracas"
    },
    {
      "key": 6159,
      "id": "101-2",
      "translation": "Qu'est-ce que le fracas"
    },
    {
      "key": 6160,
      "id": "101-3",
      "translation": "Et qui te dira ce qu'est le fracas"
    },
    {
      "key": 6161,
      "id": "101-4",
      "translation": "C'est le jour où les gens seront comme des papillons éparpillés"
    },
    {
      "key": 6162,
      "id": "101-5",
      "translation": "et les montagnes comme de la laine cardée"
    },
    {
      "key": 6163,
      "id": "101-6",
      "translation": "quant à celui dont la balance sera lourde"
    },
    {
      "key": 6164,
      "id": "101-7",
      "translation": "il sera dans une vie agréable"
    },
    {
      "key": 6165,
      "id": "101-8",
      "translation": "et quant à celui dont la balance sera légère"
    },
    {
      "key": 6166,
      "id": "101-9",
      "translation": "sa mère [destination] est un abîme très profond"
    },
    {
      "key": 6167,
      "id": "101-10",
      "translation": "Et qui te dira ce que c'est"
    },
    {
      "key": 6168,
      "id": "101-11",
      "translation": "C'est un Feu ardent"
    },
    {
      "key": 6169,
      "id": "102-1",
      "translation": "La course aux richesses vous distrait"
    },
    {
      "key": 6170,
      "id": "102-2",
      "translation": "jusqu'à ce que vous visitiez les tombes"
    },
    {
      "key": 6171,
      "id": "102-3",
      "translation": "Mais non! Vous saurez bientôt"
    },
    {
      "key": 6172,
      "id": "102-4",
      "translation": "(Encore une fois)! Vous saurez bientôt"
    },
    {
      "key": 6173,
      "id": "102-5",
      "translation": "Sûrement! Si vous saviez de science certaine"
    },
    {
      "key": 6174,
      "id": "102-6",
      "translation": "Vous verrez, certes, la Fournaise"
    },
    {
      "key": 6175,
      "id": "102-7",
      "translation": "Puis, vous la verrez certes, avec l'œil de la certitude"
    },
    {
      "key": 6176,
      "id": "102-8",
      "translation": "Puis, assurément, vous serez interrogés, ce jour-là, sur les délices"
    },
    {
      "key": 6177,
      "id": "103-1",
      "translation": "Par le Temps"
    },
    {
      "key": 6178,
      "id": "103-2",
      "translation": "L'homme est certes, en perdition"
    },
    {
      "key": 6179,
      "id": "103-3",
      "translation": "sauf ceux qui croient et accomplissent les bonnes œuvres, s'enjoignent mutuellement la vérité et s'enjoignent mutuellement l'endurance"
    },
    {
      "key": 6180,
      "id": "104-1",
      "translation": "Malheur à tout calomniateur diffamateur"
    },
    {
      "key": 6181,
      "id": "104-2",
      "translation": "qui amasse une fortune et la compte"
    },
    {
      "key": 6182,
      "id": "104-3",
      "translation": "pensant que sa fortune l'immortalisera"
    },
    {
      "key": 6183,
      "id": "104-4",
      "translation": "Mais non! Il sera certes, jeté dans la Hutamah"
    },
    {
      "key": 6184,
      "id": "104-5",
      "translation": "Et qui te dira ce qu'est la Hutamah"
    },
    {
      "key": 6185,
      "id": "104-6",
      "translation": "Le Feu attisé d'Allah"
    },
    {
      "key": 6186,
      "id": "104-7",
      "translation": "qui monte jusqu'aux cœurs"
    },
    {
      "key": 6187,
      "id": "104-8",
      "translation": "Il se refermera sur eux"
    },
    {
      "key": 6188,
      "id": "104-9",
      "translation": "en colonnes (de flammes) étendues"
    },
    {
      "key": 6189,
      "id": "105-1",
      "translation": "N'as-tu pas vu comment ton Seigneur a agi envers les gens de l'Eléphant"
    },
    {
      "key": 6190,
      "id": "105-2",
      "translation": "N'a-t-Il pas rendu leur ruse complètement vaine"
    },
    {
      "key": 6191,
      "id": "105-3",
      "translation": "et envoyé sur eux des oiseaux par volées"
    },
    {
      "key": 6192,
      "id": "105-4",
      "translation": "qui leur lançaient des pierres d'argile"
    },
    {
      "key": 6193,
      "id": "105-5",
      "translation": "Et Il les a rendus semblables à une paille mâchée"
    },
    {
      "key": 6194,
      "id": "106-1",
      "translation": "A cause du pacte des Coraïch"
    },
    {
      "key": 6195,
      "id": "106-2",
      "translation": "De leur pacte [concernant] les voyages d'hiver et d'été"
    },
    {
      "key": 6196,
      "id": "106-3",
      "translation": "Qu'ils adorent donc le Seigneur de cette Maison [la Ka'ba]"
    },
    {
      "key": 6197,
      "id": "106-4",
      "translation": "qui les a nourris contre la faim et rassurés de la crainte"
    },
    {
      "key": 6198,
      "id": "107-1",
      "translation": "Vois-tu celui qui traite de mensonge la Rétribution"
    },
    {
      "key": 6199,
      "id": "107-2",
      "translation": "C'est bien lui qui repousse l'orphelin"
    },
    {
      "key": 6200,
      "id": "107-3",
      "translation": "et qui n'encourage point à nourrir le pauvre"
    },
    {
      "key": 6201,
      "id": "107-4",
      "translation": "Malheur donc, à ceux qui prient"
    },
    {
      "key": 6202,
      "id": "107-5",
      "translation": "tout en négligeant (et retardant) leur Salât"
    },
    {
      "key": 6203,
      "id": "107-6",
      "translation": "qui sont pleins d'ostentation"
    },
    {
      "key": 6204,
      "id": "107-7",
      "translation": "et refusent l'ustensile (à celui qui en a besoin)"
    },
    {
      "key": 6205,
      "id": "108-1",
      "translation": "Nous t'avons certes, accordé l'Abondance"
    },
    {
      "key": 6206,
      "id": "108-2",
      "translation": "Accomplis la Salât pour ton Seigneur et sacrifie"
    },
    {
      "key": 6207,
      "id": "108-3",
      "translation": "Celui qui te hait sera certes, sans postérité"
    },
    {
      "key": 6208,
      "id": "109-1",
      "translation": "Dis: «O vous les infidèles"
    },
    {
      "key": 6209,
      "id": "109-2",
      "translation": "Je n'adore pas ce que vous adorez"
    },
    {
      "key": 6210,
      "id": "109-3",
      "translation": "Et vous n'êtes pas adorateurs de ce que j'adore"
    },
    {
      "key": 6211,
      "id": "109-4",
      "translation": "Je ne suis pas adorateur de ce que vous adorez"
    },
    {
      "key": 6212,
      "id": "109-5",
      "translation": "Et vous n'êtes pas adorateurs de ce que j'adore"
    },
    {
      "key": 6213,
      "id": "109-6",
      "translation": "A vous votre religion, et à moi ma religion»"
    },
    {
      "key": 6214,
      "id": "110-1",
      "translation": "Lorsque vient le secours d'Allah ainsi que la victoire"
    },
    {
      "key": 6215,
      "id": "110-2",
      "translation": "et que tu vois les gens entrer en foule dans la religion d'Allah"
    },
    {
      "key": 6216,
      "id": "110-3",
      "translation": "alors, par la louange, célèbre la gloire de ton Seigneur et implore Son pardon. Car c'est Lui le grand Accueillant au repentir"
    },
    {
      "key": 6217,
      "id": "111-1",
      "translation": "Que périssent les deux mains d'Abû-Lahab et que lui-même périsse"
    },
    {
      "key": 6218,
      "id": "111-2",
      "translation": "Sa fortune ne lui sert à rien, ni ce qu'il a acquis"
    },
    {
      "key": 6219,
      "id": "111-3",
      "translation": "Il sera brûlé dans un Feu plein de flammes"
    },
    {
      "key": 6220,
      "id": "111-4",
      "translation": "de même sa femme, la porteuse de bois"
    },
    {
      "key": 6221,
      "id": "111-5",
      "translation": "à son cou, une corde de fibres"
    },
    {
      "key": 6222,
      "id": "112-1",
      "translation": "Dis: «Il est Allah, Unique"
    },
    {
      "key": 6223,
      "id": "112-2",
      "translation": "Allah, Le Seul à être imploré pour ce que nous désirons"
    },
    {
      "key": 6224,
      "id": "112-3",
      "translation": "Il n'a jamais engendré, n'a pas été engendré non plus"
    },
    {
      "key": 6225,
      "id": "112-4",
      "translation": "Et nul n'est égal à Lui»"
    },
    {
      "key": 6226,
      "id": "113-1",
      "translation": "Dis: «Je cherche protection auprès du Seigneur de l'aube naissante"
    },
    {
      "key": 6227,
      "id": "113-2",
      "translation": "contre le mal des êtres qu'Il a créés"
    },
    {
      "key": 6228,
      "id": "113-3",
      "translation": "contre le mal de l'obscurité quand elle s'approfondit"
    },
    {
      "key": 6229,
      "id": "113-4",
      "translation": "contre le mal de celles qui soufflent [les sorcières] sur les nœuds"
    },
    {
      "key": 6230,
      "id": "113-5",
      "translation": "et contre le mal de l'envieux quand il envie»"
    },
    {
      "key": 6231,
      "id": "114-1",
      "translation": "Dis: «Je cherche protection auprès du Seigneur des hommes"
    },
    {
      "key": 6232,
      "id": "114-2",
      "translation": "Le Souverain des hommes"
    },
    {
      "key": 6233,
      "id": "114-3",
      "translation": "Dieu des hommes"
    },
    {
      "key": 6234,
      "id": "114-4",
      "translation": "contre le mal du mauvais conseiller, furtif"
    },
    {
      "key": 6235,
      "id": "114-5",
      "translation": "qui souffle le mal dans les poitrines des hommes"
    },
    {
      "key": 6236,
      "id": "114-6",
      "translation": "qu'il (le conseiller) soit un djinn, ou un être humain»"
    }
  ];