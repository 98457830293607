import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';

function RadarChart({ surahData   }) {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  const [selectedSurahIndex, setSelectedSurahIndex] = useState(0);

  const countArabicWords = (text) => {
    // Séparez les mots arabes en utilisant l'espace comme délimiteur
    const words = text.split(' ');

    // Filtrez les éléments vides (peuvent apparaître en raison d'espaces multiples)
    const filteredWords = words.filter((word) => word.trim() !== '');

    return filteredWords.length;
  };

  useEffect(() => {
    if ( surahData) {
      const verseLabels = surahData.verses.map((verse) => ` ${verse.id}`);
      const verseWordCounts = surahData.verses.map((verse) => countArabicWords(verse.text));

      chartInstance.current = new Chart(chartRef.current, {
        type: 'radar',
        data: {
          labels: verseLabels,
          datasets: [
            {
              label: 'Nombre de mots par verset',
              data: verseWordCounts,
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
              pointStyle: 'line', // Afficher les points comme des lignes
              pointRadius: 0, // Masquer les points
              fill: false, // Ne pas remplir la zone
            },
          ],
        },
        options: {
          scales: {
            r: {
              beginAtZero: true,
            },
          },
          plugins: {
            legend: {
              display: false, // Masquer la légende
            },
          },
        },
      });
    }

    // Nettoyer le graphique lorsque le composant est démonté
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [surahData]);

  return (
    <div className='color'>
      <h2>Nombre de mots par versets</h2>
      <canvas ref={chartRef} width={400} height={400}></canvas>
      <div className='flexB'>
        <h2>{surahData.translation}</h2>
      </div>
    </div>
  );
}

export default RadarChart;
