// Exemple de composant de page simple (par exemple, Home.js)
import React from 'react';
import HalfCircleChart from './HalfCircleChart.js';

const AnimeCoran = () => {
  return (
    <div>
      <HalfCircleChart />
    </div>
  );
};

export default AnimeCoran;