// Sidebar.js
import React, { useState } from 'react'; // Ajout de useState
import { Link } from 'react-router-dom';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Email , Home, Whatshot, Subscriptions, VideoLibrary, History, ThumbUp, InsertChart, Share, FiberManualRecord } from '@mui/icons-material';
import "./Sidebar.css"; // Styles CSS pour le Sidebar
import PayPalButtonComponent from './PayPalButtonComponent';
import CameraIcon from '@mui/icons-material/Camera';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';

const Sidebar = () => {
  const urlAPartager = 'https://votre-url.com'; // Remplacez par votre URL réelle

  const [isEmailFormOpen, setEmailFormOpen] = useState(false);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isParticipatePopupOpen, setParticipatePopupOpen] = useState(false);

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const toggleEmailForm = () => {
      setEmailFormOpen(!isEmailFormOpen);
  };

  const openParticipatePopup = () => {
    setParticipatePopupOpen(true);
  };

  const closeParticipatePopup = () => {
    setParticipatePopupOpen(false);
  };
  
  return (
    <div className='testtoto'>
        <div className='flexSidebar'>
          {/* Élément 1 */}
          <Link  component={Link} to="/" className="hover-effect">
            <div className="icon-color"><Home className="icon-color"/></div>
            <ListItemText primary="Accueil" />
          </Link>
          {/* Élément 2 */}
          <Link  component={Link} to="/coran" className="hover-effect">
            <div ><CameraIcon className="icon-color"/></div>
            <ListItemText primary="Coran" />
          </Link>
          {/* Élément 3 */}
          <Link  component={Link} to="/geneseCoran" className="hover-effect">
            <div ><History className="icon-color"/></div>
            <ListItemText primary="Génèse" />
          </Link>
          <Link  component={Link} to="/chart" className="hover-effect">
            <div ><InsertChart className="icon-color"/></div>
            <ListItemText primary="Charts" />
          </Link>
          {/* Élément 2 */}
          <Link  component={Link} to="/coranNum" className="hover-effect">
            <div ><FormatListNumberedRtlIcon className="icon-color"/></div>
            <ListItemText primary="Versets" />
          </Link>
          {/* Élément 3 */}
          <Link  component={Link} to="/worldmap" className="hover-effect">
            <div ><Subscriptions className="icon-color"/></div>
            <ListItemText primary="Carte" />
          </Link>
          {/* Ajoutez plus d'éléments ici */}
      
        <Link className="hover-effect" onClick={openPopup}>
          <div><Share className="icon-color" /></div>
          <ListItemText primary="Partager" />
        </Link>
        <Link  className="hover-effect">
          <div><VideoLibrary className="icon-color"/></div>
          <ListItemText primary="Bibliothèque" />
        </Link>
        <Link className="hover-effect" onClick={openParticipatePopup}>
          <div><ThumbUp className="icon-color" /></div>
          <ListItemText primary="Participer" />
        </Link>
        <Link className="hover-effect" onClick={toggleEmailForm}>
          <div><Email className="icon-color" /></div>
          <ListItemText primary="Me contacter" />
        </Link>
        {/* Icône de diffusion en direct */}
        <Link className="hover-effect">
          <div><FiberManualRecord className="live-icon" /></div>
          <ListItemText primary="En direct" />
        </Link>
        {/* Ajoutez plus d'éléments ici */}
      </div>
      {isEmailFormOpen && (
        <div className="email-form-popup">
          <div className="email-form-content">
            {/* Formulaire d'envoi d'e-mail */}
            <h2>Contactez-nous</h2>
            <p>Ce site web est en constante évolution pour offrir la meilleure expérience possible. 
              Nous cherchons toujours à améliorer nos fonctionnalités et à corriger les erreurs. 
              Si vous trouvez des fautes, des bogues ou si vous avez des idées pour rendre ce site encore meilleur, n'hésitez pas à nous contacter. 
              Votre feedback est précieux pour nous. </p>
            <p>Envoyez-nous un e-mail à l'adresse oumimcontact@gmail.com
              Nous vous remercions de nous aider à améliorer notre site pour vous.</p>

            <button onClick={toggleEmailForm}>Fermer</button>
          </div>
        </div>
      )}
      {isPopupOpen && (
        <div className="custom-popup">
          <div className="popup-content">
            <p>Partagez notre contenu sur les réseaux sociaux :</p>
            <ul>
              <li>
                <a href={`https://api.whatsapp.com/send?text=Voici le lien vers notre contenu: ${urlAPartager}`}>
                  Partager sur WhatsApp
                </a>
              </li>
              <li>
                <a href="#">Partager sur Facebook</a>
              </li>
              <li>
                <a href="#">Partager sur Twitter</a>
              </li>
              {/* Ajoutez d'autres réseaux sociaux ici */}
            </ul>
            <button onClick={closePopup}>Fermer</button>
          </div>
        </div>
      )}
      {isParticipatePopupOpen && (
        <div className="custom-popup wide-popup">
          <div className="popup-content">
            <h2>Participer au projet</h2>
            <p>
              Notre site, à but non lucratif, explore le coran et essaye de le rendre accessible au plus grand nombre. Notre ambition : faire de cette 
              plateforme interactive un lieu de partage, d'apprentissage et de recherche de la vérité. En participant, 
              vous nous aidez à bâtir un espace où la connaissance et la créativité émergent, et où chacun contribue à un avenir enrichi par chacun.
            </p>
            <p>
              Notre plateforme vise à créer un espace où vous pourrez partager du contenu personnel, organisez des groupes de 
              recherche et des lives. Une expérience collaborative, loin des systèmes de like nocifs, pour enrichir la connaissance ensemble. Et essayer, tant qu'il est encore possible de se rapprocher du créateur<br/> 
              Rejoignez ce projet !
            </p>
            <p>
              N'oubliez pas de me laisser des coordonnées (oumim@gmail.com) pour que je puisse vous communiquer dès que cela aboutira inshaAllah des identifiants donnant accès à un espace personnel, 
              où vous pourrez partager vos découvertes religieuses et plus encore. Une plateforme pour échanger et s'enrichir mutuellement dans la foi. (16/10/2023)
            </p>

            {/* Vous pouvez ajouter plus de contenu ici */}
            <PayPalButtonComponent />
            <button onClick={closeParticipatePopup}>Fermer</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
