// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Header';
import Home from './Home';
import History from './History';
import Watch from './Watch';
import Sidebar from './Sidebar'; // Ajoutez l'import du composant Sidebar
import { Grid } from '@mui/material';
import Coran from './Coran';
import Chart from './Chart';
import ArabicTextAnalyzer from './ArabicTextAnalyzer';
import AnimeCoran from './AnimeCoran';
import WorldMap from './WorldMap';
import Footer from './Footer'; // Importez le composant Footer


const App = () => {
  return (
    <Router>
      <div container>
        <Grid item xs={12}> {/* Faites en sorte que le Header occupe toute la largeur */}
          <Header />
        </Grid>
        <Grid item xs={2}> {/* La Sidebar commence après le Header */}
          <Sidebar /> {/* Ajoutez le composant Sidebar ici */}
        </Grid>
        

        <Grid item xs={9}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/coran" element={<Coran />} />
            <Route path="/chart" element={<Chart />} />
            <Route path="/geneseCoran" element={<AnimeCoran />} />
            <Route path="/coranNum" element={<ArabicTextAnalyzer />} />
            <Route path="/history" element={<History />} />
            <Route path="/worldmap" element={<WorldMap />} />
            <Route path="/watch/:videoId" element={<Watch />} />
          </Routes>
        </Grid>
        <Grid item xs={12}> {/* Ajoutez un Grid pour le Footer qui occupe toute la largeur */}
          <Footer /> {/* Ajoutez le composant Footer ici */}
        </Grid>
      </div>
    </Router>
  );
};

export default App;
