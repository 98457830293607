import React, { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import './HalfCircleChart.css';

const HalfCircleChart = () => {
  const [selectedPart, setSelectedPart] = useState(113);
  const [currentIndex, setCurrentIndex] = useState(113);
  const svgRef = useRef(null);
  const width = 700;
  const height = 800;
  const radius = Math.min(width, height) / 2;
  const numberOfParts = 131;

  // Insérez vos données JSON ici, ou importez-les depuis un fichier externe
  const jsonData = [
    {
      "data": [
        {
          "id": 1,
          "sourate": "Al Fatiha",
          "date": "-10800 av. J.-C.",
          "textScience": "Texte scientifique 1",
          "textCoran": "Texte Coran 1",
          "textBible": "Texte Bible 1"
        },
        {
          "id": 2,
          "sourate": "Al Baqara",
          "date": "-10700 av. J.-C.",
          "textScience": "Texte scientifique 2",
          "textCoran": "Texte Coran 2",
          "textBible": "Texte Bible 2"
        },
        {
          "id": 3,
          "sourate": "Al Fatiha",
          "date": "-10600 av. J.-C.",
          "textScience": "Texte scientifique 3",
          "textCoran": "Texte Coran 3",
          "textBible": "Texte Bible 3"
        },
        {
          "id": 4,
          "date": "-10500 av. J.-C.",
          "textScience": "Texte scientifique 4",
          "textCoran": "Texte Coran 4",
          "textBible": "Texte Bible 4"
        },
        {
          "id": 5,
          "date": "-10400 av. J.-C.",
          "textScience": "Texte scientifique 5",
          "textCoran": "Texte Coran 5",
          "textBible": "Texte Bible 5"
        },
        {
          "id": 6,
          "date": "-10300 av. J.-C.",
          "textScience": "Texte scientifique 6",
          "textCoran": "Texte Coran 6",
          "textBible": "Texte Bible 6"
        },
        {
          "id": 7,
          "date": "-10200 av. J.-C.",
          "textScience": "Texte scientifique 7",
          "textCoran": "Texte Coran 7",
          "textBible": "Texte Bible 7"
        },
        {
          "id": 8,
          "date": "-10100 av. J.-C.",
          "textScience": "Texte scientifique 8",
          "textCoran": "Texte Coran 8",
          "textBible": "Texte Bible 8"
        },
        {
          "id": 9,
          "date": "-10000 av. J.-C.",
          "textScience": "Texte scientifique 9",
          "textCoran": "Texte Coran 9",
          "textBible": "Texte Bible 9"
        },
        {
          "id": 10,
          "date": "-9900 av. J.-C.",
          "textScience": "Texte scientifique 10",
          "textCoran": "Texte Coran 10",
          "textBible": "Texte Bible 10"
        },
        {
          "id": 11,
          "date": "-9800 av. J.-C.",
          "textScience": "Texte scientifique 11",
          "textCoran": "Texte Coran 11",
          "textBible": "Texte Bible 11"
        },
        {
          "id": 12,
          "date": "-9700 av. J.-C.",
          "textScience": "Texte scientifique 12",
          "textCoran": "Texte Coran 12",
          "textBible": "Texte Bible 12"
        },
        {
          "id": 13,
          "date": "-9600 av. J.-C.",
          "textScience": "Texte scientifique 13",
          "textCoran": "Texte Coran 13",
          "textBible": "Texte Bible 13"
        },
        {
          "id": 14,
          "date": "-9500 av. J.-C.",
          "textScience": "Texte scientifique 14",
          "textCoran": "Texte Coran 14",
          "textBible": "Texte Bible 14"
        },
        {
          "id": 15,
          "date": "-9400 av. J.-C.",
          "textScience": "Texte scientifique 15",
          "textCoran": "Texte Coran 15",
          "textBible": "Texte Bible 15"
        },
        {
          "id": 16,
          "date": "-9300 av. J.-C.",
          "textScience": "Texte scientifique 16",
          "textCoran": "Texte Coran 16",
          "textBible": "Texte Bible 16"
        },
        {
          "id": 17,
          "date": "-9200 av. J.-C.",
          "textScience": "Texte scientifique 17",
          "textCoran": "Texte Coran 17",
          "textBible": "Texte Bible 17"
        },
        {
          "id": 18,
          "date": "-9100 av. J.-C.",
          "textScience": "Texte scientifique 18",
          "textCoran": "Texte Coran 18",
          "textBible": "Texte Bible 18"
        },
        {
          "id": 19,
          "date": "-9000 av. J.-C.",
          "textScience": "Texte scientifique 19",
          "textCoran": "Texte Coran 19",
          "textBible": "Texte Bible 19"
        },
        {
          "id": 20,
          "date": "-8900 av. J.-C.",
          "textScience": "Texte scientifique 20",
          "textCoran": "Texte Coran 20",
          "textBible": "Texte Bible 20"
        },
        {
          "id": 21,
          "date": "-8800 av. J.-C.",
          "textScience": "Texte scientifique 21",
          "textCoran": "Texte Coran 21",
          "textBible": "Texte Bible 21"
        },
        {
          "id": 22,
          "date": "-8700 av. J.-C.",
          "textScience": "Texte scientifique 22",
          "textCoran": "Texte Coran 22",
          "textBible": "Texte Bible 22"
        },
        {
          "id": 23,
          "date": "-8600 av. J.-C.",
          "textScience": "Texte scientifique 23",
          "textCoran": "Texte Coran 23",
          "textBible": "Texte Bible 23"
        },
        {
          "id": 24,
          "date": "-8500 av. J.-C.",
          "textScience": "Texte scientifique 24",
          "textCoran": "Texte Coran 24",
          "textBible": "Texte Bible 24"
        },
        {
          "id": 25,
          "date": "-8400 av. J.-C.",
          "textScience": "Texte scientifique 25",
          "textCoran": "Texte Coran 25",
          "textBible": "Texte Bible 25"
        },
        {
          "id": 26,
          "date": "-8300 av. J.-C.",
          "textScience": "Texte scientifique 26",
          "textCoran": "Texte Coran 26",
          "textBible": "Texte Bible 26"
        },
        {
          "id": 27,
          "date": "-8200 av. J.-C.",
          "textScience": "Texte scientifique 27",
          "textCoran": "Texte Coran 27",
          "textBible": "Texte Bible 27"
        },
        {
          "id": 28,
          "date": "-8100 av. J.-C.",
          "textScience": "Texte scientifique 28",
          "textCoran": "Texte Coran 28",
          "textBible": "Texte Bible 28"
        },
        {
          "id": 29,
          "date": "-8000 av. J.-C.",
          "textScience": "Texte scientifique 29",
          "textCoran": "Texte Coran 29",
          "textBible": "Texte Bible 29"
        },
        {
          "id": 30,
          "date": "-7900 av. J.-C.",
          "textScience": "Texte scientifique 30",
          "textCoran": "Texte Coran 30",
          "textBible": "Texte Bible 30"
        },
        {
          "id": 31,
          "date": "-7800 av. J.-C.",
          "textScience": "Texte scientifique 31",
          "textCoran": "Texte Coran 31",
          "textBible": "Texte Bible 31"
        },
        {
          "id": 32,
          "date": "-7700 av. J.-C.",
          "textScience": "Texte scientifique 32",
          "textCoran": "Texte Coran 32",
          "textBible": "Texte Bible 32"
        },
        {
          "id": 33,
          "date": "-7600 av. J.-C.",
          "textScience": "Texte scientifique 33",
          "textCoran": "Texte Coran 33",
          "textBible": "Texte Bible 33"
        },
        {
          "id": 34,
          "date": "-7500 av. J.-C.",
          "textScience": "Texte scientifique 34",
          "textCoran": "Texte Coran 34",
          "textBible": "Texte Bible 34"
        },
        {
          "id": 35,
          "date": "-7400 av. J.-C.",
          "textScience": "Texte scientifique 35",
          "textCoran": "Texte Coran 35",
          "textBible": "Texte Bible 35"
        },
        {
          "id": 36,
          "date": "-7300 av. J.-C.",
          "textScience": "Texte scientifique 36",
          "textCoran": "Texte Coran 36",
          "textBible": "Texte Bible 36"
        },
        {
          "id": 37,
          "date": "-7200 av. J.-C.",
          "sourate": "SAD",
          "textScience": "Texte scientifique 37",
          "textCoran": "Texte Coran 37",
          "textBible": "Texte Bible 37"
        },
        {
          "id": 38,
          "date": "-7100 av. J.-C.",
          "textScience": "Texte scientifique 38",
          "textCoran": "Texte Coran 38",
          "textBible": "Texte Bible 38"
        },
        {
          "id": 39,
          "date": "-7000 av. J.-C.",
          "textScience": "Texte scientifique 39",
          "textCoran": "Texte Coran 39",
          "textBible": "Texte Bible 39"
        },
        {
          "id": 40,
          "date": "-6900 av. J.-C.",
          "textScience": "Texte scientifique 40",
          "textCoran": "Texte Coran 40",
          "textBible": "Texte Bible 40"
        },
        {
          "id": 41,
          "date": "-6800 av. J.-C.",
          "textScience": "Texte scientifique 41",
          "textCoran": "Texte Coran 41",
          "textBible": "Texte Bible 41"
        },
        {
          "id": 42,
          "date": "-6700 av. J.-C.",
          "textScience": "Texte scientifique 42",
          "textCoran": "Texte Coran 42",
          "textBible": "Texte Bible 42"
        },
        {
          "id": 43,
          "date": "-6600 av. J.-C.",
          "textScience": "Texte scientifique 43",
          "textCoran": "Texte Coran 43",
          "textBible": "Texte Bible 43"
        },
        {
          "id": 44,
          "date": "-6500 av. J.-C.",
          "textScience": "Texte scientifique 44",
          "textCoran": "Texte Coran 44",
          "textBible": "Texte Bible 44"
        },
        {
          "id": 45,
          "date": "-6400 av. J.-C.",
          "textScience": "Texte scientifique 45",
          "textCoran": "Texte Coran 45",
          "textBible": "Texte Bible 45"
        },
        {
          "id": 46,
          "date": "-6300 av. J.-C.",
          "textScience": "Texte scientifique 46",
          "textCoran": "Texte Coran 46",
          "textBible": "Texte Bible 46"
        },
        {
          "id": 47,
          "date": "-6200 av. J.-C.",
          "textScience": "Texte scientifique 47",
          "textCoran": "Texte Coran 47",
          "textBible": "Texte Bible 47"
        },
        {
          "id": 48,
          "date": "-6100 av. J.-C.",
          "textScience": "Texte scientifique 48",
          "textCoran": "Texte Coran 48",
          "textBible": "Texte Bible 48"
        },
        {
          "id": 49,
          "date": "-6000 av. J.-C.",
          "textScience": "Texte scientifique 49",
          "textCoran": "Texte Coran 49",
          "textBible": "Texte Bible 49"
        },
        {
          "id": 50,
          "date": "-5900 av. J.-C.",
          "textScience": "Texte scientifique 50",
          "textCoran": "Texte Coran 50",
          "textBible": "Texte Bible 50"
        },
        {
          "id": 51,
          "date": "-5800 av. J.-C.",
          "textScience": "Texte scientifique 51",
          "textCoran": "Texte Coran 51",
          "textBible": "Texte Bible 51"
        },
        {
          "id": 52,
          "date": "-5700 av. J.-C.",
          "textScience": "Texte scientifique 52",
          "textCoran": "Texte Coran 52",
          "textBible": "Texte Bible 52"
        },
        {
          "id": 53,
          "date": "-5600 av. J.-C.",
          "textScience": "Texte scientifique 53",
          "textCoran": "Texte Coran 53",
          "textBible": "Texte Bible 53"
        },
        {
          "id": 54,
          "date": "-5500 av. J.-C.",
          "textScience": "Texte scientifique 54",
          "textCoran": "Texte Coran 54",
          "textBible": "Texte Bible 54"
        },
        {
          "id": 55,
          "date": "-5400 av. J.-C.",
          "textScience": "Texte scientifique 55",
          "textCoran": "Texte Coran 55",
          "textBible": "Texte Bible 55"
        },
        {
          "id": 56,
          "date": "-5300 av. J.-C.",
          "textScience": "Texte scientifique 56",
          "textCoran": "Texte Coran 56",
          "textBible": "Texte Bible 56"
        },
        {
          "id": 57,
          "date": "-5200 av. J.-C.",
          "textScience": "Texte scientifique 57",
          "textCoran": "Texte Coran 57",
          "textBible": "Texte Bible 57"
        },
        {
          "id": 58,
          "date": "-5100 av. J.-C.",
          "textScience": "Texte scientifique 58",
          "textCoran": "Texte Coran 58",
          "textBible": "Texte Bible 58"
        },
        {
          "id": 59,
          "date": "-5000 av. J.-C.",
          "textScience": "Texte scientifique 59",
          "textCoran": "Texte Coran 59",
          "textBible": "Texte Bible 59"
        },
        {
          "id": 60,
          "date": "-4900 av. J.-C.",
          "textScience": "Texte scientifique 60",
          "textCoran": "Texte Coran 60",
          "textBible": "Texte Bible 60"
        },
        {
          "id": 61,
          "date": "-4800 av. J.-C.",
          "textScience": "Texte scientifique 61",
          "textCoran": "Texte Coran 61",
          "textBible": "Texte Bible 61"
        },
        {
          "id": 62,
          "date": "-4700 av. J.-C.",
          "textScience": "Texte scientifique 62",
          "textCoran": "Texte Coran 62",
          "textBible": "Texte Bible 62"
        },
        {
          "id": 63,
          "date": "-4600 av. J.-C.",
          "textScience": "Texte scientifique 63",
          "textCoran": "Texte Coran 63",
          "textBible": "Texte Bible 63"
        },
        {
          "id": 64,
          "date": "-4500 av. J.-C.",
          "textScience": "Texte scientifique 64",
          "textCoran": "Texte Coran 64",
          "textBible": "Texte Bible 64"
        },
        {
          "id": 65,
          "date": "-4400 av. J.-C.",
          "textScience": "Texte scientifique 65",
          "textCoran": "Texte Coran 65",
          "textBible": "Texte Bible 65"
        },
        {
          "id": 66,
          "date": "-4300 av. J.-C.",
          "textScience": "Texte scientifique 66",
          "textCoran": "Texte Coran 66",
          "textBible": "Texte Bible 66"
        },
        {
          "id": 67,
          "date": "-4200 av. J.-C.",
          "textScience": "Texte scientifique 67",
          "textCoran": "Texte Coran 67",
          "textBible": "Texte Bible 67"
        },
        {
          "id": 68,
          "date": "-4100 av. J.-C.",
          "textScience": "Texte scientifique 68",
          "textCoran": "Texte Coran 68",
          "textBible": "Texte Bible 68"
        },
        {
          "id": 69,
          "date": "-4000 av. J.-C.",
          "textScience": "Texte scientifique 69",
          "textCoran": "Texte Coran 69",
          "textBible": "Texte Bible 69"
        },
        {
          "id": 70,
          "date": "-3900 av. J.-C.",
          "textScience": "Texte scientifique 70",
          "textCoran": "Texte Coran 70",
          "textBible": "Texte Bible 70"
        },
        {
          "id": 71,
          "date": "-3800 av. J.-C.",
          "textScience": "Texte scientifique 71",
          "textCoran": "Texte Coran 71",
          "textBible": "Texte Bible 71"
        },
        {
          "id": 72,
          "date": "-3700 av. J.-C.",
          "textScience": "Texte scientifique 72",
          "textCoran": "Texte Coran 72",
          "textBible": "Texte Bible 72"
        },
        {
          "id": 73,
          "date": "-3600 av. J.-C.",
          "textScience": "Texte scientifique 73",
          "textCoran": "Texte Coran 73",
          "textBible": "Texte Bible 73"
        },
        {
          "id": 74,
          "date": "-3500 av. J.-C.",
          "textScience": "Texte scientifique 74",
          "textCoran": "Texte Coran 74",
          "textBible": "Texte Bible 74"
        },
        {
          "id": 75,
          "date": "-3400 av. J.-C.",
          "textScience": "Texte scientifique 75",
          "textCoran": "Texte Coran 75",
          "textBible": "Texte Bible 75"
        },
        {
          "id": 76,
          "date": "-3300 av. J.-C.",
          "textScience": "Texte scientifique 76",
          "textCoran": "Texte Coran 76",
          "textBible": "Texte Bible 76"
        },
        {
          "id": 77,
          "date": "-3200 av. J.-C.",
          "textScience": "Texte scientifique 77",
          "textCoran": "Texte Coran 77",
          "textBible": "Texte Bible 77"
        },
        {
          "id": 78,
          "date": "-3100 av. J.-C.",
          "textScience": "Texte scientifique 78",
          "textCoran": "Texte Coran 78",
          "textBible": "Texte Bible 78"
        },
        {
          "id": 79,
          "date": "-3000 av. J.-C.",
          "textScience": "Texte scientifique 79",
          "textCoran": "Texte Coran 79",
          "textBible": "Texte Bible 79"
        },
        {
          "id": 80,
          "date": "-2900 av. J.-C.",
          "textScience": "Texte scientifique 80",
          "textCoran": "Texte Coran 80",
          "textBible": "Texte Bible 80"
        },
        {
          "id": 81,
          "date": "-2800 av. J.-C.",
          "textScience": "Texte scientifique 81",
          "textCoran": "Texte Coran 81",
          "textBible": "Texte Bible 81"
        },
        {
          "id": 82,
          "date": "-2700 av. J.-C.",
          "textScience": "Texte scientifique 82",
          "textCoran": "Texte Coran 82",
          "textBible": "Texte Bible 82"
        },
        {
          "id": 83,
          "date": "-2600 av. J.-C.",
          "textScience": "Texte scientifique 83",
          "textCoran": "Texte Coran 83",
          "textBible": "Texte Bible 83"
        },
        {
          "id": 84,
          "date": "-2500 av. J.-C.",
          "textScience": "Texte scientifique 84",
          "textCoran": "Texte Coran 84",
          "textBible": "Texte Bible 84"
        },
        {
          "id": 85,
          "date": "-2400 av. J.-C.",
          "textScience": "Texte scientifique 85",
          "textCoran": "Texte Coran 85",
          "textBible": "Texte Bible 85"
        },
        {
          "id": 86,
          "date": "-2300 av. J.-C.",
          "textScience": "Texte scientifique 86",
          "textCoran": "Texte Coran 86",
          "textBible": "Texte Bible 86"
        },
        {
          "id": 87,
          "date": "-2200 av. J.-C.",
          "textScience": "Texte scientifique 87",
          "textCoran": "Texte Coran 87",
          "textBible": "Texte Bible 87"
        },
        {
          "id": 88,
          "date": "-2100 av. J.-C.",
          "textScience": "Texte scientifique 88",
          "textCoran": "Texte Coran 88",
          "textBible": "Texte Bible 88"
        },
        {
          "id": 89,
          "date": "-2000 av. J.-C.",
          "textScience": "Texte scientifique 89",
          "textCoran": "Texte Coran 89",
          "textBible": "Texte Bible 89"
        },
        {
          "id": 90,
          "date": "-1900 av. J.-C.",
          "textScience": "Texte scientifique 90",
          "textCoran": "Texte Coran 90",
          "textBible": "Texte Bible 90"
        },
        {
          "id": 91,
          "date": "-1800 av. J.-C.",
          "textScience": "Texte scientifique 91",
          "textCoran": "Texte Coran 91",
          "textBible": "Texte Bible 91"
        },
        {
          "id": 92,
          "date": "-1700 av. J.-C.",
          "textScience": "Texte scientifique 92",
          "textCoran": "Texte Coran 92",
          "textBible": "Texte Bible 92"
        },
        {
          "id": 93,
          "date": "-1600 av. J.-C.",
          "textScience": "Texte scientifique 93",
          "textCoran": "Texte Coran 93",
          "textBible": "Texte Bible 93"
        },
        {
          "id": 94,
          "date": "-1500 av. J.-C.",
          "textScience": "Texte scientifique 94",
          "textCoran": "Texte Coran 94",
          "textBible": "Texte Bible 94"
        },
        {
          "id": 95,
          "date": "-1400 av. J.-C.",
          "textScience": "Texte scientifique 95",
          "textCoran": "Texte Coran 95",
          "textBible": "Texte Bible 95"
        },
        {
          "id": 96,
          "date": "-1300 av. J.-C.",
          "textScience": "Texte scientifique 96",
          "textCoran": "Texte Coran 96",
          "textBible": "Texte Bible 96"
        },
        {
          "id": 97,
          "date": "-1200 av. J.-C.",
          "textScience": "Texte scientifique 97",
          "textCoran": "Texte Coran 97",
          "textBible": "Texte Bible 97"
        },
        {
          "id": 98,
          "date": "-1100 av. J.-C.",
          "textScience": "Texte scientifique 98",
          "textCoran": "Texte Coran 98",
          "textBible": "Texte Bible 98"
        },
        {
          "id": 99,
          "date": "-1000 av. J.-C.",
          "textScience": "Texte scientifique 99",
          "textCoran": "Texte Coran 99",
          "textBible": "Texte Bible 99"
        },
        {
          "id": 100,
          "date": "-900 av. J.-C.",
          "textScience": "Texte scientifique 100",
          "textCoran": "Texte Coran 100",
          "textBible": "Texte Bible 100"
        },
        {
          "id": 101,
          "date": "-800 av. J.-C.",
          "textScience": "Texte scientifique 101",
          "textCoran": "Texte Coran 101",
          "textBible": "Texte Bible 101"
        },
        {
          "id": 102,
          "date": "-700 av. J.-C.",
          "textScience": "Texte scientifique 102",
          "textCoran": "Texte Coran 102",
          "textBible": "Texte Bible 102"
        },
        {
          "id": 103,
          "date": "-600 av. J.-C.",
          "textScience": "Texte scientifique 103",
          "textCoran": "Texte Coran 103",
          "textBible": "Texte Bible 103"
        },
        {
          "id": 104,
          "date": "-500 av. J.-C.",
          "textScience": "Texte scientifique 104",
          "textCoran": "Texte Coran 104",
          "textBible": "Texte Bible 104"
        },
        {
          "id": 105,
          "date": "-400 av. J.-C.",
          "textScience": "Texte scientifique 105",
          "textCoran": "Texte Coran 105",
          "textBible": "Texte Bible 105"
        },
        {
          "id": 106,
          "date": "-300 av. J.-C.",
          "textScience": "Texte scientifique 106",
          "textCoran": "Texte Coran 106",
          "textBible": "Texte Bible 106"
        },
        {
          "id": 107,
          "date": "-200 av. J.-C.",
          "textScience": "Texte scientifique 107",
          "textCoran": "Texte Coran 107",
          "textBible": "Texte Bible 107"
        },
        {
          "id": 108,
          "date": "-100 av. J.-C.",
          "textScience": "Texte scientifique 108",
          "textCoran": "Texte Coran 108",
          "textBible": "Texte Bible 108"
        },
        {
          "id": 109,
          "date": "0",
          "textScience": "Texte scientifique 109",
          "textCoran": "Texte Coran 109",
          "textBible": "Texte Bible 109"
        },
        {
          "id": 110,
          "date": "100 ap. J.-C.",
          "textScience": "Texte scientifique 110",
          "textCoran": "Texte Coran 110",
          "textBible": "Texte Bible 110"
        },
        {
          "id": 111,
          "date": "200 ap. J.-C.",
          "textScience": "Texte scientifique 111",
          "textCoran": "Texte Coran 111",
          "textBible": "Texte Bible 111"
        },
        {
          "id": 112,
          "date": "300 ap. J.-C.",
          "textScience": "Texte scientifique 112",
          "textCoran": "Texte Coran 112",
          "textBible": "Texte Bible 112"
        },
        {
          "id": 113,
          "date": "400 ap. J.-C.",
          "textScience": "Texte scientifique 113",
          "textCoran": "Texte Coran 113",
          "textBible": "Texte Bible 113"
        },
        {
          "id": 114,
          "date": "500 ap. J.-C.",
          "textScience": "Texte scientifique 114",
          "textCoran": "Texte Coran 114",
          "textBible": "Texte Bible 114"
        },
        {
          "id": 115,
          "date": "600 ap. J.-C.",
          "textScience": "Texte scientifique 115",
          "textCoran": "Texte Coran 115",
          "textBible": "Texte Bible 115"
        },
        {
          "id": 116,
          "date": "700 ap. J.-C.",
          "textScience": "Texte scientifique 116",
          "textCoran": "Texte Coran 116",
          "textBible": "Texte Bible 116"
        },
        {
          "id": 117,
          "date": "800 ap. J.-C.",
          "textScience": "Texte scientifique 117",
          "textCoran": "Texte Coran 117",
          "textBible": "Texte Bible 117"
        },
        {
          "id": 118,
          "date": "900 ap. J.-C.",
          "textScience": "Texte scientifique 118",
          "textCoran": "Texte Coran 118",
          "textBible": "Texte Bible 118"
        },
        {
          "id": 119,
          "date": "1000 ap. J.-C.",
          "textScience": "Texte scientifique 119",
          "textCoran": "Texte Coran 119",
          "textBible": "Texte Bible 119"
        },
        {
          "id": 120,
          "date": "1100 ap. J.-C.",
          "textScience": "Texte scientifique 120",
          "textCoran": "Texte Coran 120",
          "textBible": "Texte Bible 120"
        },
        {
          "id": 121,
          "date": "1200 ap. J.-C.",
          "textScience": "Texte scientifique 121",
          "textCoran": "Texte Coran 121",
          "textBible": "Texte Bible 121"
        },
        {
          "id": 122,
          "date": "1300 ap. J.-C.",
          "textScience": "Texte scientifique 122",
          "textCoran": "Texte Coran 122",
          "textBible": "Texte Bible 122"
        },
        {
          "id": 123,
          "date": "1400 ap. J.-C.",
          "textScience": "Texte scientifique 123",
          "textCoran": "Texte Coran 123",
          "textBible": "Texte Bible 123"
        },
        {
          "id": 124,
          "date": "1500 ap. J.-C.",
          "textScience": "Texte scientifique 124",
          "textCoran": "Texte Coran 124",
          "textBible": "Texte Bible 124"
        },
        {
          "id": 125,
          "date": "1600 ap. J.-C.",
          "textScience": "Texte scientifique 125",
          "textCoran": "Texte Coran 125",
          "textBible": "Texte Bible 125"
        },
        {
          "id": 126,
          "date": "1700 ap. J.-C.",
          "textScience": "Texte scientifique 126",
          "textCoran": "Texte Coran 126",
          "textBible": "Texte Bible 126"
        },
        {
          "id": 127,
          "date": "1800 ap. J.-C.",
          "textScience": "Texte scientifique 127",
          "textCoran": "Texte Coran 127",
          "textBible": "Texte Bible 127"
        },
        {
          "id": 128,
          "date": "1900 ap. J.-C.",
          "textScience": "Texte scientifique 128",
          "textCoran": "Texte Coran 128",
          "textBible": "Texte Bible 128"
        },
        {
          "id": 129,
          "date": "2000 ap. J.-C.",
          "textScience": "Texte scientifique 129",
          "textCoran": "Texte Coran 129",
          "textBible":  [
            {
              "id": 1,
              "text": "ٱلۡحَمۡدُ لِلَّهِ ٱلَّذِي لَهُۥ مَا فِي ٱلسَّمَٰوَٰتِ وَمَا فِي ٱلۡأَرۡضِ وَلَهُ ٱلۡحَمۡدُ فِي ٱلۡأٓخِرَةِۚ وَهُوَ ٱلۡحَكِيمُ ٱلۡخَبِيرُ",
              "translation": "Louange à Allah à qui appartient tout ce qui est dans les cieux et tout ce qui est sur la terre. Et louange à Lui dans l'au-delà. Et c'est Lui le Sage, le Parfaitement Connaisseur"
            },
            {
              "id": 2,
              "text": "يَعۡلَمُ مَا يَلِجُ فِي ٱلۡأَرۡضِ وَمَا يَخۡرُجُ مِنۡهَا وَمَا يَنزِلُ مِنَ ٱلسَّمَآءِ وَمَا يَعۡرُجُ فِيهَاۚ وَهُوَ ٱلرَّحِيمُ ٱلۡغَفُورُ",
              "translation": "Il sait ce qui pénètre en terre et ce qui en sort, ce qui descend du ciel et ce qui y remonte. Et c'est Lui le Miséricordieux, le Pardonneur"
            },
            {
              "id": 3,
              "text": "وَقَالَ ٱلَّذِينَ كَفَرُواْ لَا تَأۡتِينَا ٱلسَّاعَةُۖ قُلۡ بَلَىٰ وَرَبِّي لَتَأۡتِيَنَّكُمۡ عَٰلِمِ ٱلۡغَيۡبِۖ لَا يَعۡزُبُ عَنۡهُ مِثۡقَالُ ذَرَّةٖ فِي ٱلسَّمَٰوَٰتِ وَلَا فِي ٱلۡأَرۡضِ وَلَآ أَصۡغَرُ مِن ذَٰلِكَ وَلَآ أَكۡبَرُ إِلَّا فِي كِتَٰبٖ مُّبِينٖ",
              "translation": "Ceux qui ne croient pas disent: «L'Heure ne nous viendra pas». Dis: «Par mon Seigneur! Très certainement, elle vous viendra. [Mon Seigneur] le Connaisseur de l'Inconnaissable. Rien ne Lui échappe fût-il du poids d'un atome dans les cieux, comme sur la terre. Et rien n'existe de plus petit ni de plus grand, qui ne soit inscrit dans un Livre explicite"
            }]        
        },
        {
          "id": 130,
          "date": "2000 ap. J.-C.",
          "textScience": [
            {
              "id": 1,
              "text": "TLuc Il leur répondit: Voici, quand vous serez entrés dans la ville, vous rencontrerez un homme portant une cruche d eau; suivez-le dans la maison où il entrera,",
            },
            {
              "id": 2,
              "text": "يَعۡلَمُ مَا يَلِجُ فِي ٱلۡأَرۡضِ وَمَا يَخۡرُجُ مِنۡهَا وَمَا يَنزِلُ مِنَ ٱلسَّمَآءِ وَمَا يَعۡرُجُ فِيهَاۚ وَهُوَ ٱلرَّحِيمُ ٱلۡغَفُورُ",
            },
            {
              "id": 3,
              "text": "وَقَالَ ٱلَّذِينَ كَفَرُواْ لَا تَأۡتِينَا ٱلسَّاعَةُۖ قُلۡ بَلَىٰ وَرَبِّي لَتَأۡتِيَنَّكُمۡ عَٰلِمِ ٱلۡغَيۡبِۖ لَا يَعۡزُبُ عَنۡهُ مِثۡقَالُ ذَرَّةٖ فِي ٱلسَّمَٰوَٰتِ وَلَا فِي ٱلۡأَرۡضِ وَلَآ أَصۡغَرُ مِن ذَٰلِكَ وَلَآ أَكۡبَرُ إِلَّا فِي كِتَٰبٖ مُّبِينٖ",
            }]} ,
            {
            "id": 131,
            "date": "2000 ap. J.-C.",
            "textScience": [
              {
                "id": 1,
                "text": "TLuc Il leur répondit: Voici, quand vous serez entrés dans la ville, vous rencontrerez un homme portant une cruche d eau; suivez-le dans la maison où il entrera,",
              },
              {
                "id": 2,
                "text": "يَعۡلَمُ مَا يَلِجُ فِي ٱلۡأَرۡضِ وَمَا يَخۡرُجُ مِنۡهَا وَمَا يَنزِلُ مِنَ ٱلسَّمَآءِ وَمَا يَعۡرُجُ فِيهَاۚ وَهُوَ ٱلرَّحِيمُ ٱلۡغَفُورُ",
              },
              {
                "id": 3,
                "text": "وَقَالَ ٱلَّذِينَ كَفَرُواْ لَا تَأۡتِينَا ٱلسَّاعَةُۖ قُلۡ بَلَىٰ وَرَبِّي لَتَأۡتِيَنَّكُمۡ عَٰلِمِ ٱلۡغَيۡبِۖ لَا يَعۡزُبُ عَنۡهُ مِثۡقَالُ ذَرَّةٖ فِي ٱلسَّمَٰوَٰتِ وَلَا فِي ٱلۡأَرۡضِ وَلَآ أَصۡغَرُ مِن ذَٰلِكَ وَلَآ أَكۡبَرُ إِلَّا فِي كِتَٰبٖ مُّبِينٖ",
              }] 
          
        }
      ]
    }
    
  ];
  

  useEffect(() => {
    const svg = d3.select(svgRef.current);

    const pie = d3
      .pie()
      .sort(null)
      .value((d) => d.value);

    const arc = d3
      .arc()
      .innerRadius(0)
      .outerRadius(radius)
      .startAngle((d) => (d.index / numberOfParts) * Math.PI)
      .endAngle((d) => ((d.index + 1) / numberOfParts) * Math.PI);

    svg.selectAll('*').remove();

    const g = svg
      .append('g')
      .attr('transform', `translate(${0},${height / 2})`);

    const arcs = g
      .selectAll('.arc')
      .data(pie(jsonData[0].data))
      .enter()
      .append('g')
      .attr('class', 'arc');

    arcs
      .append('path')
      .attr('d', arc)
      .attr('class', (d, i) => `arc-group-${Math.floor(i / 21.5) + 1}`)
      .on('mouseenter', (event, d) => {
        d3.select(event.currentTarget)
          .attr('fill', 'lightyellow')
          .attr('cursor', 'pointer');
        svg
          .append('text')
          .attr('x', width - 800)
          .attr('y', height / 2 - radius + 60)
          .attr('text-anchor', 'middle')
          .attr('font-size', '14px')
          .text(`Sourate : ${d.data.id}`);
      })
      .on('mouseleave', (event, d) => {
        d3.select(event.currentTarget).attr(
          'fill',
          selectedPart === d.index ? 'lightgreen' : 'lightblue'
        );
        svg.select('text').remove();
      })
      .on('click', (event, d) => {
        console.log('Partie cliquée :', d.data.textScience);
        setSelectedPart(d.index);
        setCurrentIndex(d.index);
      });
  }, [numberOfParts, selectedPart, currentIndex]);

  return (
    <div className='flexU'>
      <svg ref={svgRef} width={width} height={height}></svg>

      {selectedPart !== null && (
        <div className="composant-lie">
          <p className='pHeader'>Sourate 10 - Verset 37<br/>
            "Ce Coran n'est nullement à être forgé en dehors d'Allah mais c'est la confirmation de ce qui existait déjà avant lui, <br/>
            et l'exposé détaillé du Livre en quoi il n'y a pas de doute,  venu du Seigneur de l'Univers" 
          </p>

          <h1> environ {jsonData[0].data[currentIndex+1].date}</h1>
          <h1>{jsonData[0].data[currentIndex].id} </h1>

          <div className="partie-details">
            <p>Texte Science: {jsonData[0].data[currentIndex].textScience}</p>
            <p>Texte Coran: {jsonData[0].data[currentIndex].textCoran}</p>
            <p>Texte Bible: {jsonData[0].data[currentIndex].textBible}</p>
          </div>
          <p className='pHeader'> 
            "Non!.. <br/>Je jure par les positions des étoiles. Et c'est vraiment un serment solennel, si vous saviez...<br/>
            Et c'est certainement un Coran noble dans un Livre bien gardé que seuls les purifiés touchent"
          </p>
        </div>
      )}
    </div>
  );
};

export default HalfCircleChart;


  

 

