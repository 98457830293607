import './Services.css';
import React, { useState, useEffect } from 'react';
import './custom-styles.css'; // Assurez-vous d'importer le fichier CSS personnalisé
import data from './json/data.json';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import IconButton from '@mui/material/IconButton';
import LanguageIcon from '@mui/icons-material/Language';




const Services = ({ selectedButton, handleButtonClick }) => {


  const [selectedVerse, setSelectedVerse] = useState(null);
  const [selectedData, setSelectedData] = useState(null);    
  
  const scrollToVerset = (versetId) => {
    const anchorId = `verset-${versetId}`;
    const anchorElement = document.getElementById(anchorId);
    if (anchorElement) {
      anchorElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

    
    useEffect(() => {
      
      const newData = data.find(item => item.id === selectedButton);
    setSelectedData(newData);
      console.log("t", newData);
      handleButtonClick(selectedButton);
    }, [selectedButton]);

    const copyVerse = (verse) => {
      const textToCopy = `${verse.id} - ${verse.translation}`;
      navigator.clipboard.writeText(textToCopy)
        .then(() => {
          // Copie réussie
          console.log(`Copié avec succès : ${textToCopy}`);
        })
        .catch(err => {
          console.error('Erreur lors de la copie : ', err);
        });
    };
   

  return (
    <div className="services-container card">
      {selectedData && (
        <div className="selected-content">
          <h3 className='h2Services'>{selectedData.translation}  -  {selectedData.transliteration}-  {selectedData.name}</h3>
          <div className="verses-container">
            {selectedData.verses.map(verse => (
              <div id={`verset-${verse.id}`} className="verse custom-verse" key={verse.id}>
                
                

                <div className='versets'>
                    <div className="arabic">{verse.text}</div>
                    <div className="translation">{verse.id} - {verse.translation}</div>
                </div>

                <div className='flexV'>
                
                <IconButton
                  aria-label="Copier"
                  onClick={() => copyVerse(verse)}
                  color="primary" // Choisissez la couleur qui convient
                  title="Copier"
                >
                  <FileCopyIcon className="icon-color"/>
                </IconButton>

                <div className='remarque'>
                  <a
                      href={`https://coran-seul.com/index.php/verset?sourate=${selectedButton}&verset=${verse.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="annotation-button"
                      title="Traduire"

                  >
                  <LanguageIcon className="icon-color" />
                </a>
                </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Services;
