import React, { useState, useEffect } from 'react';
import "./SearchButton.css";
import { versesData } from './versesData';
import { AppBar, Toolbar, IconButton, Typography, InputBase } from '@mui/material';
import { Search, AccountCircle, NotificationsNoneTwoTone   } from '@mui/icons-material';

const SearchButton = ({ onSearch, data, handleButtonClick  }) => {
  const [selectedButton, setSelectedButton] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [verses, setVerses] = useState([]); // État pour stocker les versets
  const [searchResults, setSearchResults] = useState([]);

  
  useEffect(() => {
    // Réinitialiser la recherche lorsque la page est chargée
    
    setSearchTerm('');
    setSearchResults([]);
  }, []);
  
  const highlightText = (text, term) => {
    const regex = new RegExp(`(${term})`, 'gi');
    return text.replace(regex, '<span class="highlight">$1</span>');
  };

  const handleSearch = (term) => {
    const lowercaseTerm = term.toLowerCase();

    setSearchTerm(term);

    if (term.length === 0) {
      setSearchResults([]);
      return;
    }


    const results = versesData
    .filter(
      item =>
        item['translation']
          .toLowerCase()
          .includes(term.toLowerCase())
    );


    setSearchResults(results);
  };
  
  const handleSearchBis = (term) => {
    const lowercaseTerm = term.toLowerCase();

    setSearchTerm(term);

    if (term.length === 0) {
      setSearchResults([]);
      return;
    }


    const results = versesData
    .filter(
      item =>
        item['text']
          .toLowerCase()
          .includes(term.toLowerCase())
    );


    setSearchResults(results);
  };
  const copyVerse = (verse) => {
    const textToCopy = `${verse.id} - ${verse['translation']}`;
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        // Copie réussie
        console.log(`Copié avec succès : ${textToCopy}`);
      })
      .catch(err => {
        console.error('Erreur lors de la copie : ', err);
      });
  };
  


  return (
    <div className="word-search">
      
        <div className='divSearch'>
          <IconButton>
            <Search className="icon-color"/>
          </IconButton>
          <InputBase
            type="text"
            placeholder="Rechercher..."
            inputProps={{ 'aria-label': 'rechercher' }}
            style={{ borderBottom: '1px solid lightgray' }}
            value={searchTerm}
            onChange={(e) => handleSearch(e.target.value)}
            />
          
          
        </div>

      {searchTerm.length > 0 && (
            <div className='search-summary'>
            <p className='pOccurence'>{searchResults.length} occurences trouvées</p>
            <ul className='search-results'>
              {searchResults.map(item => (
                <li key={item.id} className="card">
                  <a
                    href="#"
                    onClick={() => handleButtonClick(item.id.split('-')[0])}
                    
                  >
                    {highlightText("Sourate")} {item.id.split('-')[0]} - Verset {item.id.split('-')[1]}
                  </a> <br/>
                  <span className='verse-translation' >
                  {highlightText(item['translation'])}
                  </span>
                  <br/>
                  
                </li>
              ))}
            </ul>
            </div>
          )}

    </div>
  );
};

export default SearchButton;

