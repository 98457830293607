import React from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';

const PayPalButtonComponent = () => {
  return (
    <PayPalScriptProvider options={{ 'client-id': 'AaEzhEgatzQNUYuiS5pEY8eVvsSfG7R52yIHO9p5YMX6ZtVctd59r6v2E7haWYpqTsDVdUkv22Abfvq_' }}>
      <PayPalButtons
        createOrder={(data, actions) => {
          // Fonction pour créer une commande PayPal
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: '10.00', // Montant du paiement
                },
              },
            ],
          });
        }}
        onApprove={(data, actions) => {
          // Fonction exécutée lorsque le paiement est approuvé
          return actions.order.capture();
        }}
      />
    </PayPalScriptProvider>
  );
};

export default PayPalButtonComponent;
