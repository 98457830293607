// Exemple de composant de page simple (par exemple, Home.js)
import React from 'react';

const Watch = () => {
  return (
    <div>
      <h1>Page d'Watch</h1>
      <p>Bienvenue sur la page d'watch de l'application.</p>
    </div>
  );
};

export default Watch;
