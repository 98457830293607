import React, {useState, useRef, useEffect } from 'react';
import './ButtonList.css';

const ButtonList = ({ data }) => {
  const [sortedData, setSortedData] = useState(data);
  const [translationText, setTranslationText] = useState("");
  const [transliteration, setTransliteration] = useState("");
  const [totalVerses, setTotalVerses] = useState("");
  const [id, setId] = useState("");
  const [verses, setVerses] = useState([]);
  const [revealed, setRevealed] = useState([]);
  const [activeSort, setActiveSort] = useState(null);
  const [words, setWords] = useState([]);
  const [letters, setLetters] = useState([]);
  const textRef = useRef(null);
  

  useEffect(() => {
    // Tri par ordre classique (id) par défaut lors du chargement initial
    handleSort('id');
    
    // Affichage de la sourate numéro 1 par défaut
    handleButtonClick(sortedData[0]);
  }, []);
  const handleCopy = (versetId) => {
    const textToCopy = textRef.current.textContent;
    navigator.clipboard.writeText(textToCopy);
    console.log(textRef.current);
  };

  const handleButtonClick = (sourate) => {
    setTranslationText(sourate.translation);
    setTransliteration(sourate.transliteration);
    setTotalVerses(sourate.total_verses);
    setVerses(sourate.verses);
    setId(sourate.id);
    setRevealed(sourate.order_of_revelation);
    setWords(sourate.total_words);
    setLetters(sourate.total_letters);

  };
 

  const handleSort = (sortBy) => {
    let newData = [...data];
  
    if (sortBy === 'id' || sortBy === 'appearance' || sortBy === 'verse' || sortBy === 'words' || sortBy === 'letters') {
      setActiveSort(sortBy);
      
      if (sortBy === 'id') {
        newData.sort((a, b) => a.id - b.id);
      } else if (sortBy === 'appearance') {
        newData.sort((a, b) => a.order_of_revelation - b.order_of_revelation);
      } else if (sortBy === 'verse') {
        newData.sort((b, a) => a.total_verses - b.total_verses);
      } else if (sortBy === 'words') {
        newData.sort((b, a) => a.total_words - b.total_words);
      } else if (sortBy === 'letters') {
        newData.sort((b, a) => a.total_letters - b.total_letters);
      }
    }
  
    setSortedData(newData);
  };

  const circleRadii = [100, 140, 180, 220, 260, 300]; // Rayons de chaque cercle
  const centerX = 330;
  const centerY = 600;
  



  const calculatePosition = (circleIndex, index) => {
    const totalButtonsInCircle = 19; // Nombre de boutons dans chaque cercle
    const angleIncrement = (2 * Math.PI) / totalButtonsInCircle;
    const angle = index * angleIncrement;
    const radius = circleRadii[circleIndex];
  
    const x = centerX + radius * Math.cos(angle);
    const y = centerY + radius * Math.sin(angle);
    return { x, y };
  };
  

  return (
    
    <div className="button-list-container">
      <div className="sorted-buttons">
      <button
          onClick={() => handleSort('id')}
          className={activeSort === '' || activeSort === 'id' ? 'active' : ''}
        >Trier par ordre classique</button>
        <button
          onClick={() => handleSort('appearance')}
          className={activeSort === 'appearance' ? 'active' : ''}
        >Trier par ordre d'apparition</button>
        <button
          onClick={() => handleSort('verse')}
          className={activeSort === 'verse' ? 'active' : ''}
        >Trier par nombre de verset</button>
        <button
          onClick={() => handleSort('words')}
          className={activeSort === 'words' ? 'active' : ''}>Trier par nombre de mots</button>
          <button
          onClick={() => handleSort('letters')}
          className={activeSort === 'letters' ? 'active' : ''}>Trier par nombre de lettres</button>
      </div>

      <div className="button-list">
        {Array.from({ length: 114 }, (_, index) => {
          const circleIndex = Math.floor(index / 19);
          const sourate = sortedData[index];
          const { x, y } = calculatePosition(circleIndex, index % 19);
          return (
            <button
              key={sourate.id}
              className="circle-button"
              id={sourate.id}
              style={{ left: x, top: y }}
              onClick={() => {
                handleButtonClick(sourate)
              }}
              >
              {index + 1}
      </button>
    );
  })}
</div>
<div className="translation-container">
        <h3>Sourate {id}: {translationText}  ({transliteration})</h3>
        <h5> {totalVerses} versets, {words} mots, {letters} lettres et fut la sourate {revealed} révélée au prophète Muhammad SWS.</h5>
        <div className="verses-container">
          {verses.map((verset) => (
            <div className= {verset.classe}>
              <p key={verset.id} ref={textRef}>{verset.id}   -   {verset.translation}</p>
              
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ButtonList;
