import React, { useState, useEffect, useRef } from 'react';
import './Sourates.css';

const Sourates = ({ setSelectedButton, data, selectedButton, handleButtonClick }) => {
  const [selectedData, setSelectedData] = useState(null);
  const scrollableDivRef = useRef(null);

  useEffect(() => {
    const newData = data.find((item) => item.id === selectedButton);
    setSelectedData(newData);
  }, [selectedButton, data]);

  useEffect(() => {
    // Lorsque selectedData change, faites défiler pour centrer le bouton actuellement sélectionné
    if (selectedData) {
      const button = document.getElementById(selectedData.id);
      if (button && scrollableDivRef.current) {
        const scrollableDiv = scrollableDivRef.current;
        const scrollLeft = button.offsetLeft - (scrollableDiv.offsetWidth - button.offsetWidth) / 2;

        scrollableDiv.scrollTo({
          left: scrollLeft,
          behavior: 'smooth',
        });
      }
    }
  }, [selectedData]);

  const handleButtonActive = (buttonId) => {
    setSelectedButton(buttonId);
  };

  return (
    <div className="sourate-container" ref={scrollableDivRef}>
      <div className="buttons-container">
        {data.map((item) => (
          <div
            className={`button ${item.id === selectedButton ? 'active-button' : ''}`}
            id={item.id}
            key={item.id}
            onClick={() => {
              handleButtonActive(item.id);
              handleButtonClick(item.id);
            }}
          >
            {item.id}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sourates;
