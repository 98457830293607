import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem, Dialog, TextField, Button, Tooltip } from '@mui/material';
import { Description, NotificationsNoneTwoTone, Settings, Language, AccountCircle } from '@mui/icons-material';
import './Header.css';

const Header = () => {
  const appBarStyle = {
    boxShadow: 'none',
  };

  const [anchorElMessage, setAnchorElMessage] = useState(null);
  const [anchorElNotifications, setAnchorElNotifications] = useState(null);
  const [anchorElSettings, setAnchorElSettings] = useState(null);
  const [anchorElLanguage, setAnchorElLanguage] = useState(null);
  const [anchorElAccount, setAnchorElAccount] = useState(null);
  const [anchorElNote, setAnchorElNote] = useState(null);
  const [noteText, setNoteText] = useState('');
  const [isNoteDialogOpen, setNoteDialogOpen] = useState(false);

  const noteDialogStyle = {
    background: '#e9f9ee', // Couleur de fond
    height: '85vh', // Hauteur de la boîte de dialogue
    padding: '16px', // Espacement intérieur
    width: '80vw', // Largeur de la boîte de dialogue
    borderRadius: '8px', // Coins arrondis
  };
  
  const textFieldStyle = {
    background: 'white', // Couleur de fond du champ de texte
    width: '100%',
    height: '666px' // Largeur du champ de texte
  };
  
  const buttonStyle = {
    background: '#044f1a', // Couleur de fond des boutons
    color: 'white', // Couleur du texte des boutons
    margin: '8px', // Marge entre les boutons
  };

  const handleOpenNoteDialog = () => {
    setAnchorElNote(null);
    setNoteDialogOpen(true);
  };

  const handleCloseNoteDialog = () => {
    setNoteDialogOpen(false);
  };

  const handleSaveNote = () => {
    // Save the note content wherever you want (e.g., Redux, state, etc.)
    setNoteDialogOpen(false);
  };

  const handleExportNote = () => {
    // Create a text file and initiate download
    const blob = new Blob([noteText], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'note.txt';
    a.click();
    URL.revokeObjectURL(url);
  };

  const handleClickMessage = (event) => {
    setAnchorElMessage(event.currentTarget);
  };

  const handleClickNotifications = (event) => {
    setAnchorElNotifications(event.currentTarget);
  };

  const handleClickSettings = (event) => {
    setAnchorElSettings(event.currentTarget);
  };

  const handleClickLanguage = (event) => {
    setAnchorElLanguage(event.currentTarget);
  };

  const handleClickAccount = (event) => {
    setAnchorElAccount(event.currentTarget);
  };

  const handleClose = (iconName) => {
    switch (iconName) {
      case 'Message':
        setAnchorElMessage(null);
        break;
      case 'Notifications':
        setAnchorElNotifications(null);
        break;
      case 'Settings':
        setAnchorElSettings(null);
        break;
      case 'Language':
        setAnchorElLanguage(null);
        break;
      case 'Account':
        setAnchorElAccount(null);
        break;
      default:
        break;
    }
  };

  return (
    <AppBar position="static" color="transparent" style={appBarStyle}>
      <div className='flexHeader'>
        <Typography variant="h2" style={{ flexGrow: 1, color: '#506757' }} className='my-title'>
          Oumim.com
        </Typography>
        <p className='pHeader'>Sourate 42 - Verset 10 <br/>

          "Sur toutes vos divergences, le jugement appartient à Allah. <br/>
          Tel est Allah mon Seigneur; 
          en Lui je place ma confiance et c'est à Lui que je retourne"</p>
        <div className='flexNotif'>
          <Tooltip title="Note">
            <IconButton aria-controls="icon-menu-note" onClick={handleOpenNoteDialog}>
              <Description className="icon-color" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Notifications">
            <IconButton
              aria-controls="icon-menu-notifications"
              aria-haspopup="true"
              onClick={(event) => handleClickNotifications(event)}
            >
              <NotificationsNoneTwoTone className="icon-color" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Settings">
            <IconButton
              aria-controls="icon-menu-settings"
              aria-haspopup="true"
              onClick={(event) => handleClickSettings(event)}
            >
              <Settings className="icon-color" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Language">
            <IconButton
              aria-controls="icon-menu-language"
              aria-haspopup="true"
              onClick={(event) => handleClickLanguage(event)}
            >
              <Language className="icon-color" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Account">
            <IconButton
              aria-controls="icon-menu-account"
              aria-haspopup="true"
              onClick={(event) => handleClickAccount(event)}
            >
              <AccountCircle className="icon-color" />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <Dialog open={isNoteDialogOpen} onClose={handleCloseNoteDialog} PaperProps={{ style: noteDialogStyle }}>
      <TextField
        label="Note"
        multiline
        rows={4}
        fullWidth
        value={noteText}
        onChange={(e) => setNoteText(e.target.value)}
      />
      
      <Button onClick={handleExportNote} style={buttonStyle}>
        Exporter
      </Button>
    </Dialog>
      
      <Menu
        id="icon-menu-notifications"
        anchorEl={anchorElNotifications}
        open={Boolean(anchorElNotifications)}
        onClose={() => handleClose('Notifications')}
      >
        <MenuItem onClick={() => handleClose('Notifications')}>Option en cours de création</MenuItem>
      </Menu>
      <Menu
        id="icon-menu-settings"
        anchorEl={anchorElSettings}
        open={Boolean(anchorElSettings)}
        onClose={() => handleClose('Settings')}
      >
        <MenuItem onClick={() => handleClose('Settings')}>Option 1 (Settings)</MenuItem>
        <MenuItem onClick={() => handleClose('Settings')}>Option 2 (Settings)</MenuItem>
        <MenuItem onClick={() => handleClose('Settings')}>Option 3 (Settings)</MenuItem>
      </Menu>
      <Menu
        id="icon-menu-language"
        anchorEl={anchorElLanguage}
        open={Boolean(anchorElLanguage)}
        onClose={() => handleClose('Language')}
      >
        <MenuItem onClick={() => handleClose('Language')}> Français</MenuItem>
        <MenuItem onClick={() => handleClose('Language')}> عرب</MenuItem>
        <MenuItem onClick={() => handleClose('Language')}> English</MenuItem>
      </Menu>
      <Menu
        id="icon-menu-account"
        anchorEl={anchorElAccount}
        open={Boolean(anchorElAccount)}
        onClose={() => handleClose('Account')}
      >
        <MenuItem onClick={() => handleClose('Account')}>Option en cours de création</MenuItem>
      
      </Menu>
    </AppBar>
  );
};

export default Header;
