import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import "./QuranChart.css";

function QuranChart({ data, onSortByClick, onSurahClick }) {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const [selectedSurah, setSelectedSurah] = useState(null);

  // Définissez la fonction onSurahClick pour mettre à jour la sourate sélectionnée
  

  

  useEffect(() => {
    if (chartRef.current) {
      const mecquoisColor = '#4b845c';
      const medinoisColor = '#1976d2';
      const colors = data.map((item) => (item.type === 'medinan' ? medinoisColor : mecquoisColor));

      chartInstance.current = new Chart(chartRef.current, {
        type: 'bar',
        data: {
          labels: data.map((item) => item.translation),
          datasets: [
            {
              label: ' mecquois ',
              data: data.map((item) => item.type === 'meccan' ? item.total_verses : null),
              backgroundColor: mecquoisColor,
              borderColor: mecquoisColor,
              borderWidth: 3, // Épaissir les barres
              hoverBorderColor: mecquoisColor,
              hoverBackgroundColor: mecquoisColor,
            },
            {
              label: ' medinois ',
              data: data.map((item) => item.type === 'medinan' ? item.total_verses : null),
              backgroundColor: medinoisColor,
              borderColor: medinoisColor,
              borderWidth: 4, // Épaissir les barres
              hoverBorderColor: medinoisColor,
              hoverBackgroundColor: medinoisColor,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'Nombre de versets',
              },
            },
            x: {
              title: {
                display: true,
                text: 'Sourate',
              },
            },
          },
          onClick: (event, elements) => {
            if (elements.length > 0) {
              const surahIndex = elements[0].index;
              const selectedSurah = data[surahIndex];
              onSurahClick(selectedSurah);
              // Afficher l'ID de la sourate dans la console
              console.log(`ID de la sourate : ${selectedSurah.id}`);
            }
          },
        },
      });
    }

    // Nettoyer le graphique lorsque le composant est démonté
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data]);

  return (
    <div className='color'>
      <h2>Nombre de versets par sourates</h2>
      <canvas ref={chartRef} width={300} height={200}></canvas>
      <div className='sort-buttons'>
      <button onClick={() => onSortByClick('id')}>Trier par ordre classique</button>
        <button onClick={() => onSortByClick('appearance')}>Trier par apparition</button>
        <button onClick={() => onSortByClick('verses')}>Trier par nombre de versets</button>
        <button onClick={() => onSortByClick('words')}>Trier par nombre de mots</button>
        <button onClick={() => onSortByClick('letters')}>Trier par nombre de lettres</button>
      </div>
    </div>
  );
}

export default QuranChart;
