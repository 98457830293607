import React, { useState, useEffect, useRef } from 'react';
import jsonData from './json/data.json';
import './ArabicTextAnalyzer.css';
import Chart from 'chart.js/auto';
import QuranVerses from './QuranVerses';

const ArabicTextAnalyzer = () => {
  const [selectedText, setSelectedText] = useState('بِسۡمِ ٱللَّهِ ٱلرَّحۡمَٰنِ ٱلرَّحِيمِ');
  const [wordCount, setWordCount] = useState(0);
  const [letterCount, setLetterCount] = useState(0);
  const [letterOccurrences, setLetterOccurrences] = useState({});
  const [selectedButton, setSelectedButton] = useState(13);

  const letterChartRef = useRef(null);
  const letterChart = useRef(null);
  const [startSourate, setStartSourate] = useState('1'); // Par défaut, commencez par la première sourate
  const [endSourate, setEndSourate] = useState('114'); // Par défaut, terminez par la dernière sourate
  const [startSourateOptions, setStartSourateOptions] = useState([]);
  const [endSourateOptions, setEndSourateOptions] = useState([]);
  const [startVerse, setStartVerse] = useState('1');
  const [endVerse, setEndVerse] = useState('1');
  const [startVerseOptions, setStartVerseOptions] = useState([]); // Ajout de ces déclarations
  const [endVerseOptions, setEndVerseOptions] = useState([]); // Ajout de ces déclarations
  const [copiedText, setCopiedText] = useState('بِسۡمِ ٱللَّهِ ٱلرَّحۡمَٰنِ ٱلرَّحِيمِ');

  const handleCopyArabicText = (arabicText) => {
    // Copiez le texte en arabe dans l'état "copiedText"
    setCopiedText(arabicText);
    setSelectedText(arabicText);

  };
  
  
  
  useEffect(() => {
    // Générez les options à partir des données JSON filtrées pour les sourates sélectionnées
    const filteredSourates = jsonData.filter(
      (entry) => entry.id >= parseInt(startSourate) && entry.id <= parseInt(endSourate)
    );

    const startVerseOptions = filteredSourates.map((entry) => (
      <option key={entry.id} value={String(entry.verse)}>
        Verset {entry.verse}
      </option>
    ));

    const endVerseOptions = startVerseOptions; // Mettez à jour ceci en fonction de vos besoins

    // Mettez à jour les options des sélecteurs de début et de fin
    setStartVerseOptions(startVerseOptions);
    setEndVerseOptions(endVerseOptions);
  }, [startSourate, endSourate]);

  const handleButtonClick = async (buttonId) => {
    setSelectedButton(buttonId);
    //const buttonElement = document.getElementById(`button-${buttonId}`);
    
    // Autres actions à effectuer après avoir mis à jour l'état
  };

  useEffect(() => {
    // Créez le graphique en dehors de la fonction analyzeText
    if (!letterChart.current && letterChartRef.current) {
      const ctx = letterChartRef.current.getContext('2d');
      letterChart.current = new Chart(ctx, {
        type: 'bar',
        data: {
          options: {
            scales: {
              letters: {
                position: 'left', // Position de l'axe des lettres
              },
              occurrences: {
                position: 'bottom', // Position de l'axe des occurrences
              },
            },
          },
          labels: [],
          datasets: [
            {
              label: 'Occurrences',
              data: [],
              backgroundColor: '#506757', // Définissez la couleur ici

              xAxisID: 'occurrences', // ID de l'axe X
              yAxisID: 'letters', // ID de l'axe Y
            },
          ],
        },
      });
    }
  }, []);

  useEffect(() => {
    // Fonction pour analyser le texte
    const analyzeText = () => {
      const words = selectedText.split(/\s+/);
      setWordCount(words.length);
    
      const arabicCharacters = selectedText.match(/[\u0600-\u06FF]/g);
      setLetterCount(arabicCharacters ? arabicCharacters.length : 0);
    
      const letterCounts = {};
      if (arabicCharacters) {
        arabicCharacters.forEach((letter) => {
          if (letterCounts[letter]) {
            letterCounts[letter]++;
          } else {
            letterCounts[letter] = 1;
          }
        });
      }
      setLetterOccurrences(letterCounts);
    
      if (letterChart.current) {
        letterChart.current.data.labels = Object.keys(letterCounts);
        letterChart.current.data.datasets[0].data = Object.values(letterCounts);
        letterChart.current.update();
      }
    };

    if (selectedText) {
      analyzeText();
    }
  }, [selectedText]);

  const handleTextSelection = (selected) => {
    setSelectedText(selected);
  };


  return (
    <div className='flexU'>
      
<QuranVerses onCopyArabicText={handleCopyArabicText} />
      <div className="arabic-text-analyzer">
        

        <textarea
          rows="5"
          value={copiedText}
          onChange={(e) => handleTextSelection(e.target.value)}
          ></textarea>
        <p>Nombre de mots: {wordCount}</p>
        <p>Nombre de caractere: {letterCount}</p>
        <div>
          <p>Nombres d'Occurrences de lettres arabes.</p>
          <canvas id="letterChart" ref={letterChartRef}></canvas>
        </div>
        
        
      </div>
    </div>
  );
};

export default ArabicTextAnalyzer;
