// Footer.js
import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className='footer'>
      {/* Le contenu de votre footer, par exemple : */}
      <h2>moc.mimuO</h2>

      <div>Par l'Aube <br/>

Et par les dix nuits<br/>

Par le pair et l'impair<br/>

Et par la nuit quand elle s'écoule<br/>

N'est-ce pas là un serment, pour un doué d'intelligence ? </div>
      
      <p >&copy; 2023 Oumim - Tous droits réservés</p>
    </footer>
  );
};

export default Footer;
